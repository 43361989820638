import {
  DefaultRandomDiscoverPeoplePagination,
  DefaultRandomDiscoverCompanyPagination,
} from 'constants/data';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  useGetRandomizedPeopleQuery,
  useGetRandomizedCompaniesQuery,
} from 'store/discover/discover.api';
import { useGetPostTagsQuery } from 'store/post/post-tag.api';
import Image from 'next/image';
import TellMeSomething from './CreatePost/TellMeSomething';
import DiscoverContainer from './DiscoverSection/DiscoverContainer';
import PostListContainer from './Posts';
import PostFilters from './Posts/PostFilters';
import StaffingListItemsContainer from './StaffingSection/StaffingListItemsContainer';
import Banner from './Banner';
import SearchPosts from './Posts/SearchPosts';

const FeedContainer: FunctionComponent = () => {
  const { data: postFilters } = useGetPostTagsQuery({});

  const { data: discoverdPeople, isLoading: peopleIsLoading } =
    useGetRandomizedPeopleQuery(DefaultRandomDiscoverPeoplePagination);
  const { data: discoverdCompanies, isLoading: companiesIsLoading } =
    useGetRandomizedCompaniesQuery(DefaultRandomDiscoverCompanyPagination);
  const [isUserOnboarded, setUserOnboarded] = useState<boolean>(false);

  useEffect(() => {
    setUserOnboarded(
      localStorage.getItem('isUserOnboarded')?.toLocaleLowerCase() === 'true',
    );
  }, []);

  const removeOnBoardingUserFlag = () => {
    setTimeout(() => {
      setUserOnboarded(false);
      localStorage.removeItem('isUserOnboarded');
    }, 2000);
  };
  return (
    <>
      <div className='homepage-container'>
        {isUserOnboarded ? (
          <Image
            src='/confetti-single.gif'
            alt='Confetti'
            width='100'
            height='100'
            onLoadingComplete={removeOnBoardingUserFlag}
            className='position-fixed'
            style={{
              width: '100vw',
              height: 'auto',
              zIndex: 10,
              left: '0',
              bottom: '10%',
            }}
          />
        ) : (
          ''
        )}
        <DiscoverContainer
          discoveredPeople={discoverdPeople?.items ?? []}
          discoveredCompanies={discoverdCompanies?.items ?? []}
          peopleIsLoading={peopleIsLoading}
          companiesIsLoading={companiesIsLoading}
        />
        <div className='middle'>
          <>
            <Banner />
            <TellMeSomething postTags={postFilters} />
            <PostFilters filters={postFilters} />
            <SearchPosts />
          </>
          <PostListContainer />
        </div>

        <StaffingListItemsContainer />
      </div>
    </>
  );
};

export default FeedContainer;
