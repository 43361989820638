import { UserEntity } from 'commons/types/data';
import {
  IAttachmentCreative,
  IAttachmentCredit,
  IAttachmentDefaults,
  IconProps,
} from 'components/Cards';
import { CreditPlaceHolder } from 'components/Placeholders';
import ProfileUtils from './profile';
import { getCompanyLocation } from './company';

// eslint-disable-next-line import/prefer-default-export
export class MemberCardInfo {
  static getHeadings(keyName: string, val: UserEntity): string[] {
    if (keyName === 'companies') {
      return [
        val.pro_headline,
        getCompanyLocation(val.locations ?? []),
        `${val.follower_count} Followers`,
      ] as string[];
    }
    if (keyName === 'credits') {
      return [
        val.userProfessionalTagline,
        val.userProjectTagline,
        val.userGenreTagline,
      ] as string[];
    }
    return [
      val.professionalTagline,
      val.projectTagline,
      val.genreTagline,
    ] as string[];
  }

  static getProfileImage(keyName: string, val: UserEntity): string {
    if (keyName === 'companies') {
      return ProfileUtils.getImageFileFromAwsUrl(val.logo as string);
    }
    return val.profile_img ?? '';
  }

  static getIcon(keyName: string): IconProps[] | undefined {
    if (keyName === 'companies') {
      return undefined;
    }
    return [
      {
        label: 'verified',
        icon: 'verified-fill',
        color: 'text-awards',
      },
    ];
  }

  static getId(keyName: string, val: UserEntity): number {
    if (keyName === 'credits' || keyName === 'creativeSamples') {
      return val.userId as number;
    }

    return val.id;
  }

  static getAttachment(
    keyName: string,
    val: UserEntity,
  ): IAttachmentCreative | IAttachmentCredit | IAttachmentDefaults | undefined {
    if (keyName === 'companies') return undefined;
    if (keyName === 'creativeSamples') {
      return {
        type: 'creative',
        assetType: val.assetType,
        description: [val.title, val.description],
        videoUrl: val.assetFilename,
        heading: 'Creative Portfolio',
      } as IAttachmentCreative;
    }
    if (keyName === 'memberBios') {
      return {
        type: 'default',
        heading: 'Bio',
        description: val.bio,
      } as IAttachmentDefaults;
    }
    return {
      type: 'credit',
      heading: val.credit ? 'Credits' : '',
      description: [val.credit, val.projectTitle],
      imgUrl:
        (val.credit || val.projectTitle) &&
        (val.creditImg ?? CreditPlaceHolder),
    } as IAttachmentCredit;
  }

  static getFullName(keyName: string, val: UserEntity): string {
    const name = val.fullname || val.username || val.company_name;
    return name as string;
  }

  static getClassName(keyName: string): string {
    if (keyName === 'companies') {
      return 'company';
    }

    return '';
  }
}
