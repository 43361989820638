import SquircleImage from 'components/Core/SquircleImage/SquircleImage';
import React, { useEffect, useState } from 'react';
import ProfileUtils from 'utils/profile';
import HusslUpIcon from 'components/Icon';
import { useCreateCommentMutation } from 'store/post/comment.api';
import { selectCurrentUser } from 'store/auth/selectors';
import { useSelector } from 'react-redux';
import { selectGetPostWithTagParams } from 'store/post/selectors';
import CreateCommentTextArea from './CreateCommentTextArea';

export interface PostCommentThreadContainerProps {
  postId: number;
  parent_comment_id: number;
  handleReply: () => void;
}

const rows = 1;
const cols = 80;

const PostCommentThreadContainer = React.forwardRef<
  HTMLTextAreaElement,
  PostCommentThreadContainerProps
>(({ postId, parent_comment_id, handleReply }, ref) => {
  const [createComment, { isLoading }] = useCreateCommentMutation();
  const [mentionedUsers, setMentionedUsers] = useState<string[]>([]);
  const currentUser = useSelector(selectCurrentUser);
  const getPostWithTagParams = useSelector(selectGetPostWithTagParams);
  const [comment, setComment] = useState('');

  const [isAddingMention, setIsAddingMention] = useState(false);
  const [adjustedRows, setAdjustedRows] = useState(rows);

  const handleCreateComment = async () => {
    await createComment({
      body: {
        comment,
        postId,
        mentionedUsersId: mentionedUsers,
        parent_comment_id,
      },
      commentBy: currentUser,
      getPostWithTagParams,
    });
    setComment('');
    setMentionedUsers([]);
    handleReply();
  };

  const getUser = async (husslupId: string) => {
    setIsAddingMention(true);
    // TODO: this is the hot fix comment api should accept husslup ids instead of user ids and should return exactly like post api
    if (mentionedUsers.some((userId) => userId === husslupId)) {
      setIsAddingMention(false);
      return;
    }
    setMentionedUsers((users) => [...users, husslupId]);
    setIsAddingMention(false);
  };

  useEffect(() => {
    if (!comment) {
      setAdjustedRows(1);
      setMentionedUsers([]);
    } else {
      const filteredMentions = mentionedUsers.filter((user) =>
        comment.includes(user),
      );
      setMentionedUsers(filteredMentions);
    }
  }, [comment]);

  return (
    <>
      <div className='your-comment'>
        <SquircleImage
          imgUrl={ProfileUtils.getProfileImage(
            currentUser?.profile_img ?? '',
            44,
            44,
          )}
          width={44}
          height={44}
          alt='Commenting User Profile'
        />
        <CreateCommentTextArea
          placeholder='Write a Reply...'
          required
          value={comment}
          onChange={(event: any) => {
            setComment(event.target.value?.replaceAll(/\r/g, ''));
          }}
          wrapperClass='comment'
          inputRef={ref}
          rows={adjustedRows}
          cols={cols}
          onUsersMentioned={(id) => {
            getUser(id as string);
          }}
        />
        <button
          onClick={handleCreateComment}
          type='button'
          disabled={isLoading || isAddingMention}
        >
          <HusslUpIcon
            name='sended'
            variant='3x'
            wrapperClass={isLoading ? 'text-light' : 'text-primary'}
          />
        </button>
      </div>
    </>
  );
});

export default PostCommentThreadContainer;
