import { HTTP_METHODS } from 'constants/http';
import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  API_GET_REPORT_POST_DEFAULT,
  API_GET_REPORT_USER_DEFAULT,
  API_REPORT_COMPANY,
  API_REPORT_POST,
  API_REPORT_USER,
} from 'constants/api';

export interface IReport {
  id: number;
  title: string;
}

interface ReportPayload {
  report: string;
  reportedUserId: string;
}
interface ReportPostPayload {
  report: string;
  postId: number;
}

interface CompanyReportPayload {
  report: string;
  reportedCompanyId: number;
  userId: number;
}

export const reportApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getReportLists: builder.query<IReport[], void>({
        query() {
          return {
            url: API_GET_REPORT_USER_DEFAULT,
            method: HTTP_METHODS.GET,
          };
        },
      }),
      getReportPostLists: builder.query<IReport[], void>({
        query() {
          return {
            url: API_GET_REPORT_POST_DEFAULT,
            method: HTTP_METHODS.GET,
          };
        },
        keepUnusedDataFor: 3600
      }),
      reportUser: builder.mutation<any, ReportPayload>({
        query({ report, reportedUserId }) {
          return {
            url: API_REPORT_USER,
            method: HTTP_METHODS.POST,
            body: { report, reportedUserId },
          };
        },
      }),
      reportPost: builder.mutation<any, ReportPostPayload>({
        query({ report, postId }) {
          return {
            url: API_REPORT_POST,
            method: HTTP_METHODS.POST,
            body: { report, postId },
          };
        },
      }),
      reportCompany: builder.mutation<any, CompanyReportPayload>({
        query({ report, reportedCompanyId, userId }) {
          return {
            url: API_REPORT_COMPANY,
            method: HTTP_METHODS.POST,
            body: { report, reportedCompanyId, userId },
          };
        },
      }),
    };
  },
});

export const {
  useLazyGetReportListsQuery,
  useGetReportListsQuery,
  useGetReportPostListsQuery,
  useReportUserMutation,
  useReportPostMutation,
  useReportCompanyMutation,
} = reportApiSlice;
