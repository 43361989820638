import React, {
  FunctionComponent,
  ReactNode,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import Slider, { Settings } from 'react-slick';
import HusslUpIcon from 'components/Icon';
import { useRouter } from 'next/router';
import { DISCOVER } from 'constants/routes';
import { SERENDIPITY_SLIDER_INIT_DELAY_SECONDS } from 'constants/commons';

interface IProps {
  children: ReactNode;
  onPageChangeRequest?: () => Promise<boolean>;
  wrapperClass?: string;
  isLoading?: boolean;
  onSlide?: (slideIndex: number) => void;
  slideIndex?: number;
  hasNext?: boolean;
}

type ISlideDirection = 'left' | 'right';

interface IVisibleSlide {
  index: number;
  zIndex: number;
}

// Dynamically generate the zindex and index for the slides
const pickNodes = (total: number, current: number): IVisibleSlide[] => {
  const tempNodes: IVisibleSlide[] = [];
  for (
    let index = 0, zIndex = 5, currentSlide = current, revIteration = 0;
    index < 5;
    index++, --zIndex, currentSlide++
  ) {
    if (currentSlide >= total) {
      tempNodes.push({
        index: revIteration,
        zIndex,
      });
      revIteration++;
    } else {
      tempNodes.push({
        index: currentSlide,
        zIndex,
      });
    }
  }
  return tempNodes;
};

const sliderClassName = 'serendipity-slider';
const slideInstanceAttribute = 'slide-instance';

/**
 *
 * @description This is the generic serendipity slider it accepts callback fn for paginated slide as optional and React nodes
 * @returns Slider
 */
const SerendipitySlider: FunctionComponent<IProps> = ({
  children,
  onPageChangeRequest,
  wrapperClass = '',
  onSlide,
  slideIndex = 0,
  hasNext = false,
}) => {
  const sliderRef = useRef<Slider>(null);
  const sliderHolderRef = useRef<HTMLDivElement>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const [totalSlides, setTotalSlides] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [mounted, setMounted] = useState<boolean>(false);
  const router = useRouter();
  const sliderClasses: string = `${sliderClassName} ${wrapperClass}`;

  const sliderInit = async (currentSlide?: number) => {
    const slideWrapper = sliderHolderRef.current;
    const slides = slideWrapper?.querySelectorAll('.slick-slide');
    const slidesLen = slides?.length || 0;

    // Call the api for new slides
    if (
      currentSlide &&
      currentSlide >= slidesLen - 2 &&
      hasNext &&
      onPageChangeRequest
    ) {
      onPageChangeRequest();
      sliderRef.current?.slickGoTo(currentSlideIndex + 1, false);
      const timer = setTimeout(() => {
        setLoading(false);
        clearTimeout(timer);
        slideWrapper?.classList.remove('slick-loading');
        slideWrapper
          ?.querySelector(`[data-index="${currentSlide}"]`)
          ?.removeAttribute(slideInstanceAttribute);
      }, 300);
    }
    const visibleNodes = pickNodes(slidesLen, currentSlide || 0);
    setTotalSlides(slidesLen);
    setCurrentSlideIndex(currentSlide || 0);
    slides?.forEach((slide, index) => {
      const isActiveSlide = visibleNodes.find((vn) => vn.index === index);
      if (isActiveSlide) {
        slide.setAttribute('zIndex', isActiveSlide.zIndex.toString());
      } else {
        slide.removeAttribute('zIndex');
      }
    });
  };
  const settings: Pick<
    Settings,
    | 'dots'
    | 'arrows'
    | 'initialSlide'
    | 'slidesToScroll'
    | 'centerMode'
    | 'slidesToShow'
    | 'infinite'
    | 'swipeToSlide'
    | 'swipe'
    | 'nextArrow'
    | 'prevArrow'
    | 'onInit'
    | 'beforeChange'
    | 'afterChange'
    | 'onReInit'
  > = {
    dots: false,
    arrows: true,
    initialSlide: 0,
    slidesToScroll: 1,
    centerMode: true,
    slidesToShow: 1,
    infinite: false,
    swipeToSlide: false,
    swipe: false,
    nextArrow: (
      <HusslUpIcon
        name='chevron-right'
        wrapperClass={`slick-button next icon-btn ${
          currentSlideIndex >= totalSlides ? 'disabled' : ''
        }`}
      />
    ),
    prevArrow: (
      <HusslUpIcon
        name='chevron-left'
        wrapperClass={`slick-button prev icon-btn ${
          currentSlideIndex === 0 ? 'disabled' : ''
        }`}
      />
    ),
    onInit: () => {
      sliderInit(slideIndex);
    },
    beforeChange: (currentSlide, nextSlide) => {
      const direction: ISlideDirection =
        currentSlide < nextSlide ? 'right' : 'left';
      const slideWrapper = sliderHolderRef.current;

      slideWrapper
        ?.querySelector(`.${sliderClassName}`)
        ?.classList.add(direction);
      const currentSlideEl = slideWrapper?.querySelector(
        `[data-index="${currentSlide}"]`,
      );
      const newSlideEl = slideWrapper?.querySelector(
        `[data-index="${nextSlide}"]`,
      );
      currentSlideEl?.setAttribute(slideInstanceAttribute, 'old');
      newSlideEl?.setAttribute(slideInstanceAttribute, 'new');
      sliderInit(nextSlide);
    },
    afterChange: (currentSlide) => {
      const slideWrapper = sliderHolderRef.current;
      slideWrapper
        ?.querySelector(`.${sliderClassName}`)
        ?.classList.remove('left', 'right');
      const slideInstanceEls = slideWrapper?.querySelectorAll(
        `[slide-instance^="old"]`,
      );
      const currentSlideEl = slideWrapper?.querySelector(
        `[data-index="${currentSlide}"]`,
      );
      slideInstanceEls?.forEach((el) =>
        el.removeAttribute(slideInstanceAttribute),
      );
      currentSlideEl?.removeAttribute(slideInstanceAttribute);
      onSlide && onSlide(currentSlide);
    },
  };
  useEffect(() => {
    const slideWrapper = sliderHolderRef.current;
    if (slideWrapper) {
      setMounted(true);
    }
    router.pathname.includes(DISCOVER) &&
      slideWrapper?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

    return () => {
      setMounted(false);
    };
  }, [sliderHolderRef.current]);

  useEffect(() => {
    if (sliderRef.current) {
      setTimeout(() => {
        sliderHolderRef.current?.classList.remove('loading');
        setLoading(false);
      }, SERENDIPITY_SLIDER_INIT_DELAY_SECONDS);
    }
  }, [sliderRef.current]);

  return (
    <div
      className={`${sliderClassName}-holder loading ${wrapperClass}`}
      ref={sliderHolderRef}
    >
      {loading && (
        <div className='d-flex justify-content-center mb-4'>
          <div className='spinner-border text-primary' role='status' />
        </div>
      )}

      {mounted && (
        <Slider {...settings} className={sliderClasses} ref={sliderRef}>
          {children}
        </Slider>
      )}
    </div>
  );
};

export default memo(SerendipitySlider);
