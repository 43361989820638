/* eslint-disable @next/next/no-img-element */
import React, { useMemo } from 'react';

import { PostMedia } from 'commons/types/post.type';
import PdfAttachment from 'components/Core/File/PdfAttachment';

type PostAttachmentProps = {
  media: PostMedia[];
};

const PostAttachment = ({ media }: PostAttachmentProps) => {
  const { pdfs, images } = useMemo(() => {
    const pdfFiles = media.filter((file) => file.type === 'pdf') || [];
    const imageFiles = media.filter((file) => file.type === 'image') || [];
    return { pdfs: pdfFiles, images: imageFiles };
  }, [media]);

  const pdfFileName = (fileName: string) => {
    if (!fileName) return '';
    return fileName.substring(fileName.indexOf('_') + 1);
  };

  return (
    <>
      {pdfs?.map((pdfFile) => (
        <PdfAttachment
          key={pdfFile.post_media_id}
          fileName={pdfFileName(pdfFile.filename ?? '')}
          url={pdfFile.url ?? ''}
        />
      ))}

      {images?.map((image) => (
        /** @TODO fix next/Image issue for remote images */
        <div className='post-img-wrap' key={image.post_media_id}>
          <img className='post-img' src={image?.url ?? ''} alt='Post Pic' />
        </div>
      ))}
    </>
  );
};

export default PostAttachment;
