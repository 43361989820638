import { StaticImageData } from 'next/image';
import { FunctionComponent, ReactElement } from 'react';
import SuperEllipse from 'react-superellipse';
import { clickableDivRequiredProps } from 'utils/dom';
import NextImage from '../Image';

interface SquircleImageProps {
  r1?: number;
  r2?: number;
  width?: number;
  height?: number;
  display?: string;
  imgUrl: StaticImageData | string;
  alt: string;
  handleError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  className?: string;
  objectFit?: 'cover' | 'contain' | 'fill';
  onClick?: () => void;
}

const SquircleImage: FunctionComponent<SquircleImageProps> = ({
  r1 = 0.05,
  r2 = 0.49,
  width = 328,
  height = 328,
  display = 'block',
  imgUrl,
  alt,
  onClick,
  className = '',
}): ReactElement => (
  <SuperEllipse
    r1={r1}
    r2={r2}
    style={{ width, height, display }}
    className={`squicircle-image ${className}`}
  >
    {onClick ? (
      <div onClick={onClick} {...clickableDivRequiredProps}>
        <NextImage
          src={imgUrl as string}
          width={width}
          height={height}
          alt={alt}
        />
      </div>
    ) : (
      <NextImage
        src={imgUrl as string}
        width={width}
        height={height}
        alt={alt}
      />
    )}
  </SuperEllipse>
);

export default SquircleImage;
