/**
 * @Input https://www.example.com/blog?search=hello&world
 * @returns example.com
 */
export const getDomainFromUrl = (url: string): string => {
  if (url?.length) {
    const domain = new URL(url);
    return domain.hostname.replace('www.', '');
  }
  return url;
};

export const getDomainObjectFromUrl = (
  url: string,
): { cleanUrl: string; href: string } => {
  if (url?.length) {
    try {
      const domain = new URL(decodeURIComponent(url));
      return {
        cleanUrl: domain.hostname.replace('www.', ''),
        href: domain.href,
      };
    } catch {
      return { cleanUrl: '', href: '' };
    }
  }
  return { cleanUrl: '', href: '' };
};

const urlRegex =
  /(?:\s|^)((?:https?:\/\/)?(?:[a-z0-9-]+(?:\.[a-z0-9-]+)+)(?::[0-9]+)?(?:\/(?:[^\s]+)?)?)/g;

export type KnowsUrlType = 'linkedin' | 'instagram' | 'facebook' | 'others';

export const GENERIC_URL_REGEX =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

const INSTAGRAM_URL_CHECKER =
  /^(?:(?:http|https):\/\/)?((w{3}\.)?)instagram.com\/.*/i;

const FACEBOOK_URL_CHECKER =
  /^(?:(?:http|https):\/\/)?((w{3}\.)?)facebook.com\/.*/i;

const LINKEDIN_URL_CHECKER =
  /^(?:(?:http|https):\/\/)?((w{3}\.)?)linkedin.com\/.*/i;

export const hasUrl = (text: string) => {
  if (!text) return false;
  return text.match(urlRegex);
};

export const extractUrlFromText = (text: string) => {
  const matches = text.match(urlRegex);
  if (matches?.length) {
    return matches.map((url) => url.trim());
  }
  return undefined;
};

export const extractOnlyTextFromTextWithUrl = (text: string) => {
  if (!text) return false;
  return text.replace(urlRegex, '').trim();
};

export const whichUrl = (url: string): KnowsUrlType => {
  if (url.match(FACEBOOK_URL_CHECKER)) {
    return 'facebook';
  }
  if (url.match(INSTAGRAM_URL_CHECKER)) {
    return 'instagram';
  }
  if (url.match(LINKEDIN_URL_CHECKER)) {
    return 'linkedin';
  }
  return 'others';
};
