import {
  PROFILE_ONBOARD_CREDITS,
  PROFILE_ONBOARD_DETAILS1,
  PROFILE_ONBOARD_DETAILS2,
  PROFILE_ONBOARD_DETAILS3,
  PROFILE_ONBOARD_INFO,
  PROFILE_ONBOARD_INVITE_CODE,
  PROFILE_ONBOARD_SPLASH,
} from 'constants/routes';
import { UserType } from 'store/user/types';

export const OnboardRoutes = [
  PROFILE_ONBOARD_SPLASH,
  PROFILE_ONBOARD_CREDITS,
  PROFILE_ONBOARD_INFO,
  PROFILE_ONBOARD_DETAILS1,
  PROFILE_ONBOARD_DETAILS2,
  PROFILE_ONBOARD_DETAILS3,
  PROFILE_ONBOARD_INVITE_CODE,
  // PROFILE_ONBOARD_CONGRATULATIONS,
];

export const isOnboardRoute = (route: string) => OnboardRoutes.includes(route);

export const hasFilledMinimalOnboardInfo = (user: UserType) =>
  user?.firstName &&
  user?.lastName &&
  user?.profile_img &&
  user?.professionalTagline &&
  user?.projectTagline;

export const getNextOnboardRoute = (user: UserType, currentRoute: string) => {
  if (
    currentRoute === PROFILE_ONBOARD_CREDITS &&
    hasFilledMinimalOnboardInfo(user)
  ) {
    return PROFILE_ONBOARD_INVITE_CODE;
  }
  return currentRoute;
};
