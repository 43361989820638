import Image from 'next/image';
import { FunctionComponent } from 'react';

const Logo: FunctionComponent = () => (
  <div className="logo24">
    <Image
      src="/logo-white.svg"
      width={96}
      height={24}
      alt="HUSSLUP LOGO White"
    />
  </div>
);

export default Logo;
