import Typography from 'components/Core/StylesGuide/Typography';
import { FunctionComponent } from 'react';
import NotificationList from './NotificationList';

const Notification: FunctionComponent<{ lastActivityFetchAt: number }> = ({
  lastActivityFetchAt,
}) => (
  <>
    <div className='d-flex justify-content-between align-items-center pl-2 pr-2 mb-2'>
      <Typography variant='h2'>Notifications</Typography>
    </div>

    <NotificationList lastActivityFetchAt={lastActivityFetchAt} />
  </>
);

export default Notification;
