import MemberTile from 'components/Core/MemberTile';
import Typography from 'components/Core/StylesGuide/Typography';
import HusslUpIcon from 'components/Icon';
import Image from 'next/image';
import React, { FunctionComponent, ReactElement } from 'react';
import highlightText from 'utils/hightLightText';
import {
  DEFAULT_ATTACHMENT_DESCRIPTION_MAX_LENGTH,
  ATTACHMENT_DESCRIPTION_INDEX_FIRST_MAX_LENGTH,
  ATTACHMENT_DESCRIPTION_INDEX_SECOND_MAX_LENGTH,
} from 'constants/app';
import { AttachmentProps, IAttachmentCredit } from '.';

interface IProps {
  attachment: AttachmentProps;
  searchText?: string;
  keyName?: string;
}

const Attachment: FunctionComponent<IProps> = ({
  attachment,
  searchText,
  keyName,
}): ReactElement => {
  const { type, heading } = attachment;

  const getDesc = (desc: string, searchText: string, type: string) => {
    if (type === 'creative' && desc && searchText) {
      return highlightText(desc, searchText);
    }
    if (type === 'credit' && desc && searchText) {
      return highlightText(desc, searchText);
    }

    return desc;
  };

  return (
    <div className='attachment overflow-hidden'>
      <div className='attachment__item'>
        <div className='title'>{heading}</div>
        <div
          className={
            type === 'default'
              ? 'attachment__contents gap-0'
              : 'attachment__contents'
          }
        >
          <div className=''>
            {type === 'creative' && attachment.assetType && (
              <div className='attachment__contents_image'>
                {attachment?.assetType === 'video' && (
                  <HusslUpIcon name='video' />
                )}
                {attachment?.assetType === 'audio' && (
                  <HusslUpIcon name='audio' />
                )}
                {attachment?.assetType === 'pdf' && (
                  <HusslUpIcon name='pdf-fill' />
                )}
              </div>
            )}
            {type === 'credit' && attachment?.imgUrl && (
              <Image
                src={attachment.imgUrl}
                width={31.5}
                height={48}
                alt='credit Pic'
                style={{ borderRadius: '4px' }}
              />
            )}
          </div>

          <div>
            {Boolean(type === 'creative' || type === 'credit') &&
              (attachment as IAttachmentCredit).description?.map(
                (desc, index: number) => (
                  <React.Fragment key={desc ?? index}>
                    <Typography
                      key={desc ?? index}
                      variant='p-small-d'
                      className={`${index === 0 ? 'small-sb' : ''}`}
                      lineClampNo={index === 0 ? 1 : 2}
                      title={desc}
                      trimNumber={
                        index === 0
                          ? ATTACHMENT_DESCRIPTION_INDEX_FIRST_MAX_LENGTH
                          : ATTACHMENT_DESCRIPTION_INDEX_SECOND_MAX_LENGTH
                      }
                    >
                      {getDesc(desc, String(searchText), type)}
                    </Typography>
                  </React.Fragment>
                ),
              )}
            {type === 'default' &&
              typeof attachment.description === 'object' && (
                <Typography variant='p-small-d'>
                  <>
                    {attachment.description.icon && (
                      <HusslUpIcon name={attachment.description.icon} />
                    )}
                    {attachment.description.label}
                  </>
                </Typography>
              )}
            {type === 'default' &&
              typeof attachment.description === 'string' && (
                <Typography
                  variant='s-sb'
                  className='bio-desc'
                  title={attachment.description}
                  trimNumber={DEFAULT_ATTACHMENT_DESCRIPTION_MAX_LENGTH}
                >
                  {keyName === 'memberBios'
                    ? highlightText(attachment.description, searchText ?? '')
                    : attachment.description}
                </Typography>
              )}
            {type === 'connections' && (
              <MemberTile src={attachment.connection} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attachment;
