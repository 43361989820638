import { baseApiSlice } from 'commons/apis/base-api.config';
import { API_SEARCH_CONNECTIONS } from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
import { formatYearsForCredit } from 'utils/date-time';
import { CreditCardProps } from 'components/Profile/Credits/CreditCard';
import { UserProfileCardProps } from 'components/Core/UserProfileCard/UserProfileCard';
import { MemberChickletType } from 'components/Chicklet';
import { ConnectionLabel } from './types';
import {
  ConnectionResp,
  IConnection,
  IConnectionTransformedResponse,
  IProfileInfo,
  IUserCreditResp,
  IUserCredits,
} from './profile/types';
import { UserResumeType } from './resume/types';
import { transformCreativeSamplesAddPublishStatus } from './creative/transformResponse.api';
import {
  CreativeSampleCardProps,
  CreativeTokenResponse,
} from './creative/types';

export interface User {
  id: number;
  display: string;
  email?: string;
}

export interface UserConnection {
  husslupId: string;
  id: number;
  fullname: string;
  profileImg: string;
  display: string;
}

export const usersApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getUsersExceptLoggedInUser: builder.query<User[], string | void>({
        query: (query) => `/users/except-signed-user?q=${query}&per_page=10`,

        transformResponse: (data: any) => {
          const transformedData = data.map((row: any) => ({
            id: row.id,
            display: row.email || 'email empty',
          }));

          return transformedData;
        },
      }),

      /**
       * ************************************************************************************
       * ********************************* Profile APIS ***************************************
       * ************************************************************************************
       */
      getUsersInfo: builder.query<
        IProfileInfo,
        {
          userId: string | undefined;
          isLoggedIn: boolean;
        }
      >({
        query: ({ userId, isLoggedIn }) =>
          isLoggedIn
            ? `users/web/info/with-token/${userId}`
            : `users/web/info/without-token/${userId}`,
      }),
      getUsersConnectionsWithToken: builder.query<
        IConnectionTransformedResponse,
        {
          userId: string | undefined;
          isLoggedIn: boolean;
        }
      >({
        query: ({ userId, isLoggedIn }) =>
          isLoggedIn
            ? `users/web/connections/with-token/${userId}`
            : `users/web/connections/without-token/${userId}`,
        transformResponse: (
          data: ConnectionResp,
        ): IConnectionTransformedResponse => {
          const networkArray: UserProfileCardProps[] = [];
          const representationArray: UserProfileCardProps[] = [];
          data?.connections?.map((connection: IConnection) => {
            networkArray.push({
              id: connection?.connectionUser?.id,
              imgUrl: connection?.connectionUser?.profile_img,
              fullName: connection?.connectionUser?.fullname,
              professionalTagline:
                connection?.connectionUser?.professionalTagline,
              projectTagline: connection?.connectionUser?.projectTagline,
              tier: connection?.connectionUser?.tier as MemberChickletType,
              label: connection.label,
              isOutsideTalent: connection?.connectionUser?.isOutsideTalent,
            });
            if (connection.label === ConnectionLabel.REPRESENTATION) {
              return representationArray.push({
                imgUrl: connection?.connectionUser?.profile_img,
                id: connection?.connectionUser?.id,
                fullName: connection?.connectionUser?.fullname,
                professionalTagline:
                  connection?.connectionUser?.professionalTagline,
                projectTagline: connection?.connectionUser?.projectTagline,
                tier: connection?.connectionUser?.tier as MemberChickletType,
                isOutsideTalent: connection?.connectionUser?.isOutsideTalent,
              });
            }
            return null;
          });
          return {
            network: networkArray,
            representation: representationArray,
          };
        },
      }),
      getUsersCreditsWithToken: builder.query<
        CreditCardProps[],
        {
          userId: string | undefined;
          isLoggedIn: boolean;
        }
      >({
        query: ({ userId, isLoggedIn }) =>
          isLoggedIn
            ? `users/web/credits/with-token/${userId}`
            : `users/web/credits/without-token/${userId}`,
        transformResponse: (data: IUserCreditResp): CreditCardProps[] =>
          data?.userCredits?.map((credit: IUserCredits) => ({
            id: credit.id,
            creditImg: credit?.creditImg,
            projectTitle: credit.projectTitle,
            credit: credit.credit,
            seasons: credit.seasons,
            years: credit?.years ? formatYearsForCredit(credit?.years) : '',
            network: credit.network,
            order: credit.order,
            credit_project: credit.credit_project,
          })),
      }),
      getUsersResumeWithToken: builder.query<
        UserResumeType[],
        {
          userId: string | undefined;
          isLoggedIn: boolean;
        }
      >({
        query: ({ userId, isLoggedIn }) =>
          isLoggedIn
            ? `users/web/resume/with-token/${userId}`
            : `users/web/resume/without-token/${userId}`,
        transformResponse: (data: UserResumeType[]): UserResumeType[] =>
          data?.map((resume: UserResumeType) => ({
            id: resume?.id,
            resumeImg: resume?.resumeImg,
            position: resume?.position,
            companyName: resume?.companyName,
            location: resume?.location,
            description: resume?.description,
            startMonth: resume?.startMonth,
            startYear: resume?.startYear,
            endMonth: resume?.endMonth,
            endYear: resume?.endYear,
            order: resume?.order,
            currentlyWorking: resume?.currentlyWorking,
          })),
      }),
      getUsersCreativesWithToken: builder.query<
        CreativeSampleCardProps[],
        {
          userId: string | undefined;
          isLoggedIn: boolean;
        }
      >({
        query: ({ userId, isLoggedIn }) =>
          isLoggedIn
            ? `users/web/creatives/with-token/${userId}`
            : `users/web/creatives/without-token/${userId}`,
        transformResponse: (data: CreativeTokenResponse) =>
          transformCreativeSamplesAddPublishStatus(data?.creatives),
      }),
      /**
       * ************************************************************************************
       * ********************************* End Profile APIS ***************************************
       * ************************************************************************************
       */

      searchConnections: builder.query<UserConnection[], any>({
        query(payload) {
          const { limit, page, searchText } = payload;
          return {
            url: API_SEARCH_CONNECTIONS,
            method: HTTP_METHODS.GET,
            params: {
              limit,
              page,
              searchText,
              toExcludeConnectionIds: true,
            },
          };
        },
        transformResponse: (data: { data: { items: UserConnection[] } }) => {
          const transformedData = data?.data?.items;

          return transformedData;
        },
      }),
      getUserByHusslupId: builder.query<any, number | string>({
        query: (husslupId) => `users/husslup-id/${husslupId}`,
      }),
    };
  },
});

export const {
  useGetUsersExceptLoggedInUserQuery,
  useSearchConnectionsQuery,
  useLazySearchConnectionsQuery,
  useLazyGetUsersExceptLoggedInUserQuery,
  // User Info
  useLazyGetUsersInfoQuery,
  // Connections
  useLazyGetUsersConnectionsWithTokenQuery,
  // Credits
  useLazyGetUsersCreditsWithTokenQuery,
  // Creatives
  useLazyGetUsersResumeWithTokenQuery,
  // Resume
  useLazyGetUsersCreativesWithTokenQuery,
  useLazyGetUserByHusslupIdQuery,
  useGetUserByHusslupIdQuery,
} = usersApiSlice;
