import { PostTag } from 'commons/types/post.type';
import SquircleImage from 'components/Core/SquircleImage/SquircleImage';
import { CompanyProfilePlaceHolderV2 } from 'components/Placeholders';
import { FunctionComponent, useMemo } from 'react';
import { getS3ProfileUrl } from 'utils/file';
import ProfileUtils from 'utils/profile';
import { PostAsType, PostCreatorEntities } from './CreatePostModal';

const CreatePostAsProfile: FunctionComponent<{
  postAs: PostAsType;
  creator: { [key: string]: any };
  selectedTag?: PostTag;
  onTagUnselected: () => void;
}> = ({ postAs, creator, selectedTag, onTagUnselected }) => {
  const actorName =
    postAs === PostCreatorEntities.COMPANY
      ? creator?.company_name
      : creator?.fullname;

  const companyLogo = useMemo(
    () =>
      creator?.logo
        ? ProfileUtils.getProfileImage(
            ProfileUtils.getImageFileFromAwsUrl(creator.logo),
            56,
            56,
          )
        : CompanyProfilePlaceHolderV2,
    [creator?.logo],
  );
  const imageUrl =
    postAs === PostCreatorEntities.COMPANY
      ? companyLogo
      : getS3ProfileUrl(creator?.profile_img, 56, 56);
  return (
    <div className='profile'>
      <SquircleImage
        r1={0.04}
        r2={0.5}
        width={56}
        height={56}
        imgUrl={imageUrl}
        alt='pp'
      />
      <div>
        <div className='p-lg p-lg-b'>{actorName}</div>
        {selectedTag && (
          <div className='post-tag post-tag__close'>
            <div className='post-tag__name'> {selectedTag.tag}</div>
            <div
              className='btn-close'
              onClick={onTagUnselected}
              onKeyDown={onTagUnselected}
              role='button'
              aria-hidden='true'
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreatePostAsProfile;
