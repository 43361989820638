import { PaymentIntent } from '@stripe/stripe-js';
import { AssessmentStatus, ScriptType } from 'store/user/creative/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum STRIPE_PRODUCT_IDENTIFIER {
  FSA_ONE_TIME_TIER_1 = 'FSA_ONE_TIME_TIER_1',
  FSA_ONE_TIME_TIER_2 = 'FSA_ONE_TIME_TIER_2',
}

export type AssessmentScriptBody = {
  userCreativeSample: number;
  paymentIntentId: string;
};

export type GetAssessmentStatusPayload = {
  assetId: number;
  creativeSampleId: number;
};

export type GetAssessmentStatusResponse = {
  status: AssessmentStatus;
};

export type FSACreatePaymentBody = {
  amount: number;
  couponCode?: string;
  currency?: string;
  assetId: number;
  creativeSampleId: number;
  scriptType: ScriptType;
  fullname?: string;
  email?: string;
  paymentMethod?: string;
  address?: string;
  productIdentifier: STRIPE_PRODUCT_IDENTIFIER;
};

export type FSACreatePaymentResponse = {
  clientSecret: {
    amount: number;
    client_secret: string;
    amount_capturable: number;
    amount_received: 0;
    application: null;
    application_fee_amount: null;
    canceled_at: null;
    cancellation_reason: null;
    capture_method: string;
    confirmation_method: string;
    currency: string;
    id: string;
  };
  paymentIntent: PaymentIntent;
};

export type StripeProductPrices = {
  id: number;
  name: string;
  identifier: STRIPE_PRODUCT_IDENTIFIER;
  productId: string;
  priceId: string;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type StripePrice = {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount: any;
  livemode: boolean;
  lookup_key: any;
  metadata: any;
  nickname: string;
  product: string;
  recurring: any;
  tax_behavior: string;
  tiers_mode: any;
  transform_quantity: any;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
};

export type GetFSAPriceBody = {
  pageCount: number;
};

export type GetFSAPriceResponse = {
  stripeProductPrice: StripeProductPrices;
  price: StripePrice;
};

export type ListFSAPriceResponse = {
  prices: GetFSAPriceResponse[];
};
