import config from 'config';
import { DefaultGenerics, StreamChat } from 'stream-chat';

class GetStreamInitializer {
  private static instance: StreamChat<DefaultGenerics> | undefined = undefined;

  static getClient() {
    if (GetStreamInitializer.instance === undefined && config.getStream.apiKey)
      GetStreamInitializer.instance = StreamChat.getInstance(config.getStream.apiKey);
    return GetStreamInitializer.instance;
  }
}

export default GetStreamInitializer;
