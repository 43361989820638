import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreativePortfolioSliceState } from './creative-portfolio.types';

export const CREATIVE_PORTFOLIO_SLICE_KEY = 'creative-portfolio';

const initialState: CreativePortfolioSliceState = {};

export const creativePortfolioSlice = createSlice({
  name: CREATIVE_PORTFOLIO_SLICE_KEY,
  initialState,
  reducers: {
    setUploadProgress(state, action: PayloadAction<number>) {
      if (state.assetUpload) {
        state.assetUpload.progress = action.payload;
      } else {
        state.assetUpload = { progress: action.payload };
      }
    },
    clearAssetUpload(state) {
      state.assetUpload = undefined;
    },
  },
});

export const { setUploadProgress, clearAssetUpload } =
  creativePortfolioSlice.actions;

export const creativePortfolioReducer = creativePortfolioSlice.reducer;
