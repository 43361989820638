import React, { FunctionComponent, ReactElement } from 'react';
import { ChickletSize } from '.';

interface ChickLetProps {
  label: string;
  variant: 'mine' | 'shared' | 'sample' | 'archived';
  size?: ChickletSize;
  className?: string;
}

const ChickLet: FunctionComponent<ChickLetProps> = ({
  label,
  variant,
  size,
  className,
}): ReactElement => {
  const getBadgeVariantClass = (): string => {
    if (variant === 'mine') return 'badge-mine';
    if (variant === 'shared') return 'badge-shared';
    return '';
  };
  return (
    <div
      className={`badge ${getBadgeVariantClass()} ${size} ${className || ''}`}
    >
      {label}
    </div>
  );
};

export default ChickLet;
