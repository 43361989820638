import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'config';

export const STRAPI_API_SLICE = 'strapiApi';

const prepareBaseQuery = fetchBaseQuery({
  baseUrl: config.strapi.baseUrl,
  prepareHeaders(headers) {
    if (config.strapi.apiToken) {
      headers.set('authorization', `Bearer ${config.strapi.apiToken}`);
    }
    return headers;
  },
});

export const strapiApiSlice = createApi({
  reducerPath: STRAPI_API_SLICE,
  baseQuery: prepareBaseQuery,
  endpoints: () => ({}),
  tagTypes: [],
});
