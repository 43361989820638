import { STRIPE_PRODUCT_IDENTIFIER } from 'store/assessment-script/assessment-script.types';
import { GREENLIGHT_SUITE_ASSESSMENT_RESULT_ROUTE } from './routes';

export const ASSESSMENT_PACKAGE_PRICING: any = {
  // 'Story + Character Assessment': [
  //   { min: 0, max: 35, price: 14.99 },
  //   { min: 36, max: 65, price: 29.99 },
  //   { min: 66, max: 120, price: 59.99 },
  // ],
  'Full Script Assessment': [
    { min: 0, max: 35, price: 15.99 },
    { min: 36, max: 65, price: 29.99 },
    { min: 66, max: 120, price: 59.99 },
  ],
};

/** @deprecated */
export const ASSESSMENT_PAGE_PRICING = [
  { min: 1, max: 120, price: 37.0 },
  { min: 121, max: 200, price: 52.0 },
];

export const API_ASSESSMENT_SCRIPT = 'assessment-script';

export const ADD_CREATIVE_PORTFOLIO_MODE = 'Add';
export const EDIT_CREATIVE_PORTFOLIO_MODE = 'Edit';

export const ASSESSMENT_RESULT_URL = GREENLIGHT_SUITE_ASSESSMENT_RESULT_ROUTE;

export const ASSESSMENT_PAGE_PRICING_IDENTIFIER = [
  {
    min: 1,
    max: 120,
    price: 37.0,
    identifier: STRIPE_PRODUCT_IDENTIFIER.FSA_ONE_TIME_TIER_1,
  },
  {
    min: 121,
    max: 200,
    price: 52.0,
    identifier: STRIPE_PRODUCT_IDENTIFIER.FSA_ONE_TIME_TIER_2,
  },
];

export const getIdentifierForPageCount = (pageCount: number) => {
  const pricing = ASSESSMENT_PAGE_PRICING_IDENTIFIER;

  const priceRange = pricing.find(
    ({ min, max }: any) => pageCount >= min && pageCount <= max,
  );

  return priceRange?.identifier;
};

export const getFormattedPrice = (unitAmount: number) => {
  if (unitAmount && typeof unitAmount === 'number') {
    return parseFloat((unitAmount / 100).toFixed(2));
  }
  return unitAmount;
};
