import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PRO_SEARCH_TILES_SIZE } from 'constants/commons';
import {
  Criterias,
  ProfileListType,
  ProfileListTypeKey,
  ProsearchDetailsPageStateSliceState,
  ProSearchSliceState,
} from './prosearch.types';

export const PRO_SEARCH_SLICE_KEY = 'creative-portfolio';
export const PRO_SEARCH_DETAIL_PAGE_STATE_SLICE_KEY = 'prosearchesDetailsPage';
const initialState: ProSearchSliceState = {};
const prosearchdetailPageInitialState: ProsearchDetailsPageStateSliceState = {
  criteriaAccordion: [],
  pagination: {
    page: 0,
    totalPages: 0,
  },
  facetedAccordionsData: [],
};

export const prosearchDetailStateSlice = createSlice({
  name: PRO_SEARCH_DETAIL_PAGE_STATE_SLICE_KEY,
  initialState: prosearchdetailPageInitialState,
  reducers: {
    setCriterias(state, action: PayloadAction<{ criterias: Criterias[] }>) {
      state.criterias = action?.payload?.criterias || [];
    },
    updateCriteriaAccordian(
      state,
      action: PayloadAction<{
        key: ProfileListTypeKey;
        options: ProfileListType[];
      }>,
    ) {
      state.criteriaAccordion = [
        ...state?.criteriaAccordion,
        { [action.payload.key]: action.payload.options } as any,
      ];
    },
    setInitialState:(
      state
    ) =>({ ...state,
        criterias:  [],
        filteredUsers: [],
        dropdownLabel: 'Best Match',
        accordionOpening:  false,
        criteriaAccordion: [],
        pagination: {
          page: 0,
          totalPages: 0,
        },
        facetedAccordionsData: []
      }),
    updateTotalPage: (state, action: PayloadAction<number>) => {
     state.pagination.totalPages = Math.ceil(
        action.payload / PRO_SEARCH_TILES_SIZE,
      );
    },
    updateCurrentPage: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload;
    },
    setFilteredUsers(
      state,
      action: PayloadAction<{ filteredUsers?: Array<any> }>,
    ) {
      state.filteredUsers = action?.payload?.filteredUsers as [];
    },
    setDropdownLabel(state, action: PayloadAction<{ dropdownLabel: string }>) {
      state.dropdownLabel = action.payload.dropdownLabel || 'Best Match';
    },
    setAccordionOpening(
      state,
      action: PayloadAction<{ accordionOpening: any }>,
    ) {
      state.accordionOpening = action.payload.accordionOpening;
    },

    setFacetedAccordionData(state, action: PayloadAction<{usersList: Array<any>, pagination: {from: number, size: number}, accordionOpen: boolean}>) {
      state.facetedAccordionsData.push(action.payload);
    },
    resetFacetedAccordionState(state){
        state.facetedAccordionsData = [];
    },
    setFacetedAccordionUsersListAndPagination(state, action: PayloadAction<{usersList: Array<any>, pagination: {from: number, size: number}, accordionOpen: boolean,index: number}>) {
      const facetedIndex: number = action.payload.index;
      state.facetedAccordionsData[facetedIndex] = {usersList: action.payload.usersList, pagination: action.payload.pagination, accordionOpen: action.payload.accordionOpen}
      return state;
    }
  },
});

export const proSearchSlice = createSlice({
  name: PRO_SEARCH_SLICE_KEY,
  initialState,
  reducers: {
    setUploadProgress(
      state,
      action: PayloadAction<{
        progress: number;
        process: 'uploading' | 'success';
      }>,
    ) {
      if (state.assetUpload) {
        if (action.payload.process === 'uploading') {
          state.assetUpload.progress =
            action.payload.progress > 80 ? 80 : action.payload.progress;
        } else if (action.payload.process === 'success') {
          state.assetUpload.progress = action.payload.progress;
        }
      } else if (action.payload.process === 'uploading') {
        state.assetUpload = {
          progress: action.payload.progress > 80 ? 80 : action.payload.progress,
        };
      } else if (action.payload.process === 'success') {
        state.assetUpload = { progress: action.payload.progress };
      }
    },
    clearAssetUpload(state) {
      state.assetUpload = undefined;
    },
  },
});

export const { setUploadProgress, clearAssetUpload } = proSearchSlice.actions;
export const {
  setInitialState,
  setCriterias,
  setFilteredUsers,
  updateCriteriaAccordian,
  updateCurrentPage,
  updateTotalPage,
  setFacetedAccordionData,
  setFacetedAccordionUsersListAndPagination,
  resetFacetedAccordionState
} = prosearchDetailStateSlice.actions;
export const creativePortfolioReducer = proSearchSlice.reducer;
export default prosearchDetailStateSlice;
