import { MuxVideo, PostMedia } from 'commons/types/post.type';
import NextImage from 'components/Core/Image';
import { FunctionComponent, MouseEvent } from 'react';
import { CloseButton, ProgressBar } from 'react-bootstrap';
import { formatBytes } from 'utils/file';
import useMuxUploadVideo from 'commons/hooks/useMuxVideoUpload';
import HusslUpIcon from 'components/Icon';
import PostVideoPlayer from '../Posts/PostVideoPlayer';

const EditPostFileUploads: FunctionComponent<{
  uploadedImage?: File | PostMedia;
  uploadedDoc?: File | PostMedia;
  uploadedVideo?: MuxVideo | File;
  onUnlinkImage?: (event: MouseEvent<HTMLButtonElement>) => void;
  onUnlinkDoc?: (event: MouseEvent<HTMLButtonElement>) => void;
  onUnlinkVideo?: (event: MouseEvent<HTMLButtonElement>) => void;
}> = ({
  uploadedImage,
  uploadedDoc,
  uploadedVideo,
  onUnlinkImage,
  onUnlinkDoc,
  onUnlinkVideo,
}) => {
  const isUploadedVideoIsFile = uploadedVideo instanceof File;
  const isUploadedImageIsFile = uploadedImage instanceof File;
  const isUploadedDocIsFile = uploadedDoc instanceof File;
  const { muxVideoUploadStatus, uploadProgress } =
    useMuxUploadVideo(uploadedVideo);
  return (
    <>
      {uploadedImage && (
        <div className='image-uploads'>
          <NextImage
            src={
              isUploadedImageIsFile
                ? URL.createObjectURL(uploadedImage)
                : (uploadedImage?.url as string)
            }
            width={230}
            height={165}
            alt='Uploaded Picture'
          />
          <div className='remove'>
            <CloseButton variant='white' onClick={onUnlinkImage} />
          </div>
        </div>
      )}
      {uploadedDoc && (
        <div className='pdf-block pdf-block--remove'>
          <div className='icon'>
            <i className='hic-pdf-fill hic-3x text-primary' />
          </div>
          <div>
            <div className='p-md p-md-sb'>
              {isUploadedDocIsFile
                ? uploadedDoc?.name
                : uploadedDoc?.filename!.substring(
                    uploadedDoc?.filename!.indexOf('_') + 1,
                  )}
            </div>
            <div className='p-sm'>
              {formatBytes(isUploadedDocIsFile ? uploadedDoc?.size : 0)}
            </div>
          </div>
          <CloseButton onClick={onUnlinkDoc} />
        </div>
      )}
      {uploadedVideo && (
        <>
          {muxVideoUploadStatus === 'success' ? (
            <div className='image-uploads video-frame'>
              {isUploadedVideoIsFile && (
                <video width={230} muted controls>
                  <source
                    src={URL.createObjectURL(uploadedVideo)}
                    type='video/mp4'
                  />
                  Your browser does not support the video tag.
                </video>
              )}

              {!isUploadedVideoIsFile && (
                <PostVideoPlayer muxVideo={uploadedVideo as MuxVideo} />
              )}
              <div className='remove'>
                <CloseButton variant='white' onClick={onUnlinkVideo} />
              </div>
            </div>
          ) : (
            <div className='video-uploads'>
              <div className='w-100 d-flex align-items-center flex-column'>
                <HusslUpIcon
                  name='video'
                  wrapperClass='text-primary'
                  variant='2x'
                />
                <ProgressBar now={uploadProgress} />
              </div>
              <div className='remove'>
                <CloseButton />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EditPostFileUploads;
