import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'commons/hooks/redux';
import StaffingCard from 'components/Core/StaffingCard/StaffingCard';
import Typography from 'components/Core/StylesGuide/Typography';
import { MY_NETWORK_CONTACT_LISTS } from 'constants/routes';
import Link from 'next/link';
import { Spinner } from 'react-bootstrap';
import { selectCurrentUserId } from 'store/auth/selectors';
import { useGetAllContactListsQuery } from 'store/contact/contact.api';
import { updateListStatus } from 'store/contact/contact.slice';

export const HomeContactLists = () => {
  const { data, isLoading, isFetching } = useGetAllContactListsQuery({
    key: 'all',
    sortBy: 'recent',
  });
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const handleStatus = useCallback(
    (status: string) => {
      dispatch(
        updateListStatus({
          status,
        }),
      );
    },
    [dispatch],
  );
  if (isLoading || isFetching) {
    return (
      <div className='d-flex justify-content-center pt-3'>
        <Spinner />
      </div>
    );
  }

  if (!data || data?.length < 1) {
    return (
      <div
        className='d-flex align-items-center justify-content-center pt-1'
        style={{
          minHeight: 110,
        }}
      >
        <Typography variant='p-md-d' className='text-center'>
          <>
            Manage a list of members.
            <br /> Create your first Contact List today.
          </>
        </Typography>
      </div>
    );
  }

  return data?.length > 0 ? (
    <div className='d-flex align-items-center flex-column home-contact__List'>
      {data?.slice(0, 2).map((contactList) => (
        <StaffingCard
          key={contactList.owner?.id}
          label={contactList.title}
          description={contactList.desc}
          status={contactList.isShared ? 'open' : 'archived'}
          isMine={contactList.owner?.id === currentUserId}
          lastEdited={contactList.updatedAt as any}
          sharedBy={contactList?.owner?.fullname}
          href={`${MY_NETWORK_CONTACT_LISTS}/${contactList?.id}`}
          handleStatus={handleStatus}
        />
      ))}
      <div className='d-flex justify-content-end w-100'>
        <Link href={MY_NETWORK_CONTACT_LISTS} className='link'>
          View All
        </Link>
      </div>
    </div>
  ) : (
    <Typography variant='p-sm-d'>
      Any member can create a contact list to manage groups of HUSSLUP members.
    </Typography>
  );
};

export default HomeContactLists;
