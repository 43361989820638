import { SendConnectionResponse } from 'commons/types/network.type';
import { ActionButtons } from 'components/Cards';
import { MESSAGES } from 'constants/routes';
import { useRouter } from 'next/router';
import { useSendConnectionMutation } from 'store/discover/discover.api';
import { ConnectionsActionParams } from 'store/my-network';
import {
  useAcceptConnectionMutation,
  useRemoveConnectionMutation,
} from 'store/my-network/update-connection.api';
import { ConnectionStatus } from 'store/user/types';
import ToasterUtils from 'utils/toaster';
import useGetStreamClient from 'components/Messages/hooks/useGetStreamClient';
import { User } from 'store/messages/messages.slice';
import ProfileUtils from 'utils/profile';
import asyncSetMessageScreen from 'store/messages/thunk/asyncSetMessageScreen';
import {
  USER_ACCEPTED_A_CONNECTION,
  USER_IGNORED_A_CONNECTION,
} from 'constants/gt-classes';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../redux';
import { selectCurrentUser } from '../../../store/auth/selectors';

type ConnectionActionButtonHookProp = {
  initiateDeleteConnection: (params: ConnectionsActionParams) => void;
};

export const useConnectionActionButtons = ({
  initiateDeleteConnection,
}: ConnectionActionButtonHookProp) => {
  const router = useRouter();
  const { t } = useTranslation('my_network');
  const { t: globalT } = useTranslation('global');
  const currentUser = useAppSelector(selectCurrentUser);
  const getStreamClient = useGetStreamClient();
  const appDispatch = useAppDispatch();

  const navigateToCreateMessage = async (sendTo: User) => {
    const sentBy: User = {
      id: currentUser.husslupId,
      image: ProfileUtils.getProfileImage(currentUser.profile_img),
      fullName: currentUser.fullname,
    };

    appDispatch(
      asyncSetMessageScreen({
        sendTo,
        sentBy,
        chatClient: getStreamClient ?? undefined,
      }),
    );
    router.push({
      pathname: MESSAGES,
    });
  };

  const [
    acceptConnection,
    { originalArgs: acceptConnectionArgs, isLoading: isAcceptingConnection },
  ] = useAcceptConnectionMutation();

  const [
    removeConnection,
    { originalArgs: removeConnectionArgs, isLoading: isRemovingConnection },
  ] = useRemoveConnectionMutation();

  const [
    sendConnection,
    { originalArgs: sendConnectionArgs, isLoading: isSendingConnection },
  ] = useSendConnectionMutation();

  const handleRemoveConnection = (husslupId?: string) => async () => {
    if (husslupId) {
      const response = await removeConnection({
        husslupId,
      }).unwrap();
      if (response.affected > 0) {
        ToasterUtils.successToast(t('CONNECTION_REMOVED_SUCCESS.value'), {
          hideCloseButton: true,
        });
      } else {
        ToasterUtils.errorToast(t('CONNECT_REQUEST_DECLINED_ERROR.value'));
      }
    }
  };

  const handleAcceptConnection =
    (husslupId?: string, fullName?: string) => async () => {
      if (husslupId) {
        const response = await acceptConnection({
          connectionHusslupId: husslupId,
        }).unwrap();
        // TODO: need change in api
        if (response.affected > 0) {
          ToasterUtils.successToast(
            t('CONNECT_REQUEST_ACCEPTED_SUCCESS.value', {
              memberId: fullName,
            }),
            {
              hideCloseButton: true,
            },
          );
        } else {
          ToasterUtils.errorToast(t('CONNECT_REQUEST_ACCEPTED_ERROR.value'));
        }
      }
    };

  const handleSendConnection = (husslupId?: string) => async () => {
    if (husslupId) {
      const response = (await sendConnection({
        connectionHusslupId: husslupId,
      }).unwrap()) as SendConnectionResponse;
      if (response?.generatedMaps?.length > 0) {
        ToasterUtils.successToast(globalT('CONNECTION_REQUEST_SENT.value'), {
          hideCloseButton: true,
        });
      } else {
        ToasterUtils.errorToast(t('CONNECT_REQUEST_SENT_ERROR.value'));
      }
    }
  };

  const getConnectionActionButtons = (
    connectionStatus: number | null | undefined,
    husslupId?: string,
    fullname?: string,
    profileImage?: string,
  ): ActionButtons[] | undefined => {
    if (
      !connectionStatus ||
      Number(connectionStatus) === ConnectionStatus.NONE
    ) {
      const isCurrentConnectionBeingSent =
        sendConnectionArgs?.connectionHusslupId === husslupId &&
        isSendingConnection;

      return [
        {
          onClick: handleSendConnection(husslupId),
          icon: 'connect',
          label: 'Connect',
          disabled: isCurrentConnectionBeingSent,
          loading: isCurrentConnectionBeingSent,
        },
      ];
    }

    if (Number(connectionStatus) === ConnectionStatus.PENDING) {
      return [
        {
          onClick: () =>
            initiateDeleteConnection({
              action: 'remove-connection',
              userIdToRemoveConnection: husslupId,
              removingConnectionType: 'pending',
            }),
          icon: 'connect',
          label: 'Pending',
        },
      ];
    }

    if (Number(connectionStatus) === ConnectionStatus.ACCEPTED) {
      return [
        {
          onClick: () => {
            if (husslupId && fullname) {
              navigateToCreateMessage({
                id: husslupId,
                fullName: fullname,
                image: ProfileUtils.getProfileImage(profileImage ?? '') ?? '',
              });
            }
          },
          icon: 'sended',
          label: 'Message',
        },
      ];
    }

    if (Number(connectionStatus) === ConnectionStatus.REQUESTED) {
      const isCurrentConnectionGettingAccepted =
        acceptConnectionArgs?.connectionHusslupId === husslupId &&
        isAcceptingConnection;

      const isCurrentConnectionGettingRemoved =
        removeConnectionArgs?.husslupId === husslupId && isRemovingConnection;

      const needToDisable =
        isCurrentConnectionGettingAccepted || isCurrentConnectionGettingRemoved;

      return [
        {
          onClick: handleRemoveConnection(husslupId),
          icon: 'close',
          disabled: needToDisable,
          loading: isCurrentConnectionGettingRemoved,
          wrapperClass: USER_IGNORED_A_CONNECTION,
        },
        {
          onClick: handleAcceptConnection(husslupId, fullname),
          icon: 'check',
          disabled: needToDisable,
          loading: isCurrentConnectionGettingAccepted,
          wrapperClass: USER_ACCEPTED_A_CONNECTION,
        },
      ];
    }
    return undefined;
  };

  return { getConnectionActionButtons };
};

export default useConnectionActionButtons;
