import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  API_SUBSCRIPTION_MEMBER_ASSIGN_SEAT,
  API_SUBSCRIPTION_MEMBER_CHANGE_OWNERSHIP,
  API_SUBSCRIPTION_REMOVE_MEMBER_SEAT,
  API_USER_SUBSCRIPTIONS_HAS_SUBSCRIPTION_CHECK,
  API_USER_SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_NAME,
  SEARCH_USERS,
  SUBSCRIPE,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
import {
  AssignSubscriptionMemberSeatBody,
  GetAllUsersExceptOwnerBody,
  GetAllUsersExceptOwnerResponse,
  RemoveSubscriptionMemberSeatBody,
  SubscriptionChangeOwnershipParameter,
  SubscriptionChangeOwnershipResponse,
  SubscriptionMember,
  UpdateSubscriptionNameBody,
  UpdateSubscriptionNameResponse,
} from './subscription.types';

export const subscriptionApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      subscripe: builder.mutation<boolean, any>({
        query(id: string) {
          return {
            url: `${SUBSCRIPE}/${id}`,
            method: HTTP_METHODS.PATCH,
          };
        },
        // invalidatesTags: ['CurrentAuthUser'],
      }),
      getUsersExceptOwner: builder.query<
        GetAllUsersExceptOwnerResponse[],
        GetAllUsersExceptOwnerBody
      >({
        query: (payload) => {
          const { searchText, subscriptionId } = payload;
          return {
            url: `${SEARCH_USERS}/subscription/${subscriptionId}/getAllUsersExceptOwner`,
            method: HTTP_METHODS.GET,
            params: {
              searchText,
            },
          };
        },
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      changeSubscriptionOwner: builder.mutation<
        SubscriptionChangeOwnershipResponse,
        SubscriptionChangeOwnershipParameter
      >({
        query(parameter) {
          return {
            url: `${API_SUBSCRIPTION_MEMBER_CHANGE_OWNERSHIP}/${parameter.subscriptionId}`,
            method: HTTP_METHODS.POST,
            body: parameter.body,
          };
        },
        invalidatesTags: [
          'GetUserSubscription',
          'AssignedSubscriptionSeats',
          'CurrentAuthUser',
        ],
      }),
      getSubscriptionInfo: builder.query<any, any>({
        query: (payload) => {
          const { subscriptionId } = payload;
          return {
            url: `/user-subscriptions/getSubscriptionInfo/${subscriptionId}`,
            method: HTTP_METHODS.GET,
            params: {
              // subscriptionId,
            },
          };
        },
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      getAssignedUsersBySubscription: builder.query<
        SubscriptionMember[],
        number
      >({
        query: (subscriptionId) => ({
          url: `/subscription-members/getAssignedUsersBySubscription/${subscriptionId}`,
          method: HTTP_METHODS.GET,
        }),
        providesTags: ['AssignedSubscriptionSeats'],
      }),
      updateSubscriptionName: builder.mutation<
        UpdateSubscriptionNameResponse,
        UpdateSubscriptionNameBody
      >({
        query: (payload) => ({
          url: `${API_USER_SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_NAME}/${payload.subscriptionId}`,
          method: HTTP_METHODS.PATCH,
          body: payload.body,
        }),
        invalidatesTags: ['GetUserSubscription'],
      }),
      assignSubscriptionMemberSeat: builder.mutation<
        SubscriptionMember[],
        AssignSubscriptionMemberSeatBody
      >({
        query: (payload) => ({
          url: `${API_SUBSCRIPTION_MEMBER_ASSIGN_SEAT}`,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
        invalidatesTags: [
          'AssignedSubscriptionSeats',
          'GetUserSubscription',
          'CurrentAuthUser',
        ],
      }),
      removeSubscriptionMemberSeat: builder.mutation<
        SubscriptionMember[],
        RemoveSubscriptionMemberSeatBody
      >({
        query: (payload) => ({
          url: `${API_SUBSCRIPTION_REMOVE_MEMBER_SEAT}/${payload.subscriptionId}/member/${payload.memberIdToRemove}`,
          method: HTTP_METHODS.DELETE,
        }),
        invalidatesTags: ['AssignedSubscriptionSeats', 'GetUserSubscription'],
      }),
      userHasSubscriptionBoolean: builder.query<any, any>({
        query: (userId) => ({
          url: `${API_USER_SUBSCRIPTIONS_HAS_SUBSCRIPTION_CHECK}/${userId}`,
        }),
      }),
    };
  },
});

export const {
  useUserHasSubscriptionBooleanQuery,
  useLazyUserHasSubscriptionBooleanQuery,
  useSubscripeMutation,
  useGetUsersExceptOwnerQuery,
  useLazyGetUsersExceptOwnerQuery,
  useChangeSubscriptionOwnerMutation,
  useGetSubscriptionInfoQuery,
  useLazyGetSubscriptionInfoQuery,
  useGetAssignedUsersBySubscriptionQuery,
  useUpdateSubscriptionNameMutation,
  useAssignSubscriptionMemberSeatMutation,
  useRemoveSubscriptionMemberSeatMutation,
} = subscriptionApiSlice;
