import { SVGProps } from 'commons/types/svg.type';

const StarIcon = ({
  height = 33,
  width = 35,
  color,
  borderColor = '#4C1E4F',
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 35 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <svg x='0' y='0' viewBox='0 0 35 33'>
      <path
        d='M16.6937 1.08358C17.0932 0.538885 17.9068 0.538883 18.3063 1.08358L23.8811 8.68326C24.0041 8.85102 24.1764 8.97622 24.374 9.04142L33.3244 11.9949C33.966 12.2066 34.2173 12.9803 33.8228 13.5286L28.3177 21.1789C28.1962 21.3478 28.1304 21.5504 28.1294 21.7584L28.0864 31.1835C28.0833 31.859 27.4251 32.3372 26.7817 32.1313L17.8047 29.2598C17.6065 29.1964 17.3935 29.1964 17.1953 29.2598L8.21829 32.1313C7.57487 32.3372 6.91672 31.859 6.91363 31.1835L6.87055 21.7584C6.8696 21.5504 6.80378 21.3478 6.68226 21.1789L1.17722 13.5286C0.782658 12.9803 1.03405 12.2066 1.67556 11.9949L10.626 9.04142C10.8236 8.97622 10.9959 8.85102 11.1189 8.68326L16.6937 1.08358Z'
        fill={color}
        stroke={borderColor}
        strokeWidth='1.5' // Adjust the thickness of the border as needed
      />
    </svg>
  </svg>
);
export default StarIcon;
