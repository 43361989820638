import { baseApiSlice } from 'commons/apis/base-api.config';
import { PostTag } from 'commons/types/post.type';
import { API_POST_TAGS } from 'constants/api';

export const POST_TAG_API_SLICE = 'postTagApi';

export const postTagApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getPostTags: builder.query<PostTag[], { screenRequesting?: string }>({
        query: ({ screenRequesting }) =>
          `${API_POST_TAGS}?screenRequesting=${screenRequesting || ''}`,

        transformResponse: (data: PostTag[]) => {
          const transformedData = data.map((row) => ({
            ...row,
            isActive: false,
          }));

          return [
            {
              id: '',
              isActive: true,
              show: 'true',
              tag: 'All',
              order: '0',
            },
            ...transformedData,
          ];
        },
      }),
    };
  },
});

export const { useGetPostTagsQuery } = postTagApiSlice;
