import {
  ActionButtons,
  MemberDropdownLabelType,
  memberCardDropDowns,
} from 'components/Cards';
import 'react-multi-carousel/lib/styles.css';
import {
  MemberChickletType,
  MemberChickletTypeDefault,
} from 'components/Chicklet';
import Typography from 'components/Core/StylesGuide/Typography';
import { DISCOVER_PEOPLE, MESSAGES, PROFILE } from 'constants/routes';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import {
  useGetRandomRecommendationQuery,
  useSendConnectionMutation,
} from 'store/discover/discover.api';
import useVisibility from 'commons/hooks/useVisibility';
import { useAppDispatch } from 'commons/hooks/redux';
import Carousel from 'react-multi-carousel';
import {
  addToModalState,
  titleAndLabelOfAddToModel,
  userInfo,
} from 'constants/basic-search.state';
import { MemberCardModalType } from 'utils/basic-faceted-modal.enum';
import handleCopyProfileLink from 'utils/profile-toast';
import AddToStaffingRoasterModal from 'components/StaffingRoster/Modals/AddToStaffingRoasterModal';
import ReportDialog from 'components/Core/Report';
import AddToModel from 'components/Core/AddToModel/addToModel';
import RecommendationCard from 'components/Cards/RecommendationCard';
import { updatePageFilter } from 'store/discover/pageFilters.slice';
import { RandomRecommendation } from 'store/discover/discover.types';
import DialogBox from 'components/Dialog';
import ToasterUtils from 'utils/toaster';
import { useTranslation } from 'react-i18next';
import { useRemoveConnectionMutation } from 'store/my-network/update-connection.api';
import { ConnectionStatus } from 'store/user/types';

const Recommendation = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { t: globalT } = useTranslation('global');

  const {
    hide: addToRoasterModalHide,
    show: addToRoasterModalShow,
    isVisible: addToRoasterModalIsVisible,
  } = useVisibility();
  const { hide, show, isVisible } = useVisibility();

  const [currentHussleUpId, setCurrentHusslupId] = useState('');

  const [memberCardCurrentUserInfo, setMemberCardCurrentUserInfo] =
    useState(userInfo);

  const [addToModalShow, setAddToModalSHow] = useState(addToModalState);

  const [selectedMemberId, setSelectedMemberId] = useState<number | null>(null);

  const [addToModelInfo, setTitleAndLabelOfAddToModel] = useState(
    titleAndLabelOfAddToModel,
  );

  const [sendConnection, connectResponse] = useSendConnectionMutation();

  const [removeConnection, { isLoading: isRemovingConnection }] =
    useRemoveConnectionMutation();

  const { data: recommendatedData, isLoading: peopleIsLoading } =
    useGetRandomRecommendationQuery({});

  const onCardClickHandler = (profileId: number): void => {
    router.push(`${PROFILE}/${profileId}`);
  };

  const onActionClicked = (id: string, label: string) => async () => {
    if (label === 'Connect') {
      await sendConnection({
        connectionHusslupId: id.toString(),
      });
    }

    if (label === 'Pending') {
      setCurrentHusslupId(id);
      show();
    }
  };

  const handleDropdownClick = async (
    label: string,
    id: number,
    husslupId?: string,
  ): Promise<void> => {
    setMemberCardCurrentUserInfo({
      ...memberCardCurrentUserInfo,
      userId: id,
      profileId: husslupId ?? '',
    });
    const dropdownLabel = label as MemberDropdownLabelType;

    switch (dropdownLabel) {
      case 'Report':
        setAddToModalSHow({ ...addToModalShow, showReportModal: true });
        break;
      case 'Add to Contact List':
        setTitleAndLabelOfAddToModel(MemberCardModalType.ADD_TO_CONTACT_TITLE);
        setAddToModalSHow({ ...addToModalShow, showAddToModel: true });
        break;
      case 'Add to ProSearch':
        setTitleAndLabelOfAddToModel(
          MemberCardModalType.ADD_TO_PROSEARCH_TITLE,
        );
        setAddToModalSHow({ ...addToModalShow, showAddToModel: true });
        break;
      case 'Add to Staffing Roster':
        setSelectedMemberId(id);
        addToRoasterModalShow();
        break;
      // eslint-disable-next-line
      case 'Copy Profile Link':
        handleCopyProfileLink(`${PROFILE}/${id}`);
        break;
      case 'Share':
        router.push(MESSAGES);
        break;
      case 'Message':
        router.push(MESSAGES);
        break;
      default:
        break;
    }
  };
  // eslint-disable-next-line arrow-body-style
  const memberCardDropDownsWithActions = memberCardDropDowns?.map((card) => {
    return {
      ...card,
      onClick: handleDropdownClick,
    };
  });

  const handleReportModalClose = (): void => {
    setAddToModalSHow({
      ...addToModalShow,
      showReportModal: false,
      showAddToModel: false,
    });

    setMemberCardCurrentUserInfo({
      ...memberCardCurrentUserInfo,
      profileId: '',
    });
  };

  const responsive = {
    desktop4: {
      breakpoint: { min: 2400, max: 3000 },
      items: 8,
      slidesToSlide: 2,
    },
    desktop3: {
      breakpoint: { min: 2300, max: 2399 },
      items: 7,
      slidesToSlide: 2,
    },
    desktop2: {
      breakpoint: { min: 2055, max: 2300 },
      items: 6,
      slidesToSlide: 2,
    },
    desktop1: {
      breakpoint: { min: 1924, max: 2055 },
      items: 5,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { min: 1024, max: 1924 },
      items: 3,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { min: 768, max: 1023 },
      items: 3,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { min: 0, max: 767 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  const clickedHandler = () => {
    dispatch(
      updatePageFilter({
        key: 'people',
        value: {
          page: 1,
          nextPage: false,
          sortBy: 'best-match',
        },
      }),
    );
    router.push(DISCOVER_PEOPLE);
  };

  const getActionButton = (
    connection: RandomRecommendation,
  ): ActionButtons[] => {
    if (connection.connectionStatus === ConnectionStatus.PENDING) {
      return [
        {
          onClick: onActionClicked(connection.husslupId, 'Pending'),
          icon: 'connect',
          label: 'Pending',
          wrapperClass: '',
        },
      ];
    }

    return [
      {
        onClick: onActionClicked(connection.husslupId, 'Connect'),
        icon: 'connect',
        loading:
          connectResponse.originalArgs?.connectionHusslupId ===
            connection.husslupId && connectResponse.isLoading,
        label: 'Connect',
        disabled: connectResponse.isLoading,
      },
    ];
  };

  const dialogBoxHandler = async () => {
    const response = await removeConnection({
      husslupId: currentHussleUpId,
    }).unwrap();
    if (response.affected > 0) {
      ToasterUtils.successToast(globalT('CANCEL_CONNECTION_REQUEST.value'), {
        hideCloseButton: true,
      });
      hide();
      setCurrentHusslupId('');
    }
  };

  return (
    <>
      {recommendatedData && recommendatedData.length > 0 && (
        <div className='recommendation'>
          <div className='recommendation-header'>
            <Typography variant='h2'>Connection Recommendations</Typography>
            <span onClick={clickedHandler} className='link'>
              View all
            </span>
          </div>

          <main className='recommendation-content'>
            {peopleIsLoading && (
              <section
                className='d-flex justify-content-center align-items-center mb-4'
                style={{ height: '492px' }}
              >
                <div className='spinner-border text-primary' role='status' />
              </section>
            )}
            {!peopleIsLoading && (
              <Carousel
                responsive={responsive}
                autoPlay={false}
                swipeable={true}
                draggable={false}
                showDots={false}
                itemClass='carousel-item-padding-40-px'
                // centerMode={true}
              >
                {recommendatedData.map((connection: RandomRecommendation) => (
                  <RecommendationCard
                    fillBackground
                    memberChickletStatus={
                      (connection.tier ||
                        MemberChickletTypeDefault) as MemberChickletType
                    }
                    onClick={() => onCardClickHandler(connection.id)}
                    key={connection.husslupId}
                    actionButtons={getActionButton(connection)}
                    // @ts-ignore
                    dropdown={memberCardDropDownsWithActions}
                    fullName={connection.fullname}
                    recommendationTitle={connection.title}
                    headings={
                      [
                        connection.professionalTagline,
                        connection.projectTagline,
                        connection.genreTagline,
                      ] as string[]
                    }
                    icons={[
                      {
                        label: 'verified',
                        icon: 'verified-fill',
                        color: 'text-awards',
                      },
                    ]}
                    profileImgUrl={connection.profile_img}
                    id={connection.id}
                    husslupId={connection.husslupId}
                  />
                ))}
              </Carousel>
            )}
          </main>

          <AddToStaffingRoasterModal
            handleHide={() => {
              addToRoasterModalHide();
              setSelectedMemberId(null);
            }}
            show={addToRoasterModalIsVisible}
            memberId={selectedMemberId}
          />
          <AddToModel
            show={addToModalShow.showAddToModel}
            title={addToModelInfo}
            id={memberCardCurrentUserInfo.userId}
            onClose={handleReportModalClose}
          />
          <ReportDialog
            husslupId={memberCardCurrentUserInfo.profileId}
            show={addToModalShow.showReportModal}
            onClose={handleReportModalClose}
            reportedCompanyId={memberCardCurrentUserInfo.userId}
          />
          <DialogBox
            show={isVisible}
            message='Are you sure you want to cancel this connection request?'
            onAccept={dialogBoxHandler}
            onClose={hide}
            title='Cancel Connection Request'
            acceptText='Yes'
            loading={isRemovingConnection}
            wrapperClassName='modal-confirmation'
            disabled={isRemovingConnection}
          />
        </div>
      )}
    </>
  );
};

export default Recommendation;
