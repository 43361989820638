import SearchInput from 'components/Core/Search/SearchInput';
import {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';

const NetworkHeader: FunctionComponent<{
  searchTerm: string;
  onFilter: (searchTerm: string) => void;
}> = ({ searchTerm, onFilter }): ReactElement => {
  const [searchText, setSearchText] = useState<string>(searchTerm || '');
  const handleSearch = (ev: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(ev.target.value);
    onFilter(ev.target.value);
  };

  useEffect(() => {
    setSearchText(searchTerm);
  }, [searchTerm]);

  return (
    <SearchInput
      placeholder='Search All Connections...'
      onSearch={handleSearch}
      value={searchText}
    />
  );
};

export default NetworkHeader;
