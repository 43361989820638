/* eslint-disable react/no-array-index-key */
import { useEffect, useState, FunctionComponent } from 'react';
import { Image, ProgressBar, Button, Modal } from 'react-bootstrap';
import { useAppSelector } from 'commons/hooks/redux';
import Typography from 'components/Core/StylesGuide/Typography';
import config from 'config';
import { CONTENT_TYPE_APPLICATION_JSON, HTTP_METHODS } from 'constants/http';
import {
  PROFILE_ATTRIBUTE_EDIT_ROUTES,
  PROFILE_ATTRIBUTE_NAME,
  PROFILE_GRADE_TEXT,
} from 'constants/profile-grade';
import Link from 'next/link';
import { selectCurrentUser } from 'store/auth/selectors';
import NextImage from 'components/Core/Image';

interface ProfileProgressGradeType {
  userId: number;
  profileGrade: number;
  completed: string[];
  remainingDetails: string[];
  showCompletion: boolean;
}

interface ProgressGradeInterface {
  subNav: boolean;
  fetchProfileGrade: boolean;
}

const ProgressGrade: FunctionComponent<ProgressGradeInterface> = ({
  subNav,
  fetchProfileGrade,
}) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const [profileProgressGrade, setProfileProgressGrade] =
    useState<ProfileProgressGradeType>({
      completed: [],
      remainingDetails: [''],
      userId: 0,
      profileGrade: 100,
      showCompletion: false,
    });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [imageClassName, setImageClassName] = useState<string>('');

  useEffect(() => {
    if (currentUser) fetchProfileProgressGrade();
  }, [currentUser, fetchProfileGrade]);

  const fetchProfileProgressGrade = async () => {
    const userTokenInfo = localStorage.getItem('persist:husslup');
    if (userTokenInfo) {
      const user = JSON.parse(userTokenInfo);
      const { auth } = user;
      const authToken = JSON.parse(auth);
      const { token } = authToken;
      if (token) {
        const response = await fetch(
          `${config.apiBaseUrl}/profile-progress-grade/getProfileProgressGradeByUser/${currentUser.id}`,
          {
            method: HTTP_METHODS.GET,
            headers: {
              'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
              Authorization: `Bearer ${token}`,
            },
          },
        );
        const grade: ProfileProgressGradeType = await response.json();
        if (grade.showCompletion === true) {
          setShowModal(true);
          setTimeout(() => {
            setImageClassName('image');
          }, 2000);
        }
        setProfileProgressGrade(grade);
      }
    }
  };

  const updateShowCompletionStatus = async () => {
    const userTokenInfo = localStorage.getItem('persist:husslup');
    if (userTokenInfo) {
      const user = JSON.parse(userTokenInfo);
      const { auth } = user;
      const authToken = JSON.parse(auth);
      const { token } = authToken;
      if (token) {
        await fetch(
          `${config.apiBaseUrl}/profile-progress-grade/updateShowCompletionByUser/${currentUser.id}`,
          {
            method: HTTP_METHODS.PATCH,
            headers: {
              'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              showCompletionStatus: false,
            }),
          },
        );
        fetchProfileProgressGrade();
      }
    }
  };

  const handleClose = () => {
    setImageClassName('');
    setShowModal(false);
    updateShowCompletionStatus();
  };

  if (
    profileProgressGrade.profileGrade === 100 &&
    profileProgressGrade.showCompletion
  )
    return (
      <>
        {showModal && (
          <>
            <Image
              src='/confetti-single.gif'
              alt='Confetti'
              width='100'
              height='100'
              className={`noloop-confetti ${imageClassName}`}
            />
            <Modal
              show={showModal}
              onHide={handleClose}
              className='profile-success'
            >
              <Modal.Header>
                <Modal.Title>Profile Complete</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Congratulations! You have completed your profile and earned a
                Top Profile badge! Continue to update your professional
                information to connect with opportunities.
                <div className='achievement-wrap'>
                  <div className='achievement-top'>
                    <NextImage
                      src='/icon-top-profile.svg'
                      alt='icon-top-profile'
                      className='achievement-top-icon'
                      height={32}
                      width={32}
                    />
                  </div>
                  <div className='achievement-bottom'>
                    <Typography
                      variant='p-lg-m'
                      className='achievement-bottom-text'
                    >
                      Top Profile
                    </Typography>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='primary' onClick={handleClose}>
                  Okay
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </>
    );

  return (
    profileProgressGrade.profileGrade !== 100 && (
      <>
        <Typography variant='p-md-b' className={`${subNav ? 'mt-1' : ''}`}>
          Profile Completion:{' '}
          <span className='ml-sh'>{profileProgressGrade.profileGrade}%</span>
        </Typography>

        <div className={`d-flex ${subNav ? 'border-b pb-1' : ''}`}>
          {profileProgressGrade?.remainingDetails?.length === 0 ? (
            <Typography variant='p-sm-d'>Awesome, its all done</Typography>
          ) : (
            <>
              <Typography variant='p-sm-d'>
                {
                  PROFILE_GRADE_TEXT[
                    `${profileProgressGrade?.remainingDetails?.[0]}`
                  ]
                }
              </Typography>
              <Link
                href={`/Profile/Edit/${
                  PROFILE_ATTRIBUTE_EDIT_ROUTES[
                    profileProgressGrade.remainingDetails?.[0]
                  ]
                }`}
              >
                <Typography variant='p-sm-b' className='ml-sh'>
                  {
                    PROFILE_ATTRIBUTE_NAME[
                      profileProgressGrade.remainingDetails?.[0]
                    ]
                  }
                </Typography>
              </Link>
            </>
          )}
        </div>
        {!subNav && (
          <ul className='heading-list'>
            <div className='completion-bar'>
              <div className='main-bar'>
                <ProgressBar
                  now={12.5 * profileProgressGrade?.completed?.length}
                />
                <div
                  className='progress-head'
                  style={{
                    width: `${12.5 * profileProgressGrade?.completed?.length}%`,
                  }}
                >
                  <Image src='/progress-head.svg' alt='progress-head' />
                </div>
              </div>
            </div>

            {profileProgressGrade.completed?.map((profAttrName, index) => (
              <li
                key={profAttrName + index}
                className='heading-item-container complete'
              >
                <ProgressBar now={0} />
                <Typography variant='p-sm-b'>
                  {PROFILE_ATTRIBUTE_NAME[profAttrName]}
                </Typography>
              </li>
            ))}

            {profileProgressGrade.remainingDetails?.map(
              (profAttrName, index) => (
                <li
                  key={profAttrName + index}
                  className='heading-item-container'
                >
                  <ProgressBar now={0} />
                  <Link
                    href={`/Profile/Edit/${PROFILE_ATTRIBUTE_EDIT_ROUTES[profAttrName]}`}
                  >
                    <Typography variant='p-sm-b'>
                      {PROFILE_ATTRIBUTE_NAME[profAttrName]}
                    </Typography>
                  </Link>
                </li>
              ),
            )}
          </ul>
        )}
      </>
    )
  );
};

export default ProgressGrade;
