import React, { FunctionComponent, ReactElement } from 'react';
import { Modal } from 'react-bootstrap';
import Button, {
  ButtonSizes,
  ButtonTypes,
  ButtonVariants,
} from 'components/Core/Button';
import Typography from 'components/Core/StylesGuide/Typography';
import Image from 'next/image';

interface IProps {
  show: boolean;
  onSuccess: () => void;
}

const Congratulations: FunctionComponent<IProps> = ({
  onSuccess,
  show,
}): ReactElement => (
  <Modal show={show} centered className='signup-modal'>
    <Modal.Body>
      <Image
        src='/congratulations.svg'
        alt='Congratulations!'
        width={270}
        height={224}
      />
      <Typography variant='h2' className='mb-12'>
        We're Almost Done!
      </Typography>
      <Typography variant='p-md'>
        You’ve created an account. But your profile is not live yet.
      </Typography>
      <Typography variant='p-md'>
        Now take 2 minutes to build and publish your profile so you can connect
        with opportunities.
      </Typography>
    </Modal.Body>
    <Modal.Footer>
      <Button
        type={ButtonTypes.button}
        size={ButtonSizes.small}
        variant={ButtonVariants.primary}
        onClick={onSuccess}
        label='Build Profile'
      />
    </Modal.Footer>
  </Modal>
);

export default Congratulations;
