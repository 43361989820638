import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InitialSubscriptionSliceState } from 'commons/data/pages/subscription';
import {
  ActiveStaffingSubscription,
  SubscriptionPlan,
  SubscriptionSliceState,
} from './subscription.types';
import { getPlanInterval } from './subscription.utils';

export const SUBSCRIPTION_SLICE_KEY = 'subscription';

const initialState: SubscriptionSliceState = InitialSubscriptionSliceState;

const subscriptionSlice = createSlice({
  name: SUBSCRIPTION_SLICE_KEY,
  initialState,
  reducers: {
    setSelectedSubscriptionPlan(
      state,
      action: PayloadAction<SubscriptionPlan>,
    ) {
      state.selectedSubscriptionPlan = action.payload;
      state.selectedSubscriptionPlanMeta = getPlanInterval(
        action.payload?.paymentPlan,
      );
    },
    clearSelectedSubscriptionPlan(state) {
      state.selectedSubscriptionPlan = undefined;
      state.selectedSubscriptionPlanMeta = undefined;
    },
    setIsResumingCanceledPlan(state, action: PayloadAction<boolean>) {
      state.isResumingCanceledPlan = action.payload;
    },
    setActiveStaffingSubscription(
      state,
      action: PayloadAction<ActiveStaffingSubscription>,
    ) {
      state.activeStaffingSubscription = action.payload;
    },
    clearActiveStaffingSubscription(state) {
      state.activeStaffingSubscription = undefined;
    },
  },
  extraReducers: () => {},
});

export const {
  setSelectedSubscriptionPlan,
  clearSelectedSubscriptionPlan,
  setIsResumingCanceledPlan,
  setActiveStaffingSubscription,
  clearActiveStaffingSubscription
} = subscriptionSlice.actions;

export const subscriptionReducer = subscriptionSlice.reducer;
