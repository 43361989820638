import NavLink from 'components/Core/NavLink';
import { FunctionComponent, useState } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import * as routes from 'constants/routes';
import Notification from 'components/Notification';
import useMessageBadgeCount from 'components/Messages/hooks/useMessageBadgeCount';
import useRealTimeNotification from 'commons/hooks/Notification/useRealTimeNotification';
import NavIcon from './NavIcon';

const CommunicationNavLinks: FunctionComponent<{}> = () => {
  const unreadMessagesCount = useMessageBadgeCount();
  const hasNewNotification = useRealTimeNotification();
  const [lastActivityFetchAt, setLastActivityFetchAt] = useState<number>(
    new Date().getTime(),
  );

  return (
    <>
      <div className='has-indicator negative-margin'>
        <NavLink path={routes.MESSAGES}>
          <NavIcon name='message' />
          {unreadMessagesCount > 0 && <span className='indicator'></span>}
        </NavLink>
      </div>
      <Nav.Link>
        <Dropdown align='end' className='notification-dropdown has-indicator'>
          <Dropdown.Toggle
            as='div'
            onClickCapture={() => {
              setLastActivityFetchAt(new Date().getTime());
            }}
          >
            <NavIcon name='notification' />
            {hasNewNotification && (
              <span className='notification-indicator'></span>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu className='notification'>
            <Notification lastActivityFetchAt={lastActivityFetchAt} />
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Link>
    </>
  );
};

export default CommunicationNavLinks;
