/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useAppDispatch, useAppSelector } from 'commons/hooks/redux';
import Typography from 'components/Core/StylesGuide/Typography';
import HusslUpIcon from 'components/Icon';
import ExternalSubscriptionItem from 'components/Subscription/ExternalSubscriptionItem';
import { SubscriptionItem } from 'components/Subscription/SubscriptionItem';
import { STAFFING_PRO_SEARCHES } from 'constants/routes';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { Modal } from 'react-bootstrap';

import { selectCurrentUser } from 'store/auth/selectors';
import { setActiveStaffingSubscription } from 'store/subscription/subscription.slice';

interface IProps {
  show: boolean;
  setShow: (value: boolean) => void;
}

const StaffingSubscriptionChoiceModal: FunctionComponent<IProps> = ({
  setShow,
  show,
}) => {
  const router = useRouter();
  const user: any = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  // const [showModal, setShow] = useState(show);
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName='subscription-choice-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='content-area staffing-members'>
          <div>
            <Typography
              variant='p-md-d'
              className='mb-1'
              style={{ marginTop: '-16px' }}
            >
              Which subscription do you want to work in?
            </Typography>
          </div>
          <>
            {
              <ul className='list-group'>
                {user?.userSubscriptions?.map((rs: any, index: number) => (
                  // eslint-disable-next-line
                  <li
                    className={`list-group-item d-flex align-items-center justify-content-between ${
                      index === 0 ? 'border-t' : ''
                    }`}
                    // eslint-disable-next-line
                    onClick={() => {
                      dispatch(
                        setActiveStaffingSubscription({
                          id: rs.id,
                          isExternalSubscription: false,
                        }),
                      );

                      setShow(false);
                      const currentQuery = { ...router.query };
                      router.push({
                        pathname: STAFFING_PRO_SEARCHES,
                        query: currentQuery,
                      });
                    }}
                    key={rs.id}
                  >
                    <SubscriptionItem
                      subscription={rs}
                      isOwner={user?.id === rs?.owner?.id}
                    />
                    <HusslUpIcon
                      name='chevron-right'
                      wrapperClass='hic-1x fw-600'
                    />
                  </li>
                ))}
                <li
                  className='list-group-item d-flex justify-content-between align-items-center'
                  // eslint-disable-next-line
                  onClick={() => {
                    dispatch(
                      setActiveStaffingSubscription({
                        id: undefined,
                        isExternalSubscription: true,
                      }),
                    );
                    setShow(false);
                    const currentQuery = { ...router.query };
                    router.push({
                      pathname: STAFFING_PRO_SEARCHES,
                      query: currentQuery,
                    });
                  }}
                >
                  <ExternalSubscriptionItem />
                  <HusslUpIcon
                    name='chevron-right'
                    wrapperClass='hic-1x fw-600'
                  />
                </li>
              </ul>
            }
          </>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StaffingSubscriptionChoiceModal;
