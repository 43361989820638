import Typography from 'components/Core/StylesGuide/Typography';
import SuperEllipse from 'react-superellipse';
import { UserSubscriptions } from 'store/subscription/subscription.types';

interface NotificationProps {
  subscriptionInfo: UserSubscriptions;
}

const NotificationIcon = ({ subscriptionInfo }: NotificationProps) => (
  <SuperEllipse
    r1={0.05}
    r2={0.49}
    style={{
      width: '56px',
      height: '56px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#F1F1F1',
    }}
  >
    <SuperEllipse
      r1={0.05}
      r2={0.49}
      style={{
        width: '54.5px',
        height: '54.5px',
        // display: 'block',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        className='squicircle-subscription-notification'
        style={{
          backgroundColor: subscriptionInfo?.subscriptionColor || '#4dc6ba',
        }}
      >
        <Typography variant='p-sm-d'>
          {subscriptionInfo?.subscriptionInitials}
        </Typography>
      </div>
    </SuperEllipse>
  </SuperEllipse>
);

export default NotificationIcon;
