import useGetStreamClient from 'components/Messages/hooks/useGetStreamClient';
import { MESSAGES } from 'constants/routes';
import { useRouter } from 'next/router';
import { selectCurrentUser } from 'store/auth/selectors';
import { User } from 'store/messages/messages.slice';
import asyncSetMessageScreen from 'store/messages/thunk/asyncSetMessageScreen';
import ProfileUtils from 'utils/profile';
import { useAppDispatch, useAppSelector } from './redux';

const useNavigateMessage = (): {
  sendMessage: (sendTo: User) => Promise<void>;
} => {
  const currentUser = useAppSelector(selectCurrentUser);
  const getStreamClient = useGetStreamClient();
  const appDispatch = useAppDispatch();
  const router = useRouter();

  const sendMessage = async (sendTo: User): Promise<void> => {
    const sentBy: User = {
      id: currentUser.husslupId,
      image: ProfileUtils.getProfileImage(currentUser.profile_img),
      fullName: currentUser.fullname,
    };
    appDispatch(
      asyncSetMessageScreen({
        sendTo: {
          ...sendTo,
          image: ProfileUtils.getProfileImage(sendTo.image ?? '') ?? '',
        },
        sentBy,
        chatClient: getStreamClient ?? undefined,
      }),
    );
    router.push({
      pathname: MESSAGES,
    });
  };

  return { sendMessage };
};

export default useNavigateMessage;
