import { IAttachmentCredit } from 'components/Cards';
import MemberCard from 'components/Cards/MemberCard';
import HusslUpIcon from 'components/Icon';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  useCreateMemberMutation,
  useUpdateMemberNoteMutation,
} from 'store/contact/contact.api';
import { useAddProsearchMemberMutation } from 'store/prosearch/prosearch.api';
import { useLazyGetUserByIdQuery } from 'store/user/user-profile.api';
import { MemberCardModalType } from 'utils/basic-faceted-modal.enum';
import ToasterUtils from 'utils/toaster';
import Loader from 'components/Loader';
import { CreditPlaceHolder } from 'components/Placeholders';
import { MemberCardInfo } from 'utils/basic-search-membercard';
import { UserEntity } from 'commons/types/data';
import Button, { ButtonVariants } from '../Button';
import Input from '../Input';
import { InputTypes } from '../Input/Input';
import Typography from '../StylesGuide/Typography';

interface IProps {
  show: boolean;
  onClose: () => void;
  userId: number;
  listId: number;
  memberId?: number;
  title: string;
  onSuccess: () => void;
  note?: string;
  viewOnly?: boolean;
  keyName?: string;
  userData?: UserEntity;
  createActionBtnClass?: string;
}
const NotesCreateModel: FunctionComponent<IProps> = ({
  show,
  onClose,
  listId,
  title,
  memberId,
  onSuccess,
  note,
  viewOnly,
  keyName,
  userData,
  createActionBtnClass = '',
}): ReactElement => {
  const [getProfile, { data, isFetching, isLoading }] =
    useLazyGetUserByIdQuery();
  const [createMember, addMemberToContactResponse] = useCreateMemberMutation();
  const [updateMemberNote, { isLoading: isLoadingUpdateMemberNote }] =
    useUpdateMemberNoteMutation();
  const [textAreaValue, setTextAreaValue] = useState(note ?? '');
  const [addMember, addMemberInProSearchResponse] =
    useAddProsearchMemberMutation();
  const { t } = useTranslation('contact_list');
  const { t: proSearchT } = useTranslation('pro_search');
  useEffect(() => {
    if (memberId) {
      getProfile(memberId);
    }
  }, [memberId, title]);

  useEffect(() => {
    note !== undefined && setTextAreaValue(note!);
  }, [note]);
  const handleNoteChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setTextAreaValue(e.target.value);
  };

  const createHandler = async (textAreaValue: string) => {
    if (title === MemberCardModalType.ADD_TO_CONTACT_TITLE) {
      note !== undefined
        ? await updateMemberNote({
            listId,
            memberId,
            note: textAreaValue,
          })
        : await createMember({
            listId,
            memberId,
            note: textAreaValue,
          });

      ToasterUtils.successToast(
        note !== undefined
          ? t('EDIT_NOTES.value')
          : t('ADD_MEMEBER_TO_LIST_SAVE.value'),
        {
          hideCloseButton: true,
          icon: 'check',
          position: 'top-center',
        },
      );

      setTextAreaValue('');
      onSuccess();
    }
    if (title === MemberCardModalType.ADD_TO_PROSEARCH_TITLE) {
      await addMember({
        prosearchId: listId,
        memberId,
        status: 'saved',
        note: textAreaValue,
      });
      ToasterUtils.successToast(proSearchT('SAVE_USER_TO_PRO_SEARCH.value'), {
        hideCloseButton: true,
        icon: 'check',
        position: 'top-center',
      });

      setTextAreaValue('');
      onSuccess();
    }
  };

  return (
    <Modal show={show} size='lg'>
      <Modal.Header>
        <Modal.Title>
          <Typography className='title' variant='h3'>
            {viewOnly
              ? 'View Notes'
              : note !== undefined
              ? 'Edit Notes'
              : 'Add Notes & Save'}
          </Typography>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className='pb-0 pt-1'>
        {isFetching || isLoading ? (
          <div style={{ height: '410px', paddingTop: '105px' }}>
            <Loader />
          </div>
        ) : (
          <div className='report-list d-flex gap-4'>
            <MemberCard
              memberChickletStatus='pro'
              // @ts-ignore

              fullName={data?.fullname || data?.username || data?.company_name}
              headings={
                [
                  data?.professionalTagline,
                  data?.projectTagline,
                  data?.genreTagline,
                ] as string[]
              }
              icons={[
                {
                  label: 'verified',
                  icon: 'verified-fill',
                  color: 'text-awards',
                },
              ]}
              profileImgUrl={data?.profile_img}
              id={data?.id}
              husslupId={data?.husslupId}
              attachment={
                keyName
                  ? MemberCardInfo.getAttachment(String(keyName), userData!)
                  : ({
                      type: 'credit',
                      heading: data?.credit ? 'Credits' : '',
                      description: [data?.credit, data?.projectTitle],
                      imgUrl:
                        (data?.credit || data?.projectTitle) &&
                        (data?.creditImg ?? CreditPlaceHolder),
                    } as IAttachmentCredit)
              }
              actionButtons={[]}
            />

            <div className='flex-grow-1 d-flex flex-column '>
              <div className='d-flex align-items-center '>
                <Typography className='Paragraph/MD/Default' variant='p-md-b'>
                  Personal Notes
                </Typography>
                <HusslUpIcon
                  name='notes-fill'
                  wrapperClass='ml-4px text-awards fs-20'
                />
              </div>
              <Typography className='title' variant='p-sm-d'>
                {`These notes are private unless ${
                  viewOnly ? 'owner' : 'you'
                } elect to share.`}
              </Typography>
              <Input
                type={InputTypes.textArea}
                onChange={(e) => handleNoteChange(e)}
                value={textAreaValue}
                placeholder='What do you think? (Optional)'
                required={false}
                name='note'
                labelText=''
                id='note'
                disabled={viewOnly}
                onBlur={() => {}}
                wrapperClass='flex-grow-1 d-flex flex-column'
                className='flex-grow-1 mt-8px text-area-note'
              />
              <footer className='mt-2 d-flex flex-end justify-content-end'>
                <Button
                  variant={ButtonVariants.secondary}
                  label='Cancel'
                  onClick={onClose}
                  className='mr-1'
                />
                {!viewOnly && (
                  <Button
                    variant={ButtonVariants.primary}
                    label='Save'
                    className={createActionBtnClass}
                    onClick={() => createHandler(textAreaValue)}
                    disabled={
                      title === MemberCardModalType.ADD_TO_CONTACT_TITLE
                        ? addMemberToContactResponse.isLoading ||
                          isLoadingUpdateMemberNote
                        : addMemberInProSearchResponse.isLoading
                    }
                    loading={
                      title === MemberCardModalType.ADD_TO_CONTACT_TITLE
                        ? addMemberToContactResponse.isLoading ||
                          isLoadingUpdateMemberNote
                        : addMemberInProSearchResponse.isLoading
                    }
                  />
                )}
              </footer>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default NotesCreateModel;
