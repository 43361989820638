import { baseApiSlice } from 'commons/apis/base-api.config';

import {
  GetOrCreateGetstreamProfileResp,
  ProfileListResponseType,
  UserType,
} from './types';

export const profileApi = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getProfile: builder.query<UserType, number>({
        query: (userId: number) => `users/${userId}`,
      }),
      getUserById: builder.query<UserType, number>({
        query: (userId: number) => `users/by-id/${userId}`,
      }),
      getProfileWithViewTracking: builder.query<UserType, number>({
        query: (userId: number) => `profile-view//${userId}`,
      }),
      getProfileList: builder.query<ProfileListResponseType[], {}>({
        query: () => 'user-profile-list/by-user',
      }),

      getOrCreateGetstreamProfile: builder.query<
        GetOrCreateGetstreamProfileResp,
        {}
      >({
        query: () => 'users/get-or-create-profile-with-token',
      }),
    };
  },
});

export const {
  useGetProfileQuery,
  useGetProfileListQuery,
  useLazyGetProfileQuery,
  useLazyGetUserByIdQuery,
  useGetProfileWithViewTrackingQuery,
  useGetOrCreateGetstreamProfileQuery,
  useLazyGetOrCreateGetstreamProfileQuery,
} = profileApi;
