// Custom event classnames
export const USER_REQUESTED_A_CONNECTION = 'user-connection-request';
export const USER_ACCEPTED_A_CONNECTION = 'user-connection-accept';
export const USER_SENT_A_DM = 'user-sent-dm';
export const USER_PEOPLE_SEARCH = 'user-people-search';
export const USER_POSTED = 'user-posted';
export const USER_IGNORED_A_CONNECTION = 'user-ignored-connection';
export const USER_SENDS_A_REFERRAL_INVITES = 'user-sends-a-referral-invites';
export const INVITE_CODE_USED = 'invite-code-used';
export const USER_SENDS_A_VOUCH = 'user-sends-a-vouch';
export const USER_ACCEPTS_A_VOUCH = 'user-accepts-a-vouch';
export const USER_CHANGED_I_IDENTIFY_AS_FIELD =
  'user-changed-i-identify-as-field';
export const USER_ADDED_TO_WAIT_LIST = 'user-added-to-wait-list';
export const ONBOARD_ILLDOTHISLATER_CLICKED = 'onboard-illdothislater-clicked';
export const ONBOARD_IMDB_ADDED = 'onboard-imdb-added';

// Prosearches Tags
export const PS_CREATED = 'ps-created';
export const PS_MEMBER_SAVED = 'ps-member-saved';
export const PS_MEMBER_HIRED  = 'ps-member-hired';
export const PS_SHARED = 'ps-shared';
export const PS_ACCESS_REMOVED = 'ps-access-removed';
export const PS_CRITERIA_SELECTED = 'ps-criteria-selected';
export const PS_MEMBER_REMOVED = 'ps-member-removed';
export const PS_DELETED = 'ps-deleted';
export const PS_ARCHIVED = 'ps-archived';

export const SR_CREATED = 'sr-created';
export const SR_ARCHIVED = 'sr-archived';
export const SR_DELETED = 'sr-deleted';
export const SR_SHARED = 'sr-shared';
export const SR_ACCESS_REMOVED = 'sr-access-removed';
export const SR_OUTSIDE_TALENT_ADDED = 'sr-outside-talent-added';
export const SR_EXPORT_STAFFING_ROSTER = 'sr-export-staffing-roster'
export const SR_EXPORT_STAFFING_ROSTER_SAMPLE = 'sr-export-staffing-roster-sample'
export const LISTS_LIST_ADD = 'lists-list-add';
export const LISTS_MEMBER_ADD = 'lists-member-add';
export const LISTS_LIST_OPENED_MINE = 'lists-list-opened-mine';
export const LISTS_LIST_OPENED_OTHERS = 'lists-list-opened-others';
export const LISTS_NOTE_CHGD = 'lists-note-chgd';
export const LISTS_LIST_SHARED_NO_NOTES = 'lists-list-shared-no-notes';
export const LISTS_LIST_DEL = 'lists-list-del';
export const LISTS_MEMBER_DEL = 'lists-member-del';
export const LISTS_DM_INITIATED = 'lists-dm-initiated';
export const LISTS_LIST_SHARED_W_NOTES = 'lists-list-shared-w-notes';
export const LISTS_OWNERSHIP_CHG_W_NOTES = 'lists-ownership-chg-w-notes';
export const LISTS_OWNERSHIP_CHG_NO_NOTES = 'lists-ownership-chg-no-notes';
export const LISTS_LIST_UNSHARED = 'lists-list-unshared';

export const DOWNLOAD_APP_FROM_BLOCKER = 'download-from-blocker'
