/* eslint-disable */
import { MemberChickletType } from 'components/Chicklet';
import KebabDropdown, {
  KebabDropdownItem,
} from 'components/Core/KebabDropdown/KebabDropdown';
import HusslUpIcon from 'components/Icon';
import React, {
  ChangeEvent,
  FunctionComponent,
  // useEffect,
  useMemo,
  // useState,
} from 'react';
// import LazyLoad from 'react-lazyload';
import { Button, Spinner } from 'react-bootstrap';
import ProfileUtils from 'utils/profile';
import { ActionButtons, AttachmentProps, IconProps } from '.';
import Attachment from './Attachment';
import MemberDetails from './MemberDetails';
import MemberInfo from './MemberInfo';
import useProgressiveImage from 'components/Prosearch/common/ProgressiveImageHook';
import Image from 'next/image';

interface CardIProps {
  memberChickletStatus?: MemberChickletType | undefined;
  memberChickletProfessionalStatus?: 'Hired';
  onClick?: (ev: any) => void;
  onCheck?: (ev: ChangeEvent<HTMLInputElement>) => void;
  profileImgUrl?: string;
  headings?: string[];
  icons?: IconProps[];
  actionButtons?: ActionButtons[];
  fullName?: string | JSX.Element | undefined;
  attachment?: AttachmentProps;
  dropdown?: KebabDropdownItem[];
  id?: number;
  husslupId?: string;
  checked?: boolean;
  searchText?: string;
  keyName?: string;
  wrapperClass?: string;
  fillBackground?: boolean;
  isStan?: boolean;
  calledBy?: string;
}

const MemberCard: FunctionComponent<CardIProps> = ({
  actionButtons,
  attachment,
  headings,
  icons,
  profileImgUrl = '',
  memberChickletProfessionalStatus,
  memberChickletStatus,
  onCheck,
  fullName,
  dropdown,
  onClick,
  id,
  husslupId = '',
  checked = false,
  searchText,
  keyName,
  wrapperClass = '',
  fillBackground,
  isStan = false,
  calledBy,
}) => {
  // const [isPerfectlySquare, setIsPerfectlySquare] = useState<
  //   boolean | undefined
  // >(undefined);

  const profileImage = useMemo(() => {
    if (profileImgUrl) {
      return ProfileUtils.getProfileImageWithoutMaskOriginal(
        profileImgUrl,
        238,
        fillBackground,
      );
    }
    return memberChickletStatus
      ? '/member-card-placeholder.jpg'
      : '/non-member-square.png';
  }, [profileImgUrl]);

  const filteredOptions: KebabDropdownItem[] | undefined = useMemo(
    () =>
      isStan
        ? [...(dropdown || [])]?.map((drop) => {
            return {
              ...drop,
              disabled:
                drop.label.includes('Report') ||
                drop.label.includes('Block') ||
                drop.label.includes('Remove Connection'),
            };
          })
        : memberChickletStatus
        ? dropdown
        : [...(dropdown || [])]?.map((drop) => {
            return {
              ...drop,
              disabled:
                drop.label.includes('Report') ||
                drop.label.includes('Block') ||
                drop.label.includes('Copy Profile Link') ||
                drop.label.includes('Add to Contact List') ||
                drop.label.includes('Add to ProSearch') ||
                drop.label.includes('Label Connection...') ||
                drop.label.includes('Add to Staffing Roster'),
            };
          }),
    [isStan, dropdown],
  );

  // const load = (src: string) => {
  //   if (src === '/empty avatar 2.jpg') {
  //     setIsPerfectlySquare(false);
  //     return;
  //   }
  //   const image = new Image();
  //   image.src = src;
  //   image.onload =  () => {
  //     setIsPerfectlySquare(image.height === image.width);
  //   };
  // };

  // useEffect(() => {
  //   load(profileImage);
  // }, [profileImage]);
  const loaded = useProgressiveImage(profileImage);
  return (
    <div className={`member-card ${wrapperClass ?? ''}`}>
      <div
        className='member-card__top'
        style={{
          backgroundImage: `linear-gradient(0deg, #000 3.96%, rgba(0, 0, 0, 0.00) 29%), url(${loaded})`,
        }}
        onClick={onClick}
        tabIndex={0}
        role='button'
        onKeyUp={() => {}}
      >
        <MemberInfo
          status={memberChickletProfessionalStatus}
          onSelect={onCheck}
          checked={checked}
        />
      </div>
      <div className='member-card__bottom'>
        <div className='card-misc'>
          <MemberDetails
            pro={memberChickletStatus}
            name={fullName ?? ''}
            headings={headings}
            icons={icons}
            searchText={searchText}
            keyName={keyName}
            calledBy={calledBy}
          />
        </div>
        <div
          className='membercard-click'
          onClick={onClick}
          tabIndex={0}
          role='button'
          onKeyUp={() => {}}
        >
          {attachment && (
            <Attachment
              attachment={attachment}
              searchText={searchText}
              keyName={keyName}
            />
          )}
        </div>
        <div className='action-btns'>
          {(actionButtons?.length ?? 0) > 1 ? (
            <div className='multiple-btn__holder w-100'>
              {actionButtons?.map((ab, i) => (
                <Button
                  disabled={ab.disabled || !memberChickletStatus}
                  className={`card-btn multiple-btn ${
                    ab.wrapperClass ?? ab.wrapperClass
                  }`}
                  onClick={ab.onClick}
                  key={ab?.icon || ab?.label || i}
                >
                  {ab.loading ? (
                    <Spinner
                      as='span'
                      className='multiple-btn-loader'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    ab.icon && <HusslUpIcon name={ab.icon} />
                  )}
                  {ab.label}
                </Button>
              ))}
            </div>
          ) : (
            <div className='action-buttons__holder w-100'>
              {actionButtons?.map((ab, i) => (
                <Button
                  className={`card-btn ${ab.wrapperClass ?? ab.wrapperClass}`}
                  onClick={ab.onClick}
                  key={ab?.icon || ab?.label || i}
                  disabled={ab.disabled || !memberChickletStatus}
                >
                  {ab.loading ? (
                    <Spinner
                      as='span'
                      className=' '
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    ab.icon && <HusslUpIcon name={ab.icon} />
                  )}
                  {ab.label}
                </Button>
              ))}
            </div>
          )}
          {dropdown && filteredOptions && (
            <KebabDropdown
              dropdownItems={filteredOptions}
              align='end'
              drop='up'
              profileId={id}
              husslupId={husslupId}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default MemberCard;
