/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MemberTilesCount } from 'constants/commons';
import { ReactElement, FC, useState, memo } from 'react';
import { Modal } from 'react-bootstrap';
import { MemberTypes } from 'store/contact/contact.types';
import ProfileUtils from 'utils/profile';
import SquircleSmall from './SquircleSmall';

interface IMemberTile {
  src: Array<string>;
  members?: MemberTypes[];
  title?: string;
}
const MemberTile: FC<IMemberTile> = (props: IMemberTile): ReactElement => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const { src, members, title } = props;
  const trimmedSrc = src?.filter((img, index) => index <= MemberTilesCount - 1);
  return (
    <>
      <div className='member' role='button' onKeyUp={() => {}} tabIndex={0}>
        <div className='p-sm p-sm-sb mb-h text-nowrap'>{`${
          src?.length > 0 ? `${src?.length?.toString()} Members` : 'No Members'
        }`}</div>
        <ul className='members-squicircle'>
          {trimmedSrc?.map((source: string, index: number) => (
            <li className='squicircle' key={source.slice(0, index).toString()}>
              <SquircleSmall imgUrl={source} />
            </li>
          ))}
          {src?.length > MemberTilesCount && (
            <li className='squicircle last' onClick={handleShow}>
              <SquircleSmall imgUrl={src[src?.length - 1]} />
              <small>+{src?.length - MemberTilesCount}</small>
            </li>
          )}
        </ul>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName='staffing-members'
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='member-list hic-scroll'>
            {members?.map(({ user, id, note }) => (
              <li className='member-list__item' key={id}>
                <SquircleSmall
                  imgUrl={ProfileUtils.getProfileImage(
                    user?.profile_img!,
                    40,
                    40,
                  )}
                />
                <div>
                  <div className='member-info'>
                    <div className='p-md p-md-sb'>{user?.fullname}</div>
                    <span className='badge badge-pro'>Pro</span>
                    {note ? (
                      <span className='hic-notes-fill hic-1x text-info-text' />
                    ) : null}
                  </div>
                  <small className='text-muted'>
                    {user?.professionalTagline}
                  </small>
                </div>
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(MemberTile);
