import SquircleImage from 'components/Core/SquircleImage/SquircleImage';
import { FC, ReactElement } from 'react';

type SquircleSmallProps = {
  imgUrl: any;
};

const SquircleSmall: FC<SquircleSmallProps> = (
  props: SquircleSmallProps,
): ReactElement => {
  const { imgUrl } = props;
  return (
    <SquircleImage
      imgUrl={imgUrl}
      r1={0.04}
      r2={0.5}
      height={40}
      width={40}
      alt='Profile Pic'
    />
  );
};

export default SquircleSmall;
