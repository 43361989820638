import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  AllProfileListMap,
  CategorizedProfileList,
  CategorizedProfileListDemographicsResponse,
  ProfileListOption,
  ProfileListTables,
} from 'commons/types/data';
import {
  API_CATEGORIZED_PROFILE_LIST_DEMOGRAPHICS,
  API_PROFILE_LIST,
  API_PROFILE_LIST_BY_TYPE,
  API_PROFILE_LIST_GET_ALL,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';

export const profileListApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getAllProfileListOptions: builder.query<AllProfileListMap, void>({
        query() {
          return {
            url: `${API_PROFILE_LIST}${API_PROFILE_LIST_GET_ALL}`,
            method: HTTP_METHODS.GET,
          };
        },
        transformResponse: (data: AllProfileListMap) => data,
        keepUnusedDataFor: 3600,
      }),
      getCategorizedProfileList: builder.query<
        CategorizedProfileList,
        ProfileListTables
      >({
        query(payload) {
          return {
            url: `${API_PROFILE_LIST}${API_PROFILE_LIST_BY_TYPE}/${payload}`,
            method: HTTP_METHODS.GET,
          };
        },
        transformResponse: (data: ProfileListOption[]) => {
          const groupByCategory = data.reduce(
            (acc: CategorizedProfileList, currentItem) => {
              const { id, text, order, category } = currentItem;
              if (category) {
                if (!acc[category]) {
                  acc[category] = [];
                }
                acc[category].push({ id, text, order, value: id, label: text });
              }

              return acc;
            },
            {},
          );
          return groupByCategory;
        },
      }),
      getCategorizedProfileListDemographics: builder.query<
        CategorizedProfileList,
        void
      >({
        query() {
          return {
            url: `${API_PROFILE_LIST}${API_CATEGORIZED_PROFILE_LIST_DEMOGRAPHICS}`,
            method: HTTP_METHODS.GET,
          };
        },
        transformResponse: (data: CategorizedProfileListDemographicsResponse) =>
          data?.categorized,
      }),
    };
  },
});

export const {
  useGetAllProfileListOptionsQuery,
  useLazyGetAllProfileListOptionsQuery,
  useGetCategorizedProfileListQuery,
  useGetCategorizedProfileListDemographicsQuery,
} = profileListApiSlice;
