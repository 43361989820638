import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'config';
import type { RootState } from 'commons/types/redux';

export const BASE_API_SLICE = 'baseApi';

const prepareBaseQuery = fetchBaseQuery({
  baseUrl: config.apiBaseUrl,
  prepareHeaders(headers, { getState }) {
    const { token } = (getState() as RootState).auth;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseApiSlice = createApi({
  reducerPath: BASE_API_SLICE,
  baseQuery: prepareBaseQuery,
  endpoints: () => ({}),
  tagTypes: [
    'Community',
    'suggest',
    'Connection',
    'SearchConnection',
    'AcceptedReferral',
    'ConnectionsByLabel',
    'ConnectionRequests',
    'FollowedCompanies',
    'PendingInvites',
    'RecommendedUserLists',
    'UserProfileList',
    'FetchPosts',
    'MemberLists',
    'AllContactLists',
    'UserCredits',
    'CurrentAuthUser',
    'CurrentAuthUserCompany',
    'FetchCreativePortfolio',
    'FetchCreativePortfolioById',
    'AllUserConnections',
    'UserConnectionStatus',
    'SinglePost',
    'VouchStatus',
    'Prosearches',
    'ProsearchesResults',
    'SingleProsearch',
    'ProsearchAccess',
    'ProsearchMembers',
    'ProsearchCriterias',
    'StaffingRosters',
    'SingleStaffingRoster',
    'StaffingRosterRoles',
    'StaffingRosterRoleUsers',
    'SingleStaffingRosterRoles',
    'SingleStaffingRosterRoleUsers',
    'StaffingRosterRoleUsersByRoleId',
    'StaffingRosterAccess',
    'SingleStaffingRosterAccess',
    'BlockedUserList',
    'Home',
    'Search',
    'Discover',
    'Staffing',
    'Network',
    'MyNetworkConnectionsCount',
    'GetUserSubscription',
    'ProsearchListByUser',
    'ContactLists',
    'ReframeCreate',
    'RosterDemographicsReportView',
    'StaffingRostersV2',
    'AssignedSubscriptionSeats',
    'UserLinkedIn',
    'ProSearchesTopResults',
    'RandomRecommendentaions',
    'ScriptAssessmentResults',
  ],
});
