import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SerendipityState {
  people: boolean;
  companies: boolean;
  slides?: {
    people?: number;
    companies?: number;
  };
}

const initialState: SerendipityState = {
  companies: false,
  people: false,
  slides: {
    companies: 0,
    people: 0,
  },
};

const serendipitySlice = createSlice({
  name: 'serendipity',
  initialState,
  reducers: {
    updateSerendipityVisibility: (
      state,
      action: PayloadAction<{
        key: keyof Omit<SerendipityState, 'slides'>;
        value: boolean;
      }>,
    ) => {
      const key = action.payload.key;
      const value = action.payload.value;
      state[key] = value;
    },

    resetSerendipityMode: (state) => {
      state = {
        ...state,
        companies: false,
        people: false,
      };
    },

    onSerendipitySlideChanges: (
      state,
      action: PayloadAction<{
        key: keyof Omit<SerendipityState, 'slides'>;
        slideIndex: number;
      }>,
    ) => {
      const key = action.payload.key;
      const value = action.payload.slideIndex;
      const tempSlides = {
        ...state.slides,
        [key]: value,
      };
      state.slides = tempSlides;
    },
  },
});

export const {
  updateSerendipityVisibility,
  resetSerendipityMode,
  onSerendipitySlideChanges,
} = serendipitySlice.actions;
export const serendipityReducer = serendipitySlice.reducer;
