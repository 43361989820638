import React from 'react';
import { Form } from 'react-bootstrap';
import Select, {
  StylesConfig,
  components,
  OptionProps,
  MultiValue,
  ActionMeta,
  MultiValueGenericProps,
} from 'react-select';
import { ColorCodes } from 'constants/color';
import Typography from '../StylesGuide/Typography';
import SquircleImage from '../SquircleImage/SquircleImage';

const Option = ({ isSelected, ...props }: OptionProps & any) => (
  <div>
    <components.Option
      {...props}
      isSelected={isSelected}
      label={props.data?.label}
      className='select-options'
    >
      <div className='select-options-left'>
        <SquircleImage
          imgUrl={props.data?.img}
          alt='select-input-img'
          height={32}
          width={32}
        />
        <Typography variant='p-sm'>{props.data?.label}</Typography>
      </div>
      <Form.Check checked={isSelected} readOnly />
    </components.Option>
  </div>
);

const MultiValueContainer = (props: MultiValueGenericProps<any>) => (
  <div className='selected-values-wrap'>
    <SquircleImage
      imgUrl={props.data?.img}
      alt='select-input-img'
      height={32}
      width={32}
    />
    <Typography variant='p-sm-d'>{props.data?.label}</Typography>
    <span className='hic-close' />
  </div>
);

const colourStyles: StylesConfig<any, true> = {
  control: (styles) => ({
    ...styles,
    borderWidth: '1.5px',
    borderRadius: '8px',
    padding: '2px 0px 2px 0px',
    borderColor: ColorCodes.NeutralsWeb['sub-text-creative-content'],
    boxShadow: undefined,
    ':hover': {
      borderColor: ColorCodes.Primary['background-placeholder'],
      boxShadow: `0 0 0 1px ${ColorCodes.Primary['background-placeholder']}`,
    },
  }),
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected
      ? ColorCodes.Primary['background-placeholder']
      : ColorCodes.NeutralsWeb.background,
    color: ColorCodes.Primary['interactive-controls'],
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':hover': {
      ...styles[':hover'],
      /** @TODO color name not yet defined. use color variable after color name is assigned */
      backgroundColor: '#F3F0F3',
    },
    ':active': {
      ...styles[':active'],
      backgroundColor: ColorCodes.Primary['background-placeholder'],
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '2px',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: ColorCodes.NeutralsWeb.background,
    borderRadius: '8px',
    border: `1.5px solid ${ColorCodes.Primary['interactive-controls']};`,
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: ColorCodes.Primary['interactive-controls'],
    fontFamily: 'SFProText',
    fontSize: '14px',
    lineHeight: '140%',
    fontWeight: 500,
  }),

  multiValueRemove: (styles) => ({
    ...styles,
    fontWeight: 200,
    paddingLeft: 0,
    color: ColorCodes.Primary['interactive-controls'],
    ':hover': {
      color: ColorCodes.Primary['background-placeholder'],
    },
  }),
};

export type SelectInputWithImageOptionType = {
  label: string;
  value: string | number;
  text: string;
  img: string;
};

type SelectInputProps<T extends SelectInputWithImageOptionType> = {
  /**
   * Optional label text
   */
  labelText?: string;
  /**
   * Array of option of type {@link SelectInputWithImageOptionType}
   */
  options: T[];
  handleChange: (
    newValue: MultiValue<SelectInputWithImageOptionType>,
    actionMeta?: ActionMeta<SelectInputWithImageOptionType>,
  ) => void;
  value: MultiValue<SelectInputWithImageOptionType>;
  loading?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
};

const SelectInputWithImage = <T extends SelectInputWithImageOptionType>({
  labelText,
  options,
  loading,
  handleChange,
  value,
  disabled,
  error,
  errorMessage,
}: SelectInputProps<T>) => (
  <Form.Group>
    {labelText ? <Form.Label>{labelText}</Form.Label> : null}
    <Select
      closeMenuOnSelect={false}
      isMulti
      value={value}
      placeholder=''
      isLoading={loading}
      hideSelectedOptions={false}
      options={options}
      isDisabled={disabled}
      onChange={handleChange}
      styles={colourStyles}
      isClearable={false}
      components={{
        Option: Option as any,
        MultiValueContainer,
        IndicatorSeparator: () => null,
      }}
    />
    {error && (
      <Typography variant='p-sm-md' className='select-options-error-text'>
        {errorMessage ?? ''}
      </Typography>
    )}
  </Form.Group>
);

export default SelectInputWithImage;
