import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "commons/types/redux";

export const selectBaseAPIState: (state: RootState) => any = (
  state: RootState,
) => state;

export const selectBaseApi = createSelector(
  selectBaseAPIState,
  (state) => state?.baseApi?.queries,
);