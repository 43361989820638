import {
  combineReducers,
  configureStore,
  Reducer,
  createAction,
} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer,
} from 'redux-persist';
import { APP_ENVIRONMENT_PRODUCTION } from 'constants/app';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import config from 'config';
import { strapiApiSlice } from 'commons/apis/strapi-api';
import errorLoggerMiddleware from './middleware/error-logger.middleware';
import { usersApiSlice } from './user/users.api';
import { authApiSlice } from './auth/auth.api';
import { auth0ApiSlice } from './auth/auth0.api';
import { profileApi } from './user/user-profile.api';
import { authReducer } from './auth/auth.slice';
import { postApiSlice } from './post/post.api';
import { postTagApiSlice } from './post/post-tag.api';
import { discoverApiSlice } from './discover/discover.api';
import { postReducer } from './post/post.slice';
import { likeApiSlice } from './post/like.api';
import { reportApiSlice } from './report/report.api';
import { messagesReducer } from './messages/messages.slice';
import { connectionsReducer } from './my-network';
import { accountApiSlice } from './account/account.api';
import { inviteApiSlice } from './invite';
import { contactUsApiSlice } from './contact-us/contact.api';
import unauthenticatedMiddleware from './middleware/unauthenticated.middleware';
import { basicSearchReducer } from './basic-faceted-search/basic-search.slice';
import { serendipityReducer } from './discover/serendipity.slice';
import { pageSettingReducer } from './discover/pageFilters.slice';
import { contactReducer } from './contact/contact.slice';
import { profileListApiSlice } from './profile-list';
import { onboardReducer } from './user/onboard';
import {
  CREATIVE_PORTFOLIO_SLICE_KEY,
  creativePortfolioReducer,
} from './creative-portfolio';
import { prosearchDetailStateSlice } from './prosearch';
import {
  SUBSCRIPTION_SLICE_KEY,
  subscriptionReducer,
} from './subscription/subscription.slice';
import { assessmentScriptApiExtendedSlice } from './assessment-script/assessment-script.api';

export const RESET_STATE_ACTION_TYPE = 'resetState';

// black list app apis reducers to avoid persisting on local
const blackListedReducers = {
  [auth0ApiSlice.reducerPath]: auth0ApiSlice.reducer,
  [strapiApiSlice.reducerPath]: strapiApiSlice.reducer,
  [usersApiSlice.reducerPath]: usersApiSlice.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [postApiSlice.reducerPath]: postApiSlice.reducer,
  [postTagApiSlice.reducerPath]: postTagApiSlice.reducer,
  [discoverApiSlice.reducerPath]: discoverApiSlice.reducer,
  [reportApiSlice.reducerPath]: reportApiSlice.reducer,
  [accountApiSlice.reducerPath]: accountApiSlice.reducer,
  connections: connectionsReducer,
  onboard: onboardReducer,
  messages: messagesReducer,
  [inviteApiSlice.reducerPath]: inviteApiSlice.reducer,
  [contactUsApiSlice.reducerPath]: contactUsApiSlice.reducer,
  basicSearch: basicSearchReducer,
  authApi: authApiSlice.reducer,
  post: postReducer,
  profileListApi: profileListApiSlice.reducer,
  pagesetting: pageSettingReducer,
  prosearchesDetailsPage: prosearchDetailStateSlice.reducer,
  [CREATIVE_PORTFOLIO_SLICE_KEY]: creativePortfolioReducer,
  [SUBSCRIPTION_SLICE_KEY]: subscriptionReducer,
  scriptAssessment: assessmentScriptApiExtendedSlice.reducer,
};

const persistConfig = {
  key: 'husslup',
  version: 1,
  storage,
  blacklist: [...Object.keys(blackListedReducers)],
};

const combinedReducer = combineReducers({
  auth: authReducer,
  serendipity: serendipityReducer,
  contact: contactReducer,
  ...blackListedReducers,
});

const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState;
  }

  return combinedReducer(state, action);
};

const persistedReducers = persistReducer(persistConfig, rootReducer);

const combinedMiddleware = (
  getDefaultMiddleware: CurriedGetDefaultMiddleware,
) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat([
    unauthenticatedMiddleware,
    usersApiSlice.middleware,
    authApiSlice.middleware,
    auth0ApiSlice.middleware,
    strapiApiSlice.middleware,
    profileApi.middleware,
    postApiSlice.middleware,
    likeApiSlice.middleware,
    postTagApiSlice.middleware,
    discoverApiSlice.middleware,
    reportApiSlice.middleware,
    accountApiSlice.middleware,
    contactUsApiSlice.middleware,
    assessmentScriptApiExtendedSlice.middleware,
    errorLoggerMiddleware,
  ]);

const store = configureStore({
  reducer: persistedReducers,
  middleware: combinedMiddleware,
  devTools: config.env !== APP_ENVIRONMENT_PRODUCTION,
});

const storePersistor = persistStore(store);

const resetStateAction = createAction(RESET_STATE_ACTION_TYPE, () => ({
  payload: null,
}));

type RootState = ReturnType<typeof combinedReducer>;

export { storePersistor, store, combinedReducer, resetStateAction };
