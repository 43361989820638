import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Post } from 'commons/types/post.type';
import SquircleImage from 'components/Core/SquircleImage/SquircleImage';
import HusslUpIcon from 'components/Icon';
import { selectCurrentUser } from 'store/auth/selectors';
import { useCreateCommentMutation } from 'store/post/comment.api';
import { selectGetPostWithTagParams } from 'store/post/selectors';
import ProfileUtils from 'utils/profile';
import PostUtils from 'utils/post';
import { useRouter } from 'next/router';
import { GenericDropdownItem } from 'components/Core/GenericDropdown';
import { CommentActivities } from 'commons/types/comment.type';
import PostCommentList from './PostCommentList';
import CreateCommentTextArea from './CreateCommentTextArea';

type PostCommentSectionProps = {
  post: Post;
  commentProps: (
    initiateCommentEdit: (
      postId: number,
      commentId: number,
      commentLevel?: number,
    ) => void,
  ) => (comment: CommentActivities) => GenericDropdownItem[] | null;
};
const rows = 1;
const cols = 80;

const PostCommentSection = ({
  post,
  commentProps,
}: PostCommentSectionProps) => {
  const router = useRouter();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [adjustedRows, setAdjustedRows] = useState(rows);
  const [isAddingMention, setIsAddingMention] = useState(false);

  const [createComment, { isLoading }] = useCreateCommentMutation();

  const [mentionedUsers, setMentionedUsers] = useState<string[]>([]);

  const currentUser = useSelector(selectCurrentUser);
  const getPostWithTagParams = useSelector(selectGetPostWithTagParams);

  const topTwoComments = useMemo(() => {
    if (router.query?.id) {
      return post?.commentActivities ?? [];
    }
    return PostUtils.getLatestComments(post?.commentActivities, 2);
  }, [post?.comment, router.query, post?.commentActivities]);

  const [comment, setComment] = useState('');

  const handleCreateComment = async () => {
    if (comment && post.post_id) {
      const response = await createComment({
        body: {
          comment,
          postId: post.post_id,
          mentionedUsersId: mentionedUsers,
        },
        commentBy: currentUser,
        getPostWithTagParams,
      }).unwrap();
      if (response?.commentId) {
        setComment('');
        setMentionedUsers([]);
      }
    }
  };

  const postInitiateEditComment = (postId: number, commentId: number) => {
    if (postId && commentId) {
      setComment('');
    }
  };

  const getUser = async (husslupId: string) => {
    setIsAddingMention(true);
    // TODO: this is the hot fix comment api should accept husslup ids instead of user ids and should return exactly like post api
    if (mentionedUsers.some((userId) => userId === husslupId)) {
      setIsAddingMention(false);
      return;
    }
    setMentionedUsers((users) => [...users, husslupId]);
    setIsAddingMention(false);
  };

  useEffect(() => {
    if (!comment) {
      setAdjustedRows(1);
      setMentionedUsers([]);
    } else {
      const filteredMentions = mentionedUsers.filter((user) =>
        comment.includes(user),
      );
      setMentionedUsers(filteredMentions);
    }
  }, [comment]);

  return (
    <>
      <PostCommentList
        comments={topTwoComments}
        commentActionGetter={commentProps(postInitiateEditComment)}
        postId={post.post_id}
      />
      <div className='your-comment'>
        <SquircleImage
          imgUrl={ProfileUtils.getProfileImage(
            currentUser?.profile_img ?? '',
            44,
            44,
          )}
          width={44}
          height={44}
          alt='Commenting User Profile'
        />
        <CreateCommentTextArea
          placeholder='Write a Comment...'
          required
          value={comment}
          onChange={(event: any) => {
            setComment(event.target.value?.replaceAll(/\r/g, ''));
            // adjustHeight();
          }}
          wrapperClass='comment'
          inputRef={inputRef}
          rows={adjustedRows}
          cols={cols}
          onUsersMentioned={(id) => {
            getUser(id as string);
          }}
        />
        <button
          onClick={handleCreateComment}
          type='button'
          disabled={isLoading || isAddingMention}
        >
          <HusslUpIcon
            name='sended'
            variant='3x'
            wrapperClass={isLoading ? 'text-light' : 'text-primary'}
          />
        </button>
      </div>
    </>
  );
};

export default PostCommentSection;
