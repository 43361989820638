import ReportDialog, { ReportDialogProps } from 'components/Core/Report';
import DialogBox from 'components/Dialog';
import { DialogBoxProps } from 'components/Dialog/DialogBox';
import React from 'react';
import AddToModel, {
  AddToModalProps,
} from 'components/Core/AddToModel/addToModel';
import {
  LabelConnectionModal,
  LabelConnectionModalProps,
} from './LabelConnectionModal';

type ConnectionsModalsProps = {
  blockDialogPropGetter?: DialogBoxProps;
  reportDialogPropGetter?: ReportDialogProps;
  removeConnectionDialogPropGetter?: DialogBoxProps;
  labelConnectionModalPropGetter?: LabelConnectionModalProps;
  addToModalGetter?: AddToModalProps;
};

const ConnectionsModals = ({
  blockDialogPropGetter,
  reportDialogPropGetter,
  removeConnectionDialogPropGetter,
  labelConnectionModalPropGetter,
  addToModalGetter,
}: ConnectionsModalsProps) => (
  <>
    {blockDialogPropGetter ? <DialogBox {...blockDialogPropGetter} /> : null}
    {removeConnectionDialogPropGetter ? (
      <DialogBox {...removeConnectionDialogPropGetter} />
    ) : null}
    {labelConnectionModalPropGetter ? (
      <LabelConnectionModal {...labelConnectionModalPropGetter} />
    ) : null}
    {reportDialogPropGetter ? (
      <ReportDialog {...reportDialogPropGetter} />
    ) : null}
    {addToModalGetter ? <AddToModel {...addToModalGetter} /> : null}
  </>
);

export default ConnectionsModals;
