import { UnfollowCompanyResponse } from 'commons/types/company.type';
import { ActionButtons } from 'components/Cards';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUserId } from 'store/auth/selectors';
import {
  useFollowCompanyMutation,
  useUnFollowCompanyMutation,
} from 'store/discover/discover.api';
import { CompanyActionParams, setCompanyActionParams } from 'store/my-network';
import ToasterUtils from 'utils/toaster';
import useVisibility from '../useVisibility';

export const useCompanyActionButtons = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('my_network');
  const currentUserId = useSelector(selectCurrentUserId);

  const [
    followCompany,
    { isLoading: isFollowingCompany, originalArgs: followCompanyArgs },
  ] = useFollowCompanyMutation();
  const [
    unFollowCompany,
    { isLoading: isUnfollowingCompany, originalArgs: unfollowCompanyArgs },
  ] = useUnFollowCompanyMutation();

  const {
    show: showUnfollowCompany,
    hide: hideUnfollowCompany,
    isVisible,
  } = useVisibility();

  const initiateUnfollowCompany = (params: CompanyActionParams) => {
    dispatch(setCompanyActionParams(params));
    showUnfollowCompany();
  };

  const handleUnfollow = (companyId: number, userId: number) => async () => {
    if (companyId && userId) {
      const response = (await unFollowCompany({
        companyId,
        userId,
      }).unwrap()) as UnfollowCompanyResponse;
      if (response.companyConnectionRemoved) {
        ToasterUtils.successToast(t('COMPANY_UNFOLLOW_SUCCESS.value'), {
          hideCloseButton: true,
        });
      } else {
        ToasterUtils.errorToast(t('COMPANY_UNFOLLOW_ERROR.value'));
      }
      hideUnfollowCompany();
    }
  };

  const companyUnfollowPropGetter = useCallback(
    (params: CompanyActionParams) => {
      if (params?.companyIdToUnfollow) {
        return {
          show: isVisible,
          title: 'Unfollow Company',
          message: `Are you sure you want to unfollow ${params.companyNameToUnfollow}?`,
          onClose: () => hideUnfollowCompany(),
          onAccept: handleUnfollow(params.companyIdToUnfollow!, currentUserId),
          acceptText: 'Yes',
          closeText: 'Cancel',
          disabled: isUnfollowingCompany,
          loading: isUnfollowingCompany,
        };
      }
      return undefined;
    },
    [isVisible, isUnfollowingCompany, currentUserId],
  );

  const handleFollow = (companyId: number) => async () => {
    if (companyId) {
      const response = await followCompany({
        companyId,
      }).unwrap();
      if (response.affected > 0) {
        ToasterUtils.successToast(t('COMPANY_FOLLOW_SUCCESS.value'), {
          hideCloseButton: true,
        });
      } else {
        ToasterUtils.errorToast(t('COMPANY_FOLLOW_ERROR.value'));
      }
    }
  };

  const getCompanyActionButtons = (
    companyId: number,
    companyName: string,
    isFollowing: boolean | null | undefined,
  ): ActionButtons[] | undefined => {
    if (isFollowing) {
      const isCurrentCompanyBeingUnfollowed =
        unfollowCompanyArgs?.companyId === companyId && isUnfollowingCompany;

      return [
        {
          onClick: () =>
            initiateUnfollowCompany({
              action: 'unfollow',
              companyIdToUnfollow: companyId,
              companyNameToUnfollow: companyName,
            }),
          icon: 'check',
          label: 'Following',
          disabled: isCurrentCompanyBeingUnfollowed,
        },
      ];
    }
    const isCurrentCompanyBeingFollowed =
      followCompanyArgs?.companyId === companyId && isFollowingCompany;

    return [
      {
        onClick: handleFollow(companyId),
        icon: 'plus',
        label: 'Follow',
        disabled: isCurrentCompanyBeingFollowed,
        loading: isCurrentCompanyBeingFollowed,
      },
    ];
  };

  return { getCompanyActionButtons, companyUnfollowPropGetter };
};

export default useCompanyActionButtons;
