import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { NextPageWithLayout } from 'pages/_app';
import useAuth from 'commons/hooks/auth';
import { LOGIN } from 'constants/routes';
import { Noop } from '../Layout';

const Loader = dynamic(() => import('components/Loader'), { ssr: false });

export type AuthStateMeta = {
  hideFooter?: boolean;
  // hideSecondaryNav?: boolean;
};

const withAuthState = (
  Component: NextPageWithLayout<any>,
  meta?: AuthStateMeta,
) =>
  function WrappedComponent(props: any) {
    const router = useRouter();

    const { isLoggedIn, isAuthReady } = useAuth();

    useEffect(() => {
      const redirectToLoginIfNotLoggedIn = async () => {
        if (!isLoggedIn) {
          router.push(
            {
              pathname: LOGIN,
              query: { from: router.asPath },
            },
            LOGIN,
          );
        }
      };
      redirectToLoginIfNotLoggedIn();
    }, [isLoggedIn, isAuthReady]);

    if (!isAuthReady) {
      return <Loader />;
    }

    const Layout = (Component as any).Layout || Noop;

    return (
      <Layout authenticated={isLoggedIn} hideFooter={meta?.hideFooter}>
        <Component {...props} />
      </Layout>
    );
  };

export default withAuthState;
