import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CONTENT_TYPE_APPLICATION_JSON, HTTP_METHODS } from 'constants/http';
import type { RootState } from 'commons/types/redux';
// import { PhoneAccountDetails } from 'components/Account/Login/PhoneLoginForm';

export const AUTH0_API_SLICE = 'auth0Api';

export interface OauthLoginCredentials {
  email: string;
  password: string;
}

export const auth0ApiSlice = createApi({
  reducerPath: AUTH0_API_SLICE,
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders(headers, { getState }) {
      headers.set('content-type', CONTENT_TYPE_APPLICATION_JSON);
      const { token } = (getState() as RootState).auth;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Login'],
  endpoints(builder) {
    return {
      loginUser: builder.mutation<any, OauthLoginCredentials>({
        query(payload: { email: string; password: string }) {
          const { email, password } = payload;
          const requestPayload = {
            username: email,
            password,
          };
          return {
            url: 'api/auth/login',
            method: HTTP_METHODS.POST,
            body: requestPayload,
          };
        },
        invalidatesTags: ['Login'],
      }),

      // loginWithPhone: builder.mutation<any, PhoneAccountDetails>({
      //   query(payload: PhoneAccountDetails) {
      //     const { phoneNumber, code } = payload;
      //
      //     return {
      //       url: 'api/auth/login-with-phone',
      //       method: HTTP_METHODS.POST,
      //       body: { phoneNumber, code },
      //     };
      //   },
      //   invalidatesTags: ['Login'],
      // }),

      logoutUser: builder.mutation<any, void>({
        query() {
          return {
            url: 'api/auth/logout',
            method: HTTP_METHODS.POST,
          };
        },
        invalidatesTags: ['Login'],
      }),
      getAuthUser: builder.query<any, void>({
        query() {
          return {
            url: 'api/auth/user',
            method: HTTP_METHODS.GET,
          };
        },
      }),
    };
  },
});

export const {
  useLoginUserMutation,
  // useLoginWithPhoneMutation,
  useLogoutUserMutation,
} = auth0ApiSlice;
