import { baseApiSlice } from 'commons/apis/base-api.config';
import { GlobalAppConfigResponse } from 'commons/types/global-app-config.type';
import { API_GLOBAL_APP_CONFIG } from 'constants/api';

export const globalAppConfigApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getGlobalAppConfig: builder.query<GlobalAppConfigResponse, void>({
        query: () => `${API_GLOBAL_APP_CONFIG}`,
      }),
    };
  },
});

export const { useGetGlobalAppConfigQuery } = globalAppConfigApiSlice;
