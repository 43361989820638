import { forwardRef } from 'react';
import { Stack } from 'react-bootstrap';

import HusslUpIcon from 'components/Icon';

const PostDropdownToggle = forwardRef<any>(
  ({ children, onClick }: any, ref) => (
    <Stack
      ref={ref}
      onClick={(e: any) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: '0.25rem',
        height: '100%',
      }}
    >
      <HusslUpIcon name='more' wrapperClass='profile-kebab-icon' />
      {children}
    </Stack>
  ),
);

PostDropdownToggle.displayName = 'PostDropdownToggle';

export default PostDropdownToggle;
