import useAuth from 'commons/hooks/auth';
import { manageAccountLinks, NavLinkProps } from 'constants/navbar';
import { COMPANY_ADD, COMPANY_EDIT } from 'constants/routes';
import { memo } from 'react';
import SecondaryNavbar from './SecondaryNavbar';

const ManageAccountSecondaryNavbar = () => {
  const { company } = useAuth();

  const userWhoHasCompanyRoutes = manageAccountLinks.filter(
    (route) => route.path !== COMPANY_ADD,
  );

  const userWhoHasNoCompanyRoutes = manageAccountLinks.filter(
    (route: Omit<NavLinkProps, 'icon'>) => route.path !== COMPANY_EDIT,
  );

  return company ? (
    <SecondaryNavbar navLinks={userWhoHasCompanyRoutes} />
  ) : (
    <SecondaryNavbar navLinks={userWhoHasNoCompanyRoutes} />
  );
};

export default memo(ManageAccountSecondaryNavbar);
