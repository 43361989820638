import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IContactUs } from 'components/ContactUs/ContactUsForm';
import config from 'config';
import { HTTP_METHODS, CONTENT_TYPE_APPLICATION_JSON } from 'constants/http';

const CONTACT_US_API_SLICE = 'contactUsApi';

export const contactUsApiSlice = createApi({
  reducerPath: CONTACT_US_API_SLICE,
  baseQuery: fetchBaseQuery({
    baseUrl: config.zendesk.baseUrl,
    prepareHeaders(headers) {
      headers.set('content-type', CONTENT_TYPE_APPLICATION_JSON);
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      createTicket: builder.mutation<any, IContactUs>({
        query(payload) {
          const { email, subject, message, firstName, lastName } = payload;

          const requestPayload = {
            request: {
              subject,
              requester: {
                name: `${firstName} ${lastName}`,
                email,
                verified: false,
              },
              comment: {
                body: message,
              },
            },
          };

          return {
            url: '/requests',
            method: HTTP_METHODS.POST,
            body: requestPayload,
          };
        },
      }),
    };
  },
});

export const { useCreateTicketMutation } = contactUsApiSlice;
