import { APP_ENVIRONMENT_PRODUCTION, LANGUAGES } from "constants/app";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import resources from "lang";

export const nameSpaces = ["common", "users", "errors", "connection"];

export const defaultNS = "common";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: LANGUAGES.english,
    lng: LANGUAGES.english,
    debug: process.env.mode === APP_ENVIRONMENT_PRODUCTION,
    ns: nameSpaces,
    defaultNS,
    returnNull: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
  });

export { i18n as translator };

export default i18n;
