import { Activity } from 'getstream';
import { FunctionComponent } from 'react';
import timeSince, { formatIso8601DateToUTCDate } from 'utils/date-time';
import { useGetSingleStaffingRosterForNotificationsQuery } from 'store/staffing-roster';
import Typography from 'components/Core/StylesGuide/Typography';
import HusslUpIcon from 'components/Icon';

const StaffingRosterReframeSubmitActivity: FunctionComponent<{
  activity: Activity;
}> = ({ activity }) => {
  const actedEntity = activity.object as string;
  const rosterId = actedEntity.split(':')[1].trim();
  const { isLoading } =
    useGetSingleStaffingRosterForNotificationsQuery(rosterId);

  if (isLoading) {
    return <></>;
  }

  return (
    <li>
      <div className='notification-item'>
        <div className='notification-face icon'>
          <HusslUpIcon name='share' />
        </div>
        <div className='notification-content'>
          <Typography variant='p-md-d'>
            {activity.verb === 'staffingRosterReframeSubmit' ? (
              <>
                You have submitted your Demographic Report for the{' '}
                <b className='fw-600'>ReFrame Gender Parity Stamp.</b>{' '}
              </>
            ) : (
              ''
            )}{' '}
          </Typography>
          <div className='timestamp'>
            {timeSince(formatIso8601DateToUTCDate(activity?.time))}
          </div>
        </div>
      </div>
    </li>
  );
};

export default StaffingRosterReframeSubmitActivity;
