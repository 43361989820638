import React, { useCallback, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { debounce } from 'debounce';
import { useRouter } from 'next/router';

import { DEBOUNCE_SECONDS } from 'constants/commons';
import GenericDropdown, {
  GenericDropdownItem,
} from 'components/Core/GenericDropdown';
import { SortBy } from 'commons/types/base';
import { useAppDispatch, useAppSelector } from 'commons/hooks/redux';
import { ConnectionUtils } from 'utils/connection';
import { setGetNetworkParam } from 'store/my-network';
import {
  ConnectionsSortOptions,
  FollowedCompaniesSortOptions,
  ReferralInviteSortOptions,
} from 'commons/data/pages/my-network';
import { selectActiveMyNetworkParams } from 'store/my-network/selectors';
import NetworkHeader from './NetworkHeader';

type MyNetworkHeaderProps = {
  selectedSortBy: SortBy;
};

const MyNetworkHeader = ({ selectedSortBy }: MyNetworkHeaderProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const activeRoute = useMemo(
    () => ConnectionUtils.getActiveNetworkRoute(router.pathname),
    [router.pathname],
  );

  // const activeTab = useAppSelector(selectNetworkActiveTab(activeRoute)) || '';
  const currentParams = useAppSelector(
    selectActiveMyNetworkParams(activeRoute),
  ) as any;

  const handleSearchTextChange = (searchTerm: string) => {
    debounceSetSearchText(searchTerm);
  };

  const debounceSetSearchText = useCallback(
    debounce((text: string) => {
      dispatch(
        setGetNetworkParam({ options: { searchText: text }, activeRoute }),
      );
    }, DEBOUNCE_SECONDS),
    [],
  );

  // useEffect(() => {
  //   // dispatch(setResetConnections());

  // }, [activeRoute]);

  const sortOptions = useMemo(() => {
    if (activeRoute.referralInvites) {
      return ReferralInviteSortOptions.map((option) => ({
        ...option,
        onClick: () => {
          dispatch(
            setGetNetworkParam({
              options: { sortBy: option.key },
              activeRoute,
            }),
          );
        },
      }));
    }
    if (activeRoute.followedCompanies) {
      return FollowedCompaniesSortOptions.map((option) => ({
        ...option,
        onClick: () => {
          dispatch(
            setGetNetworkParam({
              options: { sortBy: option.key },
              activeRoute,
            }),
          );
        },
      }));
    }
    const dropdownOptions: GenericDropdownItem[] = ConnectionsSortOptions.map(
      (option) => ({
        ...option,
        onClick: () => {
          dispatch(
            setGetNetworkParam({
              options: { sortBy: option.key },
              activeRoute,
            }),
          );
        },
      }),
    );
    return dropdownOptions;
  }, [activeRoute]);

  const selectedDropdownOption = useMemo(() => {
    if (activeRoute.referralInvites) {
      const selectedOptions = ReferralInviteSortOptions.find(
        (option) => option.key === selectedSortBy,
      );
      return selectedOptions?.label ?? ReferralInviteSortOptions[0].label;
    }
    if (activeRoute.followedCompanies) {
      const selectedOptions = FollowedCompaniesSortOptions.find(
        (option) => option.key === selectedSortBy,
      );
      return selectedOptions?.label ?? FollowedCompaniesSortOptions[0].label;
    }
    const selectedOptions = ConnectionsSortOptions.find(
      (option) => option.key === selectedSortBy,
    );
    return selectedOptions?.label ?? ConnectionsSortOptions[0].label;
  }, [selectedSortBy, activeRoute]);

  return (
    <div className='mt-1 d-flex justify-content-between'>
      <NetworkHeader
        onFilter={handleSearchTextChange}
        searchTerm={currentParams?.searchText}
      />
      <GenericDropdown
        align='end'
        dropdownItems={sortOptions}
        toggleRootComponent={
          <Dropdown.Toggle
            variant='secondary'
            className='d-flex align-items-center gap-2 px-3'
          >
            {selectedDropdownOption}
            <i className='hic-chevron-down p-md' />
          </Dropdown.Toggle>
        }
      />
    </div>
  );
};

export default MyNetworkHeader;
