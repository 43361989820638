import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'commons/types/redux';
import { EditProfileUtils } from 'utils/edit-profile.utils';
import { selectFetchedUserLinkedInProfile } from 'store/user-linkedIn/selectors';
import { authApiSlice } from './auth.api';

export const selectIsLoggedIn = (state: RootState) => state.auth.token;
export const selectIsAuthReady = (state: RootState) => state.auth.isAuthReady;
export const selectTokenTimeout = (state: RootState) => state.auth.timeout;
export const selectUserEmail = (state: RootState) => state.auth.userEmail;
export const selectIsLoggingInAfterSignup = (state: RootState) =>
  state.auth.isLoggingInAfterSignup;
export const selectLoginPhoneNumber = (state: RootState) =>
  state.auth.loginPhoneNumber;

export const selectIsPhoneLoginUser = (state: RootState) =>
  !state.auth.isEmailLogin;

export const selectAuthUser = createSelector(
  authApiSlice.endpoints.getCurrentAuthUser.select(),
  (response) => response.data,
);

export const selectCurrentUserLoading = createSelector(
  authApiSlice.endpoints.getCurrentAuthUser.select(),
  (response) => response.isLoading,
);

export const selectCurrentUser = createSelector(
  authApiSlice.endpoints.getCurrentAuthUser.select(),
  (response) => response.data!,
);

export const selectCurrentUserCompany = createSelector(
  selectAuthUser,
  (user) => user?.company,
);

export const selectCurrentUserGetstreamToken = createSelector(
  selectAuthUser,
  (user) => user?.getstreamToken,
);

export const selectCurrentUserId = createSelector(
  selectCurrentUser,
  (user) => user?.id || 0,
);

export const selectCurrentUserHusslupId = createSelector(
  selectCurrentUser,
  (user) => user?.husslupId,
);

export const selectIsDemographicsAllowed = createSelector(
  selectCurrentUser,
  (user) => user?.allowDemographics,
);

export const selectUpdateAccountInfoInitialValues = createSelector(
  selectCurrentUser,
  (user) => ({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    professionalTagline: user?.professionalTagline ?? '',
    projectTagline: user?.projectTagline ?? '',
    bio: user?.bio ?? '',
    demographics: user?.demographics ?? '',
  }),
);

export const selectUpdateAccountInfoAndLinkedInInitialValues = createSelector(
  selectCurrentUser,
  selectFetchedUserLinkedInProfile,
  (user, linkedinInfo) => ({
    firstName:
      user?.firstName ||
      (linkedinInfo?.isVerified === true && linkedinInfo?.firstName) ||
      '',
    lastName:
      user?.lastName ||
      (linkedinInfo?.isVerified === true && linkedinInfo?.lastName) ||
      '',
    professionalTagline:
      user?.professionalTagline ||
      (linkedinInfo?.isVerified === true && linkedinInfo?.position) ||
      '',
    projectTagline:
      user?.projectTagline ||
      (linkedinInfo?.isVerified === true && linkedinInfo?.company) ||
      '',
    bio: user?.bio ?? '',
    demographics: user?.demographics ?? '',
  }),
);

export const selectUpdateAccountInfoInitialValuesV2 = createSelector(
  selectCurrentUser,
  (user) => ({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    professionalTagline: user?.professionalTagline ?? '',
    projectTagline: user?.projectTagline ?? '',
    bio: user?.bio ?? '',
    demographics: user?.demographics ?? [],
    showIIdentityAs: user?.showIIdentityAs ?? false,
  }),
);

export const selectEditProfileDetailsInitialValues = createSelector(
  selectCurrentUser,
  (user) => ({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    professionalTagline: user?.professionalTagline ?? '',
    projectTagline: user?.projectTagline ?? '',
    bio: user?.bio ?? '',
    readyToHusslFromDate: user?.readyToHusslFromDate ?? '',
    workStatuses:
      EditProfileUtils.workStatusToOption(
        EditProfileUtils.getWorkStatuses(
          user?.workStatuses ?? [],
          user?.workStatus ?? '',
        ),
      ) ?? [],
  }),
);

export const selectPersonalDetailsForPreview = createSelector(
  selectCurrentUser,
  (user) => ({
    firstName: user?.firstName ?? 'Your',
    lastName: user?.lastName ?? 'Name',
    position: user?.professionalTagline ?? 'Position / Role',
    company: user?.projectTagline ?? 'Company / Production',
    bio: user?.bio ?? 'Brag a little',
    profileImg: user?.profile_img,
  }),
);

export const selectBioInitialValues = createSelector(
  selectCurrentUser,
  (user) => ({
    bio: user?.bio ?? '',
  }),
);

export const selectHasNewNotification = (state: RootState) =>
  state.auth.hasNewNotification;
