import { FC, ReactElement } from 'react';

export type BadgeProps = {
  badgeType: string;
  badgeLabel: string;
};

const Badge: FC<BadgeProps> = ({
  badgeType,
  badgeLabel,
}: BadgeProps): ReactElement => (
  <div className={`badge ${badgeType}`}>{badgeLabel}</div>
);
export default Badge;
