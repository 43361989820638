import { ScriptType } from 'store/user/creative/types';
import { UserType } from 'store/user/types';

export enum UserStatus {
  OWNER = 'OWNER',
  MEMBER = 'MEMBER',
}

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELLED = 'CANCELLED',
  PRE_CANCELLED = 'PRE_CANCELLED',
  EXPIRED = 'EXPIRED',
  FAILED_CHARGED = 'FAILED_CHARGED',
  AWAITING_PAYMENT_METHOD = 'AWAITING_PAYMENT_METHOD',
}

export type UserSubscriptions = {
  id: number;
  user: UserType;
  totalSeats: number;
  usedSeats: number;
  subscriptionStatus: SubscriptionStatus;
  subscriptionColor?: string;
  subscriptionInitials?: string;
  subscriptionName: string;
  paymentMethod: string;
  createdAt: Date;
  updatedAt: Date;
  isDeleted: boolean;
  owner?: UserType;
};

export type SubscriptionItem = {
  billing_thresholds?: string;
  created: Date;
  id: string;
  metadata: any;
  object: string;
  plan: any;
  price: any;
  quantity: number;
  subscription: string;
  tax_rates: any[];
};

export type SubscriptionMember = {
  id: number;
  user?: UserType;
  subscription?: UserSubscriptions;
  userStatus: UserStatus;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export enum PaymentPlan {
  INDIVIDUAL_MONTHLY = 'Individual_Monthly',
  INDIVIDUAL_ANNUALLY = 'Individual_Annual',
  COMPANY_MONTHLY = 'Company_Monthly',
  COMPANY_ANNUALLY = 'Company_Annual',
  STUDIO_MONTHLY = 'Studio_Monthly',
  STUDIO_ANNUALLY = 'Studio_Annual',
  STUDIO_WEEKLY = 'Studio_Weekly',
  STUDIO_BIWEEKLY = 'Studio_Biweekly',
}

export enum PaymentMode {
  SPIELBERG = 'SPIELBERG',
}

export type SubscriptionPlan = {
  amount: number;
  createdAt: Date;
  id: number;
  isDeleted: boolean;
  paymentFrequency: string;
  paymentPlan: PaymentPlan;
  updatedAt: Date;
};

export type SubscriptionChangeOwnershipBody = { newOwnerId: number };

export type SubscriptionCreatePaymentBody = {
  amount: number;
  couponCode?: string;
  currency?: string;
  assetId: number;
  creativeSampleId: number;
  scriptType: ScriptType;
};

export type SubscriptionCreatePaymentResponse = {
  clientSecret: {
    amount: number;
    client_secret: string;
    amount_capturable: number;
    amount_received: 0;
    application: null;
    application_fee_amount: null;
    canceled_at: null;
    cancellation_reason: null;
    capture_method: string;
    confirmation_method: string;
    currency: string;
    id: string;
  };
};

export type GetPromoCodeUpdatesBody = {
  amount: number;
  promoCode: string;
};

export type GetPromoCodeUpdatesResponse = {
  promoCode: string;
  discount: number;
  payableAmount: number;
  promoCodeId: string;
};

export type SubscriptionChangeOwnershipParameter = {
  subscriptionId: number;
  body: SubscriptionChangeOwnershipBody;
};

export type SubscriptionChangeOwnershipResponse = SubscriptionMember[];

export type GetUserSubscription = {
  id: number;
  cancel_at?: number;
  cancel_at_period_end?: boolean;
  subscriptionColor?: string;
  subscriptionInitials?: string;
  canceled_at?: number;
  totalSeats: number;
  usedSeats: number;
  subscriptionStatus: string;
  subscriptionName: string;
  paymentIntentId: string;
  isDeleted: boolean;
  stripeSubscriptionId: string;
  stripeCustomerId: string;
  paymentMethod: ActivePaymentMethod;
  createdAt: string;
  updatedAt: string;
  trialStart: number;
  trialEnd: number;
  plan: ActivePlan;
  subscriptionPaymentPlan: SubscriptionPlan;
  customer: StripeCustomer;
  items: SubscriptionItem[];
  paymentMode?: PaymentMode;
};

export type PaymentBillingDetails = {
  address: {
    city?: string;
    country?: string;
    line1?: string;
    line2?: string;
    postal_code?: string;
    state?: string;
  };
  email: string;
  name: string;
  phone: string;
};
export type ActivePaymentMethod = {
  id: string;
  last4: string;
  cardBrand: string;
  billingDetails: PaymentBillingDetails;
  expiryDate: { year: number; month: number };
};

export type StripeCustomer = {
  name: string;
  email: string;
  address?: {
    city?: string;
    country?: string;
    line1?: string;
    line2?: string;
    postal_code?: string;
    state?: string;
  };
};

export type ActivePlan = {
  id: string;
  object: string;
  active: boolean;
  aggregate_usage: any;
  amount: number;
  amount_decimal: string;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: [];
  nickname: string;
  product: string;
  tiers_mode: any;
  transform_usage: any;
  trial_period_days: any;
  usage_type: string;
};

export type SubscribeAddress = {
  city: string;
  country: string;
  line1?: string;
  line2?: string;
  postal_code?: string;
  state?: string;
};

export type InitializeSubscriptionBody = {
  fullname: string;
  email: string;
  paymentMethod: string;
  paymentIntentId: string;
  // change these for multiple plans
  // make these dynamic
  paymentPlanId: number;
  paymentPlan: string;
  priceId: string;
  subscriptionName: string;
  address?: SubscribeAddress;
};

export type InitializeSubscriptionResponse = {
  success: boolean;
  message: string;
  subscriptionId: number;
  clientSecret: string;
};

export type FinalizeSubscriptionBody = {
  subscriptionName: string;
  subscriptionId: number;
};

export type FinalizeSubscriptionResponse = {
  success: boolean;
  message: string;
};

export type UpdatePaymentMethodBody = {
  subscriptionId: number;
  body: {
    paymentMethod: string;
    email?: string;
    name?: string;
    address?: SubscribeAddress;
    paymentIntentId: string;
  };
};

export type UpdateSubscriptionPlanBody = {
  subscriptionId: number;
  body: {
    itemId: string;
    priceId: string;
    paymentPlanId: number;
    paymentPlan: string;
  };
};

export type UpdateRecipientEmailBody = {
  subscriptionId: number;
  body: {
    email: string;
    paymentIntentId: string;
  };
};

export type ResumeSubscriptionBody = {
  subscriptionId: number;
  body: {
    priceId?: string;
    subscriptionName?: string;
    fullname?: string;
    email?: string;
    paymentMethod?: string;
    paymentPlan?: string;
    paymentPlanId?: number;
    address?: SubscribeAddress;
    paymentIntentId?: string;
  };
};

export type UpdateSubscriptionNameBody = {
  subscriptionId: number;
  body: {
    subscriptionName: string;
  };
};

export type AssignSubscriptionMemberSeatBody = {
  ownerId: number;
  memberIds: string[];
  subscriptionId: number;
};

export type RemoveSubscriptionMemberSeatBody = {
  subscriptionId: number;
  memberIdToRemove: number;
};

export type UpdateSubscriptionNameResponse = {
  success: boolean;
  message: string;
};

export type UpdateSubscriptionResponse = {
  success: boolean;
  message: string;
};

export type UpdatePaymentMethodResponse = {
  success: boolean;
  message: string;
};

// Subscription form
export type SubscriptionFormValues = {
  subscriptionName: string;
  cardholderNumber: string;
  email: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
};

// Receipts
export type SubscriptionReceipt = {
  amount: number;
  receiptNo: number;
  created: number;
  hostedUrl: string;
  status: string;
  plan: string;
  receiptUrl: string;
  invoicePdf: string;
};

export type ActiveStaffingSubscription = {
  id?: number;
  isExternalSubscription?: boolean;
};

// Slice types
export type SubscriptionSliceState = {
  isResumingCanceledPlan?: boolean;
  activeStaffingSubscription?: ActiveStaffingSubscription;
  selectedSubscriptionPlan?: SubscriptionPlan;
  selectedSubscriptionPlanMeta?: {
    planType: string;
    planInterval: string;
    planFrequency: string;
  };
};

export type GetAllUsersExceptOwnerResponse = {
  fullname: string;
  husslupId: string;
  id: number;
  profile_img: string;
};

export type GetAllUsersExceptOwnerBody = {
  searchText?: string;
  subscriptionId: number;
};

export type PlanType = 'individual' | 'company' | 'studio';
export type PlanFrequencyType = 'annually' | 'monthly';
