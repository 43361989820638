import { selectCurrentUserId } from 'store/auth/selectors';
import { useBlockUserMutation } from 'store/block-users';
import { useDeleteCommentMutation } from 'store/post/comment.api';
import { useDeletePostMutation } from 'store/post/post.api';
import { PostActionParams, setPostActionParams } from 'store/post/post.slice';
import {
  selectGetPostWithTagParams,
  selectPostActionParams,
  selectPostTags,
} from 'store/post/selectors';
import { useGetPostTagsQuery } from 'store/post/post-tag.api';
import { useAppDispatch, useAppSelector } from './redux';
import useVisibility from './useVisibility';

const usePostActions = () => {
  const dispatch = useAppDispatch();
  const actionParams = useAppSelector(selectPostActionParams);
  const getPostWithTagParams = useAppSelector(selectGetPostWithTagParams);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [deletePost, { isLoading: isDeletingPost }] = useDeletePostMutation();
  const [deleteComment, { isLoading: isDeletingComment }] =
    useDeleteCommentMutation();
  const [blockUser, { isLoading: isBlockingUser }] = useBlockUserMutation();
  const postTags = useAppSelector(selectPostTags);

  const { data } = useGetPostTagsQuery(
    {
      screenRequesting: 'create-post',
    },
    {
      skip: (postTags || [])?.length > 0,
    },
  );

  const {
    hide: hideDelete,
    show: showDelete,
    isVisible: isDeleteVisible,
  } = useVisibility();

  const {
    hide: hideEdit,
    show: showEdit,
    isVisible: isEditVisible,
  } = useVisibility();

  const {
    hide: hideDeleteComment,
    show: showDeleteComment,
    isVisible: isDeleteCommentVisible,
  } = useVisibility();

  const {
    hide: hideBlock,
    show: showBlock,
    isVisible: isBlockVisible,
  } = useVisibility();

  const {
    hide: hideReportPost,
    show: showReportPost,
    isVisible: isReportPostVisible,
  } = useVisibility();

  const handleCloseReportPost = () => {
    setPostActionParams({ postId: null });
    hideReportPost();
  };

  const handleDelete = async () => {
    if (actionParams?.postId && currentUserId === actionParams?.postedById) {
      const response = await deletePost({
        body: { postId: actionParams.postId },
        getPostWithTagParams,
      }).unwrap();
      if (response) {
        hideDelete();
      }
    }
  };

  const handleDeleteComment = async () => {
    if (
      actionParams.commentId &&
      actionParams.postId &&
      currentUserId === actionParams?.commentedById
    ) {
      const response = await deleteComment({
        body: { commentId: actionParams.commentId },
        getPostWithTagParams,
        postId: actionParams.postId,
        commentLevel: actionParams.commentLevel,
      }).unwrap();
      if (response) {
        hideDeleteComment();
      }
    }
  };

  const handleBlock = async () => {
    if (
      actionParams?.postId &&
      actionParams?.postedById &&
      currentUserId !== actionParams?.postedById
    ) {
      const response = await blockUser({
        body: { userId: actionParams?.postedById },
        getPostWithTagParams,
      }).unwrap();
      if (response) {
        hideBlock();
      }
    }
  };

  const initiateDelete = (params: PostActionParams) => () => {
    dispatch(setPostActionParams(params));
    showDelete();
  };

  const initiateEdit = (params: PostActionParams) => () => {
    dispatch(setPostActionParams(params));
    showEdit();
  };

  const initiateBlock = (params: PostActionParams) => () => {
    dispatch(setPostActionParams(params));
    showBlock();
  };

  const initiateReportPost = (params: PostActionParams) => () => {
    dispatch(setPostActionParams(params));
    showReportPost();
  };

  const initiateDeleteComment = (params: PostActionParams) => () => {
    dispatch(setPostActionParams(params));
    showDeleteComment();
  };

  const initiateEditComment = (params: PostActionParams) => {
    dispatch(setPostActionParams(params));
  };

  const getPostOptions = ({
    postId,
    postedById,
  }: Omit<PostActionParams, 'action'>) => {
    const isViewingOwnPost = postedById === currentUserId;
    if (isViewingOwnPost) {
      return [
        {
          label: 'Edit',
          onClick: initiateEdit({ postedById, postId }),
        },
        {
          label: 'Delete',
          onClick: initiateDelete({ postedById, postId, action: 'delete' }),
        },
      ];
    }
    return [
      {
        label: 'Block Member',
        onClick: initiateBlock({ postedById, postId, action: 'delete' }),
      },
      {
        label: 'Report',
        onClick: initiateReportPost({
          postedById,
          postId,
          action: 'report-post',
        }),
      },
    ];
  };

  const getCommentOptions = (
    {
      postId,
      commentedById,
      commentId,
      commentLevel,
    }: Omit<PostActionParams, 'action'>,
    postInitiateCommentEdit: (
      postId: number,
      commentId: number,
      commentLevel: number,
    ) => void,
  ) => {
    const isViewingOwnComment = commentedById === currentUserId;
    if (isViewingOwnComment && commentId) {
      return [
        {
          label: 'Delete',
          onClick: initiateDeleteComment({
            postId,
            commentedById,
            commentId,
            action: 'delete-comment',
            commentLevel,
          }),
        },
        {
          label: 'Edit Comment',
          onClick: () => {
            initiateEditComment({
              postId,
              commentedById,
              commentId,
              action: 'edit-comment',
              commentLevel,
            });
            postInitiateCommentEdit(postId!, commentId, commentLevel!);
          },
        },
      ];
    }
    return [];
  };

  return {
    isBlockVisible,
    isDeleteVisible,
    isEditVisible,
    handleBlock,
    hideBlock,
    hideDelete,
    hideEdit,
    handleDelete,
    getPostOptions,
    deleteDialogPropGetter: () => ({
      disabled: isDeletingPost,
      loading: isDeletingPost,
      show: isDeleteVisible,
      onAccept: handleDelete,
      onClose: hideDelete,
    }),
    editDialogPropGetter: () => ({
      showModal: isEditVisible,
      onFormSubmitSuccess: hideEdit,
      onDismissModal: hideEdit,
      postTags: data || postTags,
      ...actionParams,
    }),
    reportPostDialogPropGetter: () => ({
      show: isReportPostVisible,
      onClose: handleCloseReportPost,
      postId:
        actionParams.action === 'report-post' ? actionParams.postId : null,
    }),
    showReportPost,
    blockDialogPropGetter: () => ({
      disabled: isBlockingUser,
      loading: isBlockingUser,
      show: isBlockVisible,
      onAccept: handleBlock,
      onClose: hideBlock,
    }),
    getCommentOptions,
    hideDeleteComment,
    handleDeleteComment,
    isDeleteCommentVisible,
    isDeletingComment,
    deleteCommentDialogPropGetter: () => ({
      disabled: isDeletingComment,
      loading: isDeletingComment,
      show: isDeleteCommentVisible,
      onAccept: handleDeleteComment,
      onClose: hideDeleteComment,
    }),
  };
};

export default usePostActions;
