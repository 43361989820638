import React, { FunctionComponent, memo, useMemo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import useAuth from 'commons/hooks/auth';
import HusslUpIcon from 'components/Icon';
import {
  COMPANY,
  COMPANY_ADD,
  HELP_INFO,
  PROFILE_EDIT_COMPANY_PROFILE,
  PROFILE_EDIT_PERSONAL_PROFILE,
  PROFILE_MANAGE_ACCOUNT,
  PROFILE_MANAGE_SUBSCRIPTION,
} from 'constants/routes';
import SuperEllipse from 'react-superellipse';
import ProfileUtils from 'utils/profile';
import Typography from 'components/Core/StylesGuide/Typography';
import NextImage from 'components/Core/Image';
import { useAppSelector } from 'commons/hooks/redux';
import {
  selectCurrentUserCompany,
  selectCurrentUserId,
} from 'store/auth/selectors';
import NavLink from 'components/Core/NavLink';
import { UserProfilePlaceHolder } from 'components/Placeholders';
import { MEMBERCARD_MEMBER_NAME_MAX_LENGTH } from 'constants/app';
import { MemberChickletTypeDefault } from '../Chicklet';

const ProfileDropdown: FunctionComponent = () => {
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const userId = useAppSelector(selectCurrentUserId);
  const authUserCompany = useAppSelector(selectCurrentUserCompany);

  const tier = useMemo(() => user?.tier || MemberChickletTypeDefault, [user]);
  // const [showDropdown, setShowDropdown] = useState(false);
  return (
    <Dropdown align='end'>
      <Dropdown.Toggle as='div'>
        <div className='nav-profile'>
          <span className='pp'>
            <NextImage
              height={32}
              width={32}
              src={ProfileUtils.getProfileImage(user?.profile_img, 32, 32)}
            />
          </span>
          <div
            style={{
              maxWidth: '250px',
            }}
          >
            <Typography variant='p-sm-d' lineClampNo={1}>
              {user?.fullname}
            </Typography>
          </div>
          <HusslUpIcon name='chevron-down' wrapperClass='hic-20' />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className='profile-dropdown'>
        <NavLink path={`/Profile/${userId}`} wrapperClass='user-profile'>
          <span className='pp'>
            <NextImage
              height={60}
              width={60}
              src={ProfileUtils.getProfileImage(user?.profile_img, 60, 60)}
              alt='pp'
            />
          </span>
          <div className='user-profile-details'>
            <div className='identity'>
              <Typography variant='p-lg-b' lineClampNo={1}>
                {user?.fullname}
              </Typography>
              <div className={`badge badge-${tier?.toLowerCase()}`}>{tier}</div>
              {/* <HusslUpIcon name='verified-fill' /> */}
            </div>
            <div className='d-flex flex-column'>
              <div>
                <Typography
                  variant='p-sm-m'
                  lineClampNo={1}
                  title={user?.professionalTagline}
                  trimNumber={MEMBERCARD_MEMBER_NAME_MAX_LENGTH}
                >
                  {user?.professionalTagline}
                </Typography>
              </div>
              <div>
                <Typography
                  variant='p-sm-m'
                  lineClampNo={1}
                  title={user?.projectTagline}
                  trimNumber={MEMBERCARD_MEMBER_NAME_MAX_LENGTH}
                >
                  {user?.projectTagline}
                </Typography>
              </div>
            </div>
          </div>
        </NavLink>

        <NavLink path={PROFILE_EDIT_PERSONAL_PROFILE}>
          <HusslUpIcon name='edit' variant='1x' />
          Edit Personal Profile
        </NavLink>
        <Dropdown.Divider />
        {authUserCompany ? (
          <>
            <NavLink
              path={`${COMPANY}/${authUserCompany.id}`}
              wrapperClass='company-profile'
            >
              <SuperEllipse r1={0.04} r2={0.5}>
                <NextImage
                  src={
                    authUserCompany.logo
                      ? ProfileUtils.getProfileImage(
                          ProfileUtils.getImageFileFromAwsUrl(
                            authUserCompany.logo,
                          ),
                          60,
                          60,
                        )
                      : UserProfilePlaceHolder
                  }
                  width={60}
                  height={60}
                  alt='Profile Pic12'
                />
              </SuperEllipse>
              <div className='company-profile-details'>
                <div className='identity'>
                  <Typography variant='p-lg-b' lineClampNo={1}>
                    {authUserCompany.company_name}
                  </Typography>
                </div>
                <div className='d-flex'>
                  <Typography variant='p-sm-m' lineClampNo={1}>
                    {authUserCompany.pro_headline}
                  </Typography>
                </div>
              </div>
            </NavLink>
            <NavLink path={`${PROFILE_EDIT_COMPANY_PROFILE}`}>
              <HusslUpIcon name='edit' variant='1x' />
              Edit Company Profile
            </NavLink>
          </>
        ) : (
          <NavLink path={`${COMPANY_ADD}/CompanyProfileDetails`}>
            <HusslUpIcon name='plus' variant='1x' />
            Add Company Profile
          </NavLink>
        )}
        <Dropdown.Divider />

        <NavLink path={PROFILE_MANAGE_ACCOUNT}>
          <HusslUpIcon name='settings' variant='1x' />
          Manage Account
        </NavLink>

        <NavLink path={PROFILE_MANAGE_SUBSCRIPTION} disableActiveState>
          <HusslUpIcon name='credit-card' variant='1x' />
          Manage Subscription
        </NavLink>

        <NavLink path='/Info/MemberPerks'>
          <HusslUpIcon variant='1x' name='member-perks' />
          Member Perks
        </NavLink>
        <NavLink path='/Info/AboutUs'>About Us</NavLink>
        <NavLink path='/Info/JoinUs'>Join Our Team</NavLink>
        <NavLink path='/Blog'>Blog</NavLink>
        <a className='nav-link' href='http://podcast.husslup.com'>
          Podcast
        </a>

        <NavLink path={HELP_INFO}>
          <HusslUpIcon name='info' variant='1x' />
          Help and Info
        </NavLink>

        <Dropdown.Divider />

        <Dropdown.Item
          className='text-uppercase p-md-b p-md mt-h'
          onClick={() => {
            logout();
          }}
        >
          {t('logout')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default memo(ProfileDropdown);
