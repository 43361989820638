import React, { FunctionComponent, ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export interface ITab {
  key: string;
  title: string;
  content?: ReactElement | boolean;
  tabClassName?: string;
}

export interface HusslTab {
  tabs: ITab[];
  loading?: boolean;
  defaultActiveKey: string | number;
  onTabClicked?: (eventKey: any) => void;
  className?: string;
}

type Props = {
  children: ReactElement;
};

const SpinnerWrapper: FunctionComponent<Props> = ({ children }) => (
  <div
    style={{
      display: 'flex',
      height: '50vh',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {children}
  </div>
);

const HusslTab: FunctionComponent<HusslTab> = ({
  tabs,
  defaultActiveKey,
  onTabClicked,
  loading,
  className,
}): ReactElement => (
  <Tabs
    defaultActiveKey={defaultActiveKey}
    activeKey={defaultActiveKey}
    onSelect={onTabClicked}
    className={className}
  >
    {tabs.map(
      (tab: ITab): ReactElement => (
        <Tab
          key={tab.key}
          eventKey={tab.key}
          title={tab.title}
          tabClassName={tab?.tabClassName || ''}
          active={tab.key === defaultActiveKey}
        >
          {!loading && tab.content}
          {loading && (
            <SpinnerWrapper>
              <Spinner animation='border' />
            </SpinnerWrapper>
          )}
        </Tab>
      ),
    )}
  </Tabs>
);

export default HusslTab;
