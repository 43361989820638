import { baseApiSlice } from 'commons/apis/base-api.config';
import axios from 'axios';
import {
  API_PROFILE_LIST,
  API_STAFFING_PROSEARCHES,
  API_STAFFING_TOOLS_DUPLICATE,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
import config from 'config';
import { RootState } from 'commons/types/redux';
import { DuplicateStaffingToolsType } from 'store/types';
import {
  SharableUsersListPayload,
  AddMember,
  FilterCriteriaPayload,
  ProSearchResponse,
  ProSearchWithOwner,
  SingleProsearchResponse,
  EditProSearchJobDescription,
} from './prosearch.types';
import { setUploadProgress } from './prosearch.slice';

export interface ProSearchPayload {
  searchText?: string;
  userId: number;
}

export interface CreateProSearchPayload {
  listId: number;
  memberId: number;
  note?: string;
}

export const prosearchApiSlice: any = baseApiSlice.injectEndpoints({
  endpoints: (builder) =>
    ({
      getAllProsearches: builder.query<Array<any>, any>({
        query: ({ subId }) =>
          `${API_STAFFING_PROSEARCHES}/subscription/${subId}`,
        transformResponse: (data: any, meta, args) => {
          data.allProsearches = [...data.data, ...data.sharedProsearches].sort(
            (a, b) =>
              new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime(),
          );
          data.data = data.data.sort(
            (a: any, b: any) =>
              new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime(),
          );
          data.sharedProsearches = data.sharedProsearches.sort(
            (a: any, b: any) =>
              new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime(),
          );
          data.externalSubscriptions = data.externalSubscriptions.sort(
            (a: any, b: any) =>
              new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime(),
          );
          data.archivedProsearches = data.data?.filter(
            (a: any) => a.status === 'archived',
          );

          if (args.searchTerm) {
            data.data = data?.data?.filter(
              (prosearch: any) =>
                prosearch.name
                  .toLowerCase()
                  .includes(String(args.searchTerm).toLowerCase()) &&
                prosearch.status !== 'archived',
            );

            data.sharedProsearches = data.sharedProsearches.filter(
              (prosearch: any) =>
                prosearch.name
                  .toLowerCase()
                  .includes(String(args.searchTerm).toLowerCase()) &&
                prosearch.status !== 'archived',
            );

            data.externalSubscriptions = data.externalSubscriptions.filter(
              (prosearch: any) =>
                prosearch.name
                  .toLowerCase()
                  .includes(String(args.searchTerm).toLowerCase()) &&
                prosearch.status !== 'archived',
            );

            data.allProsearches = [...data.data, ...data.sharedProsearches];

            data.archivedProsearches = data.sharedProsearches.filter(
              (prosearch: any) =>
                prosearch.name
                  .toLowerCase()
                  .includes(String(args.searchTerm).toLowerCase()) &&
                prosearch.status === 'archived',
            );
          }
          if (args.sortBy === 'asc') {
            data.data = data.data.sort((a: any, b: any) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            );
            data.sharedProsearches = data.sharedProsearches.sort(
              (a: any, b: any) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            );
            data.externalSubscriptions = data.externalSubscriptions.sort(
              (a: any, b: any) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            );
            data.allProsearches = data.allProsearches.sort((a: any, b: any) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            );

            data.archivedProsearches = data.archivedProsearches.sort(
              (a: any, b: any) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            );
          }
          if (args.sortBy === 'desc') {
            data.data = data.data.sort((a: any, b: any) =>
              b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
            );
            data.sharedProsearches = data.sharedProsearches.sort(
              (a: any, b: any) =>
                b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
            );

            data.externalSubscriptions = data.externalSubscriptions.sort(
              (a: any, b: any) =>
                b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
            );

            data.allProsearches = data.allProsearches.sort((a: any, b: any) =>
              b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
            );

            data.archivedProsearches = data.archivedProsearches.sort(
              (a: any, b: any) =>
                b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
            );
          }
          return data;
        },
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        providesTags: ['Prosearches', 'Home'],
      }),

      getSingleProsearch: builder.query<SingleProsearchResponse, string>({
        query: (id) => `${API_STAFFING_PROSEARCHES}/${id}`,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        providesTags: [
          'SingleProsearch',
          'ProsearchCriterias',
          'ProsearchMembers',
          'ProsearchListByUser',
          'ProsearchAccess',
        ],
      }),
      searchByCriteria: builder.query<any, FilterCriteriaPayload>({
        query: (payload) => ({
          url: `${API_STAFFING_PROSEARCHES}/filter/criteria`,
          method: HTTP_METHODS.GET,
          params: payload.params,
        }),

        // serializeQueryArgs: ({ endpointName }) => endpointName,
        // merge: (currentCache, newItems, otherArgs) => {
        //   if ((otherArgs?.arg?.params?.paginate &&
        //     JSON.parse(otherArgs?.arg?.params?.paginate as any)?.from > 0 && newItems.data.length > 0) ) {
        //     currentCache.data.push(...newItems.data);
        //     currentCache.meta = newItems.meta;
        //   } else {
        //     if (
        //       otherArgs?.arg?.params?.paginate &&
        //       JSON.parse(otherArgs?.arg?.params?.paginate as any)?.from > 0
        //     )
        //       return;
        //     currentCache.data = newItems.data;
        //     currentCache.meta = newItems.meta;
        //   }
        // },

        // forceRefetch({ currentArg, previousArg }) {
        //   if(currentArg?.params?.criterias !== previousArg?.params?.criterias)
        //   return  currentArg?.params?.criterias !== previousArg?.params?.criterias
        //   if(currentArg?.params?.paginate !== previousArg?.params?.paginate)
        //     return currentArg?.params?.paginate !== previousArg?.params?.paginate
        //   return currentArg?.params?.criterias !== previousArg?.params?.criterias
        // },

        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        providesTags: ['ProsearchesResults'],
      }),
      searchByCriteriaSingle: builder.query<any, FilterCriteriaPayload>({
        query: (payload) => ({
          url: `${API_STAFFING_PROSEARCHES}/filter/criteria/single`,
          method: HTTP_METHODS.GET,
          params: payload.params,
        }),
        transformResponse: (data: { data: Array<any> }) => data.data,

        // serializeQueryArgs: ({ endpointName }) => endpointName,
        // merge: (currentCache, newItems, otherArgs) => {
        //   if ((otherArgs?.arg?.params?.paginate &&
        //     JSON.parse(otherArgs?.arg?.params?.paginate as any)?.from > 0 && newItems.data.length > 0) ) {
        //     currentCache.data.push(...newItems.data);
        //     currentCache.meta = newItems.meta;
        //   } else {
        //     if (
        //       otherArgs?.arg?.params?.paginate &&
        //       JSON.parse(otherArgs?.arg?.params?.paginate as any)?.from > 0
        //     )
        //       return;
        //     currentCache.data = newItems.data;
        //     currentCache.meta = newItems.meta;
        //   }
        // },

        // forceRefetch({ currentArg, previousArg }) {
        //   if(currentArg?.params?.criterias !== previousArg?.params?.criterias)
        //   return  currentArg?.params?.criterias !== previousArg?.params?.criterias
        //   if(currentArg?.params?.paginate !== previousArg?.params?.paginate)
        //     return currentArg?.params?.paginate !== previousArg?.params?.paginate
        //   return currentArg?.params?.criterias !== previousArg?.params?.criterias
        // },

        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        providesTags: ['ProsearchesResults'],
      }),
      createProsearches: builder.mutation<any, any>({
        query: (payload) => ({
          url: API_STAFFING_PROSEARCHES,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
        transformResponse: (data: any) => data.id,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: ['Prosearches'],
      }),

      duplicateStaffingTools: builder.mutation<any, any>({
        query: (payload: DuplicateStaffingToolsType) => ({
          url: API_STAFFING_TOOLS_DUPLICATE,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
        transformResponse: (data: any) => data.id,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: [
          'Prosearches',
          'StaffingRosters',
          'StaffingRostersV2',
        ],
      }),

      updateProsearch: builder.mutation<any, any>({
        query: ({ id, ...payload }) => ({
          url: `${API_STAFFING_PROSEARCHES}/${id}/update`,
          method: HTTP_METHODS.PATCH,
          body: payload,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: ['Prosearches', 'SingleProsearch', 'ProsearchMembers'],
      }),

      updateCriterias: builder.mutation<any, any>({
        query: ({ id, signal, ...payload }) => ({
          url: `${API_STAFFING_PROSEARCHES}/${id}/update`,
          method: HTTP_METHODS.PATCH,
          body: payload,
          signal,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: ['ProsearchCriterias'],
      }),

      changeStatus: builder.mutation<any, any>({
        query: ({ id, ...payload }) => ({
          url: `${API_STAFFING_PROSEARCHES}/${id}/status`,
          method: HTTP_METHODS.PATCH,
          body: payload,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: ['Prosearches', 'SingleProsearch'],
      }),
      deleteProsearch: builder.mutation<any, any>({
        query: (id: any | string[] | undefined) => ({
          url: `${API_STAFFING_PROSEARCHES}/${id}/delete`,
          method: HTTP_METHODS.PATCH,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: ['Prosearches'],
      }),

      addProsearchMember: builder.mutation<any, AddMember>({
        query: ({ prosearchId, memberId, status, note }: AddMember) => ({
          url: `${API_STAFFING_PROSEARCHES}/members`,
          method: HTTP_METHODS.POST,
          body: {
            prosearchId: parseInt(String(prosearchId ?? '0'), 10),
            memberId,
            status,
            note,
          },
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: [
          'ProsearchMembers',
          'ProsearchListByUser',
          'SingleProsearch',
        ],
      }),

      addMember: builder.mutation<any, AddMember>({
        query: (payload) => ({
          url: `${API_STAFFING_PROSEARCHES}/members`,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: ['ProsearchMembers'],
      }),

      updateMember: builder.mutation<any, any>({
        query: ({ id, ...payload }) => ({
          url: `${API_STAFFING_PROSEARCHES}/members/${id}`,
          method: HTTP_METHODS.PATCH,
          body: payload,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: [
          'ProsearchMembers',
          'SingleProsearch',
          'ProsearchListByUser',
          'ProsearchAccess',
        ],
      }),

      deleteMemberNotes: builder.mutation<any, any>({
        query: () => ({
          url: `${API_STAFFING_PROSEARCHES}/members/delete/notes`,
          method: HTTP_METHODS.PATCH,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),

      addAccessToProsearch: builder.mutation<any, any>({
        query: (payload) => ({
          url: `${API_STAFFING_PROSEARCHES}/access`,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: ['ProsearchAccess'],
      }),

      getAccessByProsearchId: builder.query<any, any>({
        query: ({ id }) => `${API_STAFFING_PROSEARCHES}/access/${id}`,
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        providesTags: ['ProsearchAccess'],
      }),

      removeMyAccessToProsearch: builder.mutation<any, any>({
        query: (accessId: string) => ({
          url: `${API_STAFFING_PROSEARCHES}/remove/my/access/${accessId}`,
          method: HTTP_METHODS.PATCH,
          body: {},
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: [
          'Prosearches',
          'ProsearchAccess',
          'SingleProsearch',
          'ProsearchMembers',
        ],
      }),

      updateAccessToProsearch: builder.mutation<any, any>({
        query: ({ id, subId, ...payload }) => ({
          url: `${API_STAFFING_PROSEARCHES}/access/${id}/${subId}`,
          method: HTTP_METHODS.PATCH,
          body: payload,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: ['ProsearchAccess', 'SingleProsearch', 'Prosearches'],
      }),
      transferOwnershipToProsearch: builder.mutation<any, any>({
        query: ({ accessId, ...payload }) => ({
          url: `${API_STAFFING_PROSEARCHES}/transferOwnership/${accessId}`,
          method: HTTP_METHODS.PATCH,
          body: payload,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: ['ProsearchAccess', 'SingleProsearch', 'Prosearches'],
      }),
      getProSearchListWithMember: builder.query<
        ProSearchResponse[],
        ProSearchPayload
      >({
        query: () => API_STAFFING_PROSEARCHES,
        transformResponse: (
          data: { data: ProSearchWithOwner[] },
          meta,
          args,
        ) => {
          let refactoredProSearchItems: ProSearchResponse[] = [];
          refactoredProSearchItems = data.data.map((proSearchItem) => {
            const memberExist = proSearchItem.members.find(
              (member: any) => member?.user?.id === args.userId,
            );
            const proSearchObject = {
              id: proSearchItem.id,
              title: proSearchItem.name,
              desc: proSearchItem.description,
              isShared: proSearchItem.status,
              ownerId: proSearchItem.owner?.id,
              isAdded: false,
              memberId: args.userId,
            };
            if (memberExist) {
              proSearchObject.isAdded = true;
            }

            return proSearchObject;
          });
          if (args.searchText) {
            refactoredProSearchItems = refactoredProSearchItems.filter((list) =>
              list.title
                .toLowerCase()
                .includes(String(args.searchText).toLowerCase()),
            );
          }

          return refactoredProSearchItems;
        },
      }),

      getProSearchListWithUser: builder.query<
        ProSearchResponse[],
        ProSearchPayload
      >({
        query: () => `${API_STAFFING_PROSEARCHES}/by-user`,
        transformResponse: (data: any, meta, args) => {
          let refactoredProSearchItems: ProSearchResponse[] = [];
          refactoredProSearchItems = data.map((proSearchItem: any) => {
            const memberExist = proSearchItem.members.find(
              (member: any) => member?.user?.id === args.userId,
            );
            const proSearchObject = {
              id: proSearchItem.id,
              title: proSearchItem.name,
              desc: proSearchItem.description,
              isShared: proSearchItem.status,
              ownerId: proSearchItem.owner?.id,
              isAdded: false,
              memberId: args.userId,
              subscriptionName: proSearchItem.subscription.subscriptionName,
              subscriptionId: proSearchItem.subscription.id,
              subscription: proSearchItem?.subscription,
            };
            if (memberExist) {
              proSearchObject.isAdded = true;
            }

            return proSearchObject;
          });
          if (args.searchText) {
            refactoredProSearchItems = refactoredProSearchItems.filter((list) =>
              list.title
                .toLowerCase()
                .includes(String(args.searchText).toLowerCase()),
            );
          }

          return refactoredProSearchItems;
        },
        providesTags: ['ProsearchListByUser'],
        // transformResponse: (data: any) => data,
      }),

      getDemographicsProfileList: builder.query<any, any>({
        query: (profileListType: string) =>
          `${API_PROFILE_LIST}/:${profileListType}`,
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      getSharableUsersList: builder.query<any[], SharableUsersListPayload>({
        query: (payload) => {
          const { searchText, listId, subscriptionId } = payload;
          return {
            url: `${API_STAFFING_PROSEARCHES}/sharable-users/${listId}/subscription/${subscriptionId}`,
            method: HTTP_METHODS.GET,
            params: {
              searchText,
              subscriptionId,
            },
          };
        },
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),

      getMembersByProsearchID: builder.query<any[], any>({
        query: (prosearchId: any) => ({
          url: `${API_STAFFING_PROSEARCHES}/members/${prosearchId}`,
          method: HTTP_METHODS.GET,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        providesTags: ['ProsearchMembers'],
      }),

      getDemographicsDataByProsearchId: builder.query<any[], any>({
        query: (prosearchId: any) => ({
          url: `${API_STAFFING_PROSEARCHES}/${prosearchId}/demographics-by-category`,
          method: HTTP_METHODS.GET,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response,
      }),
      getDemograpgicsReport: builder.query<any[], any>({
        query: (prosearchId: any) => ({
          url: `${API_STAFFING_PROSEARCHES}/${prosearchId}/demographics-by-filters`,
          method: HTTP_METHODS.GET,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response,
      }),

      uploadJobDescription: builder.mutation<
        any,
        {
          prosearchId: number;
          body: FormData;
          abortController: AbortController;
        }
      >({
        queryFn: async (arg, api) => {
          try {
            const { token } = (api.getState() as RootState).auth;

            if (token) {
              const result = await axios.post(
                `${API_STAFFING_PROSEARCHES}/job-description/${arg.prosearchId}`,
                arg.body,
                {
                  headers: { Authorization: `Bearer ${token}` },
                  baseURL: config.apiBaseUrl,
                  onUploadProgress: (upload) => {
                    const uploadloadProgress = Math.round(
                      (100 * upload.loaded) / upload.total,
                    );
                    api.dispatch(
                      setUploadProgress({
                        progress: uploadloadProgress,
                        process: 'uploading',
                      }),
                    );
                  },
                  signal: arg.abortController.signal,
                },
              );
              return { data: result.data };
            }
            throw new Error('Invalid token specified');
          } catch (axiosError) {
            const err = axiosError as any;
            return {
              error: {
                status: err.response?.status,
                data: err.response?.data || err.message,
              },
            };
          }
        },
      }),
      reRunProsearchJobPrompt: builder.mutation<any, any>({
        query: (payload: EditProSearchJobDescription) => ({
          url: `${API_STAFFING_PROSEARCHES}/${payload.id}/re-prompt`,
          method: HTTP_METHODS.PATCH,
          body: payload,
        }),
        transformResponse: (data: any) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
        invalidatesTags: ['ProsearchAccess', 'SingleProsearch', 'Prosearches'],
      }),
    } as any),

  overrideExisting: true,
});

export const {
  useGetAllProsearchesQuery,
  useSearchByCriteriaSingleQuery,
  useLazySearchByCriteriaSingleQuery,
  useLazyGetAllProsearchesQuery,
  useGetSingleProsearchQuery,
  useLazyGetSingleProsearchQuery,
  useLazyGetProSearchListWithMemberQuery,
  useLazyGetProSearchListWithUserQuery,
  useCreateProsearchesMutation,
  useDuplicateStaffingToolsMutation,
  useUpdateProsearchMutation,
  useDeleteProsearchMutation,
  useChangeStatusMutation,
  useSearchByCriteriaQuery,
  useLazySearchByCriteriaQuery,
  useAddProsearchMemberMutation,
  useAddAccessToProsearchMutation,
  useGetAccessByProsearchIdQuery,
  useUpdateAccessToProsearchMutation,
  useRemoveMyAccessToProsearchMutation,
  useAddMemberMutation,
  useGetSharableUsersListQuery,
  useLazyGetSharableUsersListQuery,
  useUpdateMemberMutation,
  useTransferOwnershipToProsearchMutation,
  useDeleteMemberNotesMutation,
  useGetMembersByProsearchIDQuery,
  useLazyGetMembersByProsearchIDQuery,
  useUpdateCriteriasMutation,
  useGetDemographicsDataByProsearchIdQuery,
  useGetDemograpgicsReportQuery,
  useUploadJobDescriptionMutation,
  useReRunProsearchJobPromptMutation,
} = prosearchApiSlice;
