import {
  API_CHECK_WHETHER_USER_IS_EMAIL_LOGIN,
  API_FORGOT_PASSWORD,
  API_FORGOT_PASSWORD_CONFIRMATION,
  API_GET_AUTH_USER_COMPANY,
  API_GET_CURRENT_AUTH_USER,
  API_GET_ME_USER_ONLY,
  // API_PHONE_LOGIN_ATTEMPT,
  API_RESET_PASSWORD,
  API_SEND_VERIFICATION_EMAIL,
  API_SIGN_UP_WITH_EMAIL,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
import { baseApiSlice } from 'commons/apis/base-api.config';
import { UserType } from 'store/user/types';
// import { PhoneAccountDetails } from 'components/Account/Login/PhoneLoginForm';

export const AUTH_API_SLICE = 'authApi';

export interface ForgotPasswordResponse {
  email: string;
}

export interface ForgotPasswordVerification {
  email: string;
  verificationCode?: string;
}

export interface SignupEmailAccount {
  email: string;
  password: string;
  verificationCode: string;
}

export interface SignupEmailAccountResponse {
  email: string;
  emailVerified: boolean;
}

export const authApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getMeUser: builder.query<UserType, void>({
        query() {
          return {
            url: API_GET_ME_USER_ONLY,
            method: HTTP_METHODS.GET,
          };
        },
        providesTags: ['CurrentAuthUser'],
      }),
      forgotPassword: builder.mutation<
        ForgotPasswordResponse,
        { email: string }
      >({
        query(payload: { email: string }) {
          return {
            url: API_FORGOT_PASSWORD,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),
      forgotPasswordVerification: builder.mutation<
        ForgotPasswordResponse,
        { email: string; verificationCode: string }
      >({
        query(payload: { email: string }) {
          return {
            url: API_FORGOT_PASSWORD_CONFIRMATION,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),
      resetPassword: builder.mutation<
        any,
        { email: string; newPassword: string }
      >({
        query(payload: { email: string; newPassword: string }) {
          return {
            url: API_RESET_PASSWORD,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),
      sendVerificationEmail: builder.mutation<
        ForgotPasswordResponse,
        { email: string }
      >({
        query(payload: { email: string }) {
          return {
            url: API_SEND_VERIFICATION_EMAIL,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),
      signUpWithEmail: builder.mutation<
        SignupEmailAccountResponse,
        SignupEmailAccount
      >({
        query(payload: SignupEmailAccount) {
          return {
            url: API_SIGN_UP_WITH_EMAIL,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),
      // sendOtpPhoneLogin: builder.mutation<PhoneAccountDetails, any>({
      //   query(payload: PhoneAccountDetails) {
      //     return {
      //       url: API_PHONE_LOGIN_ATTEMPT,
      //       method: HTTP_METHODS.POST,
      //       body: payload,
      //     };
      //   },
      //   transformErrorResponse: (response: any) => ({
      //     status: response.status ? response.status : 500,
      //     error: {
      //       message: response.data?.error_description,
      //       meta: response.data,
      //     },
      //   }),
      // }),
      getAuthUserCompany: builder.query<any, void>({
        query() {
          return {
            url: API_GET_AUTH_USER_COMPANY,
            method: HTTP_METHODS.GET,
          };
        },
        providesTags: ['CurrentAuthUserCompany'],
      }),
      getCurrentAuthUser: builder.query<any, void>({
        query() {
          return {
            url: API_GET_CURRENT_AUTH_USER,
            method: HTTP_METHODS.GET,
          };
        },
        providesTags: ['CurrentAuthUser'],
      }),
      checkIfEmailLoginUser: builder.mutation<
        { isEmailLogin: boolean; emailsUsedForLogin: string[] },
        void
      >({
        query() {
          return {
            url: API_CHECK_WHETHER_USER_IS_EMAIL_LOGIN,
            method: HTTP_METHODS.POST,
          };
        },
      }),
    };
  },
});

export const {
  useForgotPasswordMutation,
  useForgotPasswordVerificationMutation,
  useResetPasswordMutation,
  useSendVerificationEmailMutation,
  useSignUpWithEmailMutation,
  // useSendOtpPhoneLoginMutation,
  useGetMeUserQuery,
  useGetAuthUserCompanyQuery,
  useGetCurrentAuthUserQuery,
  useCheckIfEmailLoginUserMutation,
} = authApiSlice;
