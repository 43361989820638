import NavLink from 'components/Core/NavLink';
import Typography from 'components/Core/StylesGuide/Typography';
import ProgressGrade from 'components/Home/ProgressGrade';
import HusslUpIcon from 'components/Icon';
import config from 'config';
import { NavLinkProps } from 'constants/navbar';
import {
  STAFFING,
  STAFFING_PRO_SEARCHES,
  STAFFING_PRO_STAFFING_ROSTER,
} from 'constants/routes';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect, useState } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { useLazyGetSubscriptionInfoQuery } from 'store/subscription/subscription.api';
import { useAppDispatch, useAppSelector } from 'commons/hooks/redux';
import { selectCurrentUser } from 'store/auth/selectors';
import { useSubscriptionRoute } from 'commons/hooks/subscription';
import { UserSubscriptions } from 'store/subscription/subscription.types';
import { SubscriptionItem } from 'components/Subscription/SubscriptionItem';
import { selectActiveStaffingSubscription } from 'store/subscription/selectors';
import { setActiveStaffingSubscription } from 'store/subscription/subscription.slice';
import ExternalSubscriptionItem from 'components/Subscription/ExternalSubscriptionItem';

const SecondaryNavbar: FunctionComponent<{
  navLinks: Pick<
    NavLinkProps,
    'label' | 'path' | 'secondaryLinks' | 'external'
  >[];
}> = ({ navLinks }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const { id: subId, isExternalSubscription } = useAppSelector(
    selectActiveStaffingSubscription,
  );
  const [getSubscriptionInfo] = useLazyGetSubscriptionInfoQuery();
  const [subscription, setSubscription] = useState<any>(
    user?.userSubscriptions[0],
  );

  // const [dropdownSubscriptionLabel, setDropDownSubscriptionLabel] =
  //   useState<any>(user?.userSubscriptions[0]?.subscriptionName);

  useSubscriptionRoute();

  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      try {
        getSubscriptionInfo({
          subscriptionId: subId,
        })
          .unwrap()
          .then((response: any) => {
            if (response) {
              // set response in store
              setSubscription(response);
              // setDropDownSubscriptionLabel(response?.subscriptionName);
            }
            // callback(options!);
          });
      } catch {
        /* empty */
        // callback([]);
      }
    };
    if (subId) {
      fetchSubscriptionInfo();
    }
  }, [subId]);
  return (
    <Nav
      activeKey={router.asPath}
      className={
        router.asPath.includes(STAFFING)
          ? 'secondary-nav fixed-nav'
          : `secondary-nav ${navLinks.length === 0 ? 'profile-completion' : ''}`
      }
    >
      {navLinks.length === 0 && (
        <ProgressGrade subNav={false} fetchProfileGrade={false} />
      )}
      <div className='navbar-holder'>
        {navLinks.map(
          (
            link: Pick<
              NavLinkProps,
              'label' | 'path' | 'disabled' | 'secondaryLinks' | 'external'
            >,
          ) => {
            const isActiveLink =
              router.asPath === link.path || router.asPath.includes(link.path);
            const hasChildLinkActive =
              link.secondaryLinks &&
              link?.secondaryLinks
                .map((secondaryLink) => secondaryLink.path)
                .includes(router.pathname);

            return (
              <Nav.Item key={link.path}>
                <NavLink
                  path={link.path}
                  disabled={link.disabled}
                  active={isActiveLink || hasChildLinkActive}
                  newTab={!!link.external}
                >
                  {link.label}
                  {link?.label?.toLowerCase() === 'prosearch' &&
                  config.enableAIProsearch === 'true' ? (
                    <div className='badge velma ml-h'>Powered by AI</div>
                  ) : null}
                </NavLink>
              </Nav.Item>
            );
          },
        )}

        {router.pathname.includes('/Staffing') &&
          user &&
          user?.userSubscriptions?.length > 0 && (
            <Dropdown align='start' className='dropdown-user-switch'>
              <Dropdown.Toggle as='div'>
                <>
                  {isExternalSubscription ? (
                    <div className='d-flex h-100 align-items-center'>
                      <ExternalSubscriptionItem />
                      <HusslUpIcon
                        name='chevron-down'
                        wrapperClass='hic-16 fw-600 ml-12px'
                      />
                    </div>
                  ) : (
                    <div className='d-flex h-100 align-items-center'>
                      <SubscriptionItem
                        subscription={subscription}
                        isOwner={user?.id === subscription?.user?.id}
                      />
                      <HusslUpIcon
                        name='chevron-down'
                        wrapperClass='hic-16 fw-600 ml-12px'
                      />
                    </div>
                  )}
                </>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className='mx-3 mt-h'>
                  <Typography variant='p-md-b' className='mb-h'>
                    Choose Subscription
                  </Typography>
                </div>
                {user?.userSubscriptions
                  ?.filter(
                    (subscription: UserSubscriptions) =>
                      subscription.isDeleted === false,
                  )
                  .map((sub: UserSubscriptions) => (
                    <Dropdown.Item
                      className={`list-group-item d-flex justify-content-between ${
                        sub.id === subId ? 'active' : ''
                      }`}
                      onClick={() => {
                        // setDropDownSubscriptionLabel(sub?.subscriptionName);

                        const currentQuery = { ...router.query };

                        // currentQuery.ownerId = sub.subscription.owner.id;
                        // currentQuery.subId = String(sub.id);
                        // currentQuery.subscription =
                        //   sub.subscription.subscriptionName;
                        // currentQuery.isExternalSubscription = 'false';

                        dispatch(
                          setActiveStaffingSubscription({
                            id: sub.id,
                            isExternalSubscription: false,
                          }),
                        );
                        if (
                          router.pathname.includes(`${STAFFING_PRO_SEARCHES}/`)
                        ) {
                          router.push({
                            pathname: STAFFING_PRO_SEARCHES,
                          });
                        } else if (
                          router.pathname.includes(
                            `${STAFFING_PRO_STAFFING_ROSTER}/`,
                          )
                        ) {
                          router.push({
                            pathname: STAFFING_PRO_STAFFING_ROSTER,
                          });
                        } else {
                          router.push({
                            pathname: router.pathname,
                            query: currentQuery,
                          });
                        }
                      }}
                      key={sub.id}
                    >
                      <SubscriptionItem
                        subscription={sub}
                        isOwner={user?.id === sub?.owner?.id}
                      />
                    </Dropdown.Item>
                  ))}
                <Dropdown.Item
                  className={`list-group-item d-flex justify-content-between ${
                    isExternalSubscription ? 'active' : ''
                  }`}
                  onClick={() => {
                    const currentQuery = { ...router.query };

                    dispatch(
                      setActiveStaffingSubscription({
                        id: undefined,
                        isExternalSubscription: true,
                      }),
                    );
                    if (router.pathname.includes(`${STAFFING_PRO_SEARCHES}/`)) {
                      router.push({
                        pathname: STAFFING_PRO_SEARCHES,
                      });
                    } else if (
                      router.pathname.includes(
                        `${STAFFING_PRO_STAFFING_ROSTER}/`,
                      )
                    ) {
                      router.push({
                        pathname: STAFFING_PRO_STAFFING_ROSTER,
                      });
                    } else {
                      router.push({
                        pathname: router.pathname,
                        query: currentQuery,
                      });
                    }
                  }}
                >
                  <ExternalSubscriptionItem />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
      </div>

      {/* @TODO uncomment after confirmation */}
      {/* <Button
        label='Invite Colleagues'
        variant={ButtonVariants.secondary}
        className='info-btn'
        onClick={() => {
          router.push(MY_NETWORK_REFERRAL_INVITES)
        }}
      /> */}
    </Nav>
  );
};
export default SecondaryNavbar;
