import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ConnectionsSliceState } from '../connections.slice';

import { updateConnectionApiSlice } from '../update-connection.api';

function updateConnectionBuilder(
  builder: ActionReducerMapBuilder<ConnectionsSliceState>,
) {
  builder.addMatcher(
    updateConnectionApiSlice.endpoints.removeConnection.matchFulfilled,
    (state) => {
      state.actionParams = {};
    },
  );
  builder.addMatcher(
    updateConnectionApiSlice.endpoints.updateConnectionLabel.matchFulfilled,
    (state) => {
      state.actionParams = {};
    },
  );
}

export default updateConnectionBuilder;
