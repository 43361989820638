import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';

import Button, { ButtonVariants } from 'components/Core/Button';

export interface ThreeDialogBoxProps {
  show: boolean;
  title: string;
  message: string;
  onClose: () => void;
  onAccept: (values?: any) => void | Promise<void>;
  onSecondaryButtonClick?: (values?: any) => void | Promise<void>;
  acceptText?: string;
  closeText?: string;
  disabled?: boolean;
  loading?: boolean;
  loadingSecondaryButton?: boolean;
  wrapperClassName?: string;
  closeButton?: boolean;
  gtClass?: string;
}

/**
 * General Dialog[Prompt] box used in application.
 *
 */
const ThreeButtonDialogBox: FC<ThreeDialogBoxProps> = ({
  // disablePrompt,
  message,
  onAccept,
  onClose,
  onSecondaryButtonClick,
  show,
  title,
  acceptText = 'Login',
  closeText = 'Cancel',
  disabled,
  loading,
  wrapperClassName = '',
  closeButton,
  loadingSecondaryButton,
  gtClass = '',
}) => (
  <Modal show={show} onHide={onClose} className={wrapperClassName}>
    <Modal.Header closeButton={closeButton}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body className='pt-12px'>{message}</Modal.Body>
    <Modal.Footer>
      <Button
        label={closeText}
        variant={ButtonVariants.secondary}
        onClick={onSecondaryButtonClick}
        disabled={disabled || loadingSecondaryButton}
        loading={loadingSecondaryButton}
      />
      <Button
        label={acceptText}
        variant={ButtonVariants.primary}
        onClick={onAccept}
        disabled={disabled || loadingSecondaryButton}
        loading={loading}
        className={gtClass || ''}
      />
    </Modal.Footer>
  </Modal>
);

export default ThreeButtonDialogBox;
