/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { Spinner } from 'react-bootstrap';

import Typography from 'components/Core/StylesGuide/Typography';
import HusslUpIcon from 'components/Icon';
import { useGetPostLinkDetailV2Query } from 'store/post/post.api';

export type PostUrlPreviewProps = {
  url: string;
  postId: number;
};

export const PostUrlPreview = ({ url, postId }: PostUrlPreviewProps) => {
  const { isLoading, isError, data } = useGetPostLinkDetailV2Query({
    url: encodeURIComponent(url),
    post_id: postId,
  });

  if (isLoading)
    return (
      <div className='d-flex justify-content-center pt-1 pb-1'>
        <Spinner />
      </div>
    );

  if (isError)
    return (
      <a
        href={data?.original_url || url}
        target='_blank'
        rel='noreferrer'
        className='post-link-container'
      >
        {url}
      </a>
    );

  if (!data?.original_url) return null;

  return (
    <a
      href={data?.original_url}
      className='post-link-container'
      target='_blank'
      rel='noreferrer'
    >
      <div>
        {data?.images?.length && data?.images[0] ? (
          <div className='post-link-wrap'>
            <img
              className='post-link'
              src={data?.images[0].image}
              alt='Post Pic'
            />
          </div>
        ) : null}
        <div className='post-link-description-container'>
          <div className='post-link-description-text-container'>
            <Typography
              variant='p-md-sb'
              className='post-link-description-text'
            >
              {data?.description ?? ''}
            </Typography>
          </div>
          <div className='post-link-url'>
            <Typography variant='p-sm'>{data?.url ?? ''}</Typography>
            <HusslUpIcon name='link' wrapperClass='post-link-icon' />
          </div>
        </div>
      </div>
    </a>
  );
};
