/* eslint-disable no-nested-ternary */
import { Invite } from 'commons/types/data';
import Button, { ButtonTypes, ButtonVariants } from 'components/Core/Button';
import Typography from 'components/Core/StylesGuide/Typography';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useGetSentInvitesQuery } from 'store/invite';
import { selectGetAcceptedInvitesParams } from 'store/my-network/selectors';
import { getEmailInviteString } from 'utils/invite';
import { useAppSelector } from 'commons/hooks/redux';
import { selectReferalTemplate } from 'store/global-app-config';
import {
  REFERRAL_INVITE_FOUNDING_TEXT,
  REFERRAL_INVITE_MEMBER_TEXT,
  REFERRAL_INVITE_SWAG_TEXT,
} from 'constants/referral-invite';
import Link from 'next/link';
import { usePendingReferralActions } from 'commons/hooks/connections';
import { SendInviteModal } from './SendInviteModal';
import MyNetworkLoader from '../MyNetworkLoader';

const PendingInvites = () => {
  const getPendingInvitesParams = useSelector(selectGetAcceptedInvitesParams);
  const { subject, body } = useAppSelector(selectReferalTemplate);

  const { isLoading, isFetching, data } = useGetSentInvitesQuery(
    getPendingInvitesParams,
  );

  const { sendInviteDialogPropGetter, showSendInvite } =
    usePendingReferralActions();

  const handleSendInvite = (invite: Invite) => () => {
    if (invite.email) {
      window.location.href = getEmailInviteString({
        name: invite?.name ?? '',
        email: invite?.email ?? '',
        subject,
        body,
      });
    }
  };

  return (
    <>
      <SendInviteModal {...sendInviteDialogPropGetter} />

      {isLoading || isFetching ? (
        <MyNetworkLoader />
      ) : data?.invites?.items?.length! < 1 ? (
        <div className='my-network-no-data-container text-center mt-20'>
          <div className='w-50'>
            <Typography variant='p-lg-b'>
              {REFERRAL_INVITE_MEMBER_TEXT}
              <br />
              {REFERRAL_INVITE_FOUNDING_TEXT + REFERRAL_INVITE_SWAG_TEXT}
              <div className='mt-1'>
                <Link href=' ' onClick={showSendInvite}>
                  <Typography variant='p-sm-d'> Send Invite</Typography>
                </Link>
              </div>
            </Typography>
          </div>
        </div>
      ) : (
        <section className='mt-2'>
          <Row>
            {data?.invites?.items?.map((invite) => (
              <Col md={4} key={invite.id}>
                <Card
                  key={invite.id}
                  className='flex-row align-items-center border-0 rounded-0 border-bottom px-0'
                >
                  <Card.Body className='ps-0'>
                    {!invite.name ? (
                      <Typography variant='p-sm-sb'>
                        {invite?.email ?? ''}
                      </Typography>
                    ) : (
                      <>
                        <Typography variant='p-sm-sb'>
                          {invite?.name ?? ''}
                        </Typography>
                        <Typography variant='p-sm'>
                          {invite?.email ?? ''}
                        </Typography>
                      </>
                    )}
                  </Card.Body>
                  <Button
                    type={ButtonTypes.button}
                    variant={ButtonVariants.text}
                    className='fw-semibold fs-6 px-2 me-1'
                    label='Resend'
                    onClick={handleSendInvite(invite)}
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </section>
      )}
    </>
  );
};

export default PendingInvites;
