import CompanyProfilePlaceHolder from 'public/placeholders/company.png';
import SingleUserProfilePlaceHolder from 'public/placeholders/profile-img-placeholder-dark.png';
import UserProfilePlaceHolder from 'public/placeholders/user.png';
import CreditPlaceHolder from 'public/placeholders/credits-placeholder.png';
import CompanyProfilePlaceHolderV2 from 'public/company-placeholder.png';
import CompanyonlyProfilePlaceHolder from 'public/placeholders/company-placeholder-img.png';

export {
  UserProfilePlaceHolder,
  SingleUserProfilePlaceHolder,
  CreditPlaceHolder,
  CompanyProfilePlaceHolderV2,
  CompanyonlyProfilePlaceHolder,
};

export default CompanyProfilePlaceHolder;
