import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { userUpdateApiSlice } from 'store/user/user-update.api';
import { AuthState } from '../auth.slice';

function authBuilderUserUpdate(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addMatcher(
    userUpdateApiSlice.endpoints.saveAccountInfoAndHeadlines.matchFulfilled,
    () => {
      // state.authUser = action.payload as any;
    },
  );
}

export default authBuilderUserUpdate;
