/* eslint-disable @next/next/no-img-element */
import React, { FunctionComponent, useMemo } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Post } from 'commons/types/post.type';
import { selectCurrentUser } from 'store/auth/selectors';
import {
  useLikePostMutation,
  useUnlikePostMutation,
} from 'store/post/like.api';
import { selectGetPostWithTagParams } from 'store/post/selectors';
import { GenericDropdownItem } from 'components/Core/GenericDropdown';
import { POST } from 'constants/routes';
import { CommentActivities } from 'commons/types/comment.type';
import { clickableDivRequiredProps } from 'utils/dom';
import PostUtils from 'utils/post';
import HusslUpIcon from 'components/Icon';
import highlightText from 'utils/hightLightText';
import rehypeRaw from 'rehype-raw';
import PostLikeCommentCounts from './PostLikeCommentCounts';
import PostAttachment from './PostAttachment';
import PostCommentSection from './PostCommentSection';
import PostVideoPlayer from './PostVideoPlayer';
import { PostUrlPreview } from './PostUrlPreview';
import PostMentionPreview from './PostMentionPreview';

type PostProps = {
  post: Post;
  postHeader: React.ReactElement;
  searchKeyword?: string;
  commentProps: (
    initiateCommentEdit: (
      postId: number,
      commentId: number,
      commentLevel?: number,
    ) => void,
  ) => (comment: CommentActivities) => GenericDropdownItem[] | null;
};

const Post: FunctionComponent<PostProps> = React.memo(
  ({ post, postHeader, commentProps, searchKeyword = '' }) => {
    const router = useRouter();
    const currentUser = useSelector(selectCurrentUser);
    const currentGetPostParams = useSelector(selectGetPostWithTagParams);

    const [likePost, { isLoading: isLikingPost }] = useLikePostMutation();
    const [unlikePost, { isLoading: isUnlikingPost }] = useUnlikePostMutation();

    const { likeText, isLikedByMe, formattedLikes } = useMemo(() => {
      const { likes = [] } = post;

      if (currentUser?.id && likes?.length) {
        const formattedLikesArray = PostUtils.formatPostLikesByUser(
          likes,
          currentUser.id,
        );

        const likedByMe = likes.find((like) => like.id === currentUser.id);
        return {
          isLikedByMe: !!likedByMe,
          formattedLikes: formattedLikesArray,
          likeText:
            PostUtils.createTextForLikedByUsers(formattedLikesArray) ?? '',
        };
      }
      return { islikedByMe: false, formattedLikes: likes, likeText: '' };
    }, [post.likes, currentUser?.id]);

    const handleLikeUnlike = async () => {
      if (post?.post_id) {
        if (isLikedByMe) {
          await unlikePost({
            body: { post_id: post?.post_id },
            getPostWithTagParams: currentGetPostParams,
            likedBy: currentUser,
          });
        } else {
          await likePost({
            body: { post_id: post?.post_id },
            getPostWithTagParams: currentGetPostParams,
            likedBy: currentUser,
          });
        }
      }
    };

    const handleRedirectionToPost = () => {
      if (post?.post_id) {
        router.push(`${POST}/${post?.post_id}`);
      }
    };

    const formattedPost = useMemo(() => {
      if (!post?.post?.match(/\r/g)) {
        return post?.post?.replaceAll('\n', '</pre>\n') ?? '';
      }
      return post?.post?.replace(/\r/g, '</pre>') ?? '';
    }, [post?.post]);

    return (
      <li className='news-feed__item'>
        <div className='feed-post'>
          {postHeader}
          {/* __________________* Post Head END * ____________________ */}
          <div className='post-body'>
            {/* __________________* Post Head * ____________________ */}
            {post.post ? (
              <div
                className={
                  router.asPath.includes('Post')
                    ? 'post-detail-text'
                    : 'disable-copy-text'
                }
                onClick={
                  router.asPath.includes('Post')
                    ? undefined
                    : handleRedirectionToPost
                }
                {...clickableDivRequiredProps}
              >
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  transformLinkUri={null}
                  key={`${post?.post_id}`}
                  rehypePlugins={[rehypeRaw as any]}
                  components={{
                    p: (props) => {
                      if (
                        props.children.length > 1 &&
                        props.children[0]?.toString().includes('@')
                      ) {
                        return (
                          <p className='caption'>
                            {props.children.map((text) => {
                              if (typeof text === 'string') {
                                const trimmed = text.replace('@', '');
                                return highlightText(trimmed, searchKeyword);
                              }
                              return text;
                            })}
                          </p>
                        );
                      }
                      return (
                        <p className='caption'>
                          {props.children.map((child) =>
                            typeof child === 'string'
                              ? highlightText(child, searchKeyword)
                              : child,
                          )}
                        </p>
                      );
                    },
                    a: (props) => {
                      if (props.href?.startsWith('http')) {
                        return null;
                      }
                      if (props.href?.startsWith('/Profile')) {
                        return (
                          <PostMentionPreview
                            href={props.href}
                            text={props.children}
                            highlight={
                              props.children
                                .join(' ')
                                .toLowerCase()
                                .includes(searchKeyword.toLowerCase()) &&
                              searchKeyword.length > 2
                            }
                          />
                        );
                      }
                      return <>{props.children}</>;
                    },
                  }}
                >
                  {formattedPost.replace('', '')}
                </Markdown>
              </div>
            ) : null}

            {post?.media?.length ? <PostAttachment media={post.media} /> : null}
            {post?.muxVideo?.playbackId ? (
              <div className='post-video'>
                <PostVideoPlayer muxVideo={post.muxVideo} />
              </div>
            ) : null}
            {post.urls?.map((url) => (
              <PostUrlPreview key={url} url={url} postId={post.post_id!} />
            ))}
          </div>
          <div
            className={`post-interaction ${
              post?.muxVideo?.playbackId ? 'move-up' : ''
            }`}
          >
            <PostLikeCommentCounts
              likeText={likeText}
              commentCount={post?.commentActivities?.length}
              isLikedByMe={isLikedByMe}
              formattedLikes={formattedLikes}
            />
            <div className='post_action-btn'>
              <div
                {...clickableDivRequiredProps}
                aria-disabled={isLikingPost || isUnlikingPost}
                className={`post_action-btn__item ${
                  isLikedByMe
                    ? 'post_action-btn__item--active'
                    : 'post_action-btn__item'
                }`}
                onClick={handleLikeUnlike}
              >
                <HusslUpIcon name={isLikedByMe ? 'star-fill' : 'star'} />
                <span>Like</span>
              </div>
              <div
                tabIndex={0}
                role='button'
                onKeyUp={() => {}}
                className='post_action-btn__item'
                onClick={handleRedirectionToPost}
              >
                <HusslUpIcon name='message' />
                <span>Comment</span>
              </div>
              {/* @TODO add later after implementing share <div className='post_action-btn__item'>
              <span className='hic-share hic-1x' />
              <span>Share</span>
            </div> */}
            </div>
            <PostCommentSection post={post} commentProps={commentProps} />
          </div>
        </div>
      </li>
    );
  },
);

export default Post;
