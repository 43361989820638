import React, { useEffect, useRef, useState } from 'react';
import { CommentActivities } from 'commons/types/comment.type';
import { GenericDropdownItem } from 'components/Core/GenericDropdown';
import Typography from 'components/Core/StylesGuide/Typography';
import { useRouter } from 'next/router';
import { POST } from 'constants/routes';
import useVisibility from 'commons/hooks/useVisibility';
import PostCommentContainer from './PostCommentContainer';
import PostCommentThreadContainer from './PostCommentThreadContainer';

type PostCommentListProps = {
  comments?: CommentActivities[];
  commentActionGetter: (
    comment: CommentActivities,
  ) => GenericDropdownItem[] | null;
  postId?: number;
};

const PostCommentList = ({
  comments,
  commentActionGetter,
  postId,
}: PostCommentListProps) => {
  const { hide, show, isVisible } = useVisibility();
  const [currentComment, setCurrentComment] = useState(0);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isVisible && inputRef.current) {
      inputRef.current?.focus();
      /** @TODO refactor later */
      setTimeout(() => {
        inputRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 150);
    }
  }, [isVisible, inputRef.current]);

  const route = useRouter();
  if (!comments?.length) return null;
  const navigateToDetail = (postId: number) => {
    route.push(`${POST}/${postId}`);
  };

  const isFeedPage = route.asPath === '/';

  const handleReply = () => {
    hide();
  };

  const onReplyClicked = (commentId: number) => {
    show();
    setCurrentComment(commentId);
  };

  return (
    <ul className='post-comment__list'>
      {comments?.map((comment) => (
        <div key={comment.commentId}>
          <PostCommentContainer
            commentActionGetter={commentActionGetter}
            comment={comment}
            postId={postId}
            onReplyClicked={onReplyClicked}
          />

          {comment.children && comment.children.length > 0
            ? comment.children.slice(isFeedPage ? -2 : 0).map((subComment) => (
                <>
                  <div className='ml-4' key={subComment.commentId}>
                    <PostCommentContainer
                      commentActionGetter={commentActionGetter}
                      comment={subComment}
                      postId={postId}
                      onReplyClicked={onReplyClicked}
                    />
                  </div>
                </>
              ))
            : undefined}

          {comment.children && comment.children.length > 2 && isFeedPage && (
            <div
              className='ml-4 pb-1'
              onClick={() => navigateToDetail(postId as number)}
              role='button'
              tabIndex={0}
            >
              <Typography variant='p-md-sb' className='text-primary'>
                {comment.children.length - 2} More Replies
              </Typography>
            </div>
          )}
          {isVisible && currentComment === comment.commentId && (
            <div className='w-100 ml-2'>
              <PostCommentThreadContainer
                postId={postId || 0}
                parent_comment_id={comment.commentId || 0}
                handleReply={handleReply}
                ref={inputRef}
              />
            </div>
          )}
        </div>
      ))}
    </ul>
  );
};

export default PostCommentList;
