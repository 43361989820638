import { useState } from 'react';
import useVisibility from './useVisibility';

const useAddToStaffing = () => {
  const { show, hide, isVisible } = useVisibility();
  const [userId, setUserId] = useState<number | null>(null);
  const handleAddToStaffingRoaster = (userId: number) => {
    setUserId(userId);
    show();
  };

  return {
    addAddToStaffingDialogPropGetter: {
      show: isVisible,
      onClose: () => {
        setUserId(null);
        hide();
      },
      id: userId,
    },
    showAddToStaffingRoaster: handleAddToStaffingRoaster,
  };
};

export default useAddToStaffing;
