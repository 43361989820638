import { FunctionComponent, ReactElement, memo } from 'react';
import HusslUpIcon from 'components/Icon';
import timeSince from 'utils/date-time';
import { ProSearchStatus } from 'store/prosearch/prosearch.types';
import ChickLet from 'components/Chicklet/Chicklet';
import Link from 'next/link';
import {
  PROSEARCH_DESCRIPTION_MAX_LENGTH,
  PROSEARCH_HEADING_MAX_LENGTH,
} from 'constants/app';
import Typography from '../StylesGuide/Typography';

interface StaffingCardProps {
  label: string;
  description?: string;
  status: ProSearchStatus;
  isMine: boolean;
  lastEdited: Date;
  sharedBy?: string;
  href?: string;
  handleStatus?: (status: string) => void;
  className?: string;
}

const StaffingCard: FunctionComponent<StaffingCardProps> = ({
  label,
  description,
  status,
  isMine,
  lastEdited,
  // sharedBy,
  href,
  handleStatus,
  className = '',
}): ReactElement => (
  <Link
    href={{
      pathname: href,
    }}
    onClick={() => {
      handleStatus?.(isMine ? 'mine' : 'shared');
    }}
    className='fw-semi-bold text-decoration-none'
  >
    <div className={`staffing-card ${className}`}>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='name'>
          <div>
            <Typography
              variant='p-lg-sb'
              lineClampNo={1}
              title={label}
              trimNumber={PROSEARCH_HEADING_MAX_LENGTH}
            >
              {label}
            </Typography>
          </div>

          {status === 'open' ? (
            <HusslUpIcon name='unlock' />
          ) : (
            <HusslUpIcon name='lock' />
          )}
        </div>
        {isMine ? (
          <ChickLet label='MINE' variant='mine' />
        ) : (
          <div className='badge badge-shared'>Shared</div>
        )}
      </div>

      <Typography
        variant='p-sm-d'
        lineClampNo={1}
        style={{ width: '206px' }}
        className='text-body'
        title={description}
        trimNumber={PROSEARCH_DESCRIPTION_MAX_LENGTH}
      >
        {description!}
      </Typography>

      <div className='staffing-card__misc'>
        {/* {!isMine && (
          <Typography variant='p-sm-d' className='body-text'>
            <>
              Shared By <span className='p-sm-m'>{sharedBy}</span>
            </>
          </Typography>
        )} */}
        <div className='small text-muted'>
          Last Edited {timeSince(lastEdited)}
        </div>
      </div>
    </div>
  </Link>
);

export default memo(StaffingCard);
