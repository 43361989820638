import Image, { StaticImageData } from 'next/image';
import React, { FunctionComponent, ReactElement } from 'react';

interface NextImageProps {
  src: string | StaticImageData;
  alt?: string;
  width?: any;
  height?: any;
  className?: string;
}

const NextImage: FunctionComponent<NextImageProps> = ({
  src = '',
  alt = '',
  width = 100,
  height = 100,
  className = '',
}): ReactElement => (
  <Image
    width={width}
    height={height}
    src={src as string}
    alt={alt}
    className={className}
    priority
  />
);

export default NextImage;
