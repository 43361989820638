import React, { FunctionComponent, ReactElement } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Stack } from 'react-bootstrap';
import { DropDirection } from 'react-bootstrap/esm/DropdownContext';
import { AlignType } from 'react-bootstrap/esm/types';

import HusslUpIcon from 'components/Icon';
import Typography from '../StylesGuide/Typography/Typography';

const GenericToggle = React.forwardRef<any>(
  ({ children, onClick }: any, ref) => (
    <Stack
      ref={ref}
      onClick={(e: any) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 30,
        height: '100%',
      }}
    >
      <HusslUpIcon name='more' wrapperClass='profile-kebab-icon' />
      {children}
    </Stack>
  ),
);

GenericToggle.displayName = 'GenericToggle';

export interface GenericDropdownItem {
  onClick?: (key: string) => void;
  label: string;
  url?: string;
  disabled?: boolean;
}

interface GenericDropdownProps {
  dropdownItems: GenericDropdownItem[] | null;
  toggleProps?: {
    width?: number;
    height: number;
  };
  drop?: DropDirection;
  align?: AlignType;
  className?: string;
  toggleComponent?: React.ForwardRefExoticComponent<React.RefAttributes<any>>;
  toggleRootComponent?: ReactElement;
}

const GenericDropdown: FunctionComponent<GenericDropdownProps> = ({
  dropdownItems,
  toggleProps,
  toggleComponent,
  toggleRootComponent,
  ...props
}) => {
  if (!dropdownItems?.length) return null;
  return (
    <Dropdown {...props}>
      {toggleRootComponent || (
        <Dropdown.Toggle
          as={toggleComponent || GenericToggle}
          id='kebab-dropdown'
          className='dropright'
        />
      )}
      <Dropdown.Menu className='kebab-dropdown-menu'>
        {dropdownItems.map((dropdownItem: GenericDropdownItem) => (
          <Dropdown.Item
            disabled={dropdownItem.disabled}
            onClick={() => dropdownItem?.onClick?.(dropdownItem.label)}
            key={dropdownItem.label}
            className='kebab-dropdown-item'
          >
            <Typography
              variant='p-sm-d'
              className={
                dropdownItem.label === 'Block Member' ? 'block-label' : ''
              }
            >
              {dropdownItem.label}
            </Typography>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default GenericDropdown;
