import { useAppSelector } from 'commons/hooks/redux';
import { useSubscriptionChecks } from 'commons/hooks/subscription';
import StaffingCard from 'components/Core/StaffingCard/StaffingCard';
import Typography from 'components/Core/StylesGuide/Typography';
import { STAFFING_PRO_STAFFING_ROSTER } from 'constants/routes';
import Link from 'next/link';
import { Spinner } from 'react-bootstrap';
import { selectCurrentUserId } from 'store/auth/selectors';
import { useGetStaffingRostersByOwnerIdQuery } from 'store/staffing-roster';
import { StaffingRosterType } from 'store/staffing-roster/types';
import { selectActiveStaffingSubscription } from 'store/subscription/selectors';

const HomeStaffingRoster = () => {
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { id: subId } = useAppSelector(selectActiveStaffingSubscription);

  const { firstValidSubscriptionId } = useSubscriptionChecks();

  const {
    data: ownedStaffingRosters,
    isLoading,
    isFetching,
  } = useGetStaffingRostersByOwnerIdQuery(
    {
      ownerId: currentUserId,
      subscriptionId: subId || firstValidSubscriptionId,
    },
    { skip: !currentUserId || (!subId && !firstValidSubscriptionId) },
  );

  if (isLoading || isFetching) {
    return (
      <div className='d-flex justify-content-center pt-1'>
        <Spinner />
      </div>
    );
  }
  if (
    !ownedStaffingRosters?.mine ||
    !ownedStaffingRosters?.mine?.length ||
    ownedStaffingRosters?.mine?.length < 1
  ) {
    return (
      <div className='d-flex justify-content-center pt-3'>
        <Typography variant='p-md-d' className='text-center'>
          <>
            Manage your open roles and hired talent for a project. Create your
            first Staffing Roster now.
          </>
        </Typography>
      </div>
    );
  }

  return ownedStaffingRosters?.mine?.length > 0 ? (
    <div className='d-flex align-items-center flex-column  home-pro__search'>
      {ownedStaffingRosters?.mine?.slice(0, 2).map(
        (
          staffingRoster: StaffingRosterType & {
            isShared: boolean;
          },
        ) => (
          <>
            <StaffingCard
              key={staffingRoster.owner?.id}
              label={staffingRoster.projectName}
              description={staffingRoster.projectDescription}
              status={staffingRoster.isShared ? 'open' : 'archived'}
              isMine={true}
              lastEdited={staffingRoster.updatedOn}
              href={`${STAFFING_PRO_STAFFING_ROSTER}/${staffingRoster?.id}`}
            />
          </>
        ),
      )}
      <div className='d-flex justify-content-end w-100'>
        <Link href={STAFFING_PRO_STAFFING_ROSTER} className='link'>
          View All
        </Link>
      </div>
    </div>
  ) : (
    <>
      Use detailed criteria to find and save members for your open roles. Create
      your first ProSearch today.
    </>
  );
};

export default HomeStaffingRoster;
