import { HELP_INFO } from 'constants/routes';
import Link from 'next/link';
import React from 'react';

const Footer = () => (
  <>
    <div className='footer'>
      <a href='/'>
        <img src='/logo.svg' alt='HUSSLUP LOGO' />
      </a>

      <div className='text-center'>
        <div className='link-xs'>
          <Link href={HELP_INFO}>Help and Info</Link>
        </div>
        <small>HUSSLUP © 2023</small>
      </div>
      <ul className='social-links'>
        <li>
          <a href='https://www.facebook.com/HUSSLUP/'>
            {' '}
            <i className='hic-facebook hic-1x' />
          </a>
        </li>
        <li>
          <a href='https://www.linkedin.com/company/husslup'>
            {' '}
            <i className='hic-linkedin hic-1x' />
          </a>
        </li>
        <li>
          <a href='https://www.instagram.com/husslup/?hl=en'>
            {' '}
            <i className='hic-instagram hic-1x' />
          </a>
        </li>
        <li>
          <a href='https://twitter.com/husslup'>
            {' '}
            <i className='hic-twitter hic-1x' />
          </a>
        </li>
      </ul>
    </div>
  </>
);

export default Footer;
