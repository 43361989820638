import { useState } from 'react';
import { RemoveConnectionType } from 'commons/types/network.type';
import { useRemoveConnectionMutation } from 'store/my-network/update-connection.api';
import ToasterUtils from 'utils/toaster';
import useVisibility from '../useVisibility';
import { useTranslation } from 'react-i18next';

type RemoveConnectionHookIdGetter = {
  userId: string;
  type: RemoveConnectionType;
};

type RemoveConnectionHookProps = {
  getUserId: () => RemoveConnectionHookIdGetter;
};

const useRemoveConnection = ({ getUserId }: RemoveConnectionHookProps) => {
  const [removeConnection, { isLoading: isRemovingConnection }] =
    useRemoveConnectionMutation();
  const { t: globalT } = useTranslation('global');

  const { userId, type } = getUserId();

  const {
    hide: hideRemoveConnection,
    show: showRemoveConnection,
    isVisible: isRemoveConnectionVisible,
  } = useVisibility();

  const handleRemoveConnection = async () => {
    const successMessage =
      type === 'pending'
        ? globalT('CANCEL_CONNECTION_REQUEST.value')
        : 'Connection removed';
    const errorMessage =
      type === 'pending'
        ? globalT('CANCEL_CONNECTION_REQUEST_ERROR.value') //'Failed to cancel  connection request' //globalT('CANCEL_CONNECTION_REQUEST_ERROR.value')
        : globalT('CANCEL_CONNECTION_REQUEST_ERROR.value'); //'Failed to remove connection';
    if (userId) {
      try {
        const response = await removeConnection({
          husslupId: userId,
        }).unwrap();
        if (response) {
          ToasterUtils.successToast(successMessage, {
            hideCloseButton: true,
            icon: 'check',
            position: 'top-center',
          });
        } else {
          ToasterUtils.errorToast(errorMessage, {
            hideCloseButton: true,
            icon: 'close',
            position: 'top-center',
          });
        }
      } catch (err) {
        ToasterUtils.errorToast(errorMessage, {
          hideCloseButton: true,
          icon: 'close',
          position: 'top-center',
        });
      } finally {
        hideRemoveConnection();
      }
    }
  };

  return {
    handleRemoveConnection,
    showRemoveConnection,
    removeConnectionDialogPropGetter: {
      disabled: isRemovingConnection,
      loading: isRemovingConnection,
      show: isRemoveConnectionVisible,
      onAccept: handleRemoveConnection,
      onClose: hideRemoveConnection,
      message:
        type === 'pending'
          ? 'Are you sure you want to cancel this connection request?'
          : 'Are you sure you want to remove this connection?',
      title:
        type === 'pending' ? 'Cancel connection request' : 'Remove Connection',
      acceptText: 'Yes',
    },
  };
};

export const useRemoveConnectionWithId = () => {
  const [removeConnection, { isLoading: isRemovingConnection }] =
    useRemoveConnectionMutation();
  const [userIdToRemoveConnection, setUserIdToRemoveConnection] = useState('');

  const {
    hide: hideRemoveConnection,
    show: showRemoveConnection,
    isVisible: isRemoveConnectionVisible,
  } = useVisibility();

  const handleShow = (husslupId: string) => {
    setUserIdToRemoveConnection(husslupId);
    showRemoveConnection();
  };

  const handleRemoveConnection = async () => {
    const successMessage = 'Connection removed';
    const errorMessage = 'Failed to remove connection';
    if (userIdToRemoveConnection) {
      try {
        const response = await removeConnection({
          husslupId: userIdToRemoveConnection,
        }).unwrap();
        if (response) {
          ToasterUtils.successToast(successMessage, {
            hideCloseButton: true,
            icon: 'check',
            position: 'top-center',
          });
        } else {
          ToasterUtils.errorToast(errorMessage, {
            hideCloseButton: true,
            icon: 'close',
            position: 'top-center',
          });
        }
      } catch (err) {
        ToasterUtils.errorToast(errorMessage, {
          hideCloseButton: true,
          icon: 'close',
          position: 'top-center',
        });
      } finally {
        hideRemoveConnection();
      }
    }
  };

  return {
    handleRemoveConnection,
    showRemoveConnection: handleShow,
    removeConnectionDialogPropGetter: {
      disabled: isRemovingConnection,
      loading: isRemovingConnection,
      show: isRemoveConnectionVisible,
      onAccept: handleRemoveConnection,
      onClose: hideRemoveConnection,
      message: 'Are you sure you want to remove this connection?',
      title: 'Remove Connection',
      acceptText: 'Yes',
    },
  };
};

export default useRemoveConnection;
