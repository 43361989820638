import { ProfileListTables } from 'commons/types/data';
import config from 'config';
import {
  NUMBER_OF_CARDS_TO_SHOW,
  NUMBER_OF_CARDS_TO_SHOW_MOBILE,
} from 'constants/data';
import { MEMBER_CARD_PLACEHOLDER_IMAGE } from 'constants/file';
import {
  ADMINISTRATIVE_TITLE_SELECT_LIMIT,
  PROFESSION_SELECT_LIMIT,
  PROJECTS_GENRES_SELECT_LIMIT,
  PROJECT_AND_GENRE_SELECT_LIMIT,
  TYPE_OF_WORKPLACE_SELECT_LIMIT,
  UNDERGRAD_SCHOOL_SELECT_LIMIT,
} from 'constants/profile_company_select_limit';

export default class ProfileUtils {
  static getProfileImage = (
    profileImage: string,
    width?: number,
    height?: number,
  ): string => {
    const mask = `mask=https://husslup-public-images.s3.us-west-1.amazonaws.com/squircle2.png&w=${width}&h=${height}&fm=png&fit=crop`;
    return profileImage
      ? `https://${config.s3.profileImageCloudfrontUrl}/${profileImage}?${mask}`
      : MEMBER_CARD_PLACEHOLDER_IMAGE;
  };

  static getProfileImageWithoutMask = (
    profileImage: string,
    width?: number,
    height?: number,
    fillBackground?: boolean,
  ): string =>
    profileImage
      ? `https://${
          config.s3.profileImageCloudfrontUrl
        }/${profileImage}?w=${width}&h=${height}&fm=png&fit=crop${
          fillBackground ? '&bg=ffffff' : ''
        }`
      : MEMBER_CARD_PLACEHOLDER_IMAGE;

  static getProfileImageWithoutMaskOriginal = (
    profileImage: string,
    width: number,
    fillBackground?: boolean,
  ): string =>
    profileImage
      ? `https://${
          config.s3.profileImageCloudfrontUrl
        }/${profileImage}?w=${width}${fillBackground ? '&bg=ffffff' : ''}`
      : MEMBER_CARD_PLACEHOLDER_IMAGE;

  static getImageFileFromAwsUrl = (url: string) => {
    let fileUrl = '';
    try {
      fileUrl = url?.split('amazonaws.com')[1] ?? '';
    } catch (error) {
      fileUrl = '';
    }

    return fileUrl;
  };
}

export const getNumberOfCardsToShow = () => {
  if (typeof navigator === 'undefined') return NUMBER_OF_CARDS_TO_SHOW;
  let isMobile = false;
  // user agent should have mobile agent instead of os type agent
  if (/Mobile|mobile/i.test(navigator.userAgent)) {
    isMobile = true;
  }

  if (isMobile) return NUMBER_OF_CARDS_TO_SHOW_MOBILE;
  return NUMBER_OF_CARDS_TO_SHOW;
};

export const profileListLimit = (lengthOfValue: number, listType: string) => {
  let value = false;

  const allListType = {
    [`${ProfileListTables.Professions}`]: PROFESSION_SELECT_LIMIT,
    [`${ProfileListTables.ProjectTypes}`]: PROJECTS_GENRES_SELECT_LIMIT,
    [`${ProfileListTables.Genres}`]: PROJECTS_GENRES_SELECT_LIMIT,
    [`${ProfileListTables.Schools}`]: UNDERGRAD_SCHOOL_SELECT_LIMIT,
    [`${ProfileListTables.Degrees}`]: UNDERGRAD_SCHOOL_SELECT_LIMIT,
  };

  if (lengthOfValue === allListType[listType] + 1) {
    value = true;
  }
  return value;
};

export const profileLimit = (listType: string) => {
  const allListType = {
    [`${ProfileListTables.Professions}`]: PROFESSION_SELECT_LIMIT,
    [`${ProfileListTables.ProjectTypes}`]: PROJECTS_GENRES_SELECT_LIMIT,
    [`${ProfileListTables.Genres}`]: PROJECTS_GENRES_SELECT_LIMIT,
    [`${ProfileListTables.Schools}`]: UNDERGRAD_SCHOOL_SELECT_LIMIT,
    [`${ProfileListTables.Degrees}`]: UNDERGRAD_SCHOOL_SELECT_LIMIT,
    [`Administrator Title`]: ADMINISTRATIVE_TITLE_SELECT_LIMIT,
    [`Type of Workplace`]: TYPE_OF_WORKPLACE_SELECT_LIMIT,
    [`Project Types`]: PROJECT_AND_GENRE_SELECT_LIMIT,
    [`Genre`]: PROJECT_AND_GENRE_SELECT_LIMIT,
  };
  return allListType[listType] ?? 0;
};
