import { Post } from 'commons/types/post.type';
import { COMPANY, PROFILE } from 'constants/routes';
import timeSince from 'utils/date-time';
import ProfileUtils from 'utils/profile';
import SquircleImage from 'components/Core/SquircleImage/SquircleImage';
import GenericDropdown, {
  GenericDropdownItem,
} from 'components/Core/GenericDropdown';
import PostDropdownToggle from 'components/Core/GenericDropdown/PostDropdownToggle';
import { useRouter } from 'next/router';
import { clickableDivRequiredProps } from 'utils/dom';
import Typography from 'components/Core/StylesGuide/Typography';
import {
  UserProfilePlaceHolder,
  CompanyProfilePlaceHolderV2,
} from 'components/Placeholders';
import {
  POST_CREATOR_FULL_NAME_MAX_LENGTH,
  POST_CREATOR_PROJECT_TAGLINE_MAX_LENGTH,
  POST_CREATOR_PRO_HEADING_MAX_LENGTH,
} from 'constants/app';
import highlightText from 'utils/hightLightText';
import { selectGetPostWithTagParams } from 'store/post/selectors';
import { useSelector } from 'react-redux';
import { PostCreatorEntities } from '../CreatePost/CreatePostModal';

type PostHeaderProps = {
  post: Post;
  postOptions: GenericDropdownItem[];
};

export const PostHeader = ({ post, postOptions }: PostHeaderProps) => {
  const router = useRouter();
  const isStan = post?.posted_by?.userType === 'stan';
  const getPostWithTagParams = useSelector(selectGetPostWithTagParams);

  let modifiedOptions: GenericDropdownItem[];
  if (isStan) {
    // eslint-disable-next-line
    modifiedOptions = postOptions?.map((opt) => {
      return {
        ...opt,
        disabled:
          opt.label.includes('Block Member') || opt.label.includes('Report'),
      };
    });
  } else {
    modifiedOptions = postOptions;
  }
  let creatorInfo = {
    redirectUrl: `${PROFILE}/${post?.posted_by?.id}`,
    fullName: post?.posted_by?.fullname,
    proHeadline: post?.posted_by?.professionalTagline,
    projectTagLine: post?.posted_by?.projectTagline,
    imgUrl: post?.posted_by?.profile_img
      ? ProfileUtils.getProfileImageWithoutMask(
          post?.posted_by?.profile_img,
          88,
          88,
        )
      : UserProfilePlaceHolder,
  };

  if (post.postAs === PostCreatorEntities.COMPANY) {
    creatorInfo = {
      redirectUrl: `${COMPANY}/${post?.company?.id}`,
      fullName: post?.company?.company_name,
      proHeadline: post?.company?.pro_headline,
      projectTagLine: post?.company?.project_type_headline,
      imgUrl: post.company?.logo
        ? ProfileUtils.getProfileImage(
            ProfileUtils.getImageFileFromAwsUrl(post.company.logo),
            88,
            88,
          )
        : CompanyProfilePlaceHolderV2,
    };
  }

  const handleProfileRedirect = () => {
    router.push(creatorInfo.redirectUrl);
  };

  return (
    <div className='post-head'>
      <div
        className='pp'
        onClick={handleProfileRedirect}
        {...clickableDivRequiredProps}
      >
        <SquircleImage
          imgUrl={creatorInfo.imgUrl}
          width={88}
          height={88}
          alt='Profile Picture'
        />
      </div>
      <div
        className='user-details'
        onClick={handleProfileRedirect}
        {...clickableDivRequiredProps}
      >
        <Typography
          variant='p-lg-b'
          lineClampNo={1}
          className='user-name'
          title={creatorInfo?.fullName}
          trimNumber={POST_CREATOR_FULL_NAME_MAX_LENGTH}
        >
          {/* // add here the highliht part */}
          {getPostWithTagParams.searchKeyword ? (
            highlightText(
              creatorInfo?.fullName ?? '',
              getPostWithTagParams.searchKeyword,
            )
          ) : (
            <>{creatorInfo.fullName}</>
          )}
        </Typography>

        <Typography
          variant='p-md-m'
          lineClampNo={1}
          title={creatorInfo.proHeadline}
          trimNumber={POST_CREATOR_PRO_HEADING_MAX_LENGTH}
        >
          {creatorInfo.proHeadline}
        </Typography>

        <Typography
          variant='p-md-d'
          lineClampNo={1}
          title={creatorInfo.projectTagLine}
          trimNumber={POST_CREATOR_PROJECT_TAGLINE_MAX_LENGTH}
        >
          {creatorInfo.projectTagLine}
        </Typography>

        <Typography variant='p-sm-d' className='text-body-2'>
          <>
            {post?.createdAt !== post?.updatedAt && <>Edited </>}
            {timeSince(post.updatedAt)}
          </>
        </Typography>
      </div>
      <div className='post-misc'>
        <GenericDropdown
          align='end'
          className='pe-auto'
          dropdownItems={modifiedOptions ?? []}
          toggleComponent={PostDropdownToggle}
        />
        {post?.tag ? (
          <div className='post-tag'>
            <div className='post-tag__name'> {post?.tag?.tag}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default PostHeader;
