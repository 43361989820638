import { UserProfileCardProps } from 'components/Core/UserProfileCard/UserProfileCard';
import { AttachmentProps } from 'components/Cards';
import { CreativeSampleResponseType } from './creative/types';
import { UserCreditType } from './credit/types';
import { FeatureListResponse } from './press/types';
import { VouchType } from './vouch/types';

export enum WorkStatusEnum {
  CURRENTLY_WORKING = 'Currently Working',
  HIRING = 'Hiring',
  INVESTING_IN_PROJECTS = 'Investing in Projects',
  AVAILABLE_TO_WORK = 'Available To Work',
}

export enum ConnectionStatus {
  ACCEPTED = 0,
  PENDING = 1,
  REJECTED = 2,
  NONE = 3,
  REQUESTED = 4, // Show option to accept or reject connection request.
  IGNORED = 5,
}

export enum ConnectionLabel {
  NONE = 'None',
  REPRESENTATION = 'Representation',
  CLIENTS = 'Clients',
  WORK_WITH = 'Work With',
}

export type ConnectionType = {
  user: UserType;
  connectionUser: UserType;
  label: ConnectionLabel;
  status: ConnectionStatus;
};

export type UserType = {
  fullname: string;
  email: string;
  phone_number?: string;
  profile_img: string;
  auth0_id: string;
  husslupId: string;
  accountInfoSetupSkipped: boolean;
  accountInfoSetup: boolean;
  firstName: string;
  lastName: string;
  cityId: string;
  stateId: string;
  allowDemographics: boolean;
  professionalTagline: string;
  projectTagline: string;
  genreTagline: string;
  workStatus: string;
  workStatuses: Array<number>;
  bio: string;
  inviteStatus?: string;
  instagramProfile: string;
  facebookProfile: string;
  linkedinProfile: string;
  twitterProfile: string;
  tickTokProfile: string;
  representedBy: string;
  representativeName: string;
  showIIdentityAs: boolean;
  agentName: string;
  lawyerName: string;
  managerName: string;
  createdDate: string;
  updatedDate: Date;
  foundingMemberNumber?: number;
  vouchedTo: VouchType[];
  userType: string;
  userCredits: UserCreditType[];
  connection: ConnectionType[];
  id: number;
  credit?: string;
  seasons?: string;
  projectTitle?: string;
  username?: string;
  company_name?: string;
  creditImg?: string;
  company?: any;
  getstreamToken?: string;
  tier?: string;
  isOutsideTalent?: boolean | null;
};

export enum ProfileListTables {
  Affiliations = 'profile_list_affiliations',
  Degrees = 'profile_list_degrees',
  Demographics = 'profile_list_demographics',
  Genres = 'profile_list_genres',
  InternationalPassports = 'profile_list_international_passports',
  Locations = 'profile_list_locations',
  NonIndustryExperience = 'profile_list_non_industry_experience',
  Professions = 'profile_list_professions',
  ProjectTypes = 'profile_list_project_types',
  Pronouns = 'profile_list_pronouns',
  Schools = 'profile_list_schools',
  SecondLanguage = 'profile_list_second_language',
}

export type UserProfileListType = 'id' | 'extra-details';

export type CommonProfileListTypes = {
  text: string;
  order?: number;
  category?: string;
};
export type ConnectionDetail = {
  connectionsDetail: UserProfileCardProps[];
};
export type FollowerResponse = {
  data: ConnectionDetail;
};

export type ProfileListResponseType = {
  user: UserType;
  profileListTable: ProfileListTables;
  listType: UserProfileListType;
  listValue?: string;
  listSubType?: string;
  affiliation?: CommonProfileListTypes;
  degree?: CommonProfileListTypes;
  demographic?: CommonProfileListTypes;
  genre?: CommonProfileListTypes;
  international_passport?: CommonProfileListTypes;
  location?: CommonProfileListTypes;
  non_industry_experience?: CommonProfileListTypes;
  profession?: CommonProfileListTypes;
  project_type?: CommonProfileListTypes;
  pronoun?: CommonProfileListTypes;
  school?: CommonProfileListTypes;
  second_language?: CommonProfileListTypes;
};

export type ProfileDetailsRespType = {
  creatives: CreativeSampleResponseType[];
  presses: FeatureListResponse[];
  profileList: ProfileListResponseType[];
  user: UserType | {};
};

export type GetOrCreateGetstreamProfileResp = {
  user: UserType;
  getStreamToken: string;
};

export type MemberUserInfo = {
  id: number;
  fullName: string;
  headings: string[];
  attachement: AttachmentProps;
  profileImgUrl: string;
};

export type GetProfileConnections = {
  connections: ConnectionType[];
};
