import React, { useState } from 'react';

import HusslUpIcon from 'components/Icon';
import { clickableDivRequiredProps } from 'utils/dom';
import LikedUsersListModal from './LikedUsersListModal';

type PostLikeCommentCountsProps = {
  isLikedByMe?: boolean;
  likeText?: string;
  commentCount?: number;
  formattedLikes: any;
};

const PostLikeCommentCounts = ({
  isLikedByMe,
  likeText,
  commentCount,
  formattedLikes = [],
}: PostLikeCommentCountsProps) => {
  const [showLikedUsersModal, setShowLikedUsersModal] = useState<boolean>(false);

  if (!commentCount && !likeText) return null;
  return (
    <>
      <div className='post_like_container'
      >
        {likeText?.length ? (
          <span className='d-flex align-items-center'>
            <HusslUpIcon
              name={isLikedByMe ? 'star-fill' : 'star-fill'}
              wrapperClass='text-primary'
            />
            <span className='ml-h' onClick={(e) => {
              e.stopPropagation();
              setShowLikedUsersModal(true);
            }}
              {...clickableDivRequiredProps}>{likeText}</span>
          </span>
        ) : (
          <span />
        )}
        {commentCount ? (
          <span className='text-muted'>
            {commentCount} comment{`${commentCount > 1 ? 's' : ''}`}
          </span>
        ) : null}
      </div>

      <LikedUsersListModal
        show={showLikedUsersModal}
        onClose={() => {
          setShowLikedUsersModal(false);
        }}
        likedUsers={formattedLikes}
      />
    </>
  );
};

export default PostLikeCommentCounts;