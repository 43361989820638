/* eslint-disable react/no-array-index-key */
import {
  ChickletSize,
  MemberChicklet,
  MemberChickletType,
} from 'components/Chicklet';
import React, { FunctionComponent, ReactElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  MEMBER_CARD_HEADING_MAX_LENGTH,
  RECOMMENDATION_MEMBERCARD_NAME,
} from 'constants/app';
import Typography from 'components/Core/StylesGuide/Typography';
import { IconProps } from '.';

interface IProps {
  chickletType?: MemberChickletType;
  chickletSize?: ChickletSize;
  icons?: IconProps[];
  name: string;
  headings?: string[];
  searchText?: string;
  keyName?: string;
  calledBy?: string;
}

const RecommendationDetails: FunctionComponent<IProps> = ({
  name,
  chickletType,
  headings,
  chickletSize,
}): ReactElement => {
  const headerSliceNumber = 2;
  return (
    <div className='recommendation-member-info'>
      {chickletType && (
        <div className='mb-4px-card'>
          <MemberChicklet labelText={chickletType} size={chickletSize} />
        </div>
      )}
      <div className='recommendation-member-name'>
        <OverlayTrigger
          placement='bottom-start'
          delay={{ show: 250, hide: 400 }}
          overlay={
            String(name)?.length > RECOMMENDATION_MEMBERCARD_NAME ? (
              <Tooltip>{name}</Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span
            className={`text-nowrap overflow-hidden text-truncate ${
              String(name).length >= RECOMMENDATION_MEMBERCARD_NAME
                ? 'p-sm-b'
                : 'p-lg-b'
            }`}
          >
            {name}
          </span>
        </OverlayTrigger>
      </div>

      {headings?.slice(0, headerSliceNumber).map((heading, index) => (
        <div
          className={
            index === 1
              ? 'recommendation-profile-heading d-inline-flex align-items-center second-heading'
              : 'recommendation-profile-heading d-inline-flex align-items-center'
          }
          key={`${heading}-${index}`}
        >
          <OverlayTrigger
            placement='bottom-start'
            delay={{ show: 250, hide: 400 }}
            overlay={
              heading?.length > MEMBER_CARD_HEADING_MAX_LENGTH ? (
                <Tooltip>{heading}</Tooltip>
              ) : (
                <></>
              )
            }
          >
            <Typography
              className='text-nowrap overflow-hidden text-truncate'
              variant={String(name).length >= 20 ? 'p-sm-d' : 'p-sm-sb'}
            >
              {heading}
            </Typography>
          </OverlayTrigger>
          {/* {heading?.toLowerCase() === 'private' && <HusslUpIcon name='lock' />} */}
        </div>
      ))}
    </div>
  );
};

export default RecommendationDetails;
