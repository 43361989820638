import { createSelector } from '@reduxjs/toolkit';
import { globalAppConfigApiSlice } from './global-app-config.api';

const DEFAULT_REFERRAL_BODY =
  'Hi [FIRST_NAME],\n\nI am sending you one of my invites to join me on HUSSLUP, a professional network for creative talent to connect with each other and with production companies that have open roles. Thousands of amazing people are already on the invite-only platform. Here is the link to jump the waitlist and join https://HUSSLUP.com/.\n\nBe sure to signup using your email [EMAIL].\n\nLet’s HUSSLUP!';

const DEFAULT_REFERRAL_SUBJECT = 'You Are Invited to Join HUSSLUP';

export const selectGlobalAppConfig =
  globalAppConfigApiSlice.endpoints.getGlobalAppConfig.select();

export const selectReferalTemplate = createSelector(
  selectGlobalAppConfig,
  (globalAppConfig) => ({
    subject:
      globalAppConfig?.data?.data?.appConfigUrl?.ios
        ?.referralInviteTemplateSubject || DEFAULT_REFERRAL_SUBJECT,
    body:
      globalAppConfig?.data?.data?.appConfigUrl?.ios
        ?.referralInviteTemplateBody || DEFAULT_REFERRAL_BODY,
  }),
);
