import { Activity } from 'getstream';
import NextImage from 'components/Core/Image';
import { FunctionComponent } from 'react';
import {
  useGetConnectionByIdQuery,
  useRejectConnectionRequestMutation,
} from 'store/user/connection.api';
import timeSince, { formatIso8601DateToUTCDate } from 'utils/date-time';
import ProfileUtils from 'utils/profile';
import { useAcceptConnectionMutation } from 'store/my-network/update-connection.api';
import { PROFILE } from 'constants/routes';
import { USER_ACCEPTED_A_CONNECTION } from 'constants/gt-classes';
import Link from 'next/link';
import Router from 'next/router';
import { TextButton } from 'components/Core/Button';
import * as gtag from '../../../utils/gtag';

const ConnectionRequestActivity: FunctionComponent<{
  activity: Activity;
}> = ({ activity }) => {
  const connectionId = activity.foreign_id.split('Connection:')[1];
  const { data: connection } = useGetConnectionByIdQuery(connectionId);
  const [acceptConnectionAPIRequest, { isLoading: isRequestAccepting }] =
    useAcceptConnectionMutation();
  const [rejectConnectionAPIRequest, { isLoading: isRequestRejecting }] =
    useRejectConnectionRequestMutation();

  const acceptConnectionRequest = async () => {
    await acceptConnectionAPIRequest({
      connectionHusslupId: connection.user?.husslupId,
    }).unwrap();
    gtag.event({
      label: 'user accepted connection button click',
      category: 'Connection',
      action: 'USER_ACCEPTED_A_CONNECTION',
    });
  };

  const rejectConnectionRequest = async () => {
    await rejectConnectionAPIRequest({
      connectionHusslupId: connection.user?.husslupId,
    }).unwrap();
    gtag.event({
      label: 'user rejected connection button click',
      category: 'Connection',
      action: 'USER_IGNORED_A_CONNECTION',
    });
  };

  return connection && connection?.status === 'pending' ? (
    <li className='notification-item'>
      <div
        className='notification-face icon'
        onClick={() => {
          Router.push(`${PROFILE}/${connection?.user?.id}`);
        }}
      >
        <NextImage
          width={56}
          height={56}
          src={
            ProfileUtils.getProfileImage(
              connection?.user?.profile_img,
              56,
              56,
            ) ?? ''
          }
          alt=''
        />
      </div>
      <div className='notification-content'>
        <p>
          <b>{connection?.user?.fullname}</b> wants to connect
        </p>
        <div className='d-flex justify-content-between align-items-baseline'>
          <div className='timestamp'>
            {timeSince(formatIso8601DateToUTCDate(activity?.time))}
          </div>
          <div className='notification-actions'>
            <TextButton
              onClick={acceptConnectionRequest}
              label='Accept'
              className={USER_ACCEPTED_A_CONNECTION}
              loading={isRequestAccepting}
              disabled={isRequestAccepting}
            />
            <TextButton
              onClick={rejectConnectionRequest}
              label='Ignore'
              className='text-error'
              loading={isRequestRejecting}
              disabled={isRequestRejecting}
            />
          </div>
        </div>
      </div>
    </li>
  ) : connection && connection.status === 'accepted' ? (
    <li>
      <Link
        href={`${PROFILE}/${connection?.user?.id}`}
        className='notification-item'
      >
        <div className='notification-face icon'>
          <NextImage
            width={56}
            height={56}
            src={
              ProfileUtils.getProfileImage(
                connection?.user?.profile_img,
                56,
                56,
              ) ?? ''
            }
            alt=''
          />
        </div>

        <div className='notification-content'>
          <p>
            You're now connected with <b>{connection?.user?.fullname}</b>
          </p>
          <div className='d-flex justify-content-between align-items-baseline'>
            <div className='timestamp'>
              {timeSince(formatIso8601DateToUTCDate(activity?.time))}
            </div>
          </div>
        </div>
      </Link>
    </li>
  ) : (
    <></>
  );
};

export default ConnectionRequestActivity;
