import { Activity } from 'getstream';
import NextImage from 'components/Core/Image';
import { FunctionComponent } from 'react';
import { useGetCommentByIdQuery } from 'store/post/comment.api';
import timeSince, { formatIso8601DateToUTCDate } from 'utils/date-time';
import ProfileUtils from 'utils/profile';
import { truncateWithEllipse } from 'utils/text';
import Link from 'next/link';
import { POST } from 'constants/routes';

const CommentedOnPostActivity: FunctionComponent<{
  activity: Activity;
}> = ({ activity }) => {
  const actedEntity = activity.object as string;
  const commentId = actedEntity.split(':')[1];
  const { data: comment, isLoading } = useGetCommentByIdQuery(commentId);

  if (isLoading) {
    return <></>;
  }

  return (
    <li>
      <Link
        href={`${POST}/${comment?.post?.post_id}`}
        className='notification-item'
      >
        <div className='notification-face icon'>
          <NextImage
            width={56}
            height={56}
            src={
              ProfileUtils.getProfileImage(
                comment?.user?.profile_img ?? '',
                56,
                56,
              ) ?? ''
            }
            alt=''
          />
        </div>
        <div className='notification-content'>
          <p>
            <b>{comment?.user?.fullname}</b> commented on your post{' '}
            <b>{truncateWithEllipse(comment?.comment)}</b>
          </p>
          <div className='timestamp'>
            {timeSince(formatIso8601DateToUTCDate(activity?.time))}
          </div>
        </div>
      </Link>
    </li>
  );
};

export default CommentedOnPostActivity;
