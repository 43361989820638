import React, { ReactElement } from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';

import Typography from 'components/Core/StylesGuide/Typography/Typography';
import { ColorCodes } from 'constants/color';
import HusslUpIcon from 'components/Icon';
import StarIcon from './StarIcon';

export type StepsType = {
  /**
   * Label to show below the star icon
   */
  label: string;
  /**
   * Position to show on top of the star icon
   */
  position: string;
};

type ProgressStepperProps = {
  /**
   * Array of {@link StepsType} to render
   */
  steps: StepsType[];
  /**
   * Percentage value of progress made
   ** 50 with 3 steps will result in 2 of the three steps being complete
   */
  percentageCompleted: number;
  /**
   * Width of the progress stepper
   */
  width?: number;
};

const ProgressStepper = ({
  steps,
  percentageCompleted,
  width,
}: ProgressStepperProps): ReactElement => (
  <ProgressBar
    percent={percentageCompleted}
    height={2}
    width={width}
    filledBackground={ColorCodes.Primary['interactive-controls']}
  >
    {steps.map((step) => (
      <Step key={step.label}>
        {({ accomplished }) => (
          <div className='step-container'>
            {/** @TODO use this after getting disabled color */}
            {/* <HusslUpIcon name='star-fill' variant='3x' wrapperClass='text-primary' /> */}
            {/** @TODO take color from constants after implementation */}
            <div className='star-wrapper'>
              <StarIcon color={accomplished ? '#4C1E4F' : '#C3B4C4'} />
              <Typography
                variant='p-md-d'
                className='step-count'
                style={{ color: ColorCodes.NeutralsWeb.background }}
              >
                {accomplished ? <HusslUpIcon name='check' variant='0x' /> : step.position}
              </Typography>
            </div>
            <Typography
              variant={accomplished ? 'p-md-b' : 'p-md-d'}
              className='text-nowrap'
              style={{
                /** @TODO take color from constants after implementation */
                color: accomplished ? '#4C1E4F' : '#C3B4C4',
                'margin-top': '6px',
              }}
            >
              {step.label}
            </Typography>
          </div>
        )}
      </Step>
    ))}
  </ProgressBar>
);

export default ProgressStepper;
