import { useMemo } from 'react';

import { selectCurrentUser } from 'store/auth/selectors';
import { UserSubscriptions } from 'store/subscription/subscription.types';
import { isSubscriptionStatusValid } from 'store/subscription/subscription.utils';

import { useAppSelector } from '../redux';

export const useSubscriptionChecks = () => {
  const user = useAppSelector(selectCurrentUser);

  const {
    activeOwnership,
    userSubscription,
    isInActiveSubscription,
    ownsActiveSubscription,
    firstValidSubscriptionId,
  } = useMemo(() => {
    const activeOwnership = user?.usersOwnership?.find(
      (data: UserSubscriptions) =>
        data?.isDeleted !== true &&
        isSubscriptionStatusValid(data?.subscriptionStatus),
    );
    const userSubscription = user?.userSubscriptions?.filter(
      (sub: { isDeleted: boolean }) => sub.isDeleted !== true,
    );

    const isInActiveSubscription = user?.userSubscriptions?.some(
      (subscription: UserSubscriptions) =>
        subscription?.isDeleted !== true &&
        isSubscriptionStatusValid(subscription?.subscriptionStatus),
    );

    const activeSubscription = user?.userSubscriptions?.find(
      (subscription: UserSubscriptions) =>
        subscription?.isDeleted !== true &&
        isSubscriptionStatusValid(subscription?.subscriptionStatus),
    );

    return {
      activeOwnership,
      userSubscription,
      ownsActiveSubscription: !!activeOwnership?.id,
      isInActiveSubscription,
      firstValidSubscriptionId:
        activeOwnership?.id || activeSubscription?.id || null,
    };
  }, [user?.usersOwnership]);

  return {
    activeOwnership,
    userSubscription,
    ownsActiveSubscription,
    isInActiveSubscription,
    firstValidSubscriptionId,
  };
};

export default useSubscriptionChecks;
