import {
  AllProfileListMap,
  ProfileListDropdownType,
  ProfileListFieldMapping,
  ProfileListOption,
  ProfileListSelectionModel,
} from 'commons/types/data';
import { SelectOptionType } from 'components/Core/Input/SelectInput';
import { MultiValue } from 'react-select';
import { ProfileListTables } from 'store/user/types';
import { TextUtils } from './text';

export type ProfileListValueType = 'id' | 'extra-details';

export enum SchoolSubType {
  UNDERGRAD = 'undergrad',
  GRADUATE = 'graduate',
}

export type FormatProfileListUpdateParam = {
  profileLists: ProfileListSelectionModel[];
  tableName: ProfileListTables;
  listSubType?: string;
};

export const emptyProfileList = {
  [ProfileListTables.Affiliations]: [],
  [ProfileListTables.Degrees]: [],
  [ProfileListTables.Demographics]: [],
  [ProfileListTables.Genres]: [],
  [ProfileListTables.InternationalPassports]: [],
  [ProfileListTables.Locations]: [],
  [ProfileListTables.NonIndustryExperience]: [],
  [ProfileListTables.Professions]: [],
  [ProfileListTables.ProjectTypes]: [],
  [ProfileListTables.Pronouns]: [],
  [ProfileListTables.Schools]: [],
  [ProfileListTables.SecondLanguage]: [],
};

export type SaveProfileListModel = {
  profileListTable: ProfileListTables;
  listType: ProfileListValueType;
  listValue: string;
  listSubType?: string;
  affiliation?: ProfileListSelectionModel;
  degree?: ProfileListSelectionModel;
  demographic?: ProfileListSelectionModel;
  genre?: ProfileListSelectionModel;
  international_passport?: ProfileListSelectionModel;
  location?: ProfileListSelectionModel;
  non_industry_experience?: ProfileListSelectionModel;
  profession?: ProfileListSelectionModel;
  project_type?: ProfileListSelectionModel;
  pronoun?: ProfileListSelectionModel;
  school?: ProfileListSelectionModel;
  second_language?: ProfileListSelectionModel;
} & Record<string, ProfileListSelectionModel>;

/**
 * Sort function to sort list by order
 * @param prevValue Sort function previous object
 * @param nextValue Sort function next object
 * @returns Sorted list based on @see order
 */
const sortByOrder = (
  prevValue: ProfileListSelectionModel,
  nextValue: ProfileListSelectionModel,
) => {
  if (prevValue && nextValue) {
    return (prevValue?.order ?? 0) > (nextValue?.order ?? 0) ? 1 : -1;
  }
  return 0;
};

export const getProfileListOptions = (
  data: ProfileListSelectionModel[],
): ProfileListOption[] => {
  if (!data || !data.length) {
    return [];
  }

  const dataCopy = [...data];
  return dataCopy.sort(sortByOrder).map((option) => ({
    value: option.id,
    id: option.id,
    label: option.text,
    text: option.text,
    category: option.category ?? '',
  }));
};

export const getProfileListFormValue = (
  values: MultiValue<SelectOptionType>,
  existingValues: ProfileListOption[],
  listSubType?: SchoolSubType,
) => {
  if (listSubType) {
    const existingPreviousList =
      existingValues?.filter((value) => value.listSubType !== listSubType) ??
      [];
    const updatedValues =
      values
        ?.map((value) => ({ ...value, listSubType }))
        ?.filter((value) => value?.value) ?? [];
    return [...existingPreviousList, ...updatedValues];
  }
  return values;
};

/**
 * Function to format profile list by {@link tableName} to save to api
 * @param param.profileLists List of profile list to format
 * @param param.tableName table to save
 * @param param.listSubType sub type of the list
 * @returns Formatted profile object of type {@link SaveProfileListModel[]}
 */
export const formatProfileListForUpdate = ({
  profileLists,
  tableName,
  listSubType,
}: FormatProfileListUpdateParam) => {
  if (!profileLists?.length) return [];

  const formattedList: SaveProfileListModel[] = [];

  profileLists.forEach((profileList) => {
    if (listSubType) {
      if (profileList.listSubType === listSubType) {
        formattedList.push({
          profileListTable: tableName,
          listType: profileList.id ? 'id' : 'extra-details',
          listValue: profileList.id ? String(profileList.id) : profileList.text,
          listSubType,
        } as SaveProfileListModel);
      }
    } else {
      formattedList.push({
        profileListTable: tableName,
        listType: profileList.id ? 'id' : 'extra-details',
        listValue: profileList.id ? String(profileList.id) : profileList.text,
        listSubType,
      } as SaveProfileListModel);
    }
  });

  return formattedList;
};

type ConcatenateExistingProfileListsParam = {
  currentDropdowns: ProfileListDropdownType[];
  existingData: SaveProfileListModel[];
  formattedProfileList: SaveProfileListModel[];
};

export const concatenateExistingProfileLists = ({
  currentDropdowns,
  formattedProfileList,
  existingData,
}: ConcatenateExistingProfileListsParam) => {
  const currentDropdownLists =
    currentDropdowns?.map((dropdown) => dropdown.listType ?? []) ?? [];

  const filteredExistingData =
    existingData?.filter(
      (data) => !currentDropdownLists.includes(data.profileListTable),
    ) ?? [];

  return [...formattedProfileList, ...filteredExistingData];
};

/**
 * Intermediate function for {@link formatProfileList} to get the profiel list object
 * @param profileList Profile list object to process
 * @returns Formatted profile object of type {@link ProfileListSelectionModel}
 */
const getProfileListObject = (profileList: SaveProfileListModel) => {
  const currentTable = profileList.profileListTable;
  const currentListObject = {
    ...profileList[ProfileListFieldMapping[profileList.profileListTable]],
    value:
      profileList[ProfileListFieldMapping[profileList.profileListTable]]?.id,
    label:
      profileList[ProfileListFieldMapping[profileList.profileListTable]]?.text,
    listSubType: profileList?.listSubType,
  };

  if (profileList.listType === 'extra-details') {
    return {
      currentTable,
      currentListObject: {
        text: profileList?.listValue,
        label: profileList?.listValue,
        value: profileList?.listValue,
        listSubType: profileList?.listSubType,
      },
    };
  }
  return {
    currentTable,
    currentListObject,
  };
};

/**
 * Function to format profile list to save into redux state
 * @param profileLists List of profile list to format
 * @returns Formatted profile object of type {@link ProfileListSelectionModel}
 */
export const initializeProfileList = (
  profileLists?: SaveProfileListModel[],
) => {
  let formattedList: AllProfileListMap = { ...emptyProfileList };

  if (!profileLists?.length) return formattedList;

  profileLists.forEach((profileList) => {
    const { currentListObject, currentTable } =
      getProfileListObject(profileList);

    const currentList = formattedList[currentTable];

    if (currentListObject && currentListObject.text) {
      formattedList = {
        ...formattedList,
        [currentTable]: [...currentList, currentListObject],
      };
    }
  });

  return formattedList;
};

type ExtractSchoolTextParams = {
  schools: ProfileListOption[];
  degrees: ProfileListOption[];
};

/**
 * Function to extract school text from old backend school object
 * @param param.schools Schools list data from old backend
 * @param param.degrees Degrees list data from old backend
 * @returns School and Degree concatenated text to show in profile
 */
export const extractSchoolText = ({
  schools,
  degrees,
}: ExtractSchoolTextParams) => {
  let undergrad = '';
  let graduate = '';

  if (schools?.length) {
    schools.forEach((school) => {
      if (school.text) {
        const degree = degrees?.find(
          (degree) => degree.listSubType === school.listSubType,
        );
        const schoolText = TextUtils.concatIfExists(
          [school.text, degree?.text || ''],
          ', ',
        );
        if (school.listSubType === 'undergrad') {
          undergrad += schoolText;
        } else if (school.listSubType === 'graduate') {
          graduate += schoolText;
        }
      }
    });
  }
  return { undergrad, graduate };
};

export const mergeProfileListValues = (
  values: AllProfileListMap,
  listType: ProfileListTables,
) => {
  const profileList = values?.[listType];
  if (listType === ProfileListTables.Schools) {
    const { undergrad, graduate } = extractSchoolText({
      schools: values[ProfileListTables.Schools],
      degrees: values[ProfileListTables.Degrees],
    });
    return `${undergrad}\n${graduate}`;
  }

  return profileList?.map((value: any) => value.text).join(', ');
};

type ExtractSchoolTextFromDataParams = {
  schools: SaveProfileListModel[];
  degrees: SaveProfileListModel[];
};

/**
 * Function to extract school text from old backend school object
 * @param param.schools Schools list data from old backend
 * @param param.degrees Degrees list data from old backend
 * @returns School and Degree concatenated text to show in profile
 */
export const extractSchoolTextFromData = ({
  schools,
  degrees,
}: ExtractSchoolTextFromDataParams) => {
  let undergrad = '';
  let graduate = '';

  if (schools?.length) {
    schools.forEach((school) => {
      if (school?.school?.text) {
        const degree = degrees?.find(
          (degree) => degree.listSubType === school.listSubType,
        );
        const schoolText = TextUtils.concatIfExists(
          [school.school.text, degree?.degree?.text || ''],
          ', ',
        );
        if (school.listSubType === 'undergrad') {
          undergrad += schoolText;
        } else if (school.listSubType === 'graduate') {
          graduate += schoolText;
        }
      }
    });
  }
  return { undergrad, graduate };
};

export const mergeProfileListData = (
  data: SaveProfileListModel[],
  listType: ProfileListTables,
) => {
  const profileList =
    data?.filter((row) => row.profileListTable === listType) ?? [];
  if (listType === ProfileListTables.Schools) {
    const schools =
      data?.filter(
        (row) => row.profileListTable === ProfileListTables.Schools,
      ) ?? [];
    const degrees =
      data?.filter(
        (row) => row.profileListTable === ProfileListTables.Degrees,
      ) ?? [];
    const { undergrad, graduate } = extractSchoolTextFromData({
      schools,
      degrees,
    });
    return `${undergrad}\n${graduate}`;
  }
  return profileList?.map((value: any) => value.text).join(', ');
};
