import React from 'react';
import { Form } from 'react-bootstrap';
import Select, {
  StylesConfig,
  components,
  OptionProps,
  MultiValue,
  ActionMeta,
  MenuPlacement,
  createFilter,
} from 'react-select';

import { ColorCodes } from 'constants/color';
import { ProfileListColorChange } from 'commons/types/data';
import { useTranslation } from 'react-i18next';
import Typography from '../StylesGuide/Typography';

const Option = ({ isSelected, label, ...props }: OptionProps) => (
  <div>
    <components.Option
      {...props}
      isSelected={isSelected}
      label={label}
      className='select-options'
    >
      <Typography variant='p-sm'>{label}</Typography>
      <Form.Check checked={isSelected} readOnly />
    </components.Option>
  </div>
);

/** @TODO add later after font icon implementation */
// const MultiValueRemove = (props: MultiValueRemoveProps) => (
//   <components.MultiValueRemove {...props}>
//     <Typography variant='p-md'>x</Typography>
//   </components.MultiValueRemove>
// );

const colourStyles: StylesConfig<any, true> = {
  menu: (styles, { menuPlacement }) => ({
    ...styles,
    marginBottom: menuPlacement !== 'top' ? '40px' : '10px',
  }),
  control: (styles, { isDisabled }) => ({
    ...styles,
    borderWidth: '1.5px',
    borderRadius: '8px',
    padding: '2px 0px 2px 0px',
    borderColor: ColorCodes.NeutralsWeb['sub-text-creative-content'],
    boxShadow: undefined,
    ':hover': {
      borderColor: ColorCodes.Primary['background-placeholder'],
      boxShadow: `0 0 0 1px ${ColorCodes.Primary['background-placeholder']}`,
    },
    backgroundColor: isDisabled ? 'transparent' : 'transparent',
  }),
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected
      ? ColorCodes.Primary['background-placeholder']
      : ColorCodes.NeutralsWeb.background,
    color: ColorCodes.NeutralsWeb['body-text'],
    cursor: isDisabled ? 'not-allowed' : 'default',
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      ...styles[':hover'],
      /** @TODO color name not yet defined. use color variable after color name is assigned */
      backgroundColor: '#F3F0F3',
    },
    ':active': {
      ...styles[':active'],
      backgroundColor: ColorCodes.Primary['background-placeholder'],
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '4px',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: ColorCodes.NeutralsWeb.background,
    borderRadius: '6px',
    margin: '2px',
    border: `1.5px solid ${ColorCodes.Primary['interactive-controls']};`,
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: ColorCodes.Primary['interactive-controls'],
    fontFamily: 'SFProText',
    fontSize: '14px',
    lineHeight: '140%',
    fontWeight: 500,
  }),

  multiValueRemove: (styles) => ({
    ...styles,
    fontWeight: 200,
    paddingLeft: 0,
    color: ColorCodes.Primary['interactive-controls'],
    ':hover': {
      color: ColorCodes.Primary['interactive-controls'],
    },
  }),
};

export type SelectOptionType = {
  label: string;
  value: string | number;
  text: string;
};

type SelectInputProps<T extends SelectOptionType> = {
  /**
   * Optional label text
   */
  labelText?: string;
  /**
   * Array of option of type {@link SelectOptionType}
   */
  options: T[];
  handleChange: (newValue: MultiValue<T>, actionMeta?: ActionMeta<T>) => void;
  value: MultiValue<SelectOptionType>;
  loading?: boolean;
  disabled?: boolean;
  error?: string;
  hint?: string;
  menuPlacement?: MenuPlacement;
  optionDisabled?: () => boolean;
  limitNumber?: number;
  listType?: string;
  colorChange?: ProfileListColorChange;
  limitTextType?: string;
  handleMenuClose?: () => void;
};

const SelectInput = <T extends SelectOptionType>({
  hint,
  labelText,
  options,
  loading,
  handleChange,
  value,
  error,
  disabled,
  menuPlacement = 'auto',
  optionDisabled,
  limitNumber = 0,
  listType,
  colorChange,
  limitTextType,
  handleMenuClose,
}: SelectInputProps<T>) => {
  const { t } = useTranslation('dropdown');

  return (
    <Form.Group>
      {labelText ? (
        <Form.Label>
          {labelText}
          {limitNumber > 0 && (
            <div
              className={
                colorChange && colorChange[limitTextType ?? '']
                  ? 'sub-text colorChange'
                  : 'sub-text '
              }
            >
              {`${t('CHOOSE_DROPDOWN_LIMIT.value')} ${limitNumber} ${listType}`}
            </div>
          )}
          {hint && <div className='sub-text'>{hint}</div>}
        </Form.Label>
      ) : null}
      <Select
        closeMenuOnSelect={false}
        isMulti
        value={value}
        placeholder=''
        filterOption={createFilter({ ignoreAccents: false })}
        isLoading={loading}
        hideSelectedOptions={false}
        menuPlacement={menuPlacement}
        menuPosition='absolute'
        options={options}
        isDisabled={disabled}
        onChange={handleChange}
        styles={colourStyles}
        isClearable={false}
        components={{ Option, IndicatorSeparator: () => null }}
        classNamePrefix='react-select'
        isOptionDisabled={optionDisabled}
        onMenuClose={handleMenuClose}
      />
      {error && (
        <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default SelectInput;
