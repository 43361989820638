import { TextButton } from 'components/Core/Button';
import NextImage from 'components/Core/Image';
import { PROFILE } from 'constants/routes';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FunctionComponent, useMemo } from 'react';
import { useGetVouchByIdQuery } from 'store/user/vouch/user-vouch.api';
import timeSince, { formatIso8601DateToUTCDate } from 'utils/date-time';
import ProfileUtils from 'utils/profile';

const VouchRequestActivity: FunctionComponent<{
  activity: any;
}> = ({ activity }) => {
  const vouchId = activity.foreign_id.split(':')[1];
  const { data: vouch } = useGetVouchByIdQuery({ vouchId });
  const router = useRouter();

  const { isAccepted, isDeclined, isPending } = useMemo(
    () => ({
      isAccepted: vouch?.status === 0,
      isDeclined: vouch?.status === 2,
      isPending: vouch?.status === 1,
    }),
    [vouch?.status],
  );

  const handlePreviewVouch = () => {
    router.push(`${PROFILE}/Edit/Vouches`);
  };

  return isPending ? (
    <li className='notification-item'>
      <div className='notification-face icon'>
        <NextImage
          width={56}
          height={56}
          src={
            ProfileUtils.getProfileImage(
              vouch?.vouchedBy.profile_img ?? '',
              56,
              56,
            ) ?? ''
          }
          alt=''
        />
      </div>
      <div className='notification-content'>
        <p>
          <b>{vouch?.vouchedBy.fullname} </b>
          sent you a vouch
        </p>
        <div className='d-flex justify-content-between align-items-baseline'>
          <div className='timestamp'>
            {timeSince(formatIso8601DateToUTCDate(activity?.time))}
          </div>
          <div className='notification-actions'>
            <TextButton onClick={handlePreviewVouch} label='Review' />
          </div>
        </div>
      </div>
    </li>
  ) : (
    <li>
      <Link href={`${PROFILE}/Edit/Vouches`} className='notification-item'>
        <div className='notification-face icon'>
          <NextImage
            width={56}
            height={56}
            src={
              ProfileUtils.getProfileImage(
                vouch?.vouchedBy.profile_img ?? '',
                56,
                56,
              ) ?? ''
            }
            alt=''
          />
        </div>
        <div className='notification-content'>
          <p>
            {isAccepted && `You've accepted a vouch from `}
            {isDeclined && `You've declined a vouch from `}
            <b>{vouch?.vouchedBy.fullname} </b>
          </p>
          <div className='timestamp'>
            {timeSince(formatIso8601DateToUTCDate(activity?.time))}
          </div>
        </div>
      </Link>
    </li>
  );
};

export default VouchRequestActivity;
