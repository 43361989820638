import KebabDropdown, {
  KebabDropdownItem,
} from 'components/Core/KebabDropdown/KebabDropdown';
import HusslUpIcon from 'components/Icon';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import ProfileUtils from 'utils/profile';
import { ActionButtons } from '.';
import CompanyMemberDetails from './CompanyDetails';

interface CardIProps {
  onClick?: () => void;
  logo?: string;
  headings?: string[];
  actionButtons?: ActionButtons[];
  companyName?: string;
  dropdown?: KebabDropdownItem[];
  id?: number;
  husslupId?: string;
  searchText?: string;
  fillBackground?: boolean;
}

const CompanyMemberCard: FunctionComponent<CardIProps> = ({
  actionButtons,
  headings,
  logo = '',
  companyName,
  dropdown,
  onClick,
  id,
  husslupId = '',
  searchText,
  fillBackground,
}) => {
  const [,setIsPerfectlySquare] = useState<
    boolean | undefined
  >(undefined);
  const profileImage = useMemo(() => {
    if (logo) {
      return ProfileUtils.getProfileImageWithoutMaskOriginal(
        logo,
        238,
        fillBackground,
      );
    }
    return '/company-placeholder.png';
  }, [logo]);

  const load = (src: string) => {
    if (src === '/member-placeholder.png') {
      setIsPerfectlySquare(false);
      return;
    }
    const image = new Image();
    image.src = src;
    image.onload =  () => {
      setIsPerfectlySquare(image.height === image.width);
    };
  };

  useEffect(() => {
    load(profileImage);
  }, [profileImage]);

  return (
    <div className='company-card'>
      <div
        className='company-card__top'
        style={{
          backgroundImage: `linear-gradient(0deg, #000 3.96%, rgba(0, 0, 0, 0.00) 24%), url(${profileImage})`,
        }}
        onClick={onClick}
        tabIndex={0}
        role='button'
        onKeyUp={() => {}}
      >
      </div>
      <div className='company-card__bottom'>
      <div className='card-misc '>
          <CompanyMemberDetails
            name={companyName ?? ''}
            headings={headings}
            searchText={searchText}
          />
        </div>
        <div className='action-btns'>
          {(actionButtons?.length ?? 0) > 1 ? (
            <div className='multiple-btn__holder w-100'>
              {actionButtons?.map((ab, i) => (
                <Button
                  disabled={ab.disabled}
                  className='card-btn multiple-btn'
                  onClick={ab.onClick}
                  key={ab?.icon || ab?.label || i}
                >
                  {ab.loading ? (
                    <Spinner
                      as='span'
                      className='multiple-btn-loader'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    ab.icon && <HusslUpIcon name={ab.icon} />
                  )}
                  {ab.label}
                </Button>
              ))}
            </div>
          ) : (
            <div className='action-buttons__holder w-100'>
              {actionButtons?.map((ab, i) => (
                <Button
                  className='card-btn'
                  onClick={ab.onClick}
                  key={ab?.icon || ab?.label || i}
                  disabled={ab.disabled}
                >
                  {ab.loading ? (
                    <Spinner
                      as='span'
                      className=' '
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    ab.icon && <HusslUpIcon name={ab.icon} />
                  )}
                  {ab.label}
                </Button>
              ))}
            </div>
          )}
          {dropdown && (
            <KebabDropdown
              dropdownItems={dropdown}
              align='end'
              drop='up'
              profileId={id}
              husslupId={husslupId}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default CompanyMemberCard;
