import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  PROFILE_ONBOARD_INVITE_CODE,
  // PROFILE_ONBOARD_INFO,
  HOME,
  // PROFILE_ONBOARD_CREDITS,
  PROFILE_ONBOARD_CREDITS,
  PROFILE_ONBOARD_SPLASH,
} from 'constants/routes';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  authApiSlice,
  useCheckIfEmailLoginUserMutation,
  useGetCurrentAuthUserQuery,
} from 'store/auth/auth.api';
import { userLoggedIn, userLoggedOut } from 'store/auth/auth.slice';
import {
  auth0ApiSlice,
  OauthLoginCredentials,
  useLoginUserMutation,
  // useLoginWithPhoneMutation,
  useLogoutUserMutation,
} from 'store/auth/auth0.api';
import {
  selectCurrentUserCompany,
  selectCurrentUserGetstreamToken,
  selectCurrentUserHusslupId,
  selectCurrentUserId,
  selectIsAuthReady,
  selectIsLoggedIn,
  selectIsLoggingInAfterSignup,
  selectIsPhoneLoginUser,
} from 'store/auth/selectors';
import { resetSerendipityMode } from 'store/discover/serendipity.slice';
import { selectIsOnboarding, setIsOnboarding } from 'store/user/onboard';
import { UserType } from 'store/user/types';
import { clearActiveStaffingSubscription } from 'store/subscription/subscription.slice';

import {
  getNextOnboardRoute,
  hasFilledMinimalOnboardInfo,
  isOnboardRoute,
} from 'utils/onboard';
import { useAppSelector, useAppDispatch } from './redux';

const useAuth = () => {
  const dispatch = useAppDispatch();
  const isAuthReady = useAppSelector(selectIsAuthReady);
  const isOnboarding = useAppSelector(selectIsOnboarding);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const isLoggingInAfterSignup = useAppSelector(selectIsLoggingInAfterSignup);
  const isPhoneLoginUser = useAppSelector(selectIsPhoneLoginUser);
  const company = useAppSelector(selectCurrentUserCompany);
  const { data: authUser } = useGetCurrentAuthUserQuery(undefined, {
    skip: !isLoggedIn,
  });
  const currentUserGetstreamToken = useAppSelector(
    selectCurrentUserGetstreamToken,
  );
  const currentUserHusslupId = useAppSelector(selectCurrentUserHusslupId);
  const currentUserId = useAppSelector(selectCurrentUserId);

  const router = useRouter();
  const [isAuthorizationReady, setIsAuthorizationReady] = useState(false);
  const [loginUser, { isLoading: isLoggingIn }] = useLoginUserMutation();
  const [logoutUser, { isLoading: isLoggingOut }] = useLogoutUserMutation();
  // const [loginWithPhone, { isLoading: isLoggingInWithPhone }] =
  //   useLoginWithPhoneMutation();
  const [checkIfEmailLoginUser] = useCheckIfEmailLoginUserMutation();

  const logout = async () => {
    await logoutUser();
    setIsAuthorizationReady(false);
    dispatch(setIsOnboarding(false));
    dispatch(baseApiSlice.util.resetApiState());
    dispatch(auth0ApiSlice.util.resetApiState());
    dispatch(resetSerendipityMode());
    dispatch(userLoggedOut());
    dispatch(clearActiveStaffingSubscription());
    localStorage.removeItem('currentUserHusslupId');
  };

  const login = async ({ email, password }: OauthLoginCredentials) => {
    const tokens = await loginUser({
      email: email.trim(),
      password,
    }).unwrap();
    dispatch(userLoggedIn({ ...tokens }));
    const { isEmailLogin } = await checkIfEmailLoginUser().unwrap();
    dispatch(authApiSlice.endpoints.getCurrentAuthUser.initiate());
    dispatch(
      userLoggedIn({
        ...tokens,
        isAuthReady: true,
        isEmailLogin,
      }),
    );
  };

  // const loginUsingPhone = async ({
  //   phoneNumber,
  //   verificationCode,
  // }: {
  //   phoneNumber: string;
  //   verificationCode: string;
  // }) => {
  //   setIsAuthorizationReady(false);
  //   // const tokens = await loginWithPhone({
  //   //   code: verificationCode,
  //   //   phoneNumber,
  //   // }).unwrap();
  //   // dispatch(userLoggedIn({ ...tokens }));
  //   const { isEmailLogin } = await checkIfEmailLoginUser().unwrap();
  //   dispatch(authApiSlice.endpoints.getCurrentAuthUser.initiate());
  //
  //   dispatch(
  //     userLoggedIn({
  //       // ...tokens,
  //       isAuthReady: true,
  //       isEmailLogin,
  //     }),
  //   );
  // };

  useEffect(() => {
    const currentUser = authUser as UserType;
    if (isLoggedIn && currentUser) {
      localStorage.setItem('currentUserHusslupId', currentUser.husslupId);
    }
    if (isLoggedIn && currentUser && !isOnboarding && !isAuthorizationReady) {
      if (
        currentUser?.inviteStatus === 'approved' &&
        hasFilledMinimalOnboardInfo(currentUser)
      ) {
        if (isOnboardRoute(router.asPath)) {
          router.push(HOME);
          setTimeout(() => {
            setIsAuthorizationReady(true);
          }, 1000);
        } else {
          setIsAuthorizationReady(true);
        }
      } else if (hasFilledMinimalOnboardInfo(currentUser)) {
        if (currentUser?.inviteStatus !== 'approved') {
          if (isOnboardRoute(router.asPath)) {
            router.push(getNextOnboardRoute(currentUser, router.asPath));
            setIsAuthorizationReady(true);
          } else {
            router.push(PROFILE_ONBOARD_INVITE_CODE);
            setIsAuthorizationReady(true);
          }
        } else {
          if (isOnboardRoute(router.asPath)) {
            router.push(HOME);
            setIsAuthorizationReady(true);
          }
          setIsAuthorizationReady(true);
        }
      } else if (isOnboardRoute(router.asPath)) {
        if (router.asPath !== PROFILE_ONBOARD_CREDITS) {
          router.push(getNextOnboardRoute(currentUser, router.asPath));
          setIsAuthorizationReady(true);
        } else {
          if (isLoggingInAfterSignup) {
            router.push(PROFILE_ONBOARD_SPLASH);
          } else {
            router.push(PROFILE_ONBOARD_CREDITS);
          }
          setIsAuthorizationReady(true);
        }
      } else {
        if (isLoggingInAfterSignup) {
          router.push(PROFILE_ONBOARD_SPLASH);
        } else {
          router.push(PROFILE_ONBOARD_CREDITS);
        }
        setIsAuthorizationReady(true);
      }
    }
  }, [authUser, isLoggedIn, isAuthorizationReady]);

  return {
    isAuthReady,
    isLoggedIn,
    user: authUser,
    company,
    logout,
    login,
    // loginUsingPhone,
    // isLoggingInWithPhone,
    isLoggingIn,
    isLoggingOut,
    isPhoneLoginUser,
    isAuthorizationReady,
    currentUserGetstreamToken,
    currentUserHusslupId,
    currentUserId,
  };
};

export default useAuth;
