import { CommentActivities } from 'commons/types/comment.type';
import GenericDropdown, {
  GenericDropdownItem,
} from 'components/Core/GenericDropdown';
import SquircleImage from 'components/Core/SquircleImage/SquircleImage';
import { PROFILE } from 'constants/routes';
import { useRouter } from 'next/router';
import {
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import timeSince from 'utils/date-time';
import ProfileUtils from 'utils/profile';
import Markdown from 'react-markdown';
import Button, { ButtonTypes, ButtonVariants } from 'components/Core/Button';
import HusslUpIcon from 'components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/auth/selectors';
import PostDropdownToggle from 'components/Core/GenericDropdown/PostDropdownToggle';
import {
  useEditCommentMutation,
  useLikeCommentMutation,
  useUnLikeCommentMutation,
} from 'store/post/comment.api';
import {
  selectGetPostWithTagParams,
  selectPostActionParams,
} from 'store/post/selectors';
import { setPostActionParams } from 'store/post/post.slice';
import PostUtils from 'utils/post';
import Link from 'next/link';
import CommentLikesCount from './CommentLikesCount';
import CreateCommentTextArea from './CreateCommentTextArea';

export interface PostCommentProps {
  comment: CommentActivities;
  commentActionGetter: (
    comment: CommentActivities,
  ) => GenericDropdownItem[] | null;
  postId?: number;
  onReplyClicked: (commentId: number) => void;
}
const rows = 1;
const cols = 80;
// const MAX_TEXTAREA_ROWS = 20;

const PostCommentContainer: FunctionComponent<PostCommentProps> = ({
  comment,
  commentActionGetter,
  postId,
  onReplyClicked,
}): ReactElement => {
  const router = useRouter();
  const currentUser = useSelector(selectCurrentUser);
  const [editComment, { isLoading }] = useEditCommentMutation();
  const [mentionedUsers, setMentionedUsers] = useState<string[]>(
    comment?.mentionedUsers || [],
  );
  const [likeComment] = useLikeCommentMutation();
  const [unLikeComment] = useUnLikeCommentMutation();
  const [commentItem, setCommentItem] = useState(comment.comment || '');
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [adjustedRows, setAdjustedRows] = useState(rows);

  const postActionParams = useSelector(selectPostActionParams);

  const getPostWithTagParams = useSelector(selectGetPostWithTagParams);
  const handleLikeComment = async (commentId: number, isLikedByMe: boolean) => {
    if (isLikedByMe) {
      await unLikeComment({
        body: {
          commentId,
        },
        getPostWithTagParams,
        postId: postId as number,
        currentUser,
        commentLevel: comment.level,
      });
      return;
    }
    await likeComment({
      body: {
        commentId,
      },
      getPostWithTagParams,
      postId: postId as number,
      currentUser,
      commentLevel: comment.level,
    });
  };

  const handleCancel = () => {
    dispatch(setPostActionParams({ postId: null, commentId: undefined }));
    setCommentItem(comment?.comment || '');
    setAdjustedRows(1);
  };

  const handleEditComment = async () => {
    if (comment) {
      await editComment({
        body: {
          comment: PostUtils.reverseFormatCommentBody(
            commentItem,
            comment?.mentionedUserIdWithHusslupId,
          ),
          mentionedUsersId: mentionedUsers,
        },
        commentBy: currentUser,
        getPostWithTagParams,
        commentId: comment.commentId as number,
        postId: postId as number,
        commentLevel: comment.level,
      }).unwrap();
      // setCommentItem('');
      dispatch(setPostActionParams({ postId: null, commentId: undefined }));
    }
  };

  const adjustHeight = () => {
    if (inputRef?.current && inputRef?.current?.value) {
      const textAreaValue = inputRef?.current?.value;
      const numberOfColumns = inputRef?.current?.cols;

      let linecount = 0;
      textAreaValue.split('\n').forEach((line) => {
        const extraRowForLongColumn = Math.floor(line.length / numberOfColumns);
        const rowsToAdd =
          1 + (line.length / cols > 1 ? extraRowForLongColumn : 0);
        linecount += rowsToAdd;
      });

      // if (linecount < MAX_TEXTAREA_ROWS) {
      setAdjustedRows(linecount);
      // }
    }
  };

  const handleClickReply = (commentId: number) => {
    onReplyClicked(commentId);
  };

  useEffect(() => {
    if (!commentItem) {
      setAdjustedRows(1);
      setMentionedUsers([]);
    } else {
      adjustHeight();
      setMentionedUsers(() => {
        const oldMentions =
          comment?.mentionedUserIdWithHusslupId
            ?.filter(
              (idObject) =>
                commentItem?.includes(idObject.husslupId) ||
                commentItem?.includes(String(idObject.id)),
            )
            .map((idObject) => idObject.husslupId) || [];
        const existingMentions = mentionedUsers.filter((husslupId) =>
          commentItem?.includes(husslupId),
        );
        return [...oldMentions, ...existingMentions];
      });
    }
  }, [commentItem]);

  const contentHeight = useMemo(() => {
    let height = 48;
    if (inputRef?.current) {
      const lineHeight = window
        .getComputedStyle(inputRef?.current as any)
        .lineHeight.replace('px', '');
      height = Number(lineHeight) * 1.6 * adjustedRows;
    }
    return height > 48 ? height : 48;
  }, [adjustedRows]);

  const buttonDisabled = useMemo(
    () => isLoading || commentItem === comment.comment,
    [isLoading, commentItem],
  );

  const getUser = async (husslupId: string) => {
    // TODO: this is the hot fix comment api should accept husslup ids instead of user ids and should return exactly like post api
    if (
      mentionedUsers.some(
        (existingHusslupId) => existingHusslupId === husslupId,
      )
    )
      return;
    setMentionedUsers((husslupIds) => [...husslupIds, husslupId]);
  };

  const editOption = !!(
    postActionParams?.action === 'edit-comment' &&
    postActionParams.commentId === comment.commentId
  );

  useEffect(() => {
    if (!editOption) {
      setCommentItem(comment?.comment || '');
    }
  }, [editOption]);

  const likeIconFill = comment?.userLikes?.some(
    (usr) => usr.husslupId === currentUser?.husslupId,
  );

  return (
    <>
      <li className='post-comment__item' key={comment?.commentId}>
        <SquircleImage
          alt='Comment Profile Image'
          r1={0.04}
          r2={0.5}
          width={40}
          height={40}
          onClick={() => {
            const profileId = comment.user?.id;
            router.push(`${PROFILE}/${profileId}`);
          }}
          imgUrl={ProfileUtils.getProfileImage(
            comment?.user?.profile_img ?? '',
            40,
            40,
          )}
        />
        <div className='comment-wrapper'>
          <div className='comment'>
            <div className='user-accounts-wrap'>
              <div className='user-name'>{comment?.user?.fullname}</div>
              <small className='time-since'>
                {timeSince(comment?.createdDate)}
              </small>
              <GenericDropdown
                align='end'
                dropdownItems={commentActionGetter(comment)}
                toggleComponent={PostDropdownToggle}
                className='ml-1'
              />
            </div>
            <div className='comment-body-holder'>
              {editOption ? (
                <>
                  <CreateCommentTextArea
                    placeholder='Write a Comment...'
                    required
                    value={commentItem}
                    onChange={(event: any) => {
                      setCommentItem(event.target.value?.replaceAll(/\r/g, ''));
                    }}
                    wrapperClass={`comment edit-comment ${
                      comment.comment === commentItem ? 'initial' : ''
                    }`}
                    inputRef={inputRef}
                    rows={adjustedRows}
                    cols={cols}
                    onUsersMentioned={(id) => {
                      getUser(id as string);
                    }}
                    style={{
                      height: contentHeight,
                    }}
                  />
                  <div className='edit-meta'>
                    <Button
                      label='Cancel'
                      variant={ButtonVariants.text}
                      onClick={handleCancel}
                    />
                    <Button
                      label=''
                      variant={ButtonVariants.text}
                      disabled={buttonDisabled}
                      type={ButtonTypes.button}
                      onClick={handleEditComment}
                      leftIcon={
                        <HusslUpIcon
                          name='sended'
                          variant='3x'
                          wrapperClass={
                            buttonDisabled
                              ? 'text-muted-edit-comment'
                              : 'text-primary'
                          }
                        />
                      }
                    />
                  </div>
                </>
              ) : (
                <Markdown
                  components={{
                    p: (props) => {
                      if (
                        props.children.length > 1 &&
                        props.children[0]?.toString().includes('@')
                      ) {
                        return (
                          <p className='caption'>
                            {props.children.map((text) => {
                              if (typeof text === 'string') {
                                return text.replace('@', '');
                              }
                              return text;
                            })}
                          </p>
                        );
                      }
                      return <p className='caption'>{props.children}</p>;
                    },
                    a: (props) => {
                      if (props.href?.startsWith('/Profile')) {
                        return (
                          <span className='mentioned'>
                            <Link href={props?.href}>{props.children}</Link>
                          </span>
                        );
                      }
                      return <>{props.children}</>;
                    },
                  }}
                >
                  {`${comment?.comment ?? ''}${
                    comment.isEdited ? ' (Edited)' : ''
                  }`}
                </Markdown>
              )}
            </div>
            <CommentLikesCount
              likes={Number(comment.likesQty)}
              commentId={comment.commentId as any}
            />
          </div>
          {!editOption && (
            <div className='comments-meta'>
              <Button
                label='Like'
                variant={ButtonVariants.text}
                leftIcon={
                  <HusslUpIcon name={likeIconFill ? 'star-fill' : 'star'} />
                }
                onClick={() =>
                  handleLikeComment(
                    comment.commentId as number,
                    comment?.userLikes?.some(
                      (usr) => usr.husslupId === currentUser?.husslupId,
                    ) || false,
                  )
                }
              />
              {comment.level === 0 && (
                <Button
                  label='Reply'
                  variant={ButtonVariants.text}
                  leftIcon={
                    <HusslUpIcon name='reply' wrapperClass='horizontal-flip' />
                  }
                  onClick={() => handleClickReply(comment.commentId as number)}
                />
              )}
            </div>
          )}
        </div>
      </li>
    </>
  );
};

export default PostCommentContainer;
