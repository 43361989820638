import { SEARCH } from 'constants/routes';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { Form } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';

const SearchBar: FunctionComponent = () => {
  const router = useRouter();
  const searchIcon = (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6294 22.8351L19.9627 18.1684C21.4916 16.1814 22.2039 13.6854 21.954 11.1907C21.7042 8.69602 20.511 6.39094 18.6183 4.7466C16.7257 3.10226 14.2765 2.24282 11.7713 2.34395C9.26619 2.44507 6.89424 3.49912 5.14029 5.29066C3.38633 7.0822 2.38281 9.47595 2.33481 11.9827C2.28682 14.4894 3.19798 16.9198 4.88207 18.7772C6.56616 20.6345 8.89602 21.7786 11.3955 21.9756C13.8949 22.1725 16.3752 21.4074 18.3294 19.8368L22.996 24.5034C23.1041 24.6122 23.2324 24.6986 23.3739 24.7578C23.5153 24.817 23.667 24.8477 23.8203 24.8482C23.9736 24.8488 24.1255 24.8191 24.2673 24.761C24.4091 24.7028 24.5381 24.6173 24.6469 24.5093C24.7557 24.4012 24.8421 24.2729 24.9013 24.1314C24.9604 23.99 24.9912 23.8383 24.9917 23.6851C24.9922 23.5318 24.9626 23.3799 24.9044 23.238C24.8463 23.0962 24.7607 22.9672 24.6527 22.8584L24.6294 22.8351ZM12.1344 19.6501C10.6261 19.657 9.14979 19.2161 7.89237 18.3832C6.63495 17.5503 5.65304 16.3629 5.07106 14.9715C4.48907 13.5801 4.3332 12.0472 4.62319 10.5671C4.91318 9.08696 5.63598 7.72623 6.70002 6.65728C7.76406 5.58833 9.12144 4.85927 10.6002 4.56247C12.079 4.26567 13.6125 4.41448 15.0067 4.99005C16.4008 5.56562 17.5927 6.54205 18.4313 7.79563C19.27 9.0492 19.7177 10.5235 19.7177 12.0318C19.7208 14.0472 18.924 15.9814 17.5022 17.4098C16.0803 18.8382 14.1498 19.6439 12.1344 19.6501Z"
        fill="#C3B4C4"
      />
    </svg>
  );
  const handleEnterKeyPress = (event: React.KeyboardEvent<any>) => {
    if (event.key === 'Enter') {
      router.push(SEARCH);
    }
  };
  return (
    <InputGroup className="header-search mr-3">
      <InputGroup.Text id="basic-addon1">{searchIcon}</InputGroup.Text>
      <Form.Control
        placeholder="SEARCH"
        aria-label="SEARCH"
        aria-describedby="basic-addon1"
        onKeyPress={(event) => {
          handleEnterKeyPress(event);
        }}
      />
    </InputGroup>
  );
};

export default SearchBar;
