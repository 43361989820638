import { current } from '@reduxjs/toolkit';
import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  AcceptConnectionBody,
  RemoveConnectionParams,
  RemoveConnectionResponse,
  UpdateConnectionLabelParams,
} from 'commons/types/network.type';
import { RootState } from 'commons/types/redux';
import {
  API_CONNECTIONS,
  API_CONNECTIONS_ACCEPT_CONNECTION,
  API_CONNECTIONS_UPDATE_LABEL,
} from 'constants/api';
import { DefaultRandomDiscoverPeoplePagination } from 'constants/data';
import { HTTP_METHODS } from 'constants/http';
import { discoverApiSlice } from 'store/discover/discover.api';
import { ConnectionStatus } from 'store/user/types';
import { ConnectionUtils } from 'utils/connection';
import { RandomizedDiscoverUtils } from 'utils/randomized-discover';
import { connectionsApiSlice } from './connections.api';

export const updateConnectionApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      removeConnection: builder.mutation<
        RemoveConnectionResponse,
        RemoveConnectionParams
      >({
        query: (payload) => ({
          url: `${API_CONNECTIONS}`,
          method: HTTP_METHODS.DELETE,
          params: payload,
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled, getState }) {
          try {
            const response = await queryFulfilled;
            const state = getState() as RootState;
            const params = state?.connections?.getConnectionsByLabelParams;
            if (response?.data?.affected > 0) {
              dispatch(
                connectionsApiSlice.util.updateQueryData(
                  'getConnectionsByLabel',
                  params,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedConnections =
                        ConnectionUtils.updateRemovedConnection(
                          current(draft),
                          props.husslupId,
                        );
                      Object.assign(draft, updatedConnections);
                    }
                  },
                ),
              );
              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getRandomizedPeople',
                  DefaultRandomDiscoverPeoplePagination,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedConnections =
                        RandomizedDiscoverUtils.updatePeopleListConnectionStatus(
                          current(draft),
                          props.husslupId,
                          ConnectionStatus.NONE,
                        );
                      Object.assign(draft, updatedConnections);
                    }
                  },
                ),
              );
              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getRandomRecommendation',
                  {},
                  (draft) => {
                    if (current(draft)?.length) {
                      const updatedPeopleResponse =
                        RandomizedDiscoverUtils.updateRandomRecommendationListConnectionStatus(
                          current(draft),
                          props.husslupId,
                          ConnectionStatus.NONE,
                        );
                      Object.assign(draft, updatedPeopleResponse);
                    }
                  },
                ),
              );
            }
          } catch {
            /* empty */
          }
        },
        invalidatesTags: [
          'AcceptedReferral',
          'ConnectionRequests',
          'AllUserConnections',
          'UserConnectionStatus',
          'MyNetworkConnectionsCount',
          // 'UserIsConnected'
        ],
      }),
      acceptConnection: builder.mutation<
        RemoveConnectionResponse,
        AcceptConnectionBody
      >({
        query: (payload) => ({
          url: `${API_CONNECTIONS}${API_CONNECTIONS_ACCEPT_CONNECTION}`,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            const response = await queryFulfilled;
            if (response?.data?.affected > 0) {
              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getRandomizedPeople',
                  DefaultRandomDiscoverPeoplePagination,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedConnections =
                        RandomizedDiscoverUtils.updatePeopleListConnectionStatus(
                          current(draft),
                          props.connectionHusslupId,
                          ConnectionStatus.ACCEPTED,
                        );
                      Object.assign(draft, updatedConnections);
                    }
                  },
                ),
              );
            }
          } catch {
            /* empty */
          }
        },
        invalidatesTags: [
          'AcceptedReferral',
          'ConnectionRequests',
          'AllUserConnections',
          'UserConnectionStatus',
          'MyNetworkConnectionsCount',
          // 'UserIsConnected'
        ],
      }),
      updateConnectionLabel: builder.mutation<
        RemoveConnectionResponse,
        UpdateConnectionLabelParams
      >({
        query: (payload) => ({
          url: `${API_CONNECTIONS}${API_CONNECTIONS_UPDATE_LABEL}`,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
        invalidatesTags: ['MyNetworkConnectionsCount'],
      }),
    };
  },
});

export const {
  useRemoveConnectionMutation,
  useUpdateConnectionLabelMutation,
  useAcceptConnectionMutation,
} = updateConnectionApiSlice;
