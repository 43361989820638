import React, { FC, ReactElement } from 'react';
import Modal from 'react-bootstrap/Modal';

import Button, { ButtonVariants } from 'components/Core/Button';

export interface DialogBoxProps {
  show: boolean;
  title: string;
  message: string;
  messageNode?: ReactElement;
  onClose: () => void;
  onAccept: (values?: any) => void | Promise<void>;
  acceptText?: string;
  closeText?: string;
  disabled?: boolean;
  loading?: boolean;
  wrapperClassName?: string;
  removeCloseButton?: boolean;
  acceptNode?: ReactElement;
  gtClassName?: string;

  // disablePrompt?: () => void;
}

/**
 * General Dialog[Prompt] box used in application.
 *
 */
const DialogBox: FC<DialogBoxProps> = ({
  // disablePrompt,
  message,
  onAccept,
  onClose,
  show,
  title,
  acceptText = 'Login',
  closeText = 'Cancel',
  disabled,
  loading,
  wrapperClassName = '',
  removeCloseButton = false,
  acceptNode,
  messageNode,
  gtClassName = '',
}) => (
  <Modal show={show} onHide={onClose} className={wrapperClassName}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body className='pt-12px'>
      {message || messageNode || null}
    </Modal.Body>
    <Modal.Footer>
      {!removeCloseButton && (
        <Button
          label={closeText}
          variant={ButtonVariants.secondary}
          onClick={onClose}
        />
      )}
      {acceptNode && acceptNode}
      {!acceptNode && (
        <Button
          label={acceptText}
          variant={ButtonVariants.primary}
          onClick={onAccept}
          className={gtClassName || ''}
          disabled={disabled}
          loading={loading}
        />
      )}
    </Modal.Footer>
  </Modal>
);

export default DialogBox;
