const getFullName = (firstName: string, lastName: string) => {
  if (firstName || lastName) {
    return `${firstName?.trim() ?? ''} ${lastName?.trim() ?? ''}`?.trim() ?? '';
  }
  return '';
};

const UserUtils = {
  getFullName,
};

export default UserUtils;
