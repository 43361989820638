/* eslint-disable react/no-unstable-nested-components */
import React, { FunctionComponent, ReactElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export type TypographyVariant =
  // Headings
  | 'h1'
  | 'h2'
  | 'h3'
  // Paragraph
  | 'p-small-d' // Small/Default
  | 'p-sm-d' // SM/Default
  | 'p-sm' // SM/Default
  | 'p-sm-m' // SM/Medium
  | 'p-sm-md' // SM/Medium
  | 'p-xs-d' // ss
  | 'p-sm-sb' // SM/Semibold
  | 'p-sm-b' // SM/Bold
  | 'p-sm-i' // SM/Italic
  | 'p-sm-u' // SM/Underline
  | 'p-sm-c' // SM/Caps
  | 'p-md-d' // MD/Default
  | 'p-md' // MD/Default
  | 'p-md-m' // MD/Medium
  | 'p-md-sb' // MD/Semibold
  | 'p-md-b' // MD/bold
  | 'p-lg-d' // LG/Default
  | 'p-lg' // LG/Default
  | 'p-lg-m' // LG/Medium
  | 'p-lg-md'
  | 'p-lg-sb' // LG/Semibold
  | 'p-lg-b' // LG/bold
  | 'p-xlg-d' // LG/Default
  | 'p-xlg' // LG/Default
  | 'p-xlg-sb' // LG/Semibold
  | 'p-xlg-b' // LG/bold
  // Small
  | 's-d' // Small/Default
  | 's-sb' // Small/Semibold
  | 's-cb' // Small/CapsBold
  | 's-ac' // Small/CapsBold
  | 's-csb' // Small/Caps Semi Bold
  | 's-xs-csb' // Small/Caps Semi Bold
  // Button Text
  | 'p-bt-d' // Default
  | 'p-bt-sb' // Semibold
  | 'p-bt-du' // Default Underline
  // Link
  | 'p-l-d'; // Link Default

interface TypographyProps {
  variant: TypographyVariant;
  children: string | ReactElement | any;
  className?: string;
  style?: any;
  onClick?: () => void;
  lineClampNo?: number;
  title?: string;
  trimNumber?: number;
}

const Typography: FunctionComponent<TypographyProps> = ({
  variant,
  children,
  className = '',
  style = {},
  onClick = () => {},
  lineClampNo,
  title = '',
  trimNumber = 0,
}): ReactElement => {
  const getVariant = (): string => {
    const array: string[] = variant?.split('-');
    if (array.length < 3) {
      return `${array[0]} ${variant}`;
    }
    return `${array[0]}-${array[1]} ${variant}`;
  };
  const getElement = () => {
    const pVariant = [
      'p-small-d',
      'p-sm-d',
      'p-sm',
      'p-sm-m',
      'p-sm-sb',
      'p-sm-b',
      'p-sm-i',
      'p-sm-u',
      'p-sm-c',
      'p-md-d',
      'p-md',
      'p-md-m',
      'p-md-sb',
      'p-md-b',
      'p-lg-d',
      'p-lg',
      'p-lg-m',
      'p-lg-sb',
      'p-lg-b',
      'p-xlg-d',
      'p-xlg',
      'p-xlg-sb',
      'p-xlg-b',
    ];
    if (variant === 'h1') {
      return 'h1';
    }
    if (variant === 'h2') {
      return 'h2';
    }
    if (variant === 'h3') {
      return 'h3';
    }
    if (pVariant.includes(variant)) {
      return 'p';
    }
    return 'span';
  };
  const Element = ({ ...props }: React.HTMLAttributes<HTMLHeadingElement>) =>
    React.createElement(getElement(), props);

  return (
    <OverlayTrigger
      placement='bottom-start'
      delay={{ show: 250, hide: 400 }}
      overlay={
        title && Array.from(title)?.length > trimNumber ? (
          <Tooltip>{title}</Tooltip>
        ) : (
          <></>
        )
      }
    >
      <abbr>
        <Element
          className={`${getVariant()} ${className} line-clamp-${lineClampNo}`}
          style={style}
          onClick={onClick}
        >
          {children}
        </Element>
      </abbr>
    </OverlayTrigger>
  );
};

export default Typography;
