import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  GetFollowedCompaniesParams,
  GetFollowedCompaniesResponse,
} from 'commons/types/company.type';
import { API_COMPANY_WEB, API_COMPANY_WEB_FOLLOWED } from 'constants/api';
import { HTTP_METHODS } from 'constants/http';

export const followedCompaniesApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getFollowedCompanies: builder.query<
        GetFollowedCompaniesResponse,
        GetFollowedCompaniesParams
      >({
        query: (payload) => ({
          url: `${API_COMPANY_WEB}${API_COMPANY_WEB_FOLLOWED}`,
          method: HTTP_METHODS.GET,
          params: payload,
        }),
        // Only have one cache entry because the arg always maps to one string
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs?.arg?.nextPage) {
            currentCache.items.push(...newItems.items);
            currentCache.meta = newItems.meta;
          } else {
            currentCache.items = newItems.items;
            currentCache.meta = newItems.meta;
          }
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return (
            currentArg?.page !== previousArg?.page ||
            currentArg?.searchText !== previousArg?.searchText ||
            currentArg?.sortBy !== previousArg?.sortBy
          );
        },
        providesTags: ['FollowedCompanies'],
      }),
    };
  },
});

export const { useGetFollowedCompaniesQuery } = followedCompaniesApiSlice;
