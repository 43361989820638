import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  UserLinkedInInterface,
  ImportUserLinekdInPayload,
} from 'commons/types/data';
import {
  API_USER_LINKEDIN,
  API_USER_LINKEDIN_GET_BY_USER,
  API_USER_LINKEDIN_FETCH,
  API_USER_VERIFY_LINKEDIN,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';

export const userLinkedInApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getUserLinkedInInfo: builder.query<UserLinkedInInterface, void>({
        query() {
          return {
            url: `${API_USER_LINKEDIN}${API_USER_LINKEDIN_GET_BY_USER}`,
            method: HTTP_METHODS.GET,
          };
        },
        providesTags: ['UserLinkedIn'],
      }),
      fetchLinkedInProfile: builder.mutation<
        UserLinkedInInterface,
        ImportUserLinekdInPayload
      >({
        query(payload) {
          return {
            url: `${API_USER_LINKEDIN}${API_USER_LINKEDIN_FETCH}`,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        transformResponse: (response: any) => {
          if (!response) {
            throw new Error('Unable to parse!');
          }
          return response;
        },
        // invalidatesTags: ['UserLinkedIn'],
      }),
      verifyLinkedin: builder.mutation<boolean, {}>({
        query() {
          return {
            url: `${API_USER_LINKEDIN}${API_USER_VERIFY_LINKEDIN}`,
            method: HTTP_METHODS.PATCH,
          };
        },
        invalidatesTags: ['CurrentAuthUser', 'UserProfileList'],
      }),
    };
  },
});

export const {
  useFetchLinkedInProfileMutation,
  useGetUserLinkedInInfoQuery,
  useLazyGetUserLinkedInInfoQuery,
  useVerifyLinkedinMutation,
} = userLinkedInApiSlice;
