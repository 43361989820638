import { GetStreamContext } from 'commons/context/get-stream-context';
import { useContext } from 'react';

const useGetStreamActivity = () => {
  const context = useContext(GetStreamContext);

  return {
    activityFeedClient: context?.activityFeedClient,
  };
};

export default useGetStreamActivity;
