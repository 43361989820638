import { AllConnectionsResponse, GetConnectionStatusResponse } from 'commons/types/connection.type';
import {
  API_CONNECTIONS_IS_CONNECTED,
  API_CONNECTIONS_STATUS_WEB,
} from 'constants/api';
import { baseApiSlice } from '../../commons/apis/base-api.config';

export const connectionsApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      isConnected: builder.query<any, { husslupId: string }>({
        query: ({ husslupId }) => ({
          url: `${API_CONNECTIONS_IS_CONNECTED}`,
          params: { husslupId },
        }),
      }),
      getConnectionStatus: builder.query<GetConnectionStatusResponse, { husslupId: string }>({
        query: ({ husslupId }) => ({
          url: `${API_CONNECTIONS_STATUS_WEB}`,
          params: { husslupId },
        }),
        providesTags: ['UserConnectionStatus']
      }),
      getGroupInvite: builder.query<any, string | number>({
        query: (inviteId) => `groups/invite/${inviteId}`,
      }),
      getAllConnectionIds: builder.query<AllConnectionsResponse[], void>({
        query: () => `connections/web/all-connections`,
        keepUnusedDataFor: 3600,
        providesTags: ['AllUserConnections']
      }),
    };
  },
});

export const {
  useLazyIsConnectedQuery,
  useLazyGetConnectionStatusQuery,
  useGetConnectionStatusQuery,
  useGetGroupInviteQuery,
  useLazyGetGroupInviteQuery,
  useGetAllConnectionIdsQuery
} = connectionsApiSlice;
