import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useBlockByHusslupIdMutation,
  useBlockUserMutation,
} from 'store/block-users';
import { selectGetPostWithTagParams } from 'store/post/selectors';
import ToasterUtils from 'utils/toaster';
import useVisibility from './useVisibility';

const useBlockUser = (getUserId: () => number) => {
  const [blockUser, { isLoading: isBlockingUser }] = useBlockUserMutation();
  const getPostWithTagParams = useSelector(selectGetPostWithTagParams);
  const { t } = useTranslation('my_network');

  const {
    hide: hideBlock,
    show: showBlock,
    isVisible: isBlockVisible,
  } = useVisibility();

  const handleBlock = async () => {
    const userId = getUserId();
    if (userId) {
      try {
        const response = (await blockUser({
          body: { userId },
          getPostWithTagParams,
        }).unwrap()) as any;
        if (response) {
          const users = response.data.map(
            (usr: any) => usr.blocked_user,
          ) as any;
          ToasterUtils.successToast(
            t('USER_BLOCK_SUCCESS.value', {
              memberId: users?.find((usr: any) => usr.id === userId).fullname,
            }),
            {
              hideCloseButton: true,
              icon: 'check',
              position: 'top-center',
            },
          );
        } else {
          ToasterUtils.errorToast(t('USER_BLOCK_ERROR.value'), {
            hideCloseButton: true,
            icon: 'close',
            position: 'top-center',
          });
        }
      } catch (err) {
        ToasterUtils.errorToast(t('USER_BLOCK_ERROR.value'), {
          hideCloseButton: true,
          icon: 'close',
          position: 'top-center',
        });
      } finally {
        hideBlock();
      }
    }
  };

  return {
    handleBlock,
    showBlock,
    blockDialogPropGetter: {
      disabled: isBlockingUser,
      loading: isBlockingUser,
      show: isBlockVisible,
      onAccept: handleBlock,
      onClose: hideBlock,
      message: 'Are you sure you want to block this user?',
      title: 'Block User',
      acceptText: 'Yes',
    },
  };
};

export const useBlockUserWithHusslupId = () => {
  const [blockUser, { isLoading: isBlockingUser }] =
    useBlockByHusslupIdMutation();
  const [userIdToBlock, setUserIdToBlock] = useState('');
  const { t } = useTranslation('my_network');

  const {
    hide: hideBlock,
    show: showBlock,
    isVisible: isBlockVisible,
  } = useVisibility();

  const handleShow = (husslupId: string) => {
    setUserIdToBlock(husslupId);
    showBlock();
  };

  const handleBlock = async () => {
    if (userIdToBlock) {
      try {
        const response = await blockUser({
          husslupIdToBlock: userIdToBlock,
        }).unwrap();
        if (response) {
          const blockedUserDetails = response?.data.find(
            (usr: any) => usr.blocked_user.husslupId === userIdToBlock,
          );
          ToasterUtils.successToast(
            t('USER_BLOCK_SUCCESS.value', {
              memberId: blockedUserDetails?.blocked_user?.fullname || '',
            }),
            {
              hideCloseButton: true,
              icon: 'check',
              position: 'top-center',
            },
          );
        } else {
          ToasterUtils.errorToast(t('USER_BLOCK_ERROR.value'), {
            hideCloseButton: true,
            icon: 'close',
            position: 'top-center',
          });
        }
      } catch (err) {
        ToasterUtils.errorToast(t('USER_BLOCK_ERROR.value'), {
          hideCloseButton: true,
          icon: 'close',
          position: 'top-center',
        });
      } finally {
        hideBlock();
      }
    }
  };

  return {
    handleBlock,
    showBlock: handleShow,
    blockDialogPropGetter: {
      disabled: isBlockingUser,
      loading: isBlockingUser,
      show: isBlockVisible,
      onAccept: handleBlock,
      onClose: hideBlock,
      message: 'Are you sure you want to block this user?',
      title: 'Block User',
      acceptText: 'Yes',
    },
  };
};

export default useBlockUser;
