import React, { FunctionComponent, ReactElement } from 'react';
import { ChickletSize, MemberChickletType } from '.';

interface IProps {
  labelText: MemberChickletType;
  size?: ChickletSize;
  wrapperClass?: string;
}

const MemberChicklet: FunctionComponent<IProps> = ({
  size,
  labelText,
  wrapperClass,
}): ReactElement => {
  const getBadgeVariantClass = (): string => {
    if (labelText?.toLowerCase() === 'pro') return 'pro';
    if (labelText?.toLowerCase() === 'rising') return 'rising';
    if (labelText?.toLowerCase() === 'added') return 'added';
    return 'mine';
  };
  return (
    <span
      className={`badge badge-${getBadgeVariantClass()} badge-${size || ''} ${
        wrapperClass || ''
      }`}
    >
      {labelText}
    </span>
  );
};

export default MemberChicklet;
