import dynamic from 'next/dynamic';

import { PageLayout } from 'pages/_app';

import Navbar from '../Navbar/Navbar';
import Noop from './Noop';
import Layout from './Layout';

const NoSSRLayout = dynamic(() => import('./Layout'), { ssr: false }) as PageLayout;

export { Navbar, Noop, Layout };

export default NoSSRLayout;
