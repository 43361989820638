import React, {
  FunctionComponent,
  ReactElement,
  useContext,
  useState,
} from 'react';

export interface INavigationTabState {
  [key: string]: boolean;
}

export interface INavigation {
  navState: ITabUrlUtils;
  tabState: INavigationTabState;
  updateNavigation: (url: ITabUrlUtils) => void;
  updateFormState: (formState: INavigationTabState) => void;
  updateResetDropDown: () => void;
  resetDropDown: boolean;
}

export interface ITabUrlUtils {
  from: string;
  to: string;
}

export const NavigationContext = React.createContext<INavigation | null>(null);

const NavigationContextProvider: FunctionComponent<{
  children: ReactElement;
}> = ({ children }) => {
  const [navigation, setNavigation] = useState<ITabUrlUtils>({
    from: '',
    to: '',
  });
  const [formState, setFormState] = useState<INavigationTabState>({});
  const [resetDropDown, setResetDropDown] = useState<boolean>(false);
  const updateNavigation = (url: { from: string; to: string }): void => {
    setNavigation(url);
  };
  const updateFormState = (currentFormState: INavigationTabState): void => {
    setFormState({ ...formState, ...currentFormState });
  };
  const updateResetDropDown = () => {
    setResetDropDown(!resetDropDown);
  };
  return (
    <NavigationContext.Provider
      value={{
        navState: navigation,
        updateNavigation,
        tabState: formState,
        updateFormState,
        updateResetDropDown,
        resetDropDown,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
export default NavigationContextProvider;
