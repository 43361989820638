import { useMemo } from 'react';

import { SubscriptionStatus } from 'store/subscription/subscription.types';
import { useGetUserSubscriptionQuery } from 'store/subscription/subscription-payment.api';
import { getDateFromStripeDateNumber } from 'store/subscription/subscription.utils';
import moment from 'moment';

export const useCurrentSubscription = () => {
  const { data, isLoading } = useGetUserSubscriptionQuery();

  const {
    isCancelled,
    cancelFrom,
    cancelledOn,
    isFullyCanceled,
    isAwaitingPayment,
    isStudioAwaitingPayment,
    isStudioPlan,
    isExpired,
    planAmount,
    trailDaysRemaining,
    cardMonth,
    cardYear,
    isFailedPayment,
  } = useMemo(() => {
    const isCancelled =
      data?.subscriptionStatus &&
      [SubscriptionStatus.PRE_CANCELLED, SubscriptionStatus.CANCELLED].includes(
        data?.subscriptionStatus as SubscriptionStatus,
      );

    const cancelledOn =
      isCancelled && data.canceled_at
        ? getDateFromStripeDateNumber(data.canceled_at)
        : null;
    const cancelFrom =
      isCancelled && data.cancel_at
        ? getDateFromStripeDateNumber(data.cancel_at)
        : null;

    const isStudioAwaitingPayment =
      data?.subscriptionStatus === SubscriptionStatus.AWAITING_PAYMENT_METHOD;

    const isAwaitingPayment =
      data?.subscriptionStatus === SubscriptionStatus.AWAITING_PAYMENT_METHOD;

    const isStudioPlan = data?.subscriptionPaymentPlan?.paymentPlan
      ?.toLowerCase()
      ?.includes('studio');

    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const cardMonth = data?.paymentMethod?.expiryDate?.month;
    const cardYear = data?.paymentMethod?.expiryDate?.year;
    const trialDays =
      data?.trialEnd && data?.trialEnd > 0
        ? moment(new Date((data?.trialEnd || 0) * 1000)).diff(
            new Date(),
            'day',
            true,
          )
        : 0;

    const planAmount = data?.plan?.amount
      ? data?.plan?.amount / 100
      : data?.subscriptionPaymentPlan?.amount || 0.0;

    if (
      cardMonth &&
      cardYear &&
      (year < cardYear || (year <= cardYear && month <= cardMonth))
    ) {
      const isFailedPayment =
        data?.subscriptionStatus &&
        SubscriptionStatus.FAILED_CHARGED ===
          (data.subscriptionStatus as SubscriptionStatus);

      return {
        isExpired: false,
        planAmount: planAmount?.toFixed(2) || (0.0).toFixed(2),
        trailDaysRemaining: Math.ceil(trialDays),
        cardYear: cardYear.toString().slice(2),
        cardMonth: cardMonth.toString().padStart(2, '0'),
        isCancelled,
        cancelFrom,
        cancelledOn,
        isStudioAwaitingPayment,
        isAwaitingPayment,
        isFailedPayment: isFailedPayment && data?.paymentMethod?.id,
        isStudioPlan,
        isFullyCanceled:
          data?.subscriptionStatus === SubscriptionStatus.CANCELLED,
      };
    }
    return {
      isExpired: true,
      isFailedPayment:
        data?.subscriptionStatus &&
        SubscriptionStatus.FAILED_CHARGED ===
          (data.subscriptionStatus as SubscriptionStatus),
      trailDaysRemaining: 0,
      cardYear: '00',
      cardMonth: '00',
      isCancelled,
      cancelFrom,
      cancelledOn,
      planAmount: planAmount?.toFixed(2) || (0.0).toFixed(2),
      isStudioAwaitingPayment,
      isAwaitingPayment,
      isStudioPlan,
      isFullyCanceled:
        data?.subscriptionStatus === SubscriptionStatus.CANCELLED,
    };
  }, [data]);

  return {
    isCancelled,
    cancelFrom,
    isFailedPayment,
    cancelledOn,
    isLoading,
    isStudioPlan,
    userSubscription: data,
    planAmount,
    isFullyCanceled,
    isAwaitingPayment,
    isStudioAwaitingPayment,
    isExpired,
    trailDaysRemaining,
    cardMonth,
    cardYear,
  };
};

export default useCurrentSubscription;
