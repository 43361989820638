import { useAppDispatch, useAppSelector } from 'commons/hooks/redux';
import useNavigateMessage from 'commons/hooks/useNavigateMessage';
import useVisibility from 'commons/hooks/useVisibility';
import {
  ActionButtons,
  companyDropDown,
  IAttachmentCredit,
  memberCardDropDowns,
  MemberDropdownLabelType,
} from 'components/Cards';
import CompanyMemberCard from 'components/Cards/CompanyCard';
import MemberCard from 'components/Cards/MemberCard';
import AddToModel from 'components/Core/AddToModel/addToModel';
import ReportDialog from 'components/Core/Report';
import Typography from 'components/Core/StylesGuide/Typography';
import DialogBox from 'components/Dialog';
import { CreditPlaceHolder } from 'components/Placeholders';
import SerendipitySlider from 'components/Serendipity';
import AddToStaffingRoasterModal from 'components/StaffingRoster/Modals/AddToStaffingRoasterModal';
import {
  addToModalState,
  titleAndLabelOfAddToModel,
  userInfo,
} from 'constants/basic-search.state';
import {
  USER_ACCEPTED_A_CONNECTION,
  USER_IGNORED_A_CONNECTION,
  USER_REQUESTED_A_CONNECTION,
} from 'constants/gt-classes';
import {
  DISCOVER_PEOPLE,
  MESSAGES,
  PROFILE,
  HELP_INFO,
  DISCOVER_COMPANIES,
  COMPANY,
} from 'constants/routes';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FunctionComponent, memo, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectCurrentUser } from 'store/auth/selectors';
import {
  ICompaniesProfileItems,
  IUserProfile,
  useFollowCompanyMutation,
  useSendConnectionMutation,
  useUnFollowCompanyMutation,
} from 'store/discover/discover.api';
import { selectSerendipitySlides } from 'store/discover/serendipity.selectors';
import { onSerendipitySlideChanges } from 'store/discover/serendipity.slice';
import {
  useAcceptConnectionMutation,
  useRemoveConnectionMutation,
} from 'store/my-network/update-connection.api';
import { useDeleteConnectinRequestMutation } from 'store/user/connection.api';
import { ConnectionStatus } from 'store/user/types';
import { MemberCardModalType } from 'utils/basic-faceted-modal.enum';
import { ConnectionUtils } from 'utils/connection';
import ProfileUtils from 'utils/profile';
import handleCopyProfileLink from 'utils/profile-toast';
import ToasterUtils from 'utils/toaster';
import { MemberChickletType, MemberChickletTypeDefault } from '../../Chicklet';
import * as gtag from '../../../utils/gtag';

const DiscoverContainer: FunctionComponent<{
  discoveredPeople: IUserProfile[];
  discoveredCompanies: ICompaniesProfileItems[];
  companiesIsLoading: boolean;
  peopleIsLoading: boolean;
}> = ({
  discoveredPeople,
  discoveredCompanies,
  companiesIsLoading = false,
  peopleIsLoading = false,
}): ReactElement => {
  const router = useRouter();
  const { t } = useTranslation('my_network');
  const currentUser = useAppSelector(selectCurrentUser);
  const slides = useAppSelector(selectSerendipitySlides);
  const dispatch = useAppDispatch();
  const [followCompany, followCompanyResponse] = useFollowCompanyMutation();
  const [unFollowCompany, unfollowCompanyResponse] =
    useUnFollowCompanyMutation();

  const [sendConnection, connectResponse] = useSendConnectionMutation();
  const [deleteConnectinRequest, deleteResponse] =
    useDeleteConnectinRequestMutation();
  const { sendMessage } = useNavigateMessage();

  const { hide, show, isVisible } = useVisibility();
  const {
    hide: addToRoasterModalHide,
    show: addToRoasterModalShow,
    isVisible: addToRoasterModalIsVisible,
  } = useVisibility();
  const [selectedMemberId, setSelectedMemberId] = useState<number | null>(null);
  const [dialogBoxMessage, setDialogBoxMessage] = useState('');
  const [dialogBoxTitle, setDialogBoxTitle] = useState('');
  const [dialogBoxLabel, setDialogBoxLabel] = useState({ id: '', label: '' });
  const [addToModalShow, setAddToModalSHow] = useState(addToModalState);
  const [addToModelInfo, setTitleAndLabelOfAddToModel] = useState(
    titleAndLabelOfAddToModel,
  );
  const [memberCardCurrentUserInfo, setMemberCardCurrentUserInfo] =
    useState(userInfo);

  const [
    acceptConnection,
    { originalArgs: acceptConnectionArgs, isLoading: isAcceptingConnection },
  ] = useAcceptConnectionMutation();

  const [
    removeConnection,
    { originalArgs: removeConnectionArgs, isLoading: isRemovingConnection },
  ] = useRemoveConnectionMutation();

  const onActionClicked = (id: string, label: string) => async () => {
    switch (label) {
      case 'Pending':
        setDialogBoxMessage(
          'Are you sure you want to cancel this connection request ?',
        );
        setDialogBoxTitle('Cancel connection request');
        setDialogBoxLabel({ id, label });
        show();

        break;
      case 'Connected':
        setDialogBoxMessage(
          'Are you sure you want to cancel this connection request ?',
        );
        setDialogBoxTitle('Cancel connection request');
        setDialogBoxLabel({ id, label });
        show();
        break;
      default:
        await sendConnection({
          connectionHusslupId: id.toString(),
        });
      // fetchRandomizedDiscover();
    }
  };

  const onCardClickHandler = (profileId: number): void => {
    router.push(`${PROFILE}/${profileId}`);
  };

  const handleReportModalClose = (): void => {
    setAddToModalSHow({
      ...addToModalShow,
      showReportModal: false,
      showAddToModel: false,
    });

    setMemberCardCurrentUserInfo({
      ...memberCardCurrentUserInfo,
      profileId: '',
    });
  };

  const dialogBoxHandler = async () => {
    if (dialogBoxLabel.label === 'Pending') {
      await deleteConnectinRequest(dialogBoxLabel.id);
      // fetchRandomizedDiscover();

      hide();
    }
  };

  const handleDropdownClick = async (
    label: string,
    id: number,
    husslupId?: string,
  ): Promise<void> => {
    setMemberCardCurrentUserInfo({
      ...memberCardCurrentUserInfo,
      userId: id,
      profileId: husslupId ?? '',
    });
    const dropdownLabel = label as MemberDropdownLabelType;

    switch (dropdownLabel) {
      case 'Report':
        setAddToModalSHow({ ...addToModalShow, showReportModal: true });
        break;
      case 'Add to Contact List':
        setTitleAndLabelOfAddToModel(MemberCardModalType.ADD_TO_CONTACT_TITLE);
        setAddToModalSHow({ ...addToModalShow, showAddToModel: true });
        break;
      case 'Add to ProSearch':
        setTitleAndLabelOfAddToModel(
          MemberCardModalType.ADD_TO_PROSEARCH_TITLE,
        );
        setAddToModalSHow({ ...addToModalShow, showAddToModel: true });
        break;
      case 'Add to Staffing Roster':
        setSelectedMemberId(id);
        addToRoasterModalShow();
        break;
      // eslint-disable-next-line
      case 'Copy Profile Link':
        handleCopyProfileLink(`${PROFILE}/${id}`);
        break;
      case 'Share':
        router.push(MESSAGES);
        break;
      case 'Message':
        router.push(MESSAGES);
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line arrow-body-style
  const memberCardDropDownsWithActions = memberCardDropDowns?.map((card) => {
    return {
      ...card,
      onClick: handleDropdownClick,
    };
  });

  // eslint-disable-next-line arrow-body-style
  const companiesCardDropDownsWithActions = companyDropDown?.map((card) => {
    return {
      ...card,
      onClick: handleDropdownClick,
    };
  });

  // const changeRouteHandler = () => {
  //   router.push('/Messages');
  // };

  

  const getActionButtons = (actionInfo: {
    connectionStatus: string;
    husslupId?: string;
    fullName?: string;
    id?: string;
    image?: string;
    profession?: string;
  }): ActionButtons[] | undefined => {
    const { connectionStatus, fullName, husslupId, id, image, profession } =
      actionInfo;
    if (connectionStatus === String(ConnectionStatus.REQUESTED)) {
      const isCurrentConnectionGettingAccepted =
        acceptConnectionArgs?.connectionHusslupId === husslupId &&
        isAcceptingConnection;

      const isCurrentConnectionGettingRemoved =
        removeConnectionArgs?.husslupId === husslupId && isRemovingConnection;

      const needToDisable =
        isCurrentConnectionGettingAccepted || isCurrentConnectionGettingRemoved;
      return [
        {
          onClick: handleRemoveConnection(husslupId),
          icon: 'close',
          disabled: needToDisable,
          loading: isCurrentConnectionGettingRemoved,
          wrapperClass: USER_IGNORED_A_CONNECTION,
        },
        {
          onClick: handleAcceptConnection(husslupId, fullName),
          icon: 'check',
          disabled: needToDisable,
          loading: isCurrentConnectionGettingAccepted,
          wrapperClass: USER_ACCEPTED_A_CONNECTION,
        },
      ];
    }

    if (connectionStatus === String(ConnectionStatus.PENDING)) {
      return [
        {
          onClick: onActionClicked(husslupId || '', 'Pending'),
          icon: 'connect',
          label: 'Pending',
          disabled: deleteResponse.isLoading,
          wrapperClass: USER_REQUESTED_A_CONNECTION,
        },
      ];
    }

    if (connectionStatus === String(ConnectionStatus.ACCEPTED)) {
      return [
        {
          onClick: () => sendMessage({ fullName, id, image, profession }),
          icon: 'sended',
          label: 'Message',
        },
      ];
    }

    return [
      {
        onClick: onActionClicked(husslupId || '', 'Connect'),
        icon: 'connect',
        loading:
          connectResponse.originalArgs?.connectionHusslupId === husslupId &&
          connectResponse.isLoading,
        wrapperClass:
          ConnectionUtils.getMemberCardWrapperClass(connectionStatus),
        label: 'Connect',
        disabled: connectResponse.isLoading,
      },
    ];
  };

  const getCompanyActionButtons = (companyProfile: ICompaniesProfileItems) => {
    const currentCompanyIdBeingUnfollowed =
      unfollowCompanyResponse.originalArgs?.companyId;
    const currentCompanyIdBeingFollowed =
      followCompanyResponse.originalArgs?.companyId;

    const loading =
      (unfollowCompanyResponse.isLoading || followCompanyResponse.isLoading) &&
      (companyProfile.id === currentCompanyIdBeingFollowed ||
        companyProfile.id === currentCompanyIdBeingUnfollowed);

    return [
      {
        icon: companyProfile.isFollowing ? 'check' : 'plus',
        label: companyProfile.isFollowing ? 'Following' : 'Follow',
        onClick: () => {
          if (companyProfile.isFollowing) {
            unFollowCompany({
              companyId: companyProfile.id,
              userId: currentUser.id,
            });
          } else {
            followCompany({ companyId: companyProfile.id });
          }
        },
        loading,
        disabled: loading,
      },
    ] as ActionButtons[];
  };

  const handleRemoveConnection = (husslupId?: string) => async () => {
    if (husslupId) {
      const response = await removeConnection({
        husslupId,
      }).unwrap();
      if (response.affected > 0) {
        ToasterUtils.successToast(t('CONNECTION_REMOVED_SUCCESS.value'), {
          hideCloseButton: true,
        });
        gtag.event({
          label: 'user rejected connection button click',
          category: 'Connection',
          action: 'CONNECTION_REMOVED_SUCCESS',
        });
      } else {
        ToasterUtils.errorToast(t('CONNECT_REQUEST_DECLINED_ERROR.value'));
      }
    }
  };

  const handleAcceptConnection =
    (husslupId?: string, fullName?: string) => async () => {
      if (husslupId) {
        const response = await acceptConnection({
          connectionHusslupId: husslupId,
        }).unwrap();
        if (response.affected > 0) {
          ToasterUtils.successToast(
            t('CONNECT_REQUEST_ACCEPTED_SUCCESS.value', {
              memberId: fullName,
            }),
            {
              hideCloseButton: true,
            },
          );
        } else {
          ToasterUtils.errorToast(t('CONNECT_REQUEST_ACCEPTED_ERROR.value'));
        }
      }
    };

  return (
    <div className='sider sider_left'>
      <AddToStaffingRoasterModal
        handleHide={() => {
          addToRoasterModalHide();
          setSelectedMemberId(null);
        }}
        show={addToRoasterModalIsVisible}
        memberId={selectedMemberId}
      />
      <ReportDialog
        husslupId={memberCardCurrentUserInfo.profileId}
        show={addToModalShow.showReportModal}
        onClose={handleReportModalClose}
        reportedCompanyId={memberCardCurrentUserInfo.userId}
      />
      <DialogBox
        show={isVisible}
        message={dialogBoxMessage}
        onAccept={dialogBoxHandler}
        onClose={hide}
        title={dialogBoxTitle}
        acceptText='Yes'
        wrapperClassName='modal-confirmation'
        loading={deleteResponse.isLoading}
      />
      <AddToModel
        show={addToModalShow.showAddToModel}
        title={addToModelInfo}
        id={memberCardCurrentUserInfo.userId}
        onClose={handleReportModalClose}
      />
      <div className='home-people-serendipity'>
        <Typography variant='h2'>Discover</Typography>
        <div
          className='sider__block'
          style={{
            minHeight: 537,
          }}
        >
          <div className='block__head'>
            <h3>People</h3>
            <Link href={DISCOVER_PEOPLE} className='link'>
              View All
            </Link>
          </div>
          {peopleIsLoading && (
            <div
              className='d-flex justify-content-center align-items-center mb-4'
              style={{ height: '492px' }}
            >
              <div className='spinner-border text-primary' role='status' />
            </div>
          )}
          {!peopleIsLoading && discoveredPeople?.length > 0 && (
            <SerendipitySlider
              wrapperClass='mini-slider'
              slideIndex={slides?.people}
              onSlide={(slideIndex: number) => {
                dispatch(
                  onSerendipitySlideChanges({
                    key: 'people',
                    slideIndex,
                  }),
                );
              }}
            >
              {discoveredPeople.map((connection: IUserProfile) => (
                <MemberCard
                  fillBackground
                  memberChickletStatus={
                    (connection.tier ??
                      MemberChickletTypeDefault) as MemberChickletType
                  }
                  onClick={() => onCardClickHandler(connection.id)}
                  key={connection.husslupId}
                  actionButtons={getActionButtons({
                    connectionStatus: connection.connectionStatus as string,
                    fullName: connection.fullname,
                    husslupId: connection.husslupId,
                    id: connection.husslupId,
                    image: connection.profile_img,
                    profession: connection.professional_tagline || '',
                  })}
                  attachment={
                    {
                      type: 'credit',
                      heading:
                        connection.topCredit.credit ||
                        connection.topCredit.projectTitle
                          ? 'Credits'
                          : '',
                      description: [
                        connection.topCredit.credit,
                        connection.topCredit.projectTitle,
                      ],
                      imgUrl:
                        (connection.topCredit.credit ||
                          connection.topCredit.projectTitle) &&
                        (connection.topCredit.creditImg ?? CreditPlaceHolder),
                    } as IAttachmentCredit
                  }
                  // @ts-ignore
                  dropdown={memberCardDropDownsWithActions}
                  fullName={connection.fullname}
                  headings={
                    [
                      connection.professional_tagline,
                      connection.project_tagline,
                      connection.genreTagline,
                    ] as string[]
                  }
                  icons={[
                    {
                      label: 'verified',
                      icon: 'verified-fill',
                      color: 'text-awards',
                    },
                  ]}
                  profileImgUrl={connection.profile_img}
                  id={connection.id}
                  husslupId={connection.husslupId}
                />
              ))}
              <MemberCard
                headings={['Discover More...']}
                wrapperClass='explore-more people'
                actionButtons={[
                  {
                    icon: 'arrow-right',
                    label: 'View All',
                    onClick: () => {
                      router.push(DISCOVER_PEOPLE);
                    },
                  },
                ]}
              />
            </SerendipitySlider>
          )}
        </div>
      </div>
      <div className='sider__block' style={{ minHeight: 466 }}>
        <div className='block__head'>
          <h3>Companies</h3>
          <Link href={DISCOVER_COMPANIES} className='link'>
            View All
          </Link>
        </div>
        {companiesIsLoading && (
          <div
            className='d-flex justify-content-center align-items-center mb-4'
            style={{ height: '421px' }}
          >
            <div className='spinner-border text-primary' role='status' />
          </div>
        )}
        {!companiesIsLoading && discoveredCompanies.length > 0 && (
          <SerendipitySlider
            wrapperClass='mini-slider small'
            slideIndex={slides?.companies}
            onSlide={(slideIndex: number) => {
              dispatch(
                onSerendipitySlideChanges({
                  key: 'companies',
                  slideIndex,
                }),
              );
            }}
          >
            {discoveredCompanies?.map(
              (companyProfile: ICompaniesProfileItems) => (
                <CompanyMemberCard
                  fillBackground
                  key={companyProfile.id}
                  actionButtons={getCompanyActionButtons(companyProfile)}
                  // attachment={}
                  // @ts-ignore
                  dropdown={companiesCardDropDownsWithActions}
                  companyName={companyProfile.company_name}
                  headings={
                    [
                      companyProfile.pro_headline,
                      companyProfile?.locations?.[0]?.city,
                      `${companyProfile.follower_count} Followers`,
                    ] as string[]
                  }
                  logo={ProfileUtils.getImageFileFromAwsUrl(
                    companyProfile.logo,
                  )}
                  id={companyProfile.id}
                  onClick={() => {
                    router.push(`${COMPANY}/${companyProfile.id}`);
                  }}
                />
              ),
            )}
            <MemberCard
              headings={['Discover More...']}
              wrapperClass='explore-more company'
              actionButtons={[
                {
                  icon: 'arrow-right',
                  label: 'View All',
                  onClick: () => {
                    router.push(DISCOVER_COMPANIES);
                  },
                },
              ]}
            />
          </SerendipitySlider>
        )}
      </div>
      <div className='footer p-0'>
        <div className='text-left'>
          <div className='link-xs'>
            <Link href={HELP_INFO}>Help and Info</Link>
          </div>
          <small>HUSSLUP © 2023</small>
        </div>
      </div>
    </div>
  );
};

export default memo(DiscoverContainer);
