import React, { ReactNode, useEffect } from 'react';
import debounce from 'debounce';
import {
  MentionsInput,
  Mention,
  OnChangeHandlerFunc,
  DataFunc,
  SuggestionDataItem,
  MentionsInputProps,
} from 'react-mentions';
import {
  useLazySearchConnectionsQuery,
  UserConnection,
} from 'store/user/users.api';
import NextImage from 'components/Core/Image';
import { getS3ProfileUrl } from 'utils/file';
import { CommentMentionedUserType } from 'commons/types/comment.type';

const mentionInputStyle = {
  control: {
    fontSize: 16,
  },
  '&multiLine': {
    control: {
      minHeight: 65,
    },
    highlighter: {
      padding: 9,
      border: 'none',
    },
    input: {
      padding: 9,
      border: 'none',
    },
  },
  '&singleLine': {
    display: 'inline-block',
    width: 180,
    highlighter: {
      padding: 1,
      border: 'none',
    },
    input: {
      padding: 1,
      border: 'none',
    },
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 16,
      borderRadius: 8,
      overflow: 'auto',
    },
    item: {
      padding: '10px 16px',
      borderBottom: '1px solid #e4e4e4',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
interface IProps extends Partial<MentionsInputProps> {
  initialValue?: string;
  required: boolean;
  placeholder?: string;
  error?: any;
  disabled?: boolean;
  name?: string;
  value?: string;
  onChange?: OnChangeHandlerFunc;
  onBlur?: () => void;
  onKeyDown?: () => void;
  onUsersMentioned: (id: string | number) => void;
  wrapperClass?: string;
  rows?: number;
  cols?: number;
}

const MAX_RESULTS_PER_SEARCH = 100;
const DEBOUNCE_SECONDS = 200;
const CreateCommentTextArea = React.forwardRef<HTMLTextAreaElement, IProps>(
  (
    {
      required,
      placeholder,
      disabled,
      value,
      onChange,
      onBlur,
      onKeyDown,
      onUsersMentioned,
      wrapperClass,
      rows,
      cols,
      ...props
    },
    inputRef,
  ) => {
    const [trigger] = useLazySearchConnectionsQuery();

    const handleSearchConnections: DataFunc = debounce(
      async (query: string, callback: (data: SuggestionDataItem[]) => void) => {
        const data = await trigger({
          searchText: query,
          limit: MAX_RESULTS_PER_SEARCH,
        }).unwrap();

        const transformedData = data.map((row: UserConnection) => ({
          id: row.husslupId,
          display: row.fullname,
          user_id: row.id,
          profileImg: getS3ProfileUrl(row.profileImg, 16, 16),
        })) as CommentMentionedUserType[];

        callback(transformedData);
      },
      DEBOUNCE_SECONDS,
    );

    const renderSuggestion = (
      suggestion: any,
      search: string,
      highlightedDisplay: ReactNode,
    ) => (
      <>
        <NextImage
          alt=''
          height={36}
          width={36}
          src={suggestion?.profileImg as string}
          className='mr-12px'
        />
        {highlightedDisplay}
      </>
    );

    const customSuggestionsContainer = (children: ReactNode) => (
      <div className='my-custom-suggestions'>
        <div className='suggestions-title'> Tag Users</div>
        {children}
      </div>
    );

    const handlePaste = (ev: Event) => {
      ev.preventDefault();
    };
  
    useEffect(() => {
      document.addEventListener('paste', handlePaste);
  
      return () => {
        document.removeEventListener('paste', handlePaste);
      };
    }, [inputRef]);

    return (
      <MentionsInput
        style={mentionInputStyle}
        className={`post-modal__textarea ${wrapperClass || ''}`}
        value={value}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={onKeyDown}
        allowSpaceInQuery
        onBlur={onBlur}
        customSuggestionsContainer={customSuggestionsContainer}
        inputRef={inputRef}
        rows={rows}
        cols={cols}
        {...props}
        autoFocus={false}
      >
        <Mention
          className='mention'
          trigger='@'
          data={handleSearchConnections}
          onAdd={(id) => onUsersMentioned(id)}
          renderSuggestion={renderSuggestion}
          // appendSpaceOnAdd
        />
      </MentionsInput>
    );
  },
);

export default CreateCommentTextArea;
