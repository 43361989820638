import { GetFollowedCompaniesResponse } from 'commons/types/company.type';
import { ICompanyProfileSggestionsResponse } from 'store/discover/discover.api';

export type CompanyLocation = {
  id: number;
  city: string;
  createdAt: Date;
  updatedAt: Date;
};

export const getCompanyLocation = (locations: CompanyLocation[]) => {
  if (locations?.length) {
    return locations?.map((location) => location.city).join(', ');
  }
  return '';
};

export const updateRemovedCompanyConnection = (
  followedCompaniesResponse: GetFollowedCompaniesResponse,
  unfollowedCompanyId: number,
) => {
  const updatedCompanies = followedCompaniesResponse.items?.filter(
    (company) => company.id !== unfollowedCompanyId,
  );

  return {
    ...followedCompaniesResponse,
    items: updatedCompanies,
  };
};

export const updateUnfollowCompanies = (
  companies: ICompanyProfileSggestionsResponse,
  unfollowedCompanyId: number,
): ICompanyProfileSggestionsResponse => {
  const updatedCompanies = companies.items.map((company) => {
    if (company.id === unfollowedCompanyId && company.isFollowing) {
      return {
        ...company,
        isFollowing: false,
        follower_count: Number(company.follower_count) - 1,
      };
    }
    return company;
  });

  return {
    ...companies,
    items: updatedCompanies,
  };
};

export const updateFollowCompanies = (
  companies: ICompanyProfileSggestionsResponse,
  unfollowedCompanyId: number,
): ICompanyProfileSggestionsResponse => {
  const updatedCompanies = companies.items.map((company) => {
    if (company.id === unfollowedCompanyId && !company.isFollowing) {
      return {
        ...company,
        isFollowing: true,
        follower_count: Number(company.follower_count) + 1,
      };
    }
    return company;
  });

  return {
    ...companies,
    items: updatedCompanies,
  };
};
