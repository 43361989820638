/* eslint-disable import/prefer-default-export */
import { EditProfileDetails, EditProfileFormDetails } from 'commons/types/data';
import { WorkStatus, WorkStatusOption } from 'commons/types/profile.type';
import { WorkStatusOptions } from 'constants/data';
import UserUtils from './user';

/**
 * Function to get workstatuses to save to support backward compatibility for old
 * work status column
 * @param workStatuses Current work statuses array
 * @param workStatus Old work status
 * @returns backward compatible work status
 */
const getWorkStatuses = (workStatuses: WorkStatus[], workStatus: string) => {
  if (
    !workStatuses?.length &&
    workStatus !== undefined &&
    workStatus !== null
  ) {
    if (String(workStatus) === '0') {
      return [WorkStatus.READY_TO_WORK];
    }
    return [];
  }
  return workStatuses;
};

const getEditProfileDetailSaveData = (
  values: EditProfileFormDetails,
  oldWorkStatus: string,
): EditProfileDetails => {
  if (values.workStatuses) {
    const workStatusesValues =
      values.workStatuses?.map((option) => option.value) || [];
    const workStatuses = getWorkStatuses(workStatusesValues, oldWorkStatus);
    /** @TODO Add later */
    // dataToSend.readyToHusslFromDate = workStatuses?.includes(
    //   WorkStatusEnum.READY_TO_WORK,
    // )
    //   ? statuses.readyDate
    //   : null;

    const dataToSave = {
      ...values,
      fullname: UserUtils.getFullName(values.firstName, values.lastName),
      workStatuses,
      workStatus: undefined,
    };
    return dataToSave;
  }
  const dataToSave = {
    ...values,
    fullname: UserUtils.getFullName(values.firstName, values.lastName),
    workStatuses: [] as WorkStatus[],
  };
  return dataToSave;
};

const workStatusToOption = (workStatuses: WorkStatus[]): WorkStatusOption[] =>
  workStatuses
    ?.map((workStatus) => {
      const option = WorkStatusOptions.find(
        (option) => option.value === workStatus,
      );
      return option!;
    })
    .filter(Boolean) ?? [];

export const EditProfileUtils = {
  getWorkStatuses,
  workStatusToOption,
  getEditProfileDetailSaveData,
};
