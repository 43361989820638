import React, { FunctionComponent, ReactElement } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ILeaveAlertProps {
  showModal: boolean;
  handleModalStay: () => void;
  handleModalLeave: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const LeavePageAlert: FunctionComponent<ILeaveAlertProps> = ({
  handleModalLeave,
  handleModalStay,
  showModal,
}): ReactElement => (
  <Modal show={showModal} centered>
    <Modal.Header>
      <Modal.Title>Unsaved changes</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      You have unsaved changes. Do you want to discard them and leave this page?
    </Modal.Body>
    <Modal.Footer>
      <Button variant='secondary' onClick={handleModalStay}>
        Stay on this page
      </Button>
      <Button variant='primary' onClick={handleModalLeave}>
        Leave this page
      </Button>
    </Modal.Footer>
  </Modal>
);

export default LeavePageAlert;
