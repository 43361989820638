import axios from 'axios';
import config from 'config';

import { RootState } from 'commons/types/redux';
import { baseApiSlice } from 'commons/apis/base-api.config';
import { CreativeCardProps } from 'components/Profile/CreativePortfolio/CreativeCard';
import { HTTP_METHODS } from 'constants/http';
import FormData from 'form-data';
import { transformGetCreativeSamples } from 'store/user/creative/transformResponse.api';
import { ReorderBody } from 'store/user/credit/types';
import { getMediaTypeFromFileName } from 'utils/file';
import {
  CreateUserCreativeSample,
  UpdateUserCreativeSample,
} from './creative-portfolio.types';
import { setUploadProgress } from './creative-portfolio.slice';

export const creativePortfolioApi = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getCreativeSamples: builder.query<CreativeCardProps[], {}>({
        query: () => 'user-creative-sample/by-user',
        transformResponse: transformGetCreativeSamples,
        providesTags: ['FetchCreativePortfolio'],
      }),

      getCreativeSampleById: builder.query<any, number | undefined | string>({
        query: (id) => `user-creative-sample/${id}`,
        transformResponse: (response: any) => {
          if (response.asset && response.asset.length > 0) {
            return {
              ...response,
              asset: {
                ...response.asset[0],
                assetType: getMediaTypeFromFileName(
                  response.asset[0].assetFilename,
                ),
              },
            };
          }
          return response;
        },
        providesTags: ['FetchCreativePortfolioById'],
      }),

      uploadAsset: builder.mutation<
        any,
        { body: FormData; abortController: AbortController }
      >({
        queryFn: async (arg, api) => {
          try {
            const { token } = (api.getState() as RootState).auth;

            if (token) {
              const result = await axios.post(
                'user-creative-sample/upload-asset',
                arg.body,
                {
                  headers: { Authorization: `Bearer ${token}` },
                  baseURL: config.apiBaseUrl,
                  onUploadProgress: (upload) => {
                    const uploadloadProgress = Math.round(
                      (100 * upload.loaded) / upload.total,
                    );
                    api.dispatch(setUploadProgress(uploadloadProgress));
                  },
                  signal: arg.abortController.signal,
                },
              );
              return { data: result.data };
            }
            throw new Error('Invalid token specified');
          } catch (axiosError) {
            const err = axiosError as any;
            return {
              error: {
                status: err.response?.status,
                data: err.response?.data || err.message,
              },
            };
          }
        },
      }),

      uploadThumbnail: builder.mutation<any, FormData>({
        query(body: FormData) {
          return {
            url: 'user-creative-sample/upload-thumbnail',
            method: HTTP_METHODS.POST,
            body,
          };
        },
      }),

      createCreativePortfolio: builder.mutation<any, CreateUserCreativeSample>({
        query(body: CreateUserCreativeSample) {
          return {
            url: 'user-creative-sample',
            method: HTTP_METHODS.POST,
            body,
          };
        },
        invalidatesTags: ['FetchCreativePortfolio'],
      }),

      updateCreativePortfolio: builder.mutation<any, UpdateUserCreativeSample>({
        query(body: UpdateUserCreativeSample) {
          return {
            url: `user-creative-sample/${body.id}`,
            method: HTTP_METHODS.PATCH,
            body,
          };
        },
        invalidatesTags: [
          'FetchCreativePortfolio',
          'FetchCreativePortfolioById',
          'ScriptAssessmentResults'
        ],
      }),

      reOrder: builder.mutation<any, ReorderBody>({
        query: (body) => ({
          url: `user-creative-sample/reorder-web`,
          method: 'POST',
          body,
        }),
      }),
      reOrderV2: builder.mutation<any, ReorderBody>({
        query: (creative) => ({
          url: `/user-creative-sample/v2/reorder-web`,
          method: 'POST',
          body: { creative },
        }),
      }),
      deleteCreativePortfolio: builder.mutation<any, number>({
        query: (id) => ({
          url: `user-creative-sample/${id}`,
          method: HTTP_METHODS.DELETE,
        }),
      }),
    };
  },
});

export const {
  useGetCreativeSamplesQuery,
  useGetCreativeSampleByIdQuery,
  useUploadAssetMutation,
  useCreateCreativePortfolioMutation,
  useUpdateCreativePortfolioMutation,
  useUploadThumbnailMutation,
  useReOrderMutation,
  useReOrderV2Mutation,
  useDeleteCreativePortfolioMutation,
} = creativePortfolioApi;
