export const STRAPI_HOME_PAGE = '/';
export const STRAPI_INDEX_PAGE = '/index';
export const STRAPI_PRIVACY_PAGE = '/Info/Privacy';
export const STRAPI_ABOUT_PAGE = '/Info/AboutUs';
export const STRAPI_FAQ_PAGE = '/Info/FAQ';
export const STRAPI_INFO_PAGE = '/Info';
export const STRAPI_TERMS_PAGE = '/Info/TermsOfService';
export const STRAPI_COMMUNITY_PAGE = '/Info/CommunityGuidelines';
export const STRAPI_MOBILE_COMMUNITY_PAGE = '/m/Info/CommunityGuidelines';
export const STRAPI_BLOGS_PAGE = '/Blogs';
export const STRAPI_EVENT_SIGNUP_PAGE = '/app/feeds/real-screen';
export const STRAPI_JOBS_PAGE = '/Jobs';
export const STRAPI_MOBILE_PRIVACY_PAGE = '/m/Info/Privacy';
export const STRAPI_MOBILE_TERMS_PAGE = '/m/Info/TermsOfService';
