import { useGetStripePublishableKeyQuery } from 'store/subscription/subscription-payment.api';

/* eslint-disable import/prefer-default-export */
export const useStripeInitializer = () => {
  const options = {
    appearance: {
      rules: {
        '.Label': {
          color: '#111111',
          fontSize: '0.875rem',
          fontWeight: 'bold',
          marginBottom: '0.25rem',
          marginLeft: '0.375rem',
          textTransform: 'capitalize'
        },
        '.Error': {
          marginTop: '-0.5rem',
        },
        '.Input': {
          border: '1.5px solid #d6d6d6',
          borderRadius: '0.5rem',
          padding: '0.6875rem 1rem',
          height: '2.75rem',
          fontSize: '1rem',
          fontWeight: '400',
          lineHeight: '1.5',
          marginBottom: '1rem',
        },
      },
    },
  };
  const { data: publishableKey } = useGetStripePublishableKeyQuery();

  return {
    options,
    publishableKey,
  };
};
