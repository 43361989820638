import { CreativeSamplePermissionType } from 'store/creative-portfolio';
import { UserType } from '../types';

export enum ScriptType {
  TV = 'tv',
  Film = 'film',
}

export type CreativeSampleAssetType = {
  [x: string]: any;
  originalFilename: string;
  assetFilename: string;
  url?: string;
  assetType: CreativeSampleAssetType;
  creativeSample?: CreativeSampleResponseType;
  user: UserType;
};

export type CreativeSampleResponseType = {
  title: string;
  description: string;
  permission?: CreativeSamplePermissionType;
  isVisible?: boolean;
  asset: CreativeSampleAssetType[];
  id: number;
  thumbnailUrl: string;
  selectedAssetType: string;
  selectedAssetSubType?: string;
  isPublished?: boolean;
};

export type CreativeSampleScriptAssessmentApiResponse = {
  assistantId: string;
  createdAt: Date;
  fileId: string;
  id: number;
  isPublished: boolean;
  messageId: string;
  raw_json: any;
  runId: string;
  threadId: string;
  updatedAt: Date;
  assessmentMeta?: {
    assessment: any;
    character: any;
    characterOverallComplexity: any;
    createdAt: Date;
    id: number;
    story: any;
    updatedAt: Date;
  };
};

export type CreativeSampleAssetApiResponse = {
  originalFilename: string;
  assetFilename: string;
  url?: string;
  assetType: string;
  deletedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  pageNumber?: number;
  scriptAssessment?: CreativeSampleScriptAssessmentApiResponse;
};

export type CreativeSampleApiResponse = {
  title: string;
  description: string;
  permission?: CreativeSamplePermissionType;
  isVisible?: boolean;
  asset: CreativeSampleAssetType[];
  id: number;
  thumbnailUrl: string;
  selectedAssetType: string;
  selectedAssetSubType?: ScriptType;
  isPublished?: boolean;
  createdAt: Date;
  deletedAt: Date;
  updatedAt: Date;
  order: number;
};

export type CreativeTokenResponse = {
  creatives: CreativeSampleApiResponse[];
};

export type CreativeCardType =
  | 'video'
  | 'audio'
  | 'script'
  | 'blog'
  | 'design'
  | 'image';

export interface CreativeSampleCardProps extends CreativeSampleApiResponse {
  isPublished?: boolean;
}

export enum AssessmentStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
}
