import React, { ReactElement } from 'react';
import { Button as BButton, Spinner } from 'react-bootstrap';
import Typography, {
  TypographyVariant,
} from '../StylesGuide/Typography/Typography';

export enum ButtonTypes {
  submit = 'submit',
  button = 'button',
}
export enum ButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  info = 'info',
  light = 'light',
  dark = 'dark',
  link = 'link',
  text = 'text',
  outlinePrimary = 'outline-primary',
}
export enum ButtonSizes {
  large = 'lg',
  small = 'sm',
}
interface ButtonProps extends React.HTMLAttributes<HTMLElement> {
  type?: ButtonTypes;
  variant: ButtonVariants;
  typographyVariant?: TypographyVariant;
  size?: ButtonSizes;
  label: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  leftIcon?: ReactElement | boolean;
  rightIcon?: ReactElement;
  id?: string;
}

const DefualtButtonTypographyVariant: TypographyVariant = 'p-bt-sb';

const Button = ({
  type = ButtonTypes.button,
  variant = ButtonVariants.primary,
  typographyVariant = DefualtButtonTypographyVariant,
  size,
  className,
  label,
  disabled,
  loading,
  leftIcon,
  rightIcon,
  id,
  ...props
}: ButtonProps): ReactElement => (
  <BButton
      id={id}
    type={type}
    variant={variant}
    size={size}
    onClick={props.onClick}
    className={`${className || ''} d-flex align-items-center`}
    disabled={disabled}
  >
    {leftIcon && <span className='btn-icon'>{leftIcon}</span>}
    {loading && (
      <Spinner
        as='span'
        className='mr-1 pe-none'
        animation='border'
        size='sm'
        role='status'
        aria-hidden='true'
      />
    )}
    <Typography variant={typographyVariant} className='d-block pe-none'>
      {label}
    </Typography>
    {rightIcon && <span>{rightIcon}</span>}
  </BButton>
);

export default React.memo(Button);
