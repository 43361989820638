import { createSlice } from '@reduxjs/toolkit';
import { IGetUserList } from './contact.types';

const CONTACT_SLICE_KEY = 'contact';

export interface ContactState {
  status: string;
  isRedirectedFromHomePage: boolean;
  searchText: string;
  searchedListData: IGetUserList | null;
}
const initialState: ContactState = {
  status: '',
  isRedirectedFromHomePage: false,
  searchText: '',
  searchedListData: null,
};
const contactSlice = createSlice({
  name: CONTACT_SLICE_KEY,
  initialState,
  reducers: {
    updateListStatus(state, action) {
      state.status = action.payload.status;
    },
    redirectedFromHome(state, action) {
      state.isRedirectedFromHomePage = action.payload;
    },
    updateSearchText(state, action) {
      state.searchText = action.payload;
    },
    updateSearchedListData(state, action) {
      state.searchedListData = action.payload;
    },
  },
});
export const {
  updateListStatus,
  redirectedFromHome,
  updateSearchText,
  updateSearchedListData,
} = contactSlice.actions;

export const contactReducer = contactSlice.reducer;
