import * as UpChunk from '@mux/upchunk';
import { MuxVideo } from 'commons/types/post.type';
import { useState, useEffect } from 'react';
import {
  GenerateMuxVideUrlResponse,
  useLazyGenerateMuxVideoUrlQuery,
} from 'store/post/post.api';

export type MuxVideoUploadStatus = 'success' | 'progress' | 'error';

const useMuxUploadVideo = (uploadedVideo?: File | MuxVideo) => {
  const [muxVideoUrl, setMuxVideoUrl] = useState<GenerateMuxVideUrlResponse>();
  const [muxVideoUploadStatus, setMuxVideoUploadStatus] =
    useState<MuxVideoUploadStatus>();
  const [trigger] = useLazyGenerateMuxVideoUrlQuery();
  const [errorDetail, setErrorDetail] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  useEffect(() => {
    async function generateMuxUrl() {
      if (uploadedVideo && uploadedVideo instanceof File) {
        const muxVideoUrlRes = await trigger().unwrap();
        setMuxVideoUrl(muxVideoUrlRes);
      }
    }
    generateMuxUrl();
  }, [uploadedVideo]);

  useEffect(() => {
    async function uploadVideoToMux() {
      if (muxVideoUrl && uploadedVideo && uploadedVideo instanceof File) {
        const upload = UpChunk.createUpload({
          endpoint: muxVideoUrl.uploadUrl,
          file: uploadedVideo,
          chunkSize: 5120,
          attempts: 5,
        });

        upload.on('error', (err) => {
          setMuxVideoUploadStatus('error');
          setErrorDetail(err.detail);
          console.log(err.detail);
        });

        upload.on('progress', (progress) => {
          setMuxVideoUploadStatus('progress');
          setUploadProgress(progress.detail);
          console.log(progress.detail);
        });

        upload.on('success', () => {
          setMuxVideoUploadStatus('success');
          console.log('uploaded successfully');
        });
      }
    }
    uploadVideoToMux();
  }, [muxVideoUrl, uploadedVideo]);

  useEffect(() => {
    if (muxVideoUploadStatus === 'error') {
      console.log('ERROR on uploading the video', errorDetail);
    }
  }, [muxVideoUploadStatus]);

  return { muxVideoUploadStatus, muxVideoUrl, errorDetail, uploadProgress };
};

export default useMuxUploadVideo;
