import React from 'react';
import { Spinner } from 'react-bootstrap';

interface ILoaderProps {
  size?: 'sm';
}
const Loader = ({ size }: ILoaderProps) => (
  <div className='my-network-no-data-container'>
    <Spinner
      as='span'
      className='mr-1'
      animation='border'
      size={size}
      role='status'
      aria-hidden='true'
    />
  </div>
);

export default Loader;
