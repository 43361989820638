import useMuxUploadVideo from 'commons/hooks/useMuxVideoUpload';
import HusslUpIcon from 'components/Icon';
import Image from 'next/image';
import { FunctionComponent, MouseEvent } from 'react';
import { CloseButton, ProgressBar } from 'react-bootstrap';
import { formatBytes } from 'utils/file';

const CreatePostFileUploads: FunctionComponent<{
  uploadedImage?: File;
  uploadedDoc?: File;
  uploadedVideo?: File;
  onUnlinkImage?: (event: MouseEvent<HTMLButtonElement>) => void;
  onUnlinkDoc?: (event: MouseEvent<HTMLButtonElement>) => void;
  onUnlinkVideo?: (event: MouseEvent<HTMLButtonElement>) => void;
}> = ({
  uploadedImage,
  uploadedDoc,
  uploadedVideo,
  onUnlinkImage,
  onUnlinkDoc,
  onUnlinkVideo,
}) => {
  const { muxVideoUploadStatus, uploadProgress } =
    useMuxUploadVideo(uploadedVideo);
  return (
    <>
      {uploadedImage && (
        <div className='image-uploads'>
          <Image
            src={URL.createObjectURL(uploadedImage)}
            width={230}
            height={165}
            alt='Uploaded Picture'
          />
          <div className='remove'>
            <CloseButton variant='white' onClick={onUnlinkImage} />
          </div>
        </div>
      )}
      {uploadedDoc && (
        <div className='pdf-block pdf-block--remove'>
          <div className='icon'>
            <i className='hic-pdf-fill hic-3x text-primary' />
          </div>
          <div>
            <div className='p-md p-md-sb'>{uploadedDoc.name}</div>
            <div className='p-sm'>{formatBytes(uploadedDoc.size)}</div>
          </div>
          <CloseButton onClick={onUnlinkDoc} />
        </div>
      )}
      {uploadedVideo && (
        <>
          {muxVideoUploadStatus === 'success' ? (
            <div className='image-uploads video-frame'>
              <video width={230} muted controls>
                <source
                  src={URL.createObjectURL(uploadedVideo)}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
              <div className='remove'>
                <CloseButton variant='white' onClick={onUnlinkVideo} />
              </div>
            </div>
          ) : muxVideoUploadStatus === 'error' ? (
            <div className='image-uploads video-frame'>
              Unable to upload the video. Try reload?
              <div className='remove'>
                <CloseButton variant='white' onClick={onUnlinkVideo} />
              </div>
            </div>
          ) : (
            <div className='video-uploads'>
              <div className='w-100 d-flex align-items-center flex-column'>
                <HusslUpIcon
                  name='video'
                  wrapperClass='text-primary'
                  variant='2x'
                />
                <ProgressBar now={uploadProgress} />
              </div>
              <div className='remove'>
                <CloseButton onClick={onUnlinkVideo} />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CreatePostFileUploads;
