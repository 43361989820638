import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { resetStateAction } from 'store';

const unauthenticatedMiddleware: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (
      getState().auth.token &&
      isRejectedWithValue(action) &&
      action.payload.status === 401
    ) {
      dispatch(resetStateAction());
    }

    return next(action);
  };

export default unauthenticatedMiddleware;
