export const enum WorkStatus {
  CURRENTLY_WORKING,
  HIRING,
  FINANCING,
  READY_TO_WORK,
}

export type WorkStatusOption = {
  label: string;
  value: WorkStatus;
  text: string;
};
