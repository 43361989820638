import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';

/**
 * Log a warning and show a toast!
 */
const errorLoggerMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    Sentry.captureException(JSON.stringify(action));
  }

  return next(action);
};

export default errorLoggerMiddleware;
