/* eslint-disable react/no-array-index-key */
import {
  ChickletSize,
  MemberChicklet,
  MemberChickletType,
} from 'components/Chicklet';
import HusslUpIcon from 'components/Icon';
import React, { FunctionComponent, ReactElement } from 'react';
import highlightText from 'utils/hightLightText';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MEMBER_CARD_HEADING_MAX_LENGTH } from 'constants/app';
import { IconProps } from '.';

interface IProps {
  pro?: MemberChickletType;
  chickletSize?: ChickletSize;
  icons?: IconProps[];
  name: string | JSX.Element;
  headings?: string[];
  searchText?: string;
  keyName?: string;
  calledBy?: string;
}

const MemberDetails: FunctionComponent<IProps> = ({
  name,
  pro,
  headings,
  icons,
  chickletSize,
  searchText,
  keyName,
  calledBy,
}): ReactElement => {
  const headerSliceNumber = keyName === 'companies' ? 3 : 2;
  return (
    <div className='member-info'>
      {pro && <MemberChicklet labelText={pro} size={chickletSize} />}
      <div className='member-name'>
        <OverlayTrigger
          placement='bottom-start'
          delay={{ show: 250, hide: 400 }}
          overlay={
            String(name)?.length > 19 ? <Tooltip>{name}</Tooltip> : <></>
          }
        >
          <span
            className={`text-nowrap overflow-hidden text-truncate ${
              String(name).length >= 20 ? 'p-sm-b' : 'p-lg-b'
            }`}
          >
            {keyName === 'memberNames' ||
            keyName === 'companies' ||
            keyName === 'communities'
              ? highlightText(name as string, searchText ?? '')
              : name}
          </span>
        </OverlayTrigger>
        {calledBy === 'prosearch'
          ? icons?.map((icon) => (
              <HusslUpIcon
                key={icon.icon}
                name={icon.icon}
                title={icon.label}
                onClick={icon?.onClick}
                wrapperClass={`ml-h ${icon.color || ''} d-inline-flex ${
                  icon.color
                } fs-20 ${icon.onClick ? 'pointer' : ''}`}
              />
            ))
          : null}
      </div>

      {headings?.slice(0, headerSliceNumber).map((heading, index) => (
        <div
          className='profile-heading d-inline-flex align-items-center'
          key={`${heading}-${index}`}
        >
          <OverlayTrigger
            placement='bottom-start'
            delay={{ show: 250, hide: 400 }}
            overlay={
              heading?.length > MEMBER_CARD_HEADING_MAX_LENGTH ? (
                <Tooltip>{heading}</Tooltip>
              ) : (
                <></>
              )
            }
          >
            <span className={keyName === 'communities' ? 'small small-ac' : ''}>
              {keyName === 'profileHeadlines'
                ? highlightText(heading, searchText ?? '')
                : heading}
            </span>
          </OverlayTrigger>
          {heading?.toLowerCase() === 'private' && <HusslUpIcon name='lock' />}
        </div>
      ))}
    </div>
  );
};

export default MemberDetails;
