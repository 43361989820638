import { ConnectionsSortByDropdown } from 'commons/types/network.type';
import { ITab } from 'components/Core/HusslTab/HusslTab';
import { KebabDropdownItem } from 'components/Core/KebabDropdown/KebabDropdown';
import config from 'config';
import { ConnectionActions, ConnectionsSliceState } from 'store/my-network';
import { ConnectionLabel } from 'store/user/types';

export type ConnectionsKebabDropdownItem = KebabDropdownItem & {
  key: ConnectionActions;
};

export const connectionsMemberCardDropdowns: ConnectionsKebabDropdownItem[] =
  config.enableStaffingRoster === 'true'
    ? [
        {
          key: 'label-connection',
          label: 'Label Connection...',
        },
        { key: 'add-to-pro-search', label: 'Add to ProSearch' },
        { key: 'add-to-contact-list', label: 'Add to Contact List' },
        { key: 'add-to-staffing-roster', label: 'Add to Staffing Roster' },
        // { key: 'share', label: 'Share' },
        { key: 'copy-profile-link', label: 'Copy Profile Link' },
        { key: 'remove-connection', label: 'Remove Connection' },
        { key: 'report', label: 'Report' },
        { key: 'block', label: 'Block' },
      ]
    : [
        {
          key: 'label-connection',
          label: 'Label Connection...',
        },
        { key: 'add-to-pro-search', label: 'Add to ProSearch' },
        { key: 'add-to-contact-list', label: 'Add to Contact List' },
        // { key: 'share', label: 'Share' },
        { key: 'copy-profile-link', label: 'Copy Profile Link' },
        { key: 'remove-connection', label: 'Remove Connection' },
        { key: 'report', label: 'Report' },
        { key: 'block', label: 'Block' },
      ];

export const connectionRequestsMemberCardDropdowns: ConnectionsKebabDropdownItem[] =
  config.enableStaffingRoster === 'true'
    ? [
        {
          key: 'message',
          label: 'Message',
        },
        { key: 'add-to-pro-search', label: 'Add to ProSearch' },
        { key: 'add-to-contact-list', label: 'Add to Contact List' },
        { key: 'add-to-staffing-roster', label: 'Add to Staffing Roster' },
        // { key: 'share', label: 'Share' },
        { key: 'copy-profile-link', label: 'Copy Profile Link' },
        { key: 'report', label: 'Report' },
      ]
    : [
        {
          key: 'message',
          label: 'Message',
        },
        { key: 'add-to-pro-search', label: 'Add to ProSearch' },
        { key: 'add-to-contact-list', label: 'Add to Contact List' },
        // { key: 'share', label: 'Share' },
        { key: 'copy-profile-link', label: 'Copy Profile Link' },
        { key: 'report', label: 'Report' },
      ];

export const acceptedInvitesMemberCardDropdonws: ConnectionsKebabDropdownItem[] =
  config.enableStaffingRoster === 'true'
    ? [
        {
          key: 'connect',
          label: 'Connect',
        },
        {
          key: 'message',
          label: 'Message',
        },
        { key: 'add-to-pro-search', label: 'Add to ProSearch' },
        { key: 'add-to-contact-list', label: 'Add to Contact List' },
        { key: 'add-to-staffing-roster', label: 'Add to Staffing Roster' },
        // { key: 'share', label: 'Share' },
        { key: 'copy-profile-link', label: 'Copy Profile Link' },
        { key: 'report', label: 'Report' },
      ]
    : [
        {
          key: 'connect',
          label: 'Connect',
        },
        {
          key: 'message',
          label: 'Message',
        },
        { key: 'add-to-pro-search', label: 'Add to ProSearch' },
        { key: 'add-to-contact-list', label: 'Add to Contact List' },
        // { key: 'share', label: 'Share' },
        { key: 'copy-profile-link', label: 'Copy Profile Link' },
        { key: 'report', label: 'Report' },
      ];

export const getActionKeyByLabel = (label: string) => {
  const selectedOption = connectionsMemberCardDropdowns.find(
    (option) =>
      option.label.toString().toLowerCase() === label.toString().toLowerCase(),
  );
  return selectedOption?.key ?? 'copy-profile-link';
};

export const connectionTabs: ITab[] = [
  {
    key: '',
    title: 'All',
  },
  {
    key: ConnectionLabel.REPRESENTATION,
    title: 'Representation',
  },
  {
    key: ConnectionLabel.CLIENTS,
    title: 'Clients',
  },
  {
    key: ConnectionLabel.WORK_WITH,
    title: 'Work With',
  },
];

export const connectionRequestsTabs: ITab[] = [
  {
    key: 'pending',
    title: 'Pending',
  },
  {
    key: 'declined',
    title: 'Declined',
  },
];

export const referralInvitesTabs: ITab[] = [
  {
    key: 'accepted',
    title: 'Accepted',
  },
  {
    key: 'pending',
    title: 'Pending',
  },
];

export const defaultNetworkActiveRoutes = {
  connections: false,
  connectionRequests: false,
  referralInvites: false,
  followedCompanies: false,
};

export const getNetworkTabs = (
  activeRoute: typeof defaultNetworkActiveRoutes,
  data?: any,
) => {
  if (activeRoute.connections) {
    const tabsDetail = connectionTabs.map((element) => {
      const { title } = element;
      if (element.key === '') {
        return {
          ...element,
          title: `${title} ${data?.None ? `(${data?.None ?? 0})` : ''}`,
        };
      }
      return {
        ...element,
        title: `${title} ${data?.[title] ? `(${data?.[title] ?? 0})` : ''}`,
      };
    });
    return tabsDetail;
  }
  if (activeRoute.connectionRequests) {
    return connectionRequestsTabs;
  }
  if (activeRoute.referralInvites) {
    return referralInvitesTabs;
  }
  return connectionTabs;
};

export const ReferralInviteSortOptions: ConnectionsSortByDropdown[] = [
  {
    label: 'Alphabetical (A-Z)',
    key: 'best-match',
  },
  {
    label: 'Alphabetical (Z-A)',
    key: 'best-match-reverse',
  },
  {
    label: 'Most Recently Invited',
    key: 'recent',
  },
];

export const ConnectionsSortOptions: ConnectionsSortByDropdown[] = [
  {
    label: 'Alphabetical (A-Z)',
    key: 'best-match',
  },
  {
    label: 'Alphabetical (Z-A)',
    key: 'best-match-reverse',
  },
  {
    label: 'Most Recent Connections',
    key: 'recent',
  },
];

export const FollowedCompaniesSortOptions: ConnectionsSortByDropdown[] = [
  {
    label: 'Alphabetical (A-Z)',
    key: 'best-match',
  },
  {
    label: 'Alphabetical (Z-A)',
    key: 'best-match-reverse',
  },
  {
    label: 'Most Recent',
    key: 'recent',
  },
];

// Slice States
export const InitialConnectionSliceState: ConnectionsSliceState = {
  getConnectionsByLabelParams: {
    limit: 25,
    page: 1,
    sortBy: 'best-match',
    searchText: '',
    nextPage: false,
  },
  getConnectionRequestsParams: {
    limit: 25,
    page: 1,
    requestType: 'pending',
    sortBy: 'best-match',
    searchText: '',
  },
  getAcceptedInvitesParams: {
    limit: 25,
    page: 1,
    sortBy: 'best-match',
    searchText: '',
  },
  getFollowedCompaniesParams: {
    limit: 25,
    page: 1,
    sortBy: 'best-match',
    searchText: '',
    nextPage: false,
  },
  selectedReferralInviteTab: 'accepted',
  actionParams: {},
  companyActionsParams: {},
};
