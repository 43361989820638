import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'commons/types/redux';
import { PostSliceState } from '../post.slice';

export const selectPostState = (state: RootState) =>
  state.post as PostSliceState;

export const selectGetPostWithTagParams = createSelector(
  selectPostState,
  (state) => state.getPostWithTagParams,
);

export const selectFeedSearchResultCount = createSelector(
  selectPostState,
  (state) => state.feedSearchResultCount,
);

export const selectActivePostTag = createSelector(
  selectGetPostWithTagParams,
  (state) => state.tagId,
);

export const selectPostActionParams = createSelector(
  selectPostState,
  (state) => state.actionParams,
);

export const selectSearchState = createSelector(
  selectPostState,
  (state) => state.searchState,
);

export const selectSearchKeyword = createSelector(
  selectPostState,
  (state) => state.searchKeyword,
);
