import {
  FunctionComponent,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import { useCreatePostMutation } from 'store/post/post.api';
import type { PostTag } from 'commons/types/post.type';
import { useGetAuthUserCompanyQuery } from 'store/auth/auth.api';
import useMuxUploadVideo from 'commons/hooks/useMuxVideoUpload';
import ToasterUtils from 'utils/toaster';
import useAuth from 'commons/hooks/auth';
import LeavePageAlert from 'components/Core/NavigationBlocker/LeavePageAlert';
import { useGetPostTagsQuery } from 'store/post/post-tag.api';
import { USER_POSTED } from 'constants/gt-classes';
import CreatePostAsProfile from './CreatePostAsProfile';
import CreatePostFileUploads from './CreatePostFileUploads';
import CreatePostMediaUploader from './CreatePostMediaUploader';
import CreatePostPdfUploader from './CreatePostPdfUploader';
import CreatePostTagSelector from './CreatePostTagSelector';
import CreatePostTextarea from './CreatePostTextArea';
import PostAsDropdown from './PostAsDropdown';
import * as gtag from '../../../utils/gtag';

export enum PostCreatorEntities {
  COMPANY = 'company',
  USER = 'user',
}

export type PostAsType = PostCreatorEntities.USER | PostCreatorEntities.COMPANY;
const rows = 1;
const cols = 80;

const CreatePostModal: FunctionComponent<{
  showModal: boolean;
  onFormSubmitSuccess: () => void;
  onDismissModal?: () => void;
}> = ({ showModal = false, onFormSubmitSuccess, onDismissModal }) => {
  const closeButtonRef = useRef<HTMLElement>(null);
  const [uploadedImage, setUploadedImage] = useState<File>();
  const [uploadedVideo, setUploadedVideo] = useState<File>();
  const [uploadedPdf, setUploadedPdf] = useState<File>();
  const [usersMentioned, setUsersMentioned] = useState<any[]>([]);
  const [post, setPost] = useState<string>('');
  const [showLeaveAlert, setShowLeaveAlert] = useState<boolean>(false);
  const [postAs, setPostAs] = useState<PostAsType>(PostCreatorEntities.USER);
  const [selectedTag, setSelectedTag] = useState<PostTag>();
  const [createPost, { isLoading: isCreating }] = useCreatePostMutation();
  const { data: authUserCompany } = useGetAuthUserCompanyQuery();
  const [textAreaActiveClass, setTextAreaActiveClass] = useState<string>('');
  const { muxVideoUrl, muxVideoUploadStatus } =
    useMuxUploadVideo(uploadedVideo);
  const { user, company } = useAuth();
  const { t } = useTranslation('misc');
  const { data: postTags } = useGetPostTagsQuery({
    screenRequesting: 'create-post',
  });

  const handleImageUpload = (file: File) => {
    if (uploadedVideo) {
      setUploadedVideo(undefined);
    }
    setUploadedImage(file);
  };

  const handleVideoUpload = async (file: File) => {
    if (uploadedImage) {
      setUploadedImage(undefined);
    }
    setUploadedVideo(file);
  };

  const handlePostAsCompanySelected = () => {
    setPostAs(PostCreatorEntities.COMPANY);
  };

  const handlePostAsUserSelected = () => {
    setPostAs(PostCreatorEntities.USER);
  };

  const handlePdfUpload = (file: File) => {
    setUploadedPdf(file);
  };

  const handleImageUnlink = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setUploadedImage(undefined);
  };

  const handlePdfUnlink = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setUploadedPdf(undefined);
  };

  const handleVideoUnlink = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setUploadedVideo(undefined);
  };

  const handleUserMentioned = (id: string | number) => {
    setUsersMentioned([...usersMentioned, id]);
  };

  const resetForm = () => {
    setPost('');
    setUploadedImage(undefined);
    setUploadedPdf(undefined);
    setUploadedVideo(undefined);
    setUsersMentioned([]);
    setSelectedTag(undefined);
  };

  const handlePostCreate = async () => {
    try {
      const formData = new FormData();
      formData.append('post', post);
      if (uploadedImage) {
        formData.append('files', uploadedImage);
      }
      if (uploadedPdf) {
        formData.append('files', uploadedPdf);
      }
      if (selectedTag) {
        formData.append('tagId', selectedTag.id);
      }
      if (usersMentioned) {
        usersMentioned.forEach((userId) => {
          formData.append('taggedUsersId', userId);
        });
      }
      if (uploadedVideo && muxVideoUrl) {
        formData.append('muxVideoId', muxVideoUrl.id);
      }
      if (postAs === PostCreatorEntities.COMPANY) {
        formData.append('postAs', PostCreatorEntities.COMPANY);
        formData.append('companyId', company.id);
      }
      await createPost(formData).unwrap();
      resetForm();
      onFormSubmitSuccess();
      gtag.event({
        label: 'user post created',
        category: 'Post',
        action: 'USER_POSTED',
      });
    } catch (err) {
      ToasterUtils.errorToast(t('CREATE_POST_ERROR.value'));
    }
  };

  const hasPost = useMemo(() => {
    if (
      !!post ||
      !!uploadedImage ||
      !!uploadedPdf ||
      !!uploadedVideo ||
      usersMentioned.length > 0
    ) {
      return true;
    }
    return false;
  }, [post, uploadedImage, uploadedPdf, uploadedVideo, usersMentioned]);

  const handleModalStay = (): void => {
    setShowLeaveAlert(false);
  };

  const handleModalLeave = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowLeaveAlert(false);
    resetForm();
    onDismissModal && onDismissModal();
  };

  const onDismissModalHandler = () => {
    if (hasPost) {
      setShowLeaveAlert(true);
    } else {
      setShowLeaveAlert(false);
      onDismissModal && onDismissModal();
    }
  };

  const handlePopstate = (e: PopStateEvent) => {
    if (hasPost) {
      e.preventDefault();
      window.history.go(1);
      setShowLeaveAlert(true);
    }
  };

  const handleBeforeUnload = (e: BeforeUnloadEvent): void => {
    if (hasPost) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  useEffect(() => {
    if (hasPost)
      window.history.pushState(null, null as any, window.location.href);
    window.addEventListener('popstate', handlePopstate);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [hasPost]);

  const [, setAdjustedRows] = useState(rows);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    if (textAreaRef?.current && textAreaRef?.current?.value) {
      const textAreaValue = textAreaRef?.current?.value;
      const numberOfColumns = textAreaRef?.current?.cols;

      let linecount = 0;
      textAreaValue.split('\n').forEach((line) => {
        const extraRowForLongColumn = Math.floor(line.length / numberOfColumns);
        const rowsToAdd =
          1 + (line.length / cols > 1 ? extraRowForLongColumn : 0);
        linecount += rowsToAdd;
      });

      // if (linecount < MAX_TEXTAREA_ROWS) {
      setAdjustedRows(linecount);
      // }
    }
  };

  useEffect(() => {
    if (!post) {
      setAdjustedRows(1);
    } else {
      adjustHeight();
    }
  }, [post]);

  // const contentHeight = useMemo(() => {
  //   if (textAreaRef?.current) {
  //     const lineHeight = window
  //       .getComputedStyle(textAreaRef?.current as any)
  //       .lineHeight.replace('px', '');
  //     return Number(lineHeight) * 2 * adjustedRows;
  //   }
  //   return 205;
  // }, [adjustedRows, post]);

  return (
    <>
      <LeavePageAlert
        showModal={showLeaveAlert}
        handleModalLeave={handleModalLeave}
        handleModalStay={handleModalStay}
      />
      <Modal
        show={showModal}
        size='lg'
        onHide={onDismissModalHandler}
        ref={closeButtonRef}
        className='post-modal'
        key='create-post'
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='profile-block'>
            <CreatePostAsProfile
              postAs={postAs}
              creator={
                postAs === PostCreatorEntities.COMPANY && company
                  ? company
                  : user
              }
              onTagUnselected={() => {
                setSelectedTag(undefined);
              }}
              selectedTag={selectedTag}
            />
            {company && (
              <PostAsDropdown
                postAs={postAs}
                authUser={user as any}
                company={authUserCompany}
                onCompanySelected={handlePostAsCompanySelected}
                onUserSelected={handlePostAsUserSelected}
              />
            )}
          </div>
          <div
            className={`post-modal__content ${textAreaActiveClass}`}
            // style={{
            //   height: contentHeight,
            // }}
          >
            <CreatePostTextarea
              placeholder='Tell me something good ...'
              required
              value={post}
              onBlur={() => {
                setTextAreaActiveClass('');
              }}
              onKeyDown={() => {
                setTextAreaActiveClass('active');
              }}
              onChange={(event: any) => {
                setPost(event.target.value?.replaceAll(/\r/g, ''));
              }}
              onUsersMentioned={handleUserMentioned}
              inputRef={textAreaRef}
            />
            <CreatePostFileUploads
              uploadedImage={uploadedImage}
              uploadedDoc={uploadedPdf}
              uploadedVideo={uploadedVideo}
              onUnlinkImage={handleImageUnlink}
              onUnlinkDoc={handlePdfUnlink}
              onUnlinkVideo={handleVideoUnlink}
            />
            <div className='misc-holder'>
              <div className='misc'>
                <div className='misc__attachments'>
                  <CreatePostMediaUploader
                    onVideoUpload={handleVideoUpload}
                    onImageUpload={handleImageUpload}
                  />
                  <CreatePostPdfUploader onPdfUpload={handlePdfUpload} />
                </div>
                <div className='misc__tags'>
                  <CreatePostTagSelector
                    onTagSelected={(tag: PostTag) => {
                      setSelectedTag(tag);
                    }}
                    selectedTag={selectedTag}
                    tags={postTags}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ marginTop: '-8px' }}>
          <button
            disabled={
              !hasPost || muxVideoUploadStatus === 'progress' || isCreating
            }
            type='submit'
            onClick={handlePostCreate}
            className={`send ${USER_POSTED}`}
          >
            <i className='hic-sended hic-3x pe-none' />
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreatePostModal;
