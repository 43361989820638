import { current } from '@reduxjs/toolkit';
import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  BlockListResponse,
  BlockUserPayload,
  BlockUserResponse,
} from 'commons/types/block-users.type';
import {
  API_BLOCK_USER,
  API_BLOCK_USER_BLOCK,
  API_BLOCK_USER_GET_BLOCKED_USER_LIST,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
import { postApiSlice } from 'store/post/post.api';

export const blockUsersApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      blockUser: builder.mutation<BlockUserResponse, BlockUserPayload>({
        query: (payload) => ({
          url: `${API_BLOCK_USER}${API_BLOCK_USER_BLOCK}/${payload.body.userId}`,
          method: HTTP_METHODS.POST,
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            const response = (await queryFulfilled) as any;
            if (response?.data?.success) {
              dispatch(
                postApiSlice.util.updateQueryData(
                  'getPostsWithTag',
                  props.getPostWithTagParams,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedPost = current(draft).items.filter(
                        (post) => post.posted_by?.id !== props.body.userId,
                      );
                      const updatedDraft = {
                        ...current(draft),
                        items: [...updatedPost],
                      };

                      Object.assign(draft, updatedDraft);
                    }
                  },
                ),
              );
            }
          } catch {
            /* empty */
          }
        },
        invalidatesTags: ['BlockedUserList'],
      }),
      blockByHusslupId: builder.mutation<BlockUserResponse, { husslupIdToBlock: string }>({
        query: ({ husslupIdToBlock }) => ({
          url: `${API_BLOCK_USER}${API_BLOCK_USER_BLOCK}/by-husslup-id/${husslupIdToBlock}`,
          method: HTTP_METHODS.POST,
        }),
        invalidatesTags: ['BlockedUserList'],
      }),
      getBlockedUserList: builder.query<BlockListResponse, void>({
        query: () => `${API_BLOCK_USER}${API_BLOCK_USER_GET_BLOCKED_USER_LIST}`,
        providesTags: ['BlockedUserList'],
      }),
    };
  },
});

export const {
  useBlockUserMutation,
  useBlockByHusslupIdMutation,
  useGetBlockedUserListQuery,
} = blockUsersApiSlice;
