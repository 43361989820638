import React from 'react';

type HorizontalSeparatorProps = {
  text?: string;
  textClassName?: string;
  wrapperClassName?: string;
};

const HorizontalSeparator = ({
  text,
  textClassName,
  wrapperClassName,
}: HorizontalSeparatorProps) => (
  <div className='d-flex'>
    <div className={`hr-separator ${wrapperClassName ?? ''}`}>
      <hr />
      <div className='hr-separator-wrapper'>
        <span className={textClassName ?? 'p-md'}>{text}</span>
      </div>
    </div>
  </div>
);

export default HorizontalSeparator;
