import classNames from 'classnames';
import { FunctionComponent, MouseEventHandler, ReactNode } from 'react';

const Chip: FunctionComponent<{
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  children: ReactNode;
}> = ({ isActive = false, onClick, children }) => {
  const className = classNames({
    'chip-item': true,
    active: isActive,
  });

  return (
    <div
      onClick={onClick}
      role='button'
      tabIndex={0}
      onKeyUp={() => {}}
      className={className}
    >
      {children}
    </div>
  );
};

export default Chip;
