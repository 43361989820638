/* eslint-disable react/jsx-no-useless-fragment */
import { FunctionComponent } from 'react';
import Image from 'next/image';
import GreenlightStudioIcon from '../../assets/svg-icons/greenlight-studio.svg';

const discoverIcon = <i className='hic-discover hic-nav' />;

const staffingIcon = <i className='hic-staffing hic-nav' />;

const jobsIcon = <i className='hic-jobs hic-nav' />;

const myNetworkIcon = <i className='hic-network hic-nav' />;

const messageIcon = <i className='hic-message hic-nav' />;

const searchIcon = <i className='hic-search hic-nav' />;

const memberPerks = <i className='hic-member-perks hic-nav' />;
const greenlightStudioIcon = <Image src={GreenlightStudioIcon} alt='no-icon' />;

const notificationIcon = <i className='hic-notification hic-nav' />;
const NavIcon: FunctionComponent<{ name: string }> = ({ name }) => {
  let icon = null;
  switch (name) {
    case 'discover':
      icon = <>{discoverIcon}</>;
      break;
    case 'staffing':
      icon = <>{staffingIcon}</>;
      break;

    case 'jobs':
      icon = <>{jobsIcon}</>;
      break;
    case 'my-network':
      icon = <>{myNetworkIcon}</>;
      break;
    case 'message':
      icon = <>{messageIcon}</>;
      break;
    case 'notification':
      icon = <>{notificationIcon}</>;
      break;
    case 'search':
      icon = <>{searchIcon}</>;
      break;
    case 'member-perks':
      icon = <>{memberPerks}</>;
      break;
    case 'greenlight-studio':
      icon = <>{greenlightStudioIcon}</>;
      break;
    default:
      icon = <>{discoverIcon}</>;
      break;
  }

  return icon;
};

export default NavIcon;
