import type { RootState } from 'commons/types/redux';
import { createSelector } from '@reduxjs/toolkit';

import { PRO_SEARCH_TILES_SIZE } from 'constants/commons';
import {
  ProSearchSliceState,
  ProsearchDetailsPageStateSliceState,
} from './prosearch.types';
import {
  PRO_SEARCH_SLICE_KEY,
  PRO_SEARCH_DETAIL_PAGE_STATE_SLICE_KEY,
} from './prosearch.slice';

export const selectProSearchState: (state: RootState) => ProSearchSliceState = (
  state: RootState,
) => state[PRO_SEARCH_SLICE_KEY];

export const selectProsearchDetailPageState: (
  state: any,
) => ProsearchDetailsPageStateSliceState = (state: any) =>
  state[PRO_SEARCH_DETAIL_PAGE_STATE_SLICE_KEY];

export const selectPagination = createSelector(
  selectProsearchDetailPageState,
  (s) =>
    JSON.stringify({
      from: s?.pagination?.page * PRO_SEARCH_TILES_SIZE,
      size: PRO_SEARCH_TILES_SIZE,
    }),
);

export const selectHasNext = createSelector(
  selectProsearchDetailPageState,
  (s) => s?.pagination?.totalPages > s?.pagination?.page,
);

export const selectCurrentPage = createSelector(
  selectProsearchDetailPageState,
  (s) => s?.pagination?.page,
);

export const selectJobDescriptionUploadProgress = createSelector(
  selectProSearchState,
  (creativeState) => creativeState.assetUpload?.progress,
);

export const selectFacetedAccordionsData = createSelector(selectProsearchDetailPageState, (s) => s?.facetedAccordionsData);
