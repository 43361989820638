import { createSelector } from '@reduxjs/toolkit';
import { blockUsersApiSlice } from './block-users.api';

export const selectBlockedUserList =
  blockUsersApiSlice.endpoints.getBlockedUserList.select();

export const selectUsersBlockedByMe = createSelector(
  selectBlockedUserList,
  (blockedUserListResponse) => blockedUserListResponse?.data?.data?.blockedByMe,
);

export const selectUsersBlockedByMeHusslupIds = createSelector(
  selectUsersBlockedByMe,
  (usersBlockedByMe) =>
    usersBlockedByMe?.map((blockRow) => blockRow?.blocked_user?.husslupId),
);
