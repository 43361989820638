import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Channel, DefaultGenerics, FormatMessageResponse } from 'stream-chat';

import asyncSetMessageScreen from './thunk/asyncSetMessageScreen';

type MessageMode = 'default' | 'create-message' | 'bcc';

export type User = {
  id?: string;
  image?: string;
  fullName?: string;
  profession?: string;
};

export type ActiveChannelMessages = FormatMessageResponse<DefaultGenerics>[];
export interface BCCUsers {
  husslupId: string;
  image: string;
  fullName: string;
  memberId?: number | string | string[] | undefined;
}
export interface MessagesState {
  channelId: string | undefined;
  messageMode: MessageMode;
  createMessageUsers: User[];
  bccUsers: BCCUsers[];
  message: string;
  userId: string;
  loadEntireSection: boolean;
  loadActiveMessageSection: boolean;
  loadMessageDetailsSection: boolean;
  activeChannelMessages: ActiveChannelMessages;
  currentChannel?: Channel<DefaultGenerics>;
}

const initialState: MessagesState = {
  channelId: undefined,
  messageMode: 'default',
  loadEntireSection: true,
  bccUsers: [],
  createMessageUsers: [],
  activeChannelMessages: [],
  message: '',
  userId: '',
  currentChannel: undefined,
  loadActiveMessageSection: false,
  loadMessageDetailsSection: false
  
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setBccUsers: (state, action: PayloadAction<BCCUsers[]>) => {
      state.bccUsers = action.payload;
    },
    loadActiveMessageSection: (state, action: PayloadAction<boolean>) => {
      state.loadActiveMessageSection = action.payload
    },
    loadMessageDetailSection: (state, action: PayloadAction<boolean>) => {
      state.loadMessageDetailsSection = action.payload
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    addBccUser: (state, action: PayloadAction<BCCUsers>) => {
      const prevBccUsers = state.bccUsers;
      state.bccUsers = [...prevBccUsers, action.payload];
    },
    removeBccUser: (state, action: PayloadAction<{ husslupId: string }>) => {
      const prevBccUsers = state.bccUsers;
      state.bccUsers = [...prevBccUsers].filter(
        (bcc) => bcc.husslupId !== action.payload.husslupId,
      );
    },
    setMessageChannelId: (
      state,
      action: PayloadAction<{ channelId: string }>,
    ) => {
      state.messageMode = 'default';
      state.channelId = action.payload.channelId;
    },
    setMessageUserId: (state, action: PayloadAction<{ userId: string }>) => {
      state.userId = action.payload.userId;
    },
    setMessageModeCreateMessage: (state) => {
      state.channelId = undefined;
      state.messageMode = 'create-message';
    },
    setMessageModeBccMessage: (state) => {
      state.channelId = undefined;
      state.messageMode = 'bcc';
    },
    setMessageModeDefault: (state) => {
      state.messageMode = 'default';
      state.bccUsers = [];
    },
    setInitialState: (state) => {
      state.messageMode = 'default';
      state.channelId = undefined;
    },
    setCreateMessageUsers: (state, action: PayloadAction<User[]>) => {
      state.messageMode = 'create-message';
      state.createMessageUsers = action.payload;
    },
    clearCreateMessageState: (state) => {
      state.messageMode = 'default';
      state.createMessageUsers = [];
    },
    setActiveChannelMessages: (
      state,
      action: PayloadAction<ActiveChannelMessages>,
    ) => {
      state.activeChannelMessages = action.payload;
    },

    addActiveChannelMessage: (
      state,
      action: PayloadAction<ActiveChannelMessages>,
    ) => {
      const prevActiveChannelMessages = [...state.activeChannelMessages];
      state.activeChannelMessages = [
        ...prevActiveChannelMessages,
        ...action.payload,
      ];
    },

    setCurrentChannel: (
      state,
      action: PayloadAction<Channel<DefaultGenerics>>,
    ) => {
      state.currentChannel = action.payload;
    },
    clearCurrentChanel: (state) => {
      state.currentChannel = undefined;
      state.channelId = undefined;
      state.activeChannelMessages =[];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncSetMessageScreen.fulfilled, (state) => {
      state.loadEntireSection = false;
    });
    builder.addCase(asyncSetMessageScreen.pending, (state) => {
      state.loadEntireSection = true;
    });
    builder.addCase(asyncSetMessageScreen.rejected, (state) => {
      state.loadEntireSection = false;
    });
  },
});

export const {
  setMessageChannelId,
  setMessageModeCreateMessage,
  setMessageModeDefault,
  setInitialState,
  setMessageModeBccMessage,
  addBccUser,
  removeBccUser,
  setBccUsers,
  setMessage,
  setMessageUserId,
  setCreateMessageUsers,
  clearCreateMessageState,
  setActiveChannelMessages,
  addActiveChannelMessage,
  setCurrentChannel,
  clearCurrentChanel,
  loadActiveMessageSection,
  loadMessageDetailSection
} = messagesSlice.actions;
export const messagesReducer = messagesSlice.reducer;
