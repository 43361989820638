import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as routes from 'constants/routes';
import { FunctionComponent, MouseEvent, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { INavigation, useNavigation } from 'commons/context/navigation-context';
import { navLinks } from 'constants/navbar';
import { baseApiSlice } from 'commons/apis/base-api.config';
import { useAppDispatch } from 'commons/hooks/redux';
import { resetScrollPos } from 'commons/hooks/useScrollRestoration';
import {
  SearchStates,
  setFeedSearchResultCount,
  setGetPostWithTagParam,
  setSearchKeyword,
  setSearchState,
} from 'store/post/post.slice';
import { selectGetPostWithTagParams } from 'store/post/selectors';

const NavLink: FunctionComponent<{
  path: string;
  newTab?: boolean;
  active?: boolean;
  disableActiveState?: boolean;
  disabled?: boolean;
  wrapperClass?: string;
  shallow?: boolean;
  alias?: string;
  children?: ReactNode;
}> = ({
  path,
  active = false,
  newTab = false,
  children = undefined,
  disabled,
  wrapperClass = '',
  shallow = false,
  alias = path,
  disableActiveState = false,
}) => {
  const router = useRouter();
  const getPostWithTagParams = useSelector(selectGetPostWithTagParams);
  const { navState, updateNavigation } = useNavigation() as INavigation;
  const dispatch = useAppDispatch();
  const className = classNames(
    {
      'nav-link': true,
      active: !disableActiveState && (!!active || router.pathname === path),
      disabled,
    },
    wrapperClass,
  );
  const isInternalLink = path.startsWith('/');

  const resetFeed = () => {
    dispatch(setSearchKeyword(''));
    dispatch(setSearchState(SearchStates.Collapsed));
    dispatch(
      setGetPostWithTagParam({
        ...getPostWithTagParams,
        searchKeyword: '',
        page: 1,
        limit: 10,
      }),
    );
    dispatch(setFeedSearchResultCount(0));
  };

  const handleNavLinkClicked = (e: MouseEvent<HTMLAnchorElement>): void => {
    const isHome = path === routes.FEED;
    // Reset the cache behaviour of the RTK
    const resetCache = isHome || navLinks.some((nv) => nv.path === path);

    if (resetCache) {
      if (isHome) {
        resetFeed();
      }
      setTimeout(() => {
        dispatch(
          baseApiSlice.util.invalidateTags([
            isHome ? 'Home' : (path.replace('/', '') as any),
          ]),
        );
      }, 0);
      resetScrollPos(isHome ? routes.HOME : path);
    }

    if (
      router.pathname.includes(routes.PROFILE_EDIT) ||
      router.pathname.includes(routes.COMPANY_ADD) ||
      router.pathname.includes(routes.COMPANY_EDIT)
    ) {
      e.preventDefault();
      updateNavigation({
        ...navState,
        to: e.currentTarget.pathname,
      });
    } else {
      e.defaultPrevented = false;
    }
  };
  if (isInternalLink) {
    return (
      <Link
        className={className}
        href={path}
        as={alias}
        shallow={shallow}
        onClick={handleNavLinkClicked}
      >
        {children}
      </Link>
    );
  }
  if (newTab) {
    return (
      <Link
        href={path}
        className={className}
        target='_blank'
        rel='noopener noreferrer'
      >
        {children}
      </Link>
    );
  }
  return (
    <Link
      href={path}
      target='_self'
      as={alias}
      shallow={shallow}
      onClick={handleNavLinkClicked}
    >
      {children}
    </Link>
  );
};

export default NavLink;
