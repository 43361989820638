import NextImage from 'components/Core/Image';
import { MY_NETWORK_CONTACT_LISTS } from 'constants/routes';
import Link from 'next/link';
import { FunctionComponent } from 'react';
import timeSince from 'utils/date-time';
import ProfileUtils from 'utils/profile';

const ContactSheetOwnerReceivedActivity: FunctionComponent<{
  activity: any;
}> = ({ activity }) => (
  <li>
    <Link
      href={`${MY_NETWORK_CONTACT_LISTS}/${activity?.list_id}`}
      className='notification-item'
    >
      <div className='notification-face icon'>
        <NextImage
          width={56}
          height={56}
          src={
            ProfileUtils.getProfileImage(
              activity?.access_provider_detail?.profileImg,
              56,
              56,
            ) ?? ''
          }
          alt=''
        />
      </div>
      <div className='notification-content'>
        <p>{activity?.text}</p>
        <div className='timestamp'>{timeSince(activity?.mentioned_at)}</div>
      </div>
    </Link>
  </li>
);

export default ContactSheetOwnerReceivedActivity;
