import MuxPlayer from '@mux/mux-player-react';
import React from 'react';

import { MuxVideo } from 'commons/types/post.type';

type PostVideoPlayerProps = {
  muxVideo: MuxVideo;
};

export const PostVideoPlayer = ({ muxVideo }: PostVideoPlayerProps) => (
  <MuxPlayer
    streamType='on-demand'
    style={{ height: '100%', maxWidth: '100%' }}
    className='videoMob'
    onError={(e) => console.log(`Error playing video ${e}`)}
    playbackId={muxVideo.playbackId}
    metadata={{
      video_id: muxVideo.assetId,
      video_title: muxVideo?.uploadId,
    }}
  />
);

export default PostVideoPlayer;
