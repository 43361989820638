const getRecommendationTitle = (element: string, key: string) => {
  let title = '';

  switch (element) {
    case 'affiliations':
      title = `You both are members of ${key}`;
      break;

    case 'locations':
      title = `You both live in ${key}`;
      break;

    case 'schools':
      title = `You both attended ${key}`;
      break;

    case 'credits':
      title = `You both worked on ${key}`;
      break;
    default:
      break;
  }
  return title;
};

export default getRecommendationTitle;
