import { strapiApiSlice } from 'commons/apis/strapi-api';
import {
  BannerResponse,
  StrapiBannersResponse,
} from 'commons/types/strapi-content.type';
import { HTTP_METHODS } from 'constants/http';
import { STRAPI_API_FEED_BANNER } from 'constants/strapi-api';

export const strapiContentApiSlice = strapiApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getFeedBanner: builder.query<BannerResponse, void>({
        query: () => ({
          url: `${STRAPI_API_FEED_BANNER}`,
          method: HTTP_METHODS.GET,
          params: {
            populate: 'deep',
          },
        }),
        transformResponse(data: StrapiBannersResponse) {
          const bannerContent = data?.data[0]?.attributes?.content[0];
          const buttonContent = data?.data[0]?.attributes?.content[1];
          return { bannerContent, buttonContent } as BannerResponse;
        },
      }),
    };
  },
});

export const { useGetFeedBannerQuery } = strapiContentApiSlice;
