import { baseApiSlice } from 'commons/apis/base-api.config';
import { API_ASSESSMENT_SCRIPT } from 'constants/assignmentScript';
import { HTTP_METHODS } from 'constants/http';
import {
  GetPromoCodeUpdatesBody,
  GetPromoCodeUpdatesResponse,
  SubscriptionCreatePaymentBody,
} from 'store/subscription/subscription.types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'config';
import { RootState } from 'commons/types/redux';
import {
  AssessmentScriptBody,
  FSACreatePaymentBody,
  FSACreatePaymentResponse,
  GetFSAPriceBody,
  GetFSAPriceResponse,
  ListFSAPriceResponse,
} from './assessment-script.types';

export const assessmentScriptApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      createPaymentIntent: builder.mutation<
        FSACreatePaymentResponse,
        FSACreatePaymentBody
      >({
        query: (payload) => ({
          url: API_ASSESSMENT_SCRIPT,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
      }),

      createAssessmentScript: builder.mutation<any, AssessmentScriptBody>({
        query: (payload) => ({
          url: `${API_ASSESSMENT_SCRIPT}/createAssessmentScript`,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
      }),

      generateScriptAssessment: builder.mutation<
        any,
        SubscriptionCreatePaymentBody
      >({
        query: (payload) => ({
          url: `${API_ASSESSMENT_SCRIPT}/generateAssessment`,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
      }),

      getPromoCodeUpdates: builder.mutation<
        GetPromoCodeUpdatesResponse,
        GetPromoCodeUpdatesBody
      >({
        query: (payload) => ({
          url: `${API_ASSESSMENT_SCRIPT}/get-promo-code-updates`,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
      }),

      getFSAPrice: builder.query<GetFSAPriceResponse, GetFSAPriceBody>({
        query: (payload) =>
          `${API_ASSESSMENT_SCRIPT}/get-fsa-price/${payload.pageCount}`,
      }),

      listFSAPrices: builder.query<ListFSAPriceResponse, void>({
        query: () => `${API_ASSESSMENT_SCRIPT}/list-fsa-prices`,
      }),
    };
  },
});

export const {
  useGetFSAPriceQuery,
  useListFSAPricesQuery,
  useLazyGetFSAPriceQuery,
  useCreatePaymentIntentMutation,
  useGenerateScriptAssessmentMutation,
  useCreateAssessmentScriptMutation,
  useGetPromoCodeUpdatesMutation,
} = assessmentScriptApiSlice;

const prepareBaseQuery = fetchBaseQuery({
  baseUrl: config.apiBaseUrl,
  timeout: 300000,
  prepareHeaders(headers, { getState }) {
    const { token } = (getState() as RootState).auth;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const extendedScriptApiSlice = createApi({
  reducerPath: 'scriptAssessment',
  baseQuery: prepareBaseQuery,
  endpoints: () => ({}),
  tagTypes: [],
});

export const assessmentScriptApiExtendedSlice =
  extendedScriptApiSlice.injectEndpoints({
    endpoints(builder) {
      return {
        generateScriptAssessmentV2: builder.mutation<
          any,
          SubscriptionCreatePaymentBody
        >({
          query: (payload) => ({
            url: `${API_ASSESSMENT_SCRIPT}/generateAssessmentV2`,
            method: HTTP_METHODS.POST,
            body: payload,
            timeout: 300000,
          }),
        }),
      };
    },
  });

export const { useGenerateScriptAssessmentV2Mutation } =
  assessmentScriptApiExtendedSlice;
