import { string, object } from 'yup';
import * as errors from 'constants/errors';

const sendInviteSchema = object().shape({
  email: string()
    .trim()
    .email(errors.INVALID_EMAIL)
    .trim()
    .lowercase()
    .required(errors.EMAIL_IS_REQUIRED),
  firstName: string()
    .trim()
    .typeError('First Name is required')
    .required('First Name is required'),
  lastName: string()
    .trim()
    .typeError('Last Name is required')
    .required('Last Name is required'),
});

export default sendInviteSchema;
