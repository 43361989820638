import { Form } from 'react-bootstrap';
import React, { ChangeEvent, FocusEventHandler, useMemo, useRef } from 'react';

export type CodeInputProps = {
  /**
   * Optional label
   */
  labelText?: string;
  /**
   * Form error
   */
  error?: string;
  /**
   * State to store code values
   */
  inputValueState: string[];
  wrapperClassName?: string;
  id: string;
  codeLength?: number;
  setInputValueState: React.Dispatch<React.SetStateAction<string[]>>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
};

const CodeInput = ({
  id,
  labelText,
error,
  inputValueState,
  setInputValueState,
  wrapperClassName,
  codeLength = 6,
  disabled,
  onBlur,
}: CodeInputProps) => {
  const inputRef = useRef<HTMLInputElement[]>([]);
  const pins = useMemo(() => [...Array(codeLength).keys()], [codeLength]);

  const handleChange =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const pin = e.target.value;
      const array = inputValueState;
      const nextInput = inputRef.current[index + 1];
      const prevInput = inputRef.current[index - 1];

      if (
        inputValueState[index] === null ||
        inputValueState[index] === undefined
      ) {
        array.splice(index, 0, pin);
        setInputValueState([...array]);
        if (nextInput) {
          nextInput.focus();
        }
      } else if (inputValueState[index] === '') {
        array.splice(index, 1, pin);
        setInputValueState([...array]);
        if (nextInput) {
          nextInput.focus();
        }
      } else {
        array.splice(index, 1, '');
        setInputValueState([...array]);
        if (
          (!inputValueState[index - 1] ||
            inputValueState[index - 1] === undefined ||
            !inputValueState[index + 1] ||
            inputValueState[index + 1] === undefined) &&
          prevInput
        ) {
          prevInput.focus();
        }
      }
    };

  return (
    <Form.Group id={id} className={`form-group ${wrapperClassName}`}>
      {labelText ? <Form.Label>{labelText}</Form.Label> : null}
      <div className='form-code-input-group'>
        {pins.map((i) => (
          <Form.Control
            data-cy={`${id}-${i}`}
            className={`input ${inputValueState[i] ? 'filled' : ''}`}
            key={i}
            ref={(el: HTMLInputElement) => {
              if (inputRef.current) {
                inputRef.current[i] = el;
              } else {
                inputRef.current = [];
                inputRef.current[i] = el;
              }
            }}
            value={inputValueState[i]}
            maxLength={1}
            onChange={handleChange(i)}
            disabled={disabled}
            onBlur={onBlur}
          />
        ))}
        
      </div>
      {error && (
        <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default CodeInput;
