import React, { FunctionComponent, ReactElement } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropDirection } from 'react-bootstrap/esm/DropdownContext';
import { AlignType } from 'react-bootstrap/esm/types';
import HusslUpIcon from 'components/Icon';
import { useRouter } from 'next/router';
import Typography from '../StylesGuide/Typography';

export type ProfileViewedByType =
  | 'owner'
  | 'husslup-member'
  | 'non-husslup-member';

export interface KebabDropdownItem {
  onClick?: (key: string, profileId?: number, husslupId?: string) => void;
  label: string;
  url?: string;
  disabled?: boolean;
  className?: string;
}

interface KebabDropdownProps {
  dropdownItems: KebabDropdownItem[];
  toggleProps?: {
    width?: number;
    height: number;
  };
  drop?: DropDirection;
  align?: AlignType;
  profileId?: number;
  husslupId?: string;
  iconColorWhite?: boolean;
}

const KebabDropdown: FunctionComponent<KebabDropdownProps> = ({
  dropdownItems,
  toggleProps,
  profileId,
  husslupId,
  iconColorWhite = false,
  ...props
}): ReactElement => {
  const route = useRouter();
  return (
    <Dropdown {...props} align='end'>
      <Dropdown.Toggle as='div'>
        <HusslUpIcon
          name='more'
          wrapperClass={`profile-kebab-icon ${
            iconColorWhite ? 'text-white' : 'text-info-tex'
          }`}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {dropdownItems.map((dropdownItem: KebabDropdownItem, i: number) => (
          <Dropdown.Item
            onClick={() => {
              if (dropdownItem?.url?.length) {
                return route.push(dropdownItem?.url);
              }
              return dropdownItem?.onClick?.(
                dropdownItem?.label,
                profileId,
                husslupId,
              );
            }}
            disabled={dropdownItem?.disabled}
            key={dropdownItem?.url ?? i}
            className={`kebab-dropdown-item ${dropdownItem?.className ?? ''}`}
          >
            <Typography
              variant='p-sm-d'
              className={
                dropdownItem?.label === 'Block' ||
                dropdownItem?.label === 'Block User'
                  ? 'block-label'
                  : ''
              }
            >
              {dropdownItem?.label}
            </Typography>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default KebabDropdown;
