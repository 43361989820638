import {
  ProfileListDropdownType,
  ProfileListFieldMapping,
  ProfileListTables,
  SchoolSubType,
} from 'commons/types/data';

const ProfileListDropdownsBasic: ProfileListDropdownType[] = [
  {
    shortTitle: 'Professions',
    fieldName: ProfileListFieldMapping[ProfileListTables.Professions],
    listType: ProfileListTables.Professions,
    isMultiSelect: true,
  },
  {
    shortTitle: 'Projects',
    fieldName: ProfileListFieldMapping[ProfileListTables.ProjectTypes],
    listType: ProfileListTables.ProjectTypes,
    isMultiSelect: true,
  },
  {
    shortTitle: 'Genres',
    fieldName: ProfileListFieldMapping[ProfileListTables.Genres],
    listType: ProfileListTables.Genres,
    isMultiSelect: true,
  },
];

const ProfileListDropdownsAdditional = [
  {
    shortTitle: 'Pronouns',
    fieldName: ProfileListFieldMapping[ProfileListTables.Pronouns],
    listType: ProfileListTables.Pronouns,
    isMultiSelect: true,
  },
  {
    shortTitle: 'Affiliations',
    fieldName: ProfileListFieldMapping[ProfileListTables.Affiliations],
    listType: ProfileListTables.Affiliations,
    isMultiSelect: true,
  },
  {
    shortTitle: 'Undergrad School',
    fieldName: ProfileListFieldMapping[ProfileListTables.Schools],
    listType: ProfileListTables.Schools,
    listSubType: SchoolSubType.UNDERGRAD,
    isMultiSelect: true,
  },
  {
    shortTitle: 'Undergrad Degree',
    fieldName: ProfileListFieldMapping[ProfileListTables.Degrees],
    listType: ProfileListTables.Degrees,
    listSubType: SchoolSubType.UNDERGRAD,
    isMultiSelect: true,
  },
  {
    shortTitle: 'Grad School',
    fieldName: ProfileListFieldMapping[ProfileListTables.Schools],
    listType: ProfileListTables.Schools,
    listSubType: SchoolSubType.GRADUATE,
    isMultiSelect: true,
  },
  {
    shortTitle: 'Grad Degree',
    fieldName: ProfileListFieldMapping[ProfileListTables.Degrees],
    listType: ProfileListTables.Degrees,
    listSubType: SchoolSubType.GRADUATE,
    isMultiSelect: true,
  },
  {
    shortTitle: 'Locations',
    hint: 'Only select cities where you can work locally',
    searchPlaceholderText: 'Locations',
    fieldName: ProfileListFieldMapping[ProfileListTables.Locations],
    listType: ProfileListTables.Locations,
    isMultiSelect: true,
    canWriteIn: true,
  },
  {
    shortTitle: 'Passports',
    hint: 'Only select countries where you can work legally',
    fieldName:
      ProfileListFieldMapping[ProfileListTables.InternationalPassports],
    listType: ProfileListTables.InternationalPassports,
    isMultiSelect: true,
    needDemographicPermission: false,
  },
  {
    shortTitle: 'Fluent Languages',
    fieldName: ProfileListFieldMapping[ProfileListTables.SecondLanguage],
    listType: ProfileListTables.SecondLanguage,
    isMultiSelect: true,
    needDemographicPermission: false,
  },
  {
    shortTitle: 'Other Skills',
    fieldName: ProfileListFieldMapping[ProfileListTables.NonIndustryExperience],
    listType: ProfileListTables.NonIndustryExperience,
    isMultiSelect: true,
  },
  {
    shortTitle: 'Demographics',
    fieldName: ProfileListFieldMapping[ProfileListTables.Demographics],
    listType: ProfileListTables.Demographics,
    menuPlacement: 'top',
    isMultiSelect: true,
    needDemographicPermission: true,
  },
];

const ProfileListDropdownsDemographics = [
  {
    shortTitle: 'Demographics',
    fieldName: ProfileListFieldMapping[ProfileListTables.Demographics],
    listType: ProfileListTables.Demographics,
    menuPlacement: 'top',
    isMultiSelect: true,
    needDemographicPermission: true,
  },
];

const ProfileListPreviewAdditional = [
  {
    shortTitle: 'Affiliations',
    fieldName: ProfileListFieldMapping[ProfileListTables.Affiliations],
    listType: ProfileListTables.Affiliations,
    alt: 'affiliation',
  },
  {
    shortTitle: 'Education',
    fieldName: ProfileListFieldMapping[ProfileListTables.Schools],
    listType: ProfileListTables.Schools,
    alt: 'education',
  },
  {
    shortTitle: 'Location',
    fieldName: ProfileListFieldMapping[ProfileListTables.Locations],
    listType: ProfileListTables.Locations,
    alt: 'location',
  },
  {
    shortTitle: 'Other Skills',
    alt: 'other-skill',
    fieldName: ProfileListFieldMapping[ProfileListTables.NonIndustryExperience],
    listType: ProfileListTables.NonIndustryExperience,
  },
  {
    shortTitle: 'Passports',
    alt: 'passport',
    fieldName:
      ProfileListFieldMapping[ProfileListTables.InternationalPassports],
    listType: ProfileListTables.InternationalPassports,
  },
  {
    shortTitle: 'Language',
    alt: 'language',
    fieldName: ProfileListFieldMapping[ProfileListTables.SecondLanguage],
    listType: ProfileListTables.SecondLanguage,
  },
  {
    shortTitle: 'Demographics',
    alt: 'demographic',
    fieldName: ProfileListFieldMapping[ProfileListTables.Demographics],
    listType: ProfileListTables.Demographics,
  },
];

const ProfileListDropdownsAll = [
  ...ProfileListDropdownsBasic,
  ...ProfileListDropdownsAdditional,
];

export {
  ProfileListDropdownsAll,
  ProfileListDropdownsAdditional,
  ProfileListDropdownsBasic,
  ProfileListPreviewAdditional,
  ProfileListDropdownsDemographics,
};
