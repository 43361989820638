import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as routes from 'constants/routes';
import config from 'config';
import {
  REAL_SCREEN_AUTO_APPROVE_INITIALIZED,
  REAL_SCREEN_AUTO_APPROVE_STORAGE_KEY,
} from 'constants/real-screen';
import {
  STRAPI_MOBILE_COMMUNITY_PAGE,
  STRAPI_MOBILE_PRIVACY_PAGE,
  STRAPI_MOBILE_TERMS_PAGE,
} from 'constants/strapi-urls';

type OSTYPE = 'ANDROID' | 'IOS' | 'OTHER';

const mobilePages = [
  STRAPI_MOBILE_COMMUNITY_PAGE,
  STRAPI_MOBILE_PRIVACY_PAGE,
  STRAPI_MOBILE_TERMS_PAGE,
];

const useWebBlockGuard = () => {
  const [osType] = useState<OSTYPE>('OTHER');
  const [isMobile] = useState<boolean>(false);
  const router = useRouter();
  const whiteListstedUrls = config.webWhiteListUrls?.split(',') || [];
  const hasWorked = useRef(false);

  const isWhiteListRoute = useMemo(
    () =>
      [...whiteListstedUrls, ...mobilePages].some((url) => {
        if (url.includes('/[id]') || url.includes('/[...slug]')) {
          return router.pathname === url;
        // eslint-disable-next-line no-else-return
        } else {
          return router.asPath === url;
        }
      }),
    [osType, router.asPath],
  );

  const redirectToAppDownload = () => {
    // if (!isWhiteListRoute && isMobile) {
    router.push(routes.MOBILE);
    // }
  };

  // const detectMobileDevice = () => {
  //   const navigatorAPI: Navigator = navigator;
  //   const userAgent: string = navigatorAPI.userAgent;

  //   // user agent should have mobile agent instead of os type agent
  //   if (/Mobile|mobile/i.test(navigator.userAgent)) {
  //     setIsMobile(true);
  //   }

  //   if (/android/i.test(userAgent)) {
  //     setOsType('ANDROID');
  //     return;
  //   }
  //   if (/iPhone|iPod/.test(userAgent)) {
  //     setOsType('IOS');
  //   }
  // };

  const getAccessor = () => {
    const navigatorAPI: Navigator = navigator;
    // eslint-disable-next-line prefer-destructuring
    const userAgent: string = navigatorAPI.userAgent;

    let isMobile = false;
    // user agent should have mobile agent instead of os type agent
    if (/Mobile|mobile/i.test(navigator.userAgent)) {
      isMobile = true;
    }

    if (/android/i.test(userAgent)) {
      return { isMobile, osType: 'ANDROID' };
    }
    if (/iPhone|iPod/.test(userAgent)) {
      return { isMobile, osType: 'IOS' };
    }
    return { isMobile, osType: 'OTHER' };
  };

  // useEffect(() => {
  //   detectMobileDevice();
  // }, []);

  useEffect(() => {
    const { isMobile, osType } = getAccessor();
    // console.log(
    //   'logging -- in ',
    //   'osType,',
    //   osType,
    //   'router.pathname',
    //   router.pathname,
    //   router.asPath,
    //   { hasWorked: hasWorked.current },
    // );
    if (
      !hasWorked.current ||
      router.asPath === routes.REAL_SCREEN ||
      !isWhiteListRoute
    ) {
      if (
        (osType === 'IOS' || osType === 'ANDROID') &&
        router.asPath !== routes.REAL_SCREEN
      ) {
        if (!isWhiteListRoute && isMobile) {
          redirectToAppDownload();
        }
      } else if (!isMobile && router.asPath === routes.REAL_SCREEN) {
        if (router.asPath === routes.REAL_SCREEN) {
          localStorage.setItem(
            REAL_SCREEN_AUTO_APPROVE_STORAGE_KEY,
            REAL_SCREEN_AUTO_APPROVE_INITIALIZED,
          );
          router.push(routes.SIGNUP);
        }
      }
    }
    hasWorked.current = true;
    // if (osType === 'IOS' || osType === 'ANDROID') {
    //   redirectToAppDownload();
    // }
  }, [router.pathname, router.asPath]);

  return {
    platform: osType,
    isMobile,
  };
};

export default useWebBlockGuard;
