import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { PostTag } from 'commons/types/post.type';
import Slider, { Settings } from 'react-slick';

const CreatePostTagSelector: FunctionComponent<{
  tags?: PostTag[];
  selectedTag?: PostTag;
  onTagSelected: (tag: PostTag) => void;
}> = ({ tags, onTagSelected, selectedTag }) => {
  const selectableTags = useMemo(() => tags?.filter((tag) => tag.id), [tags]);
  const sliderRef = useRef<Slider>(null);
  const sliderHolderRef = useRef<HTMLDivElement>(null);

  const settings: Settings = {
    infinite: false,
    speed: 300,
    variableWidth: true,
    swipeToSlide: true,
    slidesToShow: 5,
    swipe: true,
    // focusOnSelect: true,
    arrows: false,
  };

  useEffect(() => {
    if (!!sliderRef.current && !!sliderHolderRef.current) {
      const slickSliderHolder = sliderHolderRef.current;
      const slickSlider = sliderRef.current;
      const slickList = slickSliderHolder.querySelector('.slick-list');

      slickList?.addEventListener('wheel', (event: any) => {
        const currentSlideIndex = Number(
          slickList
            ?.querySelector('.slick-active.slick-current')
            ?.getAttribute('data-index') || 0,
        );
        event.preventDefault();
        if (event.deltaX > 0) {
          slickSlider.slickGoTo(currentSlideIndex + 4);
          return;
        }
        if (event.deltaX < 0) {
          slickSlider.slickGoTo(currentSlideIndex - 4);
          // eslint-disable-next-line no-useless-return
          return;
        }
      });
    }
  }, [sliderRef.current]);

  return (
    <>
      <small>Tap to tag post</small>
      <button
        className={`btn btn-text-only ${selectedTag && 'active'}`}
        type='button'
      >
        <i className='hic-tag-plus hic-2x' />
      </button>
      <div className='scrollbox'>
        <div className='misc-holder__tag-list' ref={sliderHolderRef}>
          <Slider {...settings} className='tag-slider' ref={sliderRef}>
            {selectableTags &&
              selectableTags.map((tag: PostTag) => (
                <>
                  <div
                    key={tag.id}
                    className={`post-tag pointer ${
                      selectedTag?.id === tag?.id ? 'active' : ''
                    }`}
                    aria-hidden='true'
                    onClick={() => {
                      onTagSelected(tag);
                    }}
                  >
                    <div className='post-tag__name'> {tag.tag}</div>
                  </div>
                  {/* {index < array.length - 1 ? (
                    <div className='divider' />
                  ) : null} */}
                </>
              ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default CreatePostTagSelector;
