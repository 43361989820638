import React from 'react';
import Typography from 'components/Core/StylesGuide/Typography';
import HusslUpIcon from 'components/Icon';
import useVisibility from 'commons/hooks/useVisibility';
import PdfViewerDialog from 'components/Dialog/PdfViewerDialog';

type PdfAttachmentProps = {
  url: string;
  fileName: string;
};

const PdfAttachment = ({ fileName, url }: PdfAttachmentProps) => {
  const { show, hide, isVisible } = useVisibility();
  return (
    <>
      <div
        className='pdf-block ml-1'
        onClick={(e) => {
          show();
          e.stopPropagation();
        }}
      >
        <div className="icon">
          <HusslUpIcon
            name='pdf-fill'
            variant='3x'
            wrapperClass='text-primary'
          />
        </div>
        <div>
          <Typography variant='p-md-sb'>{fileName}</Typography>
        </div>
      </div>

      <PdfViewerDialog
        show={isVisible}
        onClose={hide}
        title={fileName}
        url={url}
      />
    </>
  );
};

export default PdfAttachment;
