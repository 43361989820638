import { CookieConsent as UniversalCookieConsent } from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { APP_COOKIE_NAME, APP_COOKIE_EXPIRY_IN_DAYS } from 'constants/app';
import React, { FunctionComponent } from 'react';

const timezoneOffset = new Date().getTimezoneOffset();

const CookieConsent: FunctionComponent = () => {
  const { t, i18n } = useTranslation('common');

  return (
    <UniversalCookieConsent
      buttonText={t('accept_All')}
      declineButtonText={t('decline_all')}
      cookieName={APP_COOKIE_NAME}
      expires={APP_COOKIE_EXPIRY_IN_DAYS}
      enableDeclineButton
      flipButtons
      contentClasses="custom-cookie"
      cookieValue={{
        lang: i18n.language,
        timezone: timezoneOffset,
      }}
    >
      {t('cookie_msg')}
    </UniversalCookieConsent>
  );
};

export default CookieConsent;
