/* eslint-disable import/prefer-default-export */
import config from 'config';
import moment from 'moment';
import {
  PaymentPlan,
  PlanFrequencyType,
  PlanType,
  SubscriptionStatus,
} from './subscription.types';

export const getPriceId = (plan: PaymentPlan) => {
  switch (plan.toLowerCase()) {
    case PaymentPlan.INDIVIDUAL_MONTHLY.toString().toLowerCase():
      return config.stripe.individualPlanMonthlyPriceId;
    case PaymentPlan.INDIVIDUAL_ANNUALLY.toString().toLowerCase():
      return config.stripe.individualPlanAnnuallyPriceId;
    case PaymentPlan.COMPANY_MONTHLY.toString().toLowerCase():
      return config.stripe.companyPlanMonthlyPriceId;
    case PaymentPlan.COMPANY_ANNUALLY.toString().toLowerCase():
      return config.stripe.companyPlanAnnuallyPriceId;
    case PaymentPlan.STUDIO_MONTHLY.toString().toLowerCase():
      /** @TODO update */
      return config.stripe.companyPlanMonthlyPriceId;
    case PaymentPlan.STUDIO_ANNUALLY.toString().toLowerCase():
      /** @TODO update */
      return config.stripe.companyPlanAnnuallyPriceId;
    default:
      return null;
  }
};

export const getPlanTypeDetail = (planType: PlanType) => {
  switch (planType) {
    case 'individual': {
      return {
        badgeClassname: 'badge-owner',
        title: 'Individual',
        priceTextMonthly: '$34.99/month',
        priceTextYearly: '$377.90/year (10% discount)',
        trialText: '14-Day Free Trial',
        suitableFor: 'Best for independent producers',
        features: [
          '1 seat',
          'Unlimited new ProSearches/month',
          'Unlimited new Staffing Rosters/month',
          'Share ProSearches and Staffing Rosters with other subscribers',
          'Track messaging to candidates',
          'White glove onboarding',
        ],
      };
    }
    case 'company': {
      return {
        badgeClassname: 'badge-subscription',
        title: 'Company',
        priceTextMonthly: '$89.99/month ($5/seat discount)',
        priceTextYearly: '$971.90/year (10% discount)',
        trialText: '14-Day Free Trial',
        suitableFor: 'Best for small production companies',
        features: [
          '3 seats',
          'Unlimited new ProSearches/month',
          'Unlimited new Staffing Rosters/month',
          'Share ProSearches and Staffing Rosters with other subscribers',
          'Track messaging to candidates',
          'White glove onboarding',
          'Limited access to Demographic Reporting',
        ],
      };
    }
    case 'studio': {
      return {
        badgeClassname: 'badge-studio',
        title: 'Studio',
        priceTextMonthly:
          'Book a consult to build a custom plan based on your staffing needs',
        suitableFor: 'Best for a studios or distributors',
        features: [
          'Access to Demographic Reporting',
          'Discounted Pricing for additional Seats',
          'Ability to transfer ownership of subscription to another seat',
        ],
      };
    }
    default: {
      return {
        badgeClassname: 'badge-owner',
        title: 'Individual',
        priceTextMonthly: '$34.99/month',
        priceTextYearly: '$377.90/year (10% discount)',
        trialText: '14-Day Free Trial',
        suitableFor: 'Best for an independent producer',
        features: [
          '1 seat',
          'Unlimited new ProSearches/month',
          'Unlimited new Staffing Rosters/month',
          'Share ProSearches and Staffing Rosters with other subscribers',
          'Track messaging to candidates',
          'White glove onboarding',
        ],
      };
    }
  }
};

export const getPlanInterval = (paymentPlan: PaymentPlan) => {
  const split = paymentPlan?.split('_');
  if (split?.length) {
    return {
      planType: split[0].toLowerCase(),
      planInterval:
        split[1]?.toLowerCase() === 'MONTHLY'?.toLowerCase() ? 'month' : 'year',
      planFrequency:
        split[1]?.toLowerCase() === 'MONTHLY'?.toLowerCase()
          ? 'monthly'
          : ('annually' as PlanFrequencyType),
    };
  }
  return {
    planType: '',
    planInterval: '',
    planFrequency: '',
  };
};

export const getDateFromStripeDateNumber = (stripeDate: number) =>
  new Date((stripeDate || 0) * 1000);

export const get14DayEnd = () =>
  moment(new Date()).add(14, 'days').format('M/D/YY');

export const ValidSubscriptionStatus = [
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.PRE_CANCELLED,
];

export const PaymentSubscriptionStatus = [
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.PRE_CANCELLED,
  SubscriptionStatus.CANCELLED,
  SubscriptionStatus.FAILED_CHARGED,
];

export const isSubscriptionStatusValid = (status: string) =>
  status && ValidSubscriptionStatus.includes(status as SubscriptionStatus);

export const isSubscriptionStatusVisibleForPayment = (status: string) =>
  status && PaymentSubscriptionStatus.includes(status as SubscriptionStatus);
