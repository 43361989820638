import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Modal, ListGroup, Spinner } from 'react-bootstrap';
import SearchInput from 'components/Core/Search/SearchInput';
import HusslUpIcon from 'components/Icon';
import { MemberChicklet } from 'components/Chicklet';
import { SubscriptionItem } from 'components/Subscription/SubscriptionItem';
import { useLazyGetContactQuery } from 'store/contact/contact.api';
import { MemberCardModalType } from 'utils/basic-faceted-modal.enum';
import { DEBOUNCE_SECONDS } from 'constants/commons';
import { debounce } from 'debounce';
import { useLazyGetProSearchListWithUserQuery } from 'store/prosearch/prosearch.api';
import { LISTS_MEMBER_ADD, PS_MEMBER_SAVED } from 'constants/gt-classes';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/auth/selectors';
import Button, { ButtonVariants } from '../Button';
import Typography from '../StylesGuide/Typography';
import NotesCreateModel from './notesCreateModel';
import * as gtag from '../../../utils/gtag';

export interface AddToModalProps {
  show: boolean;
  title: string;
  id: number;
  onClose: (event: string) => void;
}

const AddToModel: FunctionComponent<AddToModalProps> = ({
  title,
  show,
  id,
  onClose,
}): ReactElement => {
  const [getContact, contactResponse] = useLazyGetContactQuery();
  const [getProSearchListWithMember, proSearchResponse] =
    useLazyGetProSearchListWithUserQuery();

  const user = useSelector(selectCurrentUser);

  const [showNoteComponent, setShowNoteComponent] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [userListId, setUserListId] = useState({
    listId: 0,
    memberId: 0,
  });

  const [listId, setListId] = useState(0);
  const [buttonState, setButtonState] = useState(true);
  const [modalInfo, setModalInfo] = useState({
    placeHolder: '',
    buttonLevel: '',
  });
  const [noListMessage, setNoListMessage] = useState('');

  const ownerSubscriptionIds = user?.userSubscriptions
    ?.filter((e: any) => e.owner.id === user.id)
    ?.map((e: any) => e.id) || [];

  const dataResponse: any =
    title === MemberCardModalType.ADD_TO_CONTACT_TITLE
      ? contactResponse
      : proSearchResponse;

  useEffect(() => {
    setListId(0);
    setSearchValue('');
    setButtonState(true);
    if (title === MemberCardModalType.ADD_TO_CONTACT_TITLE) {
      setModalInfo({
        ...modalInfo,
        placeHolder: 'Search Contact List ...',
        buttonLevel: 'Add to Contact List',
      });

      getContact({ userId: id }).then((response) => {
        if (response.data && !response.data.length) {
          setNoListMessage('You have not created any Contact List');
        } else {
          setNoListMessage('');
        }
      });
    }
    if (title === MemberCardModalType.ADD_TO_PROSEARCH_TITLE) {
      setModalInfo({
        ...modalInfo,
        placeHolder: 'Search ProSearch ...',
        buttonLevel: 'Add to ProSearch',
      });

      getProSearchListWithMember({ userId: id }).then(
        (response: { data: string | any[] }) => {
          if (response.data && !response.data.length) {
            setNoListMessage('You have not created any ProSearch.');
          } else {
            setNoListMessage('');
          }
        },
      );
    }
  }, [id, title]);

  const buttonStateHandler = () => {
    onClose('Add to modal');
    setButtonState(true);
    setListId(0);
  };

  const listOnClickHandler = (listId: number, memberId: number) => {
    setUserListId({ ...userListId, listId, memberId });
    setListId(listId);
    setButtonState(false);
  };

  const addToModalButtonHandler = () => {
    if (title === MemberCardModalType.ADD_TO_CONTACT_TITLE) {
      gtag.event({
        label: 'Lists Member Add',
        category: 'Lists Member',
        action: 'LISTS_MEMBER_ADD',
      });
    } else if (title === MemberCardModalType.ADD_TO_PROSEARCH_TITLE) {
      gtag.event({
        label: 'Add member on prosearch click',
        category: 'Prosearch',
        action: 'PS_MEMBER_SAVED',
      });
    }

    setListId(0);
    onClose('addToModal');
    setShowNoteComponent(true);
    setButtonState(true);
  };

  const searchHandler = (ev: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(ev.target.value);
    if (title === MemberCardModalType.ADD_TO_PROSEARCH_TITLE) {
      debounceProSearchListSearchText(ev.target.value);
    }

    debounceContactListSearchText(ev.target.value);
  };

  const debounceContactListSearchText = useCallback(
    debounce((text: string) => {
      if (text.length > 2) {
        getContact({
          userId: userListId.memberId,
          searchText: text,
        }).then((response) => {
          if (response.data && !response.data.length) {
            setNoListMessage('You do not have searched Contact List');
          } else {
            setNoListMessage('');
          }
        });
      } else {
        getContact({ userId: id });
      }
    }, DEBOUNCE_SECONDS),
    [],
  );

  const debounceProSearchListSearchText = useCallback(
    debounce((text: string) => {
      if (text.length > 2) {
        getProSearchListWithMember({
          userId: userListId.memberId,
          searchText: text,
        }).then((response: { data: string | any[] }) => {
          if (response.data && response.data.length < 1) {
            setNoListMessage('You do not have searched ProSearch List');
          } else {
            setNoListMessage('');
          }
        });
      } else {
        getProSearchListWithMember({ userId: id });
      }
    }, DEBOUNCE_SECONDS),
    [],
  );

  const noteSuccess = () => {
    setShowNoteComponent(false);
    onClose('addToNote');
  };

  const onHide = () => {
    setListId(0);
    setButtonState(true);
    onClose('addToModal');
  };

  return (
    <>
      <NotesCreateModel
        show={showNoteComponent}
        onClose={() => setShowNoteComponent(false)}
        userId={userListId.memberId}
        listId={userListId.listId}
        title={title}
        onSuccess={noteSuccess}
        memberId={id}
      />
      <Modal
        show={show}
        onHide={onHide}
        className='report-modal report'
        size='lg'
      >
        <Modal.Header className='d-flex justify-content-between'>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Typography variant='p-md' className='mb-1 mt-4px'>
          You can make a single selection.
        </Typography>
        <SearchInput
          value={searchValue}
          placeholder={modalInfo.placeHolder}
          onSearch={searchHandler}
        />
        {dataResponse.isFetching && (
          <div className='spinner-wrapper'>
            <Spinner animation='border' />
          </div>
        )}

        {!dataResponse.isFetching && (
          <Modal.Body>
            {dataResponse?.data?.length > 0 ? (
              <ListGroup className=' report-list pro-search'>
                {dataResponse.data.map((d: any) => (
                  <ListGroup.Item
                    key={d?.id}
                    disabled={!!d.isAdded}
                    onClick={() => listOnClickHandler(d.id, id)}
                    className={listId === d.id ? 'selected pl-1' : 'pl-1'}
                  >
                    <div>
                      <div className='d-flex align-items-center'>
                        <Typography variant='p-md-sb' className='d-inline'>
                          {d.title}
                        </Typography>
                        {d.isShared ? (
                          <HusslUpIcon
                            name='unlock'
                            wrapperClass='fs-20 ml-8px d-block'
                          />
                        ) : (
                          <HusslUpIcon
                            name='lock'
                            wrapperClass='fs-20 ml-8px d-block'
                          />
                        )}
                        {d.isAdded && (
                          <MemberChicklet
                            labelText='added'
                            wrapperClass='ml-8px'
                          />
                        )}
                      </div>
                      <Typography variant='s-d' className='mtx-2px d-block'>
                        {d.desc}
                      </Typography>
                    </div>
                    {title === MemberCardModalType.ADD_TO_PROSEARCH_TITLE && (
                      <SubscriptionItem
                        subscription={d?.subscription}
                        divStyle={{ width: '250px' }}
                        nameTypography='p-sm-sb'
                        isOwner={ownerSubscriptionIds.includes(
                          d.subscriptionId,
                        )}
                      />
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <Typography variant='p-lg-b' className='spinner-wrapper'>
                {noListMessage}
              </Typography>
            )}
          </Modal.Body>
        )}

        <Modal.Footer>
          <div className='mt-2 d-flex'>
            <Button
              variant={ButtonVariants.secondary}
              label='Cancel'
              onClick={buttonStateHandler}
            />
            <Button
              variant={ButtonVariants.primary}
              label={modalInfo.buttonLevel}
              className={
                title === MemberCardModalType.ADD_TO_CONTACT_TITLE
                  ? LISTS_MEMBER_ADD
                  : title === MemberCardModalType.ADD_TO_PROSEARCH_TITLE
                  ? PS_MEMBER_SAVED
                  : ''
              }
              disabled={!!buttonState || dataResponse?.data?.length < 1}
              onClick={addToModalButtonHandler}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddToModel;
