export const APP_NAME = 'husslup';

export const APP_ENVIRONMENT_PRODUCTION = 'production';
export const APP_ENVIRONMENT_DEVELOPMENT = 'development';

export enum LANGUAGES {
  english = 'en-US',
  spanish = 'es',
}

export const APP_COOKIE_NAME = 'husslup';
export const APP_COOKIE_EXPIRY_IN_DAYS = 24;

export const APP_ISR_REVALIDATE_SECONDS = 5;

// vouched
export const VOUCH_TITLE_MAX_LENGTH = 163;
export const VOUCH_BY_MAX_LENGTH = 22;
export const VOUCHES_PROFESSIONAL_TAGLINE_MAX_LENGTH = 38;
export const VOUCHES_PROJECT_TAGLINE_MAX_LENGTH = 38;

// Attachment
export const ATTACHMENT_DESCRIPTION_INDEX_FIRST_MAX_LENGTH = 17;
export const ATTACHMENT_DESCRIPTION_INDEX_SECOND_MAX_LENGTH = 49;
export const DEFAULT_ATTACHMENT_DESCRIPTION_MAX_LENGTH = 94;

// Credit + Profile
export const CREDIT_PROJECT_TITLE_MAX_LENGTH = 41;
export const CREDIT_MAX_LENGTH = 41;
export const CREDIT_SEASONS_MAX_LENGTH = 41;
export const CREDIT_NETWORKS_MAX_LENGTH = 41;
export const USER_FULL_NAME_MAX_LENGTH = 19;
export const USER_PROFESSIONAL_MAX_LENGTH = 54;
export const USER_PROJECT_TAGLINE_MAX_LENGTH = 60;
export const USER_FEATURED_CREDIT_CREDIT_MAX_LENGTH = 18;
export const USER_FEATURED_CREDIT_PROJECT_TITLE_MAX_LENGTH = 44;

// contact + prosearch card list
export const STAFFING_CARD_HEADING_MAX_LENGTH = 32;
export const STAFFING_CARD_SUB_HEADING_MAX_LENGTH = 51;
export const STAFFING_CARD_TITLE_MAX_LENGTH = 44;

// MemberCard + Company
export const MEMBERCARD_MEMBER_NAME_MAX_LENGTH = 19;
export const COMPANY_CARD_HEADING_MAX_LENGTH = 41;
export const MEMBER_CARD_HEADING_MAX_LENGTH = 29;

// POST
export const POST_CREATOR_FULL_NAME_MAX_LENGTH = 50;
export const POST_CREATOR_PRO_HEADING_MAX_LENGTH = 36;
export const POST_CREATOR_PROJECT_TAGLINE_MAX_LENGTH = 36;

// Staffing Card
export const PROSEARCH_HEADING_MAX_LENGTH = 16;
export const PROSEARCH_DESCRIPTION_MAX_LENGTH = 18;

// Press
export const PRESS_TITLE_MAX_LENGTH = 68;
export const PRESS_LINK_MAX_LENGTH = 19;

// Network
export const NETWORK_FULL_NAME_MAX_LENGTH = 19;
export const NETWORK_PROFESSIONAL_TAGLINE_MAX_LENGTH = 25;
export const NETWORK_PROJECT_TAGLINE_MAX_LENGTH = 25;

// Creative Portpolio
export const CREATIVE_CARD_TITLE_MAX_LENGTH = 36;

// Resume
export const RESUME_EDIT_PROFILE_DESCRIPTION_MAX_LENGTH = 438;
export const RESUME_PROFILE_DESCRIPTION_MAX_LENGTH = 286;
export const RESUME_PROFILE_DESCRIPTION_MAX_LINES = 3;

export const RESUME_POSITION_MAX_LENGTH = 160;
export const RESUME_COMPANY_MAX_LENGTH = 150;
export const RESUME_LOCATION_MAX_LENGTH = 140;

// Recommendation
export const RECOMMENDATION_TEXT = 55;
export const RECOMMENDATION_MEMBERCARD_NAME = 18;
