import { useTranslation } from 'react-i18next';
import { SendConnectionResponse } from 'commons/types/network.type';
import { ActionButtons } from 'components/Cards';
import { USER_ACCEPTED_A_CONNECTION } from 'constants/gt-classes';
import { useState } from 'react';
import { useSendConnectionMutation } from 'store/discover/discover.api';
import {
  useAcceptConnectionMutation,
  useRemoveConnectionMutation,
} from 'store/my-network/update-connection.api';
import { ConnectionStatus } from 'store/user/types';
import ToasterUtils from 'utils/toaster';
import useVisibility from '../useVisibility';
import { RemoveConnectionState } from './useConnectionActionDropdown';

export const useGenericConnectionActionButtons = () => {
  const { t } = useTranslation('connection');
  const { t: myNetworkT } = useTranslation('my_network');
  const [removeConnectionUser, setRemoveConnectionUser] =
    useState<RemoveConnectionState>();

  const {
    show: showRemoveConnection,
    hide: hideRemoveConnection,
    isVisible: isRemoveConnectionVisible,
  } = useVisibility();

  const [acceptConnection, { isLoading: isAcceptingConnection }] =
    useAcceptConnectionMutation();

  const [
    removeConnection,
    { originalArgs: removeConnectionArgs, isLoading: isRemovingConnection },
  ] = useRemoveConnectionMutation();

  const [
    sendConnection,
    { originalArgs: sendConnectionArgs, isLoading: isSendingConnection },
  ] = useSendConnectionMutation();

  const initiateDeleteConnection = (
    removeConnectionState: RemoveConnectionState,
  ) => {
    setRemoveConnectionUser(removeConnectionState);
    showRemoveConnection();
  };

  const getRemoveConnectionToast = () => {
    if (isConnectionRequested()) {
      return {
        success: t('connection_request_declined'),
        failure: t('connection_request_declined_failed'),
      };
    }
    return {
      success:
        removeConnectionUser?.toastSuccessMessage || t('connection_removed'),
      faliure:
        removeConnectionUser?.toastFailureMessage ||
        t('connection_removed_failed'),
    };
  };

  const handleRemoveConnection = (husslupId?: string) => async () => {
    if (husslupId) {
      const response = await removeConnection({
        husslupId,
      }).unwrap();
      const { success, failure } = getRemoveConnectionToast();
      if (response.affected > 0) {
        ToasterUtils.successToast(success, {
          hideCloseButton: true,
        });
        hideRemoveConnection();
      } else {
        ToasterUtils.errorToast(failure ?? '');
      }
    }
  };

  const handleAcceptConnection = (husslupId?: string) => async () => {
    if (husslupId) {
      const response = await acceptConnection({
        connectionHusslupId: husslupId,
      }).unwrap();
      if (response.affected > 0) {
        ToasterUtils.successToast(t('connection_request_accepted'), {
          hideCloseButton: true,
        });
        hideRemoveConnection();
      } else {
        ToasterUtils.errorToast(t('connection_request_accepted_failed'));
      }
    }
  };

  const handleSendConnection = (husslupId?: string) => async () => {
    if (husslupId) {
      const response = (await sendConnection({
        connectionHusslupId: husslupId,
      }).unwrap()) as SendConnectionResponse;
      if (response?.generatedMaps?.length > 0) {
        ToasterUtils.successToast(t('connection_request_sent'), {
          hideCloseButton: true,
        });
      } else {
        ToasterUtils.errorToast(t('connection_request_sent_failed'));
      }
    }
  };

  const getConnectionActionButtons = (
    connectionStatus: number | null | undefined,
    husslupId?: string,
  ): ActionButtons | undefined => {
    if (!husslupId) return undefined;
    if (
      (!connectionStatus && connectionStatus !== 0) ||
      Number(connectionStatus) === ConnectionStatus.NONE
    ) {
      const isCurrentConnectionBeingSent =
        sendConnectionArgs?.connectionHusslupId === husslupId &&
        isSendingConnection;

      return {
        onClick: handleSendConnection(husslupId),
        icon: 'connect',
        label: 'Connect',
        disabled: isCurrentConnectionBeingSent,
        loading: isCurrentConnectionBeingSent,
      };
    }

    if (Number(connectionStatus) === ConnectionStatus.PENDING) {
      return {
        onClick: () =>
          initiateDeleteConnection({
            husslupId,
            confirmationMessage:
              'Are you sure want to cancel this connection request?',
            confirmationTitle: 'Cancel Connection Request',
            confirmationFailureMessage: 'Failed to cancel connection request',
            confirmationSuccessMessage: 'Connection Request Cancelled',
            toastSuccessMessage: t('connection_request_canceled'),
            toastFailureMessage: t('connection_request_canceled_failed'),
          }),
        label: 'Pending',
        disabled: false,
        loading: false,
        icon: 'connect',
      };
    }

    if (Number(connectionStatus) === ConnectionStatus.ACCEPTED) {
      return {
        onClick: () =>
          initiateDeleteConnection({
            husslupId,
            confirmationMessage: 'Are you sure want to remove this connection?',
            confirmationTitle: 'Remove Connection',
            confirmationFailureMessage: myNetworkT(
              'CONNECTION_REMOVED_ERROR.value',
            ),
            confirmationSuccessMessage: myNetworkT(
              'CONNECTION_REMOVED_SUCCESS.value',
            ),
            toastSuccessMessage: t('connection_removed'),
            toastFailureMessage: t('connection_removed_failed'),
          }),
        label: 'Connected',
        disabled: false,
        loading: false,
        icon: 'connect',
      };
    }

    if (Number(connectionStatus) === ConnectionStatus.REQUESTED) {
      const isCurrentConnectionGettingAccepted = isAcceptingConnection;

      const isCurrentConnectionGettingRemoved =
        removeConnectionArgs?.husslupId === husslupId && isRemovingConnection;

      const needToDisable =
        isCurrentConnectionGettingAccepted || isCurrentConnectionGettingRemoved;

      return {
        onClick: () =>
          initiateDeleteConnection({
            husslupId,
            confirmationMessage: 'This member wants to connect with you',
            confirmationTitle: 'Connection Requested',
            confirmationFailureMessage: 'Failed to process connection request',
            confirmationSuccessMessage: 'Connection accepted',
          }),
        icon: 'check',
        disabled: needToDisable,
        loading:
          isCurrentConnectionGettingAccepted ||
          isCurrentConnectionGettingRemoved,
        label: 'Connection Requested',
      };
    }
    return undefined;
  };
  const isConnectionRequested = () =>
    removeConnectionUser?.confirmationTitle === 'Connection Requested';

  return {
    getConnectionActionButtons,
    removeConnectionDialogPropGetter: {
      disabled: isConnectionRequested()
        ? isAcceptingConnection
        : isRemovingConnection,
      loading: isConnectionRequested()
        ? isAcceptingConnection
        : isRemovingConnection,
      show: isRemoveConnectionVisible,
      onAccept: isConnectionRequested()
        ? handleAcceptConnection(removeConnectionUser?.husslupId)
        : handleRemoveConnection(removeConnectionUser?.husslupId),
      onSecondaryButtonClick: isConnectionRequested()
        ? handleRemoveConnection(removeConnectionUser?.husslupId)
        : hideRemoveConnection,
      loadingSecondaryButton: isConnectionRequested() && isRemovingConnection,
      onClose: hideRemoveConnection,
      message:
        removeConnectionUser?.confirmationMessage ??
        'Are you sure you want to remove this connection?',
      title: removeConnectionUser?.confirmationTitle ?? 'Remove Connection',
      acceptText: isConnectionRequested() ? 'Accept' : 'Yes',
      closeText: isConnectionRequested() ? 'Decline' : 'Cancel',
      closeButton: isConnectionRequested(),
      gtClass: isConnectionRequested() ? USER_ACCEPTED_A_CONNECTION : '',
    },
  };
};

export default useGenericConnectionActionButtons;
