import { FunctionComponent, useState } from 'react';
import SquircleImage from 'components/Core/SquircleImage/SquircleImage';
import Typography from 'components/Core/StylesGuide/Typography';
import { PostTag } from 'commons/types/post.type';
import { getS3ProfileUrl } from 'utils/file';
import useAuth from 'commons/hooks/auth';
import CreatePostModal from './CreatePostModal';

const TellMeSomething: FunctionComponent<{ postTags?: PostTag[] }> = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const { user } = useAuth();

  return (
    <>
      <div className='new-post' aria-hidden='true' onClick={handleShow}>
        <SquircleImage
          r1={0.04}
          r2={0.5}
          width={56}
          height={56}
          imgUrl={getS3ProfileUrl(user?.profile_img, 56, 56)}
          alt='pp'
        />
        <Typography variant='p-md-d'>Tell me something good...</Typography>
      </div>
      {showModal && (
        <CreatePostModal
          showModal={showModal}
          onFormSubmitSuccess={handleClose}
          onDismissModal={handleClose}
        />
      )}
    </>
  );
};

export default TellMeSomething;
