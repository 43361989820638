import axios from 'axios';
import * as crypto from 'crypto';
import { CONTENT_TYPE_APPLICATION_JSON, HTTP_METHODS } from 'constants/http';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

declare global {
  interface Window {
    gtag: any;
    lintrk: any;
    fbq: any;
  }
}
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
export const event = ({ action, category, label }, value?) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value ?? localStorage.getItem('currentUserHusslupId'),
  });
};

export const linkedInEvent = () => {
  window.lintrk('track', {
    conversion_id: process.env.NEXT_PUBLIC_LINKEDIN_ID,
  });
};

export const fbEvent = (event: string) => {
  window.fbq('track', event);
};

export const fbCustomConversion = async (email: any) => {
  const CONVERSION_API_VERSION = process.env.NEXT_PUBLIC_CONVERSION_API_VERSION;
  const CONVERSION_PIXEL_ID = process.env.NEXT_PUBLIC_CONVERSION_PIXEL_ID;
  const CONVERSION_TOKEN = process.env.NEXT_PUBLIC_CONVERSION_TOKEN;
  const EVENT_NAME = process.env.NEXT_PUBLIC_EVENT_NAME;
  const { userAgent } = navigator;

  const IP_ADDRESS_URL = 'https://api.ipify.org?format=json';

  const ipAddress = await axios(IP_ADDRESS_URL);
  const { data }: any = ipAddress;
  if (data) {
    const hashedEmail = crypto.createHash('sha256').update(email).digest('hex');
    const usersIPAddress = data.ip;
    await fetch(
      `https://graph.facebook.com/${CONVERSION_API_VERSION}/${CONVERSION_PIXEL_ID}/events?access_token=${CONVERSION_TOKEN}`,
      {
        method: HTTP_METHODS.POST,
        headers: {
          'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
        },
        body: JSON.stringify({
          data: [
            {
              event_id: Math.floor(Math.random() * 100000),
              event_name: EVENT_NAME,
              event_time: Math.floor(Date.now() / 1000),
              action_source: 'website',
              user_data: {
                em: [hashedEmail],
                client_ip_address: usersIPAddress,
                client_user_agent: userAgent,
              },
              custom_data: {
                hashedEmail,
              },
            },
          ],
        }),
      },
    );
  }
};
