import { useState } from 'react';
import useVisibility from './useVisibility';

const useReportUser = (getUserId: () => string) => {
  const { show, hide, isVisible } = useVisibility();

  return {
    reportUserDialogPropGetter: {
      show: isVisible,
      onClose: hide,
      husslupId: getUserId(),
    },
    showReportUser: show,
  };
};

export const useReportUserWithId = () => {
  const { show, hide, isVisible } = useVisibility();
  const [userIdToReport, setUserIdToReport] = useState('');

  const handleShow = (userId: string) => {
    setUserIdToReport(userId);
    show();
  }

  return {
    reportUserDialogPropGetter: {
      show: isVisible,
      onClose: hide,
      husslupId: userIdToReport,
    },
    showReportUser: handleShow,
  };
};

export default useReportUser;
