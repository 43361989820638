import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  API_STAFFINF_ROSTER_REFRAME,
  API_STAFFING_ROSTERS,
  API_STAFFING_ROSTERS_ACCESS,
  API_STAFFING_ROSTERS_ROLES,
  API_STAFFING_ROSTERS_ROLES_USERS,
  API_STAFFING_ROSTER_EXPORT,
  API_STAFFING_ROSTER_IMPORT,
  CREATE_REFRAME,
  DOWNLOAD_REFRAME,
  SUBMIT_REFRAME,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
// import { Blob } from 'buffer';
import {
  StaffingRosterType,
  CreateStaffingRosterType,
  UpdateStaffingRosterType,
  CreateStaffingRosterRoleType,
  StaffingRosterRoleType,
  CreateStaffingRosterAccessType,
  StaffingRosterAccessType,
  StaffingRosterRoleUserType,
  CreateStaffingRosterRoleUserType,
  CreateOutsideTalentAsRosterRoleUserType,
  ReframeSubmit,
} from './types';

type SharableUsersListPayloadSRType = {
  searchTerm: string;
  staffingRosterId: number;
};

export const staffingRosterAPISlice: any = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Staffing Roster Related Endpoints
    getAllStaffingRosters: builder.query<Array<StaffingRosterType>, any>({
      query: () => API_STAFFING_ROSTERS,
      transformResponse: (data: Array<StaffingRosterType>) => data,
      transformErrorResponse: (response: { status: string | number }) =>
        response.status,
      providesTags: ['StaffingRosters'],
    }),

    getStaffingRostersByOwnerId: builder.query<
      {
        mine: Partial<StaffingRosterType>[];
        sharedWithMe: Partial<StaffingRosterType>[];
      },
      {
        ownerId: number;
        searchTerm?: string;
        sortBy?: string;
        subscriptionId: number;
      }
    >({
      query: ({ ownerId, subscriptionId }) =>
        `${API_STAFFING_ROSTERS}/owner/${ownerId}/subscription/${subscriptionId}`,
      transformResponse: (
        {
          mine,
          sharedWithMe,
          externalSubscriptions,
        }: {
          mine: Partial<StaffingRosterType>[];
          sharedWithMe: Partial<StaffingRosterType>[];
          externalSubscriptions: Partial<StaffingRosterType>[];
        },
        meta,
        { searchTerm, sortBy },
      ) => {
        if (searchTerm) {
          mine = mine.filter((staffingRoster: any) =>
            staffingRoster.projectName
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()),
          );

          sharedWithMe = sharedWithMe.filter((staffingRoster: any) =>
            staffingRoster.projectName
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()),
          );
          externalSubscriptions = externalSubscriptions.filter(
            (staffingRoster: any) =>
              staffingRoster.projectName
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()),
          );
        }
        if (sortBy === 'asc') {
          mine = mine.sort((a: any, b: any) =>
            a.projectName
              .toLowerCase()
              .localeCompare(b.projectName.toLowerCase()),
          );
          sharedWithMe = sharedWithMe?.sort((a: any, b: any) =>
            a.projectName
              .toLowerCase()
              .localeCompare(b.projectName.toLowerCase()),
          );
          externalSubscriptions = externalSubscriptions?.sort(
            (a: any, b: any) =>
              a.projectName
                .toLowerCase()
                .localeCompare(b.projectName.toLowerCase()),
          );
        } else if (sortBy === 'desc') {
          mine = mine.sort((a: any, b: any) =>
            b.projectName
              .toLowerCase()
              .localeCompare(a.projectName.toLowerCase()),
          );
          sharedWithMe = sharedWithMe?.sort((a: any, b: any) =>
            b.projectName
              .toLowerCase()
              .localeCompare(a.projectName.toLowerCase()),
          );
          externalSubscriptions = externalSubscriptions?.sort(
            (a: any, b: any) =>
              b.projectName
                .toLowerCase()
                .localeCompare(a.projectName.toLowerCase()),
          );
        } else {
          mine = mine.sort(
            (a, b) =>
              (new Date(b.updatedOn as Date) as any) -
              (new Date(a.updatedOn as Date) as any),
          );

          sharedWithMe = sharedWithMe?.sort(
            (a, b) =>
              (new Date(b.updatedOn as Date) as any) -
              (new Date(a.updatedOn as Date) as any),
          );

          externalSubscriptions = externalSubscriptions?.sort(
            (a, b) =>
              (new Date(b.updatedOn as Date) as any) -
              (new Date(a.updatedOn as Date) as any),
          );
        }

        return { mine, sharedWithMe, externalSubscriptions };
      },
      providesTags: ['StaffingRosters', 'Home'],
    }),

    getStaffingRostersByOwner: builder.query<
      {
        mine: Partial<StaffingRosterType>[];
        sharedWithMe: Partial<StaffingRosterType>[];
      },
      {
        ownerId: number;
        searchTerm?: string;
        sortBy?: string;
      }
    >({
      query: () => `${API_STAFFING_ROSTERS}/by-user`,
      transformResponse: (
        {
          mine,
          sharedWithMe,
          externalSubscriptions,
        }: {
          mine: Partial<StaffingRosterType>[];
          sharedWithMe: Partial<StaffingRosterType>[];
          externalSubscriptions: Partial<StaffingRosterType>[];
        },
        meta,
        { searchTerm, sortBy },
      ) => {
        if (searchTerm) {
          mine = mine.filter((staffingRoster: any) =>
            staffingRoster.projectName
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()),
          );

          sharedWithMe = sharedWithMe.filter((staffingRoster: any) =>
            staffingRoster.projectName
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()),
          );
          externalSubscriptions = externalSubscriptions.filter(
            (staffingRoster: any) =>
              staffingRoster.projectName
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()),
          );
        }
        if (sortBy === 'asc') {
          mine = mine.sort((a: any, b: any) =>
            a.projectName
              .toLowerCase()
              .localeCompare(b.projectName.toLowerCase()),
          );
          sharedWithMe = sharedWithMe?.sort((a: any, b: any) =>
            a.projectName
              .toLowerCase()
              .localeCompare(b.projectName.toLowerCase()),
          );
          externalSubscriptions = externalSubscriptions?.sort(
            (a: any, b: any) =>
              a.projectName
                .toLowerCase()
                .localeCompare(b.projectName.toLowerCase()),
          );
        }
        if (sortBy === 'desc') {
          mine = mine.sort((a: any, b: any) =>
            b.projectName
              .toLowerCase()
              .localeCompare(a.projectName.toLowerCase()),
          );
          sharedWithMe = sharedWithMe?.sort((a: any, b: any) =>
            b.projectName
              .toLowerCase()
              .localeCompare(a.projectName.toLowerCase()),
          );
          externalSubscriptions = externalSubscriptions?.sort(
            (a: any, b: any) =>
              b.projectName
                .toLowerCase()
                .localeCompare(a.projectName.toLowerCase()),
          );
        }

        return { mine, sharedWithMe, externalSubscriptions };
      },
      providesTags: ['StaffingRostersV2'],
    }),

    getSingleStaffingRoster: builder.query<Partial<StaffingRosterType>, number>(
      {
        query: (id: number) => `${API_STAFFING_ROSTERS}/${id}`,
        transformResponse: (data: StaffingRosterType) => data,
        providesTags: ['SingleStaffingRoster'],
        keepUnusedDataFor: 0,
      },
    ),

    getSingleStaffingRosterForNotifications: builder.query<
      Partial<StaffingRosterType>,
      number
    >({
      query: (id: number) => `${API_STAFFING_ROSTERS}/for-notification/${id}`,
      transformResponse: (data: Partial<StaffingRosterType>) => data,
    }),

    createStaffingRoster: builder.mutation<any, CreateStaffingRosterType>({
      query: ({
        projectName,
        projectDescription,
        projectMedium,
        subscriptionId,
      }: CreateStaffingRosterType) => ({
        url: API_STAFFING_ROSTERS,
        method: HTTP_METHODS.POST,
        body: <CreateStaffingRosterType>{
          projectName,
          projectDescription,
          projectMedium,
          subscriptionId,
        },
      }),
      invalidatesTags: ['StaffingRosters', 'SingleStaffingRoster'],
    }),

    updateStaffingRoster: builder.mutation<any, UpdateStaffingRosterType>({
      query: ({ id, ...payload }: UpdateStaffingRosterType) => ({
        url: `${API_STAFFING_ROSTERS}/${id}`,
        method: HTTP_METHODS.PATCH,
        body: payload,
      }),
      transformResponse: (data: Partial<UpdateStaffingRosterType>) => data,
      invalidatesTags: [
        'StaffingRosters',
        'SingleStaffingRoster',
        'ReframeCreate',
        'RosterDemographicsReportView',
      ],
    }),

    deleteStaffingRoster: builder.mutation<any, number>({
      query: (id: number) => ({
        url: `${API_STAFFING_ROSTERS}/${id}`,
        method: HTTP_METHODS.PATCH,
      }),
      invalidatesTags: ['StaffingRosters', 'SingleStaffingRoster'],
    }),

    // Staffing Roster Roles Related Endpoints

    getStaffingRosterRolesByRosterId: builder.query<any, number>({
      query: (id: number) => ({
        url: `${API_STAFFING_ROSTERS_ROLES}/roster/${id}`,
      }),
      transformResponse: (data) => data,
      providesTags: ['StaffingRosterRoles'],
    }),

    createStaffingRosterRoles: builder.mutation<
      Partial<StaffingRosterRoleType>,
      CreateStaffingRosterRoleType
    >({
      query: (payload: CreateStaffingRosterRoleType) => ({
        url: `${API_STAFFING_ROSTERS_ROLES}`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      transformResponse: (data: StaffingRosterRoleType) => data,
      invalidatesTags: [
        'StaffingRosterRoles',
        'ReframeCreate',
        'RosterDemographicsReportView',
      ],
    }),

    // Staffing Roster Role Users Related Endpoints
    getStaffingRosterRoleUsersByUserId: builder.query<
      Partial<StaffingRosterRoleUserType>[],
      any
    >({
      query: () => ({
        url: `${API_STAFFING_ROSTERS_ROLES_USERS}`,
        transformResponse: (data: Partial<StaffingRosterRoleUserType>) => data,
      }),
      providesTags: ['StaffingRosterRoleUsers'],
    }),
    getStaffingRosterRoleUsersByRoleId: builder.query<
      Partial<StaffingRosterRoleUserType>[],
      any
    >({
      query: () => ({
        url: `${API_STAFFING_ROSTERS_ROLES_USERS}/role/:roleId`,
        transformResponse: (data: Partial<StaffingRosterRoleUserType>[]) =>
          data,
      }),
      providesTags: ['StaffingRosterRoleUsersByRoleId'],
    }),

    getStaffingRosterRoleUsersByRosterId: builder.query<
      Partial<StaffingRosterRoleUserType>[],
      number
    >({
      query: (rosterId: number) => ({
        url: `${API_STAFFING_ROSTERS_ROLES_USERS}/roster/${rosterId}`,
        transformResponse: (data: Partial<StaffingRosterRoleUserType>[]) =>
          data,
      }),
    }),

    createStaffingRosterRoleUsers: builder.mutation<
      Partial<StaffingRosterRoleUserType>,
      CreateStaffingRosterRoleUserType
    >({
      query: (payload: CreateStaffingRosterRoleUserType) => ({
        url: `${API_STAFFING_ROSTERS_ROLES_USERS}`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      transformResponse: (data: Partial<StaffingRosterRoleUserType>) => data,
      invalidatesTags: [
        'StaffingRosterRoleUsersByRoleId',
        'StaffingRosterRoleUsers',
        'ReframeCreate',
        'RosterDemographicsReportView',
      ],
    }),

    createStaffingRosterRoleUsersOutsideTalent: builder.mutation<
      any,
      CreateOutsideTalentAsRosterRoleUserType
    >({
      query: (payload: CreateOutsideTalentAsRosterRoleUserType) => ({
        url: `${API_STAFFING_ROSTERS_ROLES_USERS}/outside-talent`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      transformResponse: (data: any) => data,
      invalidatesTags: [
        'StaffingRosterRoleUsers',
        'StaffingRosterRoleUsersByRoleId',
        'StaffingRosterRoles',
        'ReframeCreate',
        'RosterDemographicsReportView',
      ],
    }),

    getStaffingRosterRoleDetailsByRoleId: builder.query<
      Partial<StaffingRosterRoleUserType>[],
      { roleId: number }
    >({
      query: ({ roleId }) => ({
        url: `${API_STAFFING_ROSTERS_ROLES}/${roleId}`,
        transformResponse: (data: Partial<StaffingRosterRoleUserType>[]) =>
          data,
      }),
      providesTags: ['StaffingRosterRoleUsersByRoleId'],
      keepUnusedDataFor: 0,
    }),

    // update Staffing roaster role
    updateStaffingRoasterRole: builder.mutation<
      Partial<StaffingRosterRoleType>,
      { roles: CreateStaffingRosterRoleType; roleId: number }
    >({
      query: ({ roleId, roles }) => ({
        url: `${API_STAFFING_ROSTERS_ROLES}/${roleId}`,
        method: HTTP_METHODS.PATCH,
        body: roles,
      }),
      transformResponse: (data: StaffingRosterRoleType) => data,
      invalidatesTags: [
        'StaffingRosterRoles',
        'ReframeCreate',
        'RosterDemographicsReportView',
      ],
    }),
    getStaffingRosterRoles: builder.query<any, void>({
      query: () => ({
        url: API_STAFFING_ROSTERS_ROLES,
      }),
      providesTags: ['StaffingRosterRoles'],
    }),

    staffingRoasterRoleById: builder.query<any, { rosterId: number }>({
      query: ({ rosterId }) => ({
        url: `${API_STAFFING_ROSTERS_ROLES}/roster/${rosterId}`,
      }),
      providesTags: ['StaffingRosterRoles'],
    }),
    searchForHsslupMembers: builder.query<
      any,
      { username: string; roleId: number; addedUsersToFilter: number }
    >({
      query: ({ username, roleId }) => ({
        url: `${API_STAFFING_ROSTERS_ROLES_USERS}/search-users/${roleId}`,
        params: {
          searchText: username,
          limit: 100,
        },
        // body: { addedUsersToFilter },
        method: HTTP_METHODS.GET,
      }),
      // providesTags: ['StaffingRosterRoles'],
    }),
    // Endpoint roles

    // Access Related Endpoints
    getStaffingRosterAccessByRosterId: builder.query<any, any>({
      query: (rosterId: number) => ({
        url: `${API_STAFFING_ROSTERS_ACCESS}/roster/${rosterId}`,
      }),
      transformResponse: (data: any) => data,
      providesTags: ['StaffingRosterAccess'],
    }),
    createStaffingRosterAccess: builder.mutation<
      Partial<StaffingRosterAccessType>,
      CreateStaffingRosterAccessType
    >({
      query: (payload) => ({
        url: `${API_STAFFING_ROSTERS_ACCESS}`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      transformResponse: (data: Partial<StaffingRosterAccessType>) => data,
      invalidatesTags: ['StaffingRosterAccess'],
    }),

    updateStaffingRosterAccess: builder.mutation<
      any,
      Partial<StaffingRosterAccessType>
    >({
      query: ({ id, ...payload }: Partial<StaffingRosterAccessType>) => ({
        url: `${API_STAFFING_ROSTERS_ACCESS}/${id}`,
        method: HTTP_METHODS.PATCH,
        body: payload,
      }),
      transformResponse: (data: Partial<UpdateStaffingRosterType>) => data,
      invalidatesTags: ['StaffingRosterAccess'],
    }),

    removeMyStaffingRosterAccess: builder.mutation<any, number>({
      query: (id: number) => ({
        url: `${API_STAFFING_ROSTERS_ACCESS}/remove-my-access/${id}`,
        method: HTTP_METHODS.PATCH,
      }),
      transformResponse: (data: Partial<UpdateStaffingRosterType>) => data,
      invalidatesTags: ['StaffingRosterAccess', 'StaffingRosters'],
    }),
    transferOwnershipToStaffingRoster: builder.mutation<any, any>({
      query: ({ accessId, ...payload }) => ({
        url: `${API_STAFFING_ROSTERS_ACCESS}/transferOwnership/${accessId}`,
        method: HTTP_METHODS.PATCH,
        body: payload,
      }),
      transformResponse: (data: any) => data,
      transformErrorResponse: (response: { status: string | number }) =>
        response.status,
      invalidatesTags: [
        'StaffingRosters',
        'SingleStaffingRoster',
        'StaffingRosterAccess',
      ],
    }),
    deleteStaffingRosterAccess: builder.mutation<any, number>({
      query: (id: number) => ({
        url: `${API_STAFFING_ROSTERS_ACCESS}/${id}/delete`,
        method: HTTP_METHODS.PATCH,
      }),
      invalidatesTags: ['StaffingRosterAccess'],
    }),

    getSharableUsersListStaffingRoster: builder.query<
      any[],
      SharableUsersListPayloadSRType
    >({
      query: (payload) => {
        const { searchTerm, staffingRosterId } = payload;
        return {
          url: `${API_STAFFING_ROSTERS}/sharable-users/${staffingRosterId}`,
          method: HTTP_METHODS.GET,
          params: {
            searchTerm,
          },
        };
      },
      transformResponse: (data: any) => data,
      transformErrorResponse: (response: { status: string | number }) =>
        response.status,
    }),
    addRoleUser: builder.mutation<
      any,
      {
        user: {
          staffingRosterId: number;
          staffingRosterRoleId: number;
          userId: number;
        }[];
      }
    >({
      query: ({ user }) => ({
        url: `staffing-roster-role-user`,
        method: HTTP_METHODS.POST,
        body: user,
      }),
      invalidatesTags: [
        'StaffingRosterRoles',
        'ReframeCreate',
        'RosterDemographicsReportView',
      ],
    }),
    deleteRoleUser: builder.mutation<any, number>({
      query: (memberId) => ({
        url: `${API_STAFFING_ROSTERS_ROLES_USERS}/${memberId}/delete`,
        method: HTTP_METHODS.PATCH,
      }),
      invalidatesTags: [
        'StaffingRosterRoles',
        'ReframeCreate',
        'RosterDemographicsReportView',
      ],
    }),
    deleteRole: builder.mutation<any, number>({
      query: (id) => ({
        url: `${API_STAFFING_ROSTERS_ROLES}/${id}/delete`,
        method: HTTP_METHODS.PATCH,
      }),
      invalidatesTags: ['StaffingRosterRoles'],
    }),
    importRoleFromSpreadsheet: builder.mutation<any, any>({
      query: ({ data }) => ({
        url: `${API_STAFFING_ROSTER_IMPORT}`,
        method: HTTP_METHODS.POST,
        body: data,
      }),
      invalidatesTags: ['StaffingRosterRoles'],
    }),
    exportSpreadsheet: builder.query<any, any>({
      query: (rosterId: any) => ({
        url: `${API_STAFFING_ROSTER_EXPORT}/${rosterId}`,
        method: HTTP_METHODS.GET,
        responseHandler: async (response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const blobUrl = URL.createObjectURL(await response.blob());
          const timestamp = new Date().getTime();
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', `${timestamp}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return true;
        },
      }),
    }),
    exportCSV: builder.query<any, any>({
      query: (rosterId: any) => ({
        url: `${API_STAFFING_ROSTER_EXPORT}/csv/${rosterId}`,
        method: HTTP_METHODS.GET,
      }),
    }),
    exportSampleSpreadsheet: builder.query<any, any>({
      query: () => ({
        url: `${API_STAFFING_ROSTER_EXPORT}/download/sample-spreadsheet`,
        method: HTTP_METHODS.GET,
      }),
    }),
    getDemographicsDataByRosterId: builder.query<any[], any>({
      query: (rosterId: any) => ({
        url: `${API_STAFFING_ROSTERS}/${rosterId}/demographics-by-category`,
        method: HTTP_METHODS.GET,
      }),
      transformResponse: (data: any) => data,
      transformErrorResponse: (response: { status: string | number }) =>
        response,
      providesTags: ['RosterDemographicsReportView'],
    }),
    createReframeDataByRosterId: builder.query<any[], any>({
      query: (rosterId: any) => ({
        url: `${API_STAFFINF_ROSTER_REFRAME}/${rosterId}/${CREATE_REFRAME}`,
        method: HTTP_METHODS.GET,
      }),
      transformResponse: (data: any) => data,
      transformErrorResponse: (response: { status: string | number }) =>
        response,
      providesTags: ['ReframeCreate'],
    }),

    downloadReframeDataByRosterId: builder.query<any[], any>({
      query: (rosterId: any) => ({
        url: `${API_STAFFINF_ROSTER_REFRAME}/${rosterId}/${DOWNLOAD_REFRAME}`,
        method: HTTP_METHODS.GET,
      }),
      transformResponse: (data: any) => data,
      transformErrorResponse: (response: { status: string | number }) =>
        response,
    }),

    submitReframeDataByRosterId: builder.mutation<any[], ReframeSubmit>({
      query: ({ id, notes, noOfEpisodes, season }) => ({
        url: `${API_STAFFINF_ROSTER_REFRAME}/${id}/${SUBMIT_REFRAME}`,
        method: HTTP_METHODS.POST,
        body: { notes, noOfEpisodes, season },
      }),
      transformResponse: (data: any) => data,
      transformErrorResponse: (response: { status: string | number }) =>
        response,
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetStaffingRosterQuery,
  useGetStaffingRostersByOwnerIdQuery,
  useLazyGetStaffingRostersByOwnerIdQuery,
  useGetStaffingRostersByOwnerQuery,
  useLazyGetStaffingRostersByOwnerQuery,
  useGetSingleStaffingRosterQuery,
  useCreateStaffingRosterMutation,
  useUpdateStaffingRosterMutation,
  useDeleteStaffingRosterMutation,

  useGetStaffingRosterRolesByRosterIdQuery,
  useCreateStaffingRosterRolesMutation,
  useGetStaffingRosterRoleDetailsByRoleIdQuery,
  useUpdateStaffingRoasterRoleMutation,
  useGetStaffingRosterRolesQuery,
  useStaffingRoasterRoleByIdQuery,
  useLazyStaffingRoasterRoleByIdQuery,
  useLazySearchForHsslupMembersQuery,

  useGetStaffingRosterRoleUsersByRosterIdQuery,
  useCreateStaffingRosterRoleUsersOutsideTalentMutation,
  useCreateStaffingRosterRoleUsersMutation,

  useCreateStaffingRosterAccessMutation,
  useGetStaffingRosterAccessByRosterIdQuery,
  useLazyGetStaffingRosterAccessByRosterIdQuery,
  useUpdateStaffingRosterAccessMutation,
  useDeleteStaffingRosterAccessMutation,
  useRemoveMyStaffingRosterAccessMutation,
  useTransferOwnershipToStaffingRosterMutation,

  useGetSharableUsersListStaffingRosterQuery,
  useLazyGetSharableUsersListStaffingRosterQuery,

  useAddRoleUserMutation,
  useDeleteRoleUserMutation,
  useDeleteRoleMutation,

  useGetSingleStaffingRosterForNotificationsQuery,

  useImportRoleFromSpreadsheetMutation,
  useExportSpreadsheetQuery,
  useLazyExportSpreadsheetQuery,
  useLazyExportCSVQuery,
  useExportCSVQuery,
  useLazyExportSampleSpreadsheetQuery,
  useExportSampleSpreadsheetQuery,
  useGetDemographicsDataByRosterIdQuery,
  useLazyGetDemographicsDataByRosterIdQuery,
  useCreateReframeDataByRosterIdQuery,
  useLazyCreateReframeDataByRosterIdQuery,
  useDownloadReframeDataByRosterIdQuery,
  useSubmitReframeDataByRosterIdMutation,
} = staffingRosterAPISlice;
