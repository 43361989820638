import Link from 'next/link';
import React from 'react';

const PostMentionPreview = ({
  href,
  text,
  highlight = false,
}: {
  href: string;
  text: React.ReactNode;
  highlight?: boolean;
}) => {
  const handleMentionClick = (
    e: React.MouseEvent<HTMLAnchorElement> | undefined,
  ) => {
    e?.stopPropagation();
  };

  return (
    <span className={`mentioned + ${highlight ? 'mentioned-searched' : ''}`}>
      <Link href={href} onClick={handleMentionClick}>
        {text}
      </Link>
    </span>
  );
};

export default PostMentionPreview;
