import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { selectCurrentUser } from 'store/auth/selectors';
import { STAFFING, SUBSCRIPTION } from 'constants/routes';
import {
  clearActiveStaffingSubscription,
  setActiveStaffingSubscription,
} from 'store/subscription/subscription.slice';
import { selectActiveStaffingSubscription } from 'store/subscription/selectors';
import { isSubscriptionStatusValid } from 'store/subscription/subscription.utils';

import { useAppDispatch, useAppSelector } from '../redux';
import useSubscriptionChecks from './useSubscriptionChecks';

export const useSubscriptionRoute = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const user = useAppSelector(selectCurrentUser);

  const { id: subId, isExternalSubscription } = useAppSelector(
    selectActiveStaffingSubscription,
  );

  const {
    activeOwnership,
    userSubscription,
    isInActiveSubscription,
    ownsActiveSubscription,
  } = useSubscriptionChecks();
  useEffect(() => {
    if (user && router.pathname.includes(STAFFING)) {
      if (
        user?.usersOwnership?.length > 0 &&
        !subId &&
        !isExternalSubscription
      ) {
        const activeOwnership = user?.usersOwnership?.find((data: any) =>
          isSubscriptionStatusValid(data?.subscriptionStatus),
        );

        if (activeOwnership) {
          const currentQuery = { ...router.query };

          // currentQuery.subId = activeOwnership.id;

          dispatch(
            setActiveStaffingSubscription({
              id: activeOwnership.id,
              isExternalSubscription: false,
            }),
          );
          router.push({
            pathname: router.pathname,
            query: currentQuery,
          });
        } else {
          dispatch(clearActiveStaffingSubscription());
          router.push(SUBSCRIPTION);
        }
      } else if (
        user?.userSubscriptions?.length > 0 &&
        !subId &&
        !isExternalSubscription
      ) {
        const currentQuery = { ...router.query };
        const subscriptions = user?.userSubscriptions?.filter(
          (sub: { isDeleted: boolean }) => sub.isDeleted !== true,
        );
        if (subscriptions.length > 0) {
          const newCurrentQuery = {
            ...currentQuery,
            // subId: subscriptions[0]?.id,
          };
          dispatch(
            setActiveStaffingSubscription({
              id: subscriptions[0]?.id,
              isExternalSubscription: false,
            }),
          );
          router.push({
            pathname: router.pathname,
            query: newCurrentQuery,
          });
        } else {
          dispatch(clearActiveStaffingSubscription());
          router.push(SUBSCRIPTION);
        }
      } else if (!subId && !isExternalSubscription) {
        dispatch(clearActiveStaffingSubscription());
        router.push(SUBSCRIPTION);
      }
    }
  }, [user]);

  return {
    activeOwnership,
    userSubscription,
    isInActiveSubscription,
    ownsActiveSubscription,
  };
};

export default useSubscriptionRoute;
