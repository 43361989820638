import Typography from 'components/Core/StylesGuide/Typography';
import HusslUpIcon from 'components/Icon';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SearchStates,
  setFeedSearchResultCount,
  setGetPostWithTagParam,
  setSearchKeyword,
  setSearchState,
} from 'store/post/post.slice';
import {
  selectFeedSearchResultCount,
  selectGetPostWithTagParams,
  selectSearchKeyword,
  selectSearchState,
} from 'store/post/selectors';
import { clickableDivRequiredProps } from 'utils/dom';

const labels = {
  placeholder: 'Search the feed',
};

const SearchPosts = () => {
  const dispatch = useDispatch();
  // moved all of these commented states to global to presrve the seach states
  // const [searchKeyword, setSearchKeyword] = useState<string>('');
  const getPostWithTagParams = useSelector(selectGetPostWithTagParams);
  const feedSearchResultCount = useSelector(selectFeedSearchResultCount);
  // const [searchState, setSearchState] = useState<SearchStates>(SearchStates.Collapsed);
  const searchState = useSelector(selectSearchState);
  const searchKeyword = useSelector(selectSearchKeyword);

  function handleSubmit() {
    dispatch(setSearchState(SearchStates.FetchCompleted));
    dispatch(
      setGetPostWithTagParam({
        ...getPostWithTagParams,
        searchKeyword,
        page: 1,
        limit: 10,
      }),
    );
  }

  useEffect(() => {
    if (
      !getPostWithTagParams.searchKeyword &&
      searchState !== SearchStates.ExpandedAndEmpty
    ) {
      dispatch(setSearchKeyword(''));
      dispatch(setSearchState(SearchStates.Collapsed));
    }
  }, [getPostWithTagParams.searchKeyword]);

  //   useEffect(() => {
  //     dispatch(setSearchState(SearchStates.Collapsed));
  //   }, [getPostWithTagParams.tagId]);

  const handleClear = () => {
    dispatch(setSearchKeyword(''));
    dispatch(setSearchState(SearchStates.Collapsed));
    dispatch(
      setGetPostWithTagParam({
        ...getPostWithTagParams,
        searchKeyword: '',
        page: 1,
        limit: 10,
      }),
    );
    dispatch(setFeedSearchResultCount(0));
  };

  const handleInputChange = (event: any) => {
    dispatch(setFeedSearchResultCount(0));

    if (event.target.value === '') {
      dispatch(setSearchState(SearchStates.ExpandedAndEmpty));
      dispatch(
        setGetPostWithTagParam({
          ...getPostWithTagParams,
          searchKeyword: '',
          page: 1,
          limit: 10,
        }),
      );
      dispatch(setFeedSearchResultCount(0));
    }
    dispatch(setSearchKeyword(event.target.value));
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && searchKeyword.length > 2) {
      handleSubmit();
    }
  };

  // states : idle, typable, fetching, fetched
  const getViewBySearchStates = () => {
    switch (searchState) {
      case SearchStates.Expanded:
      case SearchStates.FetchCompleted:
      case SearchStates.ExpandedAndEmpty:
        return (
          <div
            className='mt-1 mb-1 d-flex justify-content-between align-items-center border-2 pl-1 pr-1'
            style={{ borderBottom: '1px solid #4C1E4F' }}
          >
            <div className='col-md-auto d-flex justify-content-end'>
              {/* Align search icon to the right while adding space */}
              <i className='hic-search' />
            </div>
            <div className='col-md'>
              {/* Input text field with underlined border */}
              <input
                type='text'
                className='form-control border-0'
                placeholder={labels.placeholder}
                style={{ height: '30px' }} // Adjust the height value as needed
                value={searchKeyword}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className='col-md-auto d-flex justify-content-end'>
              {/* Display '(54 Results)' text if state is FetchCompleted */}
              {searchState === SearchStates.FetchCompleted &&
                feedSearchResultCount > 0 && (
                  <div className='col-md-auto mr-1'>{`(${feedSearchResultCount} Results)`}</div>
                )}
              {/* Align close icon to the right */}
              <HusslUpIcon
                name='close'
                variant='1x'
                onClick={handleClear}
                aria-hidden='true'
                wrapperClass='pointer'
              />
            </div>
            {/* Display "Search" text if searchKeyword is greater than 2 */}
            {searchKeyword.length > 2 && (
              <div
                className='col-md-auto clickable'
                onClick={() => {
                  handleSubmit();
                }}
                {...clickableDivRequiredProps}
              >
                {/* Display "Search" text if searchKeyword is greater than 2 */}
                {searchKeyword.length > 2 && (
                  <div className='col-md-auto ml-1'>
                    <Typography variant='p-sm-sb' style={{ color: '#4C1E4F' }}>
                      Search
                    </Typography>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      default: // idle case
        return (
          <div
            className=' mt-1 mb-1 d-flex justify-content-end  pl-1 pr-1'
            onClick={() => {
              dispatch(setSearchState(SearchStates.Expanded));
              dispatch(setFeedSearchResultCount(0));
            }}
            {...clickableDivRequiredProps}
          >
            <i className='hic-search' />
          </div>
        );
    }
  };

  return <>{getViewBySearchStates()}</>;
};

export default SearchPosts;
