import Typography from 'components/Core/StylesGuide/Typography';
import { TypographyVariant } from 'components/Core/StylesGuide/Typography/Typography';
import React from 'react';
import SuperEllipse from 'react-superellipse';
import { UserSubscriptions } from 'store/subscription/subscription.types';

export type SubscriptionItemProps = {
  subscription: UserSubscriptions;
  isOwner?: boolean;
  nameTypography?: TypographyVariant;
  divStyle?: React.CSSProperties;
};

export const SubscriptionItem = ({
  subscription,
  isOwner = false,
  divStyle = {},
  nameTypography = 'p-sm-sb',
}: SubscriptionItemProps) => {
  const initials =
    subscription?.subscriptionInitials ||
    subscription?.subscriptionName
      ?.split(' ')
      .reduce((a: string, b: string) => {
        a += b[0]?.toUpperCase();
        return a;
      }, '')
      .slice(0, 2);

  return (
    <div className='subscription-user' style={divStyle}>
      <SuperEllipse
        r1={0.05}
        r2={0.49}
        style={{
          width: '36px',
          height: '36px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#F1F1F1',
        }}
      >
        <SuperEllipse
          r1={0.05}
          r2={0.49}
          style={{
            width: '34.5px',
            height: '34.5px',
            // display: 'block',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className='squicircle-subscription'
            style={{
              backgroundColor: subscription?.subscriptionColor || '#4dc6ba',
            }}
          >
            <Typography variant='p-sm-d'>{initials}</Typography>
          </div>
        </SuperEllipse>
      </SuperEllipse>

      <div className='d-flex align-items-center'>
        <Typography variant={nameTypography}>
          {subscription?.subscriptionName}
        </Typography>
        {isOwner ? (
          <span className='badge badge-subscription ml-12px'>OWNER</span>
        ) : null}
      </div>
    </div>
  );
};
