import {
  AccentColor,
  NeutralsWebColor,
  PrimaryColor,
  SemanticsColor,
} from 'commons/types/color.type';

export interface AppColor {
  name: string;
  code: string;
}

export interface Palettes {
  primary: AppColor[];
  accent: AppColor[];
  semantics: AppColor[];
  neutralsWeb: AppColor[];
}

export const primary: AppColor[] = [
  {
    name: 'interactive-controls',
    code: '#4c1e4f',
  },
  {
    name: 'interactive-controls-hover',
    code: '#3f1942',
  },
  {
    name: 'itab-items-default',
    code: '#6a446c',
  },
  {
    name: 'nav-items-header',
    code: '#c3b4c4',
  },
  {
    name: 'interactive-controls-borde',
    code: '#dbd2dc',
  },
  {
    name: 'background-placeholder',
    code: '#e7e1e8',
  },
];

export const accent: AppColor[] = [
  {
    name: 'awards',
    code: '#b62641',
  },
  {
    name: 'info-text',
    code: '#216cb2',
  },
  {
    name: 'info-accent',
    code: '#b5cee5',
  },
  {
    name: 'info-background',
    code: '#f0f5fa',
  },
  {
    name: 'badge-rising',
    code: '#e7e1e8',
  },
  {
    name: 'badge-pro',
    code: '#ffcb00',
  },
  {
    name: 'status-comment',
    code: '#4dc6ba',
  },
];

export const semantics: AppColor[] = [
  {
    name: 'success',
    code: '#0d564f',
  },
  {
    name: 'error',
    code: '#f36267',
  },
];

export const neutralsWeb: AppColor[] = [
  {
    name: 'member-tile-bg',
    code: '#000000',
  },
  {
    name: 'body-text',
    code: '#111111',
  },
  {
    name: 'body-text-2',
    code: '#343434',
  },
  {
    name: 'sub-text',
    code: '#565656',
  },
  {
    name: 'badge-archived',
    code: '#787878',
  },
  {
    name: 'form-placeholder',
    code: '#999999',
  },
  {
    name: 'borders-3',
    code: '#bbbbbb',
  },
  {
    name: 'sub-text-creative-content',
    code: '#d6d6d6',
  },
  {
    name: 'borders-1',
    code: '#e4e4e4',
  },
  {
    name: 'borders-2',
    code: '#f1f1f1',
  },
  {
    name: 'background',
    code: '#ffffff',
  },
];

const accumulatePalette = <T extends string>(
  accumulator: Record<T, string>,
  currentColor: AppColor,
) => {
  accumulator[currentColor.name as T] = currentColor.code;
  return accumulator;
};

export const ColorCodes = {
  Primary: primary.reduce(accumulatePalette, {}) as Record<
    PrimaryColor,
    string
  >,
  Accent: accent.reduce(accumulatePalette, {}) as Record<AccentColor, string>,
  Semantics: semantics.reduce(accumulatePalette, {}) as Record<
    SemanticsColor,
    string
  >,
  NeutralsWeb: neutralsWeb.reduce(accumulatePalette, {}) as Record<
    NeutralsWebColor,
    string
  >,
};

export const palettes: Palettes = {
  primary,
  accent,
  semantics,
  neutralsWeb,
};
