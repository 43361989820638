import { useStripeInitializer } from './useStripeInitializer';
import useVisibility from '../useVisibility';

export const useEditSubscriptionModal = () => {
  const { options, publishableKey } = useStripeInitializer();

  const {
    show: showEdit,
    hide: hideEdit,
    isVisible: isEditVisible,
  } = useVisibility();

  const handleEdit = () => {
    showEdit();
  };

  return {
    options,
    publishableKey,
    handleEdit,
    hideEdit,
    isEditVisible,
  };
};

export default useEditSubscriptionModal;
