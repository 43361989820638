import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import authBuilderUserUpdate from './action-builders/user-update.action-builders';

const AUTH_SLICE_KEY = 'auth';

export interface AuthState {
  isAuthReady: boolean;
  token: string | null;
  refreshToken?: string | null;
  timeout: string | null;
  userEmail?: string;
  loginPhoneNumber?: string;
  isEmailLogin?: boolean;
  hasNewNotification?: boolean;
  isLoggingInAfterSignup?: boolean;
}

const initialState: AuthState = {
  isAuthReady: false,
  token: null,
  refreshToken: null,
  timeout: null,
  hasNewNotification: false,
};

const authSlice = createSlice({
  name: AUTH_SLICE_KEY,
  initialState,
  reducers: {
    userLoggedIn(state, action: PayloadAction<AuthState>) {
      state.isAuthReady = action.payload.isAuthReady;
      state.token = action.payload.token;
      state.refreshToken = action.payload?.refreshToken;
      state.timeout = action.payload?.timeout;
      state.isEmailLogin = action.payload?.isEmailLogin;
      state.isLoggingInAfterSignup = action.payload?.isLoggingInAfterSignup;
    },
    userLoggedOut(state) {
      state.token = null;
      state.refreshToken = null;
      state.timeout = null;
      state.isAuthReady = false;
    },
    getUserEmail(state, { payload }) {
      state.userEmail = payload;
    },
    phoneLoginAttempted(state, action: PayloadAction<{ phoneNumber: string }>) {
      state.loginPhoneNumber = action.payload.phoneNumber;
    },
    setIsEmailLogin(state, action: PayloadAction<boolean>) {
      state.isEmailLogin = action.payload;
    },
    setHasNewNotification(state, action: PayloadAction<boolean>) {
      state.hasNewNotification = action.payload;
    },
    clearLoginAfterSignup(state) {
      state.isLoggingInAfterSignup = false;
    },
  },
  extraReducers: (builder) => {
    authBuilderUserUpdate(builder);
  },
});

export const {
  userLoggedIn,
  userLoggedOut,
  getUserEmail,
  phoneLoginAttempted,
  setIsEmailLogin,
  setHasNewNotification,
  clearLoginAfterSignup
} = authSlice.actions;

export const authReducer = authSlice.reducer;
