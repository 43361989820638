import {
  DASHBOARD,
  HOME,
  LOGIN,
  SEARCH,
  SIGNUP,
  STAFFING,
  STAFFING_PRO_SEARCHES,
} from 'constants/routes';
import { useRouter } from 'next/router';
import { FunctionComponent, memo, useEffect, useMemo, useState } from 'react';
import { Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';
import Logo from 'components/Logo';
import Link from 'next/link';
import { NavLinkProps, PublicNavLinkProps } from 'constants/navbar';
import { USER_PEOPLE_SEARCH } from 'constants/gt-classes';
import { useAppSelector } from 'commons/hooks/redux';
import { selectCurrentUser } from 'store/auth/selectors';
import StaffingSubscriptionChoiceModal from 'components/StaffingRoster/Modals/StaffingSubscriptionChoiceModal';
import SecondaryNavbar from './SecondaryNavbar';
import ProfileDropdown from './ProfileDropdown';
import CommunicationNavLinks from './CommunicationsNavLinks';

import NavIcon from './NavIcon';
import NavLink from '../Core/NavLink';

const GlobalNavBar: FunctionComponent<{
  mainNavLinks: NavLinkProps[];
  publicNavLinks: PublicNavLinkProps[];
  authenticated?: boolean;
  hideSecondaryNavBar: boolean;
}> = ({
  mainNavLinks,
  publicNavLinks,
  authenticated = false,
  hideSecondaryNavBar = false,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  /** To avoid hydration warnings
   * {@link https://nextjs.org/docs/messages/react-hydration-error}
   */
  useEffect(() => {
    setIsAuthenticated(authenticated);
  }, [authenticated]);

  const router = useRouter();
  const user = useAppSelector(selectCurrentUser);

  const secondaryLinks: Pick<NavLinkProps, 'label' | 'path'>[] = useMemo(
    () =>
      mainNavLinks.reduce((accumulator: any, currentValue: any) => {
        // show the secondary menu to all it's child menus i.e => Discover/Companies
        if (router.pathname.includes(currentValue.path)) {
          return currentValue.secondaryLinks;
        }
        return accumulator;
      }, []),
    [router.pathname, mainNavLinks],
  );

  const handleOnClickNav = (event: any, link: any) => {
    if (link.icon.includes('staffing')) {
      event?.stopPropagation();
      event?.preventDefault();
      const { userSubscriptions } = user;
      const existingSubscriptions = userSubscriptions.filter(
        ({ isDeleted }: any) => isDeleted !== true,
      );
      if (existingSubscriptions?.length > 0) {
        if (existingSubscriptions?.length > 1) setShow(true);
        else router.push(STAFFING_PRO_SEARCHES);
      } else {
        router.push('/Subscription/ManageSubscription');
      }
    } else {
      router.push(link.path);
    }
  };

  const [show, setShow] = useState(false);

  const renderStaffingNavLink = (link: NavLinkProps) => ( 
    <span
      key={link.path}
      onClick={(event: any) => handleOnClickNav(event, link)}
      className={
        router.asPath.includes(link.path) ||
        (link.path === STAFFING && router.asPath.includes('Subscription'))
          ? 'active-nav'
          : ''
      }
    >
      <NavLink
        key={link.path}
        path=''
        active={link.path === router.asPath}
        wrapperClass={
          link.label === 'My Network'
            ? 'my-network-menu'
            : link.path === router.asPath
            ? 'active-nav'
            : ''
        }
      >
        <NavIcon name={link.icon} />
        <span>{link.label}</span>
        {link.label === 'My Network' ? (
          <span
            className='badge send-invites ml-h'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              router.push('/MyNetwork/ReferralInvites');
            }}
          >
            send invites
          </span>
        ) : null}
      </NavLink>
    </span>
   );

  const mainNavLinksComponent = mainNavLinks.map((link: NavLinkProps) =>
    link.icon?.includes('staffing') ? (
      renderStaffingNavLink(link)
    ) : (
      <NavLink
        key={link.path}
        path={link.path}
        active={link.path === router.asPath}
        wrapperClass={
          link.label === 'My Network'
            ? 'my-network-menu'
            : link.path === router.asPath
            ? 'active-nav'
            : ''
        }
      >
        <NavIcon name={link.icon} />
        <span>{link.label}</span>
        {link.label === 'My Network' ? (
          <span
            className='badge send-invites ml-h'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              router.push('/MyNetwork/ReferralInvites');
            }}
          >
            send invites
          </span>
        ) : null}
      </NavLink>
    ),
  );

  const publicNavComponents = publicNavLinks?.map((link: PublicNavLinkProps) =>
    link.hasDropdown ? (
      <>
        <NavDropdown title={link.label} id='navbarScrollingDropdown'>
          {link.secondaryLinks?.map((subLink) => (
            <NavDropdown.Item key={`${subLink.path}`} href={subLink.path}>
              {subLink.label}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      </>
    ) : (
      <NavLink
        key={link.path}
        path={link.path}
        active={router.asPath.includes(link.path)}
        newTab={link.external}
      >
        {link.label}
      </NavLink>
    ),
  );

  const authLinksComponent = (
    <div className='d-flex'>
      <Link href={LOGIN}>
        <Button variant='login' className='mr-1'>
          Login
        </Button>
      </Link>
      <Link href={SIGNUP}>
        <Button variant='signup'>Sign Up</Button>
      </Link>
    </div>
  );

  return (
    <>
      <StaffingSubscriptionChoiceModal show={show} setShow={setShow} />
      <Navbar
        expand='lg'
        className={router.asPath.includes(STAFFING) ? 'fixed-nav' : ''}
      >
        <Navbar.Brand>
          <NavLink
            path={authenticated ? DASHBOARD : HOME}
            alias={HOME}
            active={router.asPath === HOME || router.asPath.includes(DASHBOARD)}
          >
            <Logo />
          </NavLink>
        </Navbar.Brand>

        {isAuthenticated && (
          <Nav>
            <NavLink
              key={SEARCH}
              path={SEARCH}
              wrapperClass={USER_PEOPLE_SEARCH}
            >
              <NavIcon name='search' />
              {/* <span>SEARCH</span> */}
            </NavLink>
          </Nav>
        )}
        <Navbar.Toggle aria-controls='basic-navbar-nav'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M5 6.75C5 6.33579 5.34822 6 5.77778 6L18.2222 6C18.6518 6 19 6.33579 19 6.75C19 7.16421 18.6518 7.5 18.2222 7.5L5.77778 7.5C5.34822 7.5 5 7.16421 5 6.75Z'
              fill='#fff'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M5 16.75C5 16.3358 5.34822 16 5.77778 16L18.2222 16C18.6518 16 19 16.3358 19 16.75C19 17.1642 18.6518 17.5 18.2222 17.5L5.77778 17.5C5.34822 17.5 5 17.1642 5 16.75Z'
              fill='#fff'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M5 11.75C5 11.3358 5.34822 11 5.77778 11L18.2222 11C18.6518 11 19 11.3358 19 11.75C19 12.1642 18.6518 12.5 18.2222 12.5L5.77778 12.5C5.34822 12.5 5 12.1642 5 11.75Z'
              fill='#fff'
            />
          </svg>
        </Navbar.Toggle>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='centered'>
            {isAuthenticated ? mainNavLinksComponent : publicNavComponents}
          </Nav>
          {isAuthenticated && (
            <Nav>
              <CommunicationNavLinks />
              <ProfileDropdown />
            </Nav>
          )}
          {!isAuthenticated && authLinksComponent}
        </Navbar.Collapse>
      </Navbar>
      {/* {!!publicSecondaryLinks.length && (
        <SecondaryNavbar navLinks={publicSecondaryLinks} />
      )} */}
      {!!secondaryLinks?.length && !hideSecondaryNavBar && (
        <SecondaryNavbar navLinks={secondaryLinks} />
      )}
    </>
  );
};

export default memo(GlobalNavBar);
