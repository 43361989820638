import { Activity } from 'getstream';
import { FunctionComponent } from 'react';
import timeSince, { formatIso8601DateToUTCDate } from 'utils/date-time';
import Typography from 'components/Core/StylesGuide/Typography';
import { useGetSubscriptionInfoQuery } from 'store/subscription/subscription.api';
import moment from 'moment';
import NotificationIcon from 'components/Subscription/NotificationIcon';

const SubscriptionSignupActivity: FunctionComponent<{
  activity: Activity;
}> = ({ activity }) => {
  const subscriptionId = +activity?.foreign_id?.split(':')[1].trim();
  const { data: subscriptionInfo } = useGetSubscriptionInfoQuery(
    {
      subscriptionId,
    },
    { skip: !subscriptionId },
  );

  return (
    <li>
      {activity.verb === 'subscriptionSignup' ? (
        <div className='notification-item'>
          <NotificationIcon subscriptionInfo={subscriptionInfo} />

          <div className='notification-content'>
            <Typography variant='p-md-d'>
              <>
                You started your HUSSLUP{' '}
                <b className='fw-600'>
                  {' '}
                  “{subscriptionInfo?.subscriptionName}“
                </b>{' '}
                plan on {moment(subscriptionInfo?.createdAt).format('M/D/YY')}.{' '}
              </>
            </Typography>
            <div className='timestamp'>
              {timeSince(formatIso8601DateToUTCDate(activity?.time))}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </li>
  );
};

export default SubscriptionSignupActivity;
