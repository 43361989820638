import { IconType } from 'components/Icon';
import { MouseEvent } from 'react';
import { KebabDropdownItem } from 'components/Core/KebabDropdown/KebabDropdown';
import config from 'config';

export interface IconProps {
  label: string;
  icon: IconType;
  color?: string;
  onClick?: () => void;
}

export interface ActionButtons {
  label?: string;
  onClick: (ev: MouseEvent<HTMLButtonElement>) => void;
  icon?: IconType;
  loading?: boolean;
  disabled?: boolean;
  wrapperClass?: string;
}

export interface IconDescription {
  label: string;
  icon: IconType;
}

interface IAttachmentGeneric {
  heading: string;
  type: 'credit' | 'creative' | 'connections' | 'default';
}

export interface IAttachmentCredit extends IAttachmentGeneric {
  type: 'credit';
  imgUrl?: string;
  description: string[];
}

export interface IAttachmentCreative extends IAttachmentGeneric {
  type: 'creative';
  videoUrl: string;
  description: string[];
  assetType: string;
}

export interface IAttachmentConnections extends IAttachmentGeneric {
  type: 'connections';
  connection: string[];
}

export interface IAttachmentDefaults extends IAttachmentGeneric {
  type: 'default';
  description: string | IconDescription;
}

export type MemberDropdownLabelType =
  | 'Connect'
  | 'Message'
  | 'Add to ProSearch'
  | 'Add to Contact List'
  | 'Add to Staffing Roster'
  | 'Share'
  | 'Copy Profile Link'
  | 'Report'
  | 'Notes';

export const memberCardDropDowns: KebabDropdownItem[] =
  config.enableStaffingRoster === 'true'
    ? [
        // {
        //   label: 'Connect',
        //   onClick: () => {},
        // },
        // { label: 'Message', onClick: () => {} },
        { label: 'Add to ProSearch', onClick: () => {}, disabled: false },
        { label: 'Add to Contact List', onClick: () => {}, disabled: false },
        // { label: 'Share', onClick: () => {} },
        { label: 'Add to Staffing Roster', onClick: () => {} },
        { label: 'Copy Profile Link', onClick: () => {} },
        { label: 'Report', onClick: () => {} },
      ]
    : [
        // {
        //   label: 'Connect',
        //   onClick: () => {},
        // },
        // { label: 'Message', onClick: () => {} },
        { label: 'Add to ProSearch', onClick: () => {}, disabled: false },
        { label: 'Add to Contact List', onClick: () => {}, disabled: false },
        // { label: 'Share', onClick: () => {} },
        { label: 'Copy Profile Link', onClick: () => {} },
        { label: 'Report', onClick: () => {} },
      ];

export const contactListDropdown: KebabDropdownItem[] = [
  {
    label: 'Connect',
    onClick: () => {},
  },
  { label: 'Message', onClick: () => {} },
  // { label: 'Share', onClick: () => {} },
  { label: 'Report', onClick: () => {} },
];
export const manageAccessDropDowns: KebabDropdownItem[] = [
  {
    label: 'Assign New List Owner',
    onClick: () => {},
  },
  { label: 'Share Personal Notes', onClick: () => {} },
  { label: 'Remove Access to Contact List', onClick: () => {} },
];
export const companyDropDown: KebabDropdownItem[] = [
  // {
  //   label: 'Share',
  //   onClick: () => {},
  // },
  {
    label: 'Report',
    onClick: () => {},
  },
];

export type AttachmentProps =
  | IAttachmentCredit
  | IAttachmentCreative
  | IAttachmentConnections
  | IAttachmentDefaults;
