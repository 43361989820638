import { useEffect } from 'react';
import Router, { NextRouter } from 'next/router';
// import { MAX_PAGE_CACHE_SECONDS } from 'constants/commons';

function saveScrollPos(asPath: string) {
  sessionStorage.setItem(
    `scrollPos:${asPath}`,
    JSON.stringify({
      x: window.scrollX,
      y: window.scrollY,
      lastVisited: Date.now(),
    }),
  );
}

export function resetScrollPos(asPath: string) {
  sessionStorage.setItem(
    `scrollPos:${asPath}`,
    JSON.stringify({
      x: 0,
      y: 0,
      lastVisited: Date.now(),
    }),
  );
}

function restoreScrollPos(asPath: string) {
  const json = sessionStorage.getItem(`scrollPos:${asPath}`);
  const scrollPos = json ? JSON.parse(json) : undefined;

  if (scrollPos) {
    // const currentTimeStamp = Date.now();
    // const timeDiff = currentTimeStamp - Number(scrollPos.lastVisited) || 0;
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    // if (timeDiff / 1000 >= MAX_PAGE_CACHE_SECONDS) {
    //   window.location.reload();
    //   resetScrollPos(asPath);
    //   return;
    // }

    window.scroll({
      top:
        asPath === '/' && isChrome && scrollPos.y >= 600
          ? Number(scrollPos.y + 235)
          : scrollPos.y,
      left: scrollPos.x,
      behavior: 'instant',
    } as any);

    // window.scroll({
    //   top: scrollPos.y,
    //   left: scrollPos.x,
    //   behavior: 'instant',
    // } as any);
  }
}

const useScrollRestoration = (router: NextRouter) => {
  useEffect(() => {
    if (!('scrollRestoration' in window.history)) return;
    let shouldScrollRestore = false;
    let isWinScrolled = false;
    window.history.scrollRestoration = 'manual';
    restoreScrollPos(router.asPath);

    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      saveScrollPos(router.asPath);
      delete event['returnValue'];
    };

    const onRouteChangeStart = () => {
      isWinScrolled && saveScrollPos(router.asPath);
    };

    const onWinScrolled = () => {
      isWinScrolled = true;
    };

    const onRouteChangeComplete = (url: string) => {
      if (isWinScrolled) {
        isWinScrolled = false;
      }
      if (shouldScrollRestore) {
        shouldScrollRestore = false;
        /**
         * Calling with relative url, not expected asPath, so this
         * will break if there is a basePath or locale path prefix.
         */
        restoreScrollPos(url);
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    window.addEventListener('scroll', onWinScrolled);
    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeComplete);
    Router.beforePopState(() => {
      shouldScrollRestore = true;
      return true;
    });

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
      window.removeEventListener('scroll', onWinScrolled);
      Router.events.off('routeChangeStart', onRouteChangeStart);
      Router.events.off('routeChangeComplete', onRouteChangeComplete);
      Router.beforePopState(() => true);
    };
  }, [router]);
};

export default useScrollRestoration;
