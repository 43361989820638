import { current } from '@reduxjs/toolkit';

import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  Comment,
  CreateCommentPayload,
  DeleteCommentPayload,
  DeleteCommentResponse,
  EditCommentPayload,
  EditCommentResponse,
  LikeCommentPayload,
} from 'commons/types/comment.type';
import { API_COMMENT, API_COMMENT_LIKE } from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
import PostUtils from 'utils/post';
import { Post } from 'commons/types/post.type';
import { postApiSlice } from './post.api';

export const commentApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getCommentById: builder.query<any, string | void>({
        query: (commentId) => `${API_COMMENT}/${commentId}`,
      }),
      createComment: builder.mutation<any, CreateCommentPayload>({
        query(payload) {
          return {
            url: API_COMMENT,
            method: HTTP_METHODS.POST,
            body: payload.body,
          };
        },

        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            const response = (await queryFulfilled) as any;
            if (response?.data?.commentId) {
              dispatch(
                postApiSlice.util.updateQueryData(
                  'getPostsWithTag',
                  props.getPostWithTagParams,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedPost = PostUtils.addCommentToPostList(
                        current(draft),
                        response.data,
                        props.body.postId,
                      );
                      const updatedDraft = {
                        ...current(draft),
                        items: [...updatedPost],
                      };

                      Object.assign(draft, updatedDraft);
                    }
                  },
                ),
              );

              dispatch(
                postApiSlice.util.updateQueryData(
                  'getPostById',
                  { postId: props.body.postId },
                  (draft) => {
                    if (current(draft)?.post_id) {
                      const updatedPost = PostUtils.addCommentToSinglePost(
                        current(draft),
                        response.data,
                        props.body.postId,
                      );
                      Object.assign(draft, updatedPost);
                    }
                  },
                ),
              );
            }
          } catch (err) {
            /* empty */
          }
        },
      }),
      editComment: builder.mutation<EditCommentResponse, EditCommentPayload>({
        query(payload) {
          return {
            url: `${API_COMMENT}/${payload.commentId}`,
            method: HTTP_METHODS.PATCH,
            body: payload.body,
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            const response = await queryFulfilled;
            if (response?.data?.success && response?.data?.data?.commentId) {
              dispatch(
                postApiSlice.util.updateQueryData(
                  'getPostsWithTag',
                  props.getPostWithTagParams,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedPost = PostUtils.updateCommentInPostList(
                        current(draft),
                        response.data.data,
                        props.postId,
                        props.commentLevel as number,
                      );
                      const updatedDraft = {
                        ...current(draft),
                        items: [...updatedPost],
                      };

                      Object.assign(draft, updatedDraft);
                    }
                  },
                ),
              );

              dispatch(
                postApiSlice.util.updateQueryData(
                  'getPostById',
                  { postId: props.postId },
                  (draft) => {
                    if (current(draft)?.post_id) {
                      const updatedPost = PostUtils.updateCommentInSinglePost(
                        current(draft),
                        response.data.data,
                        props.postId,
                        props.commentLevel as number,
                      );
                      Object.assign(draft, updatedPost);
                    }
                  },
                ),
              );
            }
          } catch {
            /* empty */
          }
        },
      }),
      deleteComment: builder.mutation<
        DeleteCommentResponse,
        DeleteCommentPayload
      >({
        query(payload) {
          return {
            url: `${API_COMMENT}/${payload.body.commentId}`,
            method: HTTP_METHODS.DELETE,
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            const response = await queryFulfilled;
            if (response?.data?.success) {
              dispatch(
                postApiSlice.util.updateQueryData(
                  'getPostsWithTag',
                  props.getPostWithTagParams,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedPost = PostUtils.removeCommentFromPostList(
                        current(draft),
                        props.body.commentId,
                        props.postId,
                        props.commentLevel as number,
                      );

                      const updatedDraft = {
                        ...current(draft),
                        items: [...updatedPost],
                      };

                      Object.assign(draft, updatedDraft);
                    }
                  },
                ),
              );

              dispatch(
                postApiSlice.util.updateQueryData(
                  'getPostById',
                  { postId: props.postId },
                  (draft) => {
                    if (current(draft)?.post_id) {
                      const updatedPost = PostUtils.removeCommentFromSinglePost(
                        current(draft),
                        props.body.commentId,
                        props.postId,
                        props.commentLevel as number,
                      );

                      Object.assign(draft, updatedPost);
                    }
                  },
                ),
              );
            }
          } catch {
            /* empty */
          }
        },
      }),
      likeComment: builder.mutation<any, LikeCommentPayload>({
        query(payload) {
          return {
            url: `${API_COMMENT_LIKE}/${payload.body.commentId}/like`,
            method: HTTP_METHODS.POST,
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          const patchListResult = dispatch(
            postApiSlice.util.updateQueryData(
              'getPostsWithTag',
              props.getPostWithTagParams,
              (draft) => {
                const updatedPost = PostUtils.addCommentLikeInPostList(
                  current(draft),
                  props.body.commentId,
                  props.postId,
                  props.currentUser,
                  props.commentLevel,
                );
                const updatedDraft = {
                  ...current(draft),
                  items: [...updatedPost],
                };
                Object.assign(draft, updatedDraft);
              },
            ),
          );
          const patchSinglePostResult = dispatch(
            postApiSlice.util.updateQueryData(
              'getPostById',
              { postId: props.postId },
              (draft) => {
                const updatedPost = PostUtils.addCommentLikesInSinglePost(
                  current(draft),
                  props.body.commentId,
                  props.postId,
                  props.currentUser,
                  props.commentLevel,
                );

                Object.assign(draft, updatedPost);
              },
            ),
          );
          try {
            await queryFulfilled;
          } catch (err) {
            /**
             * @TODO Fix
             * {@link onQueryStarted} is running multiple times
             * That is why is check is required for perfoming {@link patchResult.undo}
             */

            const previousPost: Comment[] =
              patchListResult.inversePatches[0]?.value?.find(
                (post: Post) => post.post_id === props.postId,
              )?.comment;

            const wasPreviouslyLiked =
              previousPost
                ?.find((cmt) => cmt.commentId === props.body.commentId)
                ?.userLikes.some(
                  (cmt) => cmt.husslupId === props.currentUser.husslupId,
                ) || false;

            if (wasPreviouslyLiked) {
              patchListResult.undo();
            }
            const previousSinglePost: Comment[] =
              patchSinglePostResult.inversePatches[0]?.value;
            const wasPreviousSinglePostLiked =
              previousSinglePost
                ?.find((cmt) => cmt.commentId === props.body.commentId)
                ?.userLikes.some(
                  (cmt) => cmt.husslupId === props.currentUser.husslupId,
                ) || false;

            if (wasPreviousSinglePostLiked) {
              patchSinglePostResult.undo();
            }
          }
        },
      }),
      unLikeComment: builder.mutation<any, LikeCommentPayload>({
        query(payload) {
          return {
            url: `${API_COMMENT_LIKE}/${payload.body.commentId}/like`,
            method: HTTP_METHODS.DELETE,
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          const patchListResult = dispatch(
            postApiSlice.util.updateQueryData(
              'getPostsWithTag',
              props.getPostWithTagParams,
              (draft) => {
                const updatedPost = PostUtils.removeCommentLikeInPostList(
                  current(draft),
                  props.body.commentId,
                  props.postId,
                  props.currentUser,
                  props.commentLevel,
                );
                const updatedDraft = {
                  ...current(draft),
                  items: [...updatedPost],
                };

                Object.assign(draft, updatedDraft);
              },
            ),
          );
          const patchSinglePostResult = dispatch(
            postApiSlice.util.updateQueryData(
              'getPostById',
              { postId: props.postId },
              (draft) => {
                if (current(draft)?.post_id) {
                  const updatedPost = PostUtils.removeCommentLikesInSinglePost(
                    current(draft),
                    props.body.commentId,
                    props.postId,
                    props.currentUser,
                    props.commentLevel,
                  );
                  Object.assign(draft, updatedPost);
                }
              },
            ),
          );
          try {
            await queryFulfilled;
          } catch (err) {
            /**
             * @TODO Fix
             * {@link onQueryStarted} is running multiple times
             * That is why is check is required for perfoming {@link patchResult.undo}
             */
            const previousPost: Comment[] =
              patchListResult.inversePatches[0].value?.find(
                (post: Post) => post.post_id === props.postId,
              )?.comment;

            const wasPreviouslyLiked =
              previousPost
                ?.find((cmt) => cmt.commentId === props.body.commentId)
                ?.userLikes.some(
                  (cmt) => cmt.husslupId === props.currentUser.husslupId,
                ) || false;

            if (!wasPreviouslyLiked) {
              patchListResult.undo();
            }
            const previousSinglePost: Comment[] =
              patchSinglePostResult.inversePatches[0].value;
            const wasPreviousSinglePostLiked =
              previousSinglePost
                .find((cmt) => cmt.commentId === props.body.commentId)
                ?.userLikes.some(
                  (cmt) => cmt.husslupId === props.currentUser.husslupId,
                ) || false;

            if (!wasPreviousSinglePostLiked) {
              patchSinglePostResult?.undo();
            }
          }
        },
      }),
      getCommentLikedUserLists: builder.query<any, number>({
        query: (commentId) => `${API_COMMENT_LIKE}/${commentId}/likes`,
      }),
    };
  },
});

export const {
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useEditCommentMutation,
  useGetCommentByIdQuery,
  useLazyGetCommentByIdQuery,
  useLikeCommentMutation,
  useUnLikeCommentMutation,
  useLazyGetCommentLikedUserListsQuery,
} = commentApiSlice;
