export const truncateWithEllipse = (
  userName: string = '',
  trimAfter: number = 30,
): string => {
  if (userName.length > trimAfter) {
    return `${userName.substring(0, trimAfter).trim()}...`;
  }
  return userName;
};

export const capitalizeFirstLetter = (word: string) =>
  !word
    ? 'Need to pass word'
    : `${word
        .split('_')
        .join(' ')
        .toLocaleLowerCase()
        .replace('profile list', '')
        .charAt(1)
        .toUpperCase()
        .concat(
          word
            .split('_')
            .join(' ')
            .toLocaleLowerCase()
            .replace('profile list', '')
            .slice(2),
        )}`;

const concatIfExists = (elements: string[], delimeter = ' ') => {
  if (elements && elements.length) {
    return elements.filter(Boolean).join(delimeter);
  }
  return '';
};

export const convertToTitleCase = (text: string) => {
  if (!text) {
    return '';
  }
  return text.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(),
  );
};

export const getFirstLetterFromString = (text: string) =>
  text.split(' ').reduce((a: string, b: string) => {
    a += b[0].toUpperCase();
    return a;
  }, '');

export const TextUtils = {
  truncateWithEllipse,
  capitalizeFirstLetter,
  concatIfExists,
};
