import React from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { FormikHelpers, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import Button, { ButtonTypes, ButtonVariants } from 'components/Core/Button';
import Input from 'components/Core/Input';
import Typography from 'components/Core/StylesGuide/Typography';
import sendInviteSchema from 'commons/schemas/send-invite.schema';
import { useSaveEmailInviteMutation } from 'store/invite';
import { getEmailInviteString } from 'utils/invite';
import ToasterUtils from 'utils/toaster';
import { USER_SENDS_A_REFERRAL_INVITES } from 'constants/gt-classes';
import { useAppSelector } from 'commons/hooks/redux';
import { selectReferalTemplate } from 'store/global-app-config';
import * as gtag from '../../../utils/gtag';

export interface SendInviteModalProps {
  onClose: () => void;
  show: boolean;
  disabled?: boolean;
  loading?: boolean;
}

type SendInviteData = {
  firstName: string;
  lastName: string;
  email: string;
};

const sendInviteInitialValues: SendInviteData = {
  firstName: '',
  lastName: '',
  email: '',
};

export const SendInviteModal = ({
  show,
  onClose,
  disabled,
  loading,
}: SendInviteModalProps) => {
  const [saveEmailInvite, { isLoading }] = useSaveEmailInviteMutation();
  const { subject, body } = useAppSelector(selectReferalTemplate);
  const { t } = useTranslation('misc');
  const handleSendInvite = async (
    values: SendInviteData,
    actions: FormikHelpers<SendInviteData>,
  ) => {
    actions.setSubmitting(true);
    const response = await saveEmailInvite({
      email: values.email,
      name: `${values.firstName} ${values.lastName}`,
    }).unwrap();
    if (response?.id) {
      window.location.href = getEmailInviteString({
        name: values.firstName,
        email: values.email,
        subject,
        body,
      });
      // ToasterUtils.successToast('Email invite sent', {
      //   hideCloseButton: true,
      // });
      actions.resetForm();
      gtag.event({
        label: 'User sends referral invites',
        category: 'Referral Invite',
        action: 'USER_SENDS_A_REFERRAL_INVITES',
      });
      onClose();
    } else {
      ToasterUtils.errorToast(t('SEND_REFERAL_INVITE_ERROR.value'));
    }
    actions.setSubmitting(false);
  };

  const { handleSubmit, getFieldProps, errors, touched } = useFormik({
    initialValues: sendInviteInitialValues,
    onSubmit: handleSendInvite,
    validationSchema: sendInviteSchema,
  });

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Typography variant='h2'>Send Invite</Typography>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Input
                id='send-invite-first-name'
                required
                labelText='First Name*'
                error={
                  errors.firstName && touched.firstName ? errors.firstName : ''
                }
                {...getFieldProps('firstName')}
              />
            </Col>
            <Col>
              <Input
                id='send-invite-last-name'
                required
                labelText='Last Name*'
                error={
                  errors.lastName && touched.lastName ? errors.lastName : ''
                }
                {...getFieldProps('lastName')}
              />
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col>
              <Input
                id='send-invite-email'
                required
                labelText='Email*'
                error={errors.email && touched.email ? errors.email : ''}
                {...getFieldProps('email')}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={ButtonVariants.secondary}
            label='Cancel'
            onClick={onClose}
            disabled={disabled || isLoading}
          />
          <Button
            variant={ButtonVariants.primary}
            label='Create Email'
            type={ButtonTypes.submit}
            disabled={disabled || isLoading}
            loading={loading || isLoading}
            className={USER_SENDS_A_REFERRAL_INVITES}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
