import { ChangeEventHandler, FocusEventHandler, useState } from 'react';
import Form from 'react-bootstrap/Form';

export enum InputTypes {
  password = 'password',
  text = 'text',
  email = 'email',
  textArea = 'textarea',
  number = 'number',
}

interface IProps {
  initialValue?: string;
  type?: InputTypes;
  labelText?: string;
  required?: boolean;
  placeholder?: string;
  error?: any;
  disabled?: boolean;
  id: string;
  name: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onInputFieldChange?: (name: string, dateOnly: string) => void;
  hideErrorText?: boolean;
  wrapperClass?: string;
  className?: string;
  addOns?: string;
  readonly?: boolean;
  onInput?: ChangeEventHandler<HTMLInputElement>;
}

/**
 * ## General user input to be used in forms.
 * *This component uses `<form-control>` from React Bootstrap internally. So please take a look also there at their extensive documentation. *
 * **Give textual form controls like s and s an upgrade with custom styles, sizing, focus states, and more**
    <br>`<input type="text">` defines a single-line text input field:
 */
const Input = (props: IProps) => {
  const {
    labelText,
    required,
    placeholder,
    error,
    disabled,
    type = InputTypes.text,
    id,
    name,
    value,
    onChange,
    onBlur,
    initialValue,
    hideErrorText,
    wrapperClass,
    className,
    addOns = '',
    readonly,
    onInput,
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  let inputView = (
    <Form.Group
      className={`form-group ${error && 'error'} ${wrapperClass ?? ''}`}
    >
      <Form.Label className={error && 'text-error'}>{labelText}</Form.Label>
      {addOns ? (
        <div className='input-group'>
          <span className='input-group-text'>{addOns}</span>
          <Form.Control
            id={id}
            name={name}
            type={type || InputTypes.text}
            value={value}
            placeholder={placeholder}
            className={className || ''}
            onChange={onChange}
            required={!!required}
            disabled={disabled}
            onBlur={onBlur}
            defaultValue={initialValue}
            isInvalid={Boolean(error)}
            autoComplete='off'
            onInput={onInput}
          />
          {error && (
            <Form.Control.Feedback type='invalid'>
              {error}
            </Form.Control.Feedback>
          )}
        </div>
      ) : (
        <>
          <Form.Control
            id={id}
            name={name}
            type={type || InputTypes.text}
            value={value}
            placeholder={placeholder}
            className={className || ''}
            onChange={onChange}
            required={!!required}
            disabled={disabled}
            onBlur={onBlur}
            defaultValue={initialValue}
            isInvalid={Boolean(error)}
            autoComplete='off'
            onInput={onInput}
          />
          {error && (
            <Form.Control.Feedback type='invalid'>
              {error}
            </Form.Control.Feedback>
          )}
        </>
      )}
    </Form.Group>
  );

  if (type && type === InputTypes.password) {
    inputView = (
      <Form.Group
        className={` form-group ${error && 'error'} ${wrapperClass ?? ''}`}
      >
        <Form.Label className={error && 'text-error'}>{labelText}</Form.Label>
        <Form.Control
          id={id}
          name={name}
          type={showPassword ? InputTypes.text : InputTypes.password}
          placeholder={placeholder}
          value={value}
          className={className || ''}
          onChange={onChange}
          required={!!required}
          disabled={disabled}
          onBlur={onBlur}
          defaultValue={initialValue}
          isInvalid={Boolean(error)}
          autoComplete='off'
          onInput={onInput}
        />
        <span
          role='presentation'
          className={`hic-eye${!showPassword ? '-false' : ''} hic-1x`}
          onClick={handleShowPassword}
        />
        {error && !hideErrorText && (
          <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
        )}
      </Form.Group>
    );
  } else if (type && type === InputTypes.textArea) {
    inputView = (
      <Form.Group
        className={`form-group ${error && 'error'} ${wrapperClass ?? ''}`}
      >
        <Form.Label className={error && 'text-error'}>{labelText}</Form.Label>
        <Form.Control
          id={id}
          as='textarea'
          name={name}
          placeholder={placeholder}
          value={value}
          className={`input form-textarea ${className}`}
          onChange={onChange}
          required={!!required}
          disabled={disabled}
          onBlur={onBlur}
          defaultValue={initialValue}
          isInvalid={Boolean(error)}
          readOnly={readonly}
          autoComplete='off'
          onInput={onInput}
        />
        {error && !hideErrorText && (
          <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
        )}
      </Form.Group>
    );
  }

  return inputView;
};

export default Input;
