import { UserType } from 'store/user/types';
import { CompanyLocation } from 'utils/company';
import { WorkStatus, WorkStatusOption } from '../profile.type';
import { ProfileListOption } from './profile-list.type';

export type SaveAccountInfoAndHeadlines = {
  fullname?: string;
  firstName: string;
  lastName: string;
  professionalTagline: string;
  projectTagline: string;
  demographics: string;
  bio?: string;
};

export type SaveAccountInfoAndHeadlinesV2 = {
  [key: string]: string | boolean | ProfileListOption[] | undefined;
  fullname?: string;
  firstName: string;
  lastName: string;
  professionalTagline: string;
  projectTagline: string;
  bio?: string;
  demographics?: ProfileListOption[];
};

export interface EditProfileDetails {
  fullname?: string;
  firstName: string;
  lastName: string;
  professionalTagline: string;
  projectTagline: string;
  demographics?: string;
  bio?: string;
  workStatuses?: WorkStatus[];
}

export interface EditProfileFormDetails
  extends Omit<EditProfileDetails, 'workStatuses'> {
  workStatuses?: WorkStatusOption[];
  readyToHusslFromDate?: Date;
}

export type SaveAccountSocialMedia = {
  linkedinProfile: string;
  instagramProfile: string;
  twitterProfile: string;
  facebookProfile: string;
  tickTokProfile: string;
};

export type UploadProfilePictureResponse = {
  fileName: string;
};

export enum UserTypeEnum {
  ADMIN = 'admin',
  MEMBER = 'member',
  AMBASSADOR = 'ambassador',
  STAN = 'stan',
}

export type UserEntity = Partial<UserType> & {
  id: number;
  userType?: UserTypeEnum;
  credit?: string;
  connectionStatus?: string;
  projectTitle?: string;
  seasons?: string;
  group_name?: string;
  group_type?: string;
  membersQTY?: number;
  url?: string;
  userId?: number;
  username?: string;
  description?: string;
  assetFilename?: string;
  assetType?: string;
  project_type_headline?: string;
  genre_headline?: string;
  follower_count?: string;
  logo?: string;
  listMemberId?: string;
  isListMember?: boolean;
  title?: string;
  creditImg?: string;
  pro_headline?: string;
  note?: string;
  locations: CompanyLocation[];
  workStatuses?: WorkStatus[];
  workStatus?: string;
  userProfessionalTagline?: string;
  userProjectTagline?: string;
  userGenreTagline?: string;
  tier?: string;
};

export type BasicSearchDataType = {
  memberNames: boolean;
  profileHeadlines: boolean;
  memberBios: boolean;
  credits: boolean;
  creativeSamples: boolean;
  companies: boolean;
};

export type BasicSearchDropdownLabelState = {
  memberNames: string;
  profileHeadlines: string;
  memberBios: string;
  credits: string;
  creativeSamples: string;
  companies: string;
  communities: string;
};

export type ConnectionList = {
  id: string;
  connectionStatus: string;
  connectedUserId: number;
};
