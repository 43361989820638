import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  setMessageChannelId,
  setCreateMessageUsers,
  User,
} from 'store/messages/messages.slice';
import { DefaultGenerics, StreamChat } from 'stream-chat';

const asyncSetMessageScreen = createAsyncThunk<
  void,
  {
    sentBy: User;
    sendTo: User;
    chatClient: StreamChat<DefaultGenerics> | undefined;
  }
>(
  'messages/asyncSetMessageScreen',
  async ({ sentBy, sendTo, chatClient }, thunkArg) => {
    try {
      const response = await chatClient?.queryChannels({
        members: { $eq: [sendTo.id ?? '', sentBy.id ?? ''] },
      });

      if (response?.length && response.length > 0) {
        thunkArg.dispatch(setMessageChannelId({ channelId: response[0].id!! }));
        thunkArg.fulfillWithValue('fullfilled');
      } else {
        thunkArg.dispatch(setCreateMessageUsers([sendTo]));
        thunkArg.fulfillWithValue('fullfilled');
      }
    } catch (e) {
      thunkArg.rejectWithValue(e);
    }
  },
);

export default asyncSetMessageScreen;
