/* eslint-disable import/prefer-default-export */
import { defaultNetworkActiveRoutes } from 'commons/data/pages/my-network';
import { GetConnectionByLabelResponse } from 'commons/types/network.type';
import {
  USER_ACCEPTED_A_CONNECTION,
  USER_REQUESTED_A_CONNECTION,
} from 'constants/gt-classes';
import {
  MY_NETWORK_CONNECTIONS,
  MY_NETWORK_COMPANIES,
  MY_NETWORK_CONNECTION_REQUESTS,
  MY_NETWORK_REFERRAL_INVITES,
} from 'constants/routes';
import { ConnectionStatus } from 'store/user/types';

const updateRemovedConnection = (
  connections: GetConnectionByLabelResponse,
  removedConnectionHusslupId: string,
) => {
  const updatedConnections =
    connections.items?.map((connection) => {
      if (connection.husslupId === removedConnectionHusslupId) {
        return {
          ...connection,
          connectionStatus: ConnectionStatus.NONE,
        };
      }
      return connection;
    }) ?? [];

  return {
    ...connections,
    items: updatedConnections,
  };
};

const getActiveNetworkRoute = (pathname: string) => {
  switch (pathname) {
    case MY_NETWORK_CONNECTIONS: {
      return {
        ...defaultNetworkActiveRoutes,
        connections: true,
        heading: 'Connections',
      };
    }
    case MY_NETWORK_CONNECTION_REQUESTS: {
      return {
        ...defaultNetworkActiveRoutes,
        connectionRequests: true,
        heading: 'Connection Requests',
      };
    }
    case MY_NETWORK_REFERRAL_INVITES: {
      return {
        ...defaultNetworkActiveRoutes,
        referralInvites: true,
        heading: 'Referral Invites',
      };
    }
    case MY_NETWORK_COMPANIES: {
      return {
        ...defaultNetworkActiveRoutes,
        followedCompanies: true,
        heading: 'Followed Companies',
      };
    }
    default: {
      return { ...defaultNetworkActiveRoutes, heading: 'Connections' };
    }
  }
};

const getMemberCardWrapperClass = (connectStatus = ''): string =>
  connectStatus === '1' ? '' : USER_REQUESTED_A_CONNECTION;

const getUserConnectWrapperClass = (): string => USER_ACCEPTED_A_CONNECTION;

export const ConnectionUtils = {
  updateRemovedConnection,
  getActiveNetworkRoute,
  getMemberCardWrapperClass,
  getUserConnectWrapperClass,
};
