const Activities = {
  USER_MENTIONED_ON_A_POST: 'userMentionedOnAPost',
  USER_MENTIONED_ON_A_COMMENT: 'userMentionedOnAComment',
  TAGGED_ON_POST: 'post',
  VOUCH_REQUEST: 'vouchRequest',
  CONNECTION_REQUEST: 'connectionRequest',
  GROUP_INVITE_REQUEST: 'groupInviteRequest',
  COMMENTS_ON_POST: 'commentsOnPost',
  LIKES_POST: 'likesPost',
  CONTACT_SHEET_OWNERSHIP_RECEIVED: 'userReceivedASheetAccess',
  CONTACT_SHEET_OWNERSHIP_TRANSFERRED: 'contactSheetOwnerShipTransferred',
  STAFFING_ROSTER_SHARE_ACCESS: 'staffingRosterShared',
  STAFFING_ROSTER_ACCESS_REMOVED: 'staffingRosterAccessRemoved',
  STAFFING_ROSTER_TRANSFERRED_OWNERSHIP: 'staffingRosterTransferredOwnership',
  STAFFING_ROSTER_REFRAME_SUBMIT: 'staffingRosterReframeSubmit',
  SUBSCRIPTION_INVITE_MEMEBRS_TO_ASSIGN_SEAT: 'addMemberToSubscription',
  SUBSCRIPTION_REMOVE_MEMBER: 'removeMemberFromSubscription',
  SUBSCRIPTION_ASSIGN_MEMBER: 'assignNewOwnerToSubscription',
  SUBSCRIPTION_PLAN_CHANGED: 'subscriptionPlanChanged',
  SUBSCRIPTION_SIGNUP:'subscriptionSignup'
};

export default Activities;
