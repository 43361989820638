import { baseApiSlice } from 'commons/apis/base-api.config';
import { VouchCardProps } from 'components/ProfileDetails/EditProfile/Vouches/VouchCard';
import { UPDATE_VOUCH, USER_VOUCH } from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
import { transformGetVouchResp } from './transformResponse.api';
import {
  CreateUserVouchParams,
  GetVouchByIdParams,
  GetVouchByIdResponse,
  RequestForVouchBody,
  UserVouch,
  VouchType,
} from './types';

export const userVouchApi = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getVouchById: builder.query<GetVouchByIdResponse, GetVouchByIdParams>({
        query: ({ vouchId }) => ({
          url: `user-vouch/${vouchId}`,
        }),
        providesTags: ['VouchStatus'],
      }),
      getVouchesByHusslupId: builder.query<VouchCardProps[], number>({
        query: (husslupId: number) => `user-vouch?husslupId=${husslupId}`,
        transformResponse: transformGetVouchResp,
        providesTags: ['VouchStatus'],
      }),
      updateReorderAndHiddenStatus: builder.mutation<
        VouchCardProps[],
        VouchType[]
      >({
        query: (updateVouchesBody) => ({
          url: `user-vouch/save`,
          method: 'POST',
          body: {
            vouches: updateVouchesBody,
          },
        }),
      }),
      reorderVouches: builder.mutation<VouchCardProps[], VouchType[]>({
        query: (updateVouchesBody) => ({
          url: `user-vouch/v2/reorder`,
          method: 'POST',
          body: {
            vouches: updateVouchesBody,
            status: updateVouchesBody[0].status,
          },
        }),
      }),
      requestForVouch: builder.mutation<any, RequestForVouchBody>({
        query: (requestForVouchBody) => ({
          url: `vouch-request`,
          method: 'POST',
          body: requestForVouchBody,
        }),
      }),
      createVouch: builder.mutation<UserVouch, CreateUserVouchParams>({
        query: (createUserVouchParams) => ({
          url: 'user-vouch',
          method: 'POST',
          body: createUserVouchParams,
        }),
      }),
      updateVouch: builder.mutation<
        UserVouch,
        CreateUserVouchParams & { id: number }
      >({
        query: (updateVouchParams) => ({
          url: `${UPDATE_VOUCH}/${updateVouchParams.id}`,
          method: HTTP_METHODS.PATCH,
          body: {
            comment: updateVouchParams.comment,
            vouchToId: updateVouchParams.vouchToId,
          },
        }),
      }),
      updateVouchStatus: builder.mutation<
        any,
        { status: number; id: number; activityId?: string }
      >({
        query: (vouch) => ({
          url: `${USER_VOUCH}/${vouch.id}`,
          method: HTTP_METHODS.PATCH,
          body: { status: vouch.status },
        }),
        invalidatesTags: ['VouchStatus'],
      }),
    };
  },
});

export const {
  useLazyGetVouchesByHusslupIdQuery,
  useGetVouchesByHusslupIdQuery,
  useUpdateReorderAndHiddenStatusMutation,
  useReorderVouchesMutation,
  useRequestForVouchMutation,
  useCreateVouchMutation,
  useUpdateVouchMutation,
  useUpdateVouchStatusMutation,
  useGetVouchByIdQuery,
} = userVouchApi;
