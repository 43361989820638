import React from 'react';
import Highlighter from 'react-highlight-words';

const highlightText = (text: string, searchText: string) => {
  const highlighted = (
    <Highlighter
      highlightClassName='highlight-text'
      searchWords={[searchText]}
      autoEscape={true}
      textToHighlight={text}
    />
  );

  return highlighted;
};

export default highlightText;
