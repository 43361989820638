/* eslint-disable */
import { MemberChickletType } from 'components/Chicklet';
import KebabDropdown, {
  KebabDropdownItem,
} from 'components/Core/KebabDropdown/KebabDropdown';
import HusslUpIcon from 'components/Icon';
import React, { ChangeEvent, FunctionComponent, useMemo } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import ProfileUtils from 'utils/profile';
import { ActionButtons, AttachmentProps, IconProps } from '.';
import useProgressiveImage from 'components/Prosearch/common/ProgressiveImageHook';
import Typography from 'components/Core/StylesGuide/Typography';
import RecommendationDetails from './RecommendationDetails';
import { RECOMMENDATION_TEXT } from 'constants/app';

interface CardIProps {
  memberChickletStatus?: MemberChickletType | undefined;
  memberChickletProfessionalStatus?: 'Hired';
  onClick?: (ev: any) => void;
  onCheck?: (ev: ChangeEvent<HTMLInputElement>) => void;
  profileImgUrl?: string;
  headings?: string[];
  icons?: IconProps[];
  actionButtons?: ActionButtons[];
  fullName?: string;
  attachment?: AttachmentProps;
  dropdown?: KebabDropdownItem[];
  id?: number;
  husslupId?: string;
  checked?: boolean;
  searchText?: string;
  keyName?: string;
  wrapperClass?: string;
  fillBackground?: boolean;
  isStan?: boolean;
  calledBy?: string;
  recommendationTitle?: string;
}

const RecommendationCard: FunctionComponent<CardIProps> = ({
  actionButtons,
  headings,
  icons,
  profileImgUrl = '',
  memberChickletStatus,
  fullName,
  dropdown,
  onClick,
  id,
  husslupId = '',
  searchText,
  keyName,
  wrapperClass = '',
  fillBackground,
  isStan = false,
  calledBy,
  recommendationTitle,
}) => {
  const profileImage = useMemo(() => {
    if (profileImgUrl) {
      return ProfileUtils.getProfileImageWithoutMaskOriginal(
        profileImgUrl,
        238,
        fillBackground,
      );
    }
    return '/member-card-placeholder.jpg';
  }, [profileImgUrl]);

  const filteredOptions: KebabDropdownItem[] | undefined = useMemo(
    () =>
      isStan
        ? [...(dropdown || [])]?.map((drop) => {
            return {
              ...drop,
              disabled:
                drop.label.includes('Report') ||
                drop.label.includes('Block') ||
                drop.label.includes('Remove Connection'),
            };
          })
        : memberChickletStatus
        ? dropdown
        : [...(dropdown || [])]?.map((drop) => {
            return {
              ...drop,
              disabled:
                drop.label.includes('Report') ||
                drop.label.includes('Block') ||
                drop.label.includes('Copy Profile Link') ||
                drop.label.includes('Add to Contact List') ||
                drop.label.includes('Add to ProSearch') ||
                drop.label.includes('Label Connection...') ||
                drop.label.includes('Add to Staffing Roster'),
            };
          }),
    [isStan, dropdown],
  );

  const loaded = useProgressiveImage(profileImage);
  return (
    <div className={`recommendation-member-card ${wrapperClass ?? ''}`}>
      <div
        className='recommendation-member-card__top'
        style={{
          backgroundImage: `linear-gradient(0deg, #000 3.96%, rgba(0, 0, 0, 0.00) 29%), url(${loaded})`,
        }}
        onClick={onClick}
        tabIndex={0}
        role='button'
        onKeyUp={() => {}}
      ></div>
      <div className='recommendation-member-card__bottom'>
        <div className='recommendation-card-misc'>
          <RecommendationDetails
            chickletType={memberChickletStatus}
            name={fullName ?? ''}
            headings={headings}
            icons={icons}
            searchText={searchText}
            keyName={keyName}
            calledBy={calledBy}
          />
          <div className='recommendation-title'>
            <Typography
              variant='p-sm-sb'
              className='title'
              title={recommendationTitle}
              trimNumber={RECOMMENDATION_TEXT}
              lineClampNo={2}
            >
              {recommendationTitle}
            </Typography>
          </div>
        </div>
        <div className='recommendation-action-btns'>
          {(actionButtons?.length ?? 0) > 1 ? (
            <div className='multiple-btn__holder w-100'>
              {actionButtons?.map((ab, i) => (
                <Button
                  disabled={ab.disabled || !memberChickletStatus}
                  className={`card-btn multiple-btn ${
                    ab.wrapperClass ?? ab.wrapperClass
                  }`}
                  onClick={ab.onClick}
                  key={ab?.icon || ab?.label || i}
                >
                  {ab.loading ? (
                    <Spinner
                      as='span'
                      className='multiple-btn-loader'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    ab.icon && <HusslUpIcon name={ab.icon} />
                  )}
                  {ab.label}
                </Button>
              ))}
            </div>
          ) : (
            <div className='action-buttons__holder w-100'>
              {actionButtons?.map((ab, i) => (
                <Button
                  className={`card-btn ${ab.wrapperClass ?? ab.wrapperClass}`}
                  onClick={ab.onClick}
                  key={ab?.icon || ab?.label || i}
                  disabled={ab.disabled || !memberChickletStatus}
                >
                  {ab.loading ? (
                    <Spinner
                      as='span'
                      className=' '
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    ab.icon && <HusslUpIcon name={ab.icon} />
                  )}
                  {ab.label}
                </Button>
              ))}
            </div>
          )}
          {dropdown && filteredOptions && (
            <KebabDropdown
              dropdownItems={filteredOptions}
              align='end'
              drop='up'
              profileId={id}
              husslupId={husslupId}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default RecommendationCard;
