import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useState,
} from 'react';
import classNames from 'classnames';

interface IProps {
  placeholder?: string;
  onSearch: (ev: ChangeEvent<HTMLInputElement>) => void;
  variant?: string;
  value?: string;
}

const SearchInput: FunctionComponent<IProps> = ({
  onSearch,
  placeholder = 'Search here...',
  variant,
  value,
}): ReactElement => {
  const [clicked, setClicked] = useState(false);

  const onFocus = () => {
    setClicked(true);
  };

  return (
    <div
      className={classNames(
        'basic-search',
        variant,
        `${clicked ? 'basic-search--focus' : ''}`,
      )}
    >
      <i className='hic-search' />
      <input
        type='text'
        placeholder={placeholder}
        onChange={onSearch}
        value={value}
        onFocus={onFocus}
        onBlur={() => setClicked(false)}
      />
      {/* {Number(value?.length) > 0 && (
        <HusslUpIcon name='close' onClick={reset} aria-hidden='true' />
      )} */}
    </div>
  );
};

export default SearchInput;
