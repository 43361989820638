import { createSelector } from '@reduxjs/toolkit';
import { userLinkedInApiSlice } from '../user-linkedIn.api';

export const selectFetchedUserLinkedInProfileResults =
  userLinkedInApiSlice.endpoints.getUserLinkedInInfo.select();

export const selectFetchedUserLinkedInProfile = createSelector(
  selectFetchedUserLinkedInProfileResults,
  (state) => state.data,
);
