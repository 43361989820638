
import { FC, ReactElement } from 'react';

type LockUnlockPropsType = {
  ownerId?: number;
  lockUnlockBoolean: boolean;
};

const LockUnlockProsearch: FC<LockUnlockPropsType> = ({
  lockUnlockBoolean
}: LockUnlockPropsType): ReactElement => {
  const lockUnlock =
  lockUnlockBoolean ? (
      <span className='hic-unlock hic' />
    ) : (
      <span className='hic-lock hic' />
    );
  return lockUnlock;
};

export default LockUnlockProsearch;
