/* eslint-disable import/prefer-default-export */
import { WorkStatus, WorkStatusOption } from 'commons/types/profile.type';

export const WorkStatusOptions: WorkStatusOption[] = [
  {
    value: WorkStatus.FINANCING,
    label: 'Investing in Projects',
    text: 'Investing in Projects',
  },
  {
    value: WorkStatus.CURRENTLY_WORKING,
    label: 'Currently Working',
    text: 'Currently Working',
  },
  { value: WorkStatus.HIRING, label: 'Hiring', text: 'Hiring' },
  {
    value: WorkStatus.READY_TO_WORK,
    label: 'Available to Work',
    text: 'Available to Work',
  },
];

export const NUMBER_OF_CARDS_TO_SHOW = 9;
export const NUMBER_OF_CARDS_TO_SHOW_MOBILE = 3;
