/* eslint-disable react/no-danger */
import config from 'config';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Step } from 'react-joyride';
import { selectBaseApi } from 'commons/apis/base-api-selectors';
import { useAppSelector } from './redux';

interface IStepProps {
  [key: string]: Step[];
}

const useWebTour = () => {
  const router = useRouter();
  const getUrlPath = useMemo(() => {
    const isDynamicPath = router.asPath.match(/(\d+)/g)?.[0];

    if (isDynamicPath) {
      return router.asPath.replace(isDynamicPath, '*')?.replace('=', '_');
    }
    return router.asPath?.replace('=', '_');
  }, [router]);

  const cookiesPath = `webtour_${getUrlPath}`;
  const [run, setRun] = useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies([cookiesPath]);
  const [allSteps, setAllSteps] = useState<IStepProps | null>(null);
  const [isTourRunning, setIsTourRunning] = useState<boolean>(false);
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  const baseApi = useAppSelector(selectBaseApi);
  const basApiState = Object.values(baseApi).every(
    (status: any) => status?.status !== 'pending',
  );

  const setToCookie = (value: string) => {
    if (!basApiState) return;
    setRun(false);
    setCookie(cookiesPath, value, {
      expires: oneYearFromNow,
    });
  };

  const startWebTour = (): void => {
    removeCookie(cookiesPath);
    setRun(true);
  };

  const fetchStepsFromS3 = async () => {
    const response = await fetch(config.s3.webTourApi);
    const data = await response.json();
    if (data.success === true) {
      setAllSteps(data.data as IStepProps);
    }
  };

  const currentCookies = Boolean(cookies[cookiesPath]);
  const currentSteps = allSteps?.[`${getUrlPath?.replace('_', '=')}`] || [];

  useEffect(() => {
    !isTourRunning &&
      setRun(basApiState && currentSteps.length > 0 && !currentCookies);
  }, [currentCookies, basApiState, router]);

  useEffect(() => {
    if (allSteps === null) {
      fetchStepsFromS3();
    }
  }, []);

  return {
    steps: currentSteps?.map(
      (step: any, index: number, array: Step[]) =>
        ({
          ...step,
          disableBeacon: true,
          spotlightPadding: step?.spotlightPadding ? step.spotlightPadding : 0,
          floaterProps: {
            hideArrow:
              !step?.placement ||
              step?.target === null ||
              step?.target === 'body',
          },
          placement:
            // eslint-disable-next-line no-constant-condition
            step?.target === null || step?.target === 'body'
              ? 'center'
              : step?.placement || 'auto',
          target: step?.target === null ? 'body' : step?.target,
          title: (
            <div className='title-holder'>
              <div className='current'>
                {step.order} of {array.length}
              </div>{' '}
              <span dangerouslySetInnerHTML={{ __html: step.title }}></span>
            </div>
          ),
          content: (
            <div
              className='joyride-content'
              dangerouslySetInnerHTML={{ __html: step.content }}
            />
          ),
        } as Step),
    ),
    rawSteps: currentSteps,
    setCookie: setToCookie,
    currentCookies,
    showWebTour: basApiState && currentSteps.length > 0,
    stepRun: run,
    setRun,
    startWebTour,
    setIsTourRunning,
    isTourRunning,
  };
};

export default useWebTour;