import { FunctionComponent, useRef, ChangeEvent, MouseEvent } from 'react';

const CreatePostPdfUploader: FunctionComponent<{
  onPdfUpload: (file: File) => void;
}> = ({ onPdfUpload }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const triggerUploadAction = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    inputRef.current?.click();
  };

  const handlePdfUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event?.target?.files;
    if (uploadedFiles) {
      const firstUploadedFile = uploadedFiles[0];
      if (firstUploadedFile) {
        onPdfUpload(firstUploadedFile);
      }
    }
    event.target.value = '';
  };

  return (
    <button
      type='button'
      className='btn btn-text-only'
      onClick={triggerUploadAction}
    >
      <input
        ref={inputRef}
        className='d-none'
        onChange={handlePdfUpload}
        type='file'
        accept='application/pdf'
      />
       <i className='hic-pdf hic-2x'/>
    </button>
  );
};

export default CreatePostPdfUploader;
