import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  GetAcceptedInvitesParams,
  GetAcceptedInvitesResponse,
  GetSaveEmailInviteResponse,
  GetSentInvitesResponse,
  SaveEmailInviteBody,
  SearchInvitePayload,
  SearchInviteResponse,
} from 'commons/types/data';
import {
  API_INVITE,
  API_INVITE_ACCEPTED_INVITES,
  API_INVITE_EMAIL_INVITE_WEB,
  API_INVITE_PENDING_INVITES,
  API_INVITE_SEARCH_EMAIL_INVITE,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';

export const inviteApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      searchEmailInvite: builder.query<
        SearchInviteResponse,
        SearchInvitePayload
      >({
        query(payload) {
          return {
            url: `${API_INVITE}${API_INVITE_SEARCH_EMAIL_INVITE}`,
            method: HTTP_METHODS.GET,
            params: payload,
          };
        },
      }),
      getSentInvites: builder.query<
        GetSentInvitesResponse,
        GetAcceptedInvitesParams
      >({
        query(payload) {
          return {
            url: `${API_INVITE}${API_INVITE_PENDING_INVITES}`,
            method: HTTP_METHODS.GET,
            params: { ...payload, limit: 100 },
          };
        },
        providesTags: ['PendingInvites'],
      }),
      getAcceptedInvites: builder.query<
        GetAcceptedInvitesResponse,
        GetAcceptedInvitesParams
      >({
        query(payload) {
          return {
            url: `${API_INVITE}${API_INVITE_ACCEPTED_INVITES}`,
            method: HTTP_METHODS.GET,
            params: payload,
          };
        },
        providesTags: ['AcceptedReferral'],
      }),
      saveEmailInvite: builder.mutation<
        GetSaveEmailInviteResponse,
        SaveEmailInviteBody
      >({
        query(payload) {
          return {
            url: `${API_INVITE}${API_INVITE_EMAIL_INVITE_WEB}`,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        invalidatesTags: ['PendingInvites'],
      }),
    };
  },
});

export const {
  useSearchEmailInviteQuery,
  useLazySearchEmailInviteQuery,
  useGetSentInvitesQuery,
  useGetAcceptedInvitesQuery,
  useSaveEmailInviteMutation,
} = inviteApiSlice;
