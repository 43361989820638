export const APP_LINK = 'https://husslup.com/';

export const getEmailInviteMessage = (name: string, email: string = '') => {
  const names = name.split(' ');

  const firstName = names && names.length > 0 ? names[0] : name;

  const message =
    encodeURIComponent(`Hi ${firstName}, \nI am sending you one of my invites to join me on HUSSLUP, a professional network for creative talent to connect with each other and with production companies that have open roles. Thousands of amazing people are already on the invite-only platform.
  \nHere is the link to jump the waitlist and join ${APP_LINK}. Be sure to signup using your email ${email}. \nLet’s HUSSLUP`);

  return `mailto:${email}?subject=Join Husslup&body=${message}`;
};

export type GetEmailInviteString = {
  name: string;
  email: string;
  subject: string;
  body: string;
};

export const getEmailInviteString = ({
  name,
  email,
  subject,
  body,
}: GetEmailInviteString) => {
  const names = name.split(' ');

  const firstName = names && names.length > 0 ? names[0] : name;

  const message = encodeURIComponent(
    body.replace('[FIRST_NAME]', firstName).replace('[EMAIL]', email),
  );

  return `mailto:${email}?subject=${subject}&body=${message}`;
};
