import moment from 'moment';

const MINUTE_SECONDS = 60;
const HOUR_SECONDS = 3600; // 60 * 60
const DAY_SECONDS = 86400; // 60 * 60 * 24
const WEEK_SECONDS = 604800; // 60 * 60 * 24 * 7

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const getFullDate = (date: string, year?: number) => {
  const inputDateObject = new Date(date);
  const day = inputDateObject.getDate();
  const monthIndex = inputDateObject.getMonth();

  const monthName = monthNames[monthIndex];
  return year ? `${monthName} ${day}, ${year}` : `${monthName} ${day}`;
};

/**
 * Requirement
 *  now – 0 to 2 minutes ago
 * x min ago – 3 - 59 minutes ago
 * x hr ago – 1 - 23 hours ago
 * 1 day ago – 1 day ago
 * x days ago – 2-6 days ago
 * Jan 24 – more than 1 week ago in this calendar year
 * Jan 24, 2022 – more than one week ago in a previous calendar year
 * @param date Date String
 * @returns Formatted date format
 */
const timeSince = (date: any) => {
  if (date === 0) return '';
  const currentDate = new Date().valueOf();
  const inputDate = date ? new Date(date).valueOf() : currentDate;
  const seconds = Math.floor((currentDate - inputDate) / 1000);

  const thisYear = new Date().getFullYear();
  const inputYear = new Date(date).getFullYear();

  if (thisYear > inputYear) {
    return getFullDate(date, inputYear);
  }

  const weekInterval = Math.floor(seconds / WEEK_SECONDS);

  if (weekInterval >= 1) {
    return getFullDate(date);
  }

  const dayInterval = Math.floor(seconds / DAY_SECONDS);

  if (dayInterval > 2 && dayInterval <= 6) {
    return `${dayInterval} days ago`;
  }

  if (dayInterval > 0 && dayInterval <= 2) {
    return `${dayInterval} day ago`;
  }

  const hourInterval = Math.floor(seconds / HOUR_SECONDS);

  if (hourInterval > 1 && hourInterval <= 24) {
    return `${hourInterval} hours ago`;
  }

  if (hourInterval > 0 && hourInterval <= 1) {
    return `${hourInterval} hour ago`;
  }

  const minInterval = Math.floor(seconds / MINUTE_SECONDS);
  if (minInterval > 2 && minInterval <= 59) {
    return `${minInterval} minutes ago`;
  }

  if (minInterval <= 2) {
    return 'now';
  }

  return `${Math.floor(seconds)} seconds`;
};

export const oldTimeSince = (date: any) => {
  const currentDate = new Date().valueOf();
  console.log('CURRENT DATE', currentDate);
  const inputDate = new Date(date).valueOf();
  console.log('INPUT DATE', inputDate);
  const seconds = Math.floor((currentDate - inputDate) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)}y`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)}m`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)}d`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)}h`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)}m`;
  }
  return `${Math.floor(seconds)}s`;
};

export const numberToDay = (number: number) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  if (number > 7)
    throw new Error('There cannot be more than 7 days in a week.');

  return days[number];
};

/**
 * 
 * @param dateRange 
 * @examples
 * *************************************************
 * const dateRange1 = "2022 - 2023";
 * const formattedDateRange1 = formatDateRange(dateRange1); // formattedDateRange1: string
 * console.log(formattedDateRange1); // Output: (2022 - 2023)

 * const dateRange2 = "2022 - 2022";
 * const formattedDateRange2 = formatDateRange(dateRange2); // formattedDateRange2: string
 * console.log(formattedDateRange2); // Output: 2022

 * const dateRange3 = "(2022)";
 * const formattedDateRange3 = formatDateRange(dateRange3); // formattedDateRange3: string
 * console.log(formattedDateRange3); // Output: (2022)
 * *************************************************
 */
export const formatYearsForCredit = (dateRange: string): string => {
  const bracketsRegex = /^\((.*)\)$/;
  const match = dateRange.match(bracketsRegex);

  // If date range already has brackets, recursively remove them before formatting
  if (match !== null) {
    return formatYearsForCredit(match[1]);
  }

  // Otherwise, determine the separator used in the date range and split into start and end years
  const separator = dateRange.includes('-') ? '-' : '–'; // check if hyphen or en dash separator is used
  const [startYear, endYear] = dateRange.split(separator);

  // If start or end year is missing or if start and end year are the same, return the input date range as is
  if (!startYear || !endYear || startYear === endYear) {
    return dateRange;
  }

  // Otherwise, format the date range with parentheses and return it
  return `(${startYear.trim()} ${separator} ${endYear.trim()})`;
};

export const formatIso8601DateToUTCDate = (date: string) => `${date}Z`;

/**
 *
 * @param dateString Date string
 * @returns Thursday, May 21 format date string
 */
export const getMessageGroupDate = (dateString: string | Date) => {
  const date = new Date(dateString ?? '');

  const formattedDate = date.toLocaleDateString('en-US', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  });
  return formattedDate;
};

/**
 *
 * @param dateString Date string
 * @returns MM-dd-yyyy format date string
 */
export const formatHusslDate = (dateString: string | Date) => {
  const date = new Date(dateString ?? '');

  // Get year, month, and day part from the date
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.toLocaleString('default', { day: '2-digit' });
  return `${month}-${day}-${year}`;
};

export const getYearArrayForResume = (selectedValue: string) => {
  const arr = [];
  const today = new Date().getFullYear();

  for (let i = 1950; i <= today; i++) {
    arr.push({
      url: `${i}`,
      label: `${i}`,
      disable: selectedValue === `${i}`,
    });
  }
  return arr;
};

export const getMonthArrayForResume = (selectedValue: string) => {
  const arr = [];
  const today = 12;
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  for (let i = 1; i <= today; i++) {
    arr.push({
      url: `${i}`,
      // label: `${i} - ${months[i - 1]}`,
      label: `${months[i - 1]}`,
      disable: selectedValue === `${i}`,
    });
  }
  return arr;
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const formatMonth = (number: number): string =>
  `${number} - ${months[number - 1]}`;

export const formatMonthOnly = (number: number): string =>
  `${months[number - 1]}`;

export const subscriptionTrialRemainingDays = (date: string) => {
  if (!date) return 14;

  const now = moment();
  const subscription = moment(date);
  const daysDifference = now.diff(subscription, 'days', true);
  const remainingDays = Math.ceil(14 - daysDifference);
  return remainingDays;
};

export default timeSince;
