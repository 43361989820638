import { ScriptType } from 'store/user/creative/types';

export interface CreateUserCreativeSample {
  title: string;
  description: string;
  permission?: CreativeSamplePermissionType;
  isVisible?: boolean;
  thumbnailUrl?: string;
  selectedAssetType?: string;
  selectedAssetSubType?: ScriptType;
  order?: number;
  asset: { id: number }[];
}

export enum CreativeSamplePermissionType {
  CONNECTIONS = 'connections',
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export interface CreativeAsset {
  id: number;
  originalFilename: string;
  assetFilename: string;
  url: string;
  assetType: string;
}

export interface UpdateUserCreativeSample
  extends Partial<CreateUserCreativeSample> {
  id?: number;
}

export interface CreativePortfolioSliceState {
  assetUpload?: {
    progress: number;
  };
}
