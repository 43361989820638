import { createSelector } from '@reduxjs/toolkit';
import {
  defaultNetworkActiveRoutes,
  InitialConnectionSliceState,
} from 'commons/data/pages/my-network';

import { RootState } from 'commons/types/redux';
import { ConnectionsSliceState } from '../connections.slice';

export const selectConnectionsState = (state: RootState) =>
  state.connections as ConnectionsSliceState;

export const selectGetConnectionsByLabelParams = createSelector(
  selectConnectionsState,
  (state) => state.getConnectionsByLabelParams,
);

export const selectGetConnectionRequestsParams = createSelector(
  selectConnectionsState,
  (state) => state.getConnectionRequestsParams,
);

export const selectGetFollowedCompaniesParams = createSelector(
  selectConnectionsState,
  (state) => state.getFollowedCompaniesParams,
);

export const selectGetAcceptedInvitesParams = createSelector(
  selectConnectionsState,
  (state) => state.getAcceptedInvitesParams,
);

export const selectSelectedReferralInviteTab = createSelector(
  selectConnectionsState,
  (state) => state.selectedReferralInviteTab,
);

export const selectActiveConnectionLabel = createSelector(
  selectGetConnectionsByLabelParams,
  (state) => state.connectionLabel,
);

export const selectActiveConnectionRequests = createSelector(
  selectGetConnectionRequestsParams,
  (state) => state?.requestType,
);

export const selectConnectionsActionParams = createSelector(
  selectConnectionsState,
  (state) => state.actionParams,
);

export const selectCompanyActionParams = createSelector(
  selectConnectionsState,
  (state) => state.companyActionsParams,
);

export const selectNetworkActiveTab = (
  activeRoute: typeof defaultNetworkActiveRoutes,
) =>
  createSelector(
    selectActiveConnectionLabel,
    selectActiveConnectionRequests,
    selectSelectedReferralInviteTab,
    (
      activeConnectionLabel,
      activeConnectionRequestsLabel,
      selectedReferralInviteTab,
    ) => {
      if (activeRoute.connections) {
        return activeConnectionLabel;
      }
      if (activeRoute.connectionRequests) {
        return activeConnectionRequestsLabel;
      }
      if (activeRoute.referralInvites) {
        return selectedReferralInviteTab;
      }
      return '';
    },
  );

export const selectNetworkActiveSortBy = (
  activeRoute: typeof defaultNetworkActiveRoutes,
) =>
  createSelector(
    selectGetConnectionsByLabelParams,
    selectGetConnectionRequestsParams,
    selectGetAcceptedInvitesParams,
    selectGetFollowedCompaniesParams,
    (
      getConnectionLabelParams,
      getConnectionRequestsParams,
      referralInvitesParams,
      followedCompaniesParams,
    ) => {
      if (activeRoute.connections) {
        return getConnectionLabelParams?.sortBy ?? 'best-match';
      }
      if (activeRoute.connectionRequests) {
        return getConnectionRequestsParams?.sortBy ?? 'best-match';
      }
      if (activeRoute.referralInvites) {
        return referralInvitesParams?.sortBy ?? 'best-match';
      }
      if (activeRoute.followedCompanies) {
        return followedCompaniesParams?.sortBy ?? 'best-match';
      }
      return 'best-match';
    },
  );

export const selectActiveMyNetworkParams = (
  activeRoute: typeof defaultNetworkActiveRoutes,
) =>
  createSelector(
    selectGetConnectionsByLabelParams,
    selectGetConnectionRequestsParams,
    selectGetAcceptedInvitesParams,
    selectGetFollowedCompaniesParams,
    (
      getConnectionLabelParams,
      getConnectionRequestsParams,
      referralInvitesParams,
      followedCompaniesParams,
    ) => {
      if (activeRoute.connections) {
        return getConnectionLabelParams;
      }
      if (activeRoute.connectionRequests) {
        return getConnectionRequestsParams;
      }
      if (activeRoute.referralInvites) {
        return referralInvitesParams;
      }
      if (activeRoute.followedCompanies) {
        return followedCompaniesParams;
      }
      return InitialConnectionSliceState.getConnectionsByLabelParams;
    },
  );
