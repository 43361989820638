import React from 'react';
import { Spinner } from 'react-bootstrap';

const MyNetworkLoader = ({ wrapperclass = '' }) => (
  <div className={`my-network-no-data-container ${wrapperclass}`}>
    <Spinner />
  </div>
);

export default MyNetworkLoader;
