import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  API_SUBSCRIPTION_PLANS,
  API_USER_SUBSCRIPTIONS,
  API_USER_SUBSCRIPTIONS_ADD_SUBSCRIPTION,
  API_USER_SUBSCRIPTIONS_GET_USER_SUBSCRIPTION,
  API_USER_SUBSCRIPTIONS_GET_PUBLISHABLE_KEY,
  API_USER_SUBSCRIPTIONS_SUBSCRIBE_USER,
  API_USER_SUBSCRIPTIONS_GET_RECEIPTS_BY_SUBSCRIPTION,
  API_USER_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION,
  API_USER_SUBSCRIPTIONS_UPDATE_PAYMENT_METHOD,
  API_USER_SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_PLAN,
  API_USER_SUBSCRIPTIONS_UPDATE_RECIPIENT_EMAIL,
  API_USER_SUBSCRIPTIONS_RESUME_SUBSCRIPTION,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
import {
  GetUserSubscription,
  FinalizeSubscriptionBody,
  FinalizeSubscriptionResponse,
  InitializeSubscriptionBody,
  InitializeSubscriptionResponse,
  SubscriptionCreatePaymentBody,
  SubscriptionCreatePaymentResponse,
  SubscriptionPlan,
  SubscriptionReceipt,
  UpdatePaymentMethodResponse,
  UpdatePaymentMethodBody,
  UpdateSubscriptionPlanBody,
  UpdateSubscriptionResponse,
  UpdateRecipientEmailBody,
  ResumeSubscriptionBody,
} from './subscription.types';

export const subscriptionPaymentApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getSubscriptionPlans: builder.query<SubscriptionPlan[], void>({
        query: () => API_SUBSCRIPTION_PLANS,
      }),
      getStripePublishableKey: builder.query<string, void>({
        query: () => ({
          url: API_USER_SUBSCRIPTIONS_GET_PUBLISHABLE_KEY,
          responseHandler: 'text',
        }),
      }),
      createPayment: builder.mutation<
        SubscriptionCreatePaymentResponse,
        SubscriptionCreatePaymentBody
      >({
        query: (payload) => ({
          url: API_USER_SUBSCRIPTIONS,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
      }),
      getUserSubscription: builder.query<GetUserSubscription, void>({
        query: () => API_USER_SUBSCRIPTIONS_GET_USER_SUBSCRIPTION,
        providesTags: ['GetUserSubscription'],
      }),
      initializeSubscription: builder.mutation<
        InitializeSubscriptionResponse,
        InitializeSubscriptionBody
      >({
        query: (payload) => ({
          url: API_USER_SUBSCRIPTIONS_SUBSCRIBE_USER,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
      }),
      finalizeSubscription: builder.mutation<
        FinalizeSubscriptionResponse,
        FinalizeSubscriptionBody
      >({
        query: (payload) => ({
          url: API_USER_SUBSCRIPTIONS_ADD_SUBSCRIPTION,
          method: HTTP_METHODS.POST,
          body: payload,
        }),
        // invalidatesTags: ['GetUserSubscription'],
      }),
      getReceiptsBySubscription: builder.query<SubscriptionReceipt[], number>({
        query: (subscriptionId) =>
          `${API_USER_SUBSCRIPTIONS_GET_RECEIPTS_BY_SUBSCRIPTION}/${subscriptionId}`,
      }),
      cancelSubscription: builder.mutation<
        FinalizeSubscriptionResponse,
        number
      >({
        query: (subscriptionId) => ({
          url: `${API_USER_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION}/${subscriptionId}`,
          method: HTTP_METHODS.DELETE,
        }),
        invalidatesTags: ['GetUserSubscription', 'CurrentAuthUser'],
      }),
      updatePaymentMethod: builder.mutation<
        UpdatePaymentMethodResponse,
        UpdatePaymentMethodBody
      >({
        query: (payload) => ({
          url: `${API_USER_SUBSCRIPTIONS_UPDATE_PAYMENT_METHOD}/${payload.subscriptionId}`,
          method: HTTP_METHODS.PATCH,
          body: payload.body,
        }),
        invalidatesTags: ['GetUserSubscription'],
      }),
      updateSubscription: builder.mutation<
        UpdateSubscriptionResponse,
        UpdateSubscriptionPlanBody
      >({
        query: (payload) => ({
          url: `${API_USER_SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_PLAN}/${payload.subscriptionId}`,
          method: HTTP_METHODS.PATCH,
          body: payload.body,
        }),
        invalidatesTags: ['AssignedSubscriptionSeats'],
      }),
      updateRecipientEmail: builder.mutation<
        UpdateSubscriptionResponse,
        UpdateRecipientEmailBody
      >({
        query: (payload) => ({
          url: `${API_USER_SUBSCRIPTIONS_UPDATE_RECIPIENT_EMAIL}/${payload.subscriptionId}`,
          method: HTTP_METHODS.PATCH,
          body: payload.body,
        }),
        invalidatesTags: ['GetUserSubscription'],
      }),
      resumeSubscription: builder.mutation<
        UpdateSubscriptionResponse,
        ResumeSubscriptionBody
      >({
        query: (payload) => ({
          url: `${API_USER_SUBSCRIPTIONS_RESUME_SUBSCRIPTION}/${payload.subscriptionId}`,
          method: HTTP_METHODS.PATCH,
          body: payload.body,
        }),
        invalidatesTags: ['CurrentAuthUser', 'GetUserSubscription'],
      }),
    };
  },
});

export const {
  useGetSubscriptionPlansQuery,
  useGetStripePublishableKeyQuery,
  useGetUserSubscriptionQuery,
  useCreatePaymentMutation,
  useInitializeSubscriptionMutation,
  useFinalizeSubscriptionMutation,
  useGetReceiptsBySubscriptionQuery,
  useCancelSubscriptionMutation,
  useUpdatePaymentMethodMutation,
  useUpdateSubscriptionMutation,
  useUpdateRecipientEmailMutation,
  useResumeSubscriptionMutation,
} = subscriptionPaymentApiSlice;
