import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  defaultNetworkActiveRoutes,
  InitialConnectionSliceState,
} from 'commons/data/pages/my-network';
import { SearchSortOptions } from 'commons/types/base';
import { GetFollowedCompaniesParams } from 'commons/types/company.type';
import { GetAcceptedInvitesParams } from 'commons/types/data';
import {
  GetConnectionRequestsParams,
  GetConnectionsByLabelParams,
  RemoveConnectionType,
} from 'commons/types/network.type';
import { ConnectionLabel } from 'store/user/types';
import { followedCompanyBuilder } from './action-builders';
import updateConnectionBuilder from './action-builders/update-connection.action-builders';

const CONNECTIONS_SLICE_KEY = 'connections';

export type ConnectionActions =
  | 'label-connection'
  | 'message'
  | 'add-to-pro-search'
  | 'add-to-contact-list'
  | 'add-to-staffing-roster'
  | 'share'
  | 'copy-profile-link'
  | 'remove-connection'
  | 'report'
  | 'connect'
  | 'block';

export type CompanyActions = 'unfollow' | 'follow';

export type ReferralInviteTab = 'accepted' | 'pending';

export type ConnectionsActionParams = {
  userIdToBlock?: number;
  userHusslupIdToReport?: string;
  userIdToRemoveConnection?: string;
  removingConnectionType?: RemoveConnectionType;
  userIdToLabel?: string;
  userNameToLabel?: string;
  userSelectedLabelToLabel?: ConnectionLabel;
  commentedById?: number;
  action?: ConnectionActions;
  userId?: number;
  addToModalLabel?: string;
};

export type CompanyActionParams = {
  companyIdToUnfollow?: number;
  companyNameToUnfollow?: string;
  action?: CompanyActions;
};

export interface ConnectionsSliceState {
  getConnectionsByLabelParams: GetConnectionsByLabelParams;
  getConnectionRequestsParams: GetConnectionRequestsParams;
  getFollowedCompaniesParams: GetFollowedCompaniesParams;
  getAcceptedInvitesParams: GetAcceptedInvitesParams;
  selectedReferralInviteTab: ReferralInviteTab;
  actionParams: ConnectionsActionParams;
  companyActionsParams: CompanyActionParams;
}

const initialState: ConnectionsSliceState = InitialConnectionSliceState;

const connectionsSlice = createSlice({
  name: CONNECTIONS_SLICE_KEY,
  initialState,
  reducers: {
    setGetConnectionsWithLabelParam(
      state,
      action: PayloadAction<GetConnectionsByLabelParams>,
    ) {
      state.getConnectionsByLabelParams = action.payload;
    },
    setGetConnectionRequestsParam(
      state,
      action: PayloadAction<GetConnectionRequestsParams>,
    ) {
      state.getConnectionRequestsParams = action.payload;
    },
    setGetAcceptedInvitesParam(
      state,
      action: PayloadAction<GetAcceptedInvitesParams>,
    ) {
      state.getAcceptedInvitesParams = action.payload;
    },
    setGetFollowedCompaniesParam(
      state,
      action: PayloadAction<GetFollowedCompaniesParams>,
    ) {
      state.getFollowedCompaniesParams = action.payload;
    },
    setSelectedReferralInviteTab(
      state,
      action: PayloadAction<ReferralInviteTab>,
    ) {
      state.selectedReferralInviteTab = action.payload;
    },
    setConnectionsActionParams(
      state,
      action: PayloadAction<ConnectionsActionParams>,
    ) {
      state.actionParams = action.payload;
    },
    setCompanyActionParams(state, action: PayloadAction<CompanyActionParams>) {
      state.companyActionsParams = action.payload;
    },
    setGetNetworkParam(
      state,
      action: PayloadAction<
        { options: SearchSortOptions } & {
          activeRoute: typeof defaultNetworkActiveRoutes;
        }
      >,
    ) {
      if (action.payload.activeRoute.connections) {
        if (state.getConnectionsByLabelParams) {
          state.getConnectionsByLabelParams = {
            ...state.getConnectionsByLabelParams,
            ...action.payload.options,
            page: initialState.getConnectionsByLabelParams.page,
            nextPage: false,
          };
        }
      } else if (action.payload?.activeRoute?.connectionRequests) {
        if (state.getConnectionRequestsParams) {
          state.getConnectionRequestsParams = {
            ...state.getConnectionRequestsParams,
            ...action.payload.options,
          };
        }
      } else if (action.payload?.activeRoute?.followedCompanies) {
        if (state.getFollowedCompaniesParams) {
          state.getFollowedCompaniesParams = {
            ...state.getFollowedCompaniesParams,
            ...action.payload.options,
            page: initialState.getConnectionsByLabelParams.page,
            nextPage: false,
          };
        }
      } else if (action.payload?.activeRoute?.referralInvites) {
        if (state.getAcceptedInvitesParams) {
          state.getAcceptedInvitesParams = {
            ...state.getAcceptedInvitesParams,
            ...action.payload.options,
          };
        }
      }
    },
    setResetConnections: (state) => {
      (state.actionParams = InitialConnectionSliceState.actionParams),
        (state.companyActionsParams =
          InitialConnectionSliceState.companyActionsParams),
        (state.getAcceptedInvitesParams =
          InitialConnectionSliceState.getAcceptedInvitesParams),
        (state.getConnectionRequestsParams =
          InitialConnectionSliceState.getConnectionRequestsParams);
      (state.getConnectionsByLabelParams =
        InitialConnectionSliceState.getConnectionsByLabelParams),
        (state.getFollowedCompaniesParams =
          InitialConnectionSliceState.getFollowedCompaniesParams),
        (state.selectedReferralInviteTab =
          InitialConnectionSliceState.selectedReferralInviteTab);
    },
  },
  extraReducers: (builder) => {
    updateConnectionBuilder(builder);
    followedCompanyBuilder(builder);
  },
});

export const {
  setGetConnectionsWithLabelParam,
  setConnectionsActionParams,
  setGetConnectionRequestsParam,
  setGetAcceptedInvitesParam,
  setGetNetworkParam,
  setSelectedReferralInviteTab,
  setGetFollowedCompaniesParam,
  setCompanyActionParams,
  setResetConnections,
} = connectionsSlice.actions;

export const connectionsReducer = connectionsSlice.reducer;
