import { useEffect } from 'react';
import { setHasNewNotification } from 'store/auth/auth.slice';
import { selectHasNewNotification } from 'store/auth/selectors';
import useGetStreamActivity from './useGetstreamActivity';
import { useAppDispatch, useAppSelector } from '../redux';

const useRealTimeNotification = () => {
  const { activityFeedClient } = useGetStreamActivity();
  const dispatch = useAppDispatch();
  const hasNewActivity = useAppSelector(selectHasNewNotification);

  useEffect(() => {
    if (activityFeedClient) {
      activityFeedClient.subscribe((data: any) => {
        if (data?.new && data.new?.length) {
          dispatch(setHasNewNotification(true));
        }
      });
    }
    return () => activityFeedClient?.unsubscribe();
  }, [activityFeedClient]);

  return hasNewActivity;
};

export default useRealTimeNotification;
