import React, { FunctionComponent, ReactElement } from 'react';
import Typography, { TypographyVariant } from './Typography';

interface TypographySubType {
  subTitle: string;
  fontFamily: string;
  fontSize: string;
  typography: TypographyVariant;
}

interface TypographyType {
  title: string;
  sub: TypographySubType[];
}

interface TypoBoxProps {
  subDetail: TypographySubType;
}

const typographies: TypographyType[] = [
  {
    title: 'Heading',
    sub: [
      {
        subTitle: 'Heading 1',
        fontFamily: 'SF Pro Text Bold',
        fontSize: '36px / auto',
        typography: 'h1',
      },
      {
        subTitle: 'Heading 2',
        fontFamily: 'SF Pro Text Bold',
        fontSize: '28px / auto',
        typography: 'h2',
      },
      {
        subTitle: 'Heading 3',
        fontFamily: 'SF Pro Text Bold',
        fontSize: '24px / auto',
        typography: 'h3',
      },
    ],
  },
  {
    title: 'Paragraph',
    sub: [
      {
        subTitle: 'Small/Default',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '12px / 140%',
        typography: 'p-small-d',
      },
      {
        subTitle: 'SM/Default',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '14px / 140%',
        typography: 'p-sm-d',
      },
      {
        subTitle: 'SM/Medium',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '14px / 140%',
        typography: 'p-sm-md',
      },
      {
        subTitle: 'SM/Semibold',
        fontFamily: 'SF Pro Text Semibold',
        fontSize: '14px / 140%',
        typography: 'p-sm-sb',
      },
      {
        subTitle: 'SM/Bold',
        fontFamily: 'SF Pro Text Bold',
        fontSize: '14px / 140%',
        typography: 'p-sm-b',
      },
      {
        subTitle: 'SM/Italic',
        fontFamily: 'SF Pro Text Regular Italic',
        fontSize: '14px / 140%',
        typography: 'p-sm-i',
      },
      {
        subTitle: 'SM/Underline',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '14px / 140%',
        typography: 'p-sm-u',
      },
      {
        subTitle: 'SM/Caps',
        fontFamily: 'SF Pro Text Semi Bold',
        fontSize: '14px / 140%',
        typography: 'p-sm-c',
      },
      {
        subTitle: 'MD/Default',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '16px / 140%',
        typography: 'p-md-d',
      },
      {
        subTitle: 'MD/Medium',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '16px / 140%',
        typography: 'p-md-m',
      },
      {
        subTitle: 'MD/Semibold',
        fontFamily: 'SF Pro Text Semibold',
        fontSize: '16px / 140%',
        typography: 'p-md-sb',
      },
      {
        subTitle: 'MD/Bold',
        fontFamily: 'SF Pro Text Bold',
        fontSize: '16px / 140%',
        typography: 'p-md-b',
      },
      {
        subTitle: 'LG/Default',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px / 140%',
        typography: 'p-lg-d',
      },
      {
        subTitle: 'LG/Medium',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px / 140%',
        typography: 'p-lg-m',
      },
      {
        subTitle: 'LG/Semibold',
        fontFamily: 'SF Pro Text Semibold',
        fontSize: '18px / 140%',
        typography: 'p-lg-sb',
      },
      {
        subTitle: 'LG/Bold',
        fontFamily: 'SF Pro Text Bold',
        fontSize: '18px / 140%',
        typography: 'p-lg-b',
      },
      {
        subTitle: 'XLG/Default',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '20px / 140%',
        typography: 'p-xlg-d',
      },
      {
        subTitle: 'XLG/Semibold',
        fontFamily: 'SF Pro Text Semibold',
        fontSize: '20px / 140%',
        typography: 'p-xlg-sb',
      },
      {
        subTitle: 'XLG/Bold',
        fontFamily: 'SF Pro Text Bold',
        fontSize: '20px / 140%',
        typography: 'p-xlg-b',
      },
    ],
  },
  {
    title: 'Button Text',
    sub: [
      {
        subTitle: 'Default',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '14px / auto',
        typography: 'p-bt-d',
      },
      {
        subTitle: 'Semibold',
        fontFamily: 'SF Pro Text Semibold',
        fontSize: '14px / auto',
        typography: 'p-bt-sb',
      },
      {
        subTitle: 'Default Underline',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '14px / auto',
        typography: 'p-bt-du',
      },
    ],
  },
  {
    title: 'Small',
    sub: [
      {
        subTitle: 'Default',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '12px / auto',
        typography: 's-d',
      },
      {
        subTitle: 'Semibold',
        fontFamily: 'SF Pro Text Semibold',
        fontSize: '12px / auto',
        typography: 's-sb',
      },
      {
        subTitle: 'Caps Bold',
        fontFamily: 'SF Pro Text Bold',
        fontSize: '12px / auto',
        typography: 's-cb',
      },
      {
        subTitle: 'Caps Semi Bold',
        fontFamily: 'SF Pro Text Semibold',
        fontSize: '10px / auto',
        typography: 's-csb',
      },
    ],
  },
  {
    title: 'Link',
    sub: [
      {
        subTitle: 'Default',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '16px / 140%',
        typography: 'p-l-d',
      },
    ],
  },
];

const TypoBox: FunctionComponent<TypoBoxProps> = ({
  subDetail,
}): ReactElement => (
  <div className='typo-box'>
    <div className='typo-box-left'>
      <Typography variant='h3'>{subDetail.subTitle}</Typography>
      <div className='typo-detail-wrap'>
        <Typography variant='p-sm-d'>{subDetail.fontFamily}</Typography>
        <Typography variant='p-sm-d'>{subDetail.fontSize}</Typography>
      </div>
    </div>
    <div className='typo-box-right'>
      <Typography variant={subDetail.typography}>
        The quick brown fox jumps over the lazy dog.
      </Typography>
    </div>
  </div>
);

const TypoGroup: FunctionComponent<TypographyType> = ({ title, sub }) => (
  <div className='typo-group'>
    <Typography variant='h1'>{title}</Typography>
    {sub.map(
      (subDetail: TypographySubType): ReactElement => (
        <TypoBox key={subDetail.typography} subDetail={subDetail} />
      ),
    )}
  </div>
);

const TypographyReferences = (): ReactElement => (
  <div>
    {typographies.map(
      (typography: TypographyType): ReactElement => (
        <TypoGroup
          key={typography.title}
          title={typography.title}
          sub={typography.sub}
        />
      ),
    )}
  </div>
);

export default TypographyReferences;
