import React, { FunctionComponent, useMemo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import SquircleImage from 'components/Core/SquircleImage/SquircleImage';
import ProfileUtils from 'utils/profile';
import HusslUpIcon from 'components/Icon';
import Typography from 'components/Core/StylesGuide/Typography';
import { CompanyProfilePlaceHolderV2 } from 'components/Placeholders';
import { PostAsType, PostCreatorEntities } from './CreatePostModal';

const PostAsDropdown: FunctionComponent<{
  postAs: PostAsType;
  company?: { [key: string]: string };
  authUser: { [key: string]: any };
  onCompanySelected: (e: React.MouseEvent<HTMLElement>) => void;
  onUserSelected: (e: React.MouseEvent<HTMLElement>) => void;
}> = ({ postAs, authUser, company, onCompanySelected, onUserSelected }) => {
  const companyLogo = useMemo(
    () =>
      company?.logo
        ? ProfileUtils.getProfileImage(
            ProfileUtils.getImageFileFromAwsUrl(company.logo),
            56,
            56,
          )
        : CompanyProfilePlaceHolderV2,
    [company?.logo],
  );
  return (
    <Dropdown align='end'>
      <Dropdown.Toggle as='div'>
        <HusslUpIcon name='more' wrapperClass='text-primary' />
      </Dropdown.Toggle>
      <Dropdown.Menu className='post-user-dropdown'>
        <Typography variant='p-xlg-sb'>Post as</Typography>
        <Dropdown.Item
          onClick={onUserSelected}
          style={{
            padding: 0,
          }}
        >
          <div
            className={`user border-bottom ${
              postAs === PostCreatorEntities.USER ? 'active' : ''
            }`}
          >
            <SquircleImage
              r1={0.04}
              r2={0.5}
              width={56}
              height={56}
              imgUrl={
                ProfileUtils.getProfileImage(authUser?.profile_img, 56, 56) ??
                ''
              }
              alt='Profile Image'
            />
            <div>
              <div className='p-lg p-lg-b'>{authUser.fullname}</div>
              <small className='p-sm p-sm-d'>{authUser.pro_headline}</small>
            </div>
          </div>
        </Dropdown.Item>
        {company && (
          <Dropdown.Item
            onClick={onCompanySelected}
            style={{
              padding: 0,
            }}
          >
            <div
              className={`user ${
                postAs === PostCreatorEntities.COMPANY ? 'active' : ''
              }`}
            >
              <SquircleImage
                r1={0.04}
                r2={0.5}
                width={56}
                height={56}
                imgUrl={companyLogo}
                alt='Company Logo'
              />
              <div>
                <div className='p-lg p-lg-b'>{company.company_name}</div>
                <small>{company.pro_headline}</small>
                {/* <div className='notification'>
                <div className='notification__indicator' />
                <small>+2 new</small>
              </div> */}
              </div>
            </div>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PostAsDropdown;
