import { DraggableCreativeProps } from 'components/ProfileDetails/EditProfile/CreativePortfolio/DraggableCreativeCard';
import { CreativeSamplePermissionType } from 'store/creative-portfolio';
import {
  AssessmentStatus,
  CreativeSampleApiResponse,
} from 'store/user/creative/types';
import { convertToTitleCase } from './text';

export const isAssessed = (data: DraggableCreativeProps) =>
  data?.asset?.length && !!data?.asset[0]?.scriptAssessment?.assessmentMeta?.id;

export const isPublished = (data: DraggableCreativeProps) =>
  data?.asset?.length &&
  !!data?.asset[0]?.scriptAssessment?.assessmentMeta?.id &&
  !!data?.asset[0]?.scriptAssessment?.isPublished;

export const getAssessmentDetails = (data: DraggableCreativeProps) => {
  const assessmentStatus =
    data?.asset?.length && !!data?.asset[0]?.scriptAssessment?.status
      ? data?.asset[0]?.scriptAssessment?.status
      : null;
  return {
    isAssessed: isAssessed(data),
    isPublished: isPublished(data),
    assessmentStatus,
    isCompleted: assessmentStatus === AssessmentStatus.COMPLETED,
    isPending: assessmentStatus === AssessmentStatus.PENDING,
    isFailed: assessmentStatus === AssessmentStatus.FAILED,
  };
};

export const getScriptDetails = (data: DraggableCreativeProps) => ({
  isScript: data?.selectedAssetType === 'Script',
});

export const isScriptAssessed = (data: CreativeSampleApiResponse) =>
  data?.asset?.length && !!data?.asset[0]?.scriptAssessment?.assessmentMeta?.id;

export const isScriptPublished = (data: CreativeSampleApiResponse) =>
  !!data?.asset?.length &&
  !!data?.asset[0]?.scriptAssessment?.assessmentMeta?.id &&
  !!data?.asset[0]?.scriptAssessment?.isPublished;

export const getSampleTypeDisplay = (
  selectedAssetType: string,
  selectedAssetSubType?: string,
) => {
  if (!selectedAssetSubType) return selectedAssetType;

  return selectedAssetSubType === 'tv'
    ? `TV ${selectedAssetType}`
    : selectedAssetSubType === 'film'
    ? `Film ${selectedAssetType}`
    : selectedAssetType;
};

export const getPermissionDisplay = (
  permission: CreativeSamplePermissionType,
) => {
  if (permission === 'public') {
    return ` - Everyone on HUSSLUP`;
  }
  if (permission) {
    return ` - ${convertToTitleCase(permission)}`;
  }
  return '';
};
