import { APP_ENVIRONMENT_DEVELOPMENT } from 'constants/app';

const config = {
  appName: process.env.NEXT_PUBLIC_APP_NAME || 'HUSSLUP',
  env: process.env.APP_ENVIRONMENT || APP_ENVIRONMENT_DEVELOPMENT,
  envClient:
    process.env.NEXT_PUBLIC_APP_ENVIRONMENT || APP_ENVIRONMENT_DEVELOPMENT,
  appUrl: process.env.NEXT_PUBLIC_APP_URL,
  apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URI,
  captchaKey: process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_ID,
  webWhiteListUrls: process.env.NEXT_PUBLIC_WEB_WHITELIST_URLS,
  googleTagManager: {
    gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  },
  auth0: {
    baseUrl: process.env.AUTH0_BASE_URI,
    grantType: process.env.AUTH0_GRANT_TYPE,
    clientId: process.env.AUTH0_CLIENT_ID,
    realm: process.env.AUTH0_REALM,
    audience: process.env.AUTH0_AUDIENCE,
  },
  strapi: {
    baseUrl: process.env.NEXT_PUBLIC_STRAPI_API_URL,
    apiToken: process.env.NEXT_PUBLIC_STRAPI_API_TOKEN,
    previewSecret: process.env.NEXT_PUBLIC_STRAPI_PREVIEW_SECRET,
  },
  getStream: {
    apiKey: process.env.NEXT_PUBLIC_GETSTREAM_API_KEY || '',
    appId: process.env.NEXT_PUBLIC_GETSTREAM_APP_ID,
    clientId: process.env.NEXT_PUBLIC_GETSTREAM_CLIENT_ID,
  },
  s3: {
    profileImageCloudfrontUrl:
      process.env.NEXT_PUBLIC_PROFILE_IMAGE_CLOUDFRONT_URL,
    postImageCloudFrontUrl: process.env.NEXT_PUBLIC_POST_IMAGE_CLOUDFRONT_URL,
    profileAssetsUrl: process.env.NEXT_PUBLIC_AWS_S3_PROFILE_ASSETS_URL,
    postAssetsUrl: process.env.NEXT_PUBLIC_AWS_S3_POST_ASSETS_URL,
    webTourApi:
      process.env.NEXT_PUBLIC_AWS_S3_WEB_TOUR_URL ||
      `https://api-dev.husslup.com/web-page/webtour`,
  },
  zendesk: {
    baseUrl: process.env.NEXT_PUBLIC_ZENDESK_API_URL,
    apiToken: process.env.NEXT_PUBLIC_ZENDESK_API_TOKEN,
  },
  enableMessaging: process.env.NEXT_PUBLIC_ENABLE_MESSAGING,
  enableStaffingRoster: process.env.NEXT_PUBLIC_ENABLE_STAFFING_ROSTER,
  enableStaffingImport: process.env.NEXT_PUBLIC_ENABLE_STAFFING_IMPORT,
  enableProsearchDemographics:
    process.env.NEXT_PUBLIC_ENABLE_PROSEARCH_DEMOGRAPHICS,
  enableAIProsearch: process.env.NEXT_PUBLIC_ENABLE_AI_PROSEARCH,
  stripe: {
    publishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    individualPlanMonthlyPriceId:
      process.env.NEXT_PUBLIC_INDIVIDUAL_PLAN_MONTHLY_STRIPE_PRICE_ID,
    individualPlanAnnuallyPriceId:
      process.env.NEXT_PUBLIC_INDIVIDUAL_PLAN_ANNUALLY_STRIPE_PRICE_ID,
    companyPlanMonthlyPriceId:
      process.env.NEXT_PUBLIC_COMPANY_PLAN_MONTHLY_STRIPE_PRICE_ID,
    companyPlanAnnuallyPriceId:
      process.env.NEXT_PUBLIC_COMPANY_PLAN_ANNUALLY_STRIPE_PRICE_ID,
  },
};

export default config;
