import { useRouter } from 'next/router';
import {
  FunctionComponent,
  memo,
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import Slider, { Settings } from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import { PostTag } from 'commons/types/post.type';
import Chip, { ChipList } from 'components/Core/Chip';
import { DASHBOARD, HOME } from 'constants/routes';
import {
  SearchStates,
  setFeedSearchResultCount,
  setGetPostWithTagParam,
  setSearchState,
} from 'store/post/post.slice';
import { selectActivePostTag } from 'store/post/selectors';

const PostFilters: FunctionComponent<{
  filters?: PostTag[];
}> = ({ filters }): ReactElement => {
  const router = useRouter();
  const dispatch = useDispatch();
  const activePostTag = useSelector(selectActivePostTag);
  const sliderRef = useRef<Slider>(null);
  const sliderHolderRef = useRef<HTMLDivElement>(null);

  const handleTagClick = (tag: PostTag) => () => {
    dispatch(
      setGetPostWithTagParam({
        limit: 10,
        tagId: tag.id ? +tag.id : undefined,
        page: 1,
        nextPage: false,
        searchKeyword: '',
      }),
    );

    dispatch(setFeedSearchResultCount(0));
    dispatch(setSearchState(SearchStates.Collapsed));
    if (tag.id) {
      router.replace(`${DASHBOARD}/${encodeURIComponent(tag.tag)}`, undefined, {
        shallow: true,
      });
    } else {
      router.push(DASHBOARD, HOME);
    }
  };

  const settings: Settings = {
    infinite: false,
    speed: 300,
    variableWidth: true,
    swipeToSlide: true,
    slidesToShow: 5,
    swipe: true,
    focusOnSelect: true,
    arrows: false,
  };

  useEffect(() => {
    if (!!sliderRef.current && !!sliderHolderRef.current) {
      const slickSliderHolder = sliderHolderRef.current;
      const slickSlider = sliderRef.current;
      const slickList = slickSliderHolder.querySelector('.slick-list');

      slickList?.addEventListener('wheel', (event: any) => {
        const currentSlideIndex = Number(
          slickList
            ?.querySelector('.slick-active.slick-current')
            ?.getAttribute('data-index') || 0,
        );
        event.preventDefault();
        if (event.deltaX > 0) {
          slickSlider.slickGoTo(currentSlideIndex + 4);
          return;
        }
        if (event.deltaX < 0) {
          slickSlider.slickGoTo(currentSlideIndex - 4);
          // eslint-disable-next-line no-useless-return
          return;
        }
      });
    }
  }, [sliderRef.current]);

  return (
    <ChipList>
      <div ref={sliderHolderRef}>
        <Slider {...settings} className='chip-slider' ref={sliderRef}>
          {filters &&
            filters.map((filter: PostTag, idx: number) => {
              const currentRoute = decodeURIComponent(router.asPath).replace(
                '/Feed/',
                '',
              );

              if (currentRoute === filter.tag) {
                sliderRef.current?.slickGoTo(idx);
              }

              const isActive = () => {
                if (currentRoute === filter.tag) {
                  return true;
                }
                if (filter.tag === 'All' && !activePostTag) {
                  return true;
                }
                return false;
              };

              // TODO: @rajen please remove bottom code if above implementation is right
              // const isActive = filter?.id
              //   ? String(filter?.id ?? '') === String(activePostTag ?? '')
              //   : filter.tag === 'All' && !activePostTag;

              return (
                <Chip
                  key={filter.id}
                  isActive={isActive()}
                  onClick={handleTagClick(filter)}
                >
                  {filter.tag}
                </Chip>
              );
            })}
        </Slider>
      </div>
    </ChipList>
  );
};

export default memo(PostFilters);
