import { useState } from 'react';

import { SendConnectionResponse } from 'commons/types/network.type';
import { selectAllUserConnections } from 'store/connections/connections.selector';
import { useSendConnectionMutation } from 'store/discover/discover.api';
import {
  useAcceptConnectionMutation,
  useRemoveConnectionMutation,
} from 'store/my-network/update-connection.api';
import { ConnectionStatus } from 'store/user/types';
import { KebabDropdownItem } from 'components/Core/KebabDropdown/KebabDropdown';
import { useTranslation } from 'react-i18next';
import ToasterUtils from 'utils/toaster';
import { useAppSelector } from '../redux';
import useVisibility from '../useVisibility';

export type GetConnectionActionDropdown = (
  husslupId: string,
) => KebabDropdownItem | undefined;

export type RemoveConnectionState = {
  husslupId: string;
  confirmationMessage: string;
  confirmationTitle: string;
  confirmationSuccessMessage: string;
  confirmationFailureMessage: string;
  toastSuccessMessage?: string;
  toastFailureMessage?: string;
};

export const useConnectionActionDropdown = () => {
  const { t } = useTranslation('connection');
  const { t: myNetworkT } = useTranslation('my_network');
  const userConnectionStatus = useAppSelector(selectAllUserConnections);
  const [removeConnectionUser, setRemoveConnectionUser] =
    useState<RemoveConnectionState>();

  const {
    show: showRemoveConnection,
    hide: hideRemoveConnection,
    isVisible: isRemoveConnectionVisible,
  } = useVisibility();
  const [acceptConnection, { isLoading: isAcceptingConnection }] =
    useAcceptConnectionMutation();

  const [
    removeConnection,
    { originalArgs: removeConnectionArgs, isLoading: isRemovingConnection },
  ] = useRemoveConnectionMutation();

  const [
    sendConnection,
    { originalArgs: sendConnectionArgs, isLoading: isSendingConnection },
  ] = useSendConnectionMutation();

  const isConnectionRequested = () =>
    removeConnectionUser?.confirmationTitle === 'Connection Requested';

  const getRemoveConnectionToast = () => {
    if (isConnectionRequested()) {
      return {
        success: t('connection_request_declined'),
        failure: t('connection_request_declined_failed'),
      };
    }
    return {
      success:
        removeConnectionUser?.toastSuccessMessage || t('connection_removed'),
      faliure:
        removeConnectionUser?.toastFailureMessage ||
        t('connection_removed_failed'),
    };
  };

  const handleRemoveConnection = async () => {
    if (removeConnectionUser?.husslupId) {
      const response = await removeConnection({
        husslupId: removeConnectionUser.husslupId,
      }).unwrap();
      const { success, failure } = getRemoveConnectionToast();
      if (response.affected > 0) {
        ToasterUtils.successToast(success, {
          hideCloseButton: true,
        });
      } else {
        ToasterUtils.errorToast(failure ?? '');
      }
      hideRemoveConnection();
    }
  };

  const handleAcceptConnection = (husslupId?: string) => async () => {
    if (husslupId) {
      const response = await acceptConnection({
        connectionHusslupId: husslupId,
      }).unwrap();
      if (response.affected > 0) {
        ToasterUtils.successToast(t('connection_request_accepted'), {
          hideCloseButton: true,
        });
      } else {
        ToasterUtils.errorToast(t('connection_request_accepted_failed'));
      }
      hideRemoveConnection();
    }
  };

  const handleSendConnection = (husslupId?: string) => async () => {
    if (husslupId) {
      const response = (await sendConnection({
        connectionHusslupId: husslupId,
      }).unwrap()) as SendConnectionResponse;
      if (response?.generatedMaps?.length > 0) {
        ToasterUtils.successToast(t('connection_request_sent'), {
          hideCloseButton: true,
        });
      } else {
        ToasterUtils.errorToast(t('connection_request_sent_failed'));
      }
    }
  };

  const initiateDeleteConnection = (
    removeConnectionState: RemoveConnectionState,
  ) => {
    setRemoveConnectionUser(removeConnectionState);
    showRemoveConnection();
  };

  const getConnectionActionDropdown: GetConnectionActionDropdown = (
    husslupId: string,
  ): KebabDropdownItem | undefined => {
    const connectionStatusObject = userConnectionStatus.data?.find(
      (connection) => connection.connectedUserHusslupId === husslupId,
    );
    const connectionStatus = connectionStatusObject?.connectionStatus;

    if (
      !connectionStatus ||
      Number(connectionStatus) === ConnectionStatus.NONE
    ) {
      const isCurrentConnectionBeingSent =
        sendConnectionArgs?.connectionHusslupId === husslupId &&
        isSendingConnection;

      return {
        onClick: handleSendConnection(husslupId),
        label: 'Connect',
        disabled: isCurrentConnectionBeingSent,
      };
    }

    if (Number(connectionStatus) === ConnectionStatus.PENDING) {
      return {
        onClick: () =>
          initiateDeleteConnection({
            husslupId,
            confirmationMessage:
              'Are you sure want to cancel this connection request?',
            confirmationTitle: 'Cancel Connection Request',
            confirmationFailureMessage: 'Failed to cancel connection request',
            confirmationSuccessMessage: 'Connection Request Cancelled',
            toastSuccessMessage: t('connection_request_canceled'),
            toastFailureMessage: t('connection_request_canceled_failed'),
          }),
        label: 'Pending',
      };
    }

    if (Number(connectionStatus) === ConnectionStatus.ACCEPTED) {
      return {
        onClick: () => {
          initiateDeleteConnection({
            husslupId,
            confirmationMessage: 'Are you sure want to remove this connection?',
            confirmationTitle: 'Remove Connection',
            confirmationFailureMessage: myNetworkT(
              'CONNECTION_REMOVED_ERROR.value',
            ),
            confirmationSuccessMessage: myNetworkT(
              'CONNECTION_REMOVED_SUCCESS.value',
            ),
            toastSuccessMessage: t('connection_removed'),
            toastFailureMessage: t('connection_removed_failed'),
          });
        },
        label: 'Remove Connection',
      };
    }

    if (Number(connectionStatus) === ConnectionStatus.REQUESTED) {
      /** @TODO handle after confirmation about how to show this option for requested connection */
      const isCurrentConnectionGettingAccepted = isAcceptingConnection;
      const isCurrentConnectionGettingRemoved =
        removeConnectionArgs?.husslupId === husslupId && isRemovingConnection;

      const needToDisable =
        isCurrentConnectionGettingAccepted || isCurrentConnectionGettingRemoved;

      return {
        onClick: () =>
          initiateDeleteConnection({
            husslupId,
            confirmationMessage: 'This member wants to connect with you',
            confirmationTitle: 'Connection Requested',
            confirmationFailureMessage: 'Failed to process connection request',
            confirmationSuccessMessage: 'Connection accepted',
          }),
        disabled: needToDisable,
        label: 'Connection Requested',
      };

      /** @TODO handle after confirmation about how to show this option for requested connection */
      // {
      //   onClick: handleAcceptConnection(husslupId),
      //   icon: 'check',
      //   disabled: needToDisable,
      //   loading: isCurrentConnectionGettingAccepted,
      // },
    }
    return undefined;
  };

  return {
    getConnectionActionDropdown,
    removeConnectionDialogPropGetter: {
      disabled: isConnectionRequested()
        ? isAcceptingConnection
        : isRemovingConnection,
      loading: isConnectionRequested()
        ? isAcceptingConnection
        : isRemovingConnection,
      loadingSecondaryButton: isConnectionRequested() && isRemovingConnection,
      show: isRemoveConnectionVisible,
      onAccept: isConnectionRequested()
        ? handleAcceptConnection(removeConnectionUser?.husslupId)
        : handleRemoveConnection,
      onSecondaryButtonClick: isConnectionRequested()
        ? handleRemoveConnection
        : hideRemoveConnection,
      onClose: hideRemoveConnection,
      message:
        removeConnectionUser?.confirmationMessage ??
        'Are you sure you want to remove this connection?',
      title: removeConnectionUser?.confirmationTitle ?? 'Remove Connection',
      acceptText: isConnectionRequested() ? 'Accept' : 'Yes',
      closeText: isConnectionRequested() ? 'Decline' : 'Cancel',
      closeButton: isConnectionRequested(),
    },
  };
};

export default useConnectionActionDropdown;
