import { current } from '@reduxjs/toolkit';
import { HTTP_METHODS } from 'constants/http';
import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  API_DISCOVER_COMPANIES,
  API_DISCOVER_GROUPS,
  API_DISCOVER_SEARCH_COMPANIES,
  API_FOLLOW_COMPANY,
  API_GET_DISCOVER,
  API_RANDOMIZED_COMPANIES,
  API_RANDOMIZED_PEOPLE,
  API_SEND_CONNECTION_REQUEST,
  API_SUGGEST_CONNECTIONS,
  API_UN_FOLLOW_COMPANY,
  GET_ALL_RECOMMENDATIONS,
} from 'constants/api';
import {
  CompanyLocation,
  updateFollowCompanies,
  updateRemovedCompanyConnection,
  updateUnfollowCompanies,
} from 'utils/company';
import { RootState } from 'commons/types/redux';
import { followedCompaniesApiSlice } from 'store/my-network/followed-companies.api';
import { ConnectionStatus } from 'store/user/types';
import { RandomizedDiscoverUtils } from 'utils/randomized-discover';
import {
  DefaultRandomDiscoverCompanyPagination,
  DefaultRandomDiscoverPeoplePagination,
} from 'constants/data';
import getRecommendationTitle from 'utils/recommendation.utils';
import { DiscoverFilters } from './pageFilters.slice';
import { RecommendationResponse } from './discover.types';

interface IMeta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

interface ITopCredit {
  creditImg: string;
  createdAt: Date;
  credit: string;
  id: number;
  network: string;
  order: 0;
  projectTitle: string;
  seasons: string;
  updatedAt: Date;
  userId: number;
  years: string;
}

export interface IUserProfile {
  id: number;
  fullname: string;
  profile_img: string;
  husslupId: string;
  professional_tagline: string | null;
  project_tagline: string | null;
  genreTagline: string | null;
  inviteStatus: 'approved' | 'disapproved';
  connectionStatus?: string;
  topCredit: ITopCredit;
  tier: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ICompaniesProfile {
  id: number;
  company_name: string;
  userId: number;
  logo: string;
  pro_headline: string;
  project_type_headline: string;
  genre_headline: string;
  biography: string;
  instagram: string;
  facebook: string;
  adm_title: string;
  workplace_type: string;
  project_types: string;
  genres: string;
  based_in: string;
  affiliation: string;
  owner_demographics: string;
  created: Date;
  updated: Date;
  isFollowing: boolean;
  follower_count: number;
}

interface MemberProfile {
  email: string;
  fullname: string;
  husslupId: string;
  id: number;
  memberStatus: string;
  phone_number: string;
  profile_img: string;
}

interface MemberMedia {
  createdAt: Date;
  filename: string;
  group_media_id: number;
  updatedAt: Date;
  url: string;
}

export type CommunityGroupType = 'private' | 'public';

export interface Communities {
  createdOn: Date;
  created_by: any;
  groupMembers: any[];
  group_description: string;
  group_name: string;
  group_type: CommunityGroupType;
  id: number;
  isDeleted: boolean;
  media: MemberMedia;
  members: MemberProfile[];
  membersQty: number;
  updatedOn: Date;
}

export interface ICompaniesProfileItems {
  adm_title: string;
  affiliation: string;
  based_in: string;
  biography: string;
  company_name: string;
  created: Date;
  facebook: string;
  follower_count: number;
  genre_headline: string;
  genres: string;
  id: number;
  instagram: string;
  isFollowing: boolean;
  locations?: CompanyLocation[];
  logo: string;
  owner_demographics: string;
  pro_headline: string;
  project_type_headline: string;
  project_types: string;
  updated: Date;
  userId: number;
  workplace_type: string;
}

export interface IUserProfilesSuggestionsResponse {
  items: IUserProfile[];
  meta: IMeta;
}

export interface ICompanyProfileSggestionsResponse {
  items: ICompaniesProfileItems[];
  meta: IMeta;
}

export const discoverApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getDiscovers: builder.query<any, {}>({
        query() {
          return {
            url: API_GET_DISCOVER,
            method: HTTP_METHODS.GET,
          };
        },
      }),
      getDiscoverSuggestions: builder.query<
        IUserProfilesSuggestionsResponse,
        DiscoverFilters
      >({
        query({ limit = 25, page = 1, text, sortBy }) {
          return {
            url: API_SUGGEST_CONNECTIONS,
            method: HTTP_METHODS.GET,
            params: { searchText: text, sortBy, limit, page },
          };
        },
        // Only have one cache entry because the arg always maps to one string
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs?.arg?.nextPage) {
            currentCache.items.push(...newItems.items);
            currentCache.meta = newItems.meta;
          } else {
            currentCache.items = newItems.items;
            currentCache.meta = newItems.meta;
          }
        },
        // // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return (
            currentArg?.page !== previousArg?.page ||
            currentArg?.text !== previousArg?.text ||
            currentArg?.sortBy !== previousArg?.sortBy
          );
        },
        providesTags: ['suggest'],
      }),
      getDiscoverSearchCompanies: builder.query<
        ICompanyProfileSggestionsResponse,
        { searchQuery: string }
      >({
        query({ searchQuery }) {
          return {
            url: `${API_DISCOVER_SEARCH_COMPANIES}?query=${searchQuery}`,
            method: HTTP_METHODS.GET,
            // params: { limit, page },
          };
        },
      }),
      getDiscoverCompanies: builder.query<
        ICompanyProfileSggestionsResponse,
        DiscoverFilters
      >({
        query({ page, limit, text, sortBy }) {
          return {
            url: API_DISCOVER_COMPANIES,
            method: HTTP_METHODS.GET,
            params: { page, limit, searchText: text, sortBy },
          };
        },
        // Only have one cache entry because the arg always maps to one string
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs?.arg?.nextPage) {
            currentCache.items.push(...newItems.items);
            currentCache.meta = newItems.meta;
          } else {
            currentCache.items = newItems.items;
            currentCache.meta = newItems.meta;
          }
        },
        // // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return (
            currentArg?.page !== previousArg?.page ||
            currentArg?.text !== previousArg?.text ||
            currentArg?.sortBy !== previousArg?.sortBy
          );
        },
        // providesTags: ['Community'],
      }),
      followCompany: builder.mutation<any, { companyId: number }>({
        query({ companyId }) {
          return {
            url: API_FOLLOW_COMPANY,
            method: HTTP_METHODS.POST,
            body: { companyId },
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled, getState }) {
          try {
            const response = await queryFulfilled;
            const state = getState() as RootState;
            if (response?.data?.isUserConnectedWithTheCompany) {
              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getRandomizedCompanies',
                  DefaultRandomDiscoverCompanyPagination,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedPeopleResponse =
                        RandomizedDiscoverUtils.updateCompanyListConnectionStatus(
                          current(draft),
                          props.companyId,
                          true,
                        );
                      Object.assign(draft, updatedPeopleResponse);
                    }
                  },
                ),
              );
              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getDiscoverCompanies',
                  state.pagesetting.companies,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedCompanies = updateFollowCompanies(
                        current(draft),
                        props.companyId,
                      );
                      Object.assign(draft, updatedCompanies);
                    }
                  },
                ),
              );
            }
          } catch {
            /* empty */
          }
        },
        // invalidatesTags: ['Community'],
      }),
      unFollowCompany: builder.mutation<
        any,
        { companyId: number; userId: number }
      >({
        query({ companyId, userId }) {
          return {
            url: API_UN_FOLLOW_COMPANY,
            method: HTTP_METHODS.POST,
            body: { companyId, userId },
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled, getState }) {
          try {
            const response = await queryFulfilled;
            const state = getState() as RootState;
            const params = state?.connections?.getFollowedCompaniesParams;
            if (response?.data?.companyConnectionRemoved) {
              dispatch(
                followedCompaniesApiSlice.util.updateQueryData(
                  'getFollowedCompanies',
                  params,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedCompanies = updateRemovedCompanyConnection(
                        draft,
                        props.companyId,
                      );
                      Object.assign(draft, updatedCompanies);
                    }
                  },
                ),
              );
              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getDiscoverCompanies',
                  state.pagesetting.companies,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedCompanies = updateUnfollowCompanies(
                        current(draft),
                        props.companyId,
                      );
                      Object.assign(draft, updatedCompanies);
                    }
                  },
                ),
              );
              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getRandomizedCompanies',
                  DefaultRandomDiscoverCompanyPagination,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedPeopleResponse =
                        RandomizedDiscoverUtils.updateCompanyListConnectionStatus(
                          current(draft),
                          props.companyId,
                          false,
                        );
                      Object.assign(draft, updatedPeopleResponse);
                    }
                  },
                ),
              );
            }
          } catch {
            /* empty */
          }
        },
        // invalidatesTags: ['Community'],
      }),
      sendConnection: builder.mutation<any, { connectionHusslupId: string }>({
        query({ connectionHusslupId }) {
          return {
            url: API_SEND_CONNECTION_REQUEST,
            method: HTTP_METHODS.POST,
            body: { connectionHusslupId },
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled, getState }) {
          try {
            const response = await queryFulfilled;
            const disoverState = getState() as any;

            if (response?.data?.generatedMaps?.length > 0) {
              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getRandomizedPeople',
                  DefaultRandomDiscoverPeoplePagination,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedPeopleResponse =
                        RandomizedDiscoverUtils.updatePeopleListConnectionStatus(
                          current(draft),
                          props.connectionHusslupId,
                          ConnectionStatus.PENDING,
                        );
                      Object.assign(draft, updatedPeopleResponse);
                    }
                  },
                ),
              );
              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getDiscoverSuggestions',
                  disoverState.pagesetting.people,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedPeopleResponse =
                        RandomizedDiscoverUtils.updatePeopleListConnectionStatus(
                          current(draft),
                          props.connectionHusslupId,
                          ConnectionStatus.PENDING,
                        );
                      Object.assign(draft, updatedPeopleResponse);
                    }
                  },
                ),
              );
              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getRandomRecommendation',
                  {},
                  (draft) => {
                    if (current(draft)?.length) {
                      const updatedPeopleResponse =
                        RandomizedDiscoverUtils.updateRandomRecommendationListConnectionStatus(
                          current(draft),
                          props.connectionHusslupId,
                          ConnectionStatus.PENDING,
                        );
                      Object.assign(draft, updatedPeopleResponse);
                    }
                  },
                ),
              );
            }
          } catch (err) {
            console.log({ err });
            /* empty */
          }
        },
        invalidatesTags: [
          // 'suggest',
          'AcceptedReferral',
          'ConnectionsByLabel',
          'AllUserConnections',
          'UserConnectionStatus',
        ],
      }),
      // Communities
      discoverGroups: builder.query<Communities[], void>({
        query() {
          return {
            url: API_DISCOVER_GROUPS,
            method: HTTP_METHODS.GET,
          };
        },
      }),
      // Randomization // API_RANDOMIZED_COMPANIES
      getRandomizedPeople: builder.query<
        IUserProfilesSuggestionsResponse,
        { limit?: number; page?: number; nextPage?: boolean }
      >({
        query({ page, limit }) {
          return {
            url: API_RANDOMIZED_PEOPLE,
            method: HTTP_METHODS.GET,
            params: { page, limit },
          };
        },
        providesTags: ['Home'],
        keepUnusedDataFor: 20,
        // Only have one cache entry because the arg always maps to one string
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs?.arg?.nextPage) {
            currentCache.items.push(...newItems.items);
            currentCache.meta = newItems.meta;
          } else {
            currentCache.items = newItems.items;
            currentCache.meta = newItems.meta;
          }
        },
        // // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return currentArg?.page !== previousArg?.page;
        },
      }),
      getRandomizedCompanies: builder.query<
        ICompanyProfileSggestionsResponse,
        { limit?: number; page?: number; nextPage?: boolean }
      >({
        query({ page, limit }) {
          return {
            url: API_RANDOMIZED_COMPANIES,
            method: HTTP_METHODS.GET,
            params: { page, limit },
          };
        },
        providesTags: ['Home'],
        keepUnusedDataFor: 20,
        // Only have one cache entry because the arg always maps to one string
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs?.arg?.nextPage) {
            currentCache.items.push(...newItems.items);
            currentCache.meta = newItems.meta;
          } else {
            currentCache.items = newItems.items;
            currentCache.meta = newItems.meta;
          }
        },
        // // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return currentArg?.page !== previousArg?.page;
        },
      }),

      getRandomRecommendation: builder.query<any, any>({
        query() {
          return {
            url: GET_ALL_RECOMMENDATIONS,
            method: HTTP_METHODS.GET,
          };
        },
        transformResponse: (data: RecommendationResponse[]) => {
          const formattedData = data?.map((element) => {
            const obj = {
              ...element,
              title: getRecommendationTitle(
                element.recommendedBy.toLowerCase(),
                element.recommendationName,
              ),
              connectionStatus: ConnectionStatus.NONE,
            };
            return obj;
          });
          return formattedData;
        },
        providesTags: ['RandomRecommendentaions'],
      }),
    };
  },
});

export const {
  useGetDiscoversQuery,
  useLazyGetDiscoversQuery,
  useGetDiscoverSuggestionsQuery,
  useLazyGetDiscoverSuggestionsQuery,
  useLazyGetDiscoverSearchCompaniesQuery,
  useLazyGetDiscoverCompaniesQuery,
  useGetDiscoverCompaniesQuery,
  useLazyGetRandomizedPeopleQuery,
  useGetRandomizedPeopleQuery,
  useGetRandomizedCompaniesQuery,
  useLazyGetRandomizedCompaniesQuery,
  useFollowCompanyMutation,
  useUnFollowCompanyMutation,
  useSendConnectionMutation,
  useLazyDiscoverGroupsQuery,
  useGetRandomRecommendationQuery,
  useLazyGetRandomRecommendationQuery,
} = discoverApiSlice;
