import { useAppSelector } from 'commons/hooks/redux';
import Button, { ButtonVariants } from 'components/Core/Button';
import Typography from 'components/Core/StylesGuide/Typography';
import { MyNetworkLoader } from 'components/MyNetwork';
import ProgressStepper from 'components/ProgressStepper';
import LockUnlockProsearch from 'components/Prosearch/LockUnlock';
import Badge from 'components/Prosearch/Staffing/Badge';
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ToasterUtils from 'utils/toaster';
import { selectCurrentUser, selectCurrentUserId } from 'store/auth/selectors';
import {
  useAddRoleUserMutation,
  // useGetStaffingRostersByOwnerIdQuery,
  useLazyGetStaffingRostersByOwnerQuery,
  useLazyStaffingRoasterRoleByIdQuery,
} from 'store/staffing-roster';
import { useSelector } from 'react-redux';
import { SubscriptionItem } from 'components/Subscription/SubscriptionItem';

const steps = [
  {
    label: 'Select Staffing Roster',
    position: '1',
  },
  {
    label: 'Select a Role',
    position: '2',
  },
];

interface IProps {
  show: boolean;
  handleHide: () => void;
  memberId: number | null;
}

const AddToStaffingRoasterModal: FunctionComponent<IProps> = ({
  handleHide,
  show,
  memberId,
}) => {
  const { t } = useTranslation('misc');
  const currentUserId = useAppSelector(selectCurrentUserId) as number;
  // const { data: allStaffingRosters } = useGetStaffingRostersByOwnerIdQuery({
  //   ownerId: currentUserId,
  // });
  const [getStaffingRosterByOwner, { data: allStaffingRosters }] =
    useLazyGetStaffingRostersByOwnerQuery();

  useEffect(() => {
    getStaffingRosterByOwner({ ownerId: currentUserId });
  }, [currentUserId, memberId]);

  const [getRoles, { data: rolesData, isLoading, isFetching }] =
    useLazyStaffingRoasterRoleByIdQuery();
  const [addRoleUser, { isLoading: addIsLoading }] = useAddRoleUserMutation();
  const [currentRoasterId, setCurrentRoasterId] = useState<number | null>(null);
  const [currentRolesId, setCurrentRolesId] = useState<number | null>(null);
  const [currentRolesTitle, setCurrentRolesTitle] = useState<string>('');
  const [ui, setUi] = useState<'STAFFING_ROASTER' | 'ROLES'>(
    'STAFFING_ROASTER',
  );
  const [filteredData, setFilteredData] = useState<any[]>(
    allStaffingRosters?.mine,
  );
  const user = useSelector(selectCurrentUser);

  const [progress, setProgress] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const fetchRoles = async (): Promise<void> => {
    try {
      const response = await getRoles({ rosterId: currentRoasterId });
      setFilteredData(response?.data as any[]);
    } catch (error) {
      //
    }
  };

  const ownerSubscriptionIds = user?.userSubscriptions
    ?.filter((e: any) => e.owner.id === user.id)
    ?.map((e: any) => e.id);
  const handleRoasterClick = (id: number) => {
    setCurrentRoasterId(id);
  };

  const handleRolesClick = (id: number) => {
    setCurrentRolesId(id);
    setProgress(100);
  };

  const resetModal = () => {
    setCurrentRoasterId(null);
    setCurrentRolesId(null);
    setCurrentRolesTitle('');
    setUi('STAFFING_ROASTER');
    setSearchTerm('');
    setProgress(0);
  };

  const handleRoasterNext = () => {
    setProgress(50);
    setUi('ROLES');
    setCurrentRolesTitle(
      allStaffingRosters?.mine?.find((rs: any) => rs.id === currentRoasterId)
        .projectName,
    );
    setSearchTerm('');
    setFilteredData([]);
    fetchRoles();
  };

  const handleBack = () => {
    resetModal();
    setFilteredData(allStaffingRosters?.mine);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const searchVal = event.target.value.trim().toLowerCase();
    setSearchTerm(searchVal);
    const data =
      ui === 'STAFFING_ROASTER'
        ? [...allStaffingRosters?.mine]?.filter(
            (rs: any) =>
              rs.projectName.toLowerCase().includes(searchVal) ||
              rs.projectDescription.toLowerCase().includes(searchVal),
          )
        : [...rolesData]?.filter((rs: any) =>
            rs.roleName.toLowerCase().includes(searchVal),
          ) || [];
    setFilteredData(data);
  };

  const handleAddRoles = async () => {
    if (!navigator.onLine) {
      ToasterUtils.errorToast(
        t('STAFFING_ROSTER_NO_INTERNET_CONNECTION.value'),
      );
      return;
    }
    if (!memberId) return;
    const payload = {
      staffingRosterRole: currentRolesId,
      staffingRoster: currentRoasterId,
      user: memberId,
    };
    try {
      const addedRole = await addRoleUser({ user: payload });
      if (addedRole.data.generatedMaps) {
        ToasterUtils.successToast(t('MEMBER_ADDED_TO_STAFFING_ROASTER.value'));
      } else {
        ToasterUtils.errorToast(
          t('MEMBER_ADDED_TO_STAFFING_ROASTER_ERROR.value'),
        );
      }
      handleHide();
    } catch (error) {
      //
      ToasterUtils.errorToast(
        t('MEMBER_ADDED_TO_STAFFING_ROASTER_ERROR.value'),
      );
    }
  };

  useEffect(() => {
    if (!show) {
      resetModal();
    }
    setFilteredData(allStaffingRosters?.mine);
  }, [show]);

  return (
    <Modal show={show} onHide={handleHide} className='add-roaster-modal'>
      <Modal.Header className='d-block'>
        <div className='progress-stepper'>
          <ProgressStepper percentageCompleted={progress} steps={steps} />
        </div>
        <Typography variant='h3' className='pt-5'>
          {ui === 'STAFFING_ROASTER'
            ? 'Select Staffing Roster'
            : 'Select a Role'}
        </Typography>
        {currentRolesTitle && (
          <Typography variant='p-md-d'>{currentRolesTitle}</Typography>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className='basic-search mb-1'>
          <i className='hic-search' />
          <input
            type='text'
            placeholder={
              ui === 'STAFFING_ROASTER'
                ? 'Search Staffing Rosters...'
                : 'Search Roles...'
            }
            onChange={handleSearch}
            value={searchTerm}
          />
        </div>
        <div className='content-area staffing-members'>
          {ui === 'STAFFING_ROASTER' && (
            <>
              {allStaffingRosters?.mine?.length === 0 ? (
                <div className='text-center pt-4 pb-4'>
                  <Typography variant='p-lg-b'>
                    You have not Created any Staffing Roster.
                  </Typography>
                </div>
              ) : (
                <ul className='list-group'>
                  {filteredData?.map((rs: any) => (
                    // eslint-disable-next-line
                    <li
                      className={`list-group-item d-flex justify-content-between ${
                        currentRoasterId === rs.id ? 'active' : ''
                      }`}
                      // eslint-disable-next-line
                      onClick={() => handleRoasterClick(rs.id)}
                      key={rs.id}
                    >
                      <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center'>
                          <Typography
                            variant='p-lg-sb'
                            className='mr-h'
                            lineClampNo={1}
                          >
                            {rs.projectName}
                          </Typography>
                          <LockUnlockProsearch lockUnlockBoolean={false} />
                        </div>
                        <small className='text-muted'>
                          {rs.projectDescription}
                        </small>
                      </div>
                      {rs.status === 'archived' && (
                        <Badge
                          badgeLabel='ARCHIVED'
                          badgeType='badge-archived'
                        />
                      )}
                      <SubscriptionItem
                        subscription={rs?.subscription}
                        divStyle={{ width: '250px' }}
                        nameTypography='p-sm-sb'
                        isOwner={ownerSubscriptionIds.includes(
                          rs?.subscription?.id,
                        )}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
          {ui === 'ROLES' && (
            <ul className='list-group'>
              {
                // eslint-disable-next-line
                Boolean(isLoading || isFetching) ? (
                  <MyNetworkLoader />
                ) : (
                  <div>
                    {filteredData?.length > 0 ? (
                      filteredData?.map((rs: any) => (
                        // eslint-disable-next-line
                        <li
                          className={`list-group-item d-flex justify-content-between ${
                            rs?.roleHeadcount === rs?.user?.length
                              ? 'disabled'
                              : ''
                          } ${currentRolesId === rs?.id ? 'active' : ''}`}
                          // eslint-disable-next-line
                          onClick={() => handleRolesClick(rs?.id)}
                          key={rs?.id}
                        >
                          <Typography variant='p-md-d' title={rs?.roleName}>
                            {rs?.roleName}
                          </Typography>
                          <Typography variant='p-sm-d' title={rs?.roleName}>
                            <strong>
                              {rs?.user?.length} of {rs?.roleHeadcount}
                            </strong>{' '}
                            Hired
                          </Typography>
                        </li>
                      ))
                    ) : (
                      <div className='text-center pt-4 pb-4'>
                        <Typography variant='p-lg-b'>
                          You have not Created any Roles.
                        </Typography>
                      </div>
                    )}
                  </div>
                )
              }
            </ul>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {ui === 'STAFFING_ROASTER' && (
          <>
            <Button
              onClick={handleHide}
              variant={ButtonVariants.secondary}
              label='Cancel'
            />
            <Button
              variant={ButtonVariants.primary}
              label='Next'
              // eslint-disable-next-line
              disabled={!!!currentRoasterId}
              onClick={handleRoasterNext}
            />
          </>
        )}
        {ui === 'ROLES' && (
          <>
            <Button
              onClick={handleBack}
              variant={ButtonVariants.secondary}
              label='Back'
            />
            <Button
              variant={ButtonVariants.primary}
              label='Add to Staffing Roster'
              // eslint-disable-next-line
              disabled={!!!currentRolesId || addIsLoading}
              loading={addIsLoading}
              onClick={handleAddRoles}
            />
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddToStaffingRoasterModal;
