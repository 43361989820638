const restuctureToNested = <T>(
  linearList: T[],
  maxColumns: number = 3,
): T[][] => {
  const rows: T[][] = [];
  for (let i = 0; i < linearList.length; i += maxColumns) {
    const rowItems = linearList.slice(i, i + maxColumns);
    rows.push(rowItems);
  }

  return rows;
};

const addOtherwiseRemove = <T>(arr: T[], item: T): T[] => {
  const list = [...arr]; // create new array to avoid making changes to the list arr
  const index = list.indexOf(item);
  if (index === -1) {
    list.push(item);
  } else {
    list.splice(index, 1);
  }
  return list;
};

const sortByAscending = <T>(data: T[], key: keyof T): T[] => {
  const array = [...data];
  return array?.sort((a, b) => String(b[key]).localeCompare(String(a[key])));
};
const sortByDescending = <T>(data: T[], key: keyof T): T[] => {
  const array = [...data];
  return array?.sort((a, b) => String(a[key]).localeCompare(String(b[key])));
};
const getRecentData = <T>(data: T[], key: keyof T): T[] => {
  const array = [...data];
  return array.sort(
    (a, b) => +new Date(String(b[key])) - +new Date(String(a[key])),
  );
};

const ArrayUtils = {
  restuctureToNested,
  addOtherwiseRemove,
  sortByAscending,
  sortByDescending,
  getRecentData,
};

export default ArrayUtils;
