// Professional Details
export const PROFESSION_SELECT_LIMIT = 4;
export const PROJECTS_GENRES_SELECT_LIMIT = 3;

// More Details
export const UNDERGRAD_SCHOOL_SELECT_LIMIT = 2;
export const UNDERGRAD_DEGREE_SELECT_LIMIT = 2;
export const GRAD_SCHOOL_SELECT_LIMIT = 2;
export const GRAD_DEGRESS_SELECT_LIMIT = 2;

// Company
export const ADMINISTRATIVE_TITLE_SELECT_LIMIT = 1;
export const TYPE_OF_WORKPLACE_SELECT_LIMIT = 2;
export const PROJECT_AND_GENRE_SELECT_LIMIT = 3;
