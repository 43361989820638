import { Activity } from 'getstream';
import { FunctionComponent, MouseEventHandler } from 'react';
import timeSince, { formatIso8601DateToUTCDate } from 'utils/date-time';
import Typography from 'components/Core/StylesGuide/Typography';
import { useGetSubscriptionInfoQuery } from 'store/subscription/subscription.api';
import Link from 'next/link';
import NotificationIcon from 'components/Subscription/NotificationIcon';
import { useAppDispatch, useAppSelector } from 'commons/hooks/redux';
import { selectCurrentUser } from 'store/auth/selectors';
import { setActiveStaffingSubscription } from 'store/subscription/subscription.slice';
import { useRouter } from 'next/router';
import { STAFFING_PRO_SEARCHES } from 'constants/routes';

const SubscriptionInviteMemberToSeatActivity: FunctionComponent<{
  activity: Activity;
}> = ({ activity }) => {
  const subscriptionId = +activity?.foreign_id?.split(':')[1].trim();

  const dispatch = useAppDispatch();
  const router = useRouter();
  const user = useAppSelector(selectCurrentUser);

  const { data: subscriptionInfo } = useGetSubscriptionInfoQuery(
    {
      subscriptionId,
    },
    { skip: !subscriptionId },
  );

  const handleRedirectToStaffing: MouseEventHandler<HTMLAnchorElement> = (
    e,
  ) => {
    e.preventDefault();
    if (
      user?.userSubscriptions?.some(
        (sub: any) => sub?.id === subscriptionInfo?.id,
      )
    ) {
      dispatch(
        setActiveStaffingSubscription({
          isExternalSubscription: false,
          id: subscriptionInfo?.id,
        }),
      );
      router.push(STAFFING_PRO_SEARCHES);
    }
  };

  return (
    <li>
      {activity.verb === 'addMemberToSubscription' ? (
        <div className='notification-item'>
          <NotificationIcon subscriptionInfo={subscriptionInfo} />

          <div className='notification-content'>
            <Typography variant='p-md-d'>
              <>
                You have been added as an editor in the HUSSLUP{' '}
                <b className='fw-600'>
                  {' '}
                  “{subscriptionInfo?.subscriptionName}“
                </b>{' '}
                plan. Access your subscription tools{' '}
              </>
              <Link
                href=' '
                className='notification-link'
                onClick={handleRedirectToStaffing}
              >
                here.
              </Link>
            </Typography>
            <div className='timestamp'>
              {timeSince(formatIso8601DateToUTCDate(activity?.time))}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </li>
  );
};

export default SubscriptionInviteMemberToSeatActivity;
