import { Activity } from 'getstream';
import NextImage from 'components/Core/Image';
import { FunctionComponent } from 'react';
import timeSince, { formatIso8601DateToUTCDate } from 'utils/date-time';
import ProfileUtils from 'utils/profile';
import { truncateWithEllipse } from 'utils/text';
import { useGetSingleStaffingRosterForNotificationsQuery } from 'store/staffing-roster';

const StaffingRosterSharedActivity: FunctionComponent<{
  activity: Activity;
}> = ({ activity }) => {
  const actedEntity = activity.object as string;
  const rosterId = actedEntity.split(':')[1].trim();
  const { data: staffingRoster, isLoading } = useGetSingleStaffingRosterForNotificationsQuery(rosterId);

  if (isLoading) {
    return <></>;
  }

  return (
    <li>
      <div className="notification-item">
      <div className='notification-face icon'>
          <NextImage
            width={56}
            height={56}
            src={
              ProfileUtils.getProfileImage(
              activity.actor.split(':')[3]?.trim()
              ) ?? ''
            }
            alt=''
          />

        </div>
        <div className='notification-content'>
          <p>
            <b>{activity.actor.split(":")[1].trim()}</b> {activity.verb === 'staffingRosterShared' ? 'shared staffing roster with you: ':
            activity.verb === 'staffingRosterAccessRemoved' ? 'removed your access to the staffing roster: ': activity.verb === 'staffingRosterTransferredOwnership' ? 'transferred ownership of following staffing roster to you: ': ''}{' '}
            <b>{truncateWithEllipse(staffingRoster?.projectName)}</b>
          </p>
          <div className='timestamp'>
            {timeSince(formatIso8601DateToUTCDate(activity?.time))}
          </div>
        </div>
      </div>

    </li>
  );
};

export default StaffingRosterSharedActivity;
