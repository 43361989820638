import { ChangeEventHandler, FocusEventHandler, ReactElement } from 'react';
import Form from 'react-bootstrap/Form';

type CheckboxInputProps = {
  initialValue?: string;
  labelText: string;
  required?: boolean;
  placeholder?: string;
  error?: any;
  disabled?: boolean;
  id: string;
  name: string;
  value?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  defaultChecked?: boolean;
  className?: string;
  checked?: boolean;
  useCustomImage?: boolean;
  imageSrc?: string;
  children?: ReactElement;
};

/**
 * ### General user checkbok input to be used in forms.
 * *This component uses `<form-control>` from React Bootstrap internally. So please take a look also there at their extensive documentation. *
 */
const CheckboxInput: React.FC<CheckboxInputProps> = ({
  labelText,
  required,
  placeholder,
  disabled,
  id,
  name,
  value,
  onChange,
  onBlur,
  initialValue,
  defaultChecked,
  className,
  checked,
  useCustomImage,
  imageSrc,
  children,
  ...props
}) => ( 
  <Form.Group
    className={`form-group ${
      useCustomImage ? 'custom-checkbox label-connection-option-creative' : ''
    }`}
  >
    {useCustomImage && imageSrc ? (
      <div>
        <input
          type='checkbox'
          id={id}
          name={name}
          value={value}
          defaultChecked={defaultChecked}
          onChange={onChange}
          required={!!required}
          checked={checked}
          disabled={disabled}
          onBlur={onBlur}
          data-cy={id}
          defaultValue={initialValue}
          style={{ display: 'none' }} // Hide the default checkbox
          {...props}
        />
        <label htmlFor={id} className='custom-checkbox-label'>
          {imageSrc && (
            <img
              src={imageSrc}
              alt={labelText}
              style={{ width: '200px', height: '200px' }}
            />
          )}{' '}
          {labelText}
        </label>
      </div>
    ) : children ? (
      <Form.Check
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        defaultChecked={defaultChecked}
        onChange={onChange}
        required={!!required}
        className={className}
        checked={checked}
        disabled={disabled}
        onBlur={onBlur}
        data-cy={id}
        defaultValue={initialValue}
        label={
          <>
            {labelText} {children}
          </>
        }
        {...props}
      />
    ) : (
      <Form.Check
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        defaultChecked={defaultChecked}
        onChange={onChange}
        required={!!required}
        className={className}
        checked={checked}
        disabled={disabled}
        onBlur={onBlur}
        data-cy={id}
        defaultValue={initialValue}
        label={labelText}
        {...props}
      />
    )}
  </Form.Group>
 );
export default CheckboxInput;
