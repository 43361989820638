/* eslint-disable prefer-destructuring */
import Activities from 'constants/notification';
import NextImage from 'components/Core/Image';
import { FunctionComponent, useMemo } from 'react';
import timeSince, { formatIso8601DateToUTCDate } from 'utils/date-time';
import ProfileUtils from 'utils/profile';
import Link from 'next/link';
import { POST } from 'constants/routes';

const MentionedOnActivity: FunctionComponent<{
  activity: any;
}> = ({ activity }) => {
  const isMentionedOnPost = useMemo(() => {
    if (activity.verb === Activities.USER_MENTIONED_ON_A_POST) {
      return true;
    }
    return false;
  }, [activity.verb]);

  const postOwnerName = useMemo(() => {
    const splitName = activity.text.split('tagged in ');
    let userName = '';
    if (isMentionedOnPost) {
      userName = splitName[1].split(' post.')[0];
    } else {
      userName = splitName[1].split(' comment.')[0];
    }
    return userName;
  }, [activity.text]);

  return (
    <li>
      <Link href={`${POST}/${activity?.post_id}`} className='notification-item'>
        <div className='notification-face icon'>
          <NextImage
            width={56}
            height={56}
            src={
              ProfileUtils.getProfileImage(
                activity['post_owner_detail']['profileImg'] ?? '',
                56,
                56,
              ) ?? ''
            }
            alt='briefcase'
          />
        </div>
        <div className='notification-content'>
          <p>
            You were tagged in <b>{postOwnerName}</b>
            {isMentionedOnPost ? "'s post" : "'s comment"}
          </p>
          <div className='timestamp'>
            {timeSince(formatIso8601DateToUTCDate(activity?.time))}
          </div>
        </div>
      </Link>
    </li>
  );
};

export default MentionedOnActivity;
