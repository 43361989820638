import { createSelector } from '@reduxjs/toolkit';
import { AllProfileListMap } from 'commons/types/data';
import { ProfileListDropdownsAll } from 'constants/data';
import { emptyProfileList, getProfileListOptions } from 'utils/profileList';

import { profileListApiSlice } from '../profile-list.api';

export const selectProfileListsResults =
  profileListApiSlice.endpoints.getAllProfileListOptions.select();

export const selectAllProfileListsOptions = createSelector(
  selectProfileListsResults,
  (lists) => lists?.data ?? [],
);

export const selectDetails2Options = createSelector(
  selectAllProfileListsOptions,
  (options) => {
    const data = options as AllProfileListMap;
    const formattedOptions: AllProfileListMap = { ...emptyProfileList };
    ProfileListDropdownsAll.forEach((dropdown) => {
      formattedOptions[dropdown.listType] = getProfileListOptions(
        data[dropdown.listType],
      );
    });
    return {
      options: formattedOptions,
    };
  },
);
