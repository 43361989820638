/**
 * Enum for types of ProfileListSelections available on backend api
 */
export enum ProfileListTables {
  Affiliations = 'profile_list_affiliations',
  Degrees = 'profile_list_degrees',
  Demographics = 'profile_list_demographics',
  Genres = 'profile_list_genres',
  InternationalPassports = 'profile_list_international_passports',
  Locations = 'profile_list_locations',
  NonIndustryExperience = 'profile_list_non_industry_experience',
  Professions = 'profile_list_professions',
  ProjectTypes = 'profile_list_project_types',
  Pronouns = 'profile_list_pronouns',
  Schools = 'profile_list_schools',
  SecondLanguage = 'profile_list_second_language',
}

export const ProfileListFieldMapping = {
  [ProfileListTables.Affiliations]: 'affiliation',
  [ProfileListTables.Degrees]: 'degree',
  [ProfileListTables.Demographics]: 'demographic',
  [ProfileListTables.Genres]: 'genre',
  [ProfileListTables.InternationalPassports]: 'international_passport',
  [ProfileListTables.Locations]: 'location',
  [ProfileListTables.NonIndustryExperience]: 'non_industry_experience',
  [ProfileListTables.Professions]: 'profession',
  [ProfileListTables.ProjectTypes]: 'project_type',
  [ProfileListTables.Pronouns]: 'pronoun',
  [ProfileListTables.Schools]: 'school',
  [ProfileListTables.SecondLanguage]: 'second_language',
};

export interface ProfileListColorChange {
  [ProfileListTables.Degrees]?: boolean;
  [ProfileListTables.Genres]?: boolean;
  [ProfileListTables.Professions]?: boolean;
  [ProfileListTables.ProjectTypes]?: boolean;
  [ProfileListTables.Schools]?: boolean;
  [`Administrator Title`]?: boolean;
  [`Type of Workplace`]?: boolean;
  [`Project Types`]?: boolean;
  [`Genre`]?: boolean;
  [key: string]: boolean | undefined;
}

export enum SchoolSubType {
  UNDERGRAD = 'undergrad',
  GRADUATE = 'graduate',
}

export type ProfileListDropdownType = {
  shortTitle: string;
  fieldName: string;
  listType: ProfileListTables;
  isMultiSelect?: boolean;
  listSubType?: SchoolSubType;
  hint?: string;
};

export type AllProfileListMap = Record<ProfileListTables, ProfileListOption[]>;

export type CategorizedProfileList = Record<string, ProfileListOption[]>;

export type CategorizedProfileListDemographicsResponse = {
  categorized: CategorizedProfileList;
  raw: ProfileListOption[];
};

/**
 * Generic model for return type of options for types
 * mentioned in {@link ProfileListTables}
 */
export interface ProfileListSelectionModel {
  id: number;
  order?: number;
  text: string;
  category?: string;
  listSubType?: string;
}

export interface ProfileListOption {
  id: number;
  order?: number;
  text: string;
  label: string;
  value: number;
  category?: string;
  listSubType?: string;
  disabled?: boolean;
}
