import { useRouter } from 'next/router';
import React from 'react';
import { useGetFeedBannerQuery } from 'store/strapi-content';
import { clickableDivRequiredProps } from 'utils/dom';

export const Banner = () => {
  const router = useRouter();
  const { data } = useGetFeedBannerQuery();

  const handleBannerClick = () => {
    if (data?.bannerContent?.banner_url) {
      if (data?.bannerContent?.banner_url.startsWith('http')) {
        window.open(data?.bannerContent?.banner_url, '_blank', 'noreferrer');
      } else {
        router.push(data?.bannerContent?.banner_url);
      }
    }
  };

  if (!data?.bannerContent?.show_banner) return null;

  return (
    <div
      className='banner'
      {...clickableDivRequiredProps}
      onClick={handleBannerClick}
    >
      {data?.bannerContent?.icon?.data?.attributes?.url ? (
        <img
          className='banner-image'
          src={data?.bannerContent?.icon?.data?.attributes?.url ?? ''}
        />
      ) : null}
    </div>
  );
};

export default Banner;
