/* eslint-disable no-nested-ternary */
import { useSelector } from 'react-redux';

import { IAttachmentCredit } from 'components/Cards';
import MemberCard from 'components/Cards/MemberCard';
import { selectGetAcceptedInvitesParams } from 'store/my-network/selectors';
import { ConnectionLabel } from 'store/user/types';
import {
  useMyNetworkActions,
  useConnectionActionButtons,
  usePendingReferralActions,
} from 'commons/hooks/connections';
import { MyNetworkLoader, ConnectionsModals } from 'components/MyNetwork';
import { useGetAcceptedInvitesQuery } from 'store/invite';
import { useRouter } from 'next/router';
import { PROFILE } from 'constants/routes';
import AddToStaffingRoasterModal from 'components/StaffingRoster/Modals/AddToStaffingRoasterModal';
import {
  MemberChickletType,
  MemberChickletTypeDefault,
} from 'components/Chicklet';
import Typography from 'components/Core/StylesGuide/Typography';
import {
  REFERRAL_INVITE_FOUNDING_TEXT,
  REFERRAL_INVITE_MEMBER_TEXT,
  REFERRAL_INVITE_SWAG_TEXT,
} from 'constants/referral-invite';
import Link from 'next/link';
import { SendInviteModal } from './SendInviteModal';

const AcceptedInvites = () => {
  const router = useRouter();

  const {
    connectionsDropdownsWithActions,
    reportUserDialogPropGetter,
    initiateRemoveConnection,
    removeConnectionDialogPropGetter,
    addAddToStaffingDialogPropGetter,
  } = useMyNetworkActions();

  const { sendInviteDialogPropGetter, showSendInvite } =
    usePendingReferralActions();

  const getAcceptedInvitesParams = useSelector(selectGetAcceptedInvitesParams);

  const { isLoading, isFetching, data } = useGetAcceptedInvitesQuery(
    getAcceptedInvitesParams,
  );

  const { getConnectionActionButtons } = useConnectionActionButtons({
    initiateDeleteConnection: initiateRemoveConnection,
  });

  const handleCardClick = (profileId: number) => () => {
    router.push(`${PROFILE}/${profileId}`);
  };

  return (
    <>
      <SendInviteModal {...sendInviteDialogPropGetter} />

      <ConnectionsModals
        reportDialogPropGetter={reportUserDialogPropGetter}
        removeConnectionDialogPropGetter={removeConnectionDialogPropGetter}
      />
      <AddToStaffingRoasterModal
        handleHide={addAddToStaffingDialogPropGetter.onClose}
        show={addAddToStaffingDialogPropGetter.show}
        memberId={addAddToStaffingDialogPropGetter.id}
      />
      {isLoading || isFetching ? (
        <MyNetworkLoader />
      ) : data?.items?.length! < 1 ? (
        <div className='my-network-no-data-container text-center mt-20'>
          <div className='w-50'>
            <Typography variant='p-lg-b'>
              {REFERRAL_INVITE_MEMBER_TEXT}
              <br />
              {REFERRAL_INVITE_FOUNDING_TEXT + REFERRAL_INVITE_SWAG_TEXT}
              <div className='mt-1'>
                <Link href=' ' onClick={showSendInvite}>
                  <Typography variant='p-sm-d'> Send Invite</Typography>
                </Link>
              </div>
            </Typography>
          </div>
        </div>
      ) : (
        <section className='mt-2'>
          <ul className='ps-0 d-flex gap-3 flex-wrap mb-3'>
            {data?.items?.map((connection) => (
              <li key={connection.userId ?? connection.id}>
                <MemberCard
                  memberChickletStatus={
                    (connection.tier ??
                      MemberChickletTypeDefault) as MemberChickletType
                  }
                  onClick={handleCardClick(connection.userId)}
                  key={connection.id}
                  actionButtons={getConnectionActionButtons(
                    connection.connectionStatus,
                    connection.husslupId,
                    connection.fullname,
                    connection.profile_img,
                  )}
                  attachment={
                    {
                      type: 'credit',
                      heading: connection.topCredit?.credit,
                      description: [
                        connection.topCredit?.projectTitle,
                        connection.topCredit?.network,
                      ],
                    } as IAttachmentCredit
                  }
                  fullName={connection.fullname}
                  headings={
                    [
                      connection.professionalTagline,
                      connection.projectTagline,
                      connection.genreTagline,
                    ] as string[]
                  }
                  icons={[
                    {
                      label: 'verified',
                      icon: 'verified-fill',
                      color: 'text-awards',
                    },
                  ]}
                  profileImgUrl={connection.profile_img}
                  id={connection.userId}
                  husslupId={connection.husslupId}
                  dropdown={connectionsDropdownsWithActions({
                    userId: connection.userId,
                    husslupId: connection.husslupId!,
                    name: connection.fullname,
                    connectionLabel: ConnectionLabel.CLIENTS,
                  })}
                />
              </li>
            ))}
          </ul>
        </section>
      )}
    </>
  );
};

export default AcceptedInvites;
