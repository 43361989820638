import { StripeElementChangeEvent } from '@stripe/stripe-js';
import { useState } from 'react';

export type CardErrors = {
  cardNumber?: string;
  cardExpiry?: string;
  cardCcv?: string;
};

export const useCardElement = () => {
  const [cardErrors, setCardErrors] = useState<CardErrors>({
    cardNumber: '',
    cardExpiry: '',
    cardCcv: '',
  });

  const getCardElementProps = <T extends StripeElementChangeEvent>(
    type: keyof CardErrors,
  ) => ({
    className: 'form-control',
    options: { placeholder: '' },
    onChange: ({ error }: T) => {
      if (error) {
        setCardErrors((prevValue) => ({
          ...prevValue,
          [type]: error.message,
        }));
      } else {
        setCardErrors((prevValue) => ({
          ...prevValue,
          [type]: '',
        }));
      }
    },
  });

  return {
    cardErrors,
    getCardElementProps,
    hasCardErrors: Object.values(cardErrors).some((error) => error),
  };
};
