import Link from 'next/link';
import { FunctionComponent, ReactElement } from 'react';

import { useAppDispatch } from 'commons/hooks/redux';
import HusslUpIcon from 'components/Icon';
import {
  MY_NETWORK_CONTACT_LISTS,
  // STAFFING_PRO_CONTACT_LIST,
  STAFFING_PRO_SEARCHES,
  STAFFING_PRO_STAFFING_ROSTER,
} from 'constants/routes';
import { redirectedFromHome } from 'store/contact/contact.slice';
// import HomeContactLists from './HomeContactLists';
import useAuth from 'commons/hooks/auth';
import Typography from 'components/Core/StylesGuide/Typography';
import { useRouter } from 'next/router';
import { Button } from 'react-bootstrap';
import HomeContactLists from './HomeContactLists';
import HomeProSearches from './HomeProSearches';
import HomeStaffingRoster from './HomeStaffingRoster';

type StaffingSubMenuType = {
  heading: string;
  href: string;
  home: ReactElement;
};

const StaffingListItemsContainer: FunctionComponent = () => {
  const { user } = useAuth();
  const router = useRouter();

  const staffingTertiaryNavMenu: Array<StaffingSubMenuType> = [
    {
      heading: 'Staffing Rosters',
      href: STAFFING_PRO_STAFFING_ROSTER,
      home: <HomeStaffingRoster />,
    },
    {
      heading: 'ProSearch',
      href: STAFFING_PRO_SEARCHES,
      home: <HomeProSearches />,
    },
  ];
  const contactTertiaryNavMenu: Array<StaffingSubMenuType> = [
    {
      heading: 'Contact Lists',
      href: MY_NETWORK_CONTACT_LISTS,
      home: <HomeContactLists />,
    },
  ];

  const dispatch = useAppDispatch();
  const handleAddStaffingItem = () => {
    dispatch(redirectedFromHome(true));
  };

  // const staffingMessage = useMemo(() => {
  //   let message = '';
  //   if (user?.subscriptionStatus === 'TRIAL_EXPIRED') {
  //     message = SubscriptionMessage.TRIAL_EXPIRED_USER_FEED_SUBSCRITION_TEXT;
  //   } else if (user?.subscriptionStatus === 'NEW') {
  //     message = SubscriptionMessage.NEW_USER_FEED_SUBSCRIPTION_TEXT;
  //   }
  //   if (user?.subscriptionStatus === 'TRIAL') {
  //     if (
  //       subscriptionTrialRemainingDays(user?.subscriptionTrialStartDate) <= 2
  //     ) {
  //       message =
  //         SubscriptionMessage.PRE_TRIAL_INFORMING_USER_FEED_SUBSCRIPTION_TEXT;
  //     }
  //   }
  //   return message;
  // }, [user]);

  return (
    <>
      <div className='sticky-sider'>
        <div className='sider sider_right mb-12 position-relative'>
          {/* <div className='sider__icon'>
  <div className='icon'>
    <HusslUpIcon name='chevron-right' />
  </div>
</div> */}
          <div className='d-flex align-items-center mb-2'>
            <h2 className='mr-12px'>Staffing</h2>
            {/* {!user?.subscriptionStatus ||
            user?.subscriptionStatus === 'TRIAL' ||
            user?.subscriptionStatus === 'LEVEL_ONE' ||
            subscriptionTrialRemainingDays(user?.subscriptionTrialStartDate) >
              2 ? (
              <span className='badge badge-subscription '>FREE TRIAL</span>
            ) : undefined} */}
          </div>
          {/* {user?.userSubscriptions?.length === 0 && (
            <Typography variant='p-md-m' className='mb-12'>
              Our staffing tools help companies hire creative professionals at a
              fraction of the time and cost.
            </Typography>
          )} */}
          {!user ||
          user?.usersOwnership?.length > 0 ||
          user?.userSubscriptions?.length > 0 ? (
            staffingTertiaryNavMenu.map((navItem, index) => (
              <div
                className={`sider__block ${index > 0 ? 'pt-1' : ''} ${
                  navItem.heading === 'ProSearch' ? 'home-prosearch' : ''
                }`}
                key={navItem.heading}
              >
                <div className='block__head mb-h' style={{ height: '29px' }}>
                  <Typography variant='p-xlg-sb'>{navItem.heading}</Typography>
                  <Link
                    href={navItem.href}
                    className=' btn-text no-text-decoration'
                    onClick={handleAddStaffingItem}
                  >
                    <HusslUpIcon name='plus' variant='3x' />
                  </Link>
                </div>
                {navItem.home}
              </div>
            ))
          ) : (
            <>
              <div className='mb-3'>
                <Typography variant='p-md-m' className='mb-12'>
                  Our staffing tools help companies hire creative professionals
                  at a fraction of the time and cost.
                </Typography>
                {/* <Typography variant='p-md-m' className='mb-12'>
                  {staffingMessage}
                </Typography> */}
                <div className='d-flex justify-content-end'>
                  <Button
                    variant='primary'
                    onClick={() => {
                      router.push('/Staffing');
                    }}
                  >
                    Go to Staffing
                  </Button>
                </div>
              </div>
              <div className='ads-card mb-12'>
                <Typography variant='p-xlg-sb'>ProSearch</Typography>
                <Typography variant='p-sm-d'>
                  Select criteria to source candidates and share your searches
                  with other people.
                </Typography>
                <img
                  src='/ads-card-PS.png'
                  className='img-fluid'
                  // alt='Responsive image'
                />
              </div>
              <div className='ads-card mb-12'>
                <Typography variant='p-xlg-sb'>Staffing Rosters</Typography>
                <Typography variant='p-sm-d'>
                  Manage your open roles and hires on a project.
                </Typography>
                <img
                  src='/ads-card-SR.png'
                  className='img-fluid'
                  // alt='Responsive image'
                />
              </div>
              <div className='ads-card mb-12'>
                <Typography variant='p-xlg-sb'>
                  Demographic Reporting
                </Typography>
                <Typography variant='p-sm-d'>
                  Generate an anonymized statistical report of demographic
                  information for tax incentives and industry credentials.
                </Typography>
                <img
                  src='/ads-card-DR.png'
                  className='img-fluid'
                  // alt='Responsive image'
                />
              </div>
            </>
          )}
        </div>

        <div className='sider sider_right position-relative'>
          {contactTertiaryNavMenu.map((navItem, index) => (
            <div
              className={`sider__block ${index > 0 ? 'pt-1' : ''} `}
              key={navItem.heading}
            >
              <div className='block__head mb-h' style={{ height: '29px' }}>
                <h2>{navItem.heading}</h2>
                <Link
                  href={navItem.href}
                  className=' btn-text no-text-decoration'
                  onClick={handleAddStaffingItem}
                >
                  <HusslUpIcon name='plus' variant='3x' />
                </Link>
              </div>
              {navItem.home}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default StaffingListItemsContainer;
