import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasicSearchRequestPayload } from 'store/user/res.types';

const initialState: BasicSearchRequestPayload = {
  sort: undefined,
  query: '',
};

const basicFactedSlice = createSlice({
  name: 'basicSearch',
  initialState,
  reducers: {
    setBasicSearchSort(state, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    setBasicSearchQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
  },
});

export const { setBasicSearchQuery, setBasicSearchSort } =
  basicFactedSlice.actions;

export const basicSearchReducer = basicFactedSlice.reducer;
