import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  SaveAccountInfoAndHeadlines,
  SaveAccountInfoAndHeadlinesV2,
  SaveAccountSocialMedia,
  UploadProfilePictureResponse,
  UserEntity,
} from 'commons/types/data';
import {
  API_SAVE_ACCOUNT_INFO_AND_HEADLINES,
  API_SAVE_ACCOUNT_INFO_AND_HEADLINES_V2,
  API_UPLOAD_PROFILE_PICTURE,
  API_USERS,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
import { User } from './users.api';

export const userUpdateApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      saveAccountInfoAndHeadlines: builder.mutation<
        User,
        SaveAccountInfoAndHeadlines
      >({
        query(payload) {
          return {
            url: `${API_USERS}${API_SAVE_ACCOUNT_INFO_AND_HEADLINES}`,
            method: HTTP_METHODS.PATCH,
            body: payload,
          };
        },
        transformResponse: (data: User) => data,
        invalidatesTags: ['CurrentAuthUser'],
      }),
      saveAccountInfoAndHeadlinesV2: builder.mutation<
        User,
        SaveAccountInfoAndHeadlinesV2
      >({
        query(payload) {
          return {
            url: `${API_USERS}${API_SAVE_ACCOUNT_INFO_AND_HEADLINES_V2}`,
            method: HTTP_METHODS.PATCH,
            body: payload,
          };
        },
        transformResponse: (data: User) => data,
        invalidatesTags: ['CurrentAuthUser', 'UserProfileList'],
      }),
      updatePartialUser: builder.mutation<
        UserEntity,
        { userId: number; data: Partial<UserEntity | SaveAccountSocialMedia> }
      >({
        query({ userId, data }) {
          return {
            url: `${API_USERS}/${userId}`,
            method: HTTP_METHODS.PATCH,
            body: data,
          };
        },
        transformResponse: (data: UserEntity) => data,
        invalidatesTags: ['CurrentAuthUser'],
      }),
      uploadProfilePicture: builder.mutation<string, File>({
        query(payload) {
          const formData = new FormData();
          formData.append('image', payload);

          return {
            url: `${API_USERS}${API_UPLOAD_PROFILE_PICTURE}`,
            method: HTTP_METHODS.POST,
            body: formData,
          };
        },
        transformResponse: (data: UploadProfilePictureResponse) => {
          if (data?.fileName) {
            return data.fileName;
          }
          return '';
        },
        invalidatesTags: ['CurrentAuthUser'],
      }),
    };
  },
});
export const {
  useSaveAccountInfoAndHeadlinesMutation,
  useUploadProfilePictureMutation,
  useUpdatePartialUserMutation,
  useSaveAccountInfoAndHeadlinesV2Mutation,
} = userUpdateApiSlice;
