import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { postApiSlice } from '../post.api';
import { PostSliceState } from '../post.slice';

function postBuilder(builder: ActionReducerMapBuilder<PostSliceState>) {
  builder.addMatcher(
    postApiSlice.endpoints.getPostsWithTag.matchFulfilled,
    () => {
      // Removed as this is handled in Post useEffect
    },
  );
}

export default postBuilder;
