import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  GetConnectionByLabelResponse,
  GetConnectionCountsPerLabelResponse,
  GetConnectionRequestsParams,
  GetConnectionRequestsResponse,
  GetConnectionsByLabelParams,
} from 'commons/types/network.type';
import {
  API_CONNECTIONS_WEB,
  API_CONNECTIONS_WEB_CONNECTION_REQUESTS,
  API_CONNECTIONS_WEB_GET_BY_LABEL,
  API_CONNECTIONS_WEB_GET_COUNT_PER_LABEL,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';

export const connectionsApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getConnectionsByLabel: builder.query<
        GetConnectionByLabelResponse,
        GetConnectionsByLabelParams
      >({
        query: (payload) => ({
          url: `${API_CONNECTIONS_WEB}${API_CONNECTIONS_WEB_GET_BY_LABEL}`,
          method: HTTP_METHODS.GET,
          params: payload,
        }),
        // Only have one cache entry because the arg always maps to one string
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs?.arg?.nextPage) {
            currentCache.items.push(...newItems.items);
            currentCache.meta = newItems.meta;
          } else {
            currentCache.items = newItems.items;
            currentCache.meta = newItems.meta;
          }
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return (
            currentArg?.page !== previousArg?.page ||
            currentArg?.connectionLabel !== previousArg?.connectionLabel ||
            currentArg?.searchText !== previousArg?.searchText ||
            currentArg?.sortBy !== previousArg?.sortBy
          );
        },
        providesTags: ['ConnectionsByLabel'],
      }),

      getConnectionRequests: builder.query<
        GetConnectionRequestsResponse,
        GetConnectionRequestsParams
      >({
        query: (payload) => ({
          url: `${API_CONNECTIONS_WEB}${API_CONNECTIONS_WEB_CONNECTION_REQUESTS}`,
          method: HTTP_METHODS.GET,
          params: payload,
        }),
        // Only have one cache entry because the arg always maps to one string
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems) => {
          currentCache.items = newItems.items;
          currentCache.meta = newItems.meta;
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return (
            currentArg?.page !== previousArg?.page ||
            currentArg?.requestType !== previousArg?.requestType ||
            currentArg?.searchText !== previousArg?.searchText ||
            currentArg?.sortBy !== previousArg?.sortBy
          );
        },
        providesTags: ['ConnectionRequests'],
      }),

      getConnectionsCountPerLabel: builder.query<
        GetConnectionCountsPerLabelResponse,
        any
      >({
        query: () => ({
          url: `${API_CONNECTIONS_WEB}${API_CONNECTIONS_WEB_GET_COUNT_PER_LABEL}`,
          method: HTTP_METHODS.GET,
        }),
        providesTags: ['MyNetworkConnectionsCount'],
      }),
    };
  },
});

export const {
  useGetConnectionRequestsQuery,
  useGetConnectionsByLabelQuery,
  useLazyGetConnectionsByLabelQuery,
  useGetConnectionsCountPerLabelQuery,
} = connectionsApiSlice;
