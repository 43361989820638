/* eslint-disable import/prefer-default-export */
import {
  SubscriptionFormValues,
  SubscriptionSliceState,
} from 'store/subscription/subscription.types';

export const InitialSubscriptionSliceState: SubscriptionSliceState = {
  selectedSubscriptionPlan: undefined,
  selectedSubscriptionPlanMeta: undefined,
  isResumingCanceledPlan: false,
  activeStaffingSubscription: undefined,
};

export const SubscriptionFormInitialValues: SubscriptionFormValues = {
  cardholderNumber: '',
  email: '',
  subscriptionName: '',
  city: '',
  country: '',
  state: '',
  zip: '',
  streetAddress: '',
};
