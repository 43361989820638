import type { RootState } from 'commons/types/redux';
import { createSelector } from '@reduxjs/toolkit';
import { SerendipityState } from './serendipity.slice';

export const selectSerendipityState: (state: RootState) => SerendipityState = (
  state: RootState,
) => state.serendipity as SerendipityState;

export const selectSerendipityPeopleVisibility = createSelector(
  selectSerendipityState,
  (serendipity) => serendipity.people,
);

export const selectSerendipityCompaniesVisibility = createSelector(
  selectSerendipityState,
  (serendipity) => serendipity.companies,
);

export const selectSerendipitySlides = createSelector(
  selectSerendipityState,
  (serendipity) => serendipity.slides,
);
