import HusslUpIcon from 'components/Icon';
import Image from 'next/image';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { ListGroup, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  useGetReportPostListsQuery,
  useReportPostMutation,
} from 'store/report/report.api';
import Button, { ButtonVariants } from '../Button';
import Typography from '../StylesGuide/Typography';

export interface ReportPostDialogProps {
  postId?: number | null;
  onClose: () => void;
  show: boolean;
}

type MODE = 'REPORT' | 'CONFIRM' | 'SUCCESS';

const ReportPostDialog: FunctionComponent<ReportPostDialogProps> = ({
  postId,
  onClose,
  show,
}): ReactElement => {
  const [uiMode, setUiMode] = useState<MODE>('CONFIRM');
  const {
    data: reportLists,
    isLoading: isLoadingOptions,
    isFetching,
  } = useGetReportPostListsQuery();
  const [reportPost, { isLoading }] = useReportPostMutation();
  const { t } = useTranslation('global');
  const [selectedReportId, setSelectedReportId] = useState<number | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const getModalClassName = (): string => {
    if (uiMode === 'REPORT') return 'report';
    if (uiMode === 'CONFIRM') return 'confirm';
    return 'success';
  };

  const getModalTitle = (): string => {
    if (uiMode === 'REPORT') return 'Report';
    return 'Submit Report';
  };

  const getReportModal = (): void => {
    setUiMode('REPORT');
    setErrorMsg('');
  };

  const onCloseHandler = () => {
    if (isLoading) return;
    setErrorMsg('');
    setUiMode('REPORT');
    onClose();
  };

  const onReportItemClick = (reportId: number): void => {
    setUiMode('CONFIRM');
    setSelectedReportId(reportId);
  };

  const onReportSubmit = async (): Promise<void> => {
    try {
      setErrorMsg('');

      if (postId) {
        const { data, error } = (await reportPost({
          postId,
          report:
            reportLists?.find((rp) => rp.id === selectedReportId)?.title || '',
        })) as any;
        if (data) {
          setUiMode('SUCCESS');
        } else {
          const errorTempMsg = error.data.message;
          setErrorMsg(errorTempMsg);
        }
      }

      setSelectedReportId(null);
    } catch (error) {
      setSelectedReportId(null);
    }
  };

  useEffect(() => {
    setUiMode('REPORT');
  }, [postId]);

  return (
    <Modal
      className={`report-modal ${getModalClassName()} modal-confirmation`}
      show={show}
      onHide={onCloseHandler}
    >
      {errorMsg && <span className='text-error'>{errorMsg}</span>}
      <Modal.Header closeButton={uiMode !== 'CONFIRM'}>
        {uiMode !== 'SUCCESS' && <Modal.Title>{getModalTitle()}</Modal.Title>}
      </Modal.Header>
      {isFetching && <Spinner animation='border' />}
      <Modal.Body>
        {uiMode === 'REPORT' && (
          <>
            <Typography variant='p-lg-d' className='mb-1'>
              Why are you reporting this post?
            </Typography>
            <ListGroup className='report-list'>
              {reportLists?.map((report) => (
                <ListGroup.Item
                  key={report.id}
                  onClick={() => onReportItemClick(report.id)}
                >
                  {report.title} <HusslUpIcon name='arrow-right' />
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
        {uiMode === 'CONFIRM' && (
          <>
            <Typography variant='p-lg-d' className='mb-1'>
              Are you sure you want to submit this report?
            </Typography>
          </>
        )}
        {uiMode === 'SUCCESS' && (
          <div className='success-message text-center'>
            <Image
              width={64}
              height={64}
              alt='checked'
              src='/check-circle.svg'
            />
            <div
              dangerouslySetInnerHTML={{
                __html: t('REPORT_INFO_MESSAGE.value'),
              }}
            />
          </div>
        )}
      </Modal.Body>
      {uiMode === 'CONFIRM' && (
        <Modal.Footer>
          <Button
            variant={ButtonVariants.secondary}
            label='Cancel'
            onClick={getReportModal}
            disabled={isLoading || isLoadingOptions || isFetching}
          />
          <Button
            variant={ButtonVariants.primary}
            label={isLoading ? 'Submitting...' : 'Yes'}
            onClick={onReportSubmit}
            disabled={isLoading || isLoadingOptions || isFetching}
            loading={isLoading || isLoadingOptions || isFetching}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ReportPostDialog;
