import { VouchCardProps } from 'components/ProfileDetails/EditProfile/Vouches/VouchCard';
import { VouchType } from './types';

// eslint-disable-next-line import/prefer-default-export
export const transformGetVouchResp = (
  response: VouchType[],
): VouchCardProps[] =>
  response.map((vouch: VouchType) => ({
    id: `${vouch.id}`,
    vouchId: vouch.id,
    title: vouch?.vouchComment ?? '',
    vouchedByImg: vouch?.vouchedBy?.profile_img ?? '',
    vouchedBy: vouch?.vouchedBy?.fullname ?? '',
    professionalTagline: vouch?.vouchedBy?.professionalTagline ?? '',
    projectTagline: vouch?.vouchedBy?.projectTagline ?? '',
    sortOrder: vouch?.sortOrder,
    isHidden: vouch?.isHidden,
    status: vouch.status as number,
  }));
