import { FileType } from 'commons/types/base';
import { ContentType } from 'components/ProfileDetails/EditProfile/CreativePortfolio/AddEditCreativePortfolioModal';
import config from 'config';
import { GETSTREAM_FILE_SIZE_LIMIT_BYTE } from 'constants/file';

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const isMediaIsVideo = (media: File) => {
  if (media.type.includes('video')) {
    return true;
  }
  return false;
};

const getS3ProfileUrl = (fileName: string, width?: number, height?: number) => {
  const mask = `mask=https://husslup-public-images.s3.us-west-1.amazonaws.com/squircle2.png&w=${width}&h=${height}&fm=png&fit=crop`;
  return `https://${config.s3.profileImageCloudfrontUrl}/${fileName}?${mask}`;
};

const getS3PostUrl = (fileName: string, width?: number, height?: number) => {
  const mask = `mask=https://husslup-public-images.s3.us-west-1.amazonaws.com/squircle2.png&w=${width}&h=${height}&fm=png&fit=crop`;
  return `https://${config.s3.postImageCloudFrontUrl}/${fileName}?${mask}`;
};

// List of all image extensions
const ImageExtensions = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'bmp',
  'tiff',
  'svg',
  'webp',
  'heic',
];

// Get array list of all video extensions
export const VideoExtensions = [
  'mp4',
  'webm',
  'ogg',
  'ogv',
  'avi',
  'mov',
  'wmv',
  'flv',
  'mkv',
  'm3u8',
];

// Get array list of audio extensions
export const AudioExtensions = ['mp3'];
/**
 * Get the file extension
 * @param fileName File name
 */
const getFileExtension = (fileName: string) => {
  const fileExtension = fileName.split('.').pop();

  return fileExtension ?? '';
};

const isVideoExtension: (fileName: string) => boolean = (
  fileName: string,
): boolean => {
  const extension = getFileExtension(fileName);
  return VideoExtensions.includes(extension.toLowerCase());
};

const isAudioExtension: (fileName: string) => boolean = (
  fileName: string,
): boolean => {
  const extension = getFileExtension(fileName);
  return AudioExtensions.includes(extension.toLowerCase());
};

/**
 * Check if the file is an image
 *
 * @param fileName File name
 */
const isImage = (fileName: string) => {
  const extension = getFileExtension(fileName);
  return ImageExtensions.includes(extension.toLowerCase());
};

const isPdf = (fileName: string) => {
  const extension = getFileExtension(fileName);
  return ['pdf', 'PDF'].includes(extension.toLowerCase());
};

const getMediaTypeFromFileName = (fileName: string): FileType | 'other' => {
  const fileExtension = getFileExtension(fileName);
  if (fileExtension === 'pdf' || fileExtension === 'PDF') {
    return 'pdf';
  }

  if (isImage(fileName)) {
    return 'image';
  }
  if (isVideoExtension(fileName)) {
    return 'video';
  }

  if (isAudioExtension(fileName)) {
    return 'audio';
  }
  return 'other';
};

export {
  getS3PostUrl,
  getS3ProfileUrl,
  getMediaTypeFromFileName,
  isImage,
  isPdf,
  isMediaIsVideo,
};

export default isMediaIsVideo;

export const validateFileTypeAndSize = (
  filename: string,
  size: number,
  selectedType: ContentType,
): boolean => {
  console.log('filenName', filename);
  console.log('fileSize', size);
  console.log('selectedType', selectedType);

  const hasMatchingExtension = selectedType.extension.some((extension) =>
    filename.toLowerCase().endsWith(extension.toLowerCase()),
  );
  console.log('hasMatchingExtension', hasMatchingExtension);
  const isSizeWithinLimits = size <= selectedType.maxSize;
  console.log('isSizeWithinLimits', isSizeWithinLimits);

  return hasMatchingExtension && isSizeWithinLimits;
};

export const validateGetStreamFileSize = (fileSizeInByte: number) => {
  if (fileSizeInByte && typeof fileSizeInByte === 'number') {
    return fileSizeInByte < GETSTREAM_FILE_SIZE_LIMIT_BYTE;
  }
  return false;
};
