import dynamic from 'next/dynamic';
import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';

const PdfViewer = dynamic(() => import('components/Core/File/PdfViewer'), {
  ssr: false,
});

interface IProps {
  show: boolean;
  url: string;
  title: string;
  onClose: () => void;
}

/**
 * PDFViewer Dialog box to render PDF
 */
const PdfViewerDialog: FC<IProps> = ({ url, show, onClose, title }) => (
  <Modal show={show} onHide={onClose} className='pdf-viewer-content'>
    <Modal.Header closeButton>
      <Modal.Title className='pdf-viewer-title-container'>
        <p className='pdf-viewer-title'>{title}</p>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className='pdf-viewer-body'>
      <PdfViewer url={url} />
    </Modal.Body>
  </Modal>
);

export default PdfViewerDialog;
