import { translator } from 'config/i18n';
import ToasterUtils from './toaster';

const handleCopyProfileLink = (url: string) => {
  if (typeof navigator !== 'undefined') {
    const profileUrl = `${window.location.origin}${url}`;
    navigator?.clipboard?.writeText(profileUrl);
    ToasterUtils.successToast(
      translator.t('global:PROFILE_LINK_COPIED.value'),
      {
        hideCloseButton: true,
        icon: 'check',
        position: 'top-center',
      },
    );
  }
};

export default handleCopyProfileLink;
