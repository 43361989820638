export const HOME = '/';

export const DASHBOARD = '/Feed';

export const SEARCH = '/Search';

// Auth routes
export const LOGIN = '/Login/EmailLogin';
// export const LOGIN_WITH_PHONE = '/Login/PhoneLogin';
// export const LOGIN_WITH_PHONE_CONFIRMATION = `${LOGIN_WITH_PHONE}/Confirmation`;
export const SIGNUP = '/Signup';
export const FORGOT_PASSWORD = '/ForgotPassword';
export const FORGOT_PASSWORD_CONFIRMATION = `${FORGOT_PASSWORD}/Confirmation`;
export const RESET_PASSWORD = `${FORGOT_PASSWORD}/ResetPassword`;

// Signup routes
// export const PROFILE_ONBOARD = '/Signup/ShortProfileOnboard';
// export const PROFILE_ONBOARD = '/Signup/ProfileOnboard';
export const PROFILE_ONBOARD = '/Signup/Onboarding';
export const PROFILE_ONBOARD_SPLASH = `${PROFILE_ONBOARD}/Splash`;
export const PROFILE_ONBOARD_INFO = `${PROFILE_ONBOARD}/Info`;
export const PROFILE_ONBOARD_DETAILS1 = `${PROFILE_ONBOARD}/Details1`;
export const PROFILE_ONBOARD_DETAILS2 = `${PROFILE_ONBOARD}/Details2`;
export const PROFILE_ONBOARD_DETAILS3 = `${PROFILE_ONBOARD}/Details3`;
export const PROFILE_ONBOARD_CREDITS = `${PROFILE_ONBOARD}/Credits`;
export const PROFILE_ONBOARD_INVITE_CODE = `${PROFILE_ONBOARD}/InviteCode`;
export const PROFILE_ONBOARD_CONGRATULATIONS = `${PROFILE_ONBOARD}/Congratulations`;

// New onboarding
// export const PROFILE_ONBOARD_V3 = '/Signup/NewOnboardingV3';
// export const PROFILE_ONBOARD_V3_INFO = `${PROFILE_ONBOARD_V3}/Info`;
// export const PROFILE_ONBOARD_V3_DETAIL1 = `${PROFILE_ONBOARD_V3}/Detail1`;
// export const PROFILE_ONBOARD_V3_DETAIL3 = `${PROFILE_ONBOARD_V3}/Detail3`;
// export const PROFILE_ONBOARD_V3_DETAIL2 = `${PROFILE_ONBOARD_V3}/Detail2`;
// export const PROFILE_ONBOARD_CREDITS = `${PROFILE_ONBOARD_V3}/Credits`;
// export const PROFILE_ONBOARD_V3_INVITE_CODE = `${PROFILE_ONBOARD_V3}/InviteCode`;
// export const PROFILE_ONBOARD_V3_CONGRATULATIONS = `${PROFILE_ONBOARD_V3}/Congratulations`;

// Profile routes
export const PROFILE = '/Profile';
export const PROFILE_EDIT = '/Profile/Edit/';
export const PROFILE_EDIT_PERSONAL_PROFILE = '/Profile/Edit';
export const PROFILE_ADD_COMPANY_PROFILE = '/Profile/AddCompanyProfile';
export const PROFILE_ADD_COMPANY_PROFILE_TODO = '/Profile/Add/';
export const PROFILE_MANAGE_ACCOUNT = '/Account';
export const PROFILE_MANAGE_SUBSCRIPTION = '/Subscription/ManageSubscription';
export const PROFILE_MANAGE_SUBSCRIPTION_PAYMENT_INFORMATION =
  '/Subscription/PaymentInformation';
export const PROFILE_MANAGE_SUBSCRIPTION_MANAGE_SEATS =
  '/Subscription/ManageSeats';
export const ACCOUNT_CHANGE_EMAIL_PASSWORD = '/Account/ChgEmailPwd';

export const PROFILE_EDIT_COMPANY_PROFILE =
  '/CompanyProfile/Edit/CompanyProfileDetails';
export const PROFILE_VIEW_COMPANY_PROFILE = '/CompanyProfile';

// Profile routes
export const COMPANY = '/CompanyProfile';
export const COMPANY_EDIT = '/CompanyProfile/Edit/';
export const COMPANY_ADD = '/CompanyProfile/Add/';
export const COMPANY_EDIT_PROFILE =
  '/CompanyProfile/Edit/CompanyProfileDetails';
export const COMPANY_EDIT_PROFILE_CREDITS = '/CompanyProfile/Edit/Credits';

export const USERS = '/Users';

// Post
export const FEED = '/Feed';
export const POST = '/Post';

// Discover routes
export const DISCOVER = '/Discover';
export const DISCOVER_COMPANIES = '/Discover/Companies';
export const DISCOVER_COMPANIES_SERENDIPITY = '/Discover/Companies/Serendipity';
export const DISCOVER_PEOPLE = '/Discover/People';
export const DISCOVER_PEOPLE_SERENDIPITY = '/Discover/People/Serendipity';
export const DISCOVER_COMMUNITIES = '/Discover/Communities';
export const DISCOVER_REPPED_TALENT = '/Discover/ReppedTalent';

export const JOBS = '/Jobs';

// My Network routes
export const MY_NETWORK = '/MyNetwork';
export const MY_NETWORK_CONNECTIONS = '/MyNetwork/Connections';
export const MY_NETWORK_CONNECTIONS_REPRESENTATION =
  '/MyNetwork/Representation';
export const MY_NETWORK_CONNECTIONS_CLIENTS = '/MyNetwork/Clients';
export const MY_NETWORK_CONNECTIONS_WORK_WITH = '/MyNetwork/WorkWith';
export const MY_NETWORK_CONNECTION_MUTUAL = '/MyNetwork/Mutual';
export const MY_NETWORK_CONNECTION_REQUESTS = '/MyNetwork/ConnectionRequests';
export const MY_NETWORK_REFERRAL_INVITES = '/MyNetwork/ReferralInvites';
export const MY_NETWORK_COMPANIES = '/MyNetwork/Companies';
export const MY_NETWORK_COMMUNITIES = '/MyNetwork/Communities';
export const MY_NETWORK_COMMUNITIES_MEMBER = '/MyNetwork/Communities/Member';
export const MY_NETWORK_CONTACT_LISTS = '/MyNetwork/ContactLists';
export const MY_NETWORK_COMMUNITIES_MODERATOR =
  '/MyNetwork/Communities/Moderator';

export const STAFFING = '/Staffing';
export const STAFFING_PRO_SEARCHES = `${STAFFING}/ProSearches`;
export const STAFFING_PRO_CONTACT_LIST = `${MY_NETWORK}/ContactLists`;
export const STAFFING_PRO_STAFFING_ROSTER = `${STAFFING}/Roster`;

export const MESSAGES = '/Messages';
export const NOTIFICATIONS = '/Notifications';

export const ABOUT_US = '/Info/AboutUs';
export const HELP_INFO = '/Info';
export const HELP_INFO_FAQ = '/Info/FAQ';
export const HELP_INFO_TERMS_OF_SERVICE = '/Info/TermsOfService';
export const HELP_INFO_COMMUNITY_GUIDELINES = '/Info/CommunityGuidelines';
export const HELP_INFO_PRIVACY_POLICIES = '/Info/Privacy';
export const HELP_INFO_CONTACT_US = '/Info/ContactUs';
export const HELP_INFO_BROWSER_SMALL = '/Info/BrowserSmall';

export const BLOGS = '/Blogs';
export const PODCASTS = 'https://external-link';

// export const CONTACT_LIST = '/Staffing/ContactList';
export const PRO_SEARCHES = '/Staffing/ProSearches';

export const MOBILE = '/Mobile';
export const REAL_SCREEN = '/app/feeds/real-screen';

export const IPAGE_TEST = 'https://mkt.husslup.com/iPageTest1/';

// MENTOR MARKETPLACE
export const MENTOR_MARKETPLACE = '/MentorMarketplace';

// Prosearch
export const PROSEARCH_DEMOGRAPHICS_TABLE_VIEW = 'ProSearches';

// IPages
export const SIGNUP_FINAL_SPLASH = '/SignUp/FinalSplash';

// Subscription
export const SUBSCRIPTION = '/Subscription';
export const SUBSCRIPTION_MANAGE_SUBSCRIPTION =
  '/Subscription/ManageSubscription';
export const SUBSCRIPTION_PAYMENT_INFORMATION =
  '/Subscription/PaymentInformation';
export const SUBSCRIPTION_MANAGE_SEATS = '/Subscription/ManageSeats';
export const MEMBER_PERKS = '/Info/MemberPerks';

export const GREENLIGHT_STUDIO='/GreenlightStudio'
export const GREENLIGHT_SUITE_ASSESSMENT_RESULT_ROUTE=`${GREENLIGHT_STUDIO}/AssessmentResult`;
// GreenLight Studio routes
// export const GREENLIGHT_STUDIO = '/GreenLightStudio';
export const GREENLIGHT_SCRIPT_ASSESSMENT =
  '/GreenLightStudio/ScriptAssessment';
export const GREENLIGHT_PITCH_ASSESSMENT = '/GreenLightStudio/PitchAssessment';
export const GREENLIGHT_OUTLINE_COLLABORATOR =
  '/GreenLightStudio/OutlineCollaborator';
