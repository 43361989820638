import Layout from 'components/Layout';
import Head from 'next/head';
import withAuthState from 'components/Hoc/Auth';
import SecondaryNavbar from 'components/Navbar/SecondaryNavbar';
import FeedContainer from 'components/Home/FeedContainer';
import { NextPageWithLayout } from '../_app';

const HomePage: NextPageWithLayout = () => (
  <>
    <Head>
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <title>HUSSLUP</title>
      <meta
        property='og:husslup-feeds'
        content='husslup-home-feeds'
        key='husslup-feeds'
      />
    </Head>
    <SecondaryNavbar navLinks={[]} />
    <FeedContainer />
  </>
);

HomePage.Layout = Layout;

export default withAuthState(HomePage, { hideFooter: true });
