import React, { FunctionComponent, ReactElement } from 'react';

// Define icons types here so that it will be easy to use
export type IconType =
  | 'affiliation'
  | 'ambassador-fill'
  | 'arrow-right'
  | 'audio'
  | 'camera'
  | 'check'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'close'
  | 'compose'
  | 'connect'
  | 'connect-fill'
  | 'demographic'
  | 'demographic-fill'
  | 'discover'
  | 'document'
  | 'drag-handle'
  | 'edit'
  | 'education'
  | 'eye'
  | 'eye-false'
  | 'facebook'
  | 'founding-member-fill'
  | 'instagram'
  | 'jobs'
  | 'language'
  | 'link'
  | 'linkedin'
  | 'location'
  | 'lock'
  | 'media'
  | 'message'
  | 'more'
  | 'network-outline'
  | 'notes-fill'
  | 'notification'
  | 'other-skill'
  | 'passport'
  | 'pdf'
  | 'pdf-fill'
  | 'pending'
  | 'plus'
  | 'remove'
  | 'reply'
  | 'rotate-left'
  | 'rotate-right'
  | 'save-outline'
  | 'save'
  | 'search'
  | 'search-thin'
  | 'sended'
  | 'settings'
  | 'share'
  | 'smiley'
  | 'staffing'
  | 'star'
  | 'star-fill'
  | 'tag-plus'
  | 'tiktok'
  | 'twitter'
  | 'unlock'
  | 'upload'
  | 'verified-fill'
  | 'video'
  | 'image'
  | 'zoom-in'
  | 'zoom-out'
  | 'calendar'
  | 'info'
  | 'user'
  | 'credit-card'
  | 'member-perks';

export type IconSize = '1x' | '2x' | '3x' | '0x';

interface IIcon {
  name: IconType;
  variant?: IconSize;
  wrapperClass?: string;
  title?: string;
  keyName?: string;
  onClick?: (keyName: string) => void;
  style?: any;
}

const Element = ({ ...props }: React.HTMLAttributes<HTMLSpanElement>) =>
  React.createElement('i', props);

/**
 * @description A Generic Icon on component variants => normal:  1x:  retina: 2x: retinaplus: 3x
 * @param name accepts icon type as classes ie:  icon name
 * @default variant 1x ie: normal
 * @returns ReactElement Icon component
 */
const HusslUpIcon: FunctionComponent<IIcon> = ({
  name,
  variant = '1x',
  wrapperClass = '',
  title,
  keyName,
  onClick,
  style
}): ReactElement => (
  <Element
    className={`hic-${name} hic-${variant} ${wrapperClass} post-link-icon`}
    title={title}
    style={style}
    onClick={() => {
      if (onClick) {
        onClick(keyName ?? '');
      }
    }}
  />
);

export default HusslUpIcon;