import { Activity } from 'getstream';
import NextImage from 'components/Core/Image';
import { FunctionComponent } from 'react';
import { useGetPostByIdQuery } from 'store/post/post.api';
import timeSince, { formatIso8601DateToUTCDate } from 'utils/date-time';
import ProfileUtils from 'utils/profile';
import { truncateWithEllipse } from 'utils/text';
import Link from 'next/link';
import { POST } from 'constants/routes';

const PostLikesActivity: FunctionComponent<{
  activity: Activity;
}> = ({ activity }) => {
  const likedByUserAuth0Id = activity.actor.split(':')[1];
  const likedPost = activity?.object as string;
  const likedPostId = likedPost.split(':')[1];
  const { data: post, isLoading } = useGetPostByIdQuery({
    postId: +likedPostId,
  });

  let user: any;

  if (post?.whoLikes?.length) {
    post?.whoLikes?.forEach((like: any) => {
      if (like?.auth0_id === likedByUserAuth0Id) {
        user = like;
      }
    });
  }

  if (isLoading) {
    return <></>;
  }

  return (
    <li>
      <Link href={`${POST}/${post?.post_id}`} className='notification-item'>
        <div className='notification-face icon'>
          <NextImage
            width={56}
            height={56}
            src={
              ProfileUtils.getProfileImage(user?.profile_img ?? '', 56, 56) ??
              ''
            }
            alt=''
          />
        </div>
        <div className='notification-content'>
          <p>
            {' '}
            <b>{user?.fullname}</b> {`likes  your post`}{' '}
            <b>{truncateWithEllipse(post?.post)}</b>
          </p>
          <div className='timestamp'>
            {timeSince(formatIso8601DateToUTCDate(activity?.time))}
          </div>
        </div>
      </Link>
    </li>
  );
};

export default PostLikesActivity;
