import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  BlockUserPayload,
  BlockUserResponse,
} from 'commons/types/block-users.type';
import { API_BLOCK_USER, API_BLOCK_USER_UNBLOCK } from 'constants/api';
import { HTTP_METHODS } from 'constants/http';

export const unblockUsersApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      unblockUser: builder.mutation<BlockUserResponse, BlockUserPayload>({
        query: (payload) => ({
          url: `${API_BLOCK_USER}${API_BLOCK_USER_UNBLOCK}/${payload.body.userId}`,
          method: HTTP_METHODS.POST,
        }),
        invalidatesTags: ['BlockedUserList'],
      }),
      unblockByHusslupId: builder.mutation<any, { husslupIdToUnblock: string }>(
        {
          query: ({ husslupIdToUnblock }) => ({
            url: `${API_BLOCK_USER}${API_BLOCK_USER_UNBLOCK}/by-husslup-id/${husslupIdToUnblock}`,
            method: HTTP_METHODS.POST,
          }),
          invalidatesTags: ['BlockedUserList'],
        },
      ),
    };
  },
});

export const { useUnblockUserMutation, useUnblockByHusslupIdMutation } =
  unblockUsersApiSlice;
