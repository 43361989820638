import { baseApiSlice } from 'commons/apis/base-api.config';
import { UserEntity } from 'commons/types/data';
import {
  API_CONTACT,
  API_CONTACT_CREATE,
  API_CONTACT_LIST_ACCESS_USERS,
  API_CONTACT_SHARABLE_USERS,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
import { HitsType } from 'store/user/res.types';
import ArrayUtils from 'utils/arrayUtils';
import {
  ContactDataResponse,
  ContactResponse,
  IUser,
  GetContactListResponse,
  ISharableUser,
  ContactPayload,
  CreateContactPayload,
  IGetUserList,
  MemberTypes,
  IContactList,
} from './contact.types';

export const contactApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getAllContactLists: builder.query<
        Array<ContactDataResponse>,
        IContactList
      >({
        query(payload) {
          const { key, searchText, sortBy } = payload;
          return {
            url: `${API_CONTACT}/web/all?listType=${key}`,
            method: HTTP_METHODS.GET,
            params: {
              searchText,
              sortBy: sortBy ?? 'recent',
            },
          };
        },
        providesTags: ['AllContactLists', 'Home'],
        transformResponse: (data: { result: ContactDataResponse[] }) =>
          data?.result,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      getContact: builder.query<ContactResponse[], ContactPayload>({
        query(payload) {
          const { searchText } = payload;
          return {
            url: API_CONTACT,
            method: HTTP_METHODS.GET,
            params: {
              searchText,
            },
          };
        },
        transformResponse: (
          data: { result: ContactDataResponse[] },
          meta,
          args,
        ) => {
          const refactoredList: ContactResponse[] = data.result.map((obj) => {
            const memberFound = obj.members.find(
              (member) => member?.user?.id === args.userId,
            );
            const objToReturned = {
              id: obj.id,
              title: obj.title,
              desc: obj.desc,
              isShared: obj.isShared,
              ownerId: obj.owner.id,
              isAdded: false,
              memberId: args.userId,
            };

            if (memberFound) {
              objToReturned.isAdded = true;
            }
            return objToReturned;
          });
          return refactoredList;
        },
        providesTags: ['ContactLists'],
      }),
      // get user lists
      getUsersList: builder.query<IGetUserList, ContactPayload>({
        query(payload) {
          const { searchText, listId } = payload;
          return {
            url: `${API_CONTACT}/users/${listId}/search`,
            method: HTTP_METHODS.GET,
            params: {
              searchText,
            },
          };
        },
        providesTags: ['RecommendedUserLists'],
        transformResponse: ({ result }: IGetUserList) => {
          let totalCount = 0;
          const array = Object.entries(result)?.map((resultData) => {
            // @ts-ignore
            const { hits } = resultData[1];
            totalCount += hits?.length;
            const accumulatedValues = hits?.reduce(
              (accumulator: UserEntity[], currentValue: HitsType) => {
                accumulator.push(currentValue['_source']);
                return accumulator;
              },
              [],
            );
            const sortedArray = ArrayUtils.getRecentData<UserEntity>(
              accumulatedValues,
              'createdDate',
            );
            return {
              keyName: resultData[0],
              values: sortedArray,
              countOfValues: hits?.length,
            };
          });
          const data = { result: array, totalCount };
          return data;
        },
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      // get shared particular list
      getParticularSharedList: builder.query<ContactDataResponse, IUser>({
        query({ listId }) {
          return {
            url: `${API_CONTACT_LIST_ACCESS_USERS}/get-shared-list/${listId}`,
            method: HTTP_METHODS.GET,
          };
        },
        providesTags: ['RecommendedUserLists'],
        // eslint-disable-next-line arrow-body-style
        transformResponse: (data: { result: ContactDataResponse }) => {
          const dataTobeSort = data?.result?.members?.map(
            // eslint-disable-next-line arrow-body-style
            (member: MemberTypes) => {
              return {
                ...member?.user,
                listMemberId: member?.id,
                createdDate: member?.createdAt,
                note: member?.note,
              } as any;
            },
          );
          const sortedArray = ArrayUtils.getRecentData<MemberTypes>(
            dataTobeSort,
            'createdDate',
          );
          return {
            ...data?.result,
            // eslint-disable-next-line arrow-body-style
            members: sortedArray,
          };
        },
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      // get particular list
      getParticularList: builder.query<ContactDataResponse, IUser>({
        query({ listId }) {
          return {
            url: `${API_CONTACT}/${listId}`,
            method: HTTP_METHODS.GET,
          };
        },
        providesTags: ['RecommendedUserLists'],
        // eslint-disable-next-line arrow-body-style
        transformResponse: (data: { result: ContactDataResponse }) => {
          const dataTobeSort = data?.result?.members?.map(
            // eslint-disable-next-line arrow-body-style
            (member: MemberTypes) => {
              return {
                ...member?.user,
                listMemberId: member?.id,
                createdDate: member?.createdAt,
                note: member?.note,
              } as any;
            },
          );
          const sortedArray = ArrayUtils.getRecentData<MemberTypes>(
            dataTobeSort,
            'createdDate',
          );
          return {
            ...data?.result,
            // eslint-disable-next-line arrow-body-style
            members: sortedArray,
          };
        },
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      getContactList: builder.query<GetContactListResponse, void>({
        query() {
          return {
            url: API_CONTACT,
            method: HTTP_METHODS.GET,
          };
        },
      }),
      createList: builder.mutation({
        query: ({ values }) => ({
          url: API_CONTACT,
          method: HTTP_METHODS.POST,
          body: values,
        }),
        invalidatesTags: ['AllContactLists'],
        transformResponse: (data: { result: ContactDataResponse }) =>
          data?.result,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      deleteList: builder.mutation({
        query: (id: number) => ({
          url: `${API_CONTACT}/${id}`,
          method: HTTP_METHODS.DELETE,
        }),
        invalidatesTags: ['AllContactLists'],
        transformResponse: (data: ContactDataResponse) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      updateList: builder.mutation({
        query: ({ id, ...payload }) => ({
          url: `${API_CONTACT}/${id}`,
          method: HTTP_METHODS.PATCH,
          body: payload?.values,
        }),
        invalidatesTags: ['AllContactLists', 'RecommendedUserLists'],
        transformResponse: (data: ContactDataResponse) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      updateMemberNote: builder.mutation({
        query: ({ listId, memberId, note }) => ({
          url: `${API_CONTACT_CREATE}/${listId}/${memberId}`,
          method: HTTP_METHODS.PATCH,
          body: { note },
        }),
        invalidatesTags: ['RecommendedUserLists'],
        transformResponse: (data: ContactDataResponse) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      createMember: builder.mutation<any, CreateContactPayload>({
        query({ note, listId, memberId }) {
          return {
            url: API_CONTACT_CREATE,
            method: HTTP_METHODS.POST,
            body: {
              note,
              listId,
              memberId,
            },
          };
        },
        invalidatesTags: ['RecommendedUserLists', 'ContactLists'],
      }),
      deleteMember: builder.mutation({
        query: ({ listId, memberId }: CreateContactPayload) => ({
          url: `${API_CONTACT_CREATE}/${listId}/${memberId}`,
          method: HTTP_METHODS.DELETE,
        }),
        invalidatesTags: ['RecommendedUserLists'],
        transformResponse: (data: ContactDataResponse) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      // remove access to the list
      removeAccessToList: builder.mutation({
        query: ({ listId, userId }: CreateContactPayload) => ({
          url: `${API_CONTACT_LIST_ACCESS_USERS}/owner/${listId}/${userId}`,
          method: HTTP_METHODS.DELETE,
        }),
        invalidatesTags: ['MemberLists'],
        transformResponse: (data: ContactDataResponse) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      // remove user access
      removeUserAccess: builder.mutation({
        query: ({ listId }: ContactPayload) => ({
          url: `${API_CONTACT_LIST_ACCESS_USERS}/user/${listId}`,
          method: HTTP_METHODS.DELETE,
        }),
        invalidatesTags: ['AllContactLists'],
        transformResponse: (data: ContactDataResponse) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),

      updateAccessToUsersPersonalNote: builder.mutation({
        query: ({ listId, userId, ...payload }) => ({
          url: `${API_CONTACT_LIST_ACCESS_USERS}/owner/update-note-access/${listId}/${userId}`,
          method: HTTP_METHODS.PATCH,
          body: payload?.values,
        }),
        invalidatesTags: ['MemberLists'],
        transformResponse: (data: ContactDataResponse) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      // get sharable users
      getSharableUsersContactList: builder.query<ISharableUser[], IUser>({
        query(payload) {
          const { searchText, listId } = payload;
          return {
            url: `${API_CONTACT_SHARABLE_USERS}/${listId}`,
            method: HTTP_METHODS.GET,
            params: {
              searchText,
            },
          };
        },
        providesTags: ['RecommendedUserLists'],
        transformResponse: ({ result }) => result,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      // get list of access users
      getListAccessUsers: builder.query<ISharableUser[], IUser>({
        query(payload) {
          const { listId } = payload;
          return {
            url: `${API_CONTACT_LIST_ACCESS_USERS}/${listId}`,
            method: HTTP_METHODS.GET,
          };
        },
        providesTags: ['MemberLists'],
        transformResponse: ({ result }) => result,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      changeListOwner: builder.mutation({
        query: ({ listId, userId, ...payload }) => ({
          url: `${API_CONTACT_LIST_ACCESS_USERS}/owner/set-as-owner/${listId}/${userId}`,
          method: HTTP_METHODS.PATCH,
          body: payload?.values,
        }),
        invalidatesTags: ['AllContactLists'],
        transformResponse: (data: ContactDataResponse) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
      // share
      saveSharableUsers: builder.mutation({
        query: ({ values, listId }) => ({
          url: `${API_CONTACT_LIST_ACCESS_USERS}/${listId}`,
          method: HTTP_METHODS.POST,
          body: values,
        }),
        invalidatesTags: ['MemberLists'],
        transformResponse: (data: ContactDataResponse) => data,
        transformErrorResponse: (response: { status: string | number }) =>
          response.status,
      }),
    };
  },
});
export const {
  useLazyGetAllContactListsQuery,
  useGetAllContactListsQuery,
  useGetContactQuery,
  useGetParticularSharedListQuery,
  useGetParticularListQuery,
  useLazyGetContactQuery,
  useGetUsersListQuery,
  useCreateListMutation,
  useUpdateListMutation,
  useCreateMemberMutation,
  useDeleteListMutation,
  useDeleteMemberMutation,
  useGetContactListQuery,
  useGetSharableUsersContactListQuery,
  useLazyGetSharableUsersContactListQuery,
  useLazyGetListAccessUsersQuery,
  useGetListAccessUsersQuery,
  useSaveSharableUsersMutation,
  useRemoveAccessToListMutation,
  useRemoveUserAccessMutation,
  useUpdateAccessToUsersPersonalNoteMutation,
  useChangeListOwnerMutation,
  useUpdateMemberNoteMutation,
} = contactApiSlice;
