import { useEffect, useState } from 'react';

import useGetStreamClient from './useGetStreamClient';

const useMessageBadgeCount = () => {
  const client = useGetStreamClient();
  const [unreadCount, setUnreadCount] = useState<number>(0);
  useEffect(() => {
    let unsubscribe: { unsubscribe: () => void } | undefined;
    if (client !== undefined) {
      unsubscribe = client?.on((event) => {
        if (event.unread_channels !== undefined) {
          setUnreadCount(event.unread_channels);
        }
      });
    }
    return () => unsubscribe?.unsubscribe();
  }, [client]);

  return unreadCount;
};

export default useMessageBadgeCount;
