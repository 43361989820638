// eslint-disable-next-line import/prefer-default-export
export enum MemberCardModalType {
  ADD_TO_CONTACT_TITLE = 'Add to Contact List',
  ADD_TO_PROSEARCH_TITLE = 'Add to ProSearch',
}

export enum BasicSearchSortBy {
  BestMatch = 'best-match',
  AlphabeticalReverse = 'alphabetical-reverse',
  Alphabetical = 'alphabetical',
  Recent = 'recent',
}
