import { useAppSelector } from 'commons/hooks/redux';
import { useSubscriptionChecks } from 'commons/hooks/subscription';
import StaffingCard from 'components/Core/StaffingCard/StaffingCard';
import Typography from 'components/Core/StylesGuide/Typography';
import { STAFFING_PRO_SEARCHES } from 'constants/routes';
import Link from 'next/link';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { selectCurrentUserId } from 'store/auth/selectors';
import { useGetAllProsearchesQuery } from 'store/prosearch/prosearch.api';
import { GetProSearch } from 'store/prosearch/prosearch.types';
import { selectActiveStaffingSubscription } from 'store/subscription/selectors';

export const HomeProSearches = () => {
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { id: subId } = useAppSelector(selectActiveStaffingSubscription);
  const { firstValidSubscriptionId } = useSubscriptionChecks();

  const { data, isLoading, isFetching } = useGetAllProsearchesQuery(
    {
      ownerId: currentUserId,
      subId: subId || firstValidSubscriptionId,
    },
    {
      skip: !currentUserId || (!subId && !firstValidSubscriptionId),
    },
  );

  /** @TODO properly type prosearch api */
  const proSearchResponse = data as unknown as GetProSearch;
  if (isLoading || isFetching) {
    return (
      <div className='d-flex justify-content-center pt-1'>
        <Spinner />
      </div>
    );
  }
  if (
    !proSearchResponse?.data ||
    !proSearchResponse?.data?.length ||
    proSearchResponse?.data?.length < 1
  ) {
    return (
      <div className='d-flex justify-content-center pt-3'>
        <Typography variant='p-md-d' className='text-center'>
          <>
            Use detailed criteria to find and save members for your open roles.
            <br />
            Create your first ProSearch today.
          </>
        </Typography>
      </div>
    );
  }

  return proSearchResponse?.data?.length > 0 ? (
    <div className='d-flex align-items-center flex-column  home-pro__search'>
      {proSearchResponse?.data?.slice(0, 2).map((proSearch) => (
        <>
          <StaffingCard
            key={proSearch.owner?.id}
            label={proSearch.name}
            description={proSearch.description}
            status={proSearch?.isShared ? 'open' : 'archived'}
            isMine={proSearch.owner?.id === currentUserId}
            lastEdited={proSearch.updatedOn}
            href={`${STAFFING_PRO_SEARCHES}/${proSearch?.id}`}
          />
        </>
      ))}
      <div className='d-flex justify-content-end w-100'>
        <Link href={STAFFING_PRO_SEARCHES} className='link'>
          View All
        </Link>
      </div>
    </div>
  ) : (
    <>
      Use detailed criteria to find and save members for your open roles. Create
      your first ProSearch today.
    </>
  );
};

export default HomeProSearches;
