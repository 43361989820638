import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { NextPageWithLayout } from 'pages/_app';
import useAuth from 'commons/hooks/auth';
import { HOME } from 'constants/routes';
import Loader from 'components/Loader';
import { Noop } from '../Layout';

export type AuthStateMeta = {
  hideFooter?: boolean;
};

const withUnauthenticatedState = (
  Component: NextPageWithLayout,
  meta?: AuthStateMeta,
) =>
  function WrappedComponent(props: any) {
    const router = useRouter();
    const { isLoggedIn, isAuthReady } = useAuth();

    useEffect(() => {
      const redirectToAuthenticatedPage = async () => {
        if (isLoggedIn) {
          router.push((router.query?.from as string) || HOME);
        }
      };
      redirectToAuthenticatedPage();
    }, [isLoggedIn]);

    if (isAuthReady) {
      return <Loader />;
    }

    const Layout = (Component as any).Layout || Noop;

    return (
      <Layout authenticated={isLoggedIn} hideFooter={meta?.hideFooter}>
        <Component {...props} />
      </Layout>
    );
  };

export default withUnauthenticatedState;
