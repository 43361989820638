import Typography from 'components/Core/StylesGuide/Typography';
import React from 'react';

type MyNetworkNoDataProps = {
  description: string;
};

const MyNetworkNoData = ({ description }: MyNetworkNoDataProps) => (
  <div className='my-network-no-data-container'>
    <Typography variant='p-lg-b'>{description}</Typography>
  </div>
);

export default MyNetworkNoData;
