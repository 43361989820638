import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'commons/types/redux';

import { SubscriptionSliceState } from '../subscription.types';
import { subscriptionPaymentApiSlice } from '../subscription-payment.api';

export const selectSubscriptionState = (state: RootState) =>
  state.subscription as SubscriptionSliceState;

export const selectSelectedSubscriptionPlan = createSelector(
  selectSubscriptionState,
  (state) => state.selectedSubscriptionPlan,
);

export const selectSelectedSubscriptionPlanMeta = createSelector(
  selectSubscriptionState,
  (state) => state.selectedSubscriptionPlanMeta,
);

export const selectIsResumingCanceledSubscription = createSelector(
  selectSubscriptionState,
  (state) => state.isResumingCanceledPlan,
);

export const selectUserSubscription = createSelector(
  subscriptionPaymentApiSlice.endpoints.getUserSubscription.select(),
  (response) => response.data,
);

export const selectIsNewPlanSelected = createSelector(
  selectSelectedSubscriptionPlan,
  selectUserSubscription,
  (selectedSubscriptionPlan, userSubscription) => {
    if (
      userSubscription?.subscriptionPaymentPlan?.id ===
      selectedSubscriptionPlan?.id
    ) {
      return false;
    }
    return true;
  },
);

export const selectIsOnlyFrequencyChanged = createSelector(
  selectSelectedSubscriptionPlanMeta,
  selectUserSubscription,
  (selectedSubscriptionPlanMeta, userSubscription) => {
    if (
      userSubscription?.subscriptionPaymentPlan?.paymentPlan
        ?.toLowerCase()
        ?.includes(selectedSubscriptionPlanMeta?.planType || '')
    ) {
      return true;
    }
    return false;
  },
);

export const selectActiveStaffingSubscription = createSelector(
  selectSubscriptionState,
  (state) =>
    state.activeStaffingSubscription || {
      id: '',
      isExternalSubscription: false,
    },
);
