/* eslint-disable react/no-array-index-key */
import Typography from 'components/Core/StylesGuide/Typography';
import { COMPANY_CARD_HEADING_MAX_LENGTH } from 'constants/app';
import React, { FunctionComponent, ReactElement } from 'react';
import highlightText from 'utils/hightLightText';

interface IProps {
  name: string | JSX.Element;
  headings?: string[];
  searchText?: string;
}

const CompanyMemberDetails: FunctionComponent<IProps> = ({
  name,
  headings,
  searchText,
}): ReactElement => {
  const getTypographyClassName = (index: number) => {
    if (index === 0) {
      return 's-sb';
    }

    return 's-d';
  };

  const getClassName = (index: number) => {
    if (index === 0) {
      return 'company-heading';
    }
    return 'location';
  };

  return (
    <>
      <Typography
        variant='p-lg-b'
        lineClampNo={1}
        className='company-name'
        title={String(name)}
        trimNumber={COMPANY_CARD_HEADING_MAX_LENGTH}
      >
        {highlightText(name as string, searchText ?? '')}
      </Typography>

      {headings?.map((heading, index) => (
        <div key={`${heading}-${index}`}>
          <Typography
            variant={getTypographyClassName(index)}
            lineClampNo={1}
            className={getClassName(index)}
            title={heading}
            trimNumber={COMPANY_CARD_HEADING_MAX_LENGTH}
          >
            {heading}
          </Typography>
        </div>
      ))}
    </>
  );
};

export default CompanyMemberDetails;
