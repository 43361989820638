import { createSelector } from '@reduxjs/toolkit';
import { postTagApiSlice } from '../post-tag.api';

export const selectPostTagResults =
  postTagApiSlice.endpoints.getPostTags.select({
    screenRequesting: 'create-post',
  });

export const selectPostTags = createSelector(
  selectPostTagResults,
  (state) => state.data,
);
