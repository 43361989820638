import React, {
  FunctionComponent,
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { StreamChat, DefaultGenerics, Channel } from 'stream-chat';
import GetStreamInitializer from 'utils/get-stream-initializer';
import useAuth from 'commons/hooks/auth';
import { StreamFeed, connect } from 'getstream';
import config from 'config';

export type GetStreamContextType = {
  getStreamClient?: StreamChat<DefaultGenerics> | null;
  getMessageChannel?: (channelId: string) => Promise<Channel[]> | any[];
  activityFeedClient?: any;
};

const initialContextValue = {
  getStreamClient: null,
  activityFeedClient: null,
};

export const GetStreamContext =
  createContext<GetStreamContextType>(initialContextValue);

export const GetStreamProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const getStreamClient = GetStreamInitializer.getClient();
  const { currentUserHusslupId, currentUserGetstreamToken } = useAuth();
  const [client, setClient] = useState<StreamChat>();
  const [activityFeedClient, setActivityFeedClient] = useState<StreamFeed>();
  const [channelId, setChannelId] = useState<string>('');

  const fetchGetStreamChannels = useMemo(
    () =>
      client?.queryChannels(
        {
          type: 'messaging',
          id: channelId,
        },
        { last_message_at: 1 },
        { watch: true },
        { offlineMode: true },
      ) ?? Promise.resolve([] as Channel[]),
    [channelId],
  );

  const contextValue = useMemo(
    () => ({
      activityFeedClient,
      getStreamClient: client,
      getMessageChannel: async (channelId: string) => {
        setChannelId(channelId);
        return fetchGetStreamChannels;
      },
    }),
    [client, channelId],
  );

  const initializeGetstream = async () => {
    if (getStreamClient && currentUserHusslupId && currentUserGetstreamToken) {
      await getStreamClient?.connectUser(
        { id: currentUserHusslupId },
        currentUserGetstreamToken,
      );
      setClient(getStreamClient);
    }
  };

  const initializeActivityFeedGetstream = async () => {
    if (getStreamClient && currentUserHusslupId && currentUserGetstreamToken) {
      const connections = connect(
        config.getStream.apiKey,
        currentUserGetstreamToken,
        config.getStream.appId,
      );
      const feedClient = connections.feed('notification', currentUserHusslupId);

      setActivityFeedClient(feedClient);
    }
  };

  useEffect(() => {
    if (getStreamClient !== undefined) {
      initializeGetstream();
    }

    if (!activityFeedClient) {
      initializeActivityFeedGetstream();
    }
  }, [currentUserGetstreamToken]);

  useEffect(() => {
    if (!activityFeedClient) {
      initializeActivityFeedGetstream();
    }
  }, [currentUserGetstreamToken]);

  return (
    <GetStreamContext.Provider value={contextValue}>
      {children}
    </GetStreamContext.Provider>
  );
};
