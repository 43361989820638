import { FileType, Pagination } from 'commons/types/base';
import { Comment, CommentActivities } from 'commons/types/comment.type';
import { UserEntity, UserTypeEnum } from 'commons/types/data';
import { Like, LikeResponse } from 'commons/types/like.type';
import {
  Post,
  PostMedia,
  TaggedUserIdWithHusslupId,
} from 'commons/types/post.type';
import { PROFILE } from 'constants/routes';
import { getMediaTypeFromFileName } from './file';
import { TextUtils } from './text';

const shiftLoggedInUserToLastIndex = (currentUserId: number, likes?: Like[]) =>
  likes?.sort((a, b) => {
    if (a.id === currentUserId && b.id !== currentUserId) {
      return -1;
    }
    if (b.id === currentUserId && a.id !== currentUserId) {
      return 1;
    }
    if (b.id !== currentUserId && a.id !== currentUserId) {
      return 0;
    }
    return 0;
  }) ?? [];

const formatPostLikesByUser = (likes: Like[], currentUserId: number) => {
  const formattedLikes: Like[] = likes?.map((like) => ({
    ...like,
    fullName: currentUserId === like.id ? 'You' : like.fullName || '',
    firstName: currentUserId === like.id ? 'You' : like.firstName || '',
  }));

  const sortedLikes = shiftLoggedInUserToLastIndex(
    currentUserId,
    formattedLikes,
  );

  return sortedLikes;
};

const formatPostLikes = (likes: LikeResponse[]) => {
  const formattedLikes: Like[] = likes?.map((like) => ({
    id: like.likedBy.id!,
    imageFileName: like.likedBy.profile_img,
    fullName: like.likedBy.fullname || '',
    firstName: like.likedBy.firstName || '',
    husslupId: like.likedBy.husslupId || '',
    userType: like.likedBy.userType || '',
    professionalTagline: like.likedBy.professionalTagline || '',
    tier: like.likedBy.tier || ''
  }));

  return formattedLikes;
};

const formatPostModalLikes = (likes: UserEntity[]) => {
  const formattedLikes: Like[] = likes?.map((like) => ({
    id: like.id!,
    imageFileName: like.profile_img,
    fullName: like.fullname || '',
    firstName: like.firstName || '',
    husslupId: like.husslupId || '',
    userType: like.userType || '',
    professionalTagline: like.professionalTagline || '',
    tier: like.tier || ''
  }));

  return formattedLikes;
};

export const createTextForLikedByUsers = (
  userList: Array<Like>,
  scale = 1,
): string | null => {
  let likeText = '';
  const lengthOfUserList: number = userList.length;
  if (lengthOfUserList === 1) {
    likeText =
      TextUtils.truncateWithEllipse(userList[0].fullName, 20) ?? 'no-name';
  } else if (lengthOfUserList === 2) {
    const firstPersonName =
      TextUtils.truncateWithEllipse(userList[0].fullName, 20 - scale) ??
      'no-name';
    likeText = `${firstPersonName} and 1 other`;
  } else if (lengthOfUserList > 2) {
    const lastPersonName =
      TextUtils.truncateWithEllipse(
        userList[userList.length - 1].fullName,
        20 - scale,
      ) ?? 'no-name';
    likeText = `${lastPersonName} and ${(
      userList.length - 1
    ).toString()} others`;
  } else return '';
  return likeText;
};

const sortComments = (comments: Comment[]) => {
  if (comments?.length) {
    return comments.sort((a, b) =>
      (a?.createdDate ?? 0) < (b?.createdDate ?? 0) ? -1 : 1,
    );
  }
  return comments;
};

const formatPostComments = (comments: CommentActivities[]) => {
  const formattedComments: CommentActivities[] =
    (comments?.map((comment) => ({
      ...comment,
      originalComment: comment.comment || '',
      comment: formatCommentBody(
        comment.comment!,
        comment.mentionedUserIdWithHusslupId || [],
      ),
      isEdited: comment.createdDate !== comment.updatedDate,
      children: comment.children?.length
        ? formatPostComments(comment.children)
        : [],
    })) as CommentActivities[]) ?? [];

  return sortComments(formattedComments);
};

function replaceMultiple(text: any, pattern: any, replacement: any) {
  return text.replace(new RegExp(pattern, 'g'), replacement);
}

const formatCommentBody = (
  comment: string,
  mentionedUsers: { id: number; husslupId: string }[],
): string => {
  let updatedText = comment;
  mentionedUsers.forEach((user) => {
    const userProfileLink = `${PROFILE}/${user.id}`;
    updatedText = replaceMultiple(updatedText, user.husslupId, userProfileLink);
  });
  return updatedText;
};

const reverseFormatCommentBody = (
  comment: string,
  mentionedUsers?: { id: number; husslupId: string }[],
): string => {
  if (!mentionedUsers) return comment;
  let updatedText = comment;
  mentionedUsers.forEach((user) => {
    const userProfileLink = `${PROFILE}/${user.id}`;
    updatedText = replaceMultiple(updatedText, userProfileLink, user.husslupId);
  });
  return updatedText;
};

const updateProfileLinkForMentionMarkdown = (
  husslupIds: string[] = [],
  userIds: TaggedUserIdWithHusslupId[] = [],
  text: string = '',
) => {
  if (husslupIds && text) {
    let updatedText = text;
    husslupIds.forEach((item) => {
      const equivalentUserIdRow = userIds.find(
        (idRow) => idRow.husslupId === item,
      );
      if (equivalentUserIdRow) {
        const userProfileLink = `${PROFILE}/${equivalentUserIdRow.id}`;
        updatedText = updatedText.replace(item, userProfileLink);
      }
    });
    return updatedText;
  }
  return text;
};

const getLatestComments = (comments: Comment[] | undefined, count: number) => {
  if (!comments?.length) return [];

  const sortedComments = [...comments];
  return sortComments(sortedComments).slice(-count) ?? [];
};

const getLikedUser = (user: Partial<UserEntity>) => {
  if (user?.id) {
    return {
      firstName: user.firstName,
      fullName: user.fullname,
      husslupId: user.husslupId,
      id: user.id,
      imageFileName: user.profile_img ?? '',
      userType: user.userType ?? UserTypeEnum.MEMBER,
      tier: user.tier,
    };
  }
  return {};
};

const removeLikeFromSinglePost = (
  post: Post,
  likedBy: Partial<UserEntity>,
  postId: number,
) => {
  if (post.post_id === postId) {
    const updatedLikesList =
      post?.likes?.filter((like) => like.id !== likedBy.id) ?? [];

    return {
      ...post,
      likes: updatedLikesList,
      isLikedByMe: false,
    };
  }
  return post;
};

const removeCommentFromSinglePost = (
  post: Post,
  commentId: number,
  postId: number,
  commentLevel: number,
) => {
  let commentList = [] as CommentActivities[];
  if (post.post_id === postId) {
    if (commentLevel === 0) {
      commentList =
        post?.commentActivities?.filter(
          (comment) => comment.commentId !== commentId,
        ) ?? [];
    }
    if (commentLevel === 1) {
      commentList = post?.commentActivities?.map(
        (comment: CommentActivities) => {
          const updatedComment = {
            ...comment,
            children: comment.children?.filter(
              (child_comment: Comment) => child_comment.commentId !== commentId,
            ),
          };

          return updatedComment;
        },
      ) as CommentActivities[];
    }

    return {
      ...post,
      commentActivities: commentList,
    };
  }
  return post;
};

const removeCommentFromPostList = (
  data: Pagination<Post>,
  commentId: number,
  postId: number,
  commentLevel: number,
) => {
  const updatedPost = data.items.map((post) =>
    removeCommentFromSinglePost(post, commentId, postId, commentLevel),
  );

  return updatedPost;
};

const addLikeToSinglePost = (
  post: Post,
  likedBy: Partial<UserEntity>,
  postId: number,
) => {
  const likedUser = getLikedUser(likedBy);
  if (post.post_id === postId) {
    const wasPreviouslyLiked = post?.likes?.find(
      (like: Like) => like.id === likedBy.id,
    );
    if (!wasPreviouslyLiked) {
      return {
        ...post,
        likes: post?.likes ? [...post.likes, likedUser] : [likedUser],
        isLikedByMe: true,
      };
    }
  }
  return post;
};

const addLikeToPostList = (
  data: Pagination<Post>,
  likedBy: Partial<UserEntity>,
  postId: number,
) => {
  const updatedPost = data.items.map((post) =>
    addLikeToSinglePost(post, likedBy, postId),
  );

  return updatedPost;
};

const removeLikeFromPostList = (
  data: Pagination<Post>,
  likedBy: Partial<UserEntity>,
  postId: number,
) => {
  const updatedPost = data.items.map((post) =>
    removeLikeFromSinglePost(post, likedBy, postId),
  );

  return updatedPost;
};

const addCommentToSinglePost = (
  post: Post,
  addedComment: CommentActivities,
  postId: number,
) => {
  if (post.post_id === postId) {
    let tempComment = {} as CommentActivities;
    if (addedComment.level === 0) {
      const isCommentAdded = post?.commentActivities?.find(
        (comment: CommentActivities) =>
          comment.commentId === addedComment.commentId,
      );
      if (!isCommentAdded) {
        tempComment = {
          ...addedComment,
          originalComment: addedComment.comment || '',
          comment: formatCommentBody(
            addedComment.comment || '',
            addedComment.mentionedUserIdWithHusslupId || [],
          ),
          userLikes: [],
          children: [],
        };
        const updatedComment = post?.commentActivities?.length
          ? [...post?.commentActivities, tempComment]
          : [tempComment];

        return {
          ...post,
          commentActivities: updatedComment,
        };
      }
    }

    if (addedComment.level === 1) {
      const parentComment = post?.commentActivities?.find(
        (comment: CommentActivities) =>
          comment.commentId === addedComment?.parent_comment_id?.commentId,
      );

      if (parentComment) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { parent_comment_id, ...commentToAdd } = addedComment;

        const threadObj = {
          ...commentToAdd,
          originalComment: commentToAdd.comment || '',
          comment: formatCommentBody(
            commentToAdd.comment || '',
            commentToAdd.mentionedUserIdWithHusslupId || [],
          ),
          userLikes: [],
        };

        const updatedPost = post.commentActivities?.map(
          (comment: CommentActivities) => {
            if (
              comment.commentId === addedComment.parent_comment_id?.commentId
            ) {
              const isCommentAdded = comment?.children?.find(
                (oldComment) => oldComment.commentId === threadObj.commentId,
              );
              if (!isCommentAdded) {
                return {
                  ...comment,
                  children: comment.children?.length
                    ? [...comment.children, threadObj]
                    : [threadObj],
                };
              }
              return comment;
            }
            return comment;
          },
        );

        const result = {
          ...post,
          commentActivities: updatedPost,
        };
        return result;
      }
    }
  }
  return post;
};

const addCommentToPostList = (
  data: Pagination<Post>,
  addedComment: Comment,
  postId: number,
) => {
  const updatedPost = data.items.map((post) =>
    addCommentToSinglePost(post, addedComment, postId),
  );

  return updatedPost;
};

const addCommentLikesInSinglePost = (
  post: Post,
  commentId: number,
  postId: number,
  currentUser: UserEntity,
  commentLevel: number,
): Post => {
  let commentActivities = [] as CommentActivities[];
  if (commentLevel === 0) {
    commentActivities = post?.commentActivities?.map(
      (cmt: CommentActivities) => {
        if (
          cmt.commentId === commentId &&
          postId === post.post_id &&
          !cmt.userLikes?.some((usr) => usr.husslupId === currentUser.husslupId)
        ) {
          return {
            ...cmt,
            likesQty: Number(cmt.likesQty || 0) + 1,
            userLikes: [...cmt.userLikes, currentUser],
          };
        }
        return cmt;
      },
    ) as CommentActivities[];
  }
  if (commentLevel === 1) {
    commentActivities = post?.commentActivities?.map(
      (comment: CommentActivities) => ({
        ...comment,
        children: comment.children?.map((commentThread: Comment) => {
          if (
            commentThread.commentId === commentId &&
            !commentThread.userLikes?.some(
              (usr) => usr.husslupId === currentUser.husslupId,
            )
          ) {
            return {
              ...commentThread,
              likesQty: Number(commentThread.likesQty || 0) + 1,
              userLikes: [...commentThread.userLikes, currentUser],
            };
          }
          return commentThread;
        }),
      }),
    ) as CommentActivities[];
  }

  return {
    ...post,
    commentActivities,
  };
};

const addCommentLikeInPostList = (
  data: Pagination<Post>,
  commentId: number,
  postId: number,
  currentUser: UserEntity,
  commentLevel: number,
) => {
  const updatedPost = data.items.map((post) =>
    addCommentLikesInSinglePost(
      post,
      commentId,
      postId,
      currentUser,
      commentLevel,
    ),
  );

  return updatedPost;
};

const removeCommentLikesInSinglePost = (
  post: Post,
  commentId: number,
  postId: number,
  currentUser: UserEntity,
  commentLevel: number,
): Post => {
  let commentActivities = [] as CommentActivities[];
  if (commentLevel === 0) {
    commentActivities = post?.commentActivities?.map(
      (cmt: CommentActivities) => {
        if (
          cmt.commentId === commentId &&
          postId === post.post_id &&
          cmt.userLikes?.some((usr) => usr.husslupId === currentUser.husslupId)
        ) {
          return {
            ...cmt,
            likesQty: Number(cmt.likesQty || 0) - 1,
            userLikes: cmt.userLikes.filter(
              (usr) => usr.husslupId !== currentUser.husslupId,
            ),
          };
        }
        return cmt;
      },
    ) as CommentActivities[];
  }
  if (commentLevel === 1) {
    commentActivities = post?.commentActivities?.map(
      (comment: CommentActivities) => ({
        ...comment,
        children: comment.children?.map((commentThread: Comment) => {
          if (
            commentThread.commentId === commentId &&
            postId === post.post_id &&
            commentThread.userLikes?.some(
              (usr) => usr.husslupId === currentUser.husslupId,
            )
          ) {
            return {
              ...commentThread,
              likesQty: Number(commentThread.likesQty || 0) - 1,
              userLikes: commentThread.userLikes.filter(
                (usr) => usr.husslupId !== currentUser.husslupId,
              ),
            };
          }
          return commentThread;
        }),
      }),
    ) as CommentActivities[];
  }

  return {
    ...post,
    commentActivities,
  };
};

const removeCommentLikeInPostList = (
  data: Pagination<Post>,
  commentId: number,
  postId: number,
  currentUser: UserEntity,
  commentLevel: number,
) => {
  const updatedPost = data.items.map((post) =>
    removeCommentLikesInSinglePost(
      post,
      commentId,
      postId,
      currentUser,
      commentLevel,
    ),
  );

  return updatedPost;
};

const updateCommentInSinglePost = (
  post: Post,
  updatedComment: CommentActivities,
  postId: number,
  commentLevel: number,
) => {
  if (post.post_id === postId) {
    let updatedCommentList = [] as CommentActivities[];
    if (commentLevel === 0) {
      updatedCommentList = post?.commentActivities?.map(
        (comment: CommentActivities) => {
          if (comment.commentId === updatedComment.commentId) {
            return {
              ...comment,
              comment: formatCommentBody(
                updatedComment.comment || '',
                updatedComment.mentionedUserIdWithHusslupId || [],
              ),
              updatedDate: updatedComment.updatedDate,
              mentionedUsers: updatedComment.mentionedUsers,
              mentionedUserIdWithHusslupId:
                updatedComment.mentionedUserIdWithHusslupId,
              isEdited: true,
              userLikes: comment.userLikes,
            };
          }
          return comment;
        },
      ) as CommentActivities[];
    }
    if (commentLevel === 1) {
      updatedCommentList = post.commentActivities?.map(
        (comment: CommentActivities) => ({
          ...comment,
          children: comment.children?.map((commentThread: Comment) => {
            if (commentThread.commentId === updatedComment.commentId) {
              return {
                ...commentThread,
                comment: formatCommentBody(
                  updatedComment.comment || '',
                  updatedComment.mentionedUserIdWithHusslupId || [],
                ),
                updatedDate: updatedComment.updatedDate,
                mentionedUsers: updatedComment.mentionedUsers,
                mentionedUserIdWithHusslupId:
                  updatedComment.mentionedUserIdWithHusslupId,
                isEdited: true,
                userLikes: commentThread.userLikes,
              };
            }
            return commentThread;
          }),
        }),
      ) as CommentActivities[];
    }
    return {
      ...post,
      commentActivities: updatedCommentList,
    };
  }
  return post;
};

const updateCommentInPostList = (
  data: Pagination<Post>,
  updatedComment: Comment,
  postId: number,
  commentLevel: number,
) => {
  const updatedPost = data.items.map((post) =>
    updateCommentInSinglePost(post, updatedComment, postId, commentLevel),
  );

  return updatedPost;
};

const addMediaType = (medias: PostMedia[]) =>
  medias?.map((media) => {
    if (media?.filename) {
      return {
        ...media,
        type: getMediaTypeFromFileName(media.filename) as FileType,
      };
    }
    return media;
  }) ?? [];

const PostUtils = {
  formatPostLikes,
  formatPostModalLikes,
  formatPostComments,
  formatPostLikesByUser,
  createTextForLikedByUsers,
  getLatestComments,
  getLikedUser,
  addLikeToSinglePost,
  addLikeToPostList,
  removeLikeFromSinglePost,
  removeLikeFromPostList,
  addCommentToPostList,
  addCommentToSinglePost,
  addMediaType,
  removeCommentFromPostList,
  removeCommentFromSinglePost,
  updateCommentInPostList,
  updateCommentInSinglePost,
  updateProfileLinkForMentionMarkdown,
  addCommentLikeInPostList,
  removeCommentLikeInPostList,
  addCommentLikesInSinglePost,
  removeCommentLikesInSinglePost,
  formatCommentBody,
  reverseFormatCommentBody,
};

export default PostUtils;
