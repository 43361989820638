import { FunctionComponent, ReactNode, useEffect } from 'react';
import debounce from 'debounce';
import {
  MentionsInput,
  Mention,
  OnChangeHandlerFunc,
  DataFunc,
  SuggestionDataItem,
} from 'react-mentions';
import {
  useLazySearchConnectionsQuery,
  UserConnection,
} from 'store/user/users.api';
import NextImage from 'components/Core/Image';
import { getS3ProfileUrl } from 'utils/file';

const mentionInputStyle = {
  control: {
    fontSize: 16,
  },
  '&multiLine': {
    control: {
      minHeight: 65,
    },
    highlighter: {
      padding: 9,
      border: 'none',
    },
    input: {
      padding: 9,
      border: 'none',
    },
  },
  '&singleLine': {
    display: 'inline-block',
    width: 180,
    highlighter: {
      padding: 1,
      border: 'none',
    },
    input: {
      padding: 1,
      border: 'none',
    },
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 16,
      borderRadius: 8,
      overflow: 'auto',
    },
    item: {
      padding: '10px 16px',
      borderBottom: '1px solid #e4e4e4',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
interface IProps {
  initialValue?: string;
  required: boolean;
  placeholder?: string;
  error?: any;
  disabled?: boolean;
  name?: string;
  value?: string;
  onChange?: OnChangeHandlerFunc;
  onBlur?: () => void;
  onKeyDown?: () => void;
  onUsersMentioned?: (id: string | number, display: string) => void;
  inputRef?: any;
}

const MAX_RESULTS_PER_SEARCH = 100;
const DEBOUNCE_SECONDS = 200;
const CreatePostTextarea: FunctionComponent<IProps> = ({
  required,
  placeholder,
  disabled,
  value,
  onChange,
  onBlur,
  onKeyDown,
  onUsersMentioned,
  inputRef
}) => {
  const [trigger] = useLazySearchConnectionsQuery();

  const handleSearchConnections: DataFunc = debounce(
    async (query: string, callback: (data: SuggestionDataItem[]) => void) => {
      const data = await trigger({
        searchText: query,
        limit: MAX_RESULTS_PER_SEARCH,
      }).unwrap();

      const transformedData = data.map((row: UserConnection) => ({
        id: row.husslupId,
        display: row.fullname,
        profileImg: getS3ProfileUrl(row.profileImg, 16, 16),
      }));

      callback(transformedData);
    },
    DEBOUNCE_SECONDS,
  );

  const renderSuggestion = (
    suggestion: any,
    search: string,
    highlightedDisplay: ReactNode,
  ) => (
    <>
      <NextImage
        alt=''
        height={36}
        width={36}
        src={suggestion?.profileImg as string}
        className='mr-12px'
      />
      {highlightedDisplay}
    </>
  );

  const customSuggestionsContainer = (children: ReactNode) => (
    <div className='my-custom-suggestions'>
      <div className='suggestions-title'> Tag Users</div>
      {children}
    </div>
  );

  const handlePaste = (ev: Event) => {
    ev.preventDefault();
  };

  useEffect(() => {
    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [inputRef]);

  return (
    <MentionsInput
      style={mentionInputStyle}
      className='post-modal__textarea'
      value={value}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      onChange={onChange}
      onKeyDown={onKeyDown}
      allowSpaceInQuery
      onBlur={onBlur}
      customSuggestionsContainer={customSuggestionsContainer}
      inputRef={inputRef}
    >
      <Mention
        className='mention'
        trigger='@'
        data={handleSearchConnections}
        onAdd={onUsersMentioned}
        renderSuggestion={renderSuggestion}
        // appendSpaceOnAdd
      />
    </MentionsInput>
  );
};

export default CreatePostTextarea;