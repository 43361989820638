import {
  FunctionComponent,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  useGetPostByIdQuery,
  useUpdatePostMutation,
} from 'store/post/post.api';
import type { MuxVideo, PostMedia, PostTag } from 'commons/types/post.type';
import useMuxUploadVideo from 'commons/hooks/useMuxVideoUpload';
import ToasterUtils from 'utils/toaster';
import useAuth from 'commons/hooks/auth';
import { isImage, isPdf } from 'utils/file';
import LeavePageAlert from 'components/Core/NavigationBlocker/LeavePageAlert';
import {
  CreatePostAsProfile,
  PostAsDropdown,
  CreatePostTextarea,
  CreatePostPdfUploader,
  CreatePostTagSelector,
} from '../CreatePost';
import CreatePostMediaUploader from '../CreatePost/CreatePostMediaUploader';
import EditPostFileUploads from './EditPostFileUploads';
import { PostAsType, PostCreatorEntities } from '../CreatePost/CreatePostModal';

const rows = 1;
const cols = 80;

const EditPostModal: FunctionComponent<{
  postId: number | null;
  showModal: boolean;
  postTags?: PostTag[];
  onFormSubmitSuccess: () => void;
  onDismissModal?: () => void;
}> = ({
  postId,
  showModal = false,
  postTags,
  onFormSubmitSuccess,
  onDismissModal,
}) => {
  const closeButtonRef = useRef<HTMLElement>(null);
  const [uploadedImage, setUploadedImage] = useState<File | PostMedia>();
  const [uploadedVideo, setUploadedVideo] = useState<File | MuxVideo>();
  const [uploadedPdf, setUploadedPdf] = useState<File | PostMedia>();
  const [usersMentioned, setUsersMentioned] = useState<any[]>([]);
  const [postAs, setPostAs] = useState<PostAsType>(PostCreatorEntities.USER);
  const [post, setPost] = useState<string>('');
  const [selectedTag, setSelectedTag] = useState<PostTag>();
  const [wasMediaReplaced, setWasMediaReplaced] = useState<boolean>(false);
  const [wasMediaRemoved, setWasMediaRemoved] = useState<boolean>(false);
  const [wasVideoReplaced, setWasVideoReplaced] = useState<boolean>(false);
  const [wasVideoRemoved, setWasVideoRemoved] = useState<boolean>(false);
  const [textAreaActiveClass, setTextAreaActiveClass] = useState<string>('');
  const [showLeaveAlert, setShowLeaveAlert] = useState<boolean>(false);
  const { user, company: authUserCompany } = useAuth();
  const [updatePost, { isLoading: isCreating }] = useUpdatePostMutation();
  const { t } = useTranslation('misc');
  const { muxVideoUrl, muxVideoUploadStatus } =
    useMuxUploadVideo(uploadedVideo);
  const { data, isLoading } = useGetPostByIdQuery(
    { postId: postId as number },
    {},
  );

  const initialData = useMemo(
    () => ({
      post: data?.originalPost?.replaceAll(/\r/g, ''),
      uploadedImage: data?.media?.find((media) =>
        isImage(media.filename as string),
      ),
      uploadedPdf: data?.media?.find((media) =>
        isPdf(media.filename as string),
      ),
      uploadedVideo: data?.muxVideo,
    }),
    [data],
  );

  useEffect(() => {
    setPost(data?.originalPost?.replaceAll(/\r/g, '') || '');
    setSelectedTag(data?.tag);
    if (data?.postAs) {
      setPostAs(data.postAs as PostAsType);
    }

    data?.media?.forEach((media: PostMedia) => {
      if (isImage(media.filename as string)) {
        setUploadedImage(media);
      }
      if (isPdf(media.filename as string)) {
        setUploadedPdf(media);
      }
    });

    if (data?.muxVideo) {
      setUploadedVideo(data.muxVideo);
    }

    return () => {
      resetForm();
    };
  }, [postId, data]);

  const handleImageUpload = (file: File) => {
    if (uploadedImage && !(uploadedImage instanceof File)) {
      setWasMediaReplaced(true);
      setWasMediaRemoved(false);
    }
    if (uploadedVideo) {
      setWasVideoRemoved(true);
      setUploadedVideo(undefined);
    }

    setUploadedImage(file);
  };

  const handleVideoUpload = async (file: File) => {
    if (uploadedVideo && !(uploadedVideo instanceof File)) {
      setWasVideoReplaced(true);
      setWasVideoRemoved(false);
    }
    if (uploadedImage) {
      setWasMediaRemoved(true);
      setUploadedImage(undefined);
    }

    setUploadedVideo(file);
  };

  const handlePdfUpload = (file: File) => {
    if (uploadedPdf && !(uploadedPdf instanceof File)) {
      setWasMediaReplaced(true);
      setWasMediaRemoved(false);
    }
    setUploadedPdf(file);
  };

  const handlePostAsCompanySelected = () => {
    setPostAs(PostCreatorEntities.COMPANY);
  };

  const handlePostAsUserSelected = () => {
    setPostAs(PostCreatorEntities.USER);
  };

  const handleImageUnlink = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (uploadedImage && !(uploadedImage instanceof File)) {
      setWasMediaRemoved(true);
    }
    setUploadedImage(undefined);
  };

  const handlePdfUnlink = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (uploadedPdf && !(uploadedPdf instanceof File)) {
      setWasMediaRemoved(true);
    }
    setUploadedPdf(undefined);
  };

  const handleVideoUnlink = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!(uploadedImage instanceof File)) {
      setWasVideoRemoved(true);
    }
    setUploadedVideo(undefined);
  };

  const handleUserMentioned = (id: string | number) => {
    setUsersMentioned([...usersMentioned, id]);
  };

  const resetForm = () => {
    setPost('');
    setUploadedImage(undefined);
    setUploadedPdf(undefined);
    setUploadedVideo(undefined);
    setUsersMentioned([]);
    setWasMediaRemoved(false);
    setWasMediaReplaced(false);
    setWasVideoRemoved(false);
    setWasVideoReplaced(false);
  };

  const handlePostCreate = async () => {
    try {
      const formData = new FormData();
      formData.append('post', post);
      formData.append('postId', `${postId}`);

      if (uploadedImage && uploadedImage instanceof File) {
        formData.append('files', uploadedImage);
      }

      if (wasMediaRemoved) {
        formData.append('mediaWasRemoved', 'true');
      }

      if (wasMediaReplaced) {
        formData.append('mediaWasReplaced', 'true');
      }

      if (wasVideoRemoved) {
        formData.append('videoWasRemoved', 'true');
      }

      if (wasVideoReplaced) {
        formData.append('videoWasReplaced', 'true');
      }

      if (uploadedPdf && uploadedPdf instanceof File) {
        formData.append('files', uploadedPdf);
      }
      if (selectedTag) {
        formData.append('tagId', selectedTag.id);
      }
      if (usersMentioned) {
        usersMentioned.forEach((userId) => {
          formData.append('taggedUsersId', userId);
        });
      }
      if (uploadedVideo && muxVideoUrl && uploadedVideo instanceof File) {
        formData.append('muxVideoId', muxVideoUrl.id);
      }
      if (postAs === 'company' && authUserCompany) {
        formData.append('postAs', postAs);
        formData.append('companyId', authUserCompany?.id);
      }
      await updatePost(formData).unwrap();
      resetForm();
      onFormSubmitSuccess();
    } catch (err) {
      ToasterUtils.errorToast(t('EDIT_POST_ERROR.value'));
    }
  };

  const hasPost = useMemo(() => {
    if (
      post !== initialData.post ||
      uploadedImage !== initialData.uploadedImage ||
      uploadedPdf !== initialData.uploadedPdf ||
      uploadedVideo !== initialData.uploadedVideo
    ) {
      return true;
    }
    return false;
  }, [post, uploadedImage, uploadedPdf, uploadedVideo, usersMentioned]);

  const handleModalStay = (): void => {
    setShowLeaveAlert(false);
  };

  const handleModalLeave = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowLeaveAlert(false);
    resetForm();
    onDismissModal && onDismissModal();
  };

  const onDismissModalHandler = () => {
    if (hasPost) {
      setShowLeaveAlert(true);
    } else {
      setShowLeaveAlert(false);
      onDismissModal && onDismissModal();
    }
  };

  const handlePopstate = (e: PopStateEvent) => {
    if (hasPost) {
      e.preventDefault();
      window.history.go(1);
      setShowLeaveAlert(true);
    }
  };

  const handleBeforeUnload = (e: BeforeUnloadEvent): void => {
    if (hasPost) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  useEffect(() => {
    if (hasPost)
      window.history.pushState(null, null as any, window.location.href);
    window.addEventListener('popstate', handlePopstate);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [hasPost]);

  useEffect(() => {
    if (hasPost)
      window.history.pushState(null, null as any, window.location.href);
    window.addEventListener('popstate', handlePopstate);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [hasPost]);

  const [, setAdjustedRows] = useState(rows);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    if (textAreaRef?.current && textAreaRef?.current?.value) {
      const textAreaValue = textAreaRef?.current?.value;
      const numberOfColumns = textAreaRef?.current?.cols;

      let linecount = 0;
      textAreaValue.split('\n').forEach((line) => {
        const extraRowForLongColumn = Math.floor(line.length / numberOfColumns);
        const rowsToAdd =
          1 + (line.length / cols > 1 ? extraRowForLongColumn : 0);
        linecount += rowsToAdd;
      });

      // if (linecount < MAX_TEXTAREA_ROWS) {
      setAdjustedRows(linecount);
      // }
    }
  };

  useEffect(() => {
    if (!post) {
      setAdjustedRows(1);
    } else {
      adjustHeight();
    }
  }, [post]);

  // const contentHeight = useMemo(() => {
  //   if (textAreaRef?.current) {
  //     const lineHeight = window
  //       .getComputedStyle(textAreaRef?.current as any)
  //       .lineHeight.replace('px', '');
  //     return Number(lineHeight) * 2 * adjustedRows;
  //   }
  //   return 205;
  // }, [adjustedRows, post]);

  return (
    <>
      <LeavePageAlert
        showModal={showLeaveAlert}
        handleModalLeave={handleModalLeave}
        handleModalStay={handleModalStay}
      />
      <Modal
        show={showModal}
        size='lg'
        onHide={onDismissModalHandler}
        ref={closeButtonRef}
        className='post-modal'
        animation
        keyboard
        key={postId}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='profile-block'>
            <CreatePostAsProfile
              postAs={postAs}
              creator={
                postAs === PostCreatorEntities.COMPANY && authUserCompany
                  ? authUserCompany
                  : user
              }
              onTagUnselected={() => {
                setSelectedTag(undefined);
              }}
              selectedTag={selectedTag}
            />
            {authUserCompany && (
              <PostAsDropdown
                authUser={user}
                company={authUserCompany}
                postAs={postAs}
                onCompanySelected={handlePostAsCompanySelected}
                onUserSelected={handlePostAsUserSelected}
              />
            )}
          </div>
          <div
            className={`post-modal__content ${textAreaActiveClass}`}
            // style={{
            //   height: contentHeight,
            // }}
          >
            {isLoading ? (
              <div className='d-flex justify-content-center pt-2'>
                <Spinner animation='border' />
              </div>
            ) : (
              <CreatePostTextarea
                placeholder='Tell me something good ...'
                required
                value={post}
                onBlur={() => {
                  setTextAreaActiveClass('');
                }}
                onKeyDown={() => {
                  setTextAreaActiveClass('active');
                }}
                onChange={(event: any) => {
                  setPost(event.target.value?.replaceAll(/\r/g, ''));
                }}
                onUsersMentioned={handleUserMentioned}
              />
            )}
            <EditPostFileUploads
              uploadedImage={uploadedImage}
              uploadedDoc={uploadedPdf}
              uploadedVideo={uploadedVideo}
              onUnlinkImage={handleImageUnlink}
              onUnlinkDoc={handlePdfUnlink}
              onUnlinkVideo={handleVideoUnlink}
            />
            <div className='misc-holder'>
              <div className='misc'>
                <div className='misc__attachments'>
                  <CreatePostMediaUploader
                    onVideoUpload={handleVideoUpload}
                    onImageUpload={handleImageUpload}
                  />
                  <CreatePostPdfUploader onPdfUpload={handlePdfUpload} />
                </div>
                <div className='misc__tags'>
                  <CreatePostTagSelector
                    onTagSelected={(tag: PostTag) => {
                      setSelectedTag(tag);
                    }}
                    selectedTag={selectedTag}
                    tags={postTags}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ marginTop: '-8px' }}>
          <button
            disabled={
              !hasPost || muxVideoUploadStatus === 'progress' || isCreating
            }
            type='submit'
            onClick={handlePostCreate}
            className='send'
          >
            <i className='hic-sended hic-3x' />
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditPostModal;