import { useMemo } from 'react';

import { SendInviteModalProps } from 'components/MyNetwork/ReferralInvites';
import useVisibility from '../useVisibility';

export const usePendingReferralActions = () => {
  const {
    show: showSendInvite,
    hide: hideSendInvite,
    isVisible: isSendInviteVisible,
  } = useVisibility();

  const sendInviteDialogPropGetter = useMemo(
    () =>
      ({
        show: isSendInviteVisible,
        onClose: hideSendInvite,
      } as SendInviteModalProps),
    [isSendInviteVisible],
  );

  return { showSendInvite, sendInviteDialogPropGetter };
};

export default usePendingReferralActions;
