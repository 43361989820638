import { ReactElement } from "react";
import { ButtonGroup as BButtonGroup } from "react-bootstrap";
import { ButtonSizes } from "./Button";

export interface ButtonGroupProps extends React.HTMLAttributes<HTMLElement> {
  size?: ButtonSizes;
  vertical?: boolean;
  children: ReactElement[] | ReactElement;
}

const ButtonGroup = ({
  size,
  vertical,
  children,
}: ButtonGroupProps): ReactElement => (
  <BButtonGroup size={size} vertical={vertical}>
    {children}
  </BButtonGroup>
);

export default ButtonGroup;
