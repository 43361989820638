import { FunctionComponent } from 'react';
import Activities from 'constants/notification';
import CommentedOnPostActivity from './Activities/CommentedOnPostActivity';
import MentionedOnActivity from './Activities/MentionedOnActivity';
import ConnectionRequestActivity from './Activities/ConnectionRequestActivity';
import ContactSheetOwnerReceivedActivity from './Activities/ContactSheetOwnerReceivedActivity';
import VouchRequestActivity from './Activities/VouchRequestActivity';
import ContactSheetOwnerTransferredActivity from './Activities/ContactSheetOwnerTransferredActivity';
import PostLikesActivity from './Activities/PostLikesActivity';
import StaffingRosterSharedActivity from './Activities/StaffingRosterAccessActivity';
import StaffingRosterReframeSubmitActivity from './Activities/StaffingRosterReframeSubmit';
import SubscriptionInviteMemberToSeatActivity from './Activities/SubscriptionInviteMemberToSeatActivity';
import SubscriptionRemoveMemberActivity from './Activities/SubscriptionRemoveMemberActivity';
import SubscriptionAssignMemberActivity from './Activities/SubscriptionAssignMemberActivity';
import SubscriptionPlanChangedActivity from './Activities/SusbcriptionPlanChangedActivity';
import SubscriptionSignupActivity from './Activities/SubscriptionSignupActivity';

const notificationActivities: Record<
  string,
  FunctionComponent<{ activity: any }>
> = {
  [Activities.COMMENTS_ON_POST]: CommentedOnPostActivity,
  [Activities.USER_MENTIONED_ON_A_COMMENT]: MentionedOnActivity,
  [Activities.USER_MENTIONED_ON_A_POST]: MentionedOnActivity,
  [Activities.TAGGED_ON_POST]: MentionedOnActivity,
  /* [Activities.GROUP_INVITE_REQUEST]: JoinRequestActivity, */
  [Activities.LIKES_POST]: PostLikesActivity,
  [Activities.CONNECTION_REQUEST]: ConnectionRequestActivity,
  [Activities.CONTACT_SHEET_OWNERSHIP_RECEIVED]:
    ContactSheetOwnerReceivedActivity,
  [Activities.CONTACT_SHEET_OWNERSHIP_TRANSFERRED]:
    ContactSheetOwnerTransferredActivity,
  [Activities.VOUCH_REQUEST]: VouchRequestActivity,
  [Activities.STAFFING_ROSTER_ACCESS_REMOVED]: StaffingRosterSharedActivity,
  [Activities.STAFFING_ROSTER_SHARE_ACCESS]: StaffingRosterSharedActivity,
  [Activities.STAFFING_ROSTER_TRANSFERRED_OWNERSHIP]:
    StaffingRosterSharedActivity,
  [Activities.STAFFING_ROSTER_REFRAME_SUBMIT]:
    StaffingRosterReframeSubmitActivity,
  [Activities.SUBSCRIPTION_INVITE_MEMEBRS_TO_ASSIGN_SEAT]:
    SubscriptionInviteMemberToSeatActivity,
  [Activities.SUBSCRIPTION_REMOVE_MEMBER]: SubscriptionRemoveMemberActivity,
  [Activities.SUBSCRIPTION_ASSIGN_MEMBER]: SubscriptionAssignMemberActivity,
  [Activities.SUBSCRIPTION_PLAN_CHANGED]: SubscriptionPlanChangedActivity,
  [Activities.SUBSCRIPTION_SIGNUP]: SubscriptionSignupActivity,
};

const NotificationItem: FunctionComponent<{ activity: any }> = ({
  activity,
}) => {
  const notificationActivityType = activity?.verb;

  const NotificationItemComponent =
    notificationActivities[
      notificationActivityType as keyof typeof notificationActivities
    ];

  if (!NotificationItemComponent) {
    return null;
  }
  return <NotificationItemComponent activity={activity} />;
};

export default NotificationItem;
