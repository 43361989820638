import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const ONBOARD_SLICE_KEY = 'onboard';

export interface OnboardSliceState {
  isOnboarding?: boolean;
}

const initialState: OnboardSliceState = { isOnboarding: false };

const onboardSlice = createSlice({
  name: ONBOARD_SLICE_KEY,
  initialState,
  reducers: {
    setIsOnboarding(state, action: PayloadAction<boolean>) {
      state.isOnboarding = action.payload;
    },
  },
});

export const { setIsOnboarding } = onboardSlice.actions;

export const onboardReducer = onboardSlice.reducer;
