import { current } from '@reduxjs/toolkit';
import { baseApiSlice } from 'commons/apis/base-api.config';
import { Like, LikePostPayload } from 'commons/types/like.type';
import { Post } from 'commons/types/post.type';
import { API_LIKE_POST } from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
import PostUtils from 'utils/post';
import { postApiSlice } from './post.api';

export const likeApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      likePost: builder.mutation<any, LikePostPayload>({
        query(payload) {
          return {
            url: API_LIKE_POST,
            method: HTTP_METHODS.POST,
            body: payload.body,
          };
        },
        transformResponse: (data: any) => data,
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            postApiSlice.util.updateQueryData(
              'getPostsWithTag',
              props.getPostWithTagParams,
              (draft) => {
                if (current(draft)?.items?.length) {
                  const updatedPost = PostUtils.addLikeToPostList(
                    current(draft),
                    props.likedBy,
                    props.body.post_id,
                  );

                  const updatedDraft = {
                    ...current(draft),
                    items: [...updatedPost],
                  };

                  Object.assign(draft, updatedDraft);
                }
              },
            ),
          );

          const patchSinglePostResult = dispatch(
            postApiSlice.util.updateQueryData(
              'getPostById',
              { postId: props.body.post_id },
              (draft) => {
                if (current(draft).post_id) {
                  const updatedPost = PostUtils.addLikeToSinglePost(
                    current(draft),
                    props.likedBy,
                    props.body.post_id,
                  );

                  Object.assign(draft, updatedPost);
                }
              },
            ),
          );

          try {
            await queryFulfilled;
          } catch {
            /**
             * @TODO Fix
             * {@link onQueryStarted} is running multiple times
             * That is why is check is required for perfoming {@link patchResult.undo}
             */
            const previousPost = patchResult.inversePatches[0].value.find(
              (post: Post) => post.post_id === props.body.post_id,
            );
            const wasPreviouslyLiked = previousPost.likes.find(
              (like: Like) => like.id === props.likedBy.id,
            );
            if (!wasPreviouslyLiked) {
              patchResult.undo();
            }

            const wasPreviousSinglePostLiked =
              patchSinglePostResult.inversePatches[0].value.likes.find(
                (like: Like) => like.id === props.likedBy.id,
              );
            if (!wasPreviousSinglePostLiked) {
              patchSinglePostResult.undo();
            }
          }
        },
      }),
      unlikePost: builder.mutation<any, LikePostPayload>({
        query(payload) {
          return {
            url: API_LIKE_POST,
            method: HTTP_METHODS.DELETE,
            body: payload.body,
          };
        },
        transformResponse: (data: any) => data,
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            postApiSlice.util.updateQueryData(
              'getPostsWithTag',
              props.getPostWithTagParams,
              (draft) => {
                if (current(draft)?.items?.length) {
                  const updatedPost = PostUtils.removeLikeFromPostList(
                    current(draft),
                    props.likedBy,
                    props.body.post_id,
                  );

                  const updatedDraft = {
                    ...current(draft),
                    items: [...updatedPost],
                  };

                  Object.assign(draft, updatedDraft);
                }
              },
            ),
          );

          const patchSinglePostResult = dispatch(
            postApiSlice.util.updateQueryData(
              'getPostById',
              { postId: props.body.post_id },
              (draft) => {
                if (current(draft).post_id) {
                  const updatedPost = PostUtils.removeLikeFromSinglePost(
                    current(draft),
                    props.likedBy,
                    props.body.post_id,
                  );

                  Object.assign(draft, updatedPost);
                }
              },
            ),
          );
          try {
            await queryFulfilled;
          } catch (err) {
            /**
             * @TODO Fix
             * {@link onQueryStarted} is running multiple times
             * That is why is check is required for perfoming {@link patchResult.undo}
             */
            const previousPost = patchResult.inversePatches[0].value.find(
              (post: Post) => post.post_id === props.body.post_id,
            );
            const wasPreviouslyLiked = previousPost.likes.find(
              (like: Like) => like.id === props.likedBy.id,
            );
            if (wasPreviouslyLiked) {
              patchResult.undo();
            }

            const wasPreviousSinglePostLiked =
              patchSinglePostResult.inversePatches[0].value.likes.find(
                (like: Like) => like.id === props.likedBy.id,
              );
            if (wasPreviousSinglePostLiked) {
              patchSinglePostResult.undo();
            }
          }
        },
      }),
    };
  },
});

export const { useLikePostMutation, useUnlikePostMutation } = likeApiSlice;
