import common from './common.json';
import users from './users.json';
import errors from './errors.json';
import profile_onboard from './profile_onboard.json';
import connection from './connection.json';
import signup from './toaster/signup.json';
import search from './toaster/search.json';
import pro_search from './toaster/pro_search.json';
import my_network from './toaster/my_network.json';
import account from './toaster/account.json';
import contact_list from './toaster/contact_list.json';
import global from './toaster/global.json';
import profile from './toaster/profile.json';
import discover from './toaster/discover.json';
import misc from './toaster/misc.json';
import blocker from './web_blocker.json';
import dropdown from './dropdown.json';
import image_cropper from './image_cropper.json';

export default {
  common,
  users,
  errors,
  profile_onboard,
  connection,
  signup,
  search,
  pro_search,
  my_network,
  account,
  contact_list,
  global,
  profile,
  discover,
  misc,
  blocker,
  dropdown,
  image_cropper,
};
