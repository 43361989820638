// eslint-disable-next-line react/no-array-index-key
import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteLoader from 'react-infinite-scroller';
import { useGetPostsWithTagQuery } from 'store/post/post.api';
import { selectGetPostWithTagParams } from 'store/post/selectors';
import {
  setFeedSearchResultCount,
  setGetPostWithTagParam,
} from 'store/post/post.slice';
import { CommentActivities } from 'commons/types/comment.type';
import DialogBox from 'components/Dialog';
import usePostActions from 'commons/hooks/usePostActions';
import ReportPostDialog from 'components/Core/Report/ReportPostDialog';
import Typography from 'components/Core/StylesGuide/Typography';
import Post from './Post';
import PostHeader from './PostHeader';
import EditPostModal from '../EditPost';
import ConnectionRecommendation from '../Recommendation';

const PostListContainer: FunctionComponent = () => {
  const getPostWithTagParams = useSelector(selectGetPostWithTagParams);
  const dispatch = useDispatch();
  const { data, isLoading, isFetching, originalArgs } = useGetPostsWithTagQuery(
    getPostWithTagParams,
    {
      // refetchOnMountOrArgChange: false,
    },
  );

  const showPage1Loader =
    isLoading || (isFetching && getPostWithTagParams?.page === 1);

  useEffect(() => {
    if (getPostWithTagParams.searchKeyword && data?.items) {
      const searchResCount = data?.meta?.totalItems ?? 0;
      dispatch(setFeedSearchResultCount(searchResCount));
    }
  }, [data]);

  const {
    getPostOptions,
    getCommentOptions,
    deleteDialogPropGetter,
    blockDialogPropGetter,
    deleteCommentDialogPropGetter,
    reportPostDialogPropGetter,
    editDialogPropGetter,
  } = usePostActions();

  const loadMoreItems = async () => {
    if (isLoading || isFetching) return;
    dispatch(
      setGetPostWithTagParam({
        ...getPostWithTagParams,
        page: (originalArgs?.page || 1) + 1,
        nextPage: true,
      }),
    );
  };

  const hasNextPage =
    (data?.meta?.totalPages ?? 0) > (data?.meta?.currentPage ?? 0);

  const getCommentProps =
    (postId: number) =>
    (initiateCommentEdit: (postId: number, commentId: number) => void) =>
    (comment: CommentActivities) => {
      if (comment?.commentId && postId) {
        return (
          getCommentOptions(
            {
              postId,
              commentedById: comment?.user?.id,
              commentId: comment?.commentId,
              commentLevel: comment.level,
            },
            initiateCommentEdit,
          ) ?? []
        );
      }
      return null;
    };

  return (
    <>
      <InfiniteLoader
        pageStart={0}
        loadMore={loadMoreItems}
        hasMore={hasNextPage}
        loader={
          showPage1Loader ? (
            <></>
          ) : (
            <div className='d-flex justify-content-center mb-4'>
              <div className='spinner-border text-primary' role='status' />
            </div>
          )
        }
      >
        <ul
          className='news-feed__list mb-4 w-100'
          key={`post-list-${getPostWithTagParams.tagId ?? '0'}`}
        >
          {showPage1Loader ? (
            <div className='d-flex justify-content-center mb-4 mt-4'>
              <div className='spinner-border text-primary' role='status' />
            </div>
          ) : data?.items.length !== 0 ? (
            data?.items.map((post, index) => (
              <>
                {index >= 2 && (index - 2) % 7 === 0 ? (
                  <>
                    <ConnectionRecommendation />
                    <Post
                      key={`${post.post_id}`}
                      post={post}
                      searchKeyword={getPostWithTagParams.searchKeyword}
                      postHeader={
                        <PostHeader
                          post={post}
                          postOptions={getPostOptions({
                            postedById: post?.posted_by?.id!,
                            postId: post?.post_id!,
                          })}
                        />
                      }
                      commentProps={getCommentProps(post.post_id!)}
                    />
                  </>
                ) : (
                  <Post
                    key={`${post.post_id}`}
                    post={post}
                    searchKeyword={getPostWithTagParams.searchKeyword}
                    postHeader={
                      <PostHeader
                        post={post}
                        postOptions={getPostOptions({
                          postedById: post?.posted_by?.id!,
                          postId: post?.post_id!,
                        })}
                      />
                    }
                    commentProps={getCommentProps(post.post_id!)}
                  />
                )}
              </>
            ))
          ) : (
            <Typography variant='p-sm-sb' style={{ color: '#4C1E4F' }}>
              No Posts yet
            </Typography>
          )}
        </ul>
      </InfiniteLoader>
      <DialogBox
        message='Are you sure you want to delete this post?'
        title='Delete Post'
        acceptText='Yes'
        {...deleteDialogPropGetter()}
      />
      <DialogBox
        message='Are you sure you want to block this user?'
        title='Block User'
        acceptText='Yes'
        {...blockDialogPropGetter()}
      />
      <DialogBox
        message='Are you sure you want to delete this comment?'
        title='Delete Comment'
        acceptText='Yes'
        {...deleteCommentDialogPropGetter()}
      />
      <ReportPostDialog {...reportPostDialogPropGetter()} />

      {editDialogPropGetter().showModal && (
        <EditPostModal {...editDialogPropGetter()} />
      )}
    </>
  );
};

export default PostListContainer;
