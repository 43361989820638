/* eslint-disable react/jsx-key */
import React, { FC } from 'react';
import SquircleImage from 'components/Core/SquircleImage/SquircleImage';
import Typography from 'components/Core/StylesGuide/Typography';
import { useRouter } from 'next/router';
import Modal from 'react-bootstrap/Modal';
import ProfileUtils from 'utils/profile';
import { truncateWithEllipse } from 'utils/text';
import {MemberChicklet, MemberChickletTypeDefault} from "../../Chicklet";

interface IProps {
  show: boolean;
  onClose: () => void;
  likedUsers: any;
}

const LikedUsersListModal: FC<IProps> = ({ onClose, show, likedUsers }) => {
  const router = useRouter();
  return (
    <Modal show={show} onHide={onClose} className='staffing-members'>
      <div className='add-edit-modal-wrap'>
        <div className='add-edit-modal-title'>
          <Typography variant='h3'>{`${likedUsers.length} Likes`}</Typography>
          <div className='add-edit-modal-body mt-1'>
            <ul className='member-list '>
            {likedUsers.map((item: any) => (
                <li className='representation-card-likedusers member-list__item'>
                  <SquircleImage
                    imgUrl={ProfileUtils.getProfileImage(
                      item.imageFileName,
                      44,
                      44,
                    )}
                    alt='image'
                    width={44}
                    height={44}
                    onClick={() => router.push(`/Profile/${item.id}`)}
                  />
                  <div className='member-info'>
                    <div>
                      <div className="d-flex">
                      <Typography variant='p-md-sb' lineClampNo={1}>
                        {item.fullName}
                      </Typography>
                        <span className='ml-4px'><MemberChicklet labelText={item?.tier || MemberChickletTypeDefault} size="md" /></span>
                      </div>
                      <Typography variant='p-md-d' lineClampNo={1}>
                        {truncateWithEllipse(item.professionalTagline ?? '')}
                      </Typography>
                    </div>


                  </div>
                </li>
            ))}
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LikedUsersListModal;
