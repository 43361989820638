import type { RootState } from 'commons/types/redux';
import { createSelector } from '@reduxjs/toolkit';

import { CreativePortfolioSliceState } from './creative-portfolio.types';
import { CREATIVE_PORTFOLIO_SLICE_KEY } from './creative-portfolio.slice';

export const selectCreativePortfolioState: (
  state: RootState,
) => CreativePortfolioSliceState = (state: RootState) =>
  state[CREATIVE_PORTFOLIO_SLICE_KEY];

export const selectAssetUploadProgress = createSelector(
  selectCreativePortfolioState,
  (creativeState) => creativeState.assetUpload?.progress,
);
