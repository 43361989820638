import useVisibility from 'commons/hooks/useVisibility';
import Button, { ButtonVariants } from 'components/Core/Button';
import HusslUpIcon from 'components/Icon';
import React, { FunctionComponent, ReactElement } from 'react';
import { useLazyGetCommentLikedUserListsQuery } from 'store/post/comment.api';
import PostUtils from 'utils/post';
import LikedUsersListModal from './LikedUsersListModal';

const CommentLikesCount: FunctionComponent<{
  likes: number;
  commentId: number;
}> = ({ likes, commentId }): ReactElement => {
  const { hide, show, isVisible } = useVisibility();
  const [getCommentLikedUserLists, { isLoading, isFetching, data }] =
    useLazyGetCommentLikedUserListsQuery();

  const handleShowLikesList = async () => {
    await getCommentLikedUserLists(commentId);
    show();
  };
  return (
    <>
      <Button
        label={`${likes}`}
        variant={ButtonVariants.text}
        className='btn-likes-count'
        onClick={handleShowLikesList}
        leftIcon={
          isLoading || isFetching ? undefined : <HusslUpIcon name='star-fill' />
        }
        disabled={Number(likes) === 0}
        loading={isLoading || isFetching}
      />
      <LikedUsersListModal
        show={isVisible}
        onClose={hide}
        likedUsers={
          PostUtils.formatPostModalLikes(data?.map((it: any) => it.user)) || []
        }
      />
    </>
  );
};

export default CommentLikesCount;