export const PROFILE_GRADE_TEXT: any = {
  profileHeadlines: 'To surface in Serendipity Mode, complete your ',
  bio: 'To surface in Serendipity Mode, add your ',
  professionalDetails: 'To be discoverable for job matches, complete your ',
  moreDetails: 'To increase your ability to get hired, add ',
  credits: 'To surface higher in search results, add your ',
  creativePortfolio: 'To surface higher in search results, add your ',
  vouches: 'To increase your opportunities, ask for ',
  featuredMedia: 'To show production partners more about you, add your ',
};

export const PROFILE_ATTRIBUTE_NAME: any = {
  profileHeadlines: 'Headings',
  bio: 'Bio',
  professionalDetails: 'Professional Details',
  moreDetails: 'More Details',
  workExperience: 'Credits/Resume',
  creativePortfolio: 'Creative Portfolio',
  vouches: 'Vouches',
  featuredMedia: 'Featured Media',
};

export const PROFILE_ATTRIBUTE_EDIT_ROUTES: any = {
  profileHeadlines: 'ProfileDetails',
  bio: 'ProfileDetails',
  professionalDetails: 'ProfessionalDetails',
  moreDetails: 'MoreDetails',
  workExperience: 'Credits',
  creativePortfolio: 'CreativePortfolio',
  vouches: 'Vouches',
  featuredMedia: 'Press',
};
