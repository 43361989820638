import { current } from '@reduxjs/toolkit';
import { baseApiSlice } from 'commons/apis/base-api.config';
import { DefaultRandomDiscoverPeoplePagination } from 'constants/data';
import { HTTP_METHODS } from 'constants/http';
import { discoverApiSlice } from 'store/discover/discover.api';
import { RandomizedDiscoverUtils } from 'utils/randomized-discover';
import { ConnectionStatus, UserType } from './types';

export const connectionApi = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      checkIfUserAlreadyConnected: builder.query<UserType, string>({
        query: (husslupId: string) =>
          `connections/connectionStatus?husslupId=${husslupId}`,
      }),
      sendConnectionRequest: builder.mutation<UserType, string>({
        query: (connectionHusslupId: string) =>
          `connections/sendRequest?connectionHusslupId=${connectionHusslupId}`,
      }),

      getConnectionById: builder.query<any, string>({
        query: (husslupId: string) =>
          `connections/withConnectionId/${husslupId}`,
        providesTags: ['ConnectionRequests'],
      }),
      acceptConnectionRequest: builder.mutation<
        any,
        { connectionHusslupId: string }
      >({
        query: (arg) => ({
          url: 'connections/acceptRequest',
          method: 'POST',
          body: arg,
        }),
        invalidatesTags: ['ConnectionRequests'],
      }),
      rejectConnectionRequest: builder.mutation<
        any,
        { connectionHusslupId: string }
      >({
        query: (arg) => ({
          url: 'connections/rejectRequest',
          method: 'POST',
          body: arg,
        }),
        invalidatesTags: ['ConnectionRequests'],
      }),

      deleteConnectinRequest: builder.mutation<any, string>({
        query(husslupId: string) {
          return {
            url: '/connections',
            method: HTTP_METHODS.DELETE,
            params: {
              husslupId,
            },
          };
        },
        invalidatesTags: ['Connection'],
        async onQueryStarted(props, { dispatch, queryFulfilled, getState }) {
          try {
            const response = await queryFulfilled;
            const disoverState = getState() as any;
            if (response?.data?.affected > 0) {
              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getRandomizedPeople',
                  DefaultRandomDiscoverPeoplePagination,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedPeopleResponse =
                        RandomizedDiscoverUtils.updatePeopleListConnectionStatus(
                          current(draft),
                          props,
                          ConnectionStatus.NONE,
                        );
                      Object.assign(draft, updatedPeopleResponse);
                    }
                  },
                ),
              );

              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getDiscoverSuggestions',
                  disoverState.pagesetting.people,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedPeopleResponse =
                        RandomizedDiscoverUtils.updatePeopleListConnectionStatus(
                          current(draft),
                          props,
                          ConnectionStatus.NONE,
                        );
                      Object.assign(draft, updatedPeopleResponse);
                    }
                  },
                ),
              );
              dispatch(
                discoverApiSlice.util.updateQueryData(
                  'getRandomRecommendation',
                  {},
                  (draft) => {
                    if (current(draft)?.length) {
                      const updatedPeopleResponse =
                        RandomizedDiscoverUtils.updateRandomRecommendationListConnectionStatus(
                          current(draft),
                          props,
                          ConnectionStatus.NONE,
                        );
                      Object.assign(draft, updatedPeopleResponse);
                    }
                  },
                ),
              );
            }
          } catch {
            /* empty */
          }
        },
        // invalidatesTags: ['suggest']
      }),
    };
  },
});

export const {
  useLazyCheckIfUserAlreadyConnectedQuery,
  useSendConnectionRequestMutation,
  useAcceptConnectionRequestMutation,
  useRejectConnectionRequestMutation,
  useDeleteConnectinRequestMutation,
  useLazyGetConnectionByIdQuery,
  useGetConnectionByIdQuery,
} = connectionApi;
