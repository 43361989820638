import React, {
  ChangeEvent,
  FunctionComponent,
  useRef,
  MouseEvent,
} from 'react';
import isMediaIsVideo from 'utils/file';

const CreatePostMediaUploader: FunctionComponent<{
  onImageUpload: (file: File) => void;
  onVideoUpload: (file: File) => void;
}> = ({ onImageUpload, onVideoUpload }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const triggerUploadAction = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    inputRef.current?.click();
  };

  const handleMediaUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event?.target?.files;
    if (uploadedFiles) {
      const firstUploadedFile = uploadedFiles[0];
      if (firstUploadedFile && isMediaIsVideo(firstUploadedFile)) {
        onVideoUpload(firstUploadedFile);
      } else {
        onImageUpload(firstUploadedFile);
      }
    }
    event.target.value = '';
  };

  return (
    <button
      type='button'
      className='btn btn-text-only'
      onClick={triggerUploadAction}
    >
      <input
        ref={inputRef}
        className='d-none'
        type='file'
        accept='image/png, image/gif, image/jpeg, video/mp4, video/x-m4v, video/*'
        onChange={handleMediaUpload}
      />
       <i className='hic-media hic-2x'/>
    </button>
  );
};

export default CreatePostMediaUploader;
