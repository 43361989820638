import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react';
import { Form } from 'react-bootstrap';

interface IProps {
  onSelect?: (ev: ChangeEvent<HTMLInputElement>) => void;
  status?: 'Hired';
  checked?: boolean;
}
const MemberInfo: FunctionComponent<IProps> = ({
  onSelect,
  status,
  checked = false,
}): ReactElement => (
  <div className='hiredcheck'>
    {status && <div className='tag'>{status}</div>}
    {typeof onSelect === 'function' && (
      <Form.Check
        type='checkbox'
        onChange={onSelect}
        checked={checked}
      />
    )}
  </div>
);
export default MemberInfo;
