import { ConnectionsActionParams } from 'store/my-network';
import useVisibility from './useVisibility';

const useModalVisibility = (
  getAddToModalUserAndLabel: () => ConnectionsActionParams,
) => {
  const { show, hide, isVisible } = useVisibility();
  return {
    addToModalGetter: {
      show: isVisible,
      onClose: hide,
      id: getAddToModalUserAndLabel().userId ?? 0,
      title: getAddToModalUserAndLabel().addToModalLabel ?? '',
    },
    showModalUser: show,
  };
};

export default useModalVisibility;
