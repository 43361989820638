import { createSelector } from '@reduxjs/toolkit';
import { selectCurrentUserHusslupId } from 'store/auth/selectors';
import { ConnectionStatus } from 'store/user/types';
import { connectionsApiSlice } from './connections.api';

export const selectAllUserConnections =
  connectionsApiSlice.endpoints.getAllConnectionIds.select();

export const selectAcceptedConnections = createSelector(
  selectAllUserConnections,
  (connections) =>
    connections.data?.filter(
      (connection) =>
        String(connection.connectionStatus) ===
        String(ConnectionStatus.ACCEPTED),
    ) ?? [],
);

export const selectAcceptedConnectionHusslupIds = createSelector(
  selectAcceptedConnections,
  selectCurrentUserHusslupId,
  (connections, husslupId) =>
    (
      connections.map((connection) => connection.connectedUserHusslupId) ?? []
    ).filter((id) => id !== husslupId),
);

export const selectAcceptedConnectionHusslupIdsSet = createSelector(
  selectAcceptedConnections,
  selectCurrentUserHusslupId,
  (connections, husslupId) => {
    const connectionHusslupId = (
      connections.map((connection) => connection.connectedUserHusslupId) ?? []
    ).filter((id) => id !== husslupId);
    const connectionHusslupIdSet = new Set(connectionHusslupId);
    return connectionHusslupIdSet;
  },
);
