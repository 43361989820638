import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import Button, { ButtonVariants } from 'components/Core/Button';
import { CheckboxInput } from 'components/Core/Input';
import HorizontalSeparator from 'components/Core/Separator';
import Typography from 'components/Core/StylesGuide/Typography';
import { ConnectionLabel } from 'store/user/types';

export interface LabelConnectionModalProps {
  onAccept: (selectedLabel?: ConnectionLabel) => void;
  onClose: () => void;
  show: boolean;
  name: string;
  selectedLabel?: ConnectionLabel;
  disabled?: boolean;
  loading?: boolean;
}

const ConnectionLabels = [
  ConnectionLabel.REPRESENTATION,
  ConnectionLabel.CLIENTS,
  ConnectionLabel.WORK_WITH,
];

export const LabelConnectionModal = ({
  show,
  onClose,
  onAccept,
  name,
  selectedLabel,
  disabled,
  loading,
}: LabelConnectionModalProps) => {
  const [label, setLabel] = useState<ConnectionLabel | undefined>();

  useEffect(() => {
    setLabel(selectedLabel);
  }, [selectedLabel]);

  const handleSelectLabel = (value: ConnectionLabel) => () => {
    if (label === value) {
      setLabel(undefined);
    } else {
      setLabel(value);
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <Typography variant='h2'>{`Label ${name} as`}</Typography>
      </Modal.Header>
      <Modal.Body>
        {ConnectionLabels.map((connectionLabel, index, array) => (
          <>
            <CheckboxInput
              key={connectionLabel}
              id={`${connectionLabel.toLowerCase().trim()}-checkbox`}
              labelText={connectionLabel}
              name='connectionLabel'
              defaultChecked={label === connectionLabel}
              value=''
              checked={label === connectionLabel}
              onChange={handleSelectLabel(connectionLabel)}
              className='label-connection-option'
            />
            {index < array.length - 1 ? (
              <HorizontalSeparator wrapperClassName='label-connection-separator' />
            ) : null}
          </>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={ButtonVariants.secondary}
          label='Cancel'
          onClick={onClose}
          disabled={disabled}
        />
        <Button
          variant={ButtonVariants.primary}
          label='Save'
          onClick={() => onAccept(label)}
          disabled={disabled}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};
