import React, { ReactNode, useEffect, useMemo } from 'react';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import Layout from 'components/Layout';
import Typography from 'components/Core/StylesGuide/Typography';
import HusslTab from 'components/Core/HusslTab/HusslTab';
import {
  getNetworkTabs,
  InitialConnectionSliceState,
} from 'commons/data/pages/my-network';
import {
  selectGetConnectionRequestsParams,
  selectGetConnectionsByLabelParams,
  selectGetFollowedCompaniesParams,
  selectNetworkActiveSortBy,
  selectNetworkActiveTab,
} from 'store/my-network/selectors';
import { ConnectionLabel } from 'store/user/types';
import { ConnectionRequestType } from 'commons/types/network.type';
import { ConnectionUtils } from 'utils/connection';
import {
  ReferralInviteTab,
  setGetAcceptedInvitesParam,
  setGetConnectionRequestsParam,
  setGetConnectionsWithLabelParam,
  setGetFollowedCompaniesParam,
  setSelectedReferralInviteTab,
  useGetConnectionsCountPerLabelQuery,
} from 'store/my-network';
import { usePendingReferralActions } from 'commons/hooks/connections';
import Button, { ButtonTypes, ButtonVariants } from 'components/Core/Button';
import {
  REFERRAL_INVITE_FOUNDING_TEXT,
  REFERRAL_INVITE_MEMBER_TEXT,
  REFERRAL_INVITE_SWAG_TEXT,
} from 'constants/referral-invite';

import MyNetworkHeader from './MyNetworkHeader';
import { SendInviteModal } from './ReferralInvites';

type MyNetworkLayoutProps = {
  children: ReactNode;
  authenticated?: boolean;
  hideFooter?: boolean;
};

const MyNetworkLayout = ({
  children,
  authenticated,
  hideFooter,
}: MyNetworkLayoutProps) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { data } = useGetConnectionsCountPerLabelQuery({});

  const { sendInviteDialogPropGetter, showSendInvite } =
    usePendingReferralActions();

  const getConnectionsByLabelParams = useSelector(
    selectGetConnectionsByLabelParams,
  );

  const getConnectionRequestsParams = useSelector(
    selectGetConnectionRequestsParams,
  );

  const getFollowedCompaniesParams = useSelector(
    selectGetFollowedCompaniesParams,
  );

  const activeRoute = useMemo(
    () => ConnectionUtils.getActiveNetworkRoute(router.pathname),
    [router.pathname],
  );

  const activeTab = useSelector(selectNetworkActiveTab(activeRoute));
  const sortBy = useSelector(selectNetworkActiveSortBy(activeRoute));

  useEffect(() => {
    if (activeRoute.followedCompanies) {
      dispatch(
        setGetFollowedCompaniesParam({
          ...InitialConnectionSliceState.getFollowedCompaniesParams,
          ...getFollowedCompaniesParams,
        }),
      );
    }
    if (activeRoute.connectionRequests) {
      dispatch(
        setGetConnectionRequestsParam({
          ...InitialConnectionSliceState.getConnectionRequestsParams,
          ...getConnectionRequestsParams,
        }),
      );
    }
  }, [activeRoute]);

  const handleChangeLabel = (
    eventKey: ConnectionLabel | ConnectionRequestType,
  ) => {
    if (activeRoute.connections) {
      dispatch(
        setGetConnectionsWithLabelParam({
          ...getConnectionsByLabelParams,
          connectionLabel: eventKey as ConnectionLabel,
          ...InitialConnectionSliceState.getConnectionsByLabelParams,
        }),
      );
    }

    if (activeRoute.referralInvites) {
      dispatch(
        setGetAcceptedInvitesParam({
          ...InitialConnectionSliceState.getAcceptedInvitesParams,
        }),
      );
      dispatch(setSelectedReferralInviteTab(eventKey as ReferralInviteTab));
    }
  };

  return (
    <Layout authenticated={authenticated} hideFooter={hideFooter}>
      <main className='px-4'>
        <SendInviteModal {...sendInviteDialogPropGetter} />
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <Typography variant='h2'>{activeRoute.heading}</Typography>
            {activeRoute.heading === 'Referral Invites' ? (
              <p className='w-75 mt-sh' style={{ fontWeight: 500 }}>
                {REFERRAL_INVITE_MEMBER_TEXT +
                  REFERRAL_INVITE_FOUNDING_TEXT +
                  REFERRAL_INVITE_SWAG_TEXT}
              </p>
            ) : null}
          </div>
          {activeRoute.referralInvites ? (
            <div className='send-invite-button text-nowrap'>
              <Button
                type={ButtonTypes.button}
                label='Send Invite'
                variant={ButtonVariants.primary}
                onClick={showSendInvite}
              />
            </div>
          ) : null}
          {activeRoute.followedCompanies && (
            <Link href='/Discover/Companies'>
              <Typography variant='p-l-d'>Discover Companies</Typography>
            </Link>
          )}
        </div>
        {activeRoute.connectionRequests || activeRoute.followedCompanies ? (
          <div />
        ) : (
          <HusslTab
            tabs={getNetworkTabs(activeRoute, data)}
            defaultActiveKey={activeTab ?? ''}
            onTabClicked={handleChangeLabel}
            className='mt-8px'
          />
        )}
        <MyNetworkHeader selectedSortBy={sortBy ?? 'best-match'} />
        {children}
      </main>
    </Layout>
  );
};

export default MyNetworkLayout;
