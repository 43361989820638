import { useTranslation } from 'react-i18next';
import { useUpdateConnectionLabelMutation } from 'store/my-network/update-connection.api';
import { ConnectionLabel } from 'store/user/types';
import ToasterUtils from 'utils/toaster';

import useVisibility from '../useVisibility';

const useLabelConnection = (
  getUserDetailsToLabel: () => {
    id: string;
    name: string;
    selectedLabel?: ConnectionLabel;
  },
) => {
  const [labelConnection, { isLoading: isLabelingConnection }] =
    useUpdateConnectionLabelMutation();
  const { t } = useTranslation('misc');
  const {
    hide: hideLabelConnection,
    show: showLabelConnection,
    isVisible: isLabelConnectionVisible,
  } = useVisibility();

  const { id, name, selectedLabel } = getUserDetailsToLabel();

  const handleLabelConnection = async (newSelectedLabel?: ConnectionLabel) => {
    if (id) {
      try {
        const response = await labelConnection({
          connectionHusslupId: id,
          connectionLabel: newSelectedLabel ?? ConnectionLabel.NONE,
        }).unwrap();
        if (response) {
          ToasterUtils.successToast(
            t('CONNECTION_LABEL_UPDATED.value', { memberId: name }),
            {
              hideCloseButton: true,
              icon: 'check',
              position: 'top-center',
            },
          );
        } else {
          ToasterUtils.errorToast(t('CONNECTION_LABEL_ERROR.value'), {
            hideCloseButton: true,
            icon: 'close',
            position: 'top-center',
          });
        }
      } catch (err) {
        ToasterUtils.errorToast(t('CONNECTION_LABEL_ERROR.value'), {
          hideCloseButton: true,
          icon: 'close',
          position: 'top-center',
        });
      } finally {
        hideLabelConnection();
      }
    }
  };

  return {
    handleLabelConnection,
    showLabelConnection,
    labelConnectionDialogPropGetter: {
      disabled: isLabelingConnection,
      loading: isLabelingConnection,
      show: isLabelConnectionVisible,
      onAccept: handleLabelConnection,
      onClose: hideLabelConnection,
      name,
      selectedLabel,
    },
  };
};

export default useLabelConnection;
