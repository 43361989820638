import { FunctionComponent, useEffect, useState } from 'react';
import { FeedAPIResponse } from 'getstream';
import Loader from 'components/Loader';
import useGetStreamActivity from 'commons/hooks/Notification/useGetstreamActivity';
import { useAppDispatch } from 'commons/hooks/redux';
import { setHasNewNotification } from 'store/auth/auth.slice';
import NotificationItem from './NotificationItem';

const NotificationList: FunctionComponent<{ lastActivityFetchAt: number }> = ({
  lastActivityFetchAt,
}) => {
  const [activities, setActivities] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { activityFeedClient } = useGetStreamActivity();
  const dispatch = useAppDispatch();

  const fetchActivities = async () => {
    setIsLoading(true);

    const response: FeedAPIResponse = await activityFeedClient.get({
      limit: 30,
      mark_seen: true,
      mark_read: true,
    });

    console.log({ response });

    setActivities(response.results);
    setIsLoading(false);
  };

  useEffect(() => {
    if (activityFeedClient) {
      fetchActivities().then(() => {
        dispatch(setHasNewNotification(false));
      });
    }
  }, [activityFeedClient, lastActivityFetchAt]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ul className='notification-list'>
      {activities.map((activity) => (
        <NotificationItem key={activity.id} activity={activity.activities[0]} />
      ))}
    </ul>
  );
};

export default NotificationList;
