/* eslint-disable import/prefer-default-export */

export const searchedDataFormat = {
  result: [
    { keyName: 'memberNames', values: [], countOfValues: 0 },
    { keyName: 'profileHeadlines', values: [], countOfValues: 0 },
    { keyName: 'memberBios', values: [], countOfValues: 0 },
    { keyName: 'credits', values: [], countOfValues: 0 },
    { keyName: 'creativeSamples', values: [], countOfValues: 0 },
    { keyName: 'companies', values: [], countOfValues: 0 },
  ],
  totalCount: 0,
};

export const hussleupIconUpAndDownArrow = {
  memberNames: true,
  profileHeadlines: true,
  memberBios: true,
  credits: true,
  creativeSamples: true,
  companies: true,
};

export const searchIndexValue = '';

export const dialogBoxInfo = {
  dialogBoxMessage: '',
  dialogBoxTitle: '',
  dialogBoxLabel: '',
  currentHussleUpId: '',
};

export const addToModalState = {
  showReportModal: false,
  showAddToModel: false,
};

export const userInfo = {
  profileId: '',
  userId: 0,
};

export const droplabelOfDropDownDown = 'Best Match';

export const titleAndLabelOfAddToModel = '';
