/* eslint-disable import/prefer-default-export */
import {
  ICompanyProfileSggestionsResponse,
  IUserProfilesSuggestionsResponse,
} from 'store/discover/discover.api';
import { RandomRecommendation } from 'store/discover/discover.types';
import { ConnectionStatus } from 'store/user/types';

const updatePeopleListConnectionStatus = (
  peopleListResponse: IUserProfilesSuggestionsResponse,
  connectionHusslupId: string,
  updatedStatus: ConnectionStatus,
) => {
  const updatedPeople = peopleListResponse?.items?.map((person) => {
    if (person.husslupId === connectionHusslupId) {
      return {
        ...person,
        connectionStatus: String(updatedStatus),
      };
    }
    return person;
  });

  return {
    ...peopleListResponse,
    items: updatedPeople,
  };
};

const getFollowerCount = (followerCount: number, following: boolean) => {
  const countNumber = followerCount ?? 0;
  const count = following ? countNumber + 1 : countNumber - 1;
  return count < 0 ? 0 : count;
};

const updateCompanyListConnectionStatus = (
  companyListResponse: ICompanyProfileSggestionsResponse,
  companyId: number,
  following: boolean,
) => {
  const updatedCompanies = companyListResponse?.items?.map((company) => {
    if (company.id === companyId && following !== company?.isFollowing) {
      return {
        ...company,
        isFollowing: following,
        follower_count: getFollowerCount(company.follower_count, following),
      };
    }
    return company;
  });

  return {
    ...companyListResponse,
    items: updatedCompanies,
  };
};

const updateRandomRecommendationListConnectionStatus = (
  recommendations: RandomRecommendation[],
  connectionHusslupId: string,
  connectionStatus?: number,
) => {
  const updatedPeople = recommendations?.map((person) => {
    if (person.husslupId === connectionHusslupId) {
      return {
        ...person,
        connectionStatus,
      };
    }
    return person;
  });

  return updatedPeople;
};

export const RandomizedDiscoverUtils = {
  updatePeopleListConnectionStatus,
  updateCompanyListConnectionStatus,
  updateRandomRecommendationListConnectionStatus,
};
