import { HTTP_METHODS } from 'constants/http';
import { baseApiSlice } from 'commons/apis/base-api.config';
import {
  API_GENERATE_MUX_VIDEO_URL,
  API_GET_POST_LINK_DETAIL,
  API_POSTS,
  API_POST_WITH_TAGS,
} from 'constants/api';
import { Pagination } from 'commons/types/base';
import {
  DeletePostParams,
  GetPostByIdParams,
  GetPostLinkDetailParams,
  GetPostLinkDetailParamsV2,
  GetPostLinkDetailResponse,
  GetPostWithTagParams,
  Post,
  PostResponse,
} from 'commons/types/post.type';
import PostUtils from 'utils/post';
import { current } from '@reduxjs/toolkit';
import {
  extractUrlFromText,
  getDomainFromUrl,
  getDomainObjectFromUrl,
} from 'utils/url';

export const AUTH_API_SLICE = 'postApi';

export interface GenerateMuxVideUrlResponse {
  id: string;
  uploadId: string;
  uploadUrl: string;
  assetId?: number;
  playbackId?: number;
}

export const postApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      getPosts: builder.query<any, number | void>({
        query: (page = 1) => `${API_POSTS}?page=${page}&per_page=10`,
      }),

      getPostsWithTag: builder.query<Pagination<Post>, GetPostWithTagParams>({
        query(payload) {
          const { limit, page, tagId, searchKeyword } = payload;
          return {
            url: API_POST_WITH_TAGS,
            method: HTTP_METHODS.GET,
            params: {
              limit,
              page,
              tagId,
              enableInlineTagging: true,
              searchKeyword,
            },
          };
        },
        providesTags: ['FetchPosts', 'Home'],
        transformResponse: (data: Pagination<PostResponse>) => {
          const formattedPosts: Pagination<Post> = {
            ...data,
            items: data.items?.map((item) => ({
              ...item,
              media: item.media ? PostUtils.addMediaType(item.media) : [],
              likes: item.likes ? PostUtils.formatPostLikes(item.likes) : [],
              urls: extractUrlFromText(item.post ?? ''),
              originalPost: item.post,
              post: PostUtils.updateProfileLinkForMentionMarkdown(
                item.taggedUsers,
                item.taggedUserIdWithHusslupId,
                item.post,
              ),
              comment: item.comment
                ? PostUtils.formatPostComments(item.comment)
                : [],
              commentActivities: item.commentActivities
                ? PostUtils.formatPostComments(item.commentActivities)
                : [],
            })),
          };
          return formattedPosts;
        },
        // Only have one cache entry because the arg always maps to one string
        serializeQueryArgs: ({ endpointName }) => endpointName,
        // Always merge incoming data to the cache entry for same tagId
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs?.arg?.nextPage && otherArgs?.arg?.page !== 1) {
            currentCache.items.push(...newItems.items);
            currentCache.meta = newItems.meta;
          } else {
            currentCache.items = newItems.items;
            currentCache.meta = newItems.meta;
          }
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return (
            currentArg?.page !== previousArg?.page ||
            currentArg?.tagId !== previousArg?.tagId ||
            currentArg?.limit !== previousArg?.limit ||
            currentArg?.searchKeyword !== previousArg?.searchKeyword ||
            currentArg?.nextPage !== previousArg?.nextPage
          );
        },
      }),

      getPostById: builder.query<Post, GetPostByIdParams>({
        query(payload) {
          const { postId } = payload;
          return {
            url: `${API_POSTS}/${postId}`,
            method: HTTP_METHODS.GET,
            params: { enableInlineTagging: true },
          };
        },
        providesTags: ['SinglePost'],
        transformResponse: (data: PostResponse) => {
          const formattedPosts: Post = {
            ...data,
            media: data.media ? PostUtils.addMediaType(data.media) : [],
            likes: data.likes ? PostUtils.formatPostLikes(data.likes) : [],
            originalPost: data.post,
            urls: extractUrlFromText(data.post ?? ''),
            post: PostUtils.updateProfileLinkForMentionMarkdown(
              data.taggedUsers,
              data.taggedUserIdWithHusslupId,
              data.post,
            ),
            comment: data.comment
              ? PostUtils.formatPostComments(data.comment)
              : [],
            commentActivities: data.commentActivities
              ? PostUtils.formatPostComments(data.commentActivities)
              : [],
          };
          return formattedPosts;
        },
      }),

      createPost: builder.mutation<any, FormData>({
        query(payload) {
          return {
            url: API_POSTS,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        invalidatesTags: ['FetchPosts'],
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),
      updatePost: builder.mutation<any, FormData>({
        query(payload) {
          return {
            url: `${API_POSTS}`,
            method: HTTP_METHODS.PATCH,
            body: payload,
          };
        },
        invalidatesTags: ['FetchPosts', 'SinglePost'],
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),
      deletePost: builder.mutation<any, DeletePostParams>({
        query(payload) {
          return {
            url: `${API_POSTS}/${payload.body.postId}`,
            method: HTTP_METHODS.DELETE,
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            const response = (await queryFulfilled) as any;
            if (response?.data?.post_id) {
              dispatch(
                postApiSlice.util.updateQueryData(
                  'getPostsWithTag',
                  props.getPostWithTagParams,
                  (draft) => {
                    if (current(draft)?.items?.length) {
                      const updatedPost = current(draft).items.filter(
                        (post) => post.post_id !== props.body.postId,
                      );
                      const updatedDraft = {
                        ...current(draft),
                        items: [...updatedPost],
                      };

                      Object.assign(draft, updatedDraft);
                    }
                  },
                ),
              );
            }
          } catch {
            /* empty */
          }
        },
      }),
      generateMuxVideoUrl: builder.query<GenerateMuxVideUrlResponse, void>({
        query: () => `${API_GENERATE_MUX_VIDEO_URL}`,
      }),
      uploadMuxVideo: builder.mutation<any, any>({
        query(payload) {
          return {
            url: payload.get('uploadUrl'),
            method: HTTP_METHODS.PUT,
            body: payload,
          };
        },
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),
      /** @deprecated  use getPostLinkDetailV2 instead */
      getPostLinkDetail: builder.mutation<
        GetPostLinkDetailResponse,
        GetPostLinkDetailParams
      >({
        query(payload) {
          return {
            url: `${API_POSTS}${API_GET_POST_LINK_DETAIL}`,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        transformResponse: (data: GetPostLinkDetailResponse) => ({
          ...data,
          url: getDomainFromUrl(data?.url ?? ''),
        }),
      }),
      getPostLinkDetailV2: builder.query<
        GetPostLinkDetailResponse,
        GetPostLinkDetailParamsV2
      >({
        query(payload) {
          return {
            url: `${API_POSTS}${API_GET_POST_LINK_DETAIL}`,
            params: payload,
          };
        },
        transformResponse: (data: GetPostLinkDetailResponse, _, args) => {
          if (data) {
            return {
              ...data,
              url: getDomainFromUrl(data?.url ?? ''),
            };
          }
          const { cleanUrl, href } = getDomainObjectFromUrl(args?.url);
          return {
            url: cleanUrl,
            description: cleanUrl,
            original_url: href,
            title: cleanUrl,
            images: [],
          };
        },
      }),
    };
  },
});

export const {
  useCreatePostMutation,
  useUploadMuxVideoMutation,
  useGetPostsQuery,
  useGetPostsWithTagQuery,
  useGenerateMuxVideoUrlQuery,
  useLazyGenerateMuxVideoUrlQuery,
  useGetPostByIdQuery,
  useLazyGetPostByIdQuery,
  useDeletePostMutation,
  /** @deprecated  use getPostLinkDetailV2 instead */
  useGetPostLinkDetailMutation,
  useGetPostLinkDetailV2Query,
  useUpdatePostMutation,
} = postApiSlice;
