import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';

const MAX_TEXTAREA_ROWS = 20;

export type AutoResizeTextAreaInputProps = {
  initialValue?: string;
  labelText?: string;
  required?: boolean;
  placeholder?: string;
  error?: any;
  disabled?: boolean;
  id: string;
  name?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  rows?: number;
  cols?: number;
  wrapperClassName?: string;
  textAreaClassName?: string;
};

export const AutoResizeTextAreaInput = React.forwardRef<
  HTMLTextAreaElement,
  AutoResizeTextAreaInputProps
>(
  (
    {
      labelText,
      id,
      required,
      placeholder,
      error,
      wrapperClassName,
      textAreaClassName,
      disabled,
      onChange,
      onBlur,
      value,
      rows = 1,
      cols = 80,
    },
    ref,
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => textAreaRef.current!);

    const [adjustedRows, setAdjustedRows] = useState(rows);

    const adjustHeight = () => {
      if (textAreaRef?.current && textAreaRef?.current?.value) {
        const textAreaValue = textAreaRef?.current?.value;
        const numberOfColumns = textAreaRef?.current?.cols;

        let linecount = 0;
        textAreaValue.split('\n').forEach((line) => {
          const extraRowForLongColumn = Math.floor(
            line.length / numberOfColumns,
          );
          const rowsToAdd =
            1 + (line.length / cols > 1 ? extraRowForLongColumn : 0);
          linecount += rowsToAdd;
        });

        if (linecount < MAX_TEXTAREA_ROWS) {
          setAdjustedRows(linecount);
        }
      }
    };

    useEffect(() => {
      if (!value) {
        setAdjustedRows(1);
      } else {
        adjustHeight();
      }
    }, [value]);

    return (
      <Form.Group
        className={`form-group ${error && 'error'} ${wrapperClassName ?? ''}`}
      >
        {labelText ? <Form.Label>{labelText}</Form.Label> : null}
        <Form.Control
          ref={textAreaRef}
          id={id}
          data-cy={id}
          required={required}
          disabled={disabled}
          onBlur={onBlur}
          as='textarea'
          aria-label='With textarea'
          className={`form-resizable-textarea ${textAreaClassName}`}
          placeholder={placeholder}
          rows={adjustedRows}
          cols={cols}
          value={value}
          onChange={onChange}
        />
        {error && (
          <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
        )}
      </Form.Group>
    );
  },
);
