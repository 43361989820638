import React, { FunctionComponent, ReactElement } from 'react';
import { Button as BButton, Spinner } from 'react-bootstrap';
import Typography from '../StylesGuide/Typography/Typography';

interface TextButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  size?: 'sm' | 'lg';
  label: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  leftIcon?: ReactElement | boolean;
  rightIcon?: ReactElement;
}

const TextButton: FunctionComponent<TextButtonProps> = ({
  size,
  className,
  label,
  disabled,
  loading,
  ...props
}): ReactElement => (
  <BButton
    type='button'
    variant='text'
    size='sm'
    onClick={props.onClick}
    className={`${className || ''} d-flex align-items-center`}
    disabled={disabled}
  >
    {loading && (
      <Spinner
        as='span'
        className='mr-h pe-none'
        animation='border'
        size='sm'
        role='status'
        aria-hidden='true'
      />
    )}
    <Typography variant='p-sm-sb' className='pe-none'>
      {label}
    </Typography>
  </BButton>
);

export default React.memo(TextButton);
