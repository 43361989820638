import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { MESSAGES, PROFILE } from 'constants/routes';
import {
  ConnectionActions,
  ConnectionsActionParams,
  setConnectionsActionParams,
} from 'store/my-network';
import { selectConnectionsActionParams } from 'store/my-network/selectors';
import handleCopyProfileLink from 'utils/profile-toast';
import {
  acceptedInvitesMemberCardDropdonws,
  connectionRequestsMemberCardDropdowns,
  connectionsMemberCardDropdowns,
  getActionKeyByLabel,
} from 'commons/data/pages/my-network';
import { ConnectionLabel } from 'store/user/types';
import { ConnectionUtils } from 'utils/connection';
import { MemberCardModalType } from 'utils/basic-faceted-modal.enum';
import useBlockUser from '../useBlockUser';
import useReportUser from '../useReportUser';
import useRemoveConnection from './useRemoveConnection';
import useLabelConnection from './useLabelConnection';
import useModalVisibility from '../useModalVisibility';
import useAddToStaffing from '../useStaffingUser';

export type MyNetworkActionDropdownProps = {
  userId: number;
  husslupId: string;
  name?: string;
  connectionLabel?: ConnectionLabel;
};

const useMyNetworkActions = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const connectionsActionParam = useSelector(selectConnectionsActionParams);

  const activeRoute = useMemo(
    () => ConnectionUtils.getActiveNetworkRoute(router.pathname),
    [router.pathname],
  );

  const getBlockUserId = () => connectionsActionParam?.userIdToBlock ?? 0;
  const getRemoveConnectionId = () => ({
    userId: connectionsActionParam?.userIdToRemoveConnection ?? '0',
    type: connectionsActionParam?.removingConnectionType ?? 'existing',
  });

  const getReportUserId = () =>
    String(connectionsActionParam?.userHusslupIdToReport ?? '0');

  const getStaffingUserId = () => Number(connectionsActionParam?.userId);

  const getUserDetailsToLabel = () => ({
    id: String(connectionsActionParam?.userIdToLabel ?? '0'),
    name: connectionsActionParam?.userNameToLabel ?? '',
    selectedLabel: connectionsActionParam?.userSelectedLabelToLabel,
  });

  const getAddToModalUserAndLabel = () => ({
    userId: connectionsActionParam?.userId ?? 0,
    addToModalLabel: connectionsActionParam?.addToModalLabel ?? '',
  });
  const { blockDialogPropGetter, showBlock } = useBlockUser(getBlockUserId);

  const { removeConnectionDialogPropGetter, showRemoveConnection } =
    useRemoveConnection({ getUserId: getRemoveConnectionId });

  const { labelConnectionDialogPropGetter, showLabelConnection } =
    useLabelConnection(getUserDetailsToLabel);

  const { reportUserDialogPropGetter, showReportUser } =
    useReportUser(getReportUserId);

  const { addAddToStaffingDialogPropGetter, showAddToStaffingRoaster } =
    useAddToStaffing();

  const initiateBlock = (params: ConnectionsActionParams) => {
    dispatch(setConnectionsActionParams(params));
    showBlock();
  };

  const initiateRemoveConnection = (params: ConnectionsActionParams) => {
    dispatch(setConnectionsActionParams(params));
    showRemoveConnection();
  };

  const initiateReport = (params: ConnectionsActionParams) => {
    dispatch(setConnectionsActionParams(params));
    showReportUser();
  };

  const initiateLabelConnection = (params: ConnectionsActionParams) => {
    dispatch(setConnectionsActionParams(params));
    showLabelConnection();
  };

  const initiateAddToModal = (params: ConnectionsActionParams) => {
    dispatch(setConnectionsActionParams(params));
    showModalUser();
  };

  const { addToModalGetter, showModalUser } = useModalVisibility(
    getAddToModalUserAndLabel,
  );

  const handleDropdownClick =
    ({
      userId,
      husslupId,
      name,
      connectionLabel,
    }: MyNetworkActionDropdownProps) =>
    (label: string): void => {
      const dropdownKey: ConnectionActions = getActionKeyByLabel(label);

      switch (dropdownKey) {
        case 'label-connection': {
          initiateLabelConnection({
            userIdToLabel: husslupId,
            userNameToLabel: name,
            userSelectedLabelToLabel: connectionLabel,
            action: dropdownKey,
          });
          break;
        }
        case 'remove-connection': {
          initiateRemoveConnection({
            userIdToRemoveConnection: husslupId,
            removingConnectionType: 'existing',
            action: dropdownKey,
          });
          break;
        }
        case 'report': {
          initiateReport({
            userHusslupIdToReport: husslupId,
            action: dropdownKey,
          });
          break;
        }
        case 'block': {
          initiateBlock({ userIdToBlock: userId, action: dropdownKey });
          break;
        }
        case 'copy-profile-link': {
          handleCopyProfileLink(`${PROFILE}/${userId}`);
          break;
        }
        case 'share': {
          router.push(MESSAGES);
          break;
        }
        case 'message': {
          router.push(MESSAGES);
          break;
        }
        case 'add-to-pro-search': {
          initiateAddToModal({
            userId,
            addToModalLabel: MemberCardModalType.ADD_TO_PROSEARCH_TITLE,
          });
          break;
        }
        case 'add-to-contact-list': {
          initiateAddToModal({
            userId,
            addToModalLabel: MemberCardModalType.ADD_TO_CONTACT_TITLE,
          });
          break;
        }
        case 'add-to-staffing-roster':
          showAddToStaffingRoaster(userId || 0);
          break;
        default: {
          break;
        }
      }
    };

  const connectionsDropdownsWithActions = (
    params: MyNetworkActionDropdownProps,
  ) => {
    if (activeRoute.connections) {
      return connectionsMemberCardDropdowns?.map((card) => ({
        ...card,
        onClick: handleDropdownClick(params),
      }));
    }
    if (activeRoute.connectionRequests) {
      return connectionRequestsMemberCardDropdowns?.map((card) => ({
        ...card,
        onClick: handleDropdownClick(params),
      }));
    }
    if (activeRoute.referralInvites) {
      return acceptedInvitesMemberCardDropdonws?.map((card) => ({
        ...card,
        onClick: handleDropdownClick(params),
      }));
    }
    return connectionsMemberCardDropdowns?.map((card) => ({
      ...card,
      onClick: handleDropdownClick(params),
    }));
  };

  return {
    connectionsDropdownsWithActions,
    blockDialogPropGetter,
    reportUserDialogPropGetter,
    removeConnectionDialogPropGetter,
    labelConnectionDialogPropGetter,
    initiateRemoveConnection,
    addToModalGetter,
    addAddToStaffingDialogPropGetter,
  };
};

export default useMyNetworkActions;
