import { useContext } from 'react';
import { StreamChat } from 'stream-chat';

import { GetStreamContext } from '../../../commons/context/get-stream-context';

const useGetStreamClient: () => StreamChat | null | undefined = () => {
  const getStreamContext = useContext(GetStreamContext);

  return getStreamContext?.getStreamClient;
};

export default useGetStreamClient;
