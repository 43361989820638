import {
  API_ADD_EMAIL_LOGIN,
  API_CHANGE_EMAIL_CONFIRM,
  API_CHANGE_EMAIL_REQUEST,
  API_CHANGE_EMAIL_VALIDATE,
  API_CHANGE_PASSWORD,
  API_SWITCH_TO_EMAIL_REQUEST,
  API_VALIDATE_EMAIL_VERIFICATION,
} from 'constants/api';
import { HTTP_METHODS } from 'constants/http';
import { baseApiSlice } from 'commons/apis/base-api.config';

export const ACCOUNT_API_SLICE = 'accountApi';

export const accountApiSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      sendVerificationCodeOnChangeEmail: builder.mutation<
        { success: boolean; message: string },
        { email: string }
      >({
        query(payload) {
          return {
            url: API_CHANGE_EMAIL_REQUEST,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),
      validateVerificationCodeOnChangeEmail: builder.mutation<
        { success: boolean; message: string },
        { email: string; verificationCode: string }
      >({
        query(payload) {
          return {
            url: API_CHANGE_EMAIL_VALIDATE,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),
      confirmEmailChangeRequest: builder.mutation<
        { success: boolean; message: string },
        { email: string }
      >({
        query(payload) {
          return {
            url: API_CHANGE_EMAIL_CONFIRM,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        invalidatesTags: ['CurrentAuthUser'],
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),
      changePassword: builder.mutation<
        any,
        { oldPassword: string; newPassword: string }
      >({
        query(payload) {
          return {
            url: API_CHANGE_PASSWORD,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),

      sendVerificationCodeOnSwitchToEmail: builder.mutation<
        { success: boolean; message: string },
        { email: string }
      >({
        query(payload) {
          return {
            url: API_SWITCH_TO_EMAIL_REQUEST,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),

      validateEmailVerification: builder.mutation<
        { success: boolean; message: string },
        { email: string; verificationCode: string }
      >({
        query(payload) {
          return {
            url: API_VALIDATE_EMAIL_VERIFICATION,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),

      addEmailLogin: builder.mutation<
        { success: boolean; message: string },
        { email: string; password: string; verificationCode: string }
      >({
        query(payload) {
          return {
            url: API_ADD_EMAIL_LOGIN,
            method: HTTP_METHODS.POST,
            body: payload,
          };
        },
        invalidatesTags: ['CurrentAuthUser'],
        transformErrorResponse: (response: any) => ({
          status: response.status ? response.status : 500,
          error: {
            message: response.data?.message,
            meta: response.data,
          },
        }),
      }),
    };
  },
});

export const {
  useSendVerificationCodeOnChangeEmailMutation,
  useValidateVerificationCodeOnChangeEmailMutation,
  useConfirmEmailChangeRequestMutation,
  useChangePasswordMutation,
  useSendVerificationCodeOnSwitchToEmailMutation,
  useValidateEmailVerificationMutation,
  useAddEmailLoginMutation,
} = accountApiSlice;
