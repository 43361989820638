import HusslUpIcon, { IconType } from 'components/Icon';
import { AUTO_CLOSE_SECONDS } from 'constants/commons';
import { ToastPosition, toast, ToastOptions } from 'react-toastify';

export interface IToastProps {
  position?: ToastPosition;
  icon?: IconType;
  hideCloseButton?: boolean;
}

const defaultPosition: ToastPosition = 'top-center';

export default class ToasterUtils {
  private static getToastsProps(options?: IToastProps): ToastOptions {
    const optionsProps: ToastOptions = {
      position: options?.position || defaultPosition,
      autoClose: AUTO_CLOSE_SECONDS,
    };

    if (options?.hideCloseButton) {
      optionsProps.closeButton = false;
    }

    if (options?.icon) {
      optionsProps.icon = <HusslUpIcon name={options?.icon} />;
    }
    return optionsProps;
  }

  static successToast(message: string, options?: IToastProps) {
    return toast(message, {
      type: 'success',
      ...this.getToastsProps({
        hideCloseButton: true,
        icon: 'check',
        position: 'top-center',
        ...options,
      }),
    });
  }

  static errorToast(message: string, options?: IToastProps) {
    return toast(message, {
      type: 'error',
      ...this.getToastsProps({
        hideCloseButton: true,
        icon: 'close',
        position: 'top-center',
        ...options,
      }),
    });
  }

  static warningToast(message: string, options?: IToastProps) {
    return toast(message, {
      type: 'warning',
      ...this.getToastsProps(options),
    });
  }

  static infoToast(message: string, options?: IToastProps) {
    return toast(message, {
      type: 'info',
      ...this.getToastsProps(options),
    });
  }
}
