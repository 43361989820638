import { translator } from 'config/i18n';

export const INVALID_EMAIL = translator.t('errors:invalid_email');
export const EMAIL_IS_REQUIRED = translator.t('errors:email_is_required');

export const PASSWORD_IS_REQUIRED = translator.t('errors:password_is_required');

export const PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS_LONG = translator.t(
  'errors:password_must_be_at_least_6_characters_long',
);
export const PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS_LONG = translator.t(
  'errors:password_must_be_at_least_8_characters_long',
);
export const PASSWORD_MUST_BE_AT_MOST_20_CHARACTERS_LONG = translator.t(
  'errors:password_must_be_at_most_20_characters_long',
);

export const ARE_YOU_A_HUMAN = translator.t('errors:are_you_a_human');

export const EMAIL_EXISTS_IN_SYSTEM = {
  title: 'Email Associated with Account',
  message:
    'This email address is associated with a account that still uses phone number login. If you want to keep this account, login with phone number.',
};

export const EMAIL_EXISTS = {
  title: 'Email Already in Use',
  message: 'This email is associated with a HUSSLUP account. Please login.',
};

export const USER_DOES_NOT_EXIST_ON_OUR_PLATFORM =
  'User does not exist on our platform.';
export const PHONE_NUMBER_NOT_ASSOCIATED = 'Phone Number Not Associated';
export const THIS_PHONE_NUMBER_IS_NOT_ASSOCIATED_WITH_AN_ACTIVE_ACCOUNT =
  'This phone number is not associated with an active account.';
