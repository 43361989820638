import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ConnectionsSliceState } from '../connections.slice';
import { followedCompaniesApiSlice } from '../followed-companies.api';

function followedCompanyBuilder(
  builder: ActionReducerMapBuilder<ConnectionsSliceState>,
) {
  builder.addMatcher(
    followedCompaniesApiSlice.endpoints.getFollowedCompanies.matchFulfilled,
    (state) => {
      if (state.getFollowedCompaniesParams) {
        state.getFollowedCompaniesParams.nextPage = false;
      }
    },
  );
}

export default followedCompanyBuilder;
