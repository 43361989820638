import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetPostWithTagParams } from 'commons/types/post.type';
import postBuilder from './action-builders';

const POST_SLICE_KEY = 'post';

export type PostActionParams = {
  postId: number | null;
  postedById?: number;
  commentId?: number;
  commentedById?: number;
  action?:
    | 'delete'
    | 'block'
    | 'delete-comment'
    | 'edit-comment'
    | 'report-post';
  commentLevel?: number;
};

export enum SearchStates {
  Collapsed,
  Expanded,
  ExpandedAndEmpty,
  Fetching,
  FetchCompleted,
}

export interface PostSliceState {
  getPostWithTagParams: GetPostWithTagParams;
  actionParams: PostActionParams;
  feedSearchResultCount: number;
  searchState: SearchStates;
  searchKeyword: string;
}

const initialState: PostSliceState = {
  getPostWithTagParams: {
    limit: 10,
    page: 1,
    nextPage: false,
    searchKeyword: '',
    tagId: undefined,
  },
  actionParams: {
    postId: null,
  },
  feedSearchResultCount: 0,
  searchState: SearchStates.Collapsed,
  searchKeyword: '',
};

const postSlice = createSlice({
  name: POST_SLICE_KEY,
  initialState,
  reducers: {
    setGetPostWithTagParam(state, action: PayloadAction<GetPostWithTagParams>) {
      state.getPostWithTagParams = action.payload;
    },
    setPostActionParams(state, action: PayloadAction<PostActionParams>) {
      state.actionParams = action.payload;
    },
    setFeedSearchResultCount(state, action: PayloadAction<number>) {
      state.feedSearchResultCount = action.payload;
    },
    setSearchState(state, action: PayloadAction<SearchStates>) {
      state.searchState = action.payload;
    },
    setSearchKeyword(state, action: PayloadAction<string>) {
      state.searchKeyword = action.payload;
    },
  },
  extraReducers: (builder) => {
    postBuilder(builder);
  },
});

export const {
  setGetPostWithTagParam,
  setPostActionParams,
  setFeedSearchResultCount,
  setSearchState,
  setSearchKeyword,
} = postSlice.actions;

export const postReducer = postSlice.reducer;
