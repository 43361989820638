import SquircleImage from 'components/Core/SquircleImage/SquircleImage';
import Typography from 'components/Core/StylesGuide/Typography';
import React from 'react';

const ExternalSubscriptionItem = () => (
  <div className='subscription-user'>
    <SquircleImage
      imgUrl='/ExternalSub-pp.svg'
      width={36}
      height={36}
      alt='Profile Picture'
      className='external'
    />
    <div className='d-flex align-items-center'>
      <Typography variant='p-sm-sb'>
        Shared from External Subscription
      </Typography>
    </div>
  </div>
);
export default ExternalSubscriptionItem;
