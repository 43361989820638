/* eslint-disable @next/next/no-img-element */
/* eslint-disable import/extensions */
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from 'react-joyride';
import { navLinks, publicNavLinks } from 'constants/navbar';
import GlobalNavBar from 'components/Navbar';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import config from 'config';
import Loader from 'components/Loader';
import useWebTour from 'commons/hooks/useWebTour.tsx';
import useAuth from 'commons/hooks/auth';
import { STAFFING } from 'constants/routes';
import Footer from './Footer';
import * as gtag from '../../utils/gtag';

interface StepState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

const Layout: FunctionComponent<{
  children: ReactNode;
  authenticated?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  hideSecondaryNavBar?: boolean;
  disableDefaultSeo?: boolean;
}> = ({
  children,
  authenticated = false,
  hideFooter = false,
  hideHeader = false,
  hideSecondaryNavBar = false,
  disableDefaultSeo = false,
}) => {
  const { isLoggedIn, user } = useAuth();
  const router = useRouter();
  const {
    steps,
    rawSteps,
    currentCookies,
    stepRun,
    showWebTour,
    isTourRunning,
    setCookie,
    startWebTour,
    setRun,
    setIsTourRunning,
  } = useWebTour();
  const pageTitle =
    router.asPath !== '/' ? `| ${router.asPath.replaceAll('/', ' ')}` : '';

  const [stepState, setStepState] = useState<StepState>({
    run: false,
    stepIndex: 0,
    steps: [],
  });
  const [showSkipButton, setShowSkipButton] = useState<boolean>(false);

  const updateSteps = (steps: Step[]) => {
    setStepState({
      ...stepState,
      steps,
      run: true,
    });
  };

  useEffect(() => {
    if (showWebTour && steps.length > 0) {
      updateSteps(steps);
    }
  }, [stepRun]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    const body = document.querySelector('body');

    if (status === 'running' && action !== 'close') {
      body?.classList.add('joyride-running');
    } else {
      body?.classList.remove('joyride-running');
      setIsTourRunning(false);
    }

    if (!currentCookies && action === 'update') {
      setCookie('true');
      !isTourRunning && setIsTourRunning(true);
    }

    if (action === 'close' || action === 'skip' || action === 'stop') {
      gtag.event({
        action: `Web Tour - ${action}`,
        category: 'Web Tour',
        label: rawSteps[index]?.title,
      });
    }

    if (action === 'close') {
      setStepState({
        ...stepState,
        stepIndex: 0,
        run: false,
      });
      setRun(false);
      return;
    }

    const currentSpotlight = document.querySelector('.react-joyride__tooltip');

    if (currentSpotlight) {
      const { classList } = currentSpotlight;
      const isCenterMode = stepState.steps[index].target === 'body';
      classList.toggle('center-mode', isCenterMode);
      setShowSkipButton(isCenterMode);
    }

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setStepState({
        ...stepState,
        run: false,
        stepIndex: 0,
      });
      setRun(false);
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepState({
        ...stepState,
        stepIndex: nextStepIndex,
      });
    }
  };

  if (router.isFallback) {
    return <Loader />;
  }

  return (
    <div>
      {!disableDefaultSeo ? (
        <DefaultSeo
          defaultTitle={`${config.appName} ${pageTitle}`}
          description='TRANSFORMING HOLLYWOOD’S OLD CLIQUE WITH ONE CLICK'
          canonical={config.appUrl}
          openGraph={{
            url: config.appUrl,
            title: pageTitle,
            description: 'TRANSFORMING HOLLYWOOD’S OLD CLIQUE WITH ONE CLICK',
            images: [
              {
                url: 'https://husslup.com/logo.svg',
                width: 800,
                height: 800,
                alt: 'HUSSLUP',
              },
              {
                url: 'https://husslup.com/logo.svg',
                width: 400,
                height: 400,
                alt: 'HUSSLUP',
              },
            ],
          }}
        />
      ) : null}
      <div>
        {!hideHeader ? (
          <GlobalNavBar
            mainNavLinks={navLinks}
            publicNavLinks={publicNavLinks}
            authenticated={authenticated}
            hideSecondaryNavBar={hideSecondaryNavBar}
          />
        ) : null}
        <main
          id='app-container'
          className={
            user?.subscriptionStatus === 'TRIAL_EXPIRED'
              ? 'content-area  trial-expired-container'
              : `content-area fh ${
                  router.asPath.includes(STAFFING) ? 'fixed-nav' : ''
                }${!hideHeader ? '' : 'no-padding'}`
          }
        >
          {children}
        </main>
        {isLoggedIn && showWebTour && (
          <Joyride
            callback={handleJoyrideCallback}
            continuous
            run={stepState.run}
            stepIndex={stepState.stepIndex}
            steps={stepState.steps}
            spotlightPadding={0}
            showSkipButton={showSkipButton}
            hideCloseButton={showSkipButton}
            locale={
              showSkipButton
                ? {
                    next: 'Continue',
                    skip: 'Dismiss',
                  }
                : undefined
            }
          />
        )}
        {showWebTour && isLoggedIn && (
          <div className='webtourbtn' onClick={startWebTour}>
            <img src='/Spotlight-white.svg' alt='spotlight' />
          </div>
        )}
        {!hideFooter ? <Footer /> : null}
      </div>
    </div>
  );
};

export default Layout;
