/* eslint-disable import/prefer-default-export */
import {
  CreativeCardProps,
  CreativeCardType,
} from 'components/Profile/CreativePortfolio/CreativeCard';
import { isPublished, isScriptPublished } from 'utils/creative-portfolio.utils';
import { DraggableCreativeProps } from 'components/ProfileDetails/EditProfile/CreativePortfolio/DraggableCreativeCard';
import {
  CreativeSampleApiResponse,
  CreativeSampleCardProps,
  CreativeSampleResponseType,
} from './types';

/**
 * @todo return proper type
 */
export const transformGetCreativeSamples = (
  response: CreativeSampleResponseType[],
): CreativeCardProps[] =>
  response.map((creativeSample: CreativeSampleResponseType, index: number) => ({
    id: creativeSample.id,
    contentType: 'image',
    imgSrc: creativeSample?.asset?.[0]?.url,
    alt: `${creativeSample.description}-${index}`,
    title: creativeSample.title,
    detail: creativeSample.description,
    thumbnailUrl: creativeSample.thumbnailUrl,
    creativeCardType:
      creativeSample.asset[0]?.assetType.length > 0
        ? (creativeSample.asset[0]?.assetType?.toLowerCase() as CreativeCardType)
        : 'image',
    isPrivate: creativeSample.permission === 'connections',
    permission: creativeSample.permission,
    isConnected: false,
    isOwner: false,
    selectedAssetType: creativeSample.selectedAssetType,
    selectedAssetSubType: creativeSample.selectedAssetSubType,
    asset: creativeSample.asset,
    isPublished: isPublished(
      creativeSample as unknown as DraggableCreativeProps,
    ),
  }));

export const transformCreativeSamplesAddPublishStatus = (
  response: CreativeSampleApiResponse[],
): CreativeSampleCardProps[] =>
  response.map((creativeSample: CreativeSampleApiResponse) => ({
    isPublished: isScriptPublished(creativeSample),
    ...creativeSample,
  }));
