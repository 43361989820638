import Button, { ButtonTypes, ButtonVariants } from 'components/Core/Button';
import withAuthState from 'components/Hoc';
import Layout from 'components/Layout';
import { Router } from 'next/router';
import { NextPageWithLayout } from 'pages/_app';
import React, { Component, ErrorInfo, PropsWithChildren } from 'react';

const Fallback: NextPageWithLayout = () => (
  <div
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: 'inherit',
    }}
  >
    <h2>Oops, something went wrong.</h2>
    <Button
      className='mt-2'
      type={ButtonTypes.button}
      onClick={() => window.location.reload()}
      variant={ButtonVariants.secondary}
      label='Try again'
    />
  </div>
);

Fallback.Layout = Layout;

const FallbackWithAuth = withAuthState(Fallback);

class ErrorBoundary extends Component<PropsWithChildren<any>, any> {
  constructor(props: PropsWithChildren<any>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    Router.events.on('routeChangeComplete', this.handleRouteChange);
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // log to sentry or external service
    // eslint-disable-next-line no-console
    console.log(error, errorInfo);
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', this.handleRouteChange);
  }

  handleRouteChange = () => {
    this.setState({
      hasError: false,
    });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <FallbackWithAuth />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
