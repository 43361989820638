import 'assets/sass/main.scss';
import 'react-datepicker/dist/react-datepicker.css';
import i18n from 'config/i18n';
import type { AppProps } from 'next/app';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';

import { PersistGate as PersistGateClient } from 'redux-persist/integration/react';
import { storePersistor, store } from 'store';
import NextNprogress from 'nextjs-progressbar';
import CookieConsent from 'components/CookieConsent/CookieConsent';
import { NextPage } from 'next';
import TagManager from 'react-gtm-module';
import { FC, FunctionComponent, ReactNode, useEffect } from 'react';
import config from 'config';
import { Noop } from 'components/Layout';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { AUTO_CLOSE_SECONDS } from 'constants/commons';
import Loader from 'components/Loader';
import NavigationContextProvider from 'commons/context/navigation-context';
import useWebBlockGuard from 'commons/hooks/useWebBlockGuard';
import useScrollRestoration from 'commons/hooks/useScrollRestoration';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import SeoConfig from 'next-seo.config';
import { UserType } from 'store/user/types';
import * as routes from 'constants/routes';
import { GetStreamProvider } from '../commons/context/get-stream-context';
import Feed from './Feed';
import * as gtag from '../utils/gtag';

const PersistGateServer: FC<any> = ({ children }) => children;

/** To fix SSR html error
 * {@link https://github.com/rt2zz/redux-persist/issues/576}
 */
const getPersistGate = () => {
  const runtime = process.env.RUNTIME;

  if (runtime === 'browser') {
    return PersistGateClient;
  }
  return PersistGateServer;
};

export type NextPageWithLayout<P = { user: UserType }, IP = P> = NextPage<
  P,
  IP
> & {
  Layout?: FunctionComponent<{
    children: ReactNode;
    hideFooter?: boolean;
  }>;
};

export type PageLayout = FunctionComponent<{
  children: ReactNode;
  authenticated?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  hideSecondaryNavBar?: boolean;
}>;

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const HusslupApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const router = useRouter();
  // const isProd = process.env.NODE_ENV === "production";
  const Layout = (Component as any).Layout || Noop;

  const PersistGate = getPersistGate();

  useEffect(() => {
    TagManager.initialize({ gtmId: config.googleTagManager.gtmId || '' });
    const handleRouteChange = (url: any) => {
      gtag.pageview(url);
      gtag.event({
        action: 'Page View',
        category: 'Route change',
        label: url,
      });
    };
    if (router.pathname.includes('/Login/PhoneLogin')) {
      router.push(routes.LOGIN);
    }
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useWebBlockGuard();
  useScrollRestoration(router);

  return (
    <PersistGate loading={<Loader />} persistor={storePersistor}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <GetStreamProvider>
            <NavigationContextProvider>
              <ErrorBoundary fallback={<Feed />}>
                <Layout>
                  <ToastContainer
                    hideProgressBar
                    autoClose={AUTO_CLOSE_SECONDS}
                  />
                  <CookieConsent />
                  <DefaultSeo {...SeoConfig} />
                  <Component {...pageProps} />
                  <NextNprogress
                    color='#216cb2'
                    height={2}
                    options={{
                      showSpinner: false,
                    }}
                  />
                </Layout>
              </ErrorBoundary>
            </NavigationContextProvider>
          </GetStreamProvider>
        </I18nextProvider>
      </Provider>
    </PersistGate>
  );
};

export default HusslupApp;
