export const API_OAUTH_TOKEN = 'oauth/token';
export const API_OAUTH_USER = 'userinfo';
export const API_OAUTH_LOGOUT = 'logout';
export const API_GET_AUTH_USER = 'users/current';
export const API_GET_CURRENT_AUTH_USER = 'users/me';
export const API_GET_ME_USER_ONLY = 'users/me-user-only';
export const API_FORGOT_PASSWORD =
  'auth/forget-password/verification/send-email';
export const API_FORGOT_PASSWORD_CONFIRMATION =
  'auth/forget-password/verification';
export const API_RESET_PASSWORD = 'auth/forget-password/change-password';
export const API_SEND_VERIFICATION_EMAIL = '/auth/send-verification-email';
export const API_SIGN_UP_WITH_EMAIL = '/auth/create-account-with-email';
export const API_PHONE_LOGIN_ATTEMPT = '/auth/login/otp/send';
export const API_CHECK_WHETHER_USER_IS_EMAIL_LOGIN =
  'auth/change-email/check/is-email-login';

export const API_POSTS = 'posts';
export const API_POST_WITH_TAGS = 'posts/tag';
export const API_POST_TAGS = 'post-tag';
export const API_GENERATE_MUX_VIDEO_URL = 'mux-video/generate/uploadUrl';
export const API_GET_POST_LINK_DETAIL = '/get-post-link-detail';

// Post Like
export const API_LIKE_POST = 'like-post';

// Post Comment
export const API_COMMENT = 'comment';

// Post like on comment
export const API_COMMENT_LIKE = 'comment-like';
export const API_COMMENT_UN_LIKE = 'comment-unlike';

// Connections
export const API_CONNECTIONS = 'connections';
export const API_CONNECTIONS_UPDATE_LABEL = '/updateLabel';
export const API_CONNECTIONS_ACCEPT_CONNECTION = '/acceptRequest';
export const API_SEARCH_CONNECTIONS = 'connections/search';
export const API_CONNECTIONS_WEB = 'connections/web';
export const API_CONNECTIONS_WEB_GET_BY_LABEL = '/by-label';
export const API_CONNECTIONS_WEB_GET_COUNT_PER_LABEL = '/all-label-count';
export const API_CONNECTIONS_WEB_CONNECTION_REQUESTS = '/connection-requests';
export const API_CONNECTIONS_IS_CONNECTED = 'connections/connectionStatus';
export const API_CONNECTIONS_STATUS_WEB = 'connections/web/connection-status';
export const API_GET_AUTH_USER_COMPANY = 'users/current/company';
export const API_GET_ALL_CONNECTION = '/connections/web/all-connections';

// Profile Onboard
export const API_USERS = '/users';
export const API_SAVE_ACCOUNT_INFO_AND_HEADLINES =
  '/save-account-info-and-headlines';
export const API_SAVE_ACCOUNT_INFO_AND_HEADLINES_V2 =
  '/save-account-info-and-headlines-v2';
export const API_UPLOAD_PROFILE_PICTURE = '/upload-profile-picture';

// Profile List
export const API_PROFILE_LIST = '/profile-lists';
export const API_PROFILE_LIST_GET_ALL = '/all';
export const API_PROFILE_LIST_BY_TYPE = '/by-list-type';
export const API_CATEGORIZED_PROFILE_LIST_DEMOGRAPHICS =
  '/categorized-demographics';

// Discover
export const API_GET_DISCOVER = 'groups/discover';
export const API_SEARCH_DISCOVER = 'groups/discover-search/';
export const API_SUGGEST_CONNECTIONS = 'connections/web/suggestion';
export const API_SEND_CONNECTION_REQUEST = 'connections/sendRequest';
export const API_DISCOVER_SEARCH_COMPANIES = 'companies/search';
export const API_DISCOVER_COMPANIES = 'companies/web/discover';
export const API_FOLLOW_COMPANY = 'company-connect/connect-to-company';
export const API_UN_FOLLOW_COMPANY =
  'company-connect/remove-company-connection';
export const API_DISCOVER_GROUPS = '/groups/discover';
export const API_RANDOMIZED_PEOPLE = 'connections/web/suggestion/randomized';
export const API_RANDOMIZED_COMPANIES = 'companies/web/discover/randomized';

// Company
export const API_COMPANY_WEB = 'companies/web';
export const API_COMPANY_WEB_FOLLOWED = '/followed';
export const API_COMPANY_PROFILE = '/CompanyProfile';

// Report
export const API_GET_REPORT_USER_DEFAULT = 'report-user-default';
export const API_GET_REPORT_POST_DEFAULT = 'report-post-default';
export const API_REPORT_USER = 'report-user';
export const API_REPORT_POST = 'report-post';
export const API_REPORT_COMPANY = 'report-company';

// User Profile List
export const API_USER_PROFILE_LIST = '/user-profile-list';
export const API_USER_PROFILE_LIST_BY_USER = '/by-user';
export const API_USER_PROFILE_LIST_BY_HUSSLUP_ID = '/by-husslup-id';
export const API_USER_PROFILE_LIST_UPDATE = '/update';

// User Profile Credits
export const API_USER_CREDIT = '/user-credit';
export const API_USER_CREDIT_PARSE = '/parse';
export const API_USER_CREDITS_CONFIRM_PARSED_PROFILE =
  '/confirm-parsed-profile';
export const API_USER_CREDIT_GET_BY_HUSSLUP_ID = '/by-husslup-id';
export const API_USER_CREDIT_GET_BY_USER = '/by-user';
export const API_USER_CREDIT_REIMPORT = '/reimport-user-credit';

// User LinkedIn
// User Profile Credits
export const API_USER_LINKEDIN = '/user-linkedin';
export const API_USER_LINKEDIN_FETCH = '/get-linkedin-profile';
export const API_USER_VERIFY_LINKEDIN = '/verify';
export const API_USER_LINKEDIN_GET_BY_USER = '/get-linkedin-profile-by-user';

// Invite
export const API_INVITE = '/invite';
export const API_INVITE_EMAIL_INVITE_WEB = '/web/email-invite';
export const API_INVITE_WITH_COUNTS = '/with-counts';
export const API_INVITE_ACCEPTED_INVITES = '/web/accepted-invites';
export const API_INVITE_PENDING_INVITES = '/web/pending-invites';
export const API_INVITE_SEARCH_EMAIL_INVITE = '/search/email';

// User Waitlist
export const API_USER_WAITLIST = '/user-waitlist';
export const API_USER_WAITLIST_SEND_WAITLIST_EMAIL = '/send-waitlist-email';

// Block User
export const API_BLOCK_USER = '/block-users';
export const API_BLOCK_USER_BLOCK = '/block';
export const API_BLOCK_USER_UNBLOCK = '/unblock';
export const API_BLOCK_USER_GET_BLOCKED_USER_LIST = '/get-blocked-users-list';

// Global Config
export const API_GLOBAL_APP_CONFIG = '/app-config';

// User Invite Code
export const API_USER_INVITE_CODE = '/user-invite-codes';
export const API_USER_INVITE_CODE_VERIFY = '/verify';
export const API_USER_INVITE_CODE_VERIFY_REAL_SCREEN = '/verify-real-screen';

// Common For Staffing Tools
export const API_STAFFING_TOOLS = '/staffing-tools';
export const API_STAFFING_TOOLS_DUPLICATE = `${API_STAFFING_TOOLS}/duplicate`;
// Prosearches
export const API_STAFFING_PROSEARCHES = '/prosearches';

// Staffing Roster
export const API_STAFFING_ROSTERS = '/staffing-roster';
export const API_STAFFING_ROSTERS_ROLES = '/staffing-roster-role';
export const API_STAFFING_ROSTERS_ROLES_USERS = '/staffing-roster-role-user';
export const API_STAFFING_ROSTERS_ACCESS = '/staffing-roster-access';
export const API_STAFFING_ROSTER_IMPORT = '/import-role-from-spreadsheet';
export const API_STAFFING_ROSTER_EXPORT = 'staffing-roster-export-spreadsheet';
export const API_STAFFINF_ROSTER_REFRAME = 'staffing-roster-reframe';
export const DOWNLOAD_REFRAME = 'download-reframe-report';
export const CREATE_REFRAME = 'create-reframe';
export const SUBMIT_REFRAME = 'submit-reframe-report';

// Contact
export const API_CONTACT = '/list';
export const API_CONTACT_CREATE = '/list-member';
export const API_CONTACT_LIST_ACCESS_USERS = 'list-access-user';
export const API_CONTACT_SHARABLE_USERS = `${API_CONTACT_LIST_ACCESS_USERS}/sharable-users`;

export const API_CHANGE_EMAIL_REQUEST =
  'auth/change-email/verification-code/send';

export const API_CHANGE_EMAIL_VALIDATE =
  'auth/change-email/verification-code/validate';

export const API_CHANGE_EMAIL_CONFIRM = 'auth/change-email/update-email';
export const API_CHANGE_PASSWORD = 'auth/change-password';

export const API_SWITCH_TO_EMAIL_REQUEST =
  'auth/switch-to-email-login/send-verification-email';

export const API_VALIDATE_EMAIL_VERIFICATION =
  'auth/validate-verification-email';

export const API_ADD_EMAIL_LOGIN = 'auth/add-email-login';

// vouch
export const USER_VOUCH = 'user-vouch';
export const UPDATE_VOUCH = `${USER_VOUCH}/update-vouch`;

// basic
export const BASIC_SEARCH_ALL_FACET = '/basic-search/all-facet';

// search user
export const SEARCH_USERS = '/users';

// User Resume
export const USER_RESUME = 'user-resume';

export const SUBSCRIPE = '/users/signupUserForFreeTrialSubscription';

// Subscription Member
export const API_SUBSCRIPTION_MEMBER = 'subscription-members';
export const API_SUBSCRIPTION_MEMBER_CHANGE_OWNERSHIP = `${API_SUBSCRIPTION_MEMBER}/change-ownership`;
export const API_SUBSCRIPTION_MEMBER_ASSIGN_SEAT = `${API_SUBSCRIPTION_MEMBER}/assignSeatsToUsers`;
export const API_SUBSCRIPTION_REMOVE_MEMBER_SEAT = `${API_SUBSCRIPTION_MEMBER}/removeMemberFromSubscription`;

// Subscription Plan
export const API_SUBSCRIPTION_PLANS = 'subscription-plans';
export const API_USER_SUBSCRIPTIONS = 'user-subscriptions';
export const API_USER_SUBSCRIPTIONS_HAS_SUBSCRIPTION_CHECK = `${API_USER_SUBSCRIPTIONS}/user/has-subscription`;
export const API_USER_SUBSCRIPTIONS_GET_PUBLISHABLE_KEY = `${API_USER_SUBSCRIPTIONS}/getPublishableKey`;
export const API_USER_SUBSCRIPTIONS_GET_USER_SUBSCRIPTION = `${API_USER_SUBSCRIPTIONS}/getUserSubscription`;
export const API_USER_SUBSCRIPTIONS_SUBSCRIBE_USER = `${API_USER_SUBSCRIPTIONS}/subscribeUser`;
export const API_USER_SUBSCRIPTIONS_ADD_SUBSCRIPTION = `${API_USER_SUBSCRIPTIONS}/addSubscription`;
export const API_USER_SUBSCRIPTIONS_GET_RECEIPTS_BY_SUBSCRIPTION = `${API_USER_SUBSCRIPTIONS}/getReceiptsBySubscription`;
export const API_USER_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION = `${API_USER_SUBSCRIPTIONS}/cancelSubscription`;
export const API_USER_SUBSCRIPTIONS_UPDATE_PAYMENT_METHOD = `${API_USER_SUBSCRIPTIONS}/updatePaymentMethod`;
export const API_USER_SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_PLAN = `${API_USER_SUBSCRIPTIONS}/updateSubscriptionPlan`;
export const API_USER_SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_NAME = `${API_USER_SUBSCRIPTIONS}/updateSubscriptionNameBySubscription`;
export const API_USER_SUBSCRIPTIONS_UPDATE_RECIPIENT_EMAIL = `${API_USER_SUBSCRIPTIONS}/updateReceiptEmail`;
export const API_USER_SUBSCRIPTIONS_RESUME_SUBSCRIPTION = `${API_USER_SUBSCRIPTIONS}/resumeSubscription`;

export const RECOMMENDATION = 'recommendations';
export const GET_ALL_RECOMMENDATIONS = `${RECOMMENDATION}/getAllRecommendations`;

export const API_USER_CREATIVE_SAMPLE='user-creative-sample'
export const API_SCRIPT_ASSESSMENT = `${API_USER_CREATIVE_SAMPLE}/script-assessment`;
export const API_SCRIPT_ASSESSMENT_STATUS = `${API_USER_CREATIVE_SAMPLE}/script-assessment/get-assessment-status`;
export const API_EDIT_SCRIPT_ASSESSMENT=`${API_SCRIPT_ASSESSMENT}/edit-script-assessment`;
export const API_PUBLISH_TO_PROFILE = `${API_SCRIPT_ASSESSMENT}/publish`;
export const API_UNPUBLISH_FROM_PROFILE = `${API_SCRIPT_ASSESSMENT}/unpublish`;
export const API_DOWNLOAD_PDF = `${API_SCRIPT_ASSESSMENT}/download/pdf`;
export const API_LIKE_UNLIKE_SCRIPT = `${API_SCRIPT_ASSESSMENT}/like`;
export const API_PUBLISH_UNPUBLISH_TO_PROFILE = `${API_SCRIPT_ASSESSMENT}/publish-unpublish`;