import { useState } from 'react';

const useVisibility = () => {
  const [isVisible, setIsVisible] = useState(false);

  return {
    isVisible,
    hide: () => setIsVisible(false),
    show: () => setIsVisible(true),
  };
};
export default useVisibility;
