import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_PAGE, ITEMS_PER_PAGE } from 'constants/commons';
import { FilterProps } from 'components/Core/DiscoverHeader';

export interface IPageUtils {
  limit?: number;
  page: number;
}

export interface DiscoverFilters extends FilterProps, IPageUtils {
  nextPage?: boolean;
}

export interface SerendipityFilters
  extends Pick<DiscoverFilters, 'limit' | 'nextPage' | 'page'> {}

export const initialFilterSetting: DiscoverFilters = {
  sortBy: 'best-match',
  text: '',
  page: INITIAL_PAGE,
  limit: ITEMS_PER_PAGE,
  nextPage: false,
};

const initialSerendipityFilterSetting: SerendipityFilters = {
  page: initialFilterSetting.page,
  limit: initialFilterSetting.limit,
  nextPage: initialFilterSetting.nextPage,
};
export interface FilterState {
  people: DiscoverFilters;
  companies: DiscoverFilters;
  peopleSerendipity: SerendipityFilters;
  companiesSerendipity: SerendipityFilters;
  slides: {
    people: number;
    companies: number;
  };
}

const initialState: FilterState = {
  companies: initialFilterSetting,
  people: initialFilterSetting,
  companiesSerendipity: initialSerendipityFilterSetting,
  peopleSerendipity: initialSerendipityFilterSetting,
  slides: {
    companies: 0,
    people: 0,
  },
};

const pageSettingSlice = createSlice({
  name: 'pagesetting',
  initialState,
  reducers: {
    updatePageFilter: (
      state,
      action: PayloadAction<{
        key: 'people' | 'companies';
        value: Partial<DiscoverFilters>;
      }>,
    ) => {
      const page = action.payload.key;
      state[page] = {
        ...state[page],
        ...action.payload.value,
      };
    },
    updateSerendipityFilters: (
      state,
      action: PayloadAction<{
        key: 'peopleSerendipity' | 'companiesSerendipity';
        value: Partial<SerendipityFilters>;
      }>,
    ) => {
      const page = action.payload.key;
      state[page] = {
        ...state[page],
        ...action.payload.value,
      };
    },
    updateSlides: (
      state,
      action: PayloadAction<{
        key: 'companies' | 'people';
        value: number;
      }>,
    ) => {
      const page = action.payload.key;
      state.slides = {
        ...state.slides,
        [page]: action.payload.value,
      };
    },
    resetPageFilter: (
      state,
      action: PayloadAction<{ key: keyof Omit<FilterState, 'slides'> }>,
    ) => {
      const page = action.payload.key;
      state[page] = initialFilterSetting;
    },
  },
});

export const {
  updatePageFilter,
  updateSerendipityFilters,
  updateSlides,
  resetPageFilter,
} = pageSettingSlice.actions;
export const pageSettingReducer = pageSettingSlice.reducer;
